import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../utils/util.service';

@Injectable()
export class VersionCheckerService {

  private readonly baseApiPath: string;
  private readonly lambdaAppInfoUrl: string;

  constructor( private _http: HttpClient ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaAppInfoUrl = `${this.baseApiPath}/appInfo`;
  }

  getLatestVersion() {
    const url = `${this.lambdaAppInfoUrl}/version`;
    const headers = new HttpHeaders( { 'Content-Type': 'application/json' } );
    // console.log( `data: ${JSON.stringify( data )}` );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
