import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { GlobalState } from '../global.state';
import { AccountManager } from './accountManager';
import { GlobalDataService } from '../globalData';
import { RipThemeLoadingSpinnerService } from '../theme/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { Util } from './util.service';
import { ExistingManualAccountForm } from './dataInterfaces';
import {
  Account,
  AccountType,
  FormsUtil,
  ManualAccountSaveUtil,
  ManualAnnuityFormValues,
  ManualBankFormValues,
  ManualCryptoAccountFormValues,
  ManualInvestmentAccountFormValues,
  ManualLoanFormValues,
  ManualRealAssetAccountFormValues,
  ManualStockAccountFormValues,
  ManualStockOptionFormValues,
  ManualTermLifeInsuranceFormValues,
  Position
} from '@ripsawllc/ripsaw-analyzer';
import * as _ from 'lodash-es';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateValidator } from '../theme/validators';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { ErrorStateMatcher } from '@angular/material/core';
import { ManualAccountUtil } from './manualAccount.util';
import { EVENT_NAMES } from './enums';
import { Logger } from './logger.service';
import { TreasuryRatesUtil } from './treasury-rates.util';
import { Moment } from 'moment/moment';
import { TermLifeInsuranceFormComponent } from '../pages/modals/manualAccountManager/components';

export class ManualAccountErrorStateMatcher implements ErrorStateMatcher {
  isErrorState( control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null ): boolean {
    const isSubmitted = form && form.submitted;
    return !!( control && control.invalid && ( control.dirty || control.touched || isSubmitted ) );
  }
}

@Injectable()
export class ManualAccountManagerState implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  manualAccounts: Account[] = [];
  existingAccountForms: Map<any, ExistingManualAccountForm> = new Map<any, ExistingManualAccountForm>();

  accountLastUpdated: string;

  readonly invalidMessage: string = '*Some fields are missing or invalid';

  readonly spinnerSelector: string = 'manual-accounts-spinner';

  readonly subscriberName: string = 'ManualAccountManagerState';

  static zillowUrl: string = 'https://www.zillow.com/homes';

  static currencyInputGreaterThanZero = ( c: AbstractControl ): ValidationErrors => {
    if ( FormsUtil.getSanitizedFloatValue( c.value ) <= 0 ) {
      return {
        currencyInputGreaterThanZero: {
          valid: false,
        },
      };
    }
  };

  static currencyInputRequired = ( c: AbstractControl ): ValidationErrors => {
    const val = FormsUtil.getSanitizedFloatValue( c.value );
    if ( val !== null && val !== undefined ) {
      return null;
    } else {
      return {
        currencyInputRequired: {
          valid: false,
        },
      };
    }
  };

  static endOfTermIsBeforeToday( tliFormComponent: TermLifeInsuranceFormComponent ): ValidatorFn {
    return () => {
      if ( tliFormComponent.end_of_term?.isBefore( moment() ) ) {
        return {
          endOfTermIsBeforeToday: {
            valid: false,
          },
        };
      } else {
        return null;
      }
    };
  }


  // this is to avoid memory leaks
  unsubscribeAllFormChanges() {
    for ( const key of Object.keys( this.existingAccountForms ) ) {
      this.existingAccountForms[ key ].subscription.unsubscribe();
    }
  }

  setupExistingForms() {
    this.unsubscribeAllFormChanges(); // need to unsubscribe from any existing form change subscriptions
    this.existingAccountForms = new Map<any, ExistingManualAccountForm>();
    this.manualAccounts = this._state.globalVars.allManualAccountsFromDB;
    for ( const a of this.manualAccounts ) {
      if ( this.accountLastUpdated && this.accountLastUpdated === a.account_id ) {
        a.expanded = true;
      }
      this.setupExistingAccountForm( a );
    }
    this.manualAccounts = [ ...this.manualAccounts ];
  }

  setupExistingAccountForm( account: Account ) {

    const type = _.find( [ ...this.assetAccountTypes, ...this.liabilityAccountTypes ], ( t: AccountType ) => {
      return t.type === account.account_type;
    } );
    const form: UntypedFormGroup = ManualAccountManagerState.createNewForm( type );
    form.controls.included.setValue( account.included );
    const existingManualAccountForm: ExistingManualAccountForm = {
      form,
      saveButtonOptions: {
        active: false,
        text: 'Save',
        buttonColor: 'primary',
        spinnerColor: 'primary',
        raised: true,
        mode: 'indeterminate',
        disabled: false,
      },
      removeButtonOptions: {
        active: false,
        text: 'REMOVE',
        buttonColor: 'warn',
        spinnerColor: 'warn',
        raised: true,
        mode: 'indeterminate',
        disabled: false,
      },
      formType: this.getFormType( account ),
    };

    this.existingAccountForms[ account.account_id ] = existingManualAccountForm;
    this.existingAccountForms[ account.account_id ].subscription = form.valueChanges.subscribe( () => {
      this.existingAccountForms[ account.account_id ].saveButtonOptions.disabled = this.existingAccountForms[ account.account_id ].form.invalid;
      this.existingAccountForms[ account.account_id ].showInvalidMessage = this.existingAccountForms[ account.account_id ].form.invalid;
    } );
  }

  updateExistingForm( account: Account ) {
    /*const existingFormComponent: ManualAccountFormComponent = this.existingFormComponents.find( ( form: ManualAccountFormComponent ) => {
     return account.account_id === form.account.account_id;
     } );
     // console.log( existingFormComponent );
     if ( existingFormComponent ) {
     existingFormComponent.account = Object.assign( {}, account );
     existingFormComponent.patchForm();
     }*/
  }

  removeExistingAccountForm( account: Account ) {
    this.existingAccountForms[ account.account_id ].subscription.unsubscribe();
    delete this.existingAccountForms[ account.account_id ];
    _.remove( this.manualAccounts, ( a: Account ) => {
      return a.account_id === account.account_id;
    } );
    this._state.notifyDataChanged( EVENT_NAMES.MANUAL_ACCOUNT_DELETED, account.account_id );
  }

  matcher = new ManualAccountErrorStateMatcher();

  accountType: AccountType = {};
  newAccountForm: UntypedFormGroup;

  saveNewButtonOptions: any = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    disabled: true,
  };

  assetAccountTypes: AccountType[] = this._state.getManualAssetAccountTypes();

  liabilityAccountTypes: AccountType[] = this._state.getManualLiabilityAccountTypes();

  static createNewForm( type?: AccountType, options?: any ): UntypedFormGroup {

    // adding some defaults for when adding an account in revision mode
    const quantity = options?.zeroDefaults ? 0 : 1;
    const cost_basis = options?.zeroDefaults ? 0 : '';
    const value = options?.zeroDefaults ? 0 : '';

    const form = new UntypedFormGroup( {
      included: new FormControl<boolean>( true ), // needed for hiding/including manual accounts without deleting
      name: new FormControl<string>( '', Validators.required ), // all except vehicle and valuables
      value: new FormControl<string | number>( value, Validators.required ), // all
      owner_type: new FormControl<string>( '' ), // all but valuables
      quantity: new FormControl<string | number>( quantity, Validators.required ), // all
      price: new FormControl<string | number>( '', Validators.required ), // all
      cost_basis: new FormControl<string | number>( cost_basis, Validators.required ), // all but fully amortizing loans, annuities and bank accounts
                                                                                       // where it is set automatically
      institution_name: new FormControl<string>( '' ), // all except real assets/other
      management_fee: new FormControl<string | number>( '' ),
      // risk_premium: new FormControl( '' ), // commented out in bond form
      // modified_duration: new FormControl( '' ), // commented out in bond/loan forms
      // maturity_in_years: new FormControl( '' ), // commented out of loan form
    } );
    // go through different categories adding/removing common fields and then into each type for specifics
    switch ( type.account_category ) {
      case 'Other': // currently other is just real assets
        // common fields to add/remove for other category
        form.addControl( 'annualized_yield', new FormControl<string | number>( '' ) );
        form.addControl( 'dollar_flow', new FormControl<string | number>( '' ) );
        // form.addControl( 'dividend_per_share', new FormControl( '' ) );
        form.removeControl( 'institution_name' );
        switch ( type.type ) {
          case 'Real Estate':
            // fields to add/remove for real estate
            form.addControl( 'address', new FormControl<string>( '' ) );
            form.addControl( 'cityStateZip', new FormControl<string>( ' ' ) );
            form.addControl( 'corresponding_liability_id', new FormControl<string>( '' ) );
            form.addControl( 'address_line1', new FormControl<string>( '' ) );
            form.addControl( 'address_line2', new FormControl<string>( '' ) );
            form.addControl( 'address_city', new FormControl<string>( '' ) );
            form.addControl( 'address_state', new FormControl<string>( '' ) );
            form.addControl( 'address_zip', new FormControl<string>( '' ) );
            break;
          case 'Vehicle':
            // fields to add/remove for vehicles
            form.addControl( 'make', new FormControl<string>( '' ) );
            form.addControl( 'model', new FormControl<string>( '' ) );
            form.addControl( 'year', new FormControl<string>( '' ) );
            form.addControl( 'corresponding_liability_id', new FormControl<string>( '' ) );
            form.removeControl( 'name' );
            break;
          case 'Valuable(s)':
            // fields to add/remove for valuables
            form.addControl( 'description', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'corresponding_liability_id', new FormControl<string>( '' ) );
            form.removeControl( 'name' );
            break;
          case 'Crypto':
            form.addControl( 'ticker', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'ticker_name', new FormControl<string>( '' ) );
            break;
        }
        break;
      case 'Insurance':
        switch ( type.type ) {
          case 'Term Life Insurance':
            form.addControl( 'premium', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'payment_frequency', new FormControl<string>( 'month', Validators.required ) );
            form.addControl( 'amount_of_insurance', new FormControl<string | number>(
              null, Validators.compose( [ this.currencyInputRequired, this.currencyInputGreaterThanZero ] ) ),
            );
            form.addControl( 'birth_date', new FormControl<Date>( null, Validators.required ) );
            form.addControl( 'gender', new FormControl<string>( null, Validators.required ) );
            form.addControl( 'effective_date', new FormControl<Moment>( null ) );
            form.addControl( 'life_insurance_term', new FormControl<string | number>( null, Validators.required ) );
            form.addControl( 'expected_remaining_life', new FormControl<string | number>( null ) );
            form.removeControl( 'cost_basis' );
            break;
        }
      case 'Investment':
        // common fields to add/remove for Investment category
        switch ( type.type ) {
          case 'Annuity':
            form.addControl( 'payment', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'payment_frequency', new FormControl<string>( 'month', Validators.required ) );
            form.addControl( 'birth_date', new FormControl<moment.Moment | string>( '', Validators.required ) );
            form.addControl( 'gender', new FormControl<string>( 'm' ) );
            form.addControl( 'joint_birth_date', new FormControl<moment.Moment | string>( '' ) );
            form.addControl( 'joint_gender', new FormControl<string>( 'm' ) );
            form.addControl( 'expected_remaining_life', new UntypedFormControl( '' ) );
            form.addControl( 'treasury_rate', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'duration_matched_treasury_rate', new FormControl<string | number>( '' ) );
            form.addControl( 'annuity_type', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'credit_quality', new FormControl<string>( '' ) );
            form.addControl( 'sector', new FormControl<string>( '' ) );
            form.addControl( 'us_non_us', new FormControl<string>( '' ) );
            form.addControl( 'emerging_markets', new FormControl<string>( '' ) );
            form.removeControl( 'cost_basis' );
            break;
          case 'Stock Option':
            // add stuff for only stock option, and then it will inherit everything added/removed in stock case
            form.addControl( 'underlying_stock', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'underlying_price', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'price_per_share', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'shares_per_option', new FormControl<string | number>( 100, Validators.required ) );
            form.addControl( 'volatility', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'dividend_list', new FormControl<string>( '' ) );
            form.addControl( 'dividend_frequency', new FormControl<string>( '' ) );
            form.addControl( 'call_put', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'exercise_price', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'maturity_date', new FormControl<moment.Moment | string>( '', Validators.compose( [
              ManualAccountManagerState.isFutureDateValidator(),
              DateValidator(),
              Validators.required,
            ] ) ) );
            form.addControl( 'treasury_rate', new FormControl<string | number>( '' ) );
          case 'Restricted Stock':
          case 'Stock': // stock and restricted stock are identical for now, if we add special stuff for restricted stock,
            // it can go in the case above and not be added to regular stock form
            form.addControl( 'annualized_yield', new FormControl<string | number>( '' ) );
            form.addControl( 'us_non_us', new FormControl<string>( '' ) );
            form.addControl( 'ticker', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'company_market_cap', new FormControl<string>( '' ) );
            form.addControl( 'emerging_markets', new FormControl<string>( '' ) );
            form.addControl( 'sector', new FormControl<string>( '' ) );
            form.addControl( 'growth_value', new FormControl<string>( '' ) );
            form.addControl( 'dividend_per_share', new FormControl<string | number>( '' ) );
            break;
          case 'Private Lending': // Fully amortizing
            form.addControl( 'original_loan_amount', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'outstanding_balance', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'loan_principal_paydown', new FormControl<string | number>( '' ) );
          case 'Bond': // interest only
            form.addControl( 'loan_origination_date', new FormControl<moment.Moment | string>(
              '',
              Validators.compose( [
                DateValidator(),
                Validators.required,
              ] ) ) );
            form.addControl( 'loan_term', new FormControl<string | number>( '', Validators.compose( [
              ManualAccountManagerState.isFloatGreaterThanZeroValidator(),
              Validators.required,
            ] ) ) );
            form.addControl( 'loan_term_in_months', new FormControl<string | number>( null ) );
            form.addControl( 'maturity_date', new FormControl<moment.Moment | string>( '', Validators.compose( [
              ManualAccountManagerState.isFutureDateValidator(),
              DateValidator(),
              Validators.required,
            ] ) ) );
            form.addControl( 'maturity_value', new FormControl<number | string>( '', Validators.required ) );
            form.addControl( 'coupon', new FormControl<string | number>( '', Validators.compose( [
              ManualAccountManagerState.isValidPctRangeValidator(),
              Validators.required,
            ] ) ) );
            form.addControl( 'coupon_frequency', new FormControl<string>( '', Validators.required ) );
            form.addControl( 'credit_quality', new FormControl<string>( '' ) );
            form.addControl( 'sector', new FormControl<string>( '' ) );
            form.addControl( 'us_non_us', new FormControl<string>( '' ) );
            form.addControl( 'emerging_markets', new FormControl<string>( '' ) );
            form.addControl( 'current_market_rate', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'maturity_in_years', new FormControl<string | number>( '' ) );
            form.addControl( 'maturity_bucket', new FormControl<string>( '' ) );
            break;
          case 'Investment':
            const positionValidators = options?.editing ? [] : Validators.compose( [
              Validators.minLength( 1 ),
              Validators.required,
            ] );
            form.addControl( 'positions', new FormControl<Position[]>( [], positionValidators ) );
            form.addControl( 'investment_account_type', new FormControl<string>( '' ) );
            form.addControl( 'is_taxable', new FormControl<boolean>( true ) );
            form.removeControl( 'price' );
            form.removeControl( 'quantity' );
            form.removeControl( 'cost_basis' ); // will do individual ones that aren't required
            break;
          default:
        }
        if ( type.type === 'Stock Option' ) { // for now, but we may use ticker eventually in options, to get quant data using proxy ticker
          form.removeControl( 'ticker' );
        }
        if ( type.type === 'Private Lending' ) {
          form.removeControl( 'maturity_value' );
          form.removeControl( 'cost_basis' );
        }
        break;
      case 'Loan':
        form.addControl( 'loan_origination_date', new FormControl<moment.Moment | string>( '', Validators.compose( [
          DateValidator(),
          Validators.required,
        ] ) ) );
        form.addControl( 'loan_term', new FormControl<string | number>( '', Validators.compose( [
          ManualAccountManagerState.isFloatGreaterThanZeroValidator(),
          Validators.required,
        ] ) ) );
        form.addControl( 'loan_term_in_months', new FormControl<string | number>( null ) );
        form.addControl( 'maturity_date', new FormControl<moment.Moment | string>( '', Validators.compose( [
          ManualAccountManagerState.isFutureDateValidator(),
          DateValidator(),
          Validators.required,
        ] ) ) );
        form.addControl( 'original_loan_amount', new FormControl<string | number>( '' ) );
        form.addControl( 'coupon', new FormControl<string | number>( '', Validators.compose( [
          ManualAccountManagerState.isValidPctRangeValidator(),
          Validators.required,
        ] ) ) );
        form.addControl( 'coupon_frequency', new FormControl<string>( '', Validators.required ) );
        form.addControl( 'credit_quality', new FormControl<string>( '' ) );
        form.addControl( 'sector', new FormControl<string>( '' ) );
        form.addControl( 'us_non_us', new FormControl<string>( '' ) );
        form.addControl( 'emerging_markets', new FormControl<string>( '' ) );
        form.addControl( 'outstanding_balance', new FormControl<string | number>( '', Validators.required ) );
        form.addControl( 'loan_principal_paydown', new FormControl<string | number>( '' ) );
        form.addControl( 'current_market_rate', new FormControl<string | number>( '', Validators.required ) );
        form.addControl( 'corresponding_asset_id', new FormControl<string>( '' ) );
        switch ( type.type ) {
          case 'Mortgage Loan':
            form.removeControl( 'cost_basis' );
            break;
          case 'Auto Loan':
            form.removeControl( 'cost_basis' );
            break;
          case 'Private Borrowing':
            form.addControl( 'maturity_value', new FormControl<string | number>( '', Validators.required ) );
            form.addControl( 'credit_quality', new FormControl<string>( '' ) );
            form.addControl( 'sector', new FormControl<string>( '' ) );
            form.addControl( 'us_non_us', new FormControl<string>( '' ) );
            form.addControl( 'emerging_markets', new FormControl<string>( '' ) );
            form.addControl( 'maturity_in_years', new FormControl<string | number>( '' ) );
            form.removeControl( 'outstanding_balance' );
            break;
          default:
        }
        break;
      case 'Banking':
        switch ( type.type ) {
          case 'Cash':
            // don't actually need to add anything here, but the default adds stuff we don't want
            break;
          default:
            form.addControl( 'annualized_yield', new FormControl<string | number>( '' ) );
        }
        form.removeControl( 'cost_basis' );
        break;
      default:

    }
    return form;
  }

  appName: string = '';

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _gdService: GlobalDataService,
               private _spinnerService: RipThemeLoadingSpinnerService,
               private snackBar: MatSnackBar,
               private treasuryRatesUtil: TreasuryRatesUtil,
               _cd: ChangeDetectorRef ) {
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_mamState' ] = this;
    }
    // this.saveNewButtonOptions.disabled = !this.newAccountForm.valid;
    this.appName = environment.appName;

    this._state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
      this.setupExistingForms();
    }, this.subscriberName );
    /*    this._state.subscribe( 're-render-tables', () => {
     // this.setupExistingForms(); // not sure if we need this or not. It is causing an issue when leaving revision by
     // resetting the forms without re-initiating them
     }, this.subscriberName );*/
    if ( this._state.globalVars?.allManualAccountsFromDB?.length > 0 ) {
      this.setupExistingForms();
    }
  }


  ngOnDestroy(): void {
    this._state.unsubscribe( [ EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, 're-render-tables' ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  showSpinner() {
    this._spinnerService.show( this.spinnerSelector );
  }

  hideSpinner() {
    this._spinnerService.hide( 0, this.spinnerSelector );
  }

  clearNewForm() {
    this.accountType = {};
    this.newAccountForm = undefined;
  }

  newAccountFormValueChangeSubscription: Subscription;


  createManualAccount( cb: Function ) {
    this.saveNewButtonOptions.active = true;
    const newManualAccount: any = {};
    // common fields for all new manual accounts
    newManualAccount.account_category = this.accountType.account_category;
    newManualAccount.is_taxable = this.accountType.taxable;
    newManualAccount.account_type = this.accountType.type;

    const newManualPosition: any = {};
    // common fields for all new manual positions
    newManualPosition.allocation = 1;
    newManualPosition.expense_ratio = 0;

    ManualAccountManagerState.setFieldsForAccountAndPosition( newManualAccount, newManualPosition, this.newAccountForm, this.treasuryRatesUtil.treasuryRates );

    this._gdService.createManualAccount( newManualAccount )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // console.log( resp );
          const createdAccount = resp.data;
          createdAccount.expanded = true;
          ManualAccountUtil.setIcon( createdAccount );
          createdAccount.formattedDescription = this._accountManager.formatAccountDescription( createdAccount );

          this.accountLastUpdated = createdAccount.account_id;

          this._accountManager.addNewManualAccountToLists( createdAccount );
          this._state.notifyDataChanged( EVENT_NAMES.MANUAL_ACCOUNT_CREATED, resp.data.account_id );
          this.setupExistingAccountForm( createdAccount );
          this.manualAccounts = [ ...this._state.globalVars.allManualAccountsFromDB ];

          this.snackBar.open( 'Manual Account Created', null, Util.getSnackBarOptions() );
          this.clearNewForm();
          this.saveNewButtonOptions.active = false;
          if ( cb ) {
            cb();
          }
          this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
        }, error: ( err ) => {
          this.saveNewButtonOptions.active = false;
          if ( cb ) {
            cb( err );
          }
        },
      } );
  }

  updateManualAccount( existingAccount: any, form: UntypedFormGroup, callback?: Function ) {
    this.existingAccountForms[ existingAccount.account_id ].saveButtonOptions.active = true;

    const account = _.cloneDeep( existingAccount );

    const position = ManualAccountUtil.typeIsInvestment( account.account_type ) ? {} : account.positions[ 0 ];

    ManualAccountManagerState.setFieldsForAccountAndPosition( account, position, form, this.treasuryRatesUtil.treasuryRates );

    const oldCorrespondingId: string = ManualAccountManagerState.didAccountHaveCorrespondingAccount( existingAccount, account );
    let oldCorrespondingAccount: Account;
    if ( oldCorrespondingId ) {
      oldCorrespondingAccount = this._accountManager.getRevisableAccountFromId( oldCorrespondingId );
    } else {
      oldCorrespondingAccount = this._accountManager.searchForCorrespondingLiabilityId( existingAccount.account_id );
      if ( !oldCorrespondingAccount ) {
        oldCorrespondingAccount = this._accountManager.searchForCorrespondingAssetId( existingAccount.account_id );
      }
    }

    this._gdService.updateManualAccount( account )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {

          this.snackBar.open( 'Manual Account Updated', null, Util.getSnackBarOptions() );
          this.existingAccountForms[ existingAccount.account_id ].saveButtonOptions.active = false;
          this.accountLastUpdated = account.account_id;
          this._accountManager.updateManualAccountFields( resp.data );

          // need make sure the account this one was pointing at updates if needed to not point back at this one
          if ( oldCorrespondingAccount && oldCorrespondingAccount.isManual ) {
            if ( oldCorrespondingAccount.corresponding_asset_id || oldCorrespondingAccount.corresponding_liability_id ) {
              oldCorrespondingAccount.corresponding_asset_id = null;
              oldCorrespondingAccount.corresponding_liability_id = null;
              this._accountManager.updateManualAccountFields( oldCorrespondingAccount );
              this.quickManualAccountUpdate( oldCorrespondingAccount );
            }
            this._state.notifyDataChanged( EVENT_NAMES.MANUAL_ACCOUNT_UPDATED, oldCorrespondingAccount );
          }

          this.manualAccounts = [ ...this._state.globalVars.allManualAccountsFromDB ];
          this.updateExistingForm( resp.data );
          this._state.notifyDataChanged( EVENT_NAMES.MANUAL_ACCOUNT_UPDATED, resp.data );
          if ( callback ) {
            callback();
          }
          this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );

        }, error: ( err ) => {
          console.error( err.err );
          this.existingAccountForms[ account.account_id ].saveButtonOptions.active = false;
          if ( callback ) {
            callback( err );
          }
        },
      } );

  }

  deleteManualAccount( account: Account, callback?: Function ) {
    // console.log( 'deleting manual account...' );
    this.existingAccountForms[ account.account_id ].removeButtonOptions.active = true;
    this._gdService.deleteManualAccount( account )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // console.log( resp );
          this.snackBar.open( 'Manual Account Deleted', null, Util.getSnackBarOptions() );
          this._accountManager.removeManualAccountFromLists( account );
          this.removeExistingAccountForm( account );
          if ( callback ) {
            callback();
          }
          this._state.notifyDataChanged( EVENT_NAMES.MANUAL_ACCOUNT_DELETED, resp.data.account_id );
          this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
          this.manualAccounts = [ ...this._state.globalVars.allManualAccountsFromDB ];
        }, error: ( err ) => {
          console.error( err.err );
          this.existingAccountForms[ account.account_id ].removeButtonOptions.active = false;
          this.snackBar.open( `Error removing manual account. ${ Util.getRefCodeSupportString( err.refCode ) }`, null, Util.getSnackBarOptions( true ) );
          if ( callback ) {
            callback( err );
          }
        },
      } );
  }

  static setFieldsForAccountAndPosition( manualAccount: any, manualPosition: any, form: FormGroup<any>, treasuryRates: any ) {

    manualAccount.included = form.controls.included.value;
    manualAccount.value = FormsUtil.getSanitizedFloatValue( form.controls.value.value );
    manualAccount.name = form.controls.name ? form.controls.name.value : '';
    manualAccount.description = form.controls.description ? form.controls.description.value : '';
    manualAccount.owner_type = form.controls.owner_type ? form.controls.owner_type.value : '';
    manualAccount.institution_name = form.controls.institution_name ? form.controls.institution_name.value : '';
    manualAccount.corresponding_asset_id = form.controls.corresponding_asset_id?.value ? form.controls.corresponding_asset_id.value : null;
    manualAccount.corresponding_liability_id = form.controls.corresponding_liability_id?.value ? form.controls.corresponding_liability_id.value : null;

    // manual account fees
    manualAccount.management_fee = form.controls.management_fee.value;

    manualPosition = ManualAccountSaveUtil.setupBlankManualPosition( manualPosition );

    // common fields to all new manuals positions
    if ( form.controls.cost_basis ) {
      manualPosition.cost_basis = FormsUtil.getSanitizedFloatValue( form.controls.cost_basis.value );
    }


    /* INVESTMENT */
    if ( ManualAccountUtil.typeIsInvestment( manualAccount.account_type ) ) {
      const investmentAccountFormValues: ManualInvestmentAccountFormValues = form.getRawValue();
      ManualAccountSaveUtil.prepareInvestmentAccount( manualAccount, investmentAccountFormValues, manualPosition );
    } else {
      /* REAL ASSET */
      if ( ManualAccountUtil.typeIsRealAsset( manualAccount.account_category, manualAccount.account_type ) ) {
        const realAssetAccountFormValues: ManualRealAssetAccountFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareRealAsset( manualAccount, realAssetAccountFormValues, manualPosition );
      }
      /* CRYPTO */
      if ( ManualAccountUtil.typeIsCryptoAccount( manualAccount.account_type ) ) {
        const cryptoAccountFormValues: ManualCryptoAccountFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareCrypto( manualAccount, cryptoAccountFormValues, manualPosition );
      }
      /* STOCK */
      if ( ManualAccountUtil.typeIsStock( manualAccount.account_type ) ) {
        const stockAccountFormValues: ManualStockAccountFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareStock( manualAccount, stockAccountFormValues, manualPosition );
      }
      /* STOCK OPTION */
      if ( ManualAccountUtil.typeIsStockOption( manualAccount.account_type ) ) {
        const stockOptionAccountFormValues: ManualStockOptionFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareStockOption( stockOptionAccountFormValues, manualPosition );
      }
      /* ANNUITY */
      if ( ManualAccountUtil.typeIsAnnuity( manualAccount.account_type ) ) {
        const annuityFormValues: ManualAnnuityFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareAnnuity( manualAccount, annuityFormValues, manualPosition );
      }
      /* TERM LIFE INSURANCE */
      if ( ManualAccountUtil.typeIsTermLifeInsurance( manualAccount.account_type ) ) {
        const termLifeInsuranceValues: ManualTermLifeInsuranceFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareTermLifeInsurance( manualAccount, termLifeInsuranceValues, manualPosition, treasuryRates );
      }
      /* MORTGAGE/AUTO LOAN AND BOND */
      if ( ManualAccountUtil.typeIsLoan( manualAccount.account_type ) ||
        ManualAccountUtil.typeIsBond( manualAccount.account_type ) ||
        ManualAccountUtil.typeIsPrivateLending( manualAccount.account_type ) ) {
        const loanOrBondValues: ManualLoanFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareLoan( manualAccount, loanOrBondValues, manualPosition );
      }
      /* BANK ACCOUNT */
      if ( ManualAccountUtil.typeIsBankAccount( manualAccount.account_category, manualAccount.account_type )
        || ManualAccountUtil.typeIsCashAccount( manualAccount.account_type ) ) {
        const bankValues: ManualBankFormValues = form.getRawValue();
        ManualAccountSaveUtil.prepareBankAccount( manualAccount, bankValues, manualPosition );
      }

      manualAccount.positions = [ manualPosition ];
    }

  }

  /**
   *
   * @param existingAccount - account before update
   * @param accountWithChanges - account after update
   * @returns null if there wasn't a corresponding account, otherwise returns the corresponding account id,
   * so it can be used to update that account in case it is pointing back
   */
  static didAccountHaveCorrespondingAccount( existingAccount: Account, accountWithChanges: Account ) {
    if ( existingAccount.corresponding_liability_id && !accountWithChanges.corresponding_liability_id ) {
      return existingAccount.corresponding_liability_id;
    }
    if ( existingAccount.corresponding_asset_id && !accountWithChanges.corresponding_asset_id ) {
      return existingAccount.corresponding_asset_id;
    }

    return null;
  }

  // Validate a numeric value > 0 if the control is used
  static isFloatGreaterThanZeroValidator(): ValidatorFn {
    return ( c: AbstractControl ) => {
      if ( c.pristine ) {
        return null;
      } else {
        if ( parseFloat( c.value ) > 0 ) {
          return null;
        } else {
          return {
            isFloatGreaterThanZeroValidator: {
              valid: false,
            },
          };
        }
      }
    };
  }

  // Validate date format.  First check if the control is used
  static isFutureDateValidator(): ValidatorFn {
    return ( c: AbstractControl ) => {
      if ( c.pristine ) {
        return null;
      } else {
        const m = moment( c.value );
        if ( m.isValid() && m.isAfter( moment().startOf( 'date' ) ) ) {
          return null;
        } else {
          return {
            isFutureDateValidator: {
              valid: false,
            },
          };
        }
      }
    };
  }

  // Validate percent range between 0 and 30 percent.  First check if the control is used
  static isValidPctRangeValidator(): ValidatorFn {
    return ( c: AbstractControl ) => {
      if ( c.pristine ) {
        return null;
      } else {
        // console.log( c.value );
        const sanitizedValue = FormsUtil.getSanitizedFloatValue( c.value, true );
        // console.log( sanitizedValue );
        if ( sanitizedValue >= 0 && sanitizedValue <= 0.3 ) {
          return null;
        } else {
          return {
            isValidPctRangeValidator: {
              valid: false,
            },
          };
        }
      }
    };
  }

  static investmentPositionsLengthNonZero( editing?: boolean ): ValidatorFn {
    return ( c: AbstractControl ) => {
      if ( editing ) {
        return null;
      }
      if ( c.pristine ) {
        return null;
      } else {
        if ( c.value instanceof Array ) {
          return {
            investmentPositionsLengthNonZero: {
              valid: c.value?.length === 0,
            },
          };
        } else {
          return null;
        }
      }
    };
  }

  getFormType( account: Account ): string {
    if ( ManualAccountUtil.typeIsRealAsset( account.account_category, account.account_type ) ) {
      return 'realAsset';
    } else if ( ManualAccountUtil.typeIsStock( account.account_type ) ) {
      return 'stock';
    } else if ( ManualAccountUtil.typeIsStockOption( account.account_type ) ) {
      return 'stockOption';
    } else if ( ManualAccountUtil.typeIsBond( account.account_type ) ) {
      return 'bond';
    } else if ( ManualAccountUtil.typeIsBankAccount( account.account_category, account.account_type ) ) {
      return 'bank';
    } else if ( ManualAccountUtil.typeIsCashAccount( account.account_type ) ) {
      return 'cash';
    } else if ( ManualAccountUtil.typeIsCryptoAccount( account.account_type ) ) {
      return 'crypto';
    } else if ( ManualAccountUtil.typeIsLoan( account.account_type ) || ManualAccountUtil.typeIsPrivateLending( account.account_type ) ) {
      return 'loan';
    } else if ( ManualAccountUtil.typeIsInvestment( account.account_type ) ) {
      return 'investment';
    } else if ( ManualAccountUtil.typeIsAnnuity( account.account_type ) ) {
      return 'annuity';
    } else if ( ManualAccountUtil.typeIsTermLifeInsurance( account.account_type ) ) {
      return 'term-life-insurance';
    } else {
      return '';
    }
  }

  openAccountPanel( account_id: string ) {
    const account = this.manualAccounts.find( ( a: any ) => {
      return a.account_id === account_id;
    } );
    account.expanded = true;
  }

  toggleIncluded( account: any, form: any, callback?: Function ) {
    this.updateManualAccount( account, form, callback );
  }

  static patchStockForm( form: UntypedFormGroup, stockInfo: any ) {
    form.patchValue( {
      us_non_us: stockInfo.us === 1 ? 'us' : stockInfo.non_us === 1 ? 'non_us' : '',
      company_market_cap: this.translateMarketCapInfo( stockInfo ),
      growth_value: this.translateValueBlendGrowthInfo( stockInfo ),
      sector: this.translateSectorInfo( stockInfo ),
    } );
    if ( form.controls.us_non_us.value === 'non_us' ) {
      form.controls.emerging_markets.setValue( stockInfo.emerging_markets );
    }
  }

  static translateMarketCapInfo( stockInfo ) {
    if ( stockInfo.large_cap === 1 ) {
      return 'large';
    }
    if ( stockInfo.mid_cap === 1 ) {
      return 'mid';
    }
    if ( stockInfo.small_cap === 1 ) {
      return 'small';
    }
  }

  static translateValueBlendGrowthInfo( stockInfo ) {
    if ( stockInfo.value_stocks === 1 ) {
      return 'value_stocks';
    }
    if ( stockInfo.blend_stocks === 1 ) {
      return 'blend_stocks';
    }
    if ( stockInfo.growth_stocks === 1 ) {
      return 'growth_stocks';
    }
  }

  static translateSectorInfo( stockInfo ) {
    const sectors = [ 'sector_basic_materials',
      'sector_communication_services',
      'sector_consumer_cyclical',
      'sector_consumer_defensive',
      'sector_energy',
      'sector_financial_services',
      'sector_healthcare',
      'sector_industrials',
      'sector_real_estate',
      'sector_technology',
      'sector_utilities',
    ];

    for ( const sector of sectors ) {
      if ( stockInfo[ sector ] === 1 ) {
        return sector;
      }
    }
  }

  static translateDividend( last_dividend: any ) {
    if ( last_dividend ) {
      switch ( last_dividend.PaymentFrequency ) {
        case 'Monthly':
          return FormsUtil.getSanitizedFloatValue( last_dividend.DividendAmount ) * 12;
        case 'Quarterly':
          return FormsUtil.getSanitizedFloatValue( last_dividend.DividendAmount ) * 4;
        case 'SemiAnnual':
          return FormsUtil.getSanitizedFloatValue( last_dividend.DividendAmount ) * 2;
        case 'Annual':
          return FormsUtil.getSanitizedFloatValue( last_dividend.DividendAmount );
        case 'Initial':
        case 'Resumption':
        case 'Interim':
        case 'Other':
        case 'Final':
          return FormsUtil.getSanitizedFloatValue( last_dividend.DividendAmount );
        // case 'None':
        // case 'Unknown':
        default:
          return null;
        /*
         list of possible frequencies according to xignite docs
         Monthly
         Quarterly
         SemiAnnual
         Annual
         Initial
         Resumption
         Interim
         Other
         Final
         None
         Unknown
         DefaultValue
         * */
      }
    }
    return null;
  }

  static clearStockForm( form: UntypedFormGroup ) {
    form.patchValue( {
      us_non_us: '',
      annualized_yield: '',
      company_market_cap: '',
      emerging_markets: '',
      sector: '',
      growth_value: '',
      dividend_per_share: '',
    } );

  }

  static clearStockOptionForm( form: UntypedFormGroup ) {
    form.patchValue( {
      underlying_stock: '',
      underlying_price: '',
      price_per_share: '',
      shares_per_option: 100,
      volatility: '',
      dividend_list: '',
      call_put: '',
      exercise_price: '',
      maturity_date: '',
      treasury_rate: '',

    } );
  }

  quickManualAccountUpdate( account: Account ) {
    const copy = _.cloneDeep( account );

    this._gdService.updateManualAccount( copy )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this._accountManager.updateManualAccountFields( resp );
          Logger.info( 'manual account updated in background successfully' );
        },
        error: ( err ) => {
          Logger.warn( 'Error updating manual account in the background: ' );
          Logger.warn( err, true );
        },
      } );
  }

}
