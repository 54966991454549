import { AfterViewInit, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Util } from '../../utils/util.service';
import { ManualAccountUtil } from '../../utils/manualAccount.util';
import { TreasuryRatesUtil } from '../../utils/treasury-rates.util';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-insurance-calculator',
  templateUrl: './insurance-calculator.component.html',
  styleUrls: [ './insurance-calculator.component.scss' ],
} )

export class InsuranceCalculatorComponent implements AfterViewInit {

  @Input() matcher: ErrorStateMatcher;

  form: FormGroup;

  calculatedInsurance: number;

  constructor( private treasuryRateUtil: TreasuryRatesUtil ) {
    this.form = new FormGroup<any>( {
      // at some point we will pull the user's income from their profile if they provided it as the default for this income field
      'income': new FormControl<number>( null, Validators.required ),
      'income_growth': new FormControl<number>( null ),
      'term': new FormControl<number>( null, Validators.required ),
    } );
    Util.updateInputPercentFormat( 'income_growth', this.form );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.form.markAsPristine();
    } );
  }

  getSumOfPVs() {
    const sanitizedValues = this.sanitizeRawValues();
    if ( sanitizedValues.income > 0 && sanitizedValues.term > 0 ) {
      this.calculatedInsurance = ManualAccountUtil.calcLifeInsuranceAmount(
        sanitizedValues.income,
        sanitizedValues.income_growth,
        sanitizedValues.term,
        this.treasuryRateUtil.treasuryRates,
      );
    }
  }

  sanitizeRawValues(): any {
    const vals = this.form.getRawValue();
    return {
      income: FormsUtil.getSanitizedFloatValue( vals.income ),
      income_growth: FormsUtil.getSanitizedFloatValue( vals.income_growth ?? 0, true ),
      term: FormsUtil.getSanitizedFloatValue( vals.term ),
    };
  }

  growthRateChanged() {
    Util.updateInputPercentFormat( 'income_growth', this.form );
  }


  ignoreEnter( event: any ) {
    event?.preventDefault();
    event?.target?.blur();
  }
}
