import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { GlobalState } from '../../../global.state';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { Logger } from '../../../utils/logger.service';


// ===================================================================================================================
// CHANGES MADE TO THIS FILE MAY REQUIRE CORRESPONDING CHANGES TO THE DESKTOP VERSION (~/src/app/reusableWidgets/oneDayBreakdownDialog/one-day-breakdown.component.ts)
// ===================================================================================================================

@Component( {
  selector: 'rip-mobile-one-day-change',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      
    </div>
    <div class="bottom-sheet-content">
      <div id="daily-perf-header">
        <div></div>
        <div>Portfolio Return</div>
        <div style="text-align: center;">{{ yourPortfolioTotal | ripCurrencyPipe }}</div>
        <div class="benchmark-line" style="font-size: 2rem;">-</div>
        <div class="benchmark-line"> Benchmark Return</div>
        <div class="benchmark-line" style="text-align: center;">{{ yourBenchmarkTotal | ripCurrencyPipe }}</div>
        <div class="daily-return-line">=</div>
        <div class="daily-return-line">Net of Benchmark Performance</div>
        <div class="daily-return-line" style="border: 2px solid; padding: 10px; text-align: center;">
              <span
                [ngClass]="{ 'up': yourPortfolioTotal - yourBenchmarkTotal > 0, 'down': yourPortfolioTotal - yourBenchmarkTotal < 0}">
                {{ yourPortfolioTotal - yourBenchmarkTotal | ripCurrencyPipe }}
              </span>
        </div>
      </div>
      <div id="one-day-breakdowns-container">
        <div class="one-day-breakdown-container">
          <div class="mobile-breakdown-header">Your Portfolio</div>
          <ngx-datatable [rows]="portfolioOneDayBreakdownRows"
                         [columns]="portfolioOneDayBreakdownColumns"
                         [columnMode]="'force'"
                         [headerHeight]="tableHeaderHeight"
                         [rowHeight]="tableRowHeight"
                         [reorderable]="false"
                         [messages]="oneDayBreakdownTableMessages"
                         [cssClasses]="oneDayBreakdownTableCssClasses"
                         [footerHeight]="50"
                         class="material striped one-day-breakdown">
          </ngx-datatable>
        </div>
        <div class="one-day-breakdown-container">
          <div class="mobile-breakdown-header">Your Benchmark</div>
          <ngx-datatable [rows]="benchmarkOneDayBreakdownRows"
                         [columns]="benchmarkOneDayBreakdownColumns"
                         [columnMode]="'force'"
                         [headerHeight]="tableHeaderHeight"
                         [rowHeight]="tableRowHeight"
                         [reorderable]="false"
                         [messages]="oneDayBreakdownTableMessages"
                         [cssClasses]="oneDayBreakdownTableCssClasses"
                         [footerHeight]="50"
                         class="material striped one-day-breakdown">
          </ngx-datatable>
          <ng-template ngx-datatable-cell-template #oneDayChangeTemplate let-row="row" let-rowIndex="rowIndex">
            <div *ngIf="row.percentChange && row.security_type !== 'Money Market' && row.security_type !== 'Money Market Fund'" class="one-day-change"
                 [ngClass]="{
          'up': row.percentChange > 0,
          'down': row.percentChange < 0
        }">
              <div class="flexing space-between">
                <span>
                  <span *ngIf="row.percentChange > 0">
                    <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
                  </span>
                  <span *ngIf="row.percentChange < 0">
                      <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
                  </span>
                  {{row.dollarChange | ripCurrencyPipe}}
                </span>
                <!--                <span>/</span>-->
                {{row.percentChange | ripLongPercentPipe }}
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  `,
  styleUrls: [ '../mobileLayout.scss' ],
} )

export class MobileOneDayChangeComponent implements OnDestroy, AfterViewInit {

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;

  @ViewChild( 'oneDayChangeTemplate', { static: false } ) oneDayChangeTemplate: TemplateRef<any>;

  portfolioOneDayBreakdownRows: any[] = [];
  portfolioOneDayBreakdownColumns: any[] = [];

  benchmarkOneDayBreakdownRows: any[] = [];
  benchmarkOneDayBreakdownColumns: any[] = [];

  oneDayBreakdownTableMessages = {
    // Message to show when array is presented
    // but contains no values
    emptyMessage: 'No Holdings With One Day Breakdown',

    // Footer total message
    totalMessage: 'Holdings',

    // Footer selected message
    selectedMessage: 'Selected',
  };

  oneDayBreakdownTableCssClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip',
  };

  tableHeaderHeight: number = 30;
  tableRowHeight: number = 60;

  yourPortfolioTotal: number;
  yourBenchmarkTotal: number;
  today: Date = new Date();

  subscriberName: string = 'MobileOneDayChangeComponent';

  constructor( private _state: GlobalState ) {
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this._state.subscribe( 'notify.mobile.one.day.component', ( obj: any ) => {
        this.setRowsAndColumns( obj );
      }, this.subscriberName );

      if ( this._state.globalVars.allocWidget && this._state.globalVars.allocWidget.portfolioOneDayBreakdownRows &&
        this._state.globalVars.allocWidget.portfolioOneDayBreakdownRows.length > 0 ) {
        const widget = this._state.globalVars.allocWidget;
        this.setRowsAndColumns( widget );
      }
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'notify.mobile.one.day.component', this.subscriberName );
  }

  setRowsAndColumns( src: any ) {
    // reset the totals
    this.yourPortfolioTotal = 0;
    this.yourBenchmarkTotal = 0;

    // set the local vars
    this.portfolioOneDayBreakdownRows = src.portfolioOneDayBreakdownRows;
    this.portfolioOneDayBreakdownColumns = src.portfolioOneDayBreakdownColumns
      .filter( ( col: any ) => {
        return col.prop !== 'ticker_name';
      } );
    this.benchmarkOneDayBreakdownRows = src.benchmarkOneDayBreakdownRows;
    this.benchmarkOneDayBreakdownColumns = src.benchmarkOneDayBreakdownColumns
      .filter( ( col: any ) => {
        return col.prop !== 'market_sector';
      } );

    // add the one-day change template
    this.waitForTemplate();

    // loop through the rows and do the totals and one day change transform
    for ( const x of this.portfolioOneDayBreakdownRows ) {
      x.dollarChange = x.dollarChange ?? x.dollar;
      x.percentChange = x.percentChange ?? x.percent;
      this.yourPortfolioTotal += x.dollarChange;
    }
    for ( const y of this.benchmarkOneDayBreakdownRows ) {
      y.dollarChange = y.dollarChange ?? y.dollar;
      y.percentChange = y.percentChange ?? y.percent;
      this.yourBenchmarkTotal += y.dollarChange;
    }
  }

  waitForTemplate() {
    if ( this.oneDayChangeTemplate ) {
      this.benchmarkOneDayBreakdownColumns[1].cellTemplate = this.oneDayChangeTemplate;
      this.portfolioOneDayBreakdownColumns[1].cellTemplate = this.oneDayChangeTemplate;
    } else {
      setTimeout( () => {
        Logger.info( 'one day change template not yet available, trying again in 500ms' );
        this.waitForTemplate();
      }, 500 );
    }
  }

}
