<!-- Headers-->
<div class="form-group" *ngIf="type === 'Stock'">
    <div class="flexing">
        <fa-icon [icon]="faAnalytics" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
        <h3 class="manual-account-form-H3" style="padding-bottom: 10px;">Add the details about your single stock
            investment</h3>

    </div>
    This account type can be used for private business ownership, public or private stocks
</div>


<div class="form-group" *ngIf="type === 'Restricted Stock'">

    <div class="flexing">
        <fa-icon [icon]="faAnalytics" size="2x" class="manual-account-icon">
        </fa-icon>
        <fa-icon [icon]="faLockAlt" size="2x" class="manual-account-icon" style="margin-right: 5px;">
        </fa-icon>
        <h3 class=" manual-account-form-H3" style="padding-bottom: 10px;">Add the details about your restricted
            stock</h3>

    </div>
    This account type can be used for private business ownership, public or private stocks with restrictions
</div>


<div class="form-group">
    <div>
        Fields with an * are required
    </div>
</div>
<div *ngIf="form">
    <form [formGroup]="form" #formElement>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="name"
                           [errorStateMatcher]="matcher"
                           placeholder="Stock / Account Name / Description">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required')">
                    Name is required
                </mat-error>
                <mat-label>
                    "Stock / Account Name / Description"
                </mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Owner Type"
                            formControlName="owner_type"
                            [errorStateMatcher]="matcher">
                    <mat-option value="Individual Account">Individual Account</mat-option>
                    <mat-option value="Joint Account">Joint Account</mat-option>
                    <mat-option value="Trust Account">Trust Account</mat-option>
                </mat-select>
                <mat-label>Owner Type</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group flexing">
            <rip-security-query-box
                    style="flex: 1;" #queryBox
                    (fundSelected)="getPublicStockDetails($event)"
                    (changed)="tickerChanged($event)"
                    [ignore]="'funds'"
                    [startingValue]="form.controls.ticker.value"
                    [placeholder]="'Stock Ticker / Proxy Ticker / Identifier'">
            </rip-security-query-box>
            <fa-icon [icon]="faInfoCircle"
                     class="form-info"
                     [fixedWidth]="true"
                     size="sm"
                     [nbPopover]="proxyTickerPopContent"
                     nbPopoverClass="rip-popover">
            </fa-icon>

        </div>

        <!--<div class="form-group">
           <mat-form-field appearance="outline">
            <label>
              <input matInput
                     formControlName="ticker"
                     [errorStateMatcher]="matcher"
                     placeholder="Stock Ticker / Proxy Ticker / Identifier"
                     (change)="getPublicStockDetails( {} )">
            </label>
            <mat-error *ngIf="form.controls.ticker.hasError('required')">
              Ticker is required
            </mat-error>
          </mat-form-field>
        </div>-->
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="US/Non US"
                            formControlName="us_non_us"
                            [errorStateMatcher]="matcher">
                    <mat-option value="us">US</mat-option>
                    <mat-option value="non_us">Non US</mat-option>
                </mat-select>
                <mat-label>US/Non US</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group" *ngIf="form.controls.us_non_us.value === 'non_us'">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Non US Developed/Emerging Markets"
                            formControlName="emerging_markets"
                            [errorStateMatcher]="matcher">
                    <mat-option value=0>Non US Developed</mat-option>
                    <mat-option value=1>Emerging Markets</mat-option>
                </mat-select>
                <mat-label>Non US Developed/Emerging Markets</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select
                        formControlName="company_market_cap"
                        [errorStateMatcher]="matcher"
                        placeholder="Company Market Capitalization">
                    <mat-option value="large">Large (More than $8 Billion)</mat-option>
                    <mat-option value="mid">Mid ($1 to $8 Billion)</mat-option>
                    <mat-option value="small">Small (Less than $1 Billion)</mat-option>
                </mat-select>
                <mat-label>Company Market Capitalization</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Stock Sector"
                            formControlName="sector"
                            panelClass="max-select-box-height"
                            [errorStateMatcher]="matcher">
                    <mat-option value="sector_basic_materials">Basic Materials</mat-option>
                    <mat-option value="sector_communication_services">Communication Services</mat-option>
                    <mat-option value="sector_consumer_cyclical">Consumer Cyclical</mat-option>
                    <mat-option value="sector_consumer_defensive">Consumer Defensive</mat-option>
                    <mat-option value="sector_energy">Energy</mat-option>
                    <mat-option value="sector_financial_services">Financial Services</mat-option>
                    <mat-option value="sector_healthcare">Healthcare</mat-option>
                    <mat-option value="sector_industrials">Industrials</mat-option>
                    <mat-option value="sector_real_estate">Real Estate</mat-option>
                    <mat-option value="sector_technology">Technology</mat-option>
                    <mat-option value="sector_utilities">Utilities</mat-option>
                </mat-select>
                <mat-label>Stock Sector</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select
                        formControlName="growth_value"
                        [errorStateMatcher]="matcher"
                        placeholder="Value/Growth/Blend Stocks">
                    <mat-option value="value_stocks">Value</mat-option>
                    <mat-option value="blend_stocks">Blend</mat-option>
                    <mat-option value="growth_stocks">Growth</mat-option>
                </mat-select>
                <mat-label>Value/Growth/Blend Stocks</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput #price
                           formControlName="price"
                           [errorStateMatcher]="matcher"
                           placeholder="Price Per Share ($)"
                           (change)="setPrice(price.value);computeValue();calcYield();calcDividend()">
                </label>
                <mat-error *ngIf="form.controls.price.hasError('required')">
                    Price is required
                </mat-error>
                <mat-label>Price Per Share ($)</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="quantity"
                           [errorStateMatcher]="matcher"
                           placeholder="Quantity (Number of shares Owned)"
                           (change)="quantityChanged()">
                </label>
                <mat-error *ngIf="form.controls.quantity.hasError('required' )">
                    Quantity is required
                </mat-error>
                <mat-label>Quantity</mat-label>
            </mat-form-field>
        </div>
        <!-- Will calc based on price and quantity -->
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="value"
                           [errorStateMatcher]="matcher"
                           placeholder="Market Value ($)"
                           (change)="valueChanged()">
                </label>
                <mat-error *ngIf="form.controls.value.hasError('required' )">
                    Market Value is required
                </mat-error>
                <mat-label>Market Value ($)</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="cost_basis"
                           [errorStateMatcher]="matcher"
                           placeholder="Cost Basis ($)"
                           (change)="costBasisChanged()">
                </label>
                <mat-label>Cost Basis ($)</mat-label>
            </mat-form-field>
        </div>
        <!-- dividend_per_share can be used to get yield and vice versa-->
        <div class="form-group">
            <div class="row"></div>
            <mat-form-field appearance="outline">
                <label>
                    <input matInput #dividendAmt
                           formControlName="dividend_per_share"
                           [errorStateMatcher]="matcher"
                           placeholder="Annual Dividend Per Share ($)"
                           (change)="setDividend(dividendAmt.value);calcYield();calcPrice()">
                </label>
                <mat-label>Annual Dividend Per Share ($)</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput #yieldPct
                           formControlName="annualized_yield"
                           [errorStateMatcher]="matcher"
                           placeholder="Yield (Annual %)"
                           (change)="setYield(yieldPct.value);calcDividend();calcPrice()">
                </label>
                <mat-label>Yield (Annual %)</mat-label>
            </mat-form-field>
        </div>
    </form>
</div>


<!--proxy security popover-->
<ng-template #proxyTickerPopContent>
    <nb-card class="popover-card">
        <nb-card-header class="popover-title">
            Proxy Ticker
        </nb-card-header>
        <nb-card-body>
            <div class="popover-content">
                You can choose a proxy for any security. All the fields in the security will be populated with those of
                the proxy. This allows you to track the proxy instead (or because your security is not public and it's
                details cannot be retrieved).
            </div>
        </nb-card-body>
    </nb-card>

</ng-template>
