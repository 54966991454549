import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RipsawDecimalPipe, RipsawPercentPipe } from '../../../theme/pipes';
import { TableColumn } from '@swimlane/ngx-datatable';
import { BenchmarkData, RiskReturn } from '../../../utils/dataInterfaces';
import { BenchmarkUtil } from '@ripsawllc/ripsaw-analyzer';
import { MarketCalcTerms } from '../../../utils/enums';
import { GlobalState } from '../../../global.state';
import { BenchmarkState } from '../../../utils/benchmark.state';

export enum ForwardLookingColumnHeaders {
  identifier = 'Benchmark Group',
  label = 'Proxy',
  weight = 'Investment Weight',
  expectedReturn = 'Expected Return (%)',
  standardDeviation = 'Annualized Standard Deviation (%)',
  riskContribution = 'Security Risk Contribution (%)',
}

@Component( {
  selector: 'rip-benchmark-forward-looking',
  template: `
    <nb-card>
      <nb-card-header class="flexing-row-only space-between">
        Forward Looking
        <mat-button-toggle-group [(value)]="term" (valueChange)="setTerm()">
          <mat-button-toggle [value]="termShort">
            Short Term
          </mat-button-toggle>
          <mat-button-toggle [value]="termLong">
            Long Term
          </mat-button-toggle>
        </mat-button-toggle-group>
      </nb-card-header>
      <nb-card-body class="no-padding-card-body no-scroll-horizontal">
        <div class="forward-looking-table">
          <ngx-datatable
            [rows]="rows"
            [columns]="columns"
            [rowHeight]="40"
            [headerHeight]="45"
            [columnMode]="'force'"
            class="material striped">

          </ngx-datatable>
        </div>
      </nb-card-body>
    </nb-card>
  `,
} )

export class BenchmarkForwardLookingComponent implements OnDestroy, AfterViewInit {

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  _pipe: any = { transform: val => this.ripDecimalPipe.transform( val, '2-2' ) };

  @Input() riskReturns: RiskReturn[] = [];
  @Input() benchmarkData: BenchmarkData;
  @Input() allProxies: any;
  @Input() cashBondStockWeightMap: any;

  @Output() termChanged: EventEmitter<any> = new EventEmitter<any>();

  term: MarketCalcTerms = MarketCalcTerms.long;
  termShort: MarketCalcTerms = MarketCalcTerms.short;
  termLong: MarketCalcTerms = MarketCalcTerms.long;

  longTermColumns: TableColumn[] = [
    {
      prop: 'identifier',
      name: ForwardLookingColumnHeaders.identifier,
      minWidth: 215,
    },
    // {
    //   prop: 'label',
    //   name: ForwardLookingColumnHeaders.label,
    // },
    {
      prop: 'weight',
      name: ForwardLookingColumnHeaders.weight,
      pipe: this.ripPercentPipe,
    },
    {
      prop: 'ltEr',
      name: ForwardLookingColumnHeaders.expectedReturn,
      pipe: this.ripPercentPipe,
    },
    // {
    //   prop: 'ltSlope',
    //   name: 'Beta/Duration',
    //   pipe: this.ripDecimalPipe,
    // },
    {
      prop: 'ltSd',
      name: ForwardLookingColumnHeaders.standardDeviation,
      pipe: this.ripPercentPipe,
    },
    // {
    //   prop: 'longTermRB',
    //   name: ForwardLookingColumnHeaders.riskContribution,
    //   pipe: this.ripPercentPipe,
    // },
  ];
  shortTermColumns: TableColumn[] = [
    {
      prop: 'identifier',
      name: ForwardLookingColumnHeaders.identifier,
      minWidth: 215,
    },
    // {
    //   prop: 'label',
    //   name: ForwardLookingColumnHeaders.label,
    // },
    {
      prop: 'weight',
      name: ForwardLookingColumnHeaders.weight,
      pipe: this.ripPercentPipe,
    },
    {
      prop: 'er',
      name: ForwardLookingColumnHeaders.expectedReturn,
      pipe: this.ripPercentPipe,
    },
    // {
    //   prop: 'slope',
    //   name: 'Beta/Duration',
    //   pipe: this.ripDecimalPipe,
    // },
    {
      prop: 'sd',
      name: ForwardLookingColumnHeaders.standardDeviation,
      pipe: this.ripPercentPipe,
    },
    // {
    //   prop: 'oneYearRB',
    //   name: ForwardLookingColumnHeaders.riskContribution,
    //   pipe: this.ripPercentPipe,
    // },
  ];

  rows = [];
  columns: TableColumn[] = [];

  subscriberName: string = 'benchmarkForwardLookingTableComponent';

  constructor( private _state: GlobalState, public benchmarkState: BenchmarkState ) {

    [ ...this.shortTermColumns, ...this.longTermColumns ].forEach( ( col ) => {
      col.sortable = false;
    } );
    this.columns = this.shortTermColumns;

    _state.subscribe( 'setup.benchmark.fl.table', () => {
      this.setupRows();
    }, this.subscriberName );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.setupRows();
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( 'setup.benchmark.fl.table', this.subscriberName );
  }

  setTerm() {
    if ( this.term === MarketCalcTerms.short ) {
      this.columns = this.shortTermColumns;
    } else {
      this.columns = this.longTermColumns;
    }
    this.benchmarkState?.setTerm( this.term );
  }

  setupRows() {
    if ( this.riskReturns.length > 0 && this.benchmarkData && this.cashBondStockWeightMap ) {

      const weightsVector: number[] = this.riskReturns.map( ( rr ) => {
        return this.cashBondStockWeightMap[rr.identifier];
      } );

      let totalWeight = 0;
      let totalContribution = 0;
      let totalLongTermContribution = 0;
      this.rows = [];

      this.rows = this.riskReturns.map( rr => {

        const proxyPortfolioWeight = this.cashBondStockWeightMap[rr.identifier];
        const contributions = BenchmarkUtil.getContribution( rr, weightsVector, proxyPortfolioWeight );


        totalContribution += contributions.contribution;
        totalLongTermContribution += contributions.longTermContribution;

        const er = rr.expectedReturn / 100; // expectedReturn is in percentage
        const ltEr = /*rr.label.includes( 'Cash' ) ? rr.longTermExpectedReturn / 100 :*/ rr.longTermExpectedReturn; // only cash not in decimal already

        const stdDev = ( rr.annualizedStandardDeviation ?? rr.updatedStandardDeviation ) / 100; // have to divide by 100 because the pipe will multiply
        /*if ( rr.label.includes( 'Cash' ) ) {
          stdDev *= 100; // cash std dev is already in decimal
        }*/

        totalWeight += proxyPortfolioWeight;

        return {
          identifier: rr.identifier,
          label: rr.label,
          weight: proxyPortfolioWeight,
          er,
          slope: rr.vix ? rr.beta : rr.effectiveDuration,
          sd: stdDev,
          contribution: contributions.contribution,
          ltEr, // have to divide by 100 because the pipe will multiply
          ltSlope: rr.vix ? rr.longTermBeta : rr.longTermEffectiveDuration,
          ltSd: ( rr.longTermUpdatedStandardDeviation ?? rr.longTermAnnualizedStandardDeviation ) / 100, // have to divide by 100 because the pipe will multiply
          longTermContribution: contributions.longTermContribution,
        };
      } );

      let totalRiskBudget = 0, totalLongTermRiskBudget = 0;

      // need to loop through again cause the risk budget requires the totalContribution number
      this.rows.forEach( ( row ) => {
        row.oneYearRB = row.contribution / totalContribution;
        totalRiskBudget += row.oneYearRB;
        row.longTermRB = row.longTermContribution / totalLongTermContribution;
        totalLongTermRiskBudget += row.longTermRB;
      } );

      const totalExpectedReturns = BenchmarkUtil.getTotalExpectedReturns( this.rows, weightsVector );


      const bmPortfolio = {
        label: 'Benchmark Portfolio',
        identifier: 'BENCHMARK PORTFOLIO',
        weight: totalWeight,
        er: totalExpectedReturns?.total,
        sd: Math.sqrt( totalContribution ) / 100,
        contribution: totalContribution,
        oneYearRB: totalRiskBudget,
        ltEr: totalExpectedReturns?.longTermTotal,
        ltSd: Math.sqrt( totalLongTermContribution ) / 100,
        longTermContribution: totalLongTermContribution,
        longTermRB: totalLongTermRiskBudget,
      };

      this.rows.push( bmPortfolio );
      this.benchmarkState.bmPortfolio = bmPortfolio;
    }
  }


}
