import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenchmarkSetupComponent } from './benchmark-setup.component';
import { routing } from './benchmark-setup.routing';
import { BenchmarkSetupLayoutComponent } from '../../reusableWidgets/benchmark-setup-layout/benchmark-setup-layout.component';
import {
  NbAccordionModule,
  NbBadgeModule,
  NbButtonModule,
  NbPopoverModule,
  NbCardModule,
  NbLayoutModule,
  NbListModule,
  NbSpinnerModule,
  NbToggleModule,
} from '@nebular/theme';
import { NgaModule } from '../../theme/nga.module';
import { MatSliderModule } from '@angular/material/slider';
import { MatListModule } from '@angular/material/list';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { BenchmarkOptimizerService } from '../../reusableWidgets/benchmark-setup-layout/benchmark-optimizer.service';

const components = [
  BenchmarkSetupComponent,
];

const services = [
  BenchmarkOptimizerService,
];

const modules = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  NbCardModule,
  NbLayoutModule,
  NgaModule,
  MatSliderModule,
  NbSpinnerModule,
  MatListModule,
  NgxDatatableModule,
  NgxSliderModule,
  NbAccordionModule,
  FontAwesomeModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatButtonModule,
  NbAccordionModule,
  NbListModule,
  MatCardModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatIconModule,
  NbToggleModule,
  NbButtonModule,
  MatDialogModule,
  NbPopoverModule,
  NbBadgeModule,
  NgChartsModule,
  routing,
];

@NgModule( {
    declarations: [
        ...components,
    ],
    imports: [
        ...modules,
    ],
    providers: [
        ...services,
    ],
} )
export class BenchmarkSetupModule {
}
