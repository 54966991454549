<div class="form-group" id="insurance-calculator-container">
  <div class="ripsaw-card-header" style="padding-bottom: 25px;">
    Term Life Insurance Calculator
    <div class="ripsaw-card-subheader">
      How much Term Life Insurance do you want?
    </div>
  </div>
  <div [nbSpinner]="!form">
    <form [formGroup]="form">
      <div class="form-group">
        <rip-currency-input
                [control]="form.controls.income"
                [stepSize]="10000"
                [label]="'Current Annual Income (After Tax)'"
                [placeholder]="'$250,000.00'"
                [noNegative]="true">
        </rip-currency-input>
        <mat-error *ngIf="!form?.controls?.income?.pristine && form?.controls?.income?.hasError('required')">
          Income is required
        </mat-error>
        <div class="hint">
          Current Annual Income (after tax) can be the baseline for calculating a spending replacement income stream. A
          more conservative strategy might increase this level.

        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline">
          <label>
            <input matInput
                   (keydown.enter)="ignoreEnter($event)"
                   formControlName="income_growth"
                   [errorStateMatcher]="matcher"
                   placeholder="Income Growth Rate (%)"
                   (change)="growthRateChanged()"
                   type="text">
          </label>
          <mat-error *ngIf="form?.controls?.income_growth?.hasError('required')">
            Income Growth Rate is required
          </mat-error>
          <mat-label>Annual Income Growth Rate (%)*</mat-label>
        </mat-form-field>
        <div class="hint">
          Income Growth Rate may include a combination of inflation expectations, history of salary raises,
          expected growth in human capital by profession and individual achievement within their profession.
        </div>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline">
          <label>
            <input matInput
                   (keydown.enter)="ignoreEnter($event)"
                   formControlName="term"
                   [errorStateMatcher]="matcher"
                   placeholder="Term"
                   type="text">
          </label>
          <mat-error *ngIf="form?.controls?.term?.hasError('required')">
            Term is required
          </mat-error>
          <mat-label>Term (Years)</mat-label>
        </mat-form-field>
      </div>
    </form>

    <div class="flexing with-gap center-vertically">
      <button mat-raised-button [disabled]="form.invalid" (click)="getSumOfPVs()">Calculate</button>
      <div>Amount of insurance required:</div>
      <div>{{ calculatedInsurance | ripCurrencyPipe }}</div>
    </div>
    <div class="explainer">
      <div>
        Some things to consider in selecting the length of time for a Term Life Insurance policy:
      </div>
      <ul>
        <li>
          Number and age of dependents
        </li>
        <li>
          How long do you need to replace your earning stream for them
        </li>
        <li>
          Until children have achieved an undergraduate college degree? or graduate school degree?, until children are
          married?
        </li>
        <li>
          Magnitude of spouse income
        </li>
        <li>
          Your probability of death in each following year increases as you get older so that the actual Term Life
          Insurance premiums increase over time while smoothing premiums into an annuity obfuscates the fact that
          shorter term policies have lower premiums
        </li>
        <li>
          Your wealth accumulation is also likely to increase over time
        </li>
        <li>
          You might consider shifting to being self-insured when your estate is large enough
        </li>
        <li>
          The longer the term, the less likely you will be denied rolling over a shorter term life insurance policy if
          unforeseen negative health conditions arise
        </li>
        <li>
          Note the many tradeoffs in this decision process.
        </li>
      </ul>
    </div>
  </div>
</div>
