import {
  Component, ViewChild, AfterViewInit, Inject, ViewChildren, QueryList,
} from '@angular/core';
import { Auth } from '../../../auth.service';
import { GlobalState } from '../../../global.state';
import { BenchmarkService } from './benchmark.service';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BenchmarkHelpers } from './benchmarkHelpers';
import { GlobalDataService } from '../../../globalData';
import { AccountManager } from '../../../utils/accountManager';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { environment } from '../../../../environments/environment';
import { RipThemeLoadingSpinnerService } from '../../../theme/services';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { AddBenchmarkWeightsComponent } from './add-benchmark-weights/add-benchmark-weights.component';
import { NbStepperComponent } from '@nebular/theme';
import { EVENT_NAMES, SCREENER_TAB_NAMES } from '../../../utils/enums';

@Component( {
  selector: 'rip-benchmark-editor',
  styleUrls: [ './benchmarkEditor.scss' ],
  templateUrl: './benchmarkEditor.component.html',
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
} )

export class BenchmarkEditorComponent implements AfterViewInit {

  stocksTab: SCREENER_TAB_NAMES = SCREENER_TAB_NAMES.stocks;
  bondsTab: SCREENER_TAB_NAMES = SCREENER_TAB_NAMES.bonds;
  moneyMarketTab: SCREENER_TAB_NAMES = SCREENER_TAB_NAMES.moneyMarket;

  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;

  @ViewChildren( 'bondStepper' ) bondProxySteppers: QueryList<NbStepperComponent>;
  @ViewChildren( 'stockStepper' ) stockProxySteppers: QueryList<NbStepperComponent>;


  helpers: typeof BenchmarkHelpers;
  subscriberName: string = 'BenchmarkEditorComponent';

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _benchmarkService: BenchmarkService,
               private _gdService: GlobalDataService,
               private _auth: Auth,
               private snackBar: MatSnackBar,
               private _spinnerService: RipThemeLoadingSpinnerService,
               public dialog: MatDialog,
               private dialogRef: MatDialogRef<BenchmarkEditorComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               public benchmarkState: BenchmarkState ) {
    this.helpers = BenchmarkHelpers;
    if ( environment.env !== 'prod' ) {
      window['ripsaw_benchmarkEditor'] = this;
    }

    _state.subscribe( EVENT_NAMES.USER_BENCHMARK_SAVED, () => {
      this.closeModal();
    }, this.subscriberName );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.init();
    }, 500 );
  }

  init() {
    this.benchmarkState.dialogIsOpen = true;
    this.benchmarkState.benchmarkEditorOpened();
  }


  closeModal() {
    this.benchmarkState.dialogIsOpen = false;
    this._state.unsubscribe( EVENT_NAMES.USER_BENCHMARK_SAVED, this.subscriberName );
    this.benchmarkState.loadBenchmark( this.benchmarkState.benchmark, true );
    this.dialogRef.close();
  }

  /*
  * Open the modal for adding a new benchmark weight/sector
  * @param groupName {String} - name of the group that a weight/sector is being added to
  * */
  openBenchmarkWeightModal( groupName: string ) {
    const ref = this.dialog.open( AddBenchmarkWeightsComponent, {} );
    const sub = ref.afterClosed().subscribe( ( newWeight: any ) => {
      if ( newWeight ) {
        this.benchmarkState.addBenchmarkWeight( newWeight );
        setTimeout( () => {
          if ( groupName === 'bond' ) {
            this.bondProxySteppers.last.next();
          } else {
            this.stockProxySteppers.last.next();
          }
        } );
      }
      sub.unsubscribe();
    } );
    this.benchmarkState.benchmarkGroupChoosing = groupName;
  }

}
