import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalDataService } from '../../../../../../../globalData';
import { PricingService } from '../../../../../../../globalData';
import * as _ from 'lodash-es';
import { GlobalState } from '../../../../../../../global.state';
import { Security } from '../../../../../../../utils/dataInterfaces';
import { ScreenerResultsFilterOptions } from '../../../newInvestmentSelector/newInvestmentSelector.component';
import { Logger } from '../../../../../../../utils/logger.service';

export interface FundDescriptiveData {
  ticker: string;
  ticker_name: string;
  fund_family: string;
  security_type: string;
  price: number;
  expense_ratio: number;
  annualized_yield: number;
  cash: number;
  bonds: number;
  stocks: number;
  fund_data_loaded: boolean;
  quote: any;
}

@Component( {
  selector: 'rip-matching-fund-data-source',
  templateUrl: './matching-fund-data-source.component.html',
  styleUrls: [ './matching-fund-data-source.component.scss' ],
  // encapsulation: ViewEncapsulation.None,
} )
export class MatchingFundDataSourceComponent implements AfterViewInit, OnDestroy {
  // to do: make this dynamic
  matchingFundDisplayedColumnsOriginal: string[] = [ 'select', 'ticker', 'description', 'type', 'price', 'expense_ratio' ];
  matchingFundDisplayedColumns: string[] = this.matchingFundDisplayedColumnsOriginal;
  matchingFundDataSource: MatTableDataSource<FundDescriptiveData>;
  currentPageData: FundDescriptiveData[];
  currentPageDataFullyLoaded: boolean;

  resultsLength = 0;

  @ViewChild( MatPaginator, { static: false } ) paginator: MatPaginator;
  @ViewChild( 'table', { static: false } ) table: MatTable<any>;
  @ViewChild( MatSort, { static: false } ) sort: MatSort;

  @Input() matchingFunds: any[];
  @Input() multiSelect: boolean = true;
  @Input() fundTypeOptionsSelected: any = {};
  @Input() selectionHeader: string = '';
  @Input() filterOptions: ScreenerResultsFilterOptions;
  @Input() hideNavButtons: boolean = false;
  @Input() selectFirstResult: boolean = false;

  @Output() securitiesSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBackOneStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() startOver: EventEmitter<any> = new EventEmitter<any>();

  selection = new SelectionModel<FundDescriptiveData>( true, [] );

  isDestroyed: boolean = false;

  selectedColumnSet: any = {};
  columnSets: any = [];

  columns: any = [];

  showNoFundsMessage: boolean = false;

  securitiesAlreadyRequested: any = {};

  constructor( private _gdService: GlobalDataService,
               private _pricingService: PricingService,
               private _cd: ChangeDetectorRef,
               private _state: GlobalState ) {
    const set = _state.defaultColumnSets.find( ( g: any ) => {
      return g.label === _state.defaultColumnSetLabel;
    } ) || {};
    this.columnSets = this._state.globalVars.columnSets;
    this.selectColumnSet( set );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      if ( this.multiSelect !== undefined && this.multiSelect !== null && !this.multiSelect ) {
        this.selection = new SelectionModel<FundDescriptiveData>( false, [] );
      }
      this.initializeData();
    } );
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.matchingFundDataSource.disconnect();
  }

  selectColumnSet( columnSet: any ) {
    this.selectedColumnSet = columnSet;

    this.columns = this._state.makeColumnSet( [ 'annualized_yield' ].concat( this.selectedColumnSet.columns ), 'account' );
    this.ignoreColumns();
    const justColumns = [];
    for ( const c of this.columns ) {
      justColumns.push( c.prop );
    }
    this.matchingFundDisplayedColumns = this.matchingFundDisplayedColumnsOriginal.concat( justColumns );
    Logger.log( `selected set: ${ columnSet.label }` );
  }

  ignoreColumns() {
    _.remove( this.columns, ( c: any ) => {
      return [ 'expense_ratio', 'real_assets', 'cost_basis', 'gain_loss', 'security_style' ].includes( c.prop );
    } );
  }

  formatColumn( column, value ) {
    if ( column.pipe ) {
      return column.pipe.transform( value );
    } else {
      return value;
    }
  }


  doChanges() {
    if ( !this.isDestroyed ) {
      this._cd.detach();
      this._cd.detectChanges();
      // this._cd.markForCheck();
      this._cd.reattach();
    }
  }

  initializeData() {
    this.currentPageDataFullyLoaded = false;
    if ( this.matchingFunds && this.matchingFunds.length > 0 ) {
      this.resultsLength = this.matchingFunds.length;

      const fundDataList: FundDescriptiveData[] = [];
      for ( const fund of this.matchingFunds ) {
        // check for existing matches
        const exists = _.find( fundDataList, ( f: any ) => {
          return f.ticker === fund.ticker;
        } );
        if ( !exists ) {
          fundDataList.push(
            {
              ticker: fund.ticker,
              ticker_name: fund.description,
              fund_family: fund.fund_family,
              security_type: fund.fund_type,
              expense_ratio: fund.expense_ratio,
              price: null,
              annualized_yield: null,
              cash: null,
              bonds: null,
              stocks: fund.stocks,
              fund_data_loaded: false,
              quote: {},
            },
          );
        }
      }
      this.matchingFundDataSource = new MatTableDataSource<FundDescriptiveData>( fundDataList );
      this.matchingFundDataSource.paginator = this.paginator;
      this.matchingFundDataSource.sort = this.sort;
      this.connectAndSubscribeToSource();
      this.matchingFundDataSource.sort.sort( { disableClear: false, id: 'expense_ratio', start: 'asc' } );
      this.selection.clear();
    }
  }

  getFundDataForCurrentPage() {
    // Logger.log(JSON.stringify(this.currentPageData));
    if ( this.currentPageData && this.currentPageData.length > 0 ) {
      this.currentPageDataFullyLoaded = false;
      for ( const f of this.currentPageData ) {
        if ( !f.fund_data_loaded ) {
          this.getSecurityData( f.ticker );
        }
      }
    }
    this.doChanges();
  }

  allFundDetailsRetrieved() {
    if ( this.matchingFundDataSource === null || this.currentPageData === null ) {
      this.currentPageDataFullyLoaded = false;
    }
    if ( !this.currentPageDataFullyLoaded ) {
      if ( this.currentPageData && this.currentPageData.length > 0 ) {
        if ( !_.find( this.currentPageData, ( fundData: FundDescriptiveData ) => {
          return !fundData.fund_data_loaded;
        } ) ) {
          this.currentPageDataFullyLoaded = true;
        }
      }
    }
    this.doChanges();
    return this.currentPageDataFullyLoaded;
  }

  getSecurityData( ticker ) {
    const fundData = _.find( this.matchingFundDataSource.data, ( f: FundDescriptiveData ) => {
      return f.ticker === ticker;
    } );

    if ( fundData ) {
      if ( !this.securitiesAlreadyRequested[ticker] ) {
        this.securitiesAlreadyRequested[ticker] = true;
        let request;
        if (fundData.stocks) {
          request = this._gdService.getStock( ticker );
        } else {
          request = this._gdService.getSecurity( ticker );
        }
        request.subscribe( ( securityData: any ) => {

          if ( securityData && securityData.data && securityData.data.ticker ) {
            const data: Security = securityData.data;
            if ( (
              this.fundTypeOptionsSelected.maxExpenseRatio &&
              this.fundTypeOptionsSelected.maxExpenseRatio <= data.expense_ratio
            ) || this.shouldFilterOutByPropMinAmount( data ) ) {
              // our screener table might not have the most up to date expense_ratio or none at all. So, if this security
              // comes back with a different, higher than set max expense ratio, we need to remove it from the list
              this.removeItemFromDataSource( ticker );
            } else {
              this.setFundDataObj( fundData, data, ticker );
            }
          } else {
            this.removeItemFromDataSource( ticker );
          }

          if ( this.matchingFundDataSource.data.length === 1 && this.selectFirstResult ) {
            // select first result
            this.selection.select( this.matchingFundDataSource.data[0] );
          }

          this.setAddButtonDisabled();
          this.doChanges();

        }, ( err ) => {
          Logger.log( `Error for query: ${ ticker } | ${ err.err }` );
          Logger.log( `removing security, ${ ticker }, from result list` );
          this.removeItemFromDataSource( ticker );
          return null;
        } );

      } else {
        // this means the security has already been requested. do nothing
      }

    } else {
      Logger.log( `no match found for ${ ticker }. removing security from result list` );
      this.removeItemFromDataSource( ticker );
    }
  }

  setFundDataObj( fundData: FundDescriptiveData, data: Security, ticker: string ) {
    fundData = Object.assign( fundData, data );
    fundData.ticker_name = data.ticker_name;
    fundData.security_type = data.security_type;
    if ( data.annualized_yield ) {
      fundData.annualized_yield = data.annualized_yield;
    }

    fundData.cash = data.cash;
    fundData.bonds = data.bonds;
    fundData.stocks = data.stocks;
    if ( !fundData.price && fundData.quote ) {
      fundData.price = fundData.quote.price;
    }
    fundData.fund_data_loaded = true;
  }

  addSelectedFunds() {
    /*const selectedTickerList: string[] = [];
    if ( this.selection.selected.length > 0 ) {
      this.selection.selected.forEach( ( s: FundDescriptiveData ) => {
        selectedTickerList.push( s.ticker );
      } );
    }*/
    this.securitiesSelected.emit( this.selection.selected );
  }

  addButtonDisabled: boolean = true;

  setAddButtonDisabled() {
    if ( this.selection.selected.length === 0 ) {
      this.addButtonDisabled = true;
      return;
    }
    for ( const s of this.selection.selected ) {
      if ( !s.fund_data_loaded ) {
        this.addButtonDisabled = true;
        return;
      }
    }
    this.addButtonDisabled = false;
  }

  selectionChange( event, row ) {
    if ( event && row ) {
      this.selection.toggle( row );
    }
    this.setAddButtonDisabled();
  }

  private removeItemFromDataSource( ticker: string ) {

    this.matchingFundDataSource.data = this.matchingFundDataSource.data.filter( ( f: FundDescriptiveData ) => {
      return f.ticker !== ticker;
    } );
    this.showNoFundsMessage = this.matchingFundDataSource.data.length === 0;
    this.matchingFundDataSource.paginator = this.paginator;
    this.getFundDataForCurrentPage();
    this.setAddButtonDisabled();
  }

  private shouldFilterOutByPropMinAmount( security: Security ) {
    if ( !this.filterOptions ) {
      return false;
    }
    if ( this.filterOptions.lessOrMoreThan === '<' ) {
      return ( this.filterOptions && ( this.filterOptions.amount <= security[this.filterOptions.column] ) );
    }

    if ( this.filterOptions.lessOrMoreThan === '>' ) {
      return ( this.filterOptions && ( this.filterOptions.amount >= security[this.filterOptions.column] ) );
    }

  }

  private connectAndSubscribeToSource() {
    this.matchingFundDataSource.connect().subscribe( ( d ) => {
      this.currentPageData = d;
      this.getFundDataForCurrentPage();
    } );

    this.sort.sortChange.subscribe( () => {
      // If the user changes the sort order, reset back to the first page.
      this.paginator.firstPage();
      this.getFundDataForCurrentPage();
    } );

  }

}
