import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NbIconLibraries, NbMenuItem, NbMenuService } from '@nebular/theme';
import { GlobalState } from '../../global.state';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Auth } from '../../auth.service';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  MobileAccountDetailsComponent,
  MobileAddManualAccountComponent,
  MobileDashboardChartsComponent,
  MobileDisclaimersComponent,
  MobileFastlinkComponent,
  MobileMarketDataComponent,
  MobileOneDayChangeComponent,
  MobileRowDetailComponent,
} from './components';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { SubscriptionDetailsUtil } from '../../utils/subscriptionDetails.util';
import { EVENT_NAMES, MOBILE_PAGE_TITLES } from '../../utils/enums';
import { BreakpointObserver } from '@angular/cdk/layout';
import { WealthFluentMessageService } from '../../theme/services';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Logger } from '../../utils/logger.service';


@Component( ( {
  selector: `rip-nebular-mobile-layout`,
  template: `
    <nb-layout center id="mobile-layout">

      <nb-layout-column id="mobile-content"> <!--[style.overflow-y]="currentIndex === 2 ? 'hidden' : 'scroll'">-->
        <ng-container *ngTemplateOutlet="bannerTemplate"></ng-container>
        <div style="padding-bottom: 50px;">
          <div [style.display]="currentIndex === 1 ? '' : 'none'" *ngIf="subscriptionActive">
            <rip-allocation-widget></rip-allocation-widget>
          </div>
          <div [style.display]="currentIndex === 2 ? '' : 'none'" *ngIf="subscriptionActive">
            <rip-benchmark-setup-layout></rip-benchmark-setup-layout>
          </div>
          <div [style.display]="currentIndex === 3 ? '' : 'none'" *ngIf="subscriptionActive">
            <rip-market-information-dashboard></rip-market-information-dashboard>
          </div>
          <div [style.display]="currentIndex === 4 ? '' : 'none'" *ngIf="subscriptionActive">
            <rip-holdings></rip-holdings>
          </div>
          <div [style.display]="currentIndex === 5 ? '' : 'none'" *ngIf="subscriptionActive">
            <rip-transactions></rip-transactions>
          </div>
        </div>
      </nb-layout-column>
    </nb-layout>
  `,
  styleUrls: [ `./nebularMobileLayout.component.scss`, `./mobileLayout.scss` ],
} ) )

export class NebularMobileLayoutComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() bannerTemplate: TemplateRef<any>;

  private readonly onDestroy:Subject<void> = new Subject<void>();

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );
  private cd: ChangeDetectorRef = inject( ChangeDetectorRef );

  faBars: IconDefinition = faBars;

  currentIndex: number = 1; // indexes are as follows: 0-balance sheet, 1-dashboard, 2-planning, 3-markets, 4-holdings, 5-transactions(coming soon)

  menuItems: NbMenuItem[] = [];

  menuClickSubscription: Subscription;

  pageTitles: string[] = [
    MOBILE_PAGE_TITLES.BALANCE_SHEET,
    MOBILE_PAGE_TITLES.DASHBOARD,
    MOBILE_PAGE_TITLES.HOLDINGS,
    MOBILE_PAGE_TITLES.PROFILE,
    MOBILE_PAGE_TITLES.TRANSACTIONS,
    MOBILE_PAGE_TITLES.ONBOARDING,
    MOBILE_PAGE_TITLES.MY_PLAN,
  ];


  pulseFastlinkButton: boolean = false;

  subscriptionActive: boolean = true;

  mobileDeviceIsPortrait: boolean = true;

  windowInnerHeight: number;

  subscriberName: string = 'nebularMobileLayout';

  inWealthFluent: boolean = false;

  constructor( private menuService: NbMenuService,
               private _state: GlobalState,
               private _bottomSheet: MatBottomSheet,
               private _detectorService: DeviceDetectorService,
               private iconLibraries: NbIconLibraries,
               private _auth: Auth,
               private _subscriptionDetailsUtil: SubscriptionDetailsUtil,
               private bpObserver: BreakpointObserver ) {

    this.iconLibraries.registerFontPack( 'fad', { packClass: 'fad', iconClassPrefix: 'fa' } );
    this.iconLibraries.registerFontPack( 'fal', { packClass: 'fal', iconClassPrefix: 'fa' } );
    this.iconLibraries.registerFontPack( 'far', { packClass: 'far', iconClassPrefix: 'fa' } );
    this.iconLibraries.registerFontPack( 'fas', { packClass: 'fas', iconClassPrefix: 'fa' } );
    // this.iconLibraries.setDefaultPack( 'fad' );

    this.checkSubscription();

    this.setMenuItems();

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_mobileLayout' ] = this;
    }

    this.wealthFluentMessageService.mobileNavigation.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( path: string ) => {
          switch ( path ) {
            case 'portfolio':
              this.changeView( 1 );
              break;
            case 'planning':
              this.changeView( 2 );
              break;
            case 'markets':
              this.changeView( 3 );
              break;
            case 'holdings':
              this.changeView( 4 );
              break;
            case 'transactions':
              this.changeView( 5 );
              return;
            default:
              this.changeView( 1 );
          }
        }, error: ( err ) => {
          Logger.log( err );
        },
      } );

    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_FASTLINK, ( institution_id: any ) => {
      this.openMobileFastLink( institution_id );
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_CHARTS, ( /*event*/ ) => {
      this._bottomSheet.open( MobileDashboardChartsComponent );
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_DISCLAIMERS, ( event ) => {
      this._bottomSheet.open( MobileDisclaimersComponent, { data: event } );
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_ONE_DAY_BREAKDOWN, () => {
      this._bottomSheet.open( MobileOneDayChangeComponent );
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_ACCOUNT_DETAILS, ( account: Account ) => {
      this._bottomSheet.open( MobileAccountDetailsComponent, { data: { account } } );
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_ROW_DETAIL, ( holding: any ) => {
      this._bottomSheet.open( MobileRowDetailComponent, { data: { holding } } );
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.MANUAL_ACCOUNT_DELETED, () => {
      this._bottomSheet.dismiss();
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.SUBSCRIPTION_CHANGED, () => {
      this.checkSubscription();
    }, this.subscriberName );
    _state.subscribe( EVENT_NAMES.OPEN_MOBILE_MANUAL_ACCOUNT_MANAGER, () => {
      this.openMobileManualAccountManager();
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.MOBILE_GO_TO_MY_PLAN, () => {
      this.changeView( 6 );
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.MOBILE_GO_TO_HOLDINGS, () => {
      this.changeView( 2 );
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.RECALC_REAL_VH, () => {
      setTimeout( () => {
        this.updateRealViewportDimensions();
      }, 500 );
    }, this.subscriberName );

    /*    this._state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
     if ( this._state.globalVars.allAccountsFromAgg.length === 0 &&
     this._state.globalVars.allManualAccountsFromDB.length === 0 ) {
     this.pulseFastlinkButton = true;
     }
     }, this.subscriberName );*/


    // check for current orientation
    this.mobileDeviceIsPortrait = this.bpObserver.isMatched( '(orientation: portrait)' );
    this.bpObserver.observe( [
      '(orientation: portrait)',
    ] )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( result ) => {
          this.updateRealViewportDimensions();
          this.mobileDeviceIsPortrait = result.matches;
        },
      } );
  }

  checkSubscription() {
    this._subscriptionDetailsUtil.hasActiveSubscription().subscribe( {
      next: ( hasActiveSubscription ) => {
        if ( hasActiveSubscription ) {
          this._state.globalVars.subscriptionInactive = false;
          this.subscriptionActive = true;
        } else {
          this._state.globalVars.subscriptionInactive = true;
          this.subscriptionActive = false;
          this.currentIndex = 3;
        }
        this.setMenuItems();
      }, error: ( err ) => {
        console.error( err );
        this._state.globalVars.subscriptionInactive = true;
        this.subscriptionActive = false;
        this.currentIndex = 3;
      },
    } );
  }

  setMenuItems() {
    this.menuItems = [
      { title: MOBILE_PAGE_TITLES.CONNECT_ACCOUNTS, icon: { icon: 'plus', pack: 'fal', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.ADD_MANUAL_ACCOUNTS, icon: { icon: 'plus-square', pack: 'fal', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.BALANCE_SHEET, icon: { icon: 'list', pack: 'fad', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.DASHBOARD, icon: { icon: 'digital-tachograph', pack: 'fad', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.HOLDINGS, icon: { icon: 'list-alt', pack: 'fad', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.TRANSACTIONS, icon: { icon: 'repeat', pack: 'fad', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.MY_PLAN, icon: { icon: 'user-chart', pack: 'fad', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.PROFILE, icon: { icon: 'user', pack: 'fad', status: 'primary' } },
      { title: MOBILE_PAGE_TITLES.LOGOUT, icon: { icon: 'sign-out-alt', pack: 'fad', status: 'primary' } },
    ];

    if ( environment.env !== 'prod' ) {
      this.menuItems.push( {
        title: MOBILE_PAGE_TITLES.ONBOARDING,
        icon: { icon: 'wand-magic-sparkles', pack: 'fad', status: 'primary' },
      } );
    }


    if ( !this.subscriptionActive ) {
      this.menuItems.splice( 0, 7 );
      if ( environment.env !== 'prod' ) {
        this.menuItems.pop(); // get rid of onboarding too
      }
    }

  }

  ngOnInit() {
    this.menuClickSubscription = this.menuService.onItemClick()
      .pipe(
        filter( ( { tag } ) => tag === 'mobile-menu' ),
        map( ( { item: { title } } ) => title ),
      )
      .subscribe( ( title: string ) => {

        const index = this.pageTitles.indexOf( title );
        if ( index >= 0 ) {
          this.changeView( index );
        } else {
          // these are menu items that do not take the user to a page, but instead open a bottom sheet or log them out
          if ( title === MOBILE_PAGE_TITLES.CONNECT_ACCOUNTS ) {
            this.openMobileFastLink();
          } else if ( title === MOBILE_PAGE_TITLES.ADD_MANUAL_ACCOUNTS ) {
            this.openMobileManualAccountManager();
          } else if ( title === MOBILE_PAGE_TITLES.LOGOUT ) {
            this._auth.logout();
          } else {
            Logger.log( `unhandled menu item clicked: ${title}` );
          }
        }
        /*else if ( title === MOBILE_PAGE_TITLES.BALANCE_SHEET ) { // go to page tabs from menu
         this.changeView( 0 );
         } else if ( title === MOBILE_PAGE_TITLES.DASHBOARD ) {
         this.changeView( 1 );
         } else if ( title === MOBILE_PAGE_TITLES.HOLDINGS ) {
         this.changeView( 2 );
         } else if ( title === MOBILE_PAGE_TITLES.TRANSACTIONS ) {
         this.changeView( 4 );
         } else if ( title === MOBILE_PAGE_TITLES.PROFILE ) {
         this.changeView( 3 );
         } else if ( title === MOBILE_PAGE_TITLES.ONBOARDING ) {
         this.changeView( 5 );
         } else if ( title === MOBILE_PAGE_TITLES.MY_PLAN ) {
         this.changeView( 6 );
         }*/
      } );

    this.addOverflowHiddenToBody();
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.inWealthFluent = this._state.globalVars.inWealthFluent;
      this.determineHeightOfMobileBrowserWindow();
      this._state.notifyDataChanged( EVENT_NAMES.MOBILE_LAYOUT_LOADED );
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [
      EVENT_NAMES.OPEN_MOBILE_FASTLINK,
      EVENT_NAMES.OPEN_MOBILE_CHARTS,
      EVENT_NAMES.OPEN_MOBILE_DISCLAIMERS,
      EVENT_NAMES.OPEN_MOBILE_ONE_DAY_BREAKDOWN,
      EVENT_NAMES.OPEN_MOBILE_ACCOUNT_DETAILS,
      EVENT_NAMES.OPEN_MOBILE_ROW_DETAIL,
      EVENT_NAMES.OPEN_MOBILE_MANUAL_ACCOUNT_MANAGER,
      EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      EVENT_NAMES.RECALC_REAL_VH,
    ].join( ' | ' ), this.subscriberName );
    this.menuClickSubscription.unsubscribe();

    this.onDestroy.next();
  }

  addOverflowHiddenToBody() {
    const body = document.body;
    const html = document.documentElement;
    body.style.overflow = 'hidden';
    body.style.height = '100vh';
    html.style.overflow = 'hidden';
  }

  changeView( index: number ) {
    this.currentIndex = index;
    this.cd.markForCheck();
  }

  openMobileFastLink( institution_id?: any ) {
    this._bottomSheet.open( MobileFastlinkComponent, MobileUtil.getBottomSheetConfig( { institution_id } ) );
    // console.log( 'open fastlink' );
    this.pulseFastlinkButton = false;
  }

  openMobileManualAccountManager() {
    this._bottomSheet.open( MobileAddManualAccountComponent, MobileUtil.getBottomSheetConfig( {} ) );
    // console.log( 'open mobile manual account manager' );
  }

  openMarketDataTable() {
    this._bottomSheet.open( MobileMarketDataComponent );
  }

  updateRealViewportDimensions() {
    // console.log( 'window.innerHeight: ', window.innerHeight );
    // console.log( 'this.windowInnerHeight: ', this.windowInnerHeight );
    // should only need to update if the last stored number is different from the current one
    let height = screen.height || screen.availHeight || 700;
    Logger.log( `Mobile height of available screen in ripsaw mobile layout: ${ height }` );

    if ( height !== this.windowInnerHeight ) {
      const root = document.documentElement;
      root.style.setProperty( '--real-vh', `${ height / 100 }px` );
      // console.log( '--real-vh: ', root.style.getPropertyValue( '--real-vh' ) );
      this.windowInnerHeight = height;

    }

  }

  determineHeightOfMobileBrowserWindow() {
    const self = this;

    self.updateRealViewportDimensions();

    const isIOS = self._detectorService?.os?.includes( 'iOS' );

    if ( isIOS ) {
      document.documentElement.style.top = '0';
    }

    $( 'body' ).on( 'blur', 'input', () => {
      self.updateRealViewportDimensions();
    } );

  }

}
