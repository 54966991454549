import { Injectable } from '@angular/core';


@Injectable()

export class ChartColorUtil {

  protected static ColorSets = [
    { start: '#00AEEF', end: '#0173AB' }, // sidebar blue : orange
     { start: '#39B54A', end: '#94E19E' }, // optimizer blue : yellow
    { start: '#1B75BC', end: '#075470' }, // accent blue : gray
     { start: '#602D91', end: '#BC91EF' },
    { start: '#FFC60B', end: '#E1E31B' },
    { start: '#F26722', end: '#F58542' },
    { start: '#A267E5', end: '#39B54A' },
    {start: '#58595B', end: '#00AEEF' },
    // { start: '#075470', end: '#D1D3D4' },
    // { start: '#1B75BC', end: '#D1D3D4' },
  ];

  static hex( c ) {
    const s = '0123456789abcdef';
    let i = parseInt( c );
    if ( i === 0 || isNaN( c ) ) {
      return '00';
    }
    i = Math.round( Math.min( Math.max( 0, i ), 255 ) );
    return s.charAt( ( i - i % 16 ) / 16 ) + s.charAt( i % 16 );
  }

  /* Convert an RGB triplet to a hex string */
  static convertToHex( rgb ) {
    return ChartColorUtil.hex( rgb[0] ) + ChartColorUtil.hex( rgb[1] ) + ChartColorUtil.hex( rgb[2] );
  }

  /* Remove '#' in color hex string */
  static trim( s ) {
    return ( s.charAt( 0 ) === '#' ) ? s.substring( 1, 7 ) : s;
  }

  /* Convert a hex string to an RGB triplet */
  static convertToRGB( hex ) {
    const color = [];
    color[0] = parseInt( ( ChartColorUtil.trim( hex ) ).substring( 0, 2 ), 16 );
    color[1] = parseInt( ( ChartColorUtil.trim( hex ) ).substring( 2, 4 ), 16 );
    color[2] = parseInt( ( ChartColorUtil.trim( hex ) ).substring( 4, 6 ), 16 );
    return color;
  }

  static generateColor( colorStart, colorEnd, colorCount ) {

    // The beginning of your gradient
    const start = ChartColorUtil.convertToRGB( colorStart );

    // The end of your gradient
    const end = ChartColorUtil.convertToRGB( colorEnd );

    // The number of colors to compute
    const len = colorCount;

    // Alpha blending amount
    let alpha: number = 0;

    const colors = [];

    for ( let i = 0; i < len; i++ ) {
      const c = [];
      alpha += ( 1 / len );

      c[0] = start[0] * alpha + ( 1 - alpha ) * end[0];
      c[1] = start[1] * alpha + ( 1 - alpha ) * end[1];
      c[2] = start[2] * alpha + ( 1 - alpha ) * end[2];

      colors.push( `#${ ChartColorUtil.convertToHex( c ) }` );

    }

    return colors;

  }

  static getColorGradients( datasetLength: number ) {
    const colors = [];

    for ( let i = 0; i < this.ColorSets.length; i++ ) {
      const gradient = ChartColorUtil.generateColor( this.ColorSets[i].start, this.ColorSets[i].end, datasetLength / this.ColorSets.length );
      colors.push( ...gradient );
    }

    ChartColorUtil.shuffle(colors);

    return colors;


  }

  static shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  static getRandomColor() {
    const n = (Math.random() * 0xFFFFF * 1000000).toString(16);
    return `#${n.slice(0, 6)}`;
  }

}
