import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnderweightExposure } from '../../../../accounts/components/optimizer/components/underweightExposures/underweightExposures.component';
import { GlobalState } from '../../../../../global.state';
import { environment } from '../../../../../../environments/environment';
import { BenchmarkComponent } from '../../../../../utils/dataInterfaces';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { Logger } from '../../../../../utils/logger.service';

@Component( {
  selector: 'rip-benchmark-exposure-choices',
  template: `
    <div>
      These are the investments in your benchmark for this exposure. Click on the investment you would like to add to
      some of your accounts, or click the Other buttons to use the investment screener to pick a different investment.
      <div *ngIf="benchmarkComponent?.weights" class="benchmark-component-choices">
        <!-- headers -->
        <div class="row">
          <div class="col-4 weight-label-column">
            Benchmark Sector
          </div>
          <div class="col-2">
            Benchmark Investment
          </div>
          <div class="col-2">

          </div>
          <div class="col-1"></div>
        </div>
        <div *ngFor="let weight of benchmarkComponent?.weights">
          <div *ngIf="weight.allocation > 0" class="row exposure-list-item">
            <div class="col-4 weight-label-column">
              <span>{{weight.label}}</span>
            </div>
            <div class="col-3">
              <button mat-raised-button (click)="addProxy(weight.proxy)" class="exposure-choice-btn">
                {{weight.proxy}}
              </button>
            </div>
            <div class="col-2">
              <button mat-raised-button (click)="pickOtherInvestment(weight)" class="exposure-choice-btn">
                Other
              </button>
            </div>
            <div class="col-1 check-column">
              <fa-icon [icon]="faCheckCircle" fixedWidth="true" size="2x" *ngIf="selectedProxies[weight.proxy]"
                       matTooltip="Exposure Has Been Added">
              </fa-icon>
              <!--<fa-icon [icon]="faCircle" fixedWidth="true" size="2x"
                       *ngIf="!selectedProxies[weight.proxy]" matTooltip="Exposure Has Not Been Added Yet">
              </fa-icon>-->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="benchmarkComponent?.proxy" class="benchmark-component-choices">
        <button mat-raised-button (click)="addProxy(benchmarkComponent.proxy)">{{benchmarkComponent.proxy}}</button>
        <button mat-raised-button (click)="pickOtherInvestment(benchmarkComponent)">Other</button>
      </div>
    </div>
  `,

} )

export class BenchmarkExposureChoicesComponent implements AfterViewInit {

  @Input() exposure: UnderweightExposure;

  @Output() addProxySecurity: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToScreener: EventEmitter<any> = new EventEmitter<any>();

  benchmarkComponent: BenchmarkComponent;
  selectedProxies: any = {};
  lastChosenWeightProxy: string;

  faCheckCircle = faCheckCircle;
  faCircle = faCircle;

  constructor( private _state: GlobalState, private _cd: ChangeDetectorRef ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_benchmarkChoicesComponent'] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.init();
    } );
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    // this._cd.markForCheck();
    this._cd.reattach();
  }

  init() {
    this.benchmarkComponent = this._state.globalVars.benchmark.benchmarkData[this.singularize( this.exposure.row.dimensionProperty )];
    Logger.log( this.benchmarkComponent );
    this.doChanges();
  }

  addProxy( proxy: string ) {
    this.lastChosenWeightProxy = proxy;
    this.addProxySecurity.emit( proxy );
  }

  pickOtherInvestment( component: BenchmarkComponent ) {
    this.lastChosenWeightProxy = component.proxy;
    this.goToScreener.emit( component );
  }

  selectProxy( proxy: string ) {
    this.selectedProxies[proxy] = true;
  }

  unSelectProxy( proxy: string ) {
    delete this.selectedProxies[proxy];
  }

  areAllProxiesSelected() {
    if ( this.benchmarkComponent.proxy ) {
      return Object.keys( this.selectedProxies ).length === 1;
    } else {
      return Object.keys( this.selectedProxies ).length === this.benchmarkComponent.weights
        .filter( ( w: BenchmarkComponent ) => {
          return w.allocation > 0;
        } ).length;
    }
  }

  allButOneProxyHasBeenAdded() {
    if ( this.benchmarkComponent.proxy ) {
      return Object.keys( this.selectedProxies ).length === 0;
    } else {
      return Object.keys( this.selectedProxies ).length === this.benchmarkComponent.weights
        .filter( ( w: BenchmarkComponent ) => {
          return w.allocation > 0;
        } ).length - 1;
    }
  }

  singularize( str: string ) {
    if ( str[str.length - 1] === 's' ) {
      return str.substring( 0, str.length - 1 );
    } else {
      return str;
    }
  }

}
