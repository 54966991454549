import { Directive } from '@angular/core';
import { WorkspacePermission } from './permission';

type Constructor<T = {}> = new ( ...args: any[] ) => T;
type AbstractConstructor<T = object> = abstract new ( ...args: any[] ) => T;

interface WithPermissionsInterface {
  perm: typeof WorkspacePermission;
}

type WithPermissionsCtor = Constructor<WithPermissionsInterface> & AbstractConstructor<WithPermissionsInterface>;

export function mixinWithWorkspacePermission<T extends AbstractConstructor<{}>>( Base: T ): WithPermissionsCtor & T;
// eslint-disable-next-line no-redeclare
export function mixinWithWorkspacePermission<T extends Constructor>( Base: T ): WithPermissionsCtor & T {
  @Directive()
  class WithPermissions extends Base {
    perm = WorkspacePermission;

    constructor( ...args: any[] ) {
      super( ...args );
    }
  }

  return WithPermissions;
}

export const WithWorkspacePermission = mixinWithWorkspacePermission( class {
} );
