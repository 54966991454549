<!-- Display matching fund results -->
<rip-screener-nav [hideNavButtons]="hideNavButtons"
                  [addButtonDisabled]="addButtonDisabled"
                  [selectedColumnSet]="selectedColumnSet"
                  [columnSets]="columnSets"
                  [selectionHeader]="selectionHeader"
                  [showAddButton]="true"
                  (startOver)="startOver.emit()"
                  (goBackOneStep)="goBackOneStep.emit()"
                  (addSelectedFunds)="addSelectedFunds()"
                  (selectColumnSet)="selectColumnSet($event)">
</rip-screener-nav>

<div *ngIf="showNoFundsMessage" style="margin: 20px;">
  No Matching Funds Found. Try changing your settings to get more results.
</div>

<div class="fund-data-table-container" *ngIf="!showNoFundsMessage">

  <mat-table class="screener-return-table" [dataSource]="matchingFundDataSource" matSort #table>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" sticky>
      <mat-header-cell *cdkHeaderCellDef class="sticky-col select-col">Select</mat-header-cell>
      <mat-cell *cdkCellDef="let row" class="sticky-col select-col">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="selectionChange($event, row)"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Ticker Column -->
    <ng-container matColumnDef="ticker" sticky>
      <mat-header-cell *cdkHeaderCellDef mat-sort-header class="sticky-col ticker-col"> Ticker</mat-header-cell>
      <mat-cell *cdkCellDef="let fund" class="sticky-col ticker-col">
        {{ fund.ticker }}
      </mat-cell>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description" sticky><!-- sticky>-->
      <mat-header-cell *cdkHeaderCellDef mat-sort-header class="sticky-col desc-col"> Description</mat-header-cell>
      <mat-cell *cdkCellDef="let fund" class="sticky-col desc-col">
        {{ fund.ticker_name }}
      </mat-cell>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header class="text-cell"> Type</mat-header-cell>
      <mat-cell *cdkCellDef="let fund" class="text-cell">
        {{ fund.security_type }}
      </mat-cell>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header class="number-cell"> Price</mat-header-cell>
      <mat-cell *cdkCellDef="let fund" class="number-cell">
        <span
          *ngIf="fund.fund_data_loaded">{{ fund.price !== null && fund.price !== undefined ? (fund.price | ripCurrencyPipe) : 'MD*' }}</span>
        <span *ngIf="!fund.fund_data_loaded"><mat-spinner [diameter]="24"></mat-spinner></span>
      </mat-cell>
    </ng-container>

    <!-- Expense Ratio Column -->
    <ng-container matColumnDef="expense_ratio">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header class="number-cell"> Expense Ratio</mat-header-cell>
      <mat-cell *cdkCellDef="let fund" class="number-cell">
        {{ fund.expense_ratio !== null && fund.expense_ratio !== undefined ? (fund.expense_ratio | ripPercentPipe) :
        'MD*' }}
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of columns" matColumnDef="{{column.prop}}">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header class="number-cell"> {{ column.name }}</mat-header-cell>
      <mat-cell *cdkCellDef="let fund" class="number-cell">
        <span
          *ngIf="fund.fund_data_loaded">{{ fund[column.prop] !== null && fund[column.prop] !== undefined ? formatColumn(column, fund[column.prop]) : 'MD*' }}</span>
        <span *ngIf="!fund.fund_data_loaded"><mat-spinner [diameter]="24"></mat-spinner></span>
      </mat-cell>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="matchingFundDisplayedColumns; sticky: true"></tr>
    <mat-row class="screener-return-table-row" *cdkRowDef="let row; columns: matchingFundDisplayedColumns;"
             (click)="selectionChange($event, row)"></mat-row>
  </mat-table>
  <div class="foot-note">
    *MD = Missing or Non Applicable Data (no data returned for this field from our data providers)
  </div>
</div>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [length]="resultsLength"></mat-paginator>
<rip-screener-nav [hideNavButtons]="hideNavButtons"
                  [addButtonDisabled]="addButtonDisabled"
                  [selectedColumnSet]="selectedColumnSet"
                  [columnSets]="columnSets"
                  [selectionHeader]="selectionHeader"
                  [showAddButton]="true"
                  (startOver)="startOver.emit()"
                  (goBackOneStep)="goBackOneStep.emit()"
                  (addSelectedFunds)="addSelectedFunds()"
                  (selectColumnSet)="selectColumnSet($event)">
</rip-screener-nav>

