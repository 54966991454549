import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

declare const TradingViewEmbed: any;

@Component( {
  selector: 'rip-trading-view-mini-chart',
  template: `
      <!--      <div id="fundamentals_{{ticker}}" style="height: 300px;"></div>-->
      <!-- TradingView Widget BEGIN -->
      <div class="tradingview-widget-container" style="height: 300px; width: 200px" #containerDiv>
          <div class="tradingview-widget-container__widget"></div>

      </div>
      <div class="tradingview-widget-copyright">

        <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
        by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
      </div>
      <!-- TradingView Widget END -->
  `,
} )

export class TradingViewMiniChartComponent implements AfterViewInit {

  fundamentals: any;
  @Input() ticker: string = 'AAPL';
  @Input() exchange: string;
  settings: any = {};

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  constructor( private _elRef: ElementRef ) {
  }

  ngAfterViewInit() {

    const symbol = `${this.ticker.split('-').join('')}`;

    this.settings = {
      symbol,
      colorTheme: GlobalState.tradingViewColorTheme,
      isTransparent: false,
      largeChartUrl: '',
      displayMode: 'regular',
      width: '100%',
      height: '100%',
      autosize: true,
      locale: 'en',
    };
    const script = document.createElement( 'script' );
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    script.async = true;
    script.innerHTML = JSON.stringify( this.settings );
    this.containerDiv.nativeElement.appendChild( script );

  }

  goToTradingView() {
    const url = `https://www.tradingview.com/symbols/${ this.ticker }/`;
    Util.openExternalUrl( url );
  }

}
