import { AfterViewInit, Component, inject, OnDestroy } from '@angular/core';
import { GlobalState } from './global.state';
import { BaThemePreloader, RipThemeLoadingSpinnerService, WealthFluentMessageService } from './theme/services';
import { BaThemeConfig } from './theme';
import { Auth } from './auth.service';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { environment } from '../environments/environment';
import { Util } from './utils/util.service';
import { NbIconLibraries } from '@nebular/theme';
import { EVENT_NAMES, IDLE_STATES } from './utils/enums';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from './utils/logger.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CrossAppMessageType } from '@ripsawllc/ripsaw-analyzer';
import { ThemeService } from './theme/theme.service';
import { UserAnalyticsService } from './utils/user-analytics.service';
import { AccountManager } from './utils/accountManager';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Annotation from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';

/*
 * App Component
 * Top Level Component
 */
@Component( {
  selector: 'rip-app',
  styleUrls: [ './app.component.scss' ],
  template: `
    <main [class.menu-collapsed]="isMenuCollapsed" ngaBaThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
      <p-toast position="bottom-right"></p-toast>
    </main>
  `,
  providers: [ Auth ],
} )
export class AppComponent implements AfterViewInit, OnDestroy {

  private userAnalyticsService: UserAnalyticsService = inject( UserAnalyticsService );
  private _wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );
  private _accountManager: AccountManager = inject( AccountManager );

  onDestroy: Subject<void> = new Subject<void>();

  isMenuCollapsed: boolean = false;

  idleState: IDLE_STATES = IDLE_STATES.NOT_STARTED;
  timedOut: boolean = false;

  subscriberName: string = 'appComponent';

  constructor( private _state: GlobalState,
               private _themeService: ThemeService,
               private _spinnerService: RipThemeLoadingSpinnerService,
               private themeConfig: BaThemeConfig,
               private router: Router,
               private iconLibraries: NbIconLibraries,
               private idle: Idle,
               private _auth: Auth,
               public dialog: MatDialog,
  ) {
    this._wealthFluentMessageService.init();  // trying to make sure that it gets injected fully and sets everything up that it needs to as early as
                                              // possible
    Logger.log( 'Ripsaw Bootstrap done-------------------------------------------------------------------' );
    if ( environment.env !== 'prod' ) {
      window[ 'ripsawAppComponent' ] = this;
    }

    this.router.events.pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( event: RouterEvent ) => {
        if ( environment.env === 'prod' ) { // FOR ANALYTICS
          if ( event instanceof NavigationEnd ) {
            ( <any> window ).ga( 'set', 'page', event.urlAfterRedirects );
            ( <any> window ).ga( 'send', 'pageview' );
          }
        }

        // need this for some reason because there is a strange bug happening with the routing that is not sending / to
        // the ** redirect defined in app.routing. it seems like that route definition is no longer pushing to the
        // canActivate for the balanceSheet, but instead just trying to load balanceSheet directly
        if ( event.url ) {
          if ( event.url === '/' ) {
            this.router.navigateByUrl( '/pages' ).catch( ( err ) => {
              console.error( err );
            } );
          }
          this.userAnalyticsService.reloadUserPilot();
        }
      } );

    this.themeConfig.config();

    this._loadImages();

    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // LOADED INSIDE WealthFluent (or other iFrame, but let's assume WF for now)
    if ( document.location.ancestorOrigins?.length > 0 ) {
      // set global variables
      this._state.globalVars.inWealthFluent = true;
      Logger.log( `Account Manager ready! ${ this._accountManager.subscriberName }` );
      // tell wealthfluent ripsaw has loaded
      this._wealthFluentMessageService.messageWealthFluent( {
        message: 'Ripsaw Loaded in Iframe',
        type: CrossAppMessageType.finishedLoading,
        data: {},
      } );
    } else if ( !document.location.hash.includes( 'legacy' ) ) {
      // no legacy query param, so we should redirect the user to wealthfluent
      window.open( environment.wealthFluentOrigin, '_self' );
    } else {
      // load the original ripsaw theme
      this._themeService.switchTheme( 'ripsaw' );
      this.router.events.pipe( takeUntil( this.onDestroy ) )
        .subscribe( ( event: RouterEvent ) => {
          if ( event instanceof NavigationStart ) {
            if ( !event.url?.includes( 'legacy' ) ) {
              this.router.navigate( [ event.url ], {
                queryParams: { legacy: 'true' },
                queryParamsHandling: 'merge',
              } ).catch( err => console.error( err ) );
            }
          }
        } );
    }
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    this._state.subscribe( EVENT_NAMES.MENU_IS_COLLAPSED, ( isCollapsed: boolean ) => {
      this.isMenuCollapsed = isCollapsed;
    }, this.subscriberName );

    this._state.subscribe( [ EVENT_NAMES.LOGGED_IN, EVENT_NAMES.REGISTRATION_COMPLETED ].join( '|' ), () => {
      if ( !this._state.globalVars.inWealthFluent ) {
        this.resetIdleTimeout();
      }
      const user = Util.getLoggedInUser( this._auth );
      if ( user ) {
        this.userAnalyticsService.identifyToAllAnalytics();
      }
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.cancelIdleTimeout();
    }, this.subscriberName );

    this.iconLibraries.registerFontPack( 'font-awesome', { iconClassPrefix: 'fa' } );

    Chart.register( ChartDataLabels, Annotation );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {

      // hide spinner once all loaders are completed
      BaThemePreloader.load().then( () => {
        this._spinnerService.hide();
        if ( Util.isWebkitHandlerAvailable() ) {
          Util.sendWebkitHandlerMessage( 'loaded' );
        }
      } );

      if ( this._auth.authenticated() && !this._state.globalVars.inWealthFluent ) {
        this.resetIdleTimeout();
      }
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
        EVENT_NAMES.MENU_IS_COLLAPSED,
        EVENT_NAMES.LOGGED_IN,
        EVENT_NAMES.REGISTRATION_COMPLETED,
        EVENT_NAMES.LOGOUT ].join( ' | ' ),
      this.subscriberName );
    this.onDestroy.next();
  }


  private resetIdleTimeout() {
    Logger.log( 'Idle watch has been reset' );
    this.idle.stop();
    this.idle.watch();
    this.idleState = IDLE_STATES.ACTIVE;
    this.timedOut = false;
    this._auth.renewTokens();
  }

  private cancelIdleTimeout() {
    this.idle.stop();
  }

  private _loadImages(): void {
    // register some loaders
    // BaThemePreloader.registerLoader( this._imageLoader.load( 'assets/img/sky-bg.jpg' ) );
  }

}
