import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GlobalState } from '../../../../global.state';
import { UntypedFormControl } from '@angular/forms';
import { Util } from '../../../../utils/util.service';
import { GlobalDataService } from '../../../../globalData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountManager } from '../../../../utils/accountManager';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { FeeEditor } from '../../../../utils/enums';
import {
  RipsawCurrencyPipe,
  RipsawLongPercentPipe,
} from '../../../../theme/pipes';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component({
  selector: 'rip-advisor-fee-editor',
  template: `
    <div class="advisor-fee-container" *ngIf="accountTypeValid">
      <ng-container *ngIf="!editing">
        <span>&nbsp;|&nbsp;Annualized Account Fees:&nbsp;</span>
        <span>
          {{ percentControl.value | ripPercentPipe : '2-2' }} |
          {{ dollarControl.value | ripCurrencyPipe }}
        </span>
      </ng-container>
      <span class="advisor-fee-input-group" *ngIf="editing">
        <p-button
            class="icon-button change-type-btn"
            (click)="changeType()"
            icon="fa-regular {{
            type === FeeEditor.DOLLAR ? 'fa-dollar-sign' : 'fa-percent'
          }}"
            iconPos="left"
            matTooltip="Click To Change Between $ and %">
        </p-button>
        <input
            pInputText
            *ngIf="type === FeeEditor.PERCENT"
            type="text"
            placeholder="Annualized Account Fees (%)"
            [formControl]="percentControl" />
        <input
            *ngIf="type === FeeEditor.DOLLAR"
            pInputText
            type="text"
            placeholder="Annualized Account Fees ($)"
            [formControl]="dollarControl" />
      </span>
      <!--
      (blur)="updateFee($event, 'percent')"
      (blur)="updateFee($event, 'dollar')"
      -->
    </div>
  `,
  styleUrls: ['./advisorFeeEditor.component.scss'],
})
export class AdvisorFeeEditorComponent implements AfterViewInit {
  faDollarSign = faDollarSign;
  faPercent = faPercent;

  @Input() account;
  @Input() editing: boolean = false;
  FeeEditor = FeeEditor;
  percentControl: UntypedFormControl;
  dollarControl: UntypedFormControl;
  currentPercentValue: any;
  currentDollarValue: any;
  type: FeeEditor = FeeEditor.PERCENT;
  ripPercentPipe: RipsawLongPercentPipe;
  ripDollarPipe: RipsawCurrencyPipe;

  accountTypeValid: boolean = true;

  constructor(
    private _state: GlobalState,
    private _accountManager: AccountManager,
    private _gdService: GlobalDataService,
    private snackBar: MatSnackBar,
  ) {
    this.ripPercentPipe = new RipsawLongPercentPipe();
    this.ripDollarPipe = new RipsawCurrencyPipe();
    this.percentControl = new UntypedFormControl(0);
    this.dollarControl = new UntypedFormControl(0);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.accountTypeValid = Util.accountCanHaveFees(this.account);
      const fee = this._accountManager.getCurrentFee(this.account);
      if (fee !== 0) {
        this._accountManager.setFeeGlobally(fee, this.account);
        this.percentControl = new UntypedFormControl(fee);
        this.dollarControl = new UntypedFormControl(fee * this.account.value);
      } else {
        if (this.account.management_fee) {
          this.percentControl = new UntypedFormControl(
            this.account.management_fee,
          );
          this.dollarControl = new UntypedFormControl(
            this.account.management_fee * this.account.value,
          );
        }
      }
    });
  }

  changeType() {
    if (this.type === this.FeeEditor.DOLLAR) {
      this.type = this.FeeEditor.PERCENT;
      const sanitized: any =
        parseFloat(FormsUtil.sanitizeInput(this.dollarControl.value)) || 0;
      const percent = sanitized / this.account.value;
      this.percentControl.setValue(this.ripPercentPipe.transform(percent));
    } else {
      this.type = this.FeeEditor.DOLLAR;
      const sanitized: any =
        parseFloat(FormsUtil.sanitizeInput(this.percentControl.value)) || 0;
      const dollar = (sanitized / 100) * this.account.value;
      this.dollarControl.setValue(this.ripDollarPipe.transform(dollar));
    }
  }

  edit() {
    this.editing = true;
    this.currentPercentValue = this.percentControl.value;
    this.currentDollarValue = this.dollarControl.value;
    this.percentControl.setValue(
      this.ripPercentPipe.transform(this.percentControl.value),
    );
    this.dollarControl.setValue(
      this.ripDollarPipe.transform(this.dollarControl.value),
    );
  }

  reset() {
    this.percentControl.setValue(this.currentPercentValue);
    this.dollarControl.setValue(this.currentDollarValue);
    this.editing = false;
  }

  updateFee() {
    if (this.type === this.FeeEditor.PERCENT) {
      const sanitized: any =
        parseFloat(FormsUtil.sanitizeInput(this.percentControl.value)) || 0;
      this.percentControl.setValue(sanitized / 100);
      this.dollarControl.setValue((sanitized / 100) * this.account.value);
      this.editing = false;
      if (this.currentPercentValue * 100 === sanitized) {
        return;
      }
      const acctOverrides = this._state.getAccountOverride(this.account);
      if (acctOverrides) {
        acctOverrides.advisor_fee = this.percentControl.value;
        this.updateMapping(acctOverrides);
      }
    } else {
      const sanitized: any =
        parseFloat(FormsUtil.sanitizeInput(this.dollarControl.value)) || 0;
      this.dollarControl.setValue(sanitized);
      this.percentControl.setValue(sanitized / this.account.value);
      this.editing = false;
      if (this.currentDollarValue === sanitized) {
        return;
      }
      const acctOverrides = this._state.getAccountOverride(this.account);
      if (acctOverrides) {
        acctOverrides.advisor_fee =
          this.dollarControl.value / this.account.value;
        this.updateMapping(acctOverrides);
      }
    }
  }

  updateMapping(acctOverrides: any) {
    this._gdService
      .updateUserAccountMapping(this._state.globalVars.accountMapping)
      .subscribe({
        next: (/*resp: any*/) => {
          this.snackBar.open(
            'Account Fee Saved!',
            null,
            Util.getSnackBarOptions(),
          );
          this._accountManager.setFeeGlobally(
            acctOverrides.advisor_fee,
            this.account,
          );
        },
        error: (err: any) => {
          this.snackBar.open(
            `Error Saving Account Fee: ${err}`,
            null,
            Util.getSnackBarOptions(),
          );
        },
      });
  }
}
