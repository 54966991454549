<div class="row">
    <div class="col-lg-8">
        <div class="form-group security-finder-form-container">
            <rip-security-query-box #queryBox (fundSelected)="optionSelected($event)"></rip-security-query-box>
        </div>
    </div>
</div>
<rip-matching-fund-data-source *ngIf="queryBox.somethingWasChosen"
                               [matchingFunds]="matchingFunds"
                               [hideNavButtons]="true"
                               (securitiesSelected)="securityChosen($event)"
                               [multiSelect]="false"
                               [selectFirstResult]="true">

</rip-matching-fund-data-source>

