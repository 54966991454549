
<!-- Headers-->
<div class="form-group">
    <div class="flexing">
        <fa-icon [icon]="faUniversity" size="3x" class="manual-account-icon" style="margin-right: 5px; padding-bottom: 5px;"></fa-icon>
        <h3 class="manual-account-form-H3">Add the details about your bank account</h3>

    </div>

</div>


<div class="form-group">
  <div>
    Fields with an * are required
  </div>
</div>
<div *ngIf="form">
  <form [formGroup]="form" #formElement>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="name"
                 [errorStateMatcher]="matcher"
                 placeholder="Account Name / Description*">
        </label>
        <mat-error *ngIf="form.controls.name.hasError('required' )">
          Account Name is required
        </mat-error>
          <mat-label>Account Name / Description</mat-label>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Owner Type"
                    formControlName="owner_type"
                    [errorStateMatcher]="matcher">
          <mat-option value="Individual Account">Individual Account</mat-option>
          <mat-option value="Joint Account">Joint Account</mat-option>
          <mat-option value="Trust Account">Trust Account</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="value"
                 [errorStateMatcher]="matcher"
                 placeholder="Balance ($)*"
                 (change)="publicUtil.updateInputCurrencyFormat('value', form)">
        </label>
          <mat-label>Balance ($)</mat-label>
        <mat-error *ngIf="form.controls.value.hasError('required' )">
          Balance is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="annualized_yield"
                 [errorStateMatcher]="matcher"
                 placeholder="Interest Rate (%)"
                 (change)="publicUtil.updateInputPercentFormat('annualized_yield', form)">
        </label>
          <mat-label>Interest Rate (%)</mat-label>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="institution_name"
                 [errorStateMatcher]="matcher"
                 placeholder="Institution Name (Your bank)">
        </label>
          <mat-label>Institution Name (Your bank)</mat-label>
      </mat-form-field>
    </div>
  </form>
</div>
