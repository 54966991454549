/**
 * Created by joshua on 12/11/16.
 */
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Util } from './utils/util.service';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalState } from './global.state';
import { Router } from '@angular/router';
import { EVENT_NAMES } from './utils/enums';
import { Logger } from './utils/logger.service';
import moment, { Moment } from 'moment/moment';
import { AppStoreService } from './store';


export interface LoginDataInterface {
  token?: string;
  email?: string;
  password?: string;
  options?: Object;
  rememberCode?: string;
}

export interface VerifyCodeInterface {
  code: string;
  rememberCode?: string;
}

@Injectable()
export class Auth {

  loggedIn: Boolean;
  loginData: LoginDataInterface;

  lastTokenRenewal: Moment;

  loggedOutSignal: Subject<void> = new Subject<void>();

  private readonly lambdaUsersUrl: string;
  private readonly baseApiPath: string;


  constructor( private _http: HttpClient,
               private jwtHelper: JwtHelperService,
               private _state: GlobalState,
               private router: Router,
               private appStoreService: AppStoreService,
  ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaUsersUrl = `${ this.baseApiPath }/users`;
    this.loggedIn = false;
    this.emptyLoginData();
  }

  login( loginData: LoginDataInterface ): Observable<any> {
    // Logger.log( loginData );
    const url: string = `${ this.lambdaUsersUrl }/login`;
    const headers = new HttpHeaders( { 'Content-Type': 'application/json' } );
    // Logger.log( url );
    return this._http.post( url, loginData, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  verifyLogin( verifyData: VerifyCodeInterface, token: string ): Observable<any> {
    const url: string = `${ this.lambdaUsersUrl }/verify`;
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': token,
    } );
    // Logger.log( url );
    return this._http.post( url, verifyData, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  renewTokens() {
    const url: string = `${ this.lambdaUsersUrl }/get`;
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      ).subscribe( {
      next: ( resp: any ) => {
        const data = resp.data;
        if ( data.token ) {
          // set session
          this.setNewToken( data.token );
          // Logger.log( 'Auth token renewed and set' );
        } else {
          console.error( 'Error renewing authentication token' );
        }
      }, error: ( err ) => {

        if ( err ) {
          console.error( 'ERROR RENEWING TOKEN:' );
          console.error( err );
        } else {
          // token is expired. log the user out and tell them why
          this.logout( true );
        }
      },
    } );
  }

  logout( sessionExpired?: boolean ) {
    this.loggedIn = false;
    this.emptyLoginData();
    sessionStorage.removeItem( 'id_token' );
    this._state.redirectUrl = undefined;

    this.lastTokenRenewal = undefined;

    this.appStoreService.clear();

    this._state.notifyDataChanged( EVENT_NAMES.LOGOUT, null );

    if ( Util.isWebkitHandlerAvailable() ) {
      Util.sendWebkitHandlerMessage( 'signout' );
    } else {
      if ( !this._state.globalVars.inWealthFluent ) {
        const queryParams = {};
        if ( sessionExpired ) {
          queryParams[ 'logoutReason' ] = 'tokenExpired';
        }

        this.router.navigate( [ '/login' ], { queryParams } ).then( () => {
          Logger.warn( `user logged out ${ sessionExpired ? 'because of session token expiration' : 'by logout button' }`, true );
        } );
      } else {
        this.router.navigate( [ '/something-went-wrong' ] ).then( () => {
          Logger.warn( 'something went wrong' );
        } );
      }
      this._state.resetGlobalVars();
      this.loggedOutSignal.next();
    }
  }

  emptyLoginData() {
    this.loginData = {};
  }

  setNewToken( token: string ) {
    sessionStorage.setItem( 'id_token', token );
    this._state.globalVars.loggedInUser = null;
    this._state.globalVars.currentExpiration = null;
    this.loggedIn = this.authenticated();
    this.lastTokenRenewal = moment();
    // Logger.log( `set new token with expiration: ${ this._state.globalVars.currentExpiration }` );
    // Logger.log( 'new token stored' );
    this._state.notifyDataChanged( EVENT_NAMES.TOKENS_RENEWED, {} );
    if ( Util.isWebkitHandlerAvailable() ) {
      Util.sendWebkitHandlerMessage( 'new.session.token', { token } );
    }
  }


  authenticated() {
    // Check if there's an unexpired JWT
    // It searches for an item in sessionStorage with key == 'id_token'
    // return tokenNotExpired();
    const token = sessionStorage.getItem( 'id_token' );
    if ( !token || token === 'undefined' ) {
      return false;
    }
    try {
      let exp: number;
      if ( this._state.globalVars.currentExpiration ) {
        exp = this._state.globalVars.currentExpiration;
      } else {
        const decodedToken = this.jwtHelper.decodeToken( token );
        exp = decodedToken.exp;
        this._state.globalVars.currentExpiration = exp;
      }
      if ( !exp ) {
        return false;
      } else {
        return exp >= Date.now() / 1000;
      }
    } catch ( e ) {
      Logger.warn( e );
      return false;
    }
  }

  getTokenItem( tokenItem: string ) {
    if ( !this.authenticated() ) {
      this.logout();
      return;
    }
    if ( tokenItem === 'user' && this._state.globalVars.loggedInUser ) {
      return this._state.globalVars.loggedInUser;
    }
    const token = sessionStorage.getItem( 'id_token' );

    const item = this.jwtHelper.decodeToken( token )[ tokenItem ];
    if ( tokenItem === 'user' ) {
      this._state.globalVars.loggedInUser = item;
    }

    return item;
  }

  static getToken() {
    return sessionStorage.getItem( 'id_token' );
  }

  getDecodedToken() {
    const token = sessionStorage.getItem( 'id_token' );
    return this.jwtHelper.decodeToken( token );
  }

  resetPassword( data: any ): Observable<any> {
    const url: string = `${ this.lambdaUsersUrl }/resetPassword`;
    const headers = new HttpHeaders( { 'Content-Type': 'application/json' } );
    return this._http.post( url, data, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  checkResetCode( code: string ) {
    const url: string = `${ this.lambdaUsersUrl }/getByCode/${ code }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  changePassword( newPassword: string, token: string ) {
    const url: string = `${ this.lambdaUsersUrl }/changePassword`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ token }`,
    } );
    return this._http.post( url, { newPassword }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  rememberComputer( code: string ): void {
    localStorage.setItem( 'rememberCode', code );
  }

  isComputerRemembered(): string {
    return localStorage.getItem( 'rememberCode' );
  }

  clearRememberCode(): void {
    localStorage.removeItem( 'rememberCode' );
  }

  /**
   *
   * @param token - simple jwt token to decode
   * @returns {object} decoded token
   */
  decodeSimpleToken( token: string ): any {
    return this.jwtHelper.decodeToken( token );
  }

  lastTokenRenewalWasRecent(): boolean {
    return Math.abs( moment().diff( this.lastTokenRenewal, 'minutes', true ) ) < 5;
  }

}
