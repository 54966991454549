import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogFrameComponent } from './dialog-frame.component';
import { DialogService } from './dialog.service';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { LibActionsPanelModule } from '../actions-panel';

import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    DialogFrameComponent,
    DialogConfirmationComponent,
    DialogInfoComponent,
  ],
  imports: [
    CommonModule,
    LibActionsPanelModule,
    DialogModule,
    OverlayModule,
    ButtonModule,
  ],
  providers: [DialogService],
  exports: [DialogFrameComponent],
})
export class LibDialogModule { }
