import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { GlobalState } from '../../../global.state';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MarketInfoSettingsComponent } from './components/marketInfoSettings/market-info-settings.component';
import { CloseSettingsConfirmationComponent } from './components/closeConfirmation/close-settings-confirmation.component';
import { Util } from '../../../utils/util.service';
import { UsersUtil } from '../../../utils/users.util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Auth } from '../../../auth.service';
import { DeviationThresholdsComponent } from './components/deviationThresholds';
import { AppSettingsComponent } from './components/appSettings/app-settings.component';
import { WithWorkspacePermission } from '../../../shared/workspace-permission';

@Component( {
  selector: 'rip-settings',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './settings.component.scss',
  ],
  templateUrl: './settings.component.html',
} )
export class SettingsComponent extends WithWorkspacePermission {

  @ViewChild( 'marketInfoSettings' ) marketInfoSettings: MarketInfoSettingsComponent;
  @ViewChild( 'deviationThresholds' ) deviationThresholds: DeviationThresholdsComponent;
  @ViewChild( 'appSettings' ) appSettings: AppSettingsComponent;

  singleSection: string;
  inWealthFluent: boolean = false;

  constructor( private _state: GlobalState,
               public dialogRef: MatDialogRef<SettingsComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               public matDialog: MatDialog,
               private _usersUtil: UsersUtil,
               public snackBar: MatSnackBar,
               private _auth: Auth ) {
    super();
    // _state.globalVars.hideGlobalWidget = true;
    if ( data?.singleSection ) {
      this.singleSection = data.singleSection;
    }
    this.inWealthFluent = this._state.globalVars.inWealthFluent;
  }

  close() {
    if ( this.deviationThresholds?.canCancelChanges || this.appSettings?.valueHasChanged || this.marketInfoSettings?.anyValueHasChanged ) {
      this.matDialog.open<CloseSettingsConfirmationComponent, void, boolean>( CloseSettingsConfirmationComponent, {
        disableClose: true,
      } ).afterClosed().subscribe(
        confirmed => {
          if ( confirmed ) {
            // if confirmed, we want to call save any changes, then we can close the dialog
            this.updateAll();
          }
          this.dialogRef.close();
        }, ( err ) => {
          console.error( err );
          // can this actually happen?
        } );
    } else {
      this.dialogRef.close();
    }
  }

  updateAll() {
    const userPrefs = UsersUtil.checkMultiplePreferences( this._auth, [ 'marketInfo', 'thresholds', 'appSettings' ] );

    let thresholdsChanged, marketInfoChanged;

    if ( this.deviationThresholds?.canCancelChanges ) {
      this.deviationThresholds.setPrefsThresholds( userPrefs );
      thresholdsChanged = true;
    }

    if ( this.appSettings?.valueHasChanged ) {
      this.appSettings.setNewPrefsValues( userPrefs );
    }

    if ( this.marketInfoSettings?.anyValueHasChanged ) {
      userPrefs.marketInfo = this.marketInfoSettings.getRawPrefsFromData();
      marketInfoChanged = true;
    }

    this._usersUtil.savePreferences( userPrefs, ( err ) => {
      if ( err ) {
        console.error( err );
        this.snackBar.open( `Error saving user preferences. ${ Util.getRefCodeSupportString( err.refCode ) }`,
          'dismiss',
          Util.getSnackBarOptions( true ) );

      } else {
        this.snackBar.open( `User preferences saved `, null, Util.getSnackBarOptions() );
        if ( thresholdsChanged ) {
          this._state.globalVars.allocWidget.thresholds = userPrefs.thresholds;
          this._state.globalVars.allocWidget.recalc();
        }
        if ( marketInfoChanged ) {
          this._state.notifyDataChanged( 'market.calc.settings.changed', userPrefs.marketInfo );
        }
      }
    } );
  }
}
