import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

declare const TradingViewEmbed: any;

@Component( {
  selector: 'rip-trading-view-symbol-info',
  template: `
      <!--      <div id="fundamentals_{{ticker}}" style="height: 300px;"></div>-->
      <!-- TradingView Widget BEGIN -->
      <div class="tradingview-widget-container" style="height: 300px; width: 200px" #containerDiv>
          <div class="tradingview-widget-container__widget"></div>

      </div>
      <div class="tradingview-widget-copyright">

        <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
        by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
      </div>
      <!-- TradingView Widget END -->
  `,
} )

export class TradingViewSymbolInfoComponent implements AfterViewInit {

  fundamentals: any;
  @Input() ticker: string = 'AAPL';
  settings: any = {};

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  constructor( private _elRef: ElementRef ) {
  }

  gotToTradingView() {
    Util.openExternalUrl( `https://www.tradingview.com/symbols/${ this.ticker }/` );
  }

  ngAfterViewInit() {
    this.settings = {
      colorTheme: GlobalState.tradingViewColorTheme,
      symbol: this.ticker,
      isTransparent: false,
      largeChartUrl: '',
      displayMode: 'regular',
      width: '100%',
      // height: '100%',
      autosize: true,
      locale: 'en',
    };
    const script = document.createElement( 'script' );
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
    script.async = true;
    script.innerHTML = JSON.stringify( this.settings );
    this.containerDiv.nativeElement.appendChild( script );
    const brandingDiv = document.createElement( 'div' );
    brandingDiv.innerHTML = `
    <div class="tradingview-widget-copyright">
    <a (click)="goToTradingView()">
    <span class="blue-text">${ this.ticker } Data</span></a>
              by TradingView
          </div>
`;
    this.containerDiv.nativeElement.appendChild( brandingDiv );

  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }

}
