<div class="rates-container">
  Choose a market rate:
  <div class="rates-column">
    <button mat-raised-button *ngFor="let rate of zillowRates" (click)="chooseRate(rate)" type="button">
      {{ rate.name }} <br> {{ rate.refi.rate | ripLongPercentPipe }}
    </button>
    <p *ngIf="!zillowRates || zillowRates.length === 0">
      We were unable to return any market rates from Zillow.
      <rip-progress-bar-button [options]="tryAgainButtonOptions" (onClick)="retrieveRates()">
      </rip-progress-bar-button>
    </p>
  </div>
  <div>
    <mat-grid-list [cols]="7" [rowHeight]="75">
      <mat-grid-tile [colspan]="2" style="text-align: left;">
        <span>
          See more <a (click)="goToZillow('https://www.zillow.com/mortgage-rates/')">
          mortgage rates</a> on Zillow
        </span>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2">
        <img src="{{zillowLogoUrl}}">
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3">
        <span class="mr-auto" style="font-size:10pt;">
          © Zillow, Inc., 2006-2016. Use is subject to
          <a (click)="goToZillow('https://www.zillow.com/corp/Terms.htm')">
            Terms of Use
          </a>
        </span>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
