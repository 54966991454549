import { Injectable } from '@angular/core';
import { RegisterDataInterface, RegisterService } from '../pages/register/register.service';
import { takeUntil } from 'rxjs/operators';
import { Util } from './util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { lastValueFrom, Observable, Subject, Subscriber } from 'rxjs';
import { Auth } from '../auth.service';
import { GlobalState } from '../global.state';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RipsawCurrencyPipe, RipsawPercentPipe } from '../theme/pipes';
import { PromoCodeService } from '../globalData';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { UntypedFormGroup } from '@angular/forms';
import { Logger } from './logger.service';
import { SubscriptionDetailsUtil } from './subscriptionDetails.util';
import { StripeDeal } from './dataInterfaces';
import { StripePrice, StripeProduct } from '@ripsawllc/ripsaw-analyzer';
import { PaymentMethod } from '@stripe/stripe-js';
import moment from 'moment';
import { UserStatsState } from './userStats.state';
import { EVENT_NAMES } from './enums';

@Injectable()
export class RegistrationUtil {

  private readonly onDestroy = new Subject<void>();

  faCheckSquare = faCheckSquare;
  faTimes = faTimes;
  faInfoCircle = faInfoCircle;

  stripeProducts: StripeProduct[] = [];
  defaultProduct: StripeProduct;
  defaultPricing: StripePrice;

  defaultTrialDays: number = 14;

  showTestFields: boolean = false;

  couponResultIcon: IconProp;
  couponResultMsg: string;

  appName: string;
  title: string;
  subTitle: string;

  promoInfo: any;
  dealPromoInfo: any;
  stripeToken: string;
  paymentMethod: PaymentMethod;

  impactClickId: any;
  triesForUTTCheck: number = 0;

  deal: StripeDeal;
  _loadingDealInfo: boolean = true;
  dealInfoHasLoaded: Subject<boolean> = new Subject<boolean>();

  set loadingDealInfo( value: boolean ) {
    this._loadingDealInfo = value;
    this.dealInfoHasLoaded.next( value );
  }

  get loadingDealInfo() {
    return this._loadingDealInfo;
  }


  constructor( private _registerService: RegisterService,
               private _promoCodeService: PromoCodeService,
               private auth: Auth,
               public snackBar: MatSnackBar,
               private _state: GlobalState,
               private _router: Router,
               private _subDetailsUtil: SubscriptionDetailsUtil,
               private userStatsState: UserStatsState ) {

    this.appName = environment.appName;
    this.title = `Sign up for `;
    this.subTitle = `Already have a ${ this.appName } account? Sign in!`;

    if ( environment.env !== 'prod' ) {
      window[ 'registerUtil' ] = this;
      if ( environment.env !== 'uat' ) { // only want to show in dev and qc
        // this.showTestFields = true; // comment this out to hide the test fields and see what it should look like in uat and prod
      }
    }

    if ( !this._subDetailsUtil.stripeProducts || this._subDetailsUtil.stripeProducts.length === 0 ) {
      this._subDetailsUtil.getCurrentStripeProducts().subscribe( {
        next: () => {
          this.defaultProductSetup();
        },
        error: ( err ) => {
          console.error( err );
        },
      } );
    } else {
      this.defaultProductSetup();
    }

    this.getImpactClickID();
  }

  defaultProductSetup() {
    // set the local stripeProducts
    this.stripeProducts = this._subDetailsUtil.stripeProducts ?? [];
    // find the default product via the metadata defaultProduct tag
    this.defaultProduct = this.stripeProducts.find( ( p: StripeProduct ) => {
      return p.metadata.defaultProduct && p.active;
    } );
    // if there is no default product marked in stripe for some reason, grab the first active product from the list
    if ( !this.defaultProduct ) {
      for ( let i = 0; i < this.stripeProducts.length; i++ ) {
        if ( this.stripeProducts[ i ]?.active ) {
          this.defaultProduct = this.stripeProducts[ i ];
          break;
        }
      }
    }
    // get the first active pricing from the default product (should be monthly as they are sorted on the backend by unit_amount)
    for ( let i = 0; i < this.defaultProduct.prices.length; i++ ) {
      if ( this.defaultProduct.prices[ i ].active ) {
        this.defaultPricing = this.defaultProduct.prices[ i ];
        break;
      }
    }

    // if a deal was loaded, set up the deal now (if the deal's product or pricing doesn't load, the default will be used
    if ( this.deal ) {
      this.setupDealInfo( this.deal );
    }
    // -----------------------------------------------------------------------------------------------------------------
  }

  checkForQueryParams( route: ActivatedRoute ) {
    route.queryParams
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( params: Params ) => {
        // deal stuff
        let dealId = 'default';
        if ( params.dealId ) {
          dealId = params.dealId;
        }

        // they came from a link that is specifying a particular deal/stripe product
        this._promoCodeService.lookupDeal( dealId )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: ( resp: any ) => {
              const deal: StripeDeal = resp?.data;
              if ( deal && deal.product_id ) {
                this.setupDealInfo( deal );
              } else {
                // no deal returned so do nothing
              }
            },
            error: ( err ) => {
              console.error( err );
              this.loadingDealInfo = false;
            },
          } );

        /*
         * example url from adworda
         * http://localhost:4200/#/register?dealId=100&_source=adwordaSource&_subsource=1234567890&_name=testing%20adworda&_email=testing_adworda@ripsaw.co&_transaction_id=123456
         * */

        // ad provider stuff
        if ( params._source ) {
          Object.assign( this.userStatsState.preRegistrationStats, params );
        }
      } );


  }

  setupDealInfo( deal: StripeDeal ) {
    // if there is a deal, we need to set the default product and pricing to the one in the deal
    if ( deal ) {
      if ( deal.is_active ) {
        this.deal = deal; // set the deal now, so it will be there when the stripe products are retrieved and the function is called again
        // we don't want to make changes until the stripeProducts are loaded. this function will be called again when they are
        this.userStatsState.preRegistrationStats.dealId = deal.id;
        if ( this.stripeProducts.length > 0 ) {
          // find the product from the deal
          const dealProduct: StripeProduct = this.stripeProducts.find( ( p: StripeProduct ) => {
            return p.id === this.deal.product_id;
          } );
          // if there's no product in the deal, we don't need to do anything because this link is old, so just use the current default
          if ( dealProduct && dealProduct.active ) {
            this.defaultProduct = dealProduct;
            const dealPrice: StripePrice = dealProduct.prices.find( ( pr: StripePrice ) => {
              return pr.id === this.deal.pricing_id;
            } );
            // if there is no price that matches the one in the deal, just choose the first active price
            if ( dealPrice ) {
              this.defaultPricing = dealPrice;
            } else {
              // loop through and choose the first active price and break
              for ( let i = 0; i < dealProduct.prices.length; i++ ) {
                if ( dealProduct.prices[ i ].active ) {
                  this.defaultPricing = dealProduct.prices[ i ];
                  break;
                }
              }
            }
            if ( this.deal.promo_code ) {
              this.getPromoCode( this.deal.promo_code )
                .pipe( takeUntil( this.onDestroy ) )
                .subscribe( {
                  next: ( resp: any ) => {
                    this.dealPromoInfo = resp.data; // just need to set this class var and the verify function will do the rest when they hit
                                                    // register button
                    this.calculateDealCostAndNextBill();
                  },
                  error: ( err ) => {
                    console.error( err );
                    this.loadingDealInfo = false;
                    this.calculateDealCostAndNextBill();
                  },
                } );
            } else {
              this.calculateDealCostAndNextBill();
            }
          } else {
            // TODO: alert user the deal/product is no longer available
          }
        }
      } else {
        // TODO: alert user the deal is no longer available
      }
      this.loadingDealInfo = false;
    }

  }

  calculateDealCostAndNextBill() {
    if ( this.deal ) { // shouldn't really need this check, but let's be extra careful
      // no promo means simple cost of the unit of the pricing
      this.deal.calculatedCost = this.defaultPricing.unit_amount / 100; // stripe does unit amounts in integers for some annoying reason
      // we want this to prefer a promo code they enter
      if ( this.promoInfo?.coupon ?? this.dealPromoInfo?.coupon ) {
        // if there is a promo, we need to calculate the discount
        const coupon = this.promoInfo?.coupon ?? this.dealPromoInfo?.coupon;
        if ( coupon.percent_off !== null ) {
          this.deal.totalDiscount = ( coupon.percent_off / 100 ) * this.deal.calculatedCost;
        } else {
          this.deal.totalDiscount = ( coupon.amount_off / 100 );
        }
        this.deal.calculatedCost -= this.deal.totalDiscount;
      }
      // next bill will be in a month or a year
      this.deal.nextBill = ( this.defaultPricing.recurring?.interval === 'year' ? moment().add( 1, 'year' ) : moment().add( 1, 'month' ) ).toDate();
    }
  }

  getImpactClickID() {
    try {
      if ( window[ 'ire' ] ) {
        window[ 'ire' ]( 'generateClickId', ( clickId ) => {
          this.impactClickId = clickId;
          console.log( 'user came from impact tracking link. click id: ', this.impactClickId );
        } );
      } else {
        if ( this.triesForUTTCheck <= 5 ) {
          this.triesForUTTCheck++;
          setTimeout( () => {
            this.getImpactClickID();
          }, 1000 );
        }
      }
    } catch ( e ) {
      Logger.info( e );
    }
  }

  /*
   * Function that registers the user in our system and then with the aggregator
   * */
  async register( form: UntypedFormGroup, signUpButtonOptions: any, callback?: Function ): Promise<any> {
    let chosenProduct: StripeProduct = this.defaultProduct;
    if ( this.showTestFields && !this.deal ) {
      chosenProduct = form.controls.plan.value ?? this.defaultProduct;
      for ( let i = 0; i < chosenProduct.prices.length; i++ ) {
        if ( chosenProduct.prices[ i ].active ) {
          this.defaultPricing = chosenProduct.prices[ i ];
          break;
        }
      }
    }

    if ( form.valid && chosenProduct && this.defaultPricing ) {
      const registerData: RegisterDataInterface = {
        name: form.value.name,
        email: form.value.email,
        password: form.value.password,
        agreed_to_terms: form.value.terms,
        plan_id: chosenProduct.id,
        pricing_id: this.defaultPricing.id,
      };


      console.log( `INFO: registering user in ${ this.appName } system...` );
      signUpButtonOptions.disabled = true;
      signUpButtonOptions.text = 'Registering...';
      try {
        const registrationResponse = await lastValueFrom(
          this._registerService.register( registerData )
            .pipe( takeUntil( this.onDestroy ) ) );
        // console.log(registrationResponse);
        console.log( ` 'INFO: Registration in ${ this.appName } system complete'` );
        const data = registrationResponse.data;
        if ( data.token ) {
          // set session
          this.auth.setNewToken( data.token );
          try {
            await this.createPaymentSubscription( form );
          } catch ( stripeErr ) {
            console.error( stripeErr );
            this.stripeToken = undefined; // can't use a token more than once
            this.registrationError( stripeErr, signUpButtonOptions );
            return;
          }

          this._state.globalVars.firstTimeUser = true;
          this._state.notifyDataChanged( EVENT_NAMES.REGISTRATION_COMPLETED, null );

          if ( callback ) {
            console.log( 'finishing registration with callback provided...' );
            callback();
          } else {
            this._router.navigateByUrl( 'pages' ).catch( ( err ) => {
              console.error( err );
            } );
          }
        }
      } catch ( err ) {
        // console.log( error );
        this.registrationError( err || 'Unknown Error', signUpButtonOptions );
      }
      /*

       } ).catch( ( err ) => {
       console.error( err );
       this.stripeToken = undefined; // can't use a token more than once
       this.registrationError( err, signUpButtonOptions );
       } );
       }
       }*/


    }
  }

  registrationError( err, signUpButtonOptions: any ) {
    this.snackBar.open( `Error Registering: ${ err.err } | Please try again. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Object.assign(
      {},
      Util.getSnackBarOptions(),
      {
        duration: 60000,
      } ) );
    signUpButtonOptions.active = false;
    signUpButtonOptions.disabled = false;
    signUpButtonOptions.text = 'Sign Up';
  }

  /*
   * Function for creating a subscription payment for the user
   * */
  createPaymentSubscription( form: UntypedFormGroup ): Promise<void> {
    const self = this;
    return new Promise( ( resolve, reject ) => {
      console.log( 'INFO: Creating subscription with Stripe...' );
      let trialDays = this.defaultTrialDays;
      if ( this.showTestFields ) {
        if ( form.controls.trialDays.value && !isNaN( form.controls.trialDays.value ) && form.controls.trialDays.value > 0 ) {
          trialDays = form.controls.trialDays.value;
        }

      }

      if ( ( this.deal && !this.deal.has_trial ) ) {
        trialDays = 0;
      }

      console.log( 'impact_click_id: ', this.impactClickId );

      self._registerService.createPaymentSubscription( this.promoInfo ?? this.dealPromoInfo, trialDays, this.stripeToken, this.paymentMethod )
        .pipe( takeUntil( self.onDestroy ) )
        .subscribe( {
          next: ( subscriptionResp: any ) => {
            console.log( 'Stripe subscription creation completed' );
            const data = subscriptionResp.data;
            // console.log( data );
            if ( data.token ) {
              // set session
              self.auth.setNewToken( data.token );
              resolve();
            } else {
              reject( 'No token came back from the api' );
            }
          }, error: ( err ) => {
            reject( err );
          },
        } );
    } );
  }

  verifyPromoCode( form: UntypedFormGroup, checkPromoCodeButtonOptions: any ) {
    const code = ( form.controls.promoCode.value || '' ).trim();
    if ( code && code.length > 0 ) {
      checkPromoCodeButtonOptions.active = true;
      this.getPromoCode( code )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( couponResponse: any ) => {
            console.log( couponResponse );
            this.promoInfo = couponResponse.data;
            checkPromoCodeButtonOptions.active = false;
            if ( this.promoInfo ) {
              this.couponResultIcon = this.faCheckSquare;
              const discount = this.promoInfo.coupon.amount_off ?
                new RipsawCurrencyPipe().transform( this.promoInfo.coupon.amount_off / 100 ) :
                new RipsawPercentPipe().transform( this.promoInfo.coupon.percent_off / 100, '0-2' );
              this.couponResultMsg = `${ this.promoInfo.coupon.name } (${ discount })`;
            } else {
              this.couponResultIcon = this.faTimes;
              this.couponResultMsg = `${ code } not valid`;
            }
          }, error: ( err ) => {
            checkPromoCodeButtonOptions.active = false;
            this.promoInfo = undefined;
            this.couponResultIcon = this.faTimes;

            if ( err.err ) {
              this.couponResultMsg = err.err;
            } else if ( err.error ) {
              if ( err.error.message && err.error.message.message ) {
                this.couponResultMsg = err.error.message.message; // don't know why this is such a strange structure, but it is.}
              } else {
                this.couponResultMsg = JSON.stringify( err.error );
              }
            } else {
              this.couponResultMsg = `${ code } not valid. Try another code or contact support at ${ Util.getSupportAddress() }`;
            }
          },
        } );
    } else {
      this.couponResultMsg = 'Must enter a code';
    }
  }

  getPromoCode( code: string ): Observable<any> {
    const self = this;
    return new Observable( function subscribe( subscriber: Subscriber<any> ) {
      self._promoCodeService.getPromoCode( code )
        .pipe( takeUntil( self.onDestroy ) )
        .subscribe( {
          next: ( couponResponse: any ) => {
            console.log( couponResponse );
            subscriber.next( couponResponse );
            subscriber.complete();
          }, error: ( err ) => {
            subscriber.error( err );
          },
        } );
    } );
  }

}
