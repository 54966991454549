import { Action, createReducer, on } from '@ngrx/store';
import { initialWorkspaceState } from './state';
import {
  addCreatingIdsWorkspacesSuccess,
  addMyWorkspace,
  clearWorkspace,
  deleteMyWorkspaces, removeCreatingIdsWorkspaces,
  removeSharedWorkspaces,
  setLoadedWorkspace,
  setLoadedWorkspacePermissions,
  setMyWorkspaces,
  setSharedWorkspaces,
  updateIsRefreshingWorkspaces,
  updateMyWorkspace,
  updateSelectedShareeWorkspaces,
  updateSharedWorkspace,
} from './actions';
import { WorkspaceStore } from './models';
import { WorkspaceWithUsersDto } from '../../api';

export function workspaceReducerFn(state: WorkspaceStore, action: Action): WorkspaceStore {
  const reducer = createReducer(
    initialWorkspaceState,
    on(setLoadedWorkspace, ( s, { payload: { workspace, type } }): WorkspaceStore => {
      const { id, name } = workspace;
      return {
        ...s,
        loaded: { type, id, name, isPrimary: type === 'my' ? (workspace as WorkspaceWithUsersDto).isPrimary : false },
      };
    }),
    on(setLoadedWorkspacePermissions, ( s, { payload }): WorkspaceStore => ({...s, loadedPermissions: payload})),
    on(setMyWorkspaces, ( s, { payload }): WorkspaceStore => ({...s, my: payload})),
    on(addMyWorkspace, ( s, { payload }): WorkspaceStore => ({...s, my: [...s.my, payload]})),
    on(updateMyWorkspace, ( s, { payload }): WorkspaceStore => {
      const idx = s.my.findIndex(el => el.id === payload.id);
      const updated = [...s.my];
      updated[idx] = payload;

      return {...s, my: updated};
    }),
    on(deleteMyWorkspaces, ( s, { payload: ids }): WorkspaceStore => {
      return {...s, my: s.my.filter(el => !ids.find(id => el.id === id))};
    }),
    on(setSharedWorkspaces, ( s, { payload }): WorkspaceStore => ({...s, shared: payload})),
    on(updateSharedWorkspace, ( s, { payload }): WorkspaceStore => {
      const idx = s.shared.findIndex(el => el.id === payload.id);
      const updated = [...s.shared];
      updated[idx] = payload;

      return {...s, shared: updated};
    }),
    on(removeSharedWorkspaces, ( s, { payload: ids }): WorkspaceStore => {
      return {...s, shared: s.shared.filter(el => !ids.find(id => el.id === id))};
    }),
    on(updateIsRefreshingWorkspaces, ( s, { payload }): WorkspaceStore => ({...s, isRefreshing: payload})),
    on(updateSelectedShareeWorkspaces, ( s, { payload }): WorkspaceStore => ({...s, selectedSharee: payload})),
    on(addCreatingIdsWorkspacesSuccess, ( s, { payload }): WorkspaceStore => {
      return {...s, creatingIds: [...s.creatingIds, ...payload]};
    }),
    on(removeCreatingIdsWorkspaces, ( s, { payload }): WorkspaceStore => {
      return {...s, creatingIds: s.creatingIds.filter(crId => !payload.find(remId => remId === crId))};
    }),
    on(clearWorkspace, () => initialWorkspaceState),
  );

  return reducer(state, action);
}
