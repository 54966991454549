import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Util } from '../../../../../utils/util.service';
import { AccountManager } from '../../../../../utils/accountManager';
import { MortgageHelpers } from '../../../../../utils/mortgageHelpers';
import { RipsawCurrencyPipe, RipsawPercentPipe } from '../../../../../theme/pipes';
import { GlobalState } from '../../../../../global.state';
import { Subject } from 'rxjs';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons';
import { AccountDataGridComponent } from '../../../../accounts/components/accountDetail/components/accountDataGrid';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';
import { GlossaryUtil } from '../../../../../utils/glossary.util';
import { TreasuryRatesUtil } from '../../../../../utils/treasury-rates.util';
import { EVENT_NAMES } from '../../../../../utils/enums';
import { ManualAccountFormsHelper } from '../../manualAccountFormsHelper';
import { Account, FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-annuity-form',
  templateUrl: './annuityForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class AnnuityFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();
  faQuestionCircle = faQuestionCircle;
  faPiggyBank = faPiggyBank;

  @Input() form: UntypedFormGroup;
  @Input() label: string;
  @Input() matcher: any;
  @Input() type: string;
  @Input() account: Account;
  @Input() inRevision: boolean = false;

  publicUtil: typeof Util;
  showSourceControl: boolean = false;
  sourceAccountFormControl: UntypedFormControl;
  purchasePriceSourceGrid: AccountDataGridComponent;
  sourceAccounts: any[] = [];

  paymentFrequencySelectOptions: any[] = ManualAccountFormsHelper.getFrequencySelectOptions();

  /*
   * Validates that the account's available cash is greater than the amount in the form control
   * */
  availableBalanceValidator( annuityForm: AnnuityFormComponent ): ValidatorFn {
    return () => {
      let isValid;
      let account; // account needs to be the source account where the purchase amount is coming from.

      // if otherAccountGrid is not defined, the user hasn't chosen a source account yet, so we can return null to
      // mean that the field is valid
      if ( annuityForm.purchasePriceSourceGrid ) {
        account = annuityForm.purchasePriceSourceGrid.account;
      } else {
        return null;
      }

      const sourcePosition = Util.accountIsInvestment( account ) ? account.non_allocated_funds : account.positions[ 0 ];
      if ( !sourcePosition || !account ) {
        return {
          availableBalanceValidator: {
            valid: false,
          },
        };
      }

      // cost_basis is purchase amount
      const annuityValue = FormsUtil.getSanitizedFloatValue( annuityForm.form.controls.cost_basis.value );
      const availableBalance = sourcePosition.revised_value;
      isValid = availableBalance >= annuityValue;

      if ( isValid ) {
        return null;
      } else {
        return {
          availableBalanceValidator: {
            valid: false,
          },
        };
      }
    };
  }

  showAnnuityWarning: boolean;

  subscriberName: string = 'annuityForm';

  constructor( private _accountManager: AccountManager,
               private _state: GlobalState,
               private glossaryUtil: GlossaryUtil,
               public treasuryRatesUtil: TreasuryRatesUtil ) {
    super();
    this.publicUtil = Util;
  }

  ngOnDestroy(): void {
    this.unsubscribeFromUpdate( this._state );
    this.onDestroy.next();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();
    } );
    this.setupUpdateSubscription( this._state );
  }


  patchForm(): void {
    // this.form.controls.expected_remaining_life.disable();
    if ( this.account ) {

      ManualAccountUtil.patchAnnuityForm( this.form, this.account );
      this.birthDateChanged(); // call this to figure out today's remaining life
      // this.form.controls.treasury_rate.setValue( new RipsawPercentPipe().transform( this.account.positions[0].treasury_rate ) );
      Util.updateInputPercentFormat( 'treasury_rate', this.form );
      this.getPVOfAnnuity();
      this.updateExistingAccountValues();
      this.showAnnuityWarning =
        this.inRevision && this.form.controls.annuity_type.value && this.form.controls.annuity_type.value.toLowerCase() === 'annuity';
    } else {
      this.form.controls.quantity.setValue( 1 );
      this.autofocus();
    }
  }

  typeChanged() {
    if ( this.inRevision && this.form.controls.annuity_type.value === 'annuity' ) {
      this.addSourceControlToForm();
      this.showSourceControl = true;
      this.showAnnuityWarning = true;
    } else {
      this.removeSourceControlFromForm();
      this.showSourceControl = false;
      this.showAnnuityWarning = false;
    }

    if ( this.form.controls.annuity_type.value === 'annuity' || this.form.controls.annuity_type.value === 'pension' ) {
      this.form.controls.sector.setValue( 'bond_primary_sector_corporate_bond' );
    } else {
      this.form.controls.sector.setValue( 'bond_primary_sector_government' );
    }
  }

  addSourceControlToForm() {
    this.sourceAccountFormControl = new UntypedFormControl( '', Validators.compose( [
      Validators.required,
      this.availableBalanceValidator( this ),
    ] ) );
    this.form.addControl( 'cost_basis', new UntypedFormControl( '', Validators.required ) );
    this.filterAccounts();
    this.form.addControl( 'sourceAccount', this.sourceAccountFormControl );
  }

  removeSourceControlFromForm() {
    this.form.removeControl( 'sourceAccount' );
    this.form.removeControl( 'cost_basis' );
  }

  checkSourceAmount() {
    if ( this.sourceAccountFormControl ) {
      const sourceAccountId = this.sourceAccountFormControl.value;
      if ( sourceAccountId ) {
        this.purchasePriceSourceGrid = this._state.globalVars.accountDataGrids[ sourceAccountId ];
        if ( this.purchasePriceSourceGrid && this.form.controls.cost_basis.value ) {
          this.sourceAccountFormControl.updateValueAndValidity();
          this.sourceAccountFormControl.markAsTouched();
        }
      }
    }
  }

  filterAccounts() {
    this.sourceAccounts = [];
    this._accountManager.getAllRevisableAccounts().forEach( ( a: any ) => {
      if ( !Util.accountIsCreditCard( a ) && !Util.accountIsLoan( a )
        && !Util.accountIsRealAsset( a ) && this.accountHasEnoughBalance( a ) ) {
        this.sourceAccounts.push( {
          account_id: a.account_id,
          formattedDescription: a.formattedDescription,
          availableBalance: this.getAvailableBalance( a ),
        } );
      }
    } );
  }

  accountHasEnoughBalance( a: any ) {
    const balanceNeeded = FormsUtil.getSanitizedFloatValue( this.form.controls.cost_basis.value );
    return this.getAvailableBalance( a ) >= ( balanceNeeded || 0 );
  }

  getAvailableBalance( a: any ) {
    const sourcePosition = Util.accountIsInvestment( a ) ? a.non_allocated_funds : a.positions[ 0 ];
    return sourcePosition.revised_value;
  }

  birthDateChanged() {
    if ( this.form.getRawValue().joint_birth_date ) {
      this.compareRemainingLifeExpectancies();
    } else {
      if ( this.form.getRawValue().birth_date ) {
        const age = Util.convertBirthdateToAge( this.form.controls.birth_date.value );
        this.form.controls.expected_remaining_life.setValue( ManualAccountUtil.lifeExpectancyMap[ Math.floor( age ) ][ this.form.controls.gender.value ]?.life_expectancy );
        this.figureOutRate();
      }
    }
  }

  jointBirthDateChanged() {
    this.compareRemainingLifeExpectancies();
  }

  compareRemainingLifeExpectancies() {
    const formValues = this.form.getRawValue();
    if ( formValues.birth_date && formValues.joint_birth_date ) {
      const jointAge = Util.convertBirthdateToAge( this.form.controls.joint_birth_date.value );
      const jointRemainingLife = ManualAccountUtil.lifeExpectancyMap[ Math.floor( jointAge ) ][ this.form.controls.joint_gender.value ]?.life_expectancy;
      const age = Util.convertBirthdateToAge( this.form.controls.birth_date.value );
      const primaryExpectancy = ManualAccountUtil.lifeExpectancyMap[ Math.floor( age ) ][ this.form.controls.gender.value ]?.life_expectancy;
      if ( jointRemainingLife > primaryExpectancy ) {
        this.form.controls.expected_remaining_life.setValue( jointRemainingLife );
      } else {
        this.form.controls.expected_remaining_life.setValue( primaryExpectancy );
      }
      this.figureOutRate();
    } else {
      console.warn( 'Both birth dates are not yet filled out. cannot compare' );
    }
  }

  paymentChanged() {
    this.getPVOfAnnuity();
  }

  paymentFrequencyChanged() {
    this.getPVOfAnnuity();
  }

  remainingLifeChanged() {
    this.figureOutRate();
  }

  figureOutRate() {
    if ( this.treasuryRatesUtil.treasuryRates ) {
      this.interpolateDurationMatchedTreasuryRate( this.treasuryRatesUtil.treasuryRates );
    } else {
      this._state.subscribe( EVENT_NAMES.TREASURIES_RETRIEVED, () => {
        // this should only be needed once
        this._state.unsubscribe( EVENT_NAMES.TREASURIES_RETRIEVED, this.subscriberName );
        this.interpolateDurationMatchedTreasuryRate( this.treasuryRatesUtil.treasuryRates );
      }, this.subscriberName );
    }
  }

  interpolateDurationMatchedTreasuryRate( rates: any ) {
    const remainingLife = this.form.getRawValue().expected_remaining_life;

    const durationMatchedRate = ManualAccountUtil.interpolateDurationMatchedTreasuryRate( rates, remainingLife );

    this.form.controls.treasury_rate.setValue( new RipsawPercentPipe().transform( durationMatchedRate, '2-2' ) );
    Util.updateInputPercentFormat( 'treasury_rate', this.form );
    this.form.controls.duration_matched_treasury_rate.setValue( FormsUtil.sanitizeInput( this.form.getRawValue().treasury_rate ) );
    this.getPVOfAnnuity();
  }

  treasuryRateChanged() {
    Util.updateInputPercentFormat( 'treasury_rate', this.form );
    this.form.controls.duration_matched_treasury_rate.setValue( FormsUtil.sanitizeInput( this.form.getRawValue().treasury_rate ) );
    this.getPVOfAnnuity();
  }

  getPVOfAnnuity() {
    const formValues = this.form.getRawValue();
    if ( formValues.payment && formValues.treasury_rate && formValues.expected_remaining_life && formValues.payment_frequency ) {
      // p * ((1 - (1 + r) ^ -n) / r)
      const freq = MortgageHelpers.translatePeriodType( formValues.payment_frequency );
      const P = parseFloat( FormsUtil.sanitizeInput( formValues.payment ) );
      const r = parseFloat( formValues.duration_matched_treasury_rate ) / ( freq * 100 );
      const n = parseFloat( formValues.expected_remaining_life ) *
        freq;
      const numerator = 1 - Math.pow( ( 1 + r ), -1 * n );
      const pv = P * ( numerator / r );
      this.form.controls.value.setValue( new RipsawCurrencyPipe().transform( pv ) );
      this.form.controls.price.setValue( this.form.controls.value.value );

      if ( this.type.toLowerCase() === 'annuity' && this.form.controls.cost_basis ) {
        this.form.controls.cost_basis.setValue( this.form.controls.value.value );
      }

    } else {
      console.warn( 'all required fields are not filled out' );
    }
    this.checkSourceAmount();
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }

  /* Function for updating global instances of account on load */
  updateExistingAccountValues() {
    ManualAccountManagerState.setFieldsForAccountAndPosition( this.account, this.account.positions[ 0 ], this.form, this.treasuryRatesUtil.treasuryRates );
    this.account.positions[ 0 ].value = this.account.positions[ 0 ].quantity * this.account.positions[ 0 ].price;
    this.account.positions[ 0 ].revised_value = this.account.positions[ 0 ].value;
    this.account.positions[ 0 ].revised_difference = 0;
    this.account.positions[ 0 ].revised_quantity = 0;
    this.account.positions[ 0 ].revised_allocation = 1;
    this.account.positions[ 0 ].buySell = '';
    this.account.positions[ 0 ].optimizerConstraints = '';
    this._accountManager.updateManualAccountFields( this.account );

  }

  /*  showPensionWarning() {
   return this.inRevision && this.form.controls.annuity_type.value.toLowerCase() !== 'annuity';
   }*/

}
