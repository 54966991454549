import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { Auth } from '../../auth.service';
import { GlobalState } from '../../global.state';
import { Router } from '@angular/router';
import { Util } from '../../utils/util.service';
import { GlobalDataService } from '../../globalData';
import { AccountManager } from '../../utils/accountManager';
import { environment } from '../../../environments/environment';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { InstitutionIconComponent } from '../../reusableWidgets/institutionIcon';
import { Connection } from '../../utils/dataInterfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AccountSettingsDialogComponent } from '../accounts/components/accountSettingsDialog/accountSettingsDialog.component';
import { FastLinkUtil } from '../../utils/fastLink.util';
import { EVENT_NAMES } from '../../utils/enums';
import { AppStoreService } from '../../store';
import { BenchmarkState } from '../../utils/benchmark.state';
import { WorkspaceLoadedStore } from '../../store/workspace';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Account, User } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from '../../utils/logger.service';

@Component( {
  selector: 'rip-balance-sheet',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './balanceSheet.component.html',
  styleUrls: [ './balanceSheet.scss' ],
} )
export class BalanceSheetComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy: Subject<void> = new Subject<void>();

  loadedWorkspace$: Observable<WorkspaceLoadedStore> =
    this.appStoreService.loadedWorkspace$;

  @ViewChildren( InstitutionIconComponent )
  institutionIcons: QueryList<InstitutionIconComponent>;
  @ViewChild( 'balanceSheetBody', { static: false } )
  balanceSheetBody: ElementRef;

  faSyncAlt: IconDefinition = faSyncAlt;
  faPrint: IconDefinition = faPrint;
  faCog: IconDefinition = faCog;
  faDollarSign: IconDefinition = faDollarSign;
  faCircleNotch: IconDefinition = faCircleNotch;

  loadingSummary: boolean;
  progressValue: number = 10;
  assetTotal: any = {};
  liabilitiesTotal: any = {};
  netWorth: any = {};
  assetAccountsLength: number = 0;
  liabilitiesAccountsLength: number = 0;
  assetAccounts: Account[] = [];
  liabilityAccounts: Account[] = [];
  refreshing: boolean = false;

  today: Date = new Date();
  small: string = 'small';
  medium: string = 'medium';
  large: string = 'large';

  dateFormat: string = '';

  user: User;

  subscriberName: string = 'balanceSheetComponent';

  firstRefreshDone: boolean = false;

  maxHeight: string;

  resizeTimeout;

  appName: string;

  wealthPortfolioName: string = '';

  @HostListener( 'window:resize', [ '$event' ] )
  onResize( /*event: Event*/ ) {
    clearTimeout( this.resizeTimeout );
    this.resizeTimeout = setTimeout( () => {
      this.resizeAccountDescriptions();
    }, 500 );
  }

  resizeAccountDescriptions( truncationLimit?: number ) {
    if ( this.deviceIsMobile && !truncationLimit ) {
      truncationLimit = window.innerWidth / 14;
    }
    for ( const a of [ ...this.assetAccounts, ...this.liabilityAccounts ] ) {
      a.formattedBalanceSheetDescription = this.formatAccountName(
        a,
        truncationLimit,
      );
    }
  }

  statusMap: any = {};
  deviceInfo: any;

  readonly spinnerSelector: string = 'balance-sheet-spinner';

  repairButtonClicked: boolean = false;
  institutionIconClicked: boolean = false;
  refreshCancelButtonClicked: boolean = false;

  accountStatusCheckInterval: any;

  deviceIsMobile: boolean = false;

  spinnerOptions: any = {};

  accountBeingHovered: string | number = '';

  dataRetrieved: boolean = false;

  constructor(
    private _gdService: GlobalDataService,
    private _auth: Auth,
    private _state: GlobalState,
    private _router: Router,
    public accountManager: AccountManager,
    private _elRef: ElementRef,
    private _cd: ChangeDetectorRef,
    private _detectorService: DeviceDetectorService,
    private dialog: MatDialog,
    private fastLinkUtil: FastLinkUtil,
    public snackBar: MatSnackBar,
    private appStoreService: AppStoreService,
    private benchmarkState: BenchmarkState,
  ) {
    this.accountManager.allDataRetrieved
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( val: boolean ) => {
          this.dataRetrieved = val;
        },
      } );
    this.loadingSummary = true;
    this.user = Util.getLoggedInUser( this._auth );
    this.statusMap = _state.statusMap;
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_balanceSheet' ] = this;
    }
    this.dateFormat = _state.syncTimeStampFormat;

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    if ( this.deviceIsMobile ) {
      this.spinnerOptions = {
        repositionSpinner: { top: '45%', left: '30%' },
      };
    } else {
      this.spinnerOptions = {
        repositionSpinner: { top: '40%', left: '43%' },
      };
    }

    this.appName = environment.appName;
    this.showProgressAfterWorkspaceLoad();
  }


  ngAfterViewInit(): void {
    setTimeout( () => {
      this._state.subscribe(
        [
          EVENT_NAMES.RECALCULATE_ALLOCATIONS,
          EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
          'account.settings.updated',
          EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
        ].join( ' | ' ),
        () => {
          this.getAccountsSummary();

          this.syncOnLogin();
        }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.PROGRESS_UPDATE, ( progress: number ) => {
        this.progressValue = progress;
      }, this.subscriberName );

      this._state.subscribe(
        EVENT_NAMES.DETECT_ACCOUNT_CHANGES,
        () => {
          this.doChanges();
        },
        this.subscriberName,
      );

      this._state.subscribe(
        EVENT_NAMES.ACCOUNTS_LOADING_ERROR,
        () => {
          this.dataRetrieved = true;
        }, this.subscriberName );

      if ( this._state.globalVars.firstAccountPullComplete ) {
        this.getAccountsSummary();
      }
    } );
  }


  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    // this._cd.markForCheck();
    this._cd.reattach();
  }

  ngOnDestroy(): void {
    this._state.unsubscribe(
      [
        EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
        EVENT_NAMES.RECALCULATE_ALLOCATIONS,
        EVENT_NAMES.PROGRESS_UPDATE,
        EVENT_NAMES.WIDGET_RESIZE,
        EVENT_NAMES.DETECT_ACCOUNT_CHANGES,
        EVENT_NAMES.ACCOUNTS_LOADING_ERROR,
        'account.settings.updated',
        EVENT_NAMES.MANUAL_ACCOUNT_DELETED,
      ].join( ' | ' ),
      this.subscriberName,
    );
    clearInterval( this.accountStatusCheckInterval );
    this.accountStatusCheckInterval = undefined;
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getAccountsSummary() {
    this.loadingSummary = true;
    this.progressValue = 80;
    this.computeSummary();
    this.progressValue = 100;
    this.loadingSummary = false;
    if ( !this.firstRefreshDone ) {
      this.updateWorkspaceStatistics();
    }
    this.resizeAccountDescriptions();
    if ( !this.accountStatusCheckInterval ) {
      this.startAccountStatusCheckInterval();
    }
    this.setCardTitle();

  }

  computeSummary() {
    const accounts = this.accountManager.getAllRevisableAccounts();

    const summary: any = {
      netWorth: 0,
      totalLiabilities: 0,
      totalAssets: 0,
      bankTotal: 0,
      loanTotal: 0,
      insuranceTotal: 0,
      investmentTotal: 0,
      realAssetTotal: 0,
      accounts,
      realAssetAccounts: [],
      bankAccounts: [],
      loanAccounts: [],
      investmentAccounts: [],
      insuranceAccounts: [],
      totals: [],
    };

    if ( accounts && accounts.length > 0 ) {
      // Logger.info( `source|ticker|price|quantity|value` );
      for ( const a of accounts ) {
        this.updateAccountValue( a );
        switch ( a.account_category ) {
          case 'Investment':
            if ( !isNaN( a.value ) ) {
              summary.investmentTotal += a.value;
            }
            summary.investmentAccounts.push( a );
            break;
          case 'Banking':
            if ( a.account_type === 'Credit Card' && a.value <= 0 ) {
              const aValue = Math.abs( a.value );
              if ( !isNaN( aValue ) ) {
                summary.loanTotal += aValue;
              }
              summary.loanAccounts.push( {
                description: `${ this.formatAccountDescription( a ) }`,
                formattedDescription: a.formattedDescription,
                newlyAdded: a.newlyAdded,
                name: a.name,
                value: aValue,
                account_id: a.account_id,
                status: a.status,
                statusIndicator: a.statusIndicator,
                last_good_sync: a.last_good_sync,
                updated_at: a.updated_at,
                connection_id: a.connection_id,
                institution_id: a.institution_id,
                isManual: a.isManual,
                favicon: a.favicon,
                faIcon: a.faIcon,
                fullAccount: a,
              } );
            } else {
              if ( !isNaN( a.value ) ) {
                summary.bankTotal += a.value;
              }
              summary.bankAccounts.push( a );
            }
            break;
          case 'Insurance':
            if ( !isNaN( a.value ) ) {
              summary.insuranceTotal += a.value;
            }
            summary.insuranceAccounts.push( {
              description: `${ this.formatAccountDescription( a ) } (Fair Value)`,
              formattedDescription: `${ a.formattedDescription } (Fair Value)`,
              newlyAdded: a.newlyAdded,
              name: a.name,
              value: a.value,
              account_id: a.account_id,
              status: a.status,
              statusIndicator: a.statusIndicator,
              last_good_sync: a.last_good_sync,
              updated_at: a.updated_at,
              connection_id: a.connection_id,
              institution_id: a.institution_id,
              isManual: a.isManual,
              favicon: a.favicon,
              faIcon: a.faIcon,
              fullAccount: a,
            } );
            break;
          case 'Loan':
            const p = a.positions[ 0 ];
            if ( p ) {
              let value;

              value = Math.abs(
                p.outstanding_balance ?? p.cost_basis ?? a.value,
              );

              if ( !isNaN( value ) ) {
                summary.loanTotal += value;
              }

              summary.loanAccounts.push( {
                description: `${ this.formatAccountDescription( a ) } (Book Value)`,
                name: a.name,
                formattedDescription: `${ a.formattedDescription } (Book Value)`,
                newlyAdded: a.newlyAdded,
                account_id: a.account_id,
                value,
                status: a.status,
                statusIndicator: a.statusIndicator,
                last_good_sync: a.last_good_sync,
                updated_at: a.updated_at,
                connection_id: a.connection_id,
                institution_id: a.institution_id,
                isManual: a.isManual,
                favicon: a.favicon,
                faIcon: a.faIcon,
                positions: a.positions,
                fullAccount: a,
              } );
            }
            break;
          case 'Other':
            if (
              [ 'Real Estate', 'Vehicle', 'Valuable(s)', 'Crypto' ].includes(
                a.account_type,
              )
            ) {
              summary.realAssetTotal += a.value;
              summary.realAssetAccounts.push( a );
            }
            break;
          default:
            Logger.log( 'Unknown account_type!' );
        }
      }
      // Logger.info( `End Loop =====================================================` );
    }
    summary.totalLiabilities = summary.loanTotal;
    summary.totalAssets =
      summary.bankTotal +
      summary.investmentTotal +
      summary.insuranceTotal +
      summary.realAssetTotal;

    summary.netWorth = summary.totalAssets - summary.totalLiabilities;

    this.assetTotal = {
      description: 'TOTAL ASSETS',
      value: summary.totalAssets,
    };
    this.liabilitiesTotal = {
      description: 'TOTAL LIABILITIES',
      value: summary.totalLiabilities,
    };
    this.netWorth = { description: 'NET WORTH', value: summary.netWorth };

    this.assetAccountsLength = 0;
    this.liabilitiesAccountsLength = 0;

    if ( summary.bankAccounts ) {
      this.assetAccountsLength += summary.bankAccounts.length;
    }
    if ( summary.investmentAccounts ) {
      this.assetAccountsLength += summary.investmentAccounts.length;
    }
    if ( summary.realAssetAccounts ) {
      this.assetAccountsLength += summary.realAssetAccounts.length;
    }
    if ( summary.insuranceAccounts ) {
      this.assetAccountsLength += summary.insuranceAccounts.length;
    }
    if ( summary.loanAccounts ) {
      this.liabilitiesAccountsLength += summary.loanAccounts.length;
    }
    this.assetAccounts = [
      ...summary.bankAccounts,
      ...summary.investmentAccounts,
      ...summary.realAssetAccounts,
      ...summary.insuranceAccounts,
    ];
    this.liabilityAccounts = [ ...summary.loanAccounts ];

    const compareFunc = ( a: Account, b: Account ) => {
      if ( a.isManual && !b.isManual ) {
        return 1;
      }
      if ( !a.isManual && b.isManual ) {
        return -1;
      }
      if ( a.institution_name === b.institution_name ) {
        return a.name > b.name ? 1 : -1;
      }
      return a.institution_name > b.institution_name ? 1 : -1;
    };

    this.assetAccounts.sort( compareFunc );

    this.liabilityAccounts.sort( compareFunc );
  }

  formatAccountDescription( acct: any ) {
    return Util.formatAccountDescription( acct );
  }

  goToAccount( account: Account ): void {
    if (
      this.repairButtonClicked ||
      this.institutionIconClicked ||
      this.refreshCancelButtonClicked
    ) {
      this.repairButtonClicked = false;
      this.institutionIconClicked = false;
      this.refreshCancelButtonClicked = false;
    } else {
      if ( !this.deviceIsMobile ) {
        const location = String( account.account_id );
        this._router
          .navigate( [ 'pages/accounts' ], { queryParams: { location } } )
          .catch( err => {
            console.error( err );
          } );
      } else {
        this._state.notifyDataChanged(
          EVENT_NAMES.OPEN_MOBILE_ACCOUNT_DETAILS,
          account,
        );
      }
    }
  }

  syncAllConnections() {
    if ( this.loadingSummary || this.refreshing ) {
      this.checkSyncStatus( 0 );
      return;
    }
    Logger.log( 'Syncing connections...' );
    this.refreshing = true;
    this._gdService
      .syncAllConnections()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          Logger.log( 'connections synced' );
          Logger.log( resp );
          this.checkSyncStatus( 0 );
        },
        error: ( err: any ) => {
          Logger.log( err.err );
          this.accountManager.getAccounts();
          this.refreshing = false;
        },
      } );
  }

  checkSyncStatus( tries: number ) {
    this._gdService
      .checkSyncProgress()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( progResp: any ) => {
          let stillSyncing = false;
          if ( progResp.data && progResp.data.length ) {
            for ( const sync of progResp.data ) {
              if (
                sync.status === 'LOGIN_IN_PROGRESS' ||
                sync.status === 'IN_PROGRESS'
              ) {
                stillSyncing = true;
                this.dataRetrieved = false;
              }
            }
            Logger.log( `still syncing... (try # ${ tries }) ` );
            if ( stillSyncing && tries < 5 ) {
              setTimeout( () => {
                this.checkSyncStatus( tries + 1 );
              }, 1000 );
            } else {
              this.refreshing = false;
              this.accountManager.isSyncingOnLogin.next( false );
              this.accountManager.getAccounts();
            }
          } else {
            this.refreshing = false;
            this.accountManager.isSyncingOnLogin.next( false );
            this.accountManager.getAccounts();
          }
        },
        error: err => {
          Logger.log( err.err );
          this.refreshing = false;
          this.accountManager.isSyncingOnLogin.next( false );
          this.accountManager.getAccounts();
        },
      } );
  }

  abs( value ) {
    return Math.abs( value );
  }

  openFastLinkForRepairingAccount( account: any ) {
    this.repairButtonClicked = true;
    // console.log( `open connect to repair connection: ${account.connection_id}` );
    // console.log( this._accountManager.getConnect() );
    if ( this.deviceIsMobile ) {
      this._state.notifyDataChanged(
        EVENT_NAMES.OPEN_MOBILE_FASTLINK,
        account.connection_id,
      );
    } else {
      this.fastLinkUtil.openFastlinkDialog( account.connection_id );
    }
  }

  formatAccountName( account: any, truncationLimit?: number ) {
    const sheetElement = this._elRef.nativeElement.firstChild;
    const width = sheetElement.scrollWidth;
    // 1 character is about 8 pixels wide. multiply by .8 for 80% of the balance sheet width
    let multiplier = this.deviceIsMobile ? 0.4 : 0.5;
    if (
      account.status !== 'SUCCESS' &&
      account.status !== 'PARTIAL_SUCCESS' &&
      this.deviceIsMobile
    ) {
      multiplier *= 0.8;
    }

    const truncNum = truncationLimit
      ? truncationLimit
      : ( width / 9 ) * multiplier;
    return Util.formatAccountDescription(
      account,
      this.deviceIsMobile,
      truncNum,
    );
  }

  updateWorkspaceStatistics() {
    this.benchmarkState.benchmark.net_worth = this.netWorth.value;
    this.benchmarkState.benchmark.total_assets = this.assetTotal.value;
    this.benchmarkState.benchmark.total_liabilities =
      this.liabilitiesTotal.value;

    this.benchmarkState.saveBenchmark( true );
  }

  updateAccountValue( account: any ) {
    if ( Util.accountIsLoan( account ) ) {
      return;
    }
    let accountValue = 0;
    for ( const p of account.positions ) {
      if ( !p.value ) {
        p.value = p.price * p.quantity;
      }
      // Logger.info( `|BalanceSheet|${ p.ticker }|${ p.price }|${ p.quantity }|${ p.value }` );
      accountValue += p.value;
    }
    // Logger.info( `${ account.name + account.description }|${ accountValue }` );
    account.value = accountValue + account.cashFromOtherAccounts;
  }

  refreshAccount( account: Account, institutionIcon: InstitutionIconComponent ) {
    this.institutionIconClicked = true;
    this.accountManager.refreshAccount(
      account,
      institutionIcon,
      this.snackBar,
      () => {
        this.getAccountsSummary();
      },
    );
  }

  stopRefreshButtonClicked() {
    this.refreshCancelButtonClicked = true;
  }

  startAccountStatusCheckInterval() {
    this.accountStatusCheckInterval = setInterval( () => {
      this.accountManager.accountStatusIntervalFunction(
        this.accountManager,
        this.institutionIcons,
        this.snackBar,
      );
    }, 10000 );
  }

  shouldAutoInitiateSync(): boolean {
    return (
      ( this.assetAccountsLength !== 0 ||
        this.liabilitiesAccountsLength !== 0 ) &&
      !this._state.globalVars.firstTimeUser &&
      !this._state.globalVars.haveInitiatedFirstSync &&
      environment.env !== 'dev'
    );
  }

  prePrintHook( caller: BalanceSheetComponent ) {
    caller.resizeAccountDescriptions( 55 );
    caller.doChanges();
  }

  postPrintHook( caller: BalanceSheetComponent ) {
    caller.resizeAccountDescriptions();
    caller.doChanges();
  }

  pdfCSSLocation: string = `assets/css/printBalanceSheetStyles.css`;

  openAccountSettingsDialog( account: Account ) {
    const ref = this.dialog.open( AccountSettingsDialogComponent, {
      data: {
        account,
      },
      disableClose: true,
      hasBackdrop: true,
    } );

    ref.afterClosed().subscribe( result => {
      if ( result ) {
        Logger.log( result );
      }
    } );
  }

  openFastLinkForMobile(): void {
    if ( this.deviceIsMobile ) {
      this._state.notifyDataChanged( EVENT_NAMES.OPEN_MOBILE_FASTLINK, null );
    } else {
      this.fastLinkUtil.openFastlinkDialog();
    }
  }

  private showProgressAfterWorkspaceLoad(): void {
    this.appStoreService.loadedWorkspace$
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( () => {
        this.dataRetrieved = false;
      } );
  }

  private setCardTitle() {
    this.wealthPortfolioName =
      this.benchmarkState.benchmark.wealthPortfolioName;
  }

  protected readonly Array = Array;

  private syncOnLogin(): void {
    if ( this.shouldAutoInitiateSync() ) {
      if ( this._state.globalVars.allConnections.length ) {
        const connectionsToSync: Connection['id'][] =
          this._state.globalVars.allConnections.reduce(
            ( acc, connection: Connection ) => {
              if ( connection.syncLogin ) {
                return [ ...acc, connection.id ];
              }
              return acc;
            },
            [],
          );
        if ( connectionsToSync.length ) {
          this.refreshing = true;
          this.accountManager.isSyncingOnLogin.next( true );
          this.accountManager.allDataRetrieved.next( false );
          this._gdService
            .syncUserConnectionsLogin( connectionsToSync )
            .pipe( finalize( () => this.checkSyncStatus( 0 ) ) )
            .subscribe();
        }
      }
      this._state.globalVars.haveInitiatedFirstSync = true;
    }
  }
}
