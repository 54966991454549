  <h3 mat-dialog-title>Add Exposure Wizard</h3>
  <mat-divider class="modal-header-divider"></mat-divider>
  <div mat-dialog-content class="modal-body" id="targetEditorBody">
    <div id="add-exposure-wizard-container">
      <h3>Add Risk Exposure: {{ exposureBeingAdded?.riskExposure }}
        <span *ngIf="selectedTickers && selectedTickers.length > 0">
          -> {{ selectedTickers.join(',') }}
        </span>
      </h3>
      <nb-stepper #stepper>
        <nb-step #step1>
          <div style="margin: 10px auto;">
            <rip-benchmark-exposure-choices #benchmarkExposureChoices *ngIf="showBenchmarkExposureChoices"
                                            [exposure]="exposureBeingAdded"
                                            (addProxySecurity)="addProxySelection($event)"
                                            (goToScreener)="startSecuritySelection($event)">

            </rip-benchmark-exposure-choices>
            <div *ngIf="!showBenchmarkExposureChoices">
              <div *ngIf="showExposureChoices">
                <rip-exposure-choices #exposureChoices
                                      [exposure]="exposureBeingAdded"
                                      [allUnderweightExposures]="underweightExposures"
                                      (goToScreener)="startSecuritySelection(null, $event)">

                </rip-exposure-choices>
              </div>
              <div *ngIf="!showExposureChoices">
                <!-- currently, we skip this step -->
                <p>
                  Click the next button to choose a new investment from the screener to add this exposure to your
                  portfolio
                </p>
                <button mat-raised-button (click)="startSecuritySelection()">
                  Next
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </nb-step>
        <nb-step #step2>
          <rip-new-investment-selector #investmentSelector></rip-new-investment-selector>
          <button mat-raised-button (click)="stepper.previous()">
            <mat-icon>chevron_left</mat-icon>
            Back
          </button>
        </nb-step>
        <nb-step #step3>
          <!-- Account selection step -->
          <div>
            <!--Choose which accounts you want the new investment selections (<b>{{ selectedTickers.join(', ') }}</b>)
            to be added to.-->
            You may be able to reduce risk exposure deviations from your custom benchmark by adding this investment to
            your account(s). Considerations include whether the total value of the accounts selected are large enough to
            accommodate the size of the underweight to be added, contain the offsetting overweight in its risk exposure
            group and the taxable versus tax-deferred location. Select the account(s) for which this investment will be
            added.
          </div>

          <rip-group-diffs *ngIf="exposureBeingAdded"
                           [groupId]="exposureBeingAdded.row.groupId"
                           [exposureBeingAddedLabel]="exposureBeingAdded.riskExposure">

          </rip-group-diffs>


          <ng-container *ngTemplateOutlet="accountSelectorNavButtons"></ng-container>

          <rip-account-selector #accountSelector [underweightExposure]="exposureBeingAdded">

          </rip-account-selector>

          <ng-container *ngTemplateOutlet="accountSelectorNavButtons"></ng-container>

          <ng-template #accountSelectorNavButtons>
            <div style="margin: 10px;">
              <button mat-raised-button (click)="goToStepBeforeAccountSelection()">
                <mat-icon>chevron_left</mat-icon>
                Back
              </button>
              <button mat-raised-button [color]="showReOptimizeButton ? 'default' : 'primary'"
                      [disabled]="accountSelector.selectedAccountNames.length === 0"
                      (click)="insertSecuritiesIntoAccounts()">
                Add and Continue
              </button>
              <button mat-raised-button color="primary"
                      [disabled]="accountSelector.selectedAccountNames.length === 0"
                      (click)="insertAndReOptimize()"
                      *ngIf="showReOptimizeButton">
                Add and Re-optimize
              </button>
            </div>
          </ng-template>

        </nb-step>
      </nb-stepper>
    </div>
  </div>
  <mat-divider class="modal-footer-divider"></mat-divider>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">Close</button>
  </div>


