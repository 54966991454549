import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ExistingManualAccountForm } from '../../../../../utils/dataInterfaces';
import { Account, Position } from '@ripsawllc/ripsaw-analyzer';
import { UntypedFormGroup } from '@angular/forms';
import { ManualAccountManagerComponent } from '../../../../modals/manualAccountManager';
import { ManualAccountErrorStateMatcher, ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';
import { environment } from '../../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { GlobalState } from '../../../../../global.state';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountSettingsDialogComponent } from '../accountSettingsDialog.component';
import { WithWorkspacePermission } from '../../../../../shared/workspace-permission';

@Component( {
  selector: 'rip-account-full-form',
  template: `

    <div *ngIf="form">
      <div class="row account-header-row">
        <div class="col">
          <mat-horizontal-stepper id="remove-manual-account-stepper" class="no-header-stepper">
            <mat-step>
              <div>
                <button *ripPerm="[perm.OWNER, perm.ACCOUNTS_DELETE]" mat-button color="warn" class="pull-right" matStepperNext>
                  Remove Account
                </button>
              </div>
            </mat-step>
            <mat-step>
              <div style="text-align: right;">
                Clicking REMOVE will remove this manual account from {{ appName }}. You cannot undo this.
              </div>
              <rip-progress-bar-button [options]="removeButtonOptions"
                                       (click)="deleteManualAccount(account)"
                                       class="pull-right">

              </rip-progress-bar-button>
              <button mat-button matStepperPrevious class="pull-right">
                Cancel
              </button>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
      <!-- use account.type or account.category to determine which component to use to complete the form -->

      <div [ngSwitch]="existingManualForm?.formType">
        <div *ngSwitchCase="'realAsset'">
          <rip-real-asset-form [form]="form"
                               [matcher]="matcher"
                               [type]="account.account_type"
                               [account]="account"
                               #existingForm>
          </rip-real-asset-form>
        </div>
        <div *ngSwitchCase="'stock'">
          <rip-stock-form [form]="form"
                          [matcher]="matcher"
                          [type]="account.account_type"
                          [account]="account"
                          #existingForm>
          </rip-stock-form>
        </div>
        <div *ngSwitchCase="'stockOption'">
          <rip-stock-option-form [form]="form"
                                 [matcher]="matcher"
                                 [type]="account.account_type"
                                 [account]="account"
                                 (autoSave)="autoSave.emit()"
                                 #existingForm>
          </rip-stock-option-form>
        </div>
        <div *ngSwitchCase="'bond'">
          <rip-bond-form [form]="form"
                         [matcher]="matcher"
                         [type]="account.account_type"
                         [account]="account"
                         [amortizationType]="account.amortization_type"
                         #existingForm>
          </rip-bond-form>
        </div>
        <div *ngSwitchCase="'cash'">
          <rip-cash-form [form]="form"
                         [matcher]="matcher"
                         [type]="account.account_type"
                         [account]="account"
                         #existingForm>
          </rip-cash-form>
        </div>
        <div *ngSwitchCase="'crypto'">
          <rip-crypto-form [form]="form"
                           [matcher]="matcher"
                           [type]="account.account_type"
                           [account]="account"
                           #existingForm>
          </rip-crypto-form>
        </div>
        <div *ngSwitchCase="'bank'">
          <rip-bank-form [form]="form"
                         [matcher]="matcher"
                         [type]="account.account_type"
                         [account]="account"
                         #existingForm>
          </rip-bank-form>
        </div>
        <div *ngSwitchCase="'loan'">
          <rip-loan-form [form]="form"
                         [matcher]="matcher"
                         [type]="account.account_type"
                         [account]="account"
                         [amortizationType]="account.amortization_type"
                         #existingForm>
          </rip-loan-form>
        </div>
        <div *ngSwitchCase="'investment'">
          <rip-investment-form
            [form]="form"
            [matcher]="matcher"
            [type]="account.account_type"
            [account]="account"
            [positionToEdit]="positionToEdit"
            #existingForm>
          </rip-investment-form>
        </div>
        <div *ngSwitchCase="'annuity'">
          <rip-annuity-form
            [form]="form"
            [matcher]="matcher"
            [type]="account.account_type"
            [account]="account">
          </rip-annuity-form>
        </div>
        <div *ngSwitchCase="'term-life-insurance'">
          <rip-term-life-insurance-form
            [form]="form"
            [matcher]="matcher"
            [type]="account.account_type"
            [account]="account">
          </rip-term-life-insurance-form>
        </div>
      </div>


      <span *ngIf="showInvalidMessage"
            class="invalid-message">{{ invalidMessage }}</span>
      <br>
    </div>

  `,
  styleUrls: [ `./account-full-form.component.scss` ],
} )

export class AccountFullFormComponent extends WithWorkspacePermission implements AfterViewInit, OnDestroy {

  @Input() account: Account;
  @Input() form: UntypedFormGroup;
  @Input() existingManualForm: ExistingManualAccountForm;
  @Input() matcher: ManualAccountErrorStateMatcher;
  @Input() dialog: MatDialogRef<AccountSettingsDialogComponent>;
  @Input() positionToEdit: Position;

  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  appName: string = '';

  saveButtonOptions: any = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    disabled: false,
  };
  removeButtonOptions: any = {
    active: false,
    text: 'REMOVE',
    buttonColor: 'warn',
    spinnerColor: 'warn',
    raised: true,
    mode: 'indeterminate',
    disabled: false,
  };

  invalidMessage: string = '';
  showInvalidMessage: boolean = false;
  subscription: Subscription;

  manualAccountManager: ManualAccountManagerComponent;

  constructor( private _state: GlobalState, private mamState: ManualAccountManagerState ) {
    super();
    this.appName = environment.appName;
    this.invalidMessage = mamState.invalidMessage;
  }

  ngAfterViewInit() {
    this.subscription = this.form.valueChanges.subscribe( () => {
      this.saveButtonOptions.disabled = this.form.invalid;
      this.showInvalidMessage = this.form.invalid;
    } );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deleteManualAccount( account: any ) {
    this.removeButtonOptions.active = true;
    this.mamState.deleteManualAccount( account, ( err ) => {

      if ( err ) {
        // this should be all we need now cause the delete function in the mamState handles the snackbar
        this.removeButtonOptions.active = false;
      } else {
        this.dialog.close( 'account.deleted' );
      }
    } );
  }

}

