import { Component, Inject, NgZone, TemplateRef } from '@angular/core';
import { GlobalState } from '../../global.state';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { environment } from '../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisclaimersUtil } from '../../utils/disclaimers.util';
import { ThemePalette } from '@angular/material/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component( {
  selector: 'rip-alert-modal',
  styleUrls: [ './alertModal.scss' ],
  template: `
    <h3 mat-dialog-title> {{ title }}</h3>
    <mat-divider class="modal-header-divider"></mat-divider>
    <div class="alert-modal-message" mat-dialog-content>
      <span [innerHTML]="message | safe: 'html'"></span>
      <span *ngIf="clickHere">
        {{ clickHere.message }}, click <a class="rip-link" (click)="openDisclaimerModal( disclaimerIndex )">here</a>.
      </span>
      <span *ngIf="this.disclaimerIndex || this.disclaimerIndex === 0">
        <ng-container *ngTemplateOutlet="disclaimerTemplate"></ng-container>
      </span>

      <div *ngIf="htmlContent">
        <ng-container *ngTemplateOutlet="htmlContent"></ng-container>
      </div>
    </div>
    <mat-divider class="modal-footer-divider"></mat-divider>
    <div class="modal-footer-btns" mat-dialog-actions>
      <button mat-button (click)="close(true)" *ngIf="showCloseButton">
        {{ closeButtonLabel }}
      </button>
      <button mat-button [color]="actionButtonColor" (click)="buttonPressed()"
              *ngIf="(callback || (buttonLabel && buttonLabel !== '')) && !actionButtonRaised">
        <fa-icon [icon]="actionButtonIcon" *ngIf="actionButtonIcon" style="margin-right: 5px;"></fa-icon>
        {{ buttonLabel }}
      </button>
      <button mat-raised-button [color]="actionButtonColor" (click)="buttonPressed()"
              *ngIf="(callback || (buttonLabel && buttonLabel !== '')) && actionButtonRaised">
        <fa-icon [icon]="actionButtonIcon" *ngIf="actionButtonIcon" style="margin-right: 5px;"></fa-icon>
        {{ buttonLabel }}
      </button>
    </div>

    <ng-template #disclaimerTemplate>
      <span class="disclaimer" (click)="openDisclaimerModal( disclaimerIndex )"
            *ngIf="disclaimerIndex || disclaimerIndex === 0">
      <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
      </span>
    </ng-template>
  `,
} )


export class AlertModalComponent {

  faQuestionCircle = faQuestionCircle;

  constructor( private _state: GlobalState,
               private disclaimersUtil: DisclaimersUtil,
               private dialog: MatDialogRef<AlertModalComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private _ngZone: NgZone ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_alertModal'] = { component: this, zone: _ngZone };
    }

    Object.assign( this, this.defaultConfig, this.data );
  }


  // @ViewChild( 'disclaimerTemplate', { static: true } ) disclaimerTemplate: TemplateRef<any>;

  title: string = '';
  message: string = '';
  closeButtonLabel: string = 'Close';
  showCloseButton: boolean = true;

  disclaimerIndex: number;
  clickHere: any;

  htmlContent: TemplateRef<any>;

  callback: Function;
  buttonLabel: string = ''; // actionButtonLabel
  actionButtonRaised: boolean = false;
  actionButtonColor: ThemePalette;
  actionButtonIcon: IconDefinition;

  defaultConfig: any = {
    title: '',
    message: '',
    buttonLabel: '',
    closeButtonLabel: 'Close',
    showCloseButton: true,
    callback: undefined,
    disclaimerIndex: null,
    clickHere: null,
  };

  close( result?: boolean ) {
    this.dialog.close( result );
  }

  buttonPressed() {
    if ( this.callback ) {
      this.callback( this._state );
    }
    this.close( false );
  }

  /*
 * Function to open the disclaimer modal for displaying the disclaimers
 * */
  openDisclaimerModal( index: number ) {
    this.disclaimersUtil.openDisclaimersDialog( index );
  }

}

