import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MarketDataUtil } from '../../../utils/marketData.util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PricingService } from '../../../globalData';
import { environment } from '../../../../environments/environment';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { GlobalState } from '../../../global.state';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component( {
  selector: 'rip-mobile-market-data',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">Markets</div>
      <span><button mat-raised-button (click)="close()" class="mobile-close-btn">Close</button></span>
    </div>
    <div class="bottom-sheet-content">
      <ngx-datatable id="mobile-market-data-table"
                     [rows]="indexBreakoutRows"
                     [columns]="indexBreakoutColumns"
                     [columnMode]="'force'"
                     [headerHeight]="40"
                     [rowHeight]="40"
                     [reorderable]="false"
                     [cssClasses]="indexBreakoutCssClasses"
                     [footerHeight]="0"
                     class="material striped mobile-market-data-breakout-table mobile-table">

      </ngx-datatable>
      <ng-template #caretTemplate ngx-datatable-cell-template let-value="value" let-row="row">
        {{ value }}
        <span *ngIf="row.dollarChange > 0">
          <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="row.dollarChange < 0">
          <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="!row.dollarChange">
          <fa-icon [icon]="faMinus" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
      </ng-template>
      <ng-template #decimalTemplate ngx-datatable-cell-template let-value="value" let-row="row">
        <span *ngIf="row.type === 'price'">{{ value | ripDecimalPipe : '2-2'}}</span>
        <span *ngIf="row.type === 'yield'">{{ value | ripDecimalPipe : '3-3'}}</span>

      </ng-template>
      <ng-template #percentTemplate ngx-datatable-cell-template let-value="value">
        {{ value | ripPercentPipe : '2-2' }}
      </ng-template>
    </div>
  `,
  styleUrls: [ `./mobileLayoutComponents.scss`, `../mobileLayout.scss` ],
  encapsulation: ViewEncapsulation.None,
} )

export class MobileMarketDataComponent implements AfterViewInit, OnDestroy {

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faMinus = faMinus;


  @ViewChild( 'caretTemplate', { static: false } ) caretTemplate: TemplateRef<any>;
  @ViewChild( 'decimalTemplate', { static: false } ) decimalTemplate: TemplateRef<any>;
  @ViewChild( 'percentTemplate', { static: false } ) percentTemplate: TemplateRef<any>;

  indexBreakoutColumns: any[] = [];

  indexBreakoutRows: any[] = [];

  indexBreakoutCssClasses = MarketDataUtil.indexBreakoutCssClasses;

  subscriberName: string = 'MobileMarketDataComponent';


  constructor( private _detectorService: DeviceDetectorService,
               private snackBar: MatSnackBar,
               private _pricingService: PricingService,
               private _bottomSheetRef: MatBottomSheetRef<MobileMarketDataComponent>,
               private _state: GlobalState ) {

  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.indexBreakoutColumns = [
        {
          prop: 'ticker',
          name: 'Ticker',
          cellTemplate: this.caretTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
        /*{
          prop: 'description',
          name: 'Description',
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
          minWidth: 200,
        },*/
        {
          prop: 'price',
          name: 'Price/Yield',
          cellTemplate: this.decimalTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
        {
          prop: 'dollarChange',
          name: 'Change',
          cellTemplate: this.decimalTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
        {
          prop: 'percentChange',
          name: 'Change (%)',
          cellTemplate: this.percentTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
      ];
      if ( environment.env !== 'prod' ) {
        window['ripsaw_mobileMarketData'] = this;
      }

      this.indexBreakoutRows = this._state.globalVars.indexBreakoutRows;

      this._state.subscribe( 'indexes.retrieved', () => {
        this.indexBreakoutRows = this._state.globalVars.indexBreakoutRows;
      }, this.subscriberName );
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'indexes.retrieved', this.subscriberName );
  }

  close() {
    // this._state.notifyDataChanged( 'close.mobile.market.data', {} );
    this._bottomSheetRef.dismiss();
  }


}
