import { Component, OnDestroy } from '@angular/core';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { Options } from '@angular-slider/ngx-slider';
import { GlobalState } from '../../../global.state';
import { EVENT_NAMES } from '../../../utils/enums';

@Component( {
  selector: 'rip-wealth-chart-zoom-control',
  template: `
    <div class="flexing-row-only center-vertically wealth-chart-zoom-slider-row custom-slider"
         *ngIf="!benchmarkState.hideWealthChart">
      <a class="rip-link" id="reset-zoom-button" (click)="resetZoom()">Reset</a>
      <ngx-slider [value]="low" [highValue]="high" [options]="sliderOptions"
                  id="wealth-chart-zoom-slider" (userChange)="setZoom($event)" *ngIf="showSlider">

      </ngx-slider>
    </div>
  `,
  styleUrls: [ './wealth-chart-zoom-control.component.scss' ],
} )

export class WealthChartZoomControlComponent implements OnDestroy {

  sliderOptions: Options = {};

  low: number;
  high: number;

  showSlider: boolean = false;

  hasZoomed: boolean = false;

  subscriberName: string = 'expectedWealthZoomControlComponent';

  constructor( public benchmarkState: BenchmarkState, private _state: GlobalState ) {
    benchmarkState.scaleSubject.subscribe( {
      next: () => {
        this.setup();
      },
    } );
    if ( benchmarkState.expectedWealthRange.min ) {
      this.setup();
    } else {
      this.setSliderOptions( 0, 100 ); // just in case
    }

    _state.subscribe( EVENT_NAMES.WEALTH_CHART_SCROLLED, ( direction: number ) => {
      if ( direction > 0 && ( this.hasZoomed || this.canZoomOut() ) ) {
        // zoom out
        this.setZoom( { value: this.low - 1, highValue: this.high + 1 } );
      } else if ( direction < 0 && this.high - this.low > 1 ) {
        // zoom in
        this.setZoom( { value: this.low + 1, highValue: this.high - 1 } );
      }
    }, this.subscriberName );
  }

  ngOnDestroy() {
    this._state.unsubscribe( EVENT_NAMES.WEALTH_CHART_SCROLLED, this.subscriberName );
  }

  canZoomOut() {
    return this.low > this.sliderOptions.floor || this.high < this.sliderOptions.ceil;
  }

  setup() {

    this.setSliderOptions( Math.floor( this.benchmarkState.expectedWealthRange.min ), Math.ceil( this.benchmarkState.expectedWealthRange.max ) );

    this.low = this.benchmarkState.expectedWealthRange.currentLow ?? this.sliderOptions.floor;
    this.high = this.benchmarkState.expectedWealthRange.currentHigh ?? this.sliderOptions.ceil;

    this.showSlider = true;
  }

  setSliderOptions( floor: number, ceil: number ) {
    this.sliderOptions = {
      floor,
      ceil,
      translate: ( value: number/*, label: LabelType*/ ): string => {
        return value.toFixed( 0 );
      },
      showTicks: true,
      showTicksValues: false,
      animateOnMove: true,
    };
  }


  setZoom( event: any ) {
    // console.log( event );

    this.low = event.value > this.sliderOptions.floor ? event.value : this.sliderOptions.floor;

    this.high = event.highValue < this.sliderOptions.ceil ? event.highValue : this.sliderOptions.ceil;
    if ( this.high - this.low <= 1 || this.high < this.low ) {
      this.high = this.low + 1;
    }

    this.benchmarkState.expectedWealthRange.currentLow = Math.floor( this.low );
    this.benchmarkState.expectedWealthRange.currentHigh = Math.ceil( this.high );
    this._state.notifyDataChanged( 'expected.wealth.zoom.changed', this.benchmarkState.expectedWealthRange );
    this.hasZoomed = this.low !== this.sliderOptions.floor || this.high !== this.sliderOptions.ceil;
  }

  resetZoom() {
    if ( this.hasZoomed ) {
      this.low = this.sliderOptions.floor;
      this.high = this.sliderOptions.ceil;

      this.benchmarkState.expectedWealthRange.currentLow = this.low;
      this.benchmarkState.expectedWealthRange.currentHigh = this.high;
      this._state.notifyDataChanged( 'expected.wealth.zoom.changed', this.benchmarkState.expectedWealthRange );
      this.hasZoomed = false;
    }
  }

}
