import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root',
} )
export class ThemeService {
  theme: string = 'ripsaw-theme';
  previousTheme: string;

  constructor( @Inject( DOCUMENT ) private document: Document ) {
  }

  switchTheme( themeName: string ): void {
    let themeLink: HTMLLinkElement = this.document.getElementById(
      'app-theme',
    ) as HTMLLinkElement;
    this.previousTheme = this.theme;

    this.theme = themeName;
    themeLink.href = `${ window.origin }/${ themeName }.css`;

    let body: HTMLElement = this.document.getElementsByTagName( 'body' )[ 0 ];
    body.classList.add( themeName );

    if ( this.previousTheme ) {
      body.classList.remove( this.previousTheme );

    }
  }
}