<div
    class="modal-body connection-manager-body"
    [nbSpinner]="!dataRetrieved || isLoading || isSyncingOnLogin">
  <div class="accordion-header-btns">
    <div (click)="handleAccordionToggleClick()" class="accordion-toggle">
      <div *ngIf="isAllClosed(); then expand; else collapse"></div>
      <ng-template #collapse>
        <div class="accordion-toggle-icons">
          <fa-icon [icon]="faAngleDown"></fa-icon>
          <fa-icon [icon]="faAngleUp"></fa-icon>
        </div>
      </ng-template>
      <ng-template #expand>
        <div class="accordion-toggle-icons">
          <fa-icon [icon]="faAngleUp"></fa-icon>
          <fa-icon [icon]="faAngleDown"></fa-icon>
        </div>
      </ng-template>
      {{ isAllClosed() ? 'Expand all' : 'Collapse all' }}
    </div>
    <div (click)="refresh()" class="accordion-toggle">Refresh</div>
    <div (click)="refreshAll()" class="accordion-toggle">Sync all</div>
  </div>
  <div
      *ngIf="
      (!connections || connections.length === 0) &&
        dataRetrieved &&
        !isSyncingOnLogin;
      else connectionsAccordion
    "
      class="empty-placeholder">
    <p class="empty-placeholder-header">No connections yet</p>
    <p class="empty-placeholder-text" *ngIf="!inWealthFluent; else wealthFluentMessage">
      Explore the Connect Accounts tab to begin
    </p>
    <ng-template #wealthFluentMessage>
      <p class="empty-placeholder-text">
        Explore the task manager in the dashboard to begin
      </p>
    </ng-template>
  </div>
  <ng-template #connectionsAccordion>

    <div>
      <ul class="accordion-header">
        <li>Bank</li>
        <li>Last sync</li>
        <li class="accordion-header-status">Connection status</li>
        <li>
          Auto sync
          <fa-icon [icon]="faQuestionCircle" (click)="op.toggle($event)" style="cursor: pointer;">
            <p-overlayPanel #op [styleClass]="'auto-sync-info-overlay'" [dismissable]="true" [baseZIndex]="100000">
              <ng-template pTemplate>
                <p>Auto Sync allows for current data to be available automatically and flexibility for managing the
                  connections individually. The daily schedule happens each night at 10pm MST and the weekly is on
                  Sundays
                  at 10pm MST. You may also choose to have the connection sync on login to the application or just on
                  demand.
                </p>
                <p> Daily is recommended for most connections so that you have history that can give you more detail in
                  the tools. However, if you have a connection that has issues like a multi-factor code that must be
                  entered every time no preferences set gives you only on demand sync.
                </p>
              </ng-template>
            </p-overlayPanel>
          </fa-icon>
        </li>
      </ul>
      <p-accordion #accordion [multiple]="true">
        <p-accordionTab
            class="custom-accordion-tab"
            *ngFor="let connection of connections; index as i"
            [(selected)]="accordionState[connection.id]">
          <ng-template pTemplate="header">
            <div class="accordion-header-content">
              <span class="accordion-header-bank">{{
                  connection.institution_name
                }}</span>
              <div>
                <span class="expansion-last-sync-label">
                  {{
                    ( connection.last_good_sync | date : dateFormat ) ?? '—'
                  }}</span
                >
              </div>
              <div
                  *ngIf="
                  connection.status === 'FAILED' ||
                    connection.status === 'USER_INPUT_REQUIRED';
                  then failed;
                  else success
                "></div>
              <ng-template #failed>
                <div class="failed-chip-container">
                  <p-chip
                      class="p-chip-custom-host"
                      styleClass="p-chip-custom {{
                      statusMap[connection.status].chipClass
                    }}"
                      (click)="
                      openFastLinkForRepairingConnection(connection, $event)
                    "
                      [icon]="statusMap[connection.status].faClass"
                      [label]="getStatusLabel(connection.status)">
                  </p-chip>
                  <fa-icon
                      matTooltip="{{ statusMap[connection.status].msg }}"
                      matTooltipPosition="above"
                      (click)="
                      openFastLinkForRepairingConnection(connection, $event)
                    "
                      [icon]="faLink"></fa-icon>
                </div>
              </ng-template>
              <ng-template #success>
                <p-chip
                    class="p-chip-custom-host"
                    styleClass="p-chip-custom {{
                    statusMap[connection.status].chipClass
                  }}"
                    matTooltip="{{ statusMap[connection.status].msg }}"
                    matTooltipPosition="above"
                    [icon]="statusMap[connection.status].faClass"
                    [label]="getStatusLabel(connection.status)">
                </p-chip>
              </ng-template>
              <div class="sync-option-cell">
                <button
                    pButton
                    type="button"
                    (click)="toggleMenu($event)"
                    [matMenuTriggerFor]="syncmenu"
                    class="p-button-outlined sync-option-container">
                  <div
                      *ngIf="
                      connection.syncLogin || connection.syncNightly !== 'NONE';
                      else preferencesLabel
                    ">
                    <p-chip
                        *ngIf="connection.syncLogin"
                        styleClass="sync-option-chip"
                        label="On login"></p-chip>
                    <p-chip
                        *ngIf="connection.syncNightly !== 'NONE'"
                        styleClass="sync-option-chip"
                        [label]="syncOptionsMap[connection.syncNightly]"></p-chip>
                  </div>
                  <ng-template #preferencesLabel>
                    <p class="label">Preferences</p>
                  </ng-template>
                  <fa-icon
                      class="sync-option-container-arrow"
                      [icon]="faChevronDown"></fa-icon>
                </button>
                <mat-menu class="mat-menu-custom" #syncmenu="matMenu">
                  <button
                      (click)="toggleSyncOnLogin($event, connection)"
                      class="schedule-toggle-group"
                      mat-menu-item>
                    <p-inputSwitch
                        styleClass="toggle-custom"
                        (click)="toggleSyncOnLogin($event, connection)"
                        [ngModel]="connection.syncLogin"
                        *ripPerm="[
                        perm.OWNER,
                        perm.ACCOUNT_SETTINGS_VIEW,
                        perm.ACCOUNT_SETTINGS_EDIT
                      ]"></p-inputSwitch>
                    Sync on login
                  </button>
                  <button
                      class="schedule-toggle-group"
                      (click)="toggleSyncBySchedule($event, connection)"
                      mat-menu-item
                      [matMenuTriggerFor]="schedule">
                    <p-inputSwitch
                        (click)="toggleSyncBySchedule($event, connection)"
                        [ngModel]="connection.syncNightly !== 'NONE'"
                        styleClass="toggle-custom"
                        *ripPerm="[
                        perm.OWNER,
                        perm.ACCOUNT_SETTINGS_VIEW,
                        perm.ACCOUNT_SETTINGS_EDIT
                      ]"></p-inputSwitch>
                    By schedule
                    <fa-icon
                        class="schedule-chevron"
                        [icon]="faChevronRight"></fa-icon>
                  </button>
                </mat-menu>
                <mat-menu class="mat-menu-custom" #schedule="matMenu">
                  <button
                      (click)="
                      setSyncByScheduleOption($event, connection, 'DAILY')
                    "
                      mat-menu-item>
                    Sync nightly
                    <span class="button-label">Recommended</span>
                  </button>
                  <button
                      (click)="
                      setSyncByScheduleOption($event, connection, 'WEEKLY')
                    "
                      mat-menu-item>
                    Sync weekly
                  </button>
                </mat-menu>
              </div>
              <button
                  (click)="
                  toggleActionMenu($event, stepper.previous.bind(stepper))
                "
                  class="toggle-menu-btn"
                  mat-icon-button
                  [matMenuTriggerFor]="menu">
                <fa-icon [icon]="faEllipsis"></fa-icon>
              </button>
            </div>
            <mat-menu
                class="mat-menu-custom"
                xPosition="before"
                #menu="matMenu">
              <mat-horizontal-stepper
                  [animationDuration]="'0'"
                  #stepper="matStepper">
                <mat-step>
                  <div>
                    <button
                        *ripPerm="[perm.OWNER, perm.ACCOUNTS_DELETE]"
                        (click)="
                        deleteClickHandle($event, stepper.next.bind(stepper))
                      "
                        class="menu-btn"
                        mat-menu-item>
                      <fa-icon [icon]="faTrash"></fa-icon>
                      Remove
                    </button>
                    <button
                        class="menu-btn"
                        (click)="syncConnection(connection.id)"
                        mat-menu-item>
                      <fa-icon [icon]="faRotate"></fa-icon>
                      Synchronize
                    </button>
                  </div>
                </mat-step>
                <mat-step>
                  <div class="remove-step">
                    <p>
                      Clicking <b>Remove</b> will remove the connection to
                      {{ connection.institution_name }}.
                    </p>
                    <div class="remove-step-btns-group">
                      <p-button
                          styleClass="connections-menu-cancel-btn"
                          label="Cancel"
                          (click)="cancelDeletion(stepper)"
                          severity="secondary"></p-button>
                      <p-button
                          styleClass="connections-menu-remove-btn"
                          (click)="deleteConnection(connection)"
                          label="Remove"
                          severity="warning"></p-button>
                    </div>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </mat-menu>
          </ng-template>
          <ng-template pTemplate="content">
            <div #includedAccounts class="included-accounts">
              <div
                  class="empty-text"
                  *ngIf="getConnectionAccounts(connection).length === 0">
                There are no accounts in this connection
              </div>
              <ng-container
                  *ngIf="
                  getConnectionAccounts(connection)
                    | accountVisibilityBySettingPermission
                    | async as accounts
                ">
                <ng-container
                    *ngIf="{
                    isDisabled: accountSettingIsDisabled$ | async
                  } as settingState">
                  <p-table
                      [value]="accounts"
                      [tableStyle]="{ 'min-width': '50rem' }">
                    <ng-template pTemplate="header">
                      <tr>
                        <th></th>
                        <th>Account</th>
                        <th class="">Display status</th>
                        <th class="table-sum-col">Balance</th>
                        <th class="table-sum-col"></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-account>
                      <tr>
                        <td class="visibility-cell">
                          <!-- <p-inputSwitch
                          styleClass="toggle-custom"
                          *ripPerm="[
                            perm.OWNER,
                            perm.ACCOUNT_SETTINGS_VIEW,
                            perm.ACCOUNT_SETTINGS_EDIT
                          ]"
                          (click)="
                            includeAccount(account, settingState.isDisabled)
                          "
                          [disabled]="settingState.isDisabled"
                          [ngModel]="accountIncluded(account)"></p-inputSwitch> -->
                          <div
                              [matMenuTriggerFor]="visibilityMenu"
                              *ripPerm="[perm.OWNER, perm.ACCOUNT_SETTINGS_VIEW, perm.ACCOUNT_SETTINGS_EDIT]"
                              matTooltip="Account visibility settings"
                              matTooltipPosition="bottom"
                              class="visibility-toggle"
                          >
                            <fa-icon [icon]="!accountIncluded(account) ? faEyeSlash : faEye"></fa-icon>
                            <fa-icon [icon]="faChevronDown"></fa-icon>
                          </div>
                          <mat-menu class="mat-menu-custom visibility-menu" #visibilityMenu="matMenu">
                            <button (click)="includeAccountNew(account, true)" mat-menu-item>
                              Show everywhere in workspace
                            </button>
                            <button (click)="includeAccountNew(account, false)" mat-menu-item>
                              Hide everywhere in workspace
                            </button>
                            <!-- <button
                              (click)="includeAccountNew(account, null)"
                              mat-menu-item>
                              Hide from just transactions
                            </button> -->
                          </mat-menu>
                        </td>
                        <td class="connection-list-item-cell" [ngClass]="{ disabled: !accountIncluded(account) }">
                          <div
                              class="connection-list-item-content"
                              [ngClass]="{
                              'account-details-being-edited': !nicknameEditor.editing
                            }"
                          >
                            <!-- show when panel loads -->

                            <span class="account-nickname-and-fees">
                              <span>
                                <rip-nickname-editor #nicknameEditor [account]="account"> </rip-nickname-editor>
                              </span>
                              <!-- <span
                              *ngIf="account.account_category === 'Investment'"
                              style="margin-right: 10px"
                              >|</span -->
                              <!-- > -->
                              <span>
                                <rip-advisor-fee-editor #feeEditor [account]="account"> </rip-advisor-fee-editor>
                              </span>
                              <span *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]">
                                <fa-icon
                                    [icon]="faPenToSquare"
                                    class="edit-button"
                                    (click)="editConnectedAccount(feeEditor, nicknameEditor)"
                                    matTooltip="Edit Account"
                                    *ngIf="!nicknameEditor.editing"
                                ></fa-icon>
                                <div class="icons-editing-group">
                                  <fa-icon
                                      *ngIf="nicknameEditor.editing"
                                      [icon]="faCheck"
                                      (click)="updateConnectedAccount(feeEditor, nicknameEditor)"
                                      matTooltip="Save Account Changes"
                                      matTooltipPosition="right"
                                      class="save-button"
                                  >
                                  </fa-icon>
                                  <fa-icon
                                      class="nickname-cancel-edit-button"
                                      (click)="cancelChanges(feeEditor, nicknameEditor)"
                                      matTooltip="Cancel Editing"
                                      matTooltipPosition="right"
                                      *ngIf="nicknameEditor.editing"
                                      [icon]="faXMark"
                                  >
                                  </fa-icon>
                                </div>
                              </span>
                            </span>
                          </div>
                        </td>
                        <td>
                          <p-chip styleClass="p-chip-custom account-status {{
                              accountIncluded(account) ? 'account-included' : 'account-not-included'
                            }}"
                                  class="p-chip-custom"
                                  [label]="accountIncluded(account) ? 'Shown in workspace' : 'Hidden in workspace'">
                          </p-chip>
                        </td>
                        <td class="table-sum-col" [ngClass]="">
                          {{ account.value | ripCurrencyPipe }}
                        </td>
                        <td class="empty-cell"></td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
  </ng-template>
</div>
