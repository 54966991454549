import { Injectable } from '@angular/core';
import { Icon, icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Logger } from './logger.service';

@Injectable()

export class IconUtil {

  constructor() {

  }

  static convertFAIconToSvgCanvasImage( i: IconDefinition, title: string, color: string ): HTMLImageElement {
    const faObj: Icon = icon( i );
    const temp = faObj?.node;

    let svg: any;
    if ( temp ) {
      svg = temp[0];
    } else {
      // problem
      Logger.log( 'problem' );
    }

    svg.style.fill = color;
    svg.style.color = color;
    svg.setAttribute( 'width', '18' );
    svg.setAttribute( 'height', '18' );
    svg.setAttribute( 'x', '16' );
    svg.setAttribute( 'y', '12' );
   /* const canvas: HTMLCanvasElement = document.createElement( 'canvas' );
    canvas.height = 25;
    canvas.width = 25;
    // canvas.setAttribute( 'class', 'in-chart-icon' );

    const XML: string = new XMLSerializer().serializeToString( svg );
    const SVG64: string = btoa( XML );

    const tempImg: HTMLImageElement = new Image();
    tempImg.height = 25;
    tempImg.width = 25;
    tempImg.src = `data:image/svg+xml;base64,${ SVG64 }`;
    tempImg.style.cursor = 'pointer';

    return tempImg;
  }

}
*/

    const circle = document.createElementNS( 'http://www.w3.org/2000/svg', 'circle' );
    circle.setAttribute( 'cx', '25' );
    circle.setAttribute( 'cy', '22' );
    circle.setAttribute( 'r', '14' );
    circle.setAttribute( 'fill', color );
    circle.setAttribute( 'opacity', '0.2' );

    //background.src = '/assets/svg/goal-background.svg';
    const containerSvg: SVGSVGElement = document.createElementNS( 'http://www.w3.org/2000/svg', 'svg' );
    containerSvg.setAttribute( 'width', '50' );
    containerSvg.setAttribute( 'height', '50' );
    containerSvg.setAttribute( 'viewBox', '0 0 50 50' );

    const path = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' );
    path.setAttribute(
      'd',
      'M 38.490717,35.624185 28.372126,45.836133 c -0.894367,0.901703 -2.106838,1.408164 -3.370922,1.408164 -1.264183,0 -2.476554,-0.506461 -3.370922,-1.408164 L 11.509322,35.624185 C 8.8412819,32.931412 7.0243518,29.500635 6.2882936,25.765819 5.5522406,22.030902 5.930115,18.159588 7.374126,14.641351 c 1.444011,-3.518156 3.889318,-6.5251789 7.026692,-8.6408127 3.137385,-2.1156238 6.82591,-3.2448348 10.599181,-3.2448348 3.773272,0 7.461797,1.129211 10.599161,3.2448348 3.137365,2.1156338 5.582691,5.1226567 7.026712,8.6408117 1.444021,3.518238 1.821871,7.389552 1.085853,11.124469 -0.736018,3.734816 -2.552968,7.165593 -5.221008,9.858366 z',
    );
    path.setAttribute( 'fill', 'white' );
    path.setAttribute( 'stroke', color );
    path.setAttribute( 'stroke-width', '2' );
    path.setAttribute( 'stroke-linecap', 'round' );
    path.setAttribute( 'stroke-linejoin', 'round' );

    containerSvg.appendChild( path );
    containerSvg.appendChild( circle );
    containerSvg.appendChild( svg );

    const XML: string = new XMLSerializer().serializeToString( containerSvg );
    const SVG64: string = btoa( XML );

    const tempImg: HTMLImageElement = new Image();
    tempImg.height = 50;
    tempImg.width = 50;
    tempImg.src = `data:image/svg+xml;base64,${ SVG64 }`;
    tempImg.style.cursor = 'pointer';

    return tempImg;
  }
}
