import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GlobalState } from '../../../global.state';
import { Util } from '../../../utils/util.service';
import { AccountManager } from '../../../utils/accountManager';
import { GlobalDataService } from '../../../globalData';
import { OverrideHelpers } from '../../../utils/overrideHelpers';
import * as _ from 'lodash-es';
import { Auth } from '../../../auth.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  RipsawCurrencyPipe,
  RipsawDecimalPipe,
  RipsawPercentPipe,
} from '../../../theme/pipes';
import { RipThemeLoadingSpinnerService } from '../../../theme/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountDataGridComponent } from '../../accounts/components/accountDetail/components/accountDataGrid';
import { environment } from '../../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManualAccountManagerState } from '../../../utils/manualAccountManager.state';
import {
  OverrideGroupSetItem,
  OverrideKeySetItem,
} from '../../../utils/dataInterfaces';
import { EVENT_NAMES } from '../../../utils/enums';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component({
  templateUrl: './manualSecurityEditor.component.html',
  styleUrls: ['./manualSecurityEditor.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualSecurityEditorComponent implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  position: any;
  origPosition: any;
  account: any;
  grid: AccountDataGridComponent;

  saveButtonOptions = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: false,
    mode: 'indeterminate',
    disabled: false,
  };

  loadingSecurityData: boolean = true;

  matcher: ErrorStateMatcher = new ErrorStateMatcher();

  form: UntypedFormGroup = new UntypedFormGroup({});
  columns: any = {};

  activeKeySets: OverrideGroupSetItem[];

  ripPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe = new RipsawDecimalPipe();

  saveChanges: boolean = true;
  unsavedChanges: boolean = false;
  callbackFunction: Function;
  forRevision: boolean = false;

  modalTitle: string = '';

  ngOnDestroy() {
    this.onDestroy.next();
  }

  constructor(
    private _state: GlobalState,
    private _accountManager: AccountManager,
    private _gdService: GlobalDataService,
    private _auth: Auth,
    private snackBar: MatSnackBar,
    private _spinnerService: RipThemeLoadingSpinnerService,
    private mamState: ManualAccountManagerState,
    private dialogRef: MatDialogRef<ManualSecurityEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (environment.env !== 'prod') {
      window['ripsaw_manualSecurityEditorModal'] = this;
    }

    this.init(
      data.position || data.row,
      data.grid ? data.grid : data.account,
      data.options,
    );
  }

  init(position: any, account: any, options?: any) {
    if (options) {
      this.saveChanges = !options.dontSave; // if dontSave is true, saveChanges should be false
      this.callbackFunction = options.callback;
      this.forRevision = options.forRevision;
    }
    this.origPosition = position;
    this.position = _.clone(position);
    if (account.account) {
      this.account = account.account;
      this.grid = account;
    } else {
      this.account = account;
    }
    this.setupFormControls();
    this.modalTitle = this.getTitle();
  }

  close() {
    if (this.callbackFunction) {
      this.callbackFunction(this.origPosition);
    }
    this.dialogRef.close();
    this.origPosition = undefined;
    this.position = undefined;
    this.account = undefined;
  }

  setupFormControls() {
    this.loadingSecurityData = true;
    this.columns = {};
    this.form = new UntypedFormGroup({});
    this.activeKeySets = OverrideHelpers.buildGlobalKeySets({
      ignoreFunc: (key: OverrideKeySetItem) => {
        if (
          ['expenses', 'security_style', 'morningstar_category'].includes(
            key.key,
          )
        ) {
          return true;
        }
      },
      position: this.position,
      positionOverrides: {},
      state: this._state,
      isManual: true,
    }); // make clone of the keySets with additional fields based on account

    // loop through the sets and then the keys in each set and create the form controls with values and the columns object
    for (const set of this.activeKeySets) {
      this.setupFormControlForKeys(set.keys);
      for (const group of set.groups) {
        this.setupFormControlForKeys(group.keys);
      }
    }
    this.form.controls.ticker.disable();
    this.form.controls.value.disable();
    this.loadingSecurityData = false;
  }

  setupFormControlForKeys(keys) {
    for (const key of keys) {
      const col = key.key;
      this.columns[col] = this._state.allColumnsObject[col]
        ? this._state.allColumnsObject[col].account
        : OverrideHelpers.leftSideColumns.find((c: any) => {
            return c.prop === col;
          });

      let value = OverrideHelpers.formatValue(this.position[col], col);
      if (value === undefined) {
        value = null;
      }
      this.position[col] = value;
      this.form.addControl(col, new UntypedFormControl(value));
    }
  }

  checkForUnsavedChanges() {
    const rawValues = this.form.getRawValue();
    this.unsavedChanges = OverrideHelpers.checkForKeySetsForChanges(
      this.activeKeySets,
      rawValues,
      this.origPosition,
      this.position,
    );
  }

  /*
   * Function to reformat the given input on any change
   * @param input {Object} - html input element that fired an input event
   * @param formControlName {String} - name of the form control to be updated
   * @param type {String} - type of formatting to use when updating the form control
   * */
  onInputChange(input: any, formControlName: any, type: string) {
    let inputValue = OverrideHelpers.sanitize(
      this.form.controls[formControlName].value,
      formControlName,
      true,
    );
    if (isNaN(inputValue)) {
      inputValue = 0;
    }
    switch (type) {
      case 'percent':
        this.form.controls[formControlName].setValue(
          this.ripPercentPipe.transform(inputValue, '2-3'),
        );
        break;
      case 'currency':
        this.form.controls[formControlName].setValue(
          this.ripCurrencyPipe.transform(inputValue),
        );
        break;
      case 'decimal':
        this.form.controls[formControlName].setValue(
          this.ripDecimalPipe.transform(inputValue, '2-3'),
        );
    }
    if ( formControlName === 'price' || formControlName === 'quantity' ) {
      const price = parseFloat( FormsUtil.sanitizeInput( this.form.controls.price.value ) );
      const quantity = parseFloat( FormsUtil.sanitizeInput( this.form.controls.quantity.value ) );
      this.form.controls.value.setValue( this.ripCurrencyPipe.transform( price * quantity ) );
    }
    this.checkForUnsavedChanges();
  }

  onSelectionChange(selectBox: any, key: string) {
    OverrideHelpers.onSelectionChange(
      selectBox,
      key,
      this.form,
      this.origPosition,
    );
    this.checkForUnsavedChanges();
  }

  dateChanged(dateInput: any, formControlName: string) {
    // right, now just want to check for changes, may want to do other things later
    this.checkForUnsavedChanges();
  }

  /*
   * Function called when the form is submitted. This function goes through the form data and updates the manual
   * position and account value
   * */
  submit() {
    this.saveButtonOptions.active = true;
    this._spinnerService.show(this.mamState.spinnerSelector);
    // get the raw values from the form
    const rawValues = this.form.getRawValue();

    // go through all the keys in all the set and check to see if the value has been changed
    for (const set of this.activeKeySets) {
      for (const key of set.keys) {
        const col = key.key;
        // remove any formatting from the raw value
        this.updatePositionValues(col, rawValues[col]);
      }
      for (const group of set.groups) {
        for (const key of group.keys) {
          const col = key.key;
          // remove any formatting from the raw value
          this.updatePositionValues(col, rawValues[col]);
        }
      }
    }

    const positionToUpdate = Util.clone(this.origPosition);
    this.updateAccountValue();
    if (this.saveChanges && !this.forRevision) {
      this._gdService
        .updateManualPosition(positionToUpdate)
        .pipe(takeUntil(this.onDestroy))
        .subscribe({
          next: (resp: any) => {
            this.origPosition = resp.data;
            if (
              this.origPosition.real_assets &&
              this.origPosition.real_assets > 0
            ) {
              this.origPosition.optimizerConstraints = '=';
            }
            this._state.notifyDataChanged(
              EVENT_NAMES.RECALCULATE_ALLOCATIONS,
              {},
            );
            this._accountManager.scanPositionsForMissingData([
              this.origPosition,
            ]);
            this.saveButtonOptions.active = false;
            const simpleAccount: any = {
              account_id: this.account.account_id,
              value: this.account.value,
            };
            this._gdService
              .updateManualAccount(simpleAccount)
              .pipe(takeUntil(this.onDestroy))
              .subscribe({
                next: (aResp: any) => {
                  this.snackBar.open(
                    'Manual Position Changes Saved!',
                    null,
                    Util.getSnackBarOptions(),
                  );
                  this._accountManager.scanPositionsForMissingData(
                    this.account.positions || [],
                  );
                  this._spinnerService.hide(0, this.mamState.spinnerSelector);
                  this._accountManager.getAccounts();
                  this.close();
                },
                error: err => {
                  console.error(err.err);
                  this.snackBar.open(
                    `Error Saving Manual Account: ${Util.getRefCodeSupportString(
                      err.refCode,
                    )}`,
                    'dismiss',
                    Util.getSnackBarOptions(true),
                  );
                  this.saveButtonOptions.active = false;
                },
              });
          },
          error: err => {
            console.error(err.err);
            this.snackBar.open(
              `Error Saving Manual Position: ${Util.getRefCodeSupportString(
                err.refCode,
              )}`,
              'dismiss',
              Util.getSnackBarOptions(true),
            );
            this.saveButtonOptions.active = false;
          },
        });
    } else {
      // in this case, the position must be a new security added in a revision
      this._state.notifyDataChanged(
        EVENT_NAMES.POSITION_OVERRIDDEN,
        this.origPosition,
      );
      this._accountManager.scanPositionsForMissingData(
        [this.origPosition] || [],
      );
      this.saveButtonOptions.active = false;
      this.close();
    }
  }

  updatePositionValues(key, value) {
    value =
      value === null || value === undefined
        ? value
        : OverrideHelpers.sanitize(value, key, true);

    if (value !== this.origPosition[key]) {
      this.markCustomizedField(key);
    }

    this.origPosition[key] = value; // update the position field value
    // gotta update the copy value too so the current aggregation recalculates right
    const currentPosition = this._accountManager
      .getAllOriginalPositionsIncludingManualAccounts()
      .find((p: any) => {
        return (
          p.account_id === this.origPosition.account_id &&
          p.ticker === this.origPosition.ticker
        );
      });
    if (currentPosition) {
      currentPosition[key] = value;
    }
  }

  markCustomizedField(key: string) {
    if (!['price', 'quantity', 'value'].includes(key)) {
      if (!this.origPosition.customized_fields) {
        this.origPosition.customized_fields = {};
      }
      this.origPosition.customized_fields[key] = true;
    }
  }

  /*
   * Get a title for the modal based on the security that was passed in when the modal was opened
   * @returns a string that is displayed as a title in the modal with security info
   * */
  getTitle() {
    if (this.position) {
      return `Values For ${this.position.ticker} | ${this.position.ticker_name}`;
    } else {
      return '';
    }
  }

  updateAccountValue() {
    let value = 0;
    if (this.account.positions && this.account.positions.length > 0) {
      for (const p of this.account.positions) {
        value += p.value;
      }
    } else {
      this.account.value = 0;
    }
    this.account.value = value;
  }

  groupHasMissingData(group: any) {
    return (
      this.position &&
      this.position.missing_data &&
      this.position.missing_data.includes(group.label)
    );
  }

  fieldHasMissingData(field: string) {
    return (
      this.position &&
      this.position.missing_data &&
      this.position.missing_data.includes(field)
    );
  }

  removeNewPosition() {
    if (this.grid) {
      this.grid.removeNewSecurity(this.origPosition.ticker);
      this.close();
    } else {
      // this shouldn't happen
    }
  }
}
