import { Component } from '@angular/core';
import { ColumnSet, GlobalState } from '../../../global.state';
import * as _ from 'lodash-es';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Util } from '../../../utils/util.service';
import { environment } from '../../../../environments/environment';
import { GlobalDataService } from '../../../globalData';
import { Auth } from '../../../auth.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faCrosshairsSimple } from '@fortawesome/pro-light-svg-icons/faCrosshairsSimple';
import { RipThemeLoadingSpinnerService } from '../../../theme/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ThemePalette } from '@angular/material/core';
import { ColumnSetForm } from '../../../utils/dataInterfaces';
import { EVENT_NAMES } from '../../../utils/enums';
import { Logger } from '../../../utils/logger.service';

@Component( {
  selector: 'rip-column-selector',
  template: `
    <h3 mat-dialog-title class="modal-title column-selector-title"> Manage Custom Views</h3>
    <mat-divider class="modal-header-divider"></mat-divider>
    <div class="row" mat-dialog-content>
      <div id="{{spinnerSelector}}" class="spinner">
        <div></div>
      </div>
      <div class="flexing vertical-flex space-between with-gap">
        <!--        EXISTING VIEWS -->
        <div>
          <div class="ripsaw-card-header flexing-row-only center-horizontally center-vertically">
            Existing Views
          </div>

          <div class="flexing-row-only with-gap space-between center-vertically"
               *ngFor="let set of userColumnSets">
            <div style="flex: 2;" class="column-set-label">{{ set.label }}</div>

            <button mat-raised-button (click)="pickSet(set)" color="accent" style="flex: 1;"
                    matTooltip="Use View"
                    matTooltipClass="mat-tooltip-custom"
                    class="icon-button column-set-button">
              <fa-icon [icon]="faCrosshairsSimple" [fixedWidth]="true"></fa-icon>
            </button>
            <button mat-raised-button (click)="editSet(set)" color="primary" style="flex: 1;"
                    matTooltip="Edit View"
                    matTooltipClass="mat-tooltip-custom"
                    class="icon-button column-set-button">
              <fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon>
            </button>
            <button mat-raised-button (click)="deleteSet(set)" color="warn" style="flex: 1;"
                    class="icon-button column-set-button"
                    matTooltip="Delete View"
                    matTooltipClass="mat-tooltip-custom">
              <fa-icon [icon]="faTrash" [fixedWidth]="true" size="sm"></fa-icon>
            </button>
          </div>
          <div class="row" *ngIf="userColumnSets?.length === 0">
            No Custom Views
          </div>
        </div>
        <!-- NEW VIEW -->
        <div *ngIf="showForm">
          <div class="ripsaw-card-header">
            <span *ngIf="!editing">New View</span>
            <span *ngIf="editing">Update View ({{ selectedSet?.label }})</span>
          </div>
          <form [formGroup]="form" autocomplete="off">
            <div class="form-group">
              <mat-form-field [color]="labelColor">
                <input matInput formControlName="label" name="label" id="label"
                       placeholder="Label" (keyup)="labelChanged()">
                <mat-error *ngIf="form.controls.label.hasError('required')">
                  Label is required
                </mat-error>
                <mat-hint *ngIf="labelAlreadyExists">
                  You already have a column set with this name. You will overwrite that set if you save with the same
                  name.
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="form-group" style="margin-bottom: 2rem;">
              <div class="form-check-inline">
                <mat-checkbox name="selectDeselectAll" id="selectDeselectAll"
                              formControlName="selectDeselectAll"
                              (change)="selectDeselectAll()">
                  Select/Deselect All
                </mat-checkbox>
              </div>
            </div>
            <div class="form-group">
              <label>Columns <span style="font-size: 8pt;">(Can be ordered by dragging)</span></label>

              <div class="row">
                <div class="col-sm-12">
                  <ul class="list-group" cdkDropList [cdkDropListData]="columnList" (cdkDropListDropped)="drop($event)">
                    <li *ngFor="let item of columnList; let i = index" class="list-group-item" cdkDrag
                        [cdkDragData]="item">
                      <mat-checkbox [checked]="item.checked"
                                    (change)="item.checked = !item.checked">{{ item.name }}
                      </mat-checkbox>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
          <button mat-button (click)="cancel()">
            Cancel
          </button>
        </div>

      </div>
    </div>

    <mat-divider class="modal-footer-divider"></mat-divider>
    <div mat-dialog-actions class="space-between">
      <button mat-button (click)="close()">
        Close
      </button>
      <button mat-button color="primary" *ngIf="showForm" [disabled]="!form.valid" (click)="submit()">
        {{ editing ? 'Update' : 'Create' }}
      </button>
      <button mat-button class="icon-button" *ngIf="!showForm"
              (click)="setupNewViewForm()"
              matTooltip="Add a New Custom View"
              matTooltipClass="mat-tooltip-custom">
        Add New View
      </button>
    </div>


  `,
  styleUrls: [
    './columnSelector.scss',
  ],
} )
export class ColumnSelectorComponent {

  protected readonly faTrash = faTrash;
  protected readonly faEdit = faEdit;
  protected readonly faPlus = faPlus;
  protected readonly faCrosshairsSimple = faCrosshairsSimple;

  columnList: any[] = [];
  userColumnSets: any[] = [];

  form: FormGroup = new FormGroup<ColumnSetForm>( {
    label: new FormControl<string>( '', Validators.required ),
    selectDeselectAll: new FormControl<boolean>( false ),
  } );

  selectedAll: boolean = false;
  labelColor: ThemePalette = 'primary';

  editing: boolean = false;
  labelAlreadyExists: boolean = false;

  selectedSet: any;

  appName: string = '';
  infoType: string;

  unsavedChanges: boolean = false;

  showForm: boolean = false;

  spinnerSelector: string = 'column-selector-spinner';

  constructor( private _state: GlobalState,
               private _gdService: GlobalDataService,
               private _spinnerService: RipThemeLoadingSpinnerService,
               private snackBar: MatSnackBar,
               public dialogRef: MatDialogRef<ColumnSelectorComponent>,
               private _auth: Auth ) {
    this.clearForm();
    this.appName = environment.appName;
    const self = this;
    this.infoType = _state.globalVars.infoType;
    if ( !this.columnList ) {
      this.columnList = [];
    }
    const cols = this._state.allColumnsList;
    cols.forEach( ( col: string ) => {
      const colObj = self._state.allColumnsObject[col];
      if ( colObj ) {
        self.columnList.push( { name: self._state.allColumnsObject[col].account.name, key: col, checked: false } );
      } else {
        self.columnList.push( { name: col, key: col, checked: false } );
      }
    } );

    this.userColumnSets = this._state.globalVars.userColumnSets;
  }

  labelChanged() {
    if ( this.editing ) {
      this.unsavedChanges = this.unsavedChanges || this.form.controls.label.value !== this.selectedSet.label;
      if ( this.unsavedChanges ) {
        const existingSet = _.find( this.userColumnSets, { label: this.form.controls.label.value } );
        this.labelAlreadyExists = !!existingSet;
        this.labelColor = 'accent';
      }
    } else {
      this.unsavedChanges = this.form.controls.label.value !== '';
      const existingSet = _.find( this.userColumnSets, { label: this.form.controls.label.value } );
      this.labelAlreadyExists = !!existingSet;
      this.labelColor = 'primary';
    }
  }

  selectAll() {
    this.columnList.forEach( function ( col ) {
      col.checked = true;
    } );
    this.selectedAll = true;
  }

  deselectAll() {
    this.columnList.forEach( function ( col ) {
      col.checked = false;
    } );
    this.selectedAll = false;
  }

  selectDeselectAll() {
    this.unsavedChanges = true;
    if ( !this.selectedAll ) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  selectSome( cols: string[] ) {
    this.unsavedChanges = true;
    const self = this;
    this.deselectAll();
    cols.forEach( function ( col ) {
      const column: any = _.find( self.columnList, ( c: any ) => {
        return c.key === col;
      } );
      if ( column ) {
        column.checked = true;
      }
    } );
  }

  submit() {
    // this.output = '(closed) ' + this.selected;
    const label = this.form.controls.label.value;
    const columns: string[] = [];
    this.columnList.forEach( function ( col ) {
      if ( col.checked ) {
        columns.push( col.key );
      }
    } );
    this._spinnerService.show( this.spinnerSelector );
    if ( !this.editing && !this.labelAlreadyExists ) {
      this._gdService.createColumnSet( label, columns )
        .subscribe( {
          next: ( response: any ) => {
            const newSet = { id: response.data.insertId, label, columns };
            this._state.globalVars.userColumnSets.push( newSet );
            this.userColumnSets = this._state.globalVars.userColumnSets;
            this._state.notifyDataChanged( EVENT_NAMES.CHANGE_INFO_TYPE, label );
            this._state.notifyDataChanged( EVENT_NAMES.COLUMN_SET_CREATED, newSet );
            this._spinnerService.hide( 0, this.spinnerSelector );
            Logger.log( `set ${ label } created` );
            this.snackBar.open( 'Custom Column Set Created Successfully', null, Util.getSnackBarOptions() );
            this.hideForm();
          }, error: ( err ) => {
            console.error( 'Error creating custom column set ' );
            console.error( err );
            this._spinnerService.hide( 0, this.spinnerSelector );
            this.snackBar.open( `Error Creating Custom Column Set: ${ Util.getRefCodeSupportString( err.refCode ) }`,
              'dismiss', Util.getSnackBarOptions() );
          },
        } );
    } else {
      if ( this.labelAlreadyExists ) {
        this.selectedSet = _.find( this.userColumnSets, { label } );
      }
      this._gdService.updateColumnSet( this.selectedSet.id, label, columns )
        .subscribe( {
          next: () => {
            const setToUpdate: any = _.find( this.userColumnSets, { id: this.selectedSet.id } );
            setToUpdate.label = label;
            setToUpdate.columns = columns;
            this._state.notifyDataChanged( EVENT_NAMES.CHANGE_INFO_TYPE, label );
            this._spinnerService.hide( 0, this.spinnerSelector );
            Logger.log( `set ${ label } updated` );
            this.snackBar.open( 'Custom Column Set Updated Successfully', null, Util.getSnackBarOptions() );
            this.hideForm();
          }, error: ( err ) => {
            console.error( 'Error updating custom column set ' );
            console.error( err );
            this._spinnerService.hide( 0, this.spinnerSelector );
            this.snackBar.open( `Error Updating Custom Column Set: ${ Util.getRefCodeSupportString( err.refCode ) }`,
              'dismiss', Util.getSnackBarOptions() );
          },
        } );
    }
  }

  cancel() {
    this.hideForm();
    this.clearForm();
  }

  close() {
    this.dialogRef.close();
  }

  hideForm() {
    this.showForm = false;
  }

  revealForm() {
    this.showForm = true;
  }

  editSet( set: any ) {
    this.revealForm();
    this.form.controls.label.setValue( set.label );
    this.selectedSet = set;
    this.selectSome( set.columns );
    this.editing = true;
    this.unsavedChanges = false;
  }

  pickSet( set: ColumnSet ) {
    this._state.notifyDataChanged( EVENT_NAMES.CHANGE_INFO_TYPE, set.label );
    this.hideForm();
    this.clearForm();
  }

  deleteSet( set: any ) {
    this._spinnerService.show( this.spinnerSelector );
    this._gdService.deleteColumnSet( set.id ).subscribe( {
      next: () => {
        if ( this.selectedSet && this.selectedSet.id === set.id ) {
          this.clearForm();
        }
        _.remove( this._state.globalVars.columnSets, { id: set.id } );
        _.remove( this.userColumnSets, { id: set.id } );
        this._state.notifyDataChanged( EVENT_NAMES.CHANGE_INFO_TYPE, this._state.defaultColumnSetLabel );
        this.clearForm();
        this._spinnerService.hide( 0, this.spinnerSelector );
        this.snackBar.open( 'Custom Column Set Deleted Successfully', null, Util.getSnackBarOptions() );
        // Logger.log( `set ${set.name} deleted` );
      }, error: ( err ) => {
        console.error( 'Error deleting custom column set' );
        console.error( err );
        this._spinnerService.hide( 0, this.spinnerSelector );
        this.snackBar.open( `Error Deleting Custom Column Set: ${ Util.getRefCodeSupportString( err.refCode ) }`,
          'dismiss', Util.getSnackBarOptions() );
      },
    } );
  }

  setupNewViewForm() {
    this.clearForm();
    this.selectedSet = undefined;
    this.revealForm();
  }

  clearForm() {
    this.form = new UntypedFormGroup( {
      label: new FormControl<string>( '', Validators.required ),
      selectDeselectAll: new UntypedFormControl( '' ),
    } );
    this.deselectAll();
    this.editing = false;
    this.selectedAll = false;
    this.unsavedChanges = false;
  }

  drop( event: CdkDragDrop<string[]> ) {
    moveItemInArray( this.columnList, event.previousIndex, event.currentIndex );
  }

}

