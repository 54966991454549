import { createAction } from '@ngrx/store';
import { SharedWorkspaceDto, UserDto, WorkspaceWithUsersDto } from '../../api';
import { WorkspaceLoadedStore, WorkspacePermissionNameStore } from './models';

export const setLoadedWorkspace = createAction(
  '[Workspaces] Set Loaded',
  (workspace: WorkspaceWithUsersDto | SharedWorkspaceDto, type: WorkspaceLoadedStore['type']) => ({ payload: {workspace, type} }),
);

export const loadPrimaryWorkspace = createAction(
  '[Workspaces] Load Primary',
);

export const getLoadedWorkspacePermissions = createAction(
  '[Workspaces] Get Loaded Permissions',
);

export const setLoadedWorkspacePermissions = createAction(
  '[Workspaces] Set Loaded Permissions',
  (value: WorkspacePermissionNameStore[]) => ({ payload: value }),
);

export const setMyWorkspaces = createAction(
  '[Workspaces] Set My',
  (value: WorkspaceWithUsersDto[]) => ({ payload: value }),
);

export const addMyWorkspace = createAction(
  '[Workspaces] Add My',
  (value: WorkspaceWithUsersDto) => ({ payload: value }),
);

export const updateMyWorkspace = createAction(
  '[Workspaces] Update My',
  (value: WorkspaceWithUsersDto) => ({ payload: value }),
);

export const deleteMyWorkspaces = createAction(
  '[Workspaces] Delete My',
  (value: WorkspaceWithUsersDto['id'][]) => ({ payload: value }),
);

export const setSharedWorkspaces = createAction(
  '[Workspaces] Set Shared',
  (value: SharedWorkspaceDto[]) => ({ payload: value }),
);

export const updateSharedWorkspace = createAction(
  '[Workspaces] Update Shared',
  (value: SharedWorkspaceDto) => ({ payload: value }),
);

export const removeSharedWorkspaces = createAction(
  '[Workspaces] Remove Shared',
  (value: SharedWorkspaceDto['id'][]) => ({ payload: value }),
);

export const updateIsRefreshingWorkspaces = createAction(
  '[Workspaces] Update Is Refreshing',
  (is: boolean, types?: WorkspaceLoadedStore['type'][]) => ({ payload: { is, types } }),
);

export const updateSelectedShareeWorkspaces = createAction(
  '[Workspaces] Update Selected Sharee',
  (value: UserDto) => ({ payload: value }),
);

export const addCreatingIdsWorkspaces = createAction(
  '[Workspaces] Add Creating Ids',
  (value: number[]) => ({ payload: value }),
);

export const addCreatingIdsWorkspacesSuccess = createAction(
  '[Workspaces] Add Creating Ids Success',
  (value: number[]) => ({ payload: value }),
);

export const removeCreatingIdsWorkspaces = createAction(
  '[Workspaces] Remove Creating Ids',
  (value: number[]) => ({ payload: value }),
);

export const clearWorkspace = createAction(
  '[Workspaces] Clear',
);
