import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { Account, FormsUtil } from '@ripsawllc/ripsaw-analyzer';
import { Subject } from 'rxjs';
import { GlobalState } from '../../../../../global.state';
import { Util } from '../../../../../utils/util.service';
import moment from 'moment/moment';
import { isMoment, Moment } from 'moment/moment';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { TreasuryRatesUtil } from '../../../../../utils/treasury-rates.util';
import { ErrorStateMatcher } from '@angular/material/core';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { HouseholdMembersState } from '../../../../../utils/household-members.state';
import { MatSelectChange } from '@angular/material/select';
import { environment } from '../../../../../../environments/environment';
import { AddHouseholdMemberDialogComponent } from '../../../../../reusableWidgets/household-members/add-household-member-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';

@Component( {
  selector: 'rip-term-life-insurance-form',
  templateUrl: './term-life-insurance-form-component.html',
} )

export class TermLifeInsuranceFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy: Subject<void> = new Subject<void>();
  protected readonly faQuestionCircle: IconDefinition = faQuestionCircle;
  protected readonly faLifeRing: IconDefinition = faLifeRing;

  @Input() form: UntypedFormGroup;
  @Input() label: string;
  @Input() matcher: ErrorStateMatcher;
  @Input() type: string;
  @Input() account: Account;
  @Input() inRevision: boolean = false;

  subscriberName: string = 'termLifeInsuranceForm';
  today: Moment = moment();

  currentAge: number;
  minEffectiveDate: moment.Moment = moment( '01/01/1950', 'MM/DD/YYYY' );

  end_of_term: Moment;
  remaining_term: number;
  fairValueOfPremiums: number;
  fairValue: number;

  constructor( private _state: GlobalState,
               private treasuryRatesUtil: TreasuryRatesUtil,
               public householdMembersState: HouseholdMembersState,
               public dialog: MatDialog ) {
    super();

  }


  ngOnDestroy(): void {
    this.unsubscribeFromUpdate( this._state );
    this.onDestroy.next();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();

    } );
    this.setupUpdateSubscription( this._state );
  }

  patchForm() {
    this.form.controls.effective_date.setValidators( Validators.compose( [ Validators.required, ManualAccountManagerState.endOfTermIsBeforeToday( this ) ] ) );
    this.form.controls.value.disable();

    if ( environment.env !== 'prod' ) {
      window[ `ripsaw_termLifeInsuranceForm_${ this.account ? this.account.account_id : 'new' }` ] = this;
    }

    if ( this.account ) {
      ManualAccountUtil.patchTermLifeInsuranceForm( this.form, this.account );
      this.birthDateChanged(); // call this to figure out today's age
      this.effectiveDateChanged(); // call this to figure out today's remaining term and end_of_term
      this.getPV();
    } else {
      this.form.controls.quantity.setValue( 1 );
      this.form.controls.name.setValue( 'Term Life Insurance' );
      this.autofocus();
      this.form.markAsPristine();
    }
  }

  birthDateChanged() {
    this.currentAge = this.today.diff( this.form.controls.birth_date.value, 'months' );
    // try and calc value of insurance
    this.getPV();
  }

  setBirthDateFromHM( change: MatSelectChange ) {
    if ( change.value === 'add-new' ) {
      const ref = this.dialog.open( AddHouseholdMemberDialogComponent, {
        hasBackdrop: true,
        width: '800px',
        height: '600px',
        panelClass: 'household-member-dialog',
      } );

      ref.afterClosed().subscribe( {
        next: ( newMember ) => {
          if ( newMember ) { // only do something if there is a new member
            this.householdMembersState.handleNewMember( newMember );
            this.form.controls.birth_date.setValue( newMember.birth_date );
            this.birthDateChanged();
          } else {
            this.form.controls.birth_date.setValue( null );
          }
        },
      } );
    } else {
      this.form.controls.birth_date.setValue( change.value );
      this.birthDateChanged();
    }
  }

  genderChanged() {
    // try and calc value of insurance
    this.getPV();
  }

  amountOfInsuranceChanged() {
    // try and calc value of insurance
    this.getPV();
  }

  premiumChanged() {
    Util.updateInputCurrencyFormat( 'premium', this.form );
    this.getPV();
  }

  getPV() {
    const vals: any = this.sanitizeRawValues( this.form.getRawValue() );
    if ( vals.amount_of_insurance > 0 && this.remaining_term > 0 && vals.gender && isMoment( vals.birth_date ) && this.end_of_term?.isAfter( this.today ) ) {
      let tempAcct: Account;
      if ( !this.account ) {
        tempAcct = {};
      } else {
        tempAcct = this.account;
      }
      tempAcct.expected_remaining_life = {
        gender: vals.gender,
        birth_date: vals.birth_date,
      };
      tempAcct.insurance_info = {
        amount_of_insurance: vals.amount_of_insurance,
        effective_date: vals.effective_date,
        life_insurance_term: vals.life_insurance_term,
        premium: vals.premium,
        household_member: null
      };

      const pvData = ManualAccountUtil.getPVOFTermLifeInsurance( tempAcct, this.treasuryRatesUtil.treasuryRates );
      this.form.controls.value.setValue( pvData.pv );
      this.form.controls.price.setValue( pvData.pv );
      this.fairValue = pvData.pv;
      this.fairValueOfPremiums = pvData.pvOfPremiums;
    }
  }

  effectiveDateChanged() {
    if ( this.form.controls.effective_date.hasError( 'matDatepickerMin' ) ) {
      this.form.controls.effective_date.setValue( this.minEffectiveDate );
    }

    if ( !isNaN( this.form.controls?.life_insurance_term?.value ) && this.form.controls?.life_insurance_term?.value > 0 ) {
      this.end_of_term = moment( this.form.controls.effective_date.value )
        .add( FormsUtil.getSanitizedFloatValue( this.form.controls?.life_insurance_term?.value ), 'years' );
      this.form.controls.effective_date.updateValueAndValidity();
      this.remaining_term = moment( this.end_of_term )
        .diff( this.today, 'months' );
      this.getPV();
    }
  }

  termChanged() {
    if ( isMoment( this.form.controls.effective_date.value ) ) {
      this.end_of_term = moment( this.form.controls?.effective_date?.value )
        .add( FormsUtil.getSanitizedFloatValue( this.form.controls?.life_insurance_term?.value ), 'years' );
      this.remaining_term = this.end_of_term.diff( this.today, 'months' );
      this.form.controls.effective_date.updateValueAndValidity();
      this.getPV();
    }
  }

  sanitizeRawValues( rawValues: any ): any {
    return {
      amount_of_insurance: FormsUtil.getSanitizedFloatValue( rawValues?.amount_of_insurance ),
      life_insurance_term: FormsUtil.getSanitizedFloatValue( rawValues?.life_insurance_term ),
      effective_date: moment( rawValues?.effective_date ),
      gender: rawValues?.gender,
      birth_date: moment( rawValues?.birth_date ),
      premium: FormsUtil.getSanitizedFloatValue( rawValues?.premium ),
    };
  }

  ignoreEnter( event: KeyboardEvent ) {
    event?.preventDefault();
    const target: EventTarget = event.target;
    ( target as HTMLElement ).blur();
  }

  tabPressed( event: KeyboardEvent ): void {
    event?.preventDefault();
    const target: EventTarget = event.target;
    ( target as HTMLElement ).blur();
    this.premiumChanged();
  }
}
