import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

@Component( {
  selector: 'rip-trading-view-forex-cross-rates',
  template: `
    <div class="heading">
      <hr>
      <h1 style="padding-left: 20px;">Forex Cross Rates</h1>
      <hr>
      <br>
      <table>
        <tr>
          <th>Currency</th>
        </tr>
        <tr *ngFor="let currency of currencies">
          <td *ngIf="user_currencies.includes(currency)"><input class="btn" type="checkbox" checked (click)="AddCurrency(currency)">{{currency}}</td>
          <td *ngIf="!user_currencies.includes(currency)"><input class="btn" type="checkbox" (click)="AddCurrency(currency)">{{currency}}</td>
        </tr>
      </table>
      <div class="tradingview-widget-container" style="height: 300px;   margin-left: auto;
  margin-right: auto;" #containerDiv>
        <div class="tradingview-widget-container__widget"></div>
      </div>
      <div class="tradingview-widget-copyright">

        <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
        by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
      </div>
    </div>
  `,
} )
export class TradingViewForexChartComponent implements AfterViewInit {

  settings: any = {};
  widgetId: string = 'forex-cross-rates-chart';
  counter = 0;

  currencies = [
    'EUR', 'USD', 'JPY', 'GBP', 'CHF', 'AUD', 'CAD', 'NZD', 'CNY',
    'TRY', 'SEK', 'NOK', 'DKK', 'ZAR', 'HKD', 'SGD', 'THB', 'MXN',
    'IDR', 'KRW', 'PLN', 'ISK', 'KWD', 'PHP', 'MYR', 'INR', 'TWD',
    'SAR', 'RUB', 'ILS',
  ];

  user_currencies = [
    'EUR', 'USD', 'JPY', 'GBP', 'CHF', 'AUD', 'CAD', 'NZD', 'CNY', 'INR',
  ];

  @ViewChild( 'containerDiv', { static: true } ) containerDiv: ElementRef;

  constructor( private _elRef: ElementRef ) {
  }

  forexCrossRates( user_currencies ) {
    setTimeout( () => {
      if ( window.addEventListener ) {
        window.addEventListener( 'message', ( e: any ) => {
            if ( e && e.data ) {
              // console.log( e );
              const payload = e.data;

              if ( payload.name === 'tv-widget-no-data' && payload.frameElementId === this.widgetId ) {
                this.containerDiv.nativeElement.style.display = 'none';
              }
            }
          }, false,
        );
      }
      this.settings = {
        'width': 840,
        'height': 400,
        'currencies': this.user_currencies,
        'isTransparent:': true,
        colorTheme: GlobalState.tradingViewColorTheme,
        'locale': 'in',
        'largeChartUrl': 'http://localhost:4200/forexcrossrates',
      };

      const script = document.createElement( 'script' );
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
      script.async = true;
      script.id = this.widgetId;
      script.innerHTML = JSON.stringify( this.settings );
      this.containerDiv.nativeElement.appendChild( script );
      // const brandingDiv = document.createElement( 'div' );
    } );
  }

  ngAfterViewInit() {
    this.forexCrossRates( this.user_currencies );
    // console.log( this.containerDiv );
  }

  removeForexScriptTag() {
    const forexChartScript = this._elRef.nativeElement.querySelector(`#${this.widgetId}`);
    // console.log(forexChartScript);
    forexChartScript.remove();
  }

// Function that adds and removes elements from currencies list:
  AddCurrency( currency ) {
    // console.log( currency );
    if ( this.user_currencies.includes( currency ) ) {
      const index = this.user_currencies.indexOf( currency );
      this.user_currencies.splice( index, 1 );
      // console.log( `Removed:${ currency }` );
      // console.log( `Updated watchlist:${ this.user_currencies }` );
    } else {
      this.user_currencies.push( currency );
      // console.log( `Pushed:${ currency }` );
      // console.log( `Updated watchlist:${ this.user_currencies }` );
    }
    this.removeForexScriptTag();
    this.forexCrossRates( this.user_currencies );
    // console.log( 'AddCurrency' );
    // console.log( this.containerDiv );
  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }

}
