import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NewInvestmentFinderUserInterface } from '../../pages/modals/newInvestmentSelectorModal/components/newInvestmentFinderUserInterface';
import { Benchmark, RiskReturn } from '../../utils/dataInterfaces';
import { RipsawDecimalPipe } from '../../theme/pipes';
import { GlobalDataService, MarketDataService } from '../../globalData';
import { GlobalState } from '../../global.state';
import { UsersUtil } from '../../utils/users.util';
import { Auth } from '../../auth.service';
import { environment } from '../../../environments/environment';
import { ChartColorUtil } from '../../utils/chart-color.util';
import { NewInvestmentSelectorModalComponent } from '../../pages/modals/newInvestmentSelectorModal/newInvestmentSelectorModal.component';
import { SecDataStoredInterface } from '../../pages/modals/newInvestmentSelectorModal/components/newInvestmentSelector/newInvestmentSelector.component';
import { Util } from '../../utils/util.service';
import { MarketInfoUtil } from '../../utils/market-info.util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { MarketCalcTerms } from '../../utils/enums';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component( {
  selector: 'rip-stock-markets-tab',
  templateUrl: './stock-markets-tab.component.html',
  styleUrls: [ './stock-markets-tab.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class StockMarketsTabComponent implements NewInvestmentFinderUserInterface, AfterViewInit, OnDestroy {

  @ViewChild( 'addFundHeaderTemplate', { static: false } ) addFundHeaderTemplate: TemplateRef<any>;
  @ViewChild( 'labelCellTemplate', { static: false } ) labelCellTemplate: TemplateRef<any>;
  @ViewChild( 'forwardLookingMarketsTable', { static: false } ) forwardLookingMarketsTable: DatatableComponent;
  @ViewChild( 'forwardLookingSectorsTable', { static: false } ) forwardLookingSectorsTable: DatatableComponent;


  faPlusCircle: IconDefinition = faPlusCircle;
  faMinusCircle: IconDefinition = faMinusCircle;
  faSquare: IconDefinition = faSquare;
  ColumnMode = ColumnMode;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  // VARIABLES FOR MAIN STOCK FUNDS
  readonly primeIdentifiers: any = {
    'VV': { longName: 'Large Cap (S&P 500)', shortName: 'Large Cap' },
    'VTWO': { longName: 'Small Cap', shortName: 'Small Cap' },
    'VTV': { longName: 'Value', shortName: 'Value' },
    'VUG': { longName: 'Growth', shortName: 'Growth' },
    'VTI': { longName: 'Total US', shortName: 'Total US' },
    'VXUS': { longName: 'Total International', shortName: 'Total Intl' },
    'VT': { longName: 'World', shortName: 'World' },
    // ticker: {longName: '', shortName: ''}
  };

  readonly primeIdentifiersKeysArray: string[] = Object.keys( this.primeIdentifiers );

  tvSymbols = Object.keys( this.primeIdentifiers ).map( t => {
    return [ t ];
  } );

  userStockFunds: any;

  riskReturns: Map<string, RiskReturn> = new Map<string, RiskReturn>();
  riskReturnsValues: RiskReturn[] = [];

  // VARIABLES FOR STOCK SECTORS - this is slightly different cause the base is still VOO, but we aren't going to show it in the cards for the sectors
  sectorRiskReturns: Map<string, RiskReturn> = new Map<string, RiskReturn>();
  sectorRiskReturnsValues: RiskReturn[] = [];

  readonly sectorPrimeIdentifiers: any = {
    'VAW': { longName: '', shortName: 'Basic Materials' },
    'VOX': { longName: 'Communication Services', shortName: 'Communications' },
    'VCR': { longName: '', shortName: 'Consumer Cyclical' },
    'VDC': { longName: '', shortName: 'Consumer Defensive' },
    'VDE': { longName: '', shortName: 'Energy' },
    'VFH': { longName: '', shortName: 'Financial Services' },
    'VHT': { longName: '', shortName: 'Healthcare' },
    'VIS': { longName: '', shortName: 'Industrials' },
    'VNQ': { longName: '', shortName: 'Real Estate' },
    'VGT': { longName: '', shortName: 'Technology' },
    'VPU': { longName: '', shortName: 'Utilities' },
    // ticker: {longName: '', shortName: ''}
  };

  readonly sectorPrimeIdentifiersKeysArray: string[] = Object.keys( this.sectorPrimeIdentifiers );

  tvSectorSymbols = Object.keys( this.sectorPrimeIdentifiers ).map( t => {
    return [ t ];
  } );

  allSectorFunds: any = {};

  sectorRiskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };

  longTermSectorRiskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };

  sectorScatterDatasetToShow: any = {
    datasets: [],
    labels: [],
  };

  //////////////////////////////////////////

  vix: number;

  stockMarketRiskPremium: number;
  loading: boolean = true;
  loadingSectors: boolean = true;

  allFunds: any = {};

  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  _pipe: any = { transform: val => this.ripDecimalPipe.transform( val, '2-2' ) };

  forwardLookingTableColumns: any[] = [];
  forwardLookingTableColumnsForSectors: any[] = [];

  cssClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip',
  };

  messages: any = {
    emptyMessage: 'Loading Risk Return Measures',
    totalMessage: 'Risk Return Measures',
  };

  riskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };

  longTermRiskReturnScatterData: any = {
    datasets: [],
    labels: [],
  };

  scatterDatasetToShow: any = {
    datasets: [],
    labels: [],
  };

  riskReturnError: boolean = false;
  sectorRiskReturnError: boolean = false;

  subscriberName: string = 'StockMarketsTabComponent';

  expectedReturnReadMore: boolean = false;
  volatilityReadMore: boolean = false;
  correlationMatrixReadMore: boolean = false;
  ltmrpReadMore: boolean = false;
  ltmvReadMore: boolean = false;
  cmrpReadMore: boolean = false;
  sharpeRatioReadMore: boolean = false;

  stocksMainBlurbMobileReadMore: boolean = false;

  deviceIsMobile: boolean = false;

  term: MarketCalcTerms = MarketCalcTerms.short;

  marketInfoSettings: any = {};
  appName: string = environment.appName;

  constructor( private _mdService: MarketDataService,
               private _state: GlobalState,
               public dialog: MatDialog,
               private _usersUtil: UsersUtil,
               private _auth: Auth,
               public snackBar: MatSnackBar,
               private _cd: ChangeDetectorRef,
               private _detectorService: DeviceDetectorService,
               private _gdService: GlobalDataService ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    this.marketInfoSettings = UsersUtil.checkPreferences( this._auth, 'marketInfo' )?.marketInfo;


    this.resetFunds();
    this.getRiskReturns();
    this.getSectorRiskReturns();

    if ( environment.env !== 'prod' ) {
      window['ripsaw_stockMarketsTab'] = this;
    }

  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.setColumns();

      this._state.subscribe( 'market.calc.settings.changed', ( settings: any ) => {
        this.marketInfoSettings = settings;
        this.getRiskReturns();
        this.getSectorRiskReturns();
      }, this.subscriberName );

    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [ 'market.calc.settings.changed' ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    this._cd.reattach();
  }

  getSharpeRatioColumnCellClass( arrayName: string ) {
    const self = this;
    const table = arrayName === 'riskReturnsValues' ? self.forwardLookingMarketsTable : self.forwardLookingSectorsTable;
    return ( { row /*, column, value*/ } ) => {
      const rowIndex = table.bodyComponent.getRowIndex( row );
      return {
        'border-on-top': rowIndex === 0,
        'border-on-bottom': rowIndex === self[arrayName].length - 1,
        'border-on-sides': true,
      };
    };
  }


  setColumns() {
    const commonCols = [
      {
        prop: 'label',
        name: '',
        // width: 200,
        headerTemplate: this.addFundHeaderTemplate,
        cellTemplate: this.labelCellTemplate,
      },
      {
        prop: this.term === MarketCalcTerms.short ? 'expectedReturn' : 'longTermExpectedReturn',
        name: 'Expected Return (%)',
        pipe: this._pipe,
      },
      {
        prop: this.term === MarketCalcTerms.short ? 'beta' : 'longTermBeta',
        name: 'Beta',
        pipe: this._pipe,
      },
      {
        prop: this.term === MarketCalcTerms.short ? 'updatedStandardDeviation' : 'longTermUpdatedStandardDeviation',
        name: 'Total Volatility (%)',
        pipe: this._pipe,
      },
    ];
    this.forwardLookingTableColumns = [ ...commonCols,
      {
        prop: this.term === MarketCalcTerms.short ? 'expectedSharpeRatio' : 'longTermSharpeRatio',
        name: 'Expected Sharpe Ratio',
        pipe: this._pipe,
        cellClass: this.getSharpeRatioColumnCellClass( 'riskReturnsValues' ),
      },
    ];

    this.forwardLookingTableColumnsForSectors = [ ...commonCols,
      {
        prop: this.term === MarketCalcTerms.short ? 'expectedSharpeRatio' : 'longTermSharpeRatio',
        name: 'Expected Sharpe Ratio',
        pipe: this._pipe,
        cellClass: this.getSharpeRatioColumnCellClass( 'sectorRiskReturnsValues' ),
      },
    ];

    if ( this.deviceIsMobile ) {
      for ( let i = 1; i < this.forwardLookingTableColumns.length; i++ ) {
        this.forwardLookingTableColumns[i].width = this.forwardLookingTableColumns[i].minWidth = this.forwardLookingTableColumns[i].maxWidth = 80;
      }
    }
  }

  resetFunds() {
    // reset allFunds to prime and then add benchmark funds (not already in the prime list) and user defined funds
    this.allFunds = _.clone( this.primeIdentifiers );
    this.allSectorFunds = _.clone( this.sectorPrimeIdentifiers );
    // this.addBenchmarkComponents();
    // this.addUserConfiguredStockFunds();
  }

  addBenchmarkComponents() {
    // grab the benchmark and add it's stock proxies into allFunds
    const benchmark: Benchmark = this._state.globalVars.benchmark;
    benchmark?.benchmarkData?.stock?.weights.forEach( i => {
      if ( !this.allFunds[i.proxy] ) {
        this.allFunds[i.proxy] = i.label;
      }
    } );
  }

  addUserConfiguredStockFunds() {
    // grab the user's prefs (and have the object we care about initialized as an empty object if it hasn't been)
    const userPrefs = UsersUtil.checkPreferences( this._auth, UsersUtil.UserStockFunds );
    // set the local object to the one from the user's prefs
    this.userStockFunds = userPrefs[UsersUtil.UserStockFunds];
    // add the user configured funds to allFunds
    for ( const key of Object.keys( this.userStockFunds ) ) {
      this.allFunds[key] = this.userStockFunds[key];
    }
  }

  getRiskReturns() {
    // show spinner
    this.loading = true;

    this._gdService.getSecurities( Object.keys( this.allFunds ).join( ',' ) ).pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( securitiesResponse: any ) => {

        // get expected returns
        this._mdService.getRiskReturns( securitiesResponse?.data, 'stock' )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( resp: any ) => {
            // reset the map
            this.riskReturns = new Map<string, RiskReturn>();
            this.riskReturnsValues = this.processReturns( resp?.data?.riskReturns,
              this.riskReturns,
              this.riskReturnScatterData,
              this.longTermRiskReturnScatterData,
              this.allFunds,
              this.userStockFunds );
            // get the base return, VOO to grab the forward-looking header numbers
            const baseRiskReturn = resp?.data?.baseRiskReturn;
            this.vix = baseRiskReturn?.vix;
            this.stockMarketRiskPremium = baseRiskReturn?.updatedRiskPremium;
            this.riskReturnError = MarketInfoUtil.stockRiskReturnsHaveError( this.riskReturnsValues );
            this.scatterDatasetToShow = this.term === MarketCalcTerms.short ? this.riskReturnScatterData : this.longTermRiskReturnScatterData;
            this.loading = false; // hide the spinner
          }, ( err ) => {
            console.error( err );
            this.riskReturnError = true;
            this.loading = false;
          } );
      }, ( err ) => {
        console.error( err );
        this.riskReturnError = true;
        this.loading = false;
      } );
  }

  getSectorRiskReturns() {
    // show spinner
    this.loadingSectors = true;

    this._gdService.getSecurities( Object.keys( this.allSectorFunds ).join( ',' ) )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( securitiesResponse: any ) => {


        // get expected returns
        this._mdService.getRiskReturns( securitiesResponse?.data, 'stock' )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( ( resp: any ) => {
            this.sectorRiskReturns = new Map<string, RiskReturn>();
            this.sectorRiskReturnsValues = this.processReturns( resp?.data?.riskReturns,
              this.sectorRiskReturns,
              this.sectorRiskReturnScatterData,
              this.longTermSectorRiskReturnScatterData,
              this.allSectorFunds,
              {} ); // don't worry about this until we want to let users add their own funds later
            this.sectorRiskReturnError = MarketInfoUtil.stockRiskReturnsHaveError( this.sectorRiskReturnsValues );
            this.sectorScatterDatasetToShow = this.term === MarketCalcTerms.short ? this.sectorRiskReturnScatterData : this.longTermSectorRiskReturnScatterData;
            this.loadingSectors = false;
          }, ( err ) => {
            console.error( err );
            this.sectorRiskReturnError = true;
            this.loadingSectors = false;
          } );
      }, ( err ) => {
        console.error( err );
        this.sectorRiskReturnError = true;
        this.loadingSectors = false;
      } );
  }

  processReturns( retrievedReturns: RiskReturn[],
                  riskReturns: Map<string, RiskReturn>,
                  scatterData: any,
                  longTermScatterData: any,
                  allFunds: any,
                  userStockFunds: any ): RiskReturn[] {

    // setup scatter plot datasets and labels
    scatterData.datasets = [];
    longTermScatterData.datasets = [];
    const riskReturnScatterLabels = [];
    const colors = ChartColorUtil.getColorGradients( retrievedReturns.length );
    // go through the expected returns and set up the map and then back to an array to
    // get the correct order (in case the backend function didn't return the right order)
    for ( let i = 0; i < retrievedReturns.length; i++ ) {
      const rr = retrievedReturns[i];

      rr.label = allFunds[rr.identifier].shortName ?? allFunds[rr.identifier];
      rr.longName = allFunds[rr.identifier].longName ?? rr.label;

      // set the label for this risk return set in the scatter plot
      riskReturnScatterLabels.push( rr.label );
      // set the data point and the dataset options
      const point = { x: rr.updatedStandardDeviation, y: rr.expectedReturn };

      scatterData.datasets.push( {
        data: [ point ],
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],

      } );

      const longTermPoint = { x: rr.longTermUpdatedStandardDeviation, y: rr.longTermExpectedReturn };

      longTermScatterData.datasets.push( {
        data: [ longTermPoint ],
        label: rr.label,
        pointRadius: 6,
        backgroundColor: colors[i],
        borderColor: colors[i],
        pointBackgroundColor: colors[i],

      } );


      /*      if ( userStockFunds[rr.identifier] ) {
              rr.userAdded = true;
            }*/
      riskReturns.set( rr.identifier, rr );
    }
    scatterData.labels = riskReturnScatterLabels;
    longTermScatterData.labels = riskReturnScatterLabels;

    return Array.from( riskReturns.values() );
  }

  openScreener() {
    this.dialog.open( NewInvestmentSelectorModalComponent, {
      data: {
        finderUser: this,
      },
      width: '85vw',
      disableClose: false,
      hasBackdrop: true,
    } );
  }

  investmentFinderMessage: string = 'Choose a fund to add';

  newSecuritiesChosen( chosenSecurityData: SecDataStoredInterface[] ) {
    if ( chosenSecurityData && chosenSecurityData.length > 0 ) {
      chosenSecurityData.forEach( ( newSec: SecDataStoredInterface ) => {

        // check to see if the security is already in allFunds, don't want or need it there more than once

        if ( !this.allFunds[newSec.ticker] ) {
          this.allFunds[newSec.ticker] = newSec.securityData.ticker_name; // set in all funds
          this.userStockFunds[newSec.ticker] = newSec.securityData.ticker_name; // also set in user funds object
        } else {
          this.snackBar.open( `${ newSec.ticker } is already in the list` );
        }
      } );

      if ( this._state.globalVars.investmentSelector ) {
        this._state.globalVars.investmentSelector.close();
      }

      this.getRiskReturns();
      this.saveUserPrefs();

    } else {
      console.error( 'no data from the screener somehow' );
    }

  }

  saveUserPrefs() {
    // grab the whole prefs object again in case it changed from somewhere else
    const userPrefs = UsersUtil.checkPreferences( this._auth, UsersUtil.UserStockFunds );
    // this is a mirror of what is in the get function above
    userPrefs[UsersUtil.UserStockFunds] = this.userStockFunds;

    // for now, we aren't gonna tell the user this stuff is being saved. eventually, we might
    this._usersUtil.savePreferences( userPrefs );
  }

  removeFund( fund: string ) {
    delete this.userStockFunds[fund];
    delete this.allFunds[fund];
    this.saveUserPrefs();
    this.getRiskReturns();
  }

  openSBBILink() {
    Util.openExternalUrl( MarketInfoUtil.SBBI_URL );
  }

  changeTerm( term: MarketCalcTerms ) {
    this.term = term;
    // console.log( 'change term' );
    this.scatterDatasetToShow = this.term === MarketCalcTerms.short ? this.riskReturnScatterData : this.longTermRiskReturnScatterData;
    this.sectorScatterDatasetToShow = this.term === MarketCalcTerms.short ? this.sectorRiskReturnScatterData : this.longTermSectorRiskReturnScatterData;
    this.setColumns();
    setTimeout( () => {
      this.forwardLookingMarketsTable.recalculateColumns();
      this.forwardLookingSectorsTable.recalculateColumns();
    }, 1000 );
  }
}
