import { AfterViewInit, Component, Input } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

declare const TradingView: any;

@Component( {
  selector: 'rip-trading-view-chart',
  template: `
      <div id="chart_{{ticker}}" style="height: 300px;"></div>
      <div class="tradingview-widget-copyright">

        <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
        by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
      </div>
  `,
} )

export class TradingViewChartComponent implements AfterViewInit {

  chart: any;
  @Input() ticker: string = 'AAPL';

  ngAfterViewInit() {
    this.chart = new TradingView.widget( {
      container_id: `chart_${ this.ticker }`,
      autosize: true,
      // height: 510,
      // width: 680,
      symbol: this.ticker,
      interval: 'D',
      timezone: 'Etc/UTC',
      theme: GlobalState.tradingViewColorTheme,
      style: '2',
      toolbar_bg: '#F1F3F6',
      enable_publishing: false,
      allow_symbol_change: true,
      show_popup_button: true,
      popup_width: '1000',
      popup_height: '650',
    } );
  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }


}
