import { Injectable } from '@angular/core';
import { faRingsWedding } from '@fortawesome/pro-light-svg-icons/faRingsWedding';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faPlaneDeparture } from '@fortawesome/pro-light-svg-icons/faPlaneDeparture';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons/faEllipsis';
import { faUmbrellaBeach } from '@fortawesome/pro-light-svg-icons/faUmbrellaBeach';
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faLightEmergencyOn } from '@fortawesome/pro-light-svg-icons/faLightEmergencyOn';
import { faBalloons } from '@fortawesome/pro-light-svg-icons/faBalloons';
import { faHammer } from '@fortawesome/pro-light-svg-icons/faHammer';

import { GoalType, ScheduledGoalWithdrawal, User } from './dataInterfaces';
import { HouseholdMember, InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from './logger.service';
import { TitleCasePipe } from '@angular/common';
import { RipsawUnHyphenatePipe } from '../theme/pipes';
import moment, { Moment } from 'moment';
import { USER_GOAL_TYPE_IDS } from './enums';
import { Util } from './util.service';

@Injectable()
export class GoalsUtil {

  static readonly goalTypes: GoalType[] = [
    // 1 left
    {
      id: USER_GOAL_TYPE_IDS.real_estate,
      name: 'Save for a down payment',
      icon: faHouse,
      form: 'generic',
    },
    // 1 right
    {
      id: USER_GOAL_TYPE_IDS.home_improvement,
      name: 'Home Improvement',
      icon: faHammer,
      form: 'generic',
    },

    // 2nd left
    {
      id: USER_GOAL_TYPE_IDS.education,
      name: 'Investing in an Education',
      icon: faGraduationCap,
      form: 'generic',
    },
    // 2nd right
    {
      id: USER_GOAL_TYPE_IDS.large_party,
      name: 'Large Event',
      icon: faBalloons,
      form: 'generic',
    },

    // 3rd left
    {
      id: USER_GOAL_TYPE_IDS.wedding,
      name: 'A Wedding',
      icon: faRingsWedding,
      form: 'generic',
    },
    // 3rd right
    {
      id: USER_GOAL_TYPE_IDS.vacation,
      name: 'Go on a Vacation',
      icon: faPlaneDeparture,
      form: 'generic',
    },
    // 4th left
    {
      id: USER_GOAL_TYPE_IDS.vehicle,
      name: 'Purchase Vehicle',
      icon: faCar,
      form: 'generic',
    },
    // 4th right
    {
      id: USER_GOAL_TYPE_IDS.emergency_fund,
      name: 'Emergency Fund',
      icon: faLightEmergencyOn,
      form: 'no-withdrawal',
    },
    // 5th left
    {
      id: USER_GOAL_TYPE_IDS.retirement,
      name: 'Additional Retirement',
      icon: faUmbrellaBeach,
      form: 'retirement',
    },
    // 5th right
    {
      id: USER_GOAL_TYPE_IDS.other,
      name: 'Other',
      icon: faEllipsis,
      form: 'generic',
    },
  ];

  static getOwnerNames( goal: InvestorGoal, householdMembers: HouseholdMember[], user: User ): string[] {
    const names: string[] = [];

    for ( const owner of goal.owners ) {
      const member = householdMembers.find( ( h: HouseholdMember ) => {
        return h.id === owner; // owner is an id string, h is a HouseholdMember instance
      } );
      if ( member ) {
        names.push( member.name );
      } else {
        if ( owner === user.userId ) {
          names.push( 'Me' );
        } else {
          Logger.log( `household member, with id ${ owner }, must no longer exist, so just ignore it` );
        }
      }
    }

    return names;
  }

  static getGoalTypeById( id: string ): GoalType {
    return this.goalTypes.find( ( gt: GoalType ) => {
      return gt.id === id;
    } );
  }

  static transformIdToDefaultName( id: string ) {
    return new TitleCasePipe().transform( new RipsawUnHyphenatePipe().transform( id ) );
  }

  static goalIsShortTerm( goal: InvestorGoal ): any {
    if ( goal.withdrawal_dates?.length > 0 ) {
      // check first withdrawal date
      if ( moment( GoalsUtil.getMomentFromWithdrawalDate( goal.withdrawal_dates[ 0 ] ) ).diff( moment(), 'years', true ) < 1 ) {
        return {
          date: GoalsUtil.getMomentFromWithdrawalDate( goal.withdrawal_dates[ 0 ] ),
          amount: goal.total_withdrawal / goal.withdrawal_dates.length,
          label: goal.name,
        };
      }
    } else {
      if ( moment( goal.goal_date ).diff( moment(), 'years', true ) < 1 ) {
        return {
          date: goal.goal_date,
          amount: goal.total_withdrawal,
          label: goal.name,
        };
      }
    }
  }

  static calcAnnualContributionsForGoal( expectedReturn: number, goalAmount: number, goalDate: Moment | Date | string ) {

    // C = FV / ((Math.pow(1+i, n) - 1) / i)

    let FV, numberOfYears;

    FV = goalAmount;
    numberOfYears = moment( goalDate )
      .diff( moment(), 'years', true );

    return FV / ( ( Math.pow( 1 + expectedReturn, numberOfYears ) - 1 ) / expectedReturn );
  }

  static calcMonthlyContributionsForGoal( expectedReturn: number, goalAmount: number, goalDate: Moment | Date | string ): number {
    return this.calcAnnualContributionsForGoal( expectedReturn, goalAmount, goalDate ) / 12;
  }

  static calcDateOfCompletion( expectedReturn: number, goalAmount: number, monthlyContributions: number ): Moment {
    // (ln(((FV*i)/C) + 1 ) / ln(1 + i)
    const annualPayment = monthlyContributions * 12;
    const numerator: number = Math.log( ( ( goalAmount * expectedReturn ) / annualPayment ) + 1 );
    const denominator: number = Math.log( 1 + expectedReturn );
    const numberOfYears = numerator / denominator;
    return moment().add( numberOfYears, 'year' );
  }

  // static openExistingGoalDialog( goal: InvestorGoal, dialog: MatDialog, data?: any ): MatDialogRef<ExistingGoalDialogComponent> {
  //   return dialog.open( ExistingGoalDialogComponent, {
  //     hasBackdrop: false,
  //     minWidth: 600,
  //     // minHeight: 500,
  //     data: {
  //       goal: goal,
  //       data,
  //     },
  //   } );
  // }

  // static openAddGoalDialog( dialog: MatDialog ) {
  //   dialog.open( AddGoalDialogComponent, {
  //     hasBackdrop: false,
  //     minWidth: 800,
  //     // minHeight: 500,
  //   } );
  // }

  static getMomentFromWithdrawalDate( withdrawalDate: Date | ScheduledGoalWithdrawal ): Moment {
    if ( typeof withdrawalDate === 'string' || withdrawalDate instanceof Date ) {
      return moment( withdrawalDate );
    } else {
      return moment( withdrawalDate.date );
    }
  }

  static calculateRetirementGoalPV( goalDate: string | Moment | Date, annualWithdrawals: number, totalLTExpectedReturn: number ) {
    const m: Moment = moment( goalDate );
    if ( m.isBefore( moment() ) ) {
      // person is already retired, so there is nothing to be done here. return undefined so it doesn't get shown
      return;
    } else {
      const monthsTillRetirement: number = Util.diffMomentByUTC( m, moment(), 'months', true );
      const LTDiscountFactor = 1 + ( totalLTExpectedReturn / 12 ); // $E$7
      const monthlyWithdrawals = annualWithdrawals / 12; // $D$13
      const pvOfWithdrawals = ( 1 - ( Math.pow( LTDiscountFactor, -monthsTillRetirement ) ) ) / ( LTDiscountFactor - 1 ); // $K$10
      return pvOfWithdrawals * monthlyWithdrawals;
    }
  }
}
