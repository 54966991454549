import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from './pages.routing';
import { NgaModule } from '../theme/nga.module';

import { PagesComponent } from './pages.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VersionCheckerService } from '../globalData/versionChecker.service';
import { BalanceSheetModule } from './balanceSheet/balanceSheet.module';
import { HoldingsModule } from './holdings';
import { TransactionsModule } from './transactions/transactions.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccountSettingsDialogComponent } from './accounts/components/accountSettingsDialog/accountSettingsDialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AccountFullFormComponent, AccountShortFormComponent } from './accounts/components/accountSettingsDialog/components';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbPopoverModule,
  NbRadioModule,
  NbSpinnerModule,
  NbTooltipModule,
} from '@nebular/theme';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { RevisionManagerUtil } from './modals/revisionManager/revisionManager.util';
import { RevisionService } from './modals/revisionManager/revision.service';
import { OverrideModalComponent } from './modals/overrideModal';
import { AddBenchmarkWeightsComponent } from './modals/benchmarkEditor/add-benchmark-weights/add-benchmark-weights.component';
import { BenchmarkState } from '../utils/benchmark.state';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '../utils/gesture.config';
import { CanDeactivateGuard } from './accounts/can-deactivate.guard';
import { SubscriptionDetailsUtil } from '../utils/subscriptionDetails.util';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OverridesService } from '../globalData/overrides.service';
import { GlobalOverridesManagerComponent } from './modals/globalOverridesManager';
import { OverridesUtil } from '../utils/overrides.util';
import { SettingsComponent } from './modals/settings';
import { OverridesDialogUtil } from '../utils/overiddesDialog.util';
import { TransactionsState } from '../utils/transactions.state';
import { BenchmarkSetupModule } from './benchmark-setup/benchmark-setup.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CloseSettingsConfirmationComponent } from './modals/settings/components/closeConfirmation/close-settings-confirmation.component';
import { LibWorkspacePermissionModule } from '../shared/workspace-permission';
import { ChipModule } from 'primeng/chip';
import { RevisionManagerComponent } from './modals/revisionManager';
import { SomethingWentWrongComponent } from './something-went-wrong/something-went-wrong.component';
import { ButtonModule } from 'primeng/button';
import { LoginGuard } from './login.guard';

const SHARED = [ LibWorkspacePermissionModule ];

const pagesModules = [
  CommonModule,
  NgaModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatMenuModule,
  MatCardModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatDividerModule,
  MatListModule,
  MatIconModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTooltipModule,
  NbTooltipModule,
  MatDatepickerModule,
  MatGridListModule,
  MatCardModule,
  MatIconModule,
  MatTabsModule,
  MatStepperModule,
  MatDividerModule,
  MatButtonModule,
  MatGridListModule,
  ChipModule,
  MatBottomSheetModule,
  FontAwesomeModule,
  BalanceSheetModule,
  HoldingsModule,
  TransactionsModule,
  BenchmarkSetupModule,
  NgxDatatableModule,
  MatDialogModule,
  NbLayoutModule,
  NbActionsModule,
  NbContextMenuModule,
  NbMenuModule,
  NbButtonModule,
  NbIconModule,
  NbAlertModule,
  NbRadioModule,
  MatButtonToggleModule,
  NbAccordionModule,
  NbPopoverModule,
  NbCardModule,
  NbSpinnerModule,
  DragDropModule,
  routing,
];

const pagesDeclarations = [
  PagesComponent,
  AccountSettingsDialogComponent,
  AccountFullFormComponent,
  AccountShortFormComponent,
  AddBenchmarkWeightsComponent,
  OverrideModalComponent,
  GlobalOverridesManagerComponent,
  SettingsComponent,
  CloseSettingsConfirmationComponent,
  RevisionManagerComponent,
];

const pagesProviders = [
  CanDeactivateGuard,
  LoginGuard,
  VersionCheckerService,
  RevisionService,
  RevisionManagerUtil,
  BenchmarkState,
  SubscriptionDetailsUtil,
  OverridesService,
  OverridesUtil,
  OverridesDialogUtil,
  TransactionsState,
  {
    provide: MatDialogRef,
    useValue: {},
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: GestureConfig,
  },
];

@NgModule( {
  imports: [ ...pagesModules, ...SHARED, ButtonModule ],
  declarations: [ ...pagesDeclarations, SomethingWentWrongComponent ],
  providers: [ ...pagesProviders ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
} )
export class PagesModule {}
