import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GlobalState } from '../../../../global.state';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { UsersUtil } from '../../../../utils/users.util';
import { Auth } from '../../../../auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../utils/mobileUtil.service';
import { Util } from '../../../../utils/util.service';
import { EVENT_NAMES } from '../../../../utils/enums';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';


@Component( {
  selector: 'rip-go-to-group-menu',
  templateUrl: './goToGroupMenu.component.html',
  styleUrls: [ './goToGroupMenu.scss', '../widgetMenu/widgetMenu.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class GoToGroupMenuComponent implements OnDestroy {

  columnGroups: any[] = [];
  groupDeviations: any = {};
  thresholds: any = {};
  badge: any = {};
  currentGroup: any = {};

  faExclamationCircle: IconDefinition = faExclamationCircle;
  faCircle: IconDefinition = faCircle;

  subscriberName: string = 'goToGroupMenuComponent';

  deviceIsMobile: boolean = false;
  deviceIsDesktop: boolean = true;

  constructor( private _state: GlobalState,
               private _auth: Auth,
               private _detectorService: DeviceDetectorService ) {
    // this.getColumnGroups( _state.globalVars.infoType );
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );
    this.deviceIsDesktop = MobileUtil.deviceIsDesktop( this._detectorService );

    this._state.subscribe( 'benchmark.calculated', () => {
      this.setupData();
    }, this.subscriberName );
    this.setupData();
    this._state.globalVars.currentColumnGroup = this._state.columnGroupings[0];
    this.currentGroup = this._state.globalVars.currentColumnGroup;

  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'benchmark.calculated', this.subscriberName );
  }

  setupData() {
    this.columnGroups = this._state.columnGroupings;
    this.groupDeviations = this._state.globalVars.groupDeviations || {};
    this.thresholds = UsersUtil.checkPreferences( this._auth, 'thresholds' ).thresholds;
    let redBadgeNumber = 0, yellowBadgeNumber = 0;
    if ( this.groupDeviations ) {
      for ( const key of Object.keys( this.groupDeviations ) ) {
        if ( this.groupDeviations[key].color.name === 'red' ) {
          redBadgeNumber++;
        }
        if ( this.groupDeviations[key].color.name === 'yellow' ) {
          yellowBadgeNumber++;
        }
      }
      if ( redBadgeNumber > 0 ) {
        this.badge = { color: Util.getDeviationColorObjectByName( 'red' ), number: redBadgeNumber };
      } else {
        if ( yellowBadgeNumber > 0 ) {
          this.badge = { color: Util.getDeviationColorObjectByName( 'yellow' ), number: yellowBadgeNumber };
        } else {
          this.badge = { color: Util.getDeviationColorObjectByName( 'green' ) };
        }
      }
    }
  }

  goToGroup( group: any ) {
    this.currentGroup = group;
    // then try and find group in set, if it isn't there, change to appropriate set first
    const currentSet = [ ...this._state.defaultColumnSets, ...this._state.globalVars.userColumnSets ].find( ( s: any ) => {
      return s.label === this._state.globalVars.infoType;
    } );

    if ( currentSet.columns.includes( group.label ) ) { // group is in current set
      this._state.notifyDataChanged( 'goto.column.group', group );
    } else {
      const set = [ ...this._state.defaultColumnSets, ...this._state.globalVars.userColumnSets ].find( ( s: any ) => {
        return s.columns.find( ( c: string ) => {
          return c === group.label;
        } );
      } );
      this._state.notifyDataChanged( EVENT_NAMES.CHANGE_INFO_TYPE, set.label );
      setTimeout( () => {
        if ( set.label === this._state.defaultColumnSetLabel ) {
          this._state.globalVars.allocWidget.triggerRerenderOfDatatable( false );
        }
        setTimeout( () => {
          this._state.notifyDataChanged( 'column.group.changed', set.label !== this._state.defaultColumnSetLabel );
          this._state.notifyDataChanged( 'goto.column.group', group );
          this.groupDeviations = this._state.globalVars.groupDeviations;
        }, 500 );
      }, 500 );
    }

  }

  goToBeginning() {
    this._state.notifyDataChanged( 'goto.first.column', {} );
  }

  getIcon( group: any ) {
    if ( this.groupDeviations ) {
      const deviation = this.groupDeviations[group.label].deviation;
      const threshold = this.thresholds[group.label];
      return deviation > ( threshold || group.defaultThreshold ) ? this.faExclamationCircle : this.faCircle;
    } else {
      return this.faCircle;
    }
  }

  getDeviationTooltip( color: string ) {
    switch ( color ) {
      case 'red':
        return 'This portfolio dimension group has at least one component that exceeds the deviation threshold relative to your benchmark';
      case 'yellow':
        return 'This portfolio dimension group has at least one component that exceeds 50% of the deviation threshold relative to your benchmark';
      case 'green':
        return 'All components of this portfolio dimension are within 50% of the deviation threshold relative to your benchmark';
    }
  }

  /*getColumnGroups( infoType: string ) {
    const columnSet: any = [...this._state.globalVars.userColumnSets, ...this._state.defaultColumnSets].find( ( s: any ) => {
      return s.label === infoType;
    } );
    this.makeColumnGroups( columnSet.columns );
  }*/
  /*
    * Function for creating a set of column groups based on which info type is chosen
    * @param cols {Array[String]} - and array of column/group names to be turned into a set of column groups
    * */
  /*makeColumnGroups( cols: string[] ): any {
    const columnType: string = 'alloc'; // since this in only in the global widget, columnType doesn't need to be dynamic
    const self = this;
    this.columnGroups = [];
    for ( const c of cols ) {
      const column = self._state.allColumnsObject[c];
      if ( !column ) {
        // do we want individual columns in here?
        /!* const col = _.clone( self._state.allColumnsObject[c][columnType] );
        this.columnGroups.push( { label: col.name, firstCol: col } );
      } else { *!/
        const group: any = _.find( self._state.columnGroupings, { label: c } );
        if ( group ) {
          if ( self._state.allColumnsObject[group.columns[0]] ) {
            const firstCol = _.clone( self._state.allColumnsObject[group.columns[0]][columnType] );
            this.columnGroups.push( { label: group.label, firstCol, group } );
          } else {
            console.warn( `could not find column with label: ${group.columns[0]}` );
          }
        } else {
          console.warn( `could not find column group with label: ${c}` );
        }
      }
    }
  }*/
}
