<rip-dialog-frame closeButton
>
  <ng-container dialogHeader>Loading Workspace</ng-container>

  <ng-container dialogBody>
    <div class="description">
      Opening this workspace will load the investor information and the associated accounts for this workspace.
      You can seamlessly switch back to your primary workspace or any other workspaces at any time.
      <br/> <br/>
      Are you sure you want to load <strong class="name">“{{ data.workspace.name }}”</strong>?
    </div>

    <p-checkbox [(ngModel)]="dontShowAgain"
                name="dontShowAgain"
                [binary]="true"
                label="Don’t show this message again"
    ></p-checkbox>
  </ng-container>

  <ng-container dialogFooter>
    <button type="button"
            pButton
            class="p-button-outlined p-button-plain"
            label="Cancel"
            (click)="close(null)"
    ></button>

    <button type="button"
            pButton
            cdkFocusInitial
            class="p-button-secondary"
            label="Load"
            (click)="load()"
    ></button>
  </ng-container>
</rip-dialog-frame>
