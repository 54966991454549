import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { GlobalState } from '../../../../../../../global.state';
import { faPlus } from '@fortawesome/sharp-solid-svg-icons/faPlus';
import { MatDialog } from '@angular/material/dialog';
import { AccountManagementUtil } from '../../../../../../../utils/account-management-util.service';
import { NbPopoverDirective } from '@nebular/theme';
import { EVENT_NAMES } from '../../../../../../../utils/enums';
import { WithWorkspacePermission } from '../../../../../../../shared/workspace-permission';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';


@Component( {
  selector: 'rip-account-management-menu',
  templateUrl: './accountManagementMenu.component.html',
  styleUrls: [ '../pagesMenu/pagesMenu.scss', '../../../../baSidebar.scss', './accountManagementMenu.component.scss' ],
} )

export class AccountManagementMenuComponent extends WithWorkspacePermission implements OnDestroy {

  @ViewChild( NbPopoverDirective ) startHerePopover: NbPopoverDirective;

  @Input() sidebarCollapsed: boolean = true;
  @Input() menuHeight: number;
  @Output() itemHover = new EventEmitter<any>();

  editing: boolean = false;
  protected readonly faPlus: IconDefinition = faPlus;


  subscriberName: string = 'accountManagementMenuComponent';

  constructor( private _state: GlobalState,
               public dialog: MatDialog,
               private accountManagementUtil: AccountManagementUtil ) {
    super();

    this.editing = _state.globalVars.editing;

    this._state.subscribe( EVENT_NAMES.EDITING_CHANGED, ( editingState ) => {
      this.editing = editingState;
    }, this.subscriberName );

  }

  onHoverItem( $event: any ): void {
    this.itemHover.emit( $event );
  }

  openAccountManagement(): void {
    this.accountManagementUtil.openAccountManagementDialog();
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.EDITING_CHANGED, this.subscriberName );
  }


  protected readonly faDatabase = faDatabase;
  protected readonly faCog = faCog;
  protected readonly faUniversity = faUniversity;
}
