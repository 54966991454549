import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { HoldingsFilterPreset } from '../holdings.util';
import { MatMenu } from '@angular/material/menu';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../utils/mobileUtil.service';

@Component( {
  selector: 'rip-holdings-filter-preset-menu-item',
  template: `
    <mat-menu #menu>
      <ng-container *ngFor="let p of presetGroups">
        <ng-container *ngIf="p?.presets?.length > 0; else leaf">
          <button mat-menu-item [matMenuTriggerFor]="innerPanel.menu" (click)="shouldFilter(p)"
                  *ngIf="viewLoaded">
            {{ p?.label }}
          </button>
          <rip-holdings-filter-preset-menu-item #innerPanel
                                                [presetGroups]="p?.presets"
                                                (choosePresetFilter)="choosePresetFilter.emit($event)">

          </rip-holdings-filter-preset-menu-item>
        </ng-container>
        <ng-template #leaf>
          <button mat-menu-item (click)="choosePresetFilter.emit(p)">
            {{ p?.label }}
          </button>
        </ng-template>
      </ng-container>
    </mat-menu>
  `,

} )

export class HoldingsFilterPresetMenuItemComponent implements AfterViewInit {

  @ViewChild( 'menu' ) menu: MatMenu;
  @ViewChildren( HoldingsFilterPresetMenuItemComponent ) innerPanels: QueryList<HoldingsFilterPresetMenuItemComponent>;

  @Input() presetGroups: HoldingsFilterPreset[];
  @Output() choosePresetFilter: EventEmitter<any> = new EventEmitter<any>();

  pressedOnce: boolean = false;
  deviceIsTablet: boolean = false;
  viewLoaded: boolean = false;

  constructor( private _detectorService: DeviceDetectorService ) {
    this.deviceIsTablet = MobileUtil.deviceIsTablet( _detectorService );
  }

  shouldFilter( p: HoldingsFilterPreset ) {
    if ( p.filter ) {
      if ( !this.deviceIsTablet || this.pressedOnce ) {
        this.pressedOnce = false;
        this.choosePresetFilter.emit( p );
      } else {
        this.pressedOnce = true;
      }
    } else {
      this.pressedOnce = false;
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.viewLoaded = true;
    } );
  }

  clearPressedOnceFlags() {
    this.pressedOnce = false;
    this.innerPanels.forEach( ( item: HoldingsFilterPresetMenuItemComponent ) => {
      item.pressedOnce = false;
    } );
  }

}
