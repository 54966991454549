import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../../../../../theme/pipes';
import { Util } from '../../../../../utils/util.service';
import { GlobalDataService, PricingService } from '../../../../../globalData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { SecurityQueryBoxComponent } from '../../../../../reusableWidgets/securityQueryBox';
import { ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { GlobalState } from '../../../../../global.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faAnalytics, faInfoCircle, faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-stock-form',
  templateUrl: './stockForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class StockFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy: Subject<void> = new Subject<void>();

  @ViewChild( 'queryBox', { static: false } ) queryBox: SecurityQueryBoxComponent;

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account;

  faAnalytics = faAnalytics;
  faInfoCircle = faInfoCircle;
  faLockAlt = faLockAlt;

  subscriberName: string = 'stockForm';

  constructor( private _pricingService: PricingService,
               private _gdService: GlobalDataService,
               private _state: GlobalState,
               private snackBar: MatSnackBar ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    this.form.controls.value.disable();
    // Logger.log( this.account );
    if ( this.account && this.form ) {
      const position = this.account.positions[ 0 ];

      ManualAccountUtil.patchStockForm( this.form, this.account );

      if ( position ) {
        this.setPrice( position.price );
      } else {
        // TODO: need to inform user that the position has been lost for some reason
        console.error( `ERROR LOADING POSITION FOR MANUAL STOCK: ${ this.account.name }` );
      }

      this.getPublicStockDetails( { ticker: this.form.controls.ticker.value }, true );
    } else {
      this.autofocus();
    }
  }

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  // store the full (not truncated to 2 decimals) values for calculations
  dividendAmount;
  yieldPercent;
  stockPrice;

  // store stock price
  setPrice( price ) {
    if ( price !== undefined && price !== null ) {
      Util.setFormattedValue( 'price', 'Currency', price, this.form );
      this.stockPrice = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value, false );
      this.computeValue();
    }
  }

  // store yield
  setYield( yieldPct ) {
    Util.setFormattedValue( 'annualized_yield', 'Percent', yieldPct, this.form, true );
    this.yieldPercent = FormsUtil.getSanitizedFloatValue( this.form.controls.annualized_yield.value, false );
  }

  setDividend( dividendAmt ) {
    Util.setFormattedValue( 'dividend_per_share', 'Currency', dividendAmt, this.form );
    this.dividendAmount = FormsUtil.getSanitizedFloatValue( this.form.controls.dividend_per_share.value, false );
  }

  computeValue() {
    if ( this.form.controls.quantity.value && this.stockPrice ) {
      const quantity = FormsUtil.getSanitizedFloatValue( this.form.controls.quantity.value, false );
      Util.setFormattedValue( 'value', 'Currency', quantity * this.stockPrice, this.form );
    }
  }

  calcYield() {
    if ( this.dividendAmount && this.stockPrice ) {
      this.setYield( ( this.dividendAmount / this.stockPrice ) * 100 );
    }
  }

  calcPrice() {
    if ( this.yieldPercent && this.dividendAmount ) {
      this.setPrice( this.dividendAmount / ( this.yieldPercent / 100 ) );
    }
  }

  quantityChanged() {
    Util.updateInputDecimalFormat( 'quantity', this.form );
    this.computeValue();
  }

  calcDividend() {
    if ( this.stockPrice && this.yieldPercent ) {
      this.setDividend( ( this.yieldPercent / 100 ) * this.stockPrice );
    }
  }

  costBasisChanged() {
    Util.updateInputCurrencyFormat( 'cost_basis', this.form );
  }

  valueChanged() {
    Util.updateInputCurrencyFormat( 'value', this.form );
  }

  tickerChanged( val: any ) {
    this.form.controls.ticker.setValue( val );
  }

  getPublicStockDetails( event: any, fromPatchFunc?: boolean ) {

    if ( event && event.ticker ) {

      const ticker = event.ticker;
      this.form.controls.ticker.setValue( event.ticker );

      this.queryBox.showRetrieving();

      this._gdService.getStock( ticker )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( stockSearchResult: any ) => {
            const stockInfo = stockSearchResult.data;
            if ( stockInfo && stockInfo.price ) {
              ManualAccountManagerState.clearStockForm( this.form );
              ManualAccountManagerState.patchStockForm( this.form, stockInfo );

              const dividend = ManualAccountManagerState.translateDividend( stockInfo.last_dividend );
              if ( dividend ) {
                this.setDividend( dividend );
                this.setYield( stockInfo.annualized_yield );
              }
              this.setPrice( stockInfo.price );
              if ( !fromPatchFunc ) {
                this.snackBar.open( `Details for ${ ticker } retrieved successfully`, null, Util.getSnackBarOptions() );
              }
            } else {
              if ( !fromPatchFunc ) {
                this.snackBar.open( 'No data could be retrieved for the provided ticker', null, Util.getSnackBarOptions() );
              }
            }

            this.queryBox.hideRetrieving();
            this.queryBox.resetQueryControl( ticker );
          }, error: ( err ) => {
            console.error( err.err );
            if ( !fromPatchFunc ) {
              this.snackBar.open( 'Could not retrieve any data for the provided ticker', null, Util.getSnackBarOptions() );
            }
          },
        } );
    }
  }
}

