import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalState } from '../../../../global.state';
import { SecDataStoredInterface } from '../../newInvestmentSelectorModal/components/newInvestmentSelector/newInvestmentSelector.component';
import { NewInvestmentFinderUserInterface } from '../../newInvestmentSelectorModal/components/newInvestmentFinderUserInterface';
import { RipsawPercentPipe } from '../../../../theme/pipes';
import { NewInvestmentSelectorModalComponent } from '../../newInvestmentSelectorModal/newInvestmentSelectorModal.component';
import { MatDialog } from '@angular/material/dialog';
import { NbStepperComponent } from '@nebular/theme';
import { SCREENER_TAB_NAMES } from '../../../../utils/enums';
import { Logger } from '../../../../utils/logger.service';

@Component( {
  selector: 'rip-proxy-picker',
  templateUrl: './proxyPicker.component.html',
  styleUrls: [ '../benchmarkEditor.scss' ],
} )

export class ProxyPickerComponent implements NewInvestmentFinderUserInterface, AfterViewInit {

  @Input() weight;
  @Input() isBondOrCash;
  @Input() screenerTabToUse: SCREENER_TAB_NAMES;
  @Input() stepper: NbStepperComponent;
  @Output() proxyChosen = new EventEmitter<any>();
  confirmedOnceAlready: boolean = false;

  investmentFinderMessage: string = '';
  needConfirmation: boolean = false;

  constructor( private _state: GlobalState, public dialog: MatDialog ) {

  }

  ngAfterViewInit() {
    setTimeout( () => {
      if ( !this.weight?.proxy ) {
        this.chooseButtonPressed();
      }
    } );
  }

  chooseButtonPressed() {
    // this._state.globalVars.investmentSelector.open( this, false );
    this.dialog.open( NewInvestmentSelectorModalComponent, {
      data: {
        finderUser: this,
        forBenchmarkProxy: true,
        tabsToHide: this.getTabsToHide(),
      },
      width: '85vw',
      disableClose: false,
      hasBackdrop: true,
    } );
  }

  getTabsToHide() {
    if ( !this.screenerTabToUse ) {
      return {};
    } else {
      if ( this.screenerTabToUse === SCREENER_TAB_NAMES.stocks ) {
        return {
          bonds: true,
          moneyMarket: true,
          byFundName: true,
        };
      }
      if ( this.screenerTabToUse === SCREENER_TAB_NAMES.bonds ) {
        return {
          stocks: true,
          moneyMarket: true,
          byFundName: true,
        };
      }
      if ( this.screenerTabToUse === SCREENER_TAB_NAMES.moneyMarket ) {
        return {
          stocks: true,
          bonds: true,
          byFundName: true,
        };
      }
    }
  }

  newSecuritiesChosen( chosenSecurityData: SecDataStoredInterface[] ) {
    if ( chosenSecurityData && chosenSecurityData.length > 0 ) {
      const chosen = chosenSecurityData[0].securityData;
      this._state.globalVars.securities.push( chosen );
      this.weight.proxy = chosen.ticker;
      this.proxyChosen.emit();
      if ( this._state.globalVars.investmentSelector ) {
        this._state.globalVars.investmentSelector.close();
      }
      this.stepper.previous();
    }
  }

  checkSelections( selections: any[] ) {
    if ( selections && selections.length > 0 ) {
      Logger.log( `new securities chosen: ${ selections }` );
      const chosen = selections[0].securityData;
      if ( chosen.stocks > 0 && this.isBondOrCash && !this.confirmedOnceAlready ) {
        this.investmentFinderMessage = `You have chosen a security with a non zero stock exposure (${ new RipsawPercentPipe().transform( chosen.stocks ) }). Click confirm to choose this security anyway`;
        this.needConfirmation = true;
        this.confirmedOnceAlready = true;
        return false;
      } else {
        this.needConfirmation = false;
        return true;
      }
    }

  }


}
