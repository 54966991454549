// noinspection TypeScriptValidateTypes

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { GlobalState } from '../../global.state';
import { RipsawDecimalPipe, RipsawPercentPipe } from '../../theme/pipes';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { environment } from '../../../environments/environment';
import { MarketDataService } from '../../globalData';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { Util } from '../../utils/util.service';
import { ChartOptions, ChartType } from 'chart.js';
import { Auth } from '../../auth.service';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { YieldTableComponent } from './components/yield-table.component';
import { BaseChartDirective } from 'ng2-charts';
import { TreasuryRatesUtil } from '../../utils/treasury-rates.util';
import { EVENT_NAMES } from '../../utils/enums';

@Component( {
  selector: 'rip-us-yield-curve',
  template: `

    <div class="market-info-dashboard-tab-content">
      <nb-card [nbSpinner]="loadingComparison || treasuryRatesUtil.retrievingTreasuryRates">
        <nb-card-header style="position: relative;">
          <nb-badge *ngIf="comparisonError || treasuryRatesUtil.treasuryDataError"
                    text="Error Loading Data. Click here to try again"
                    position="top left" (click)="retrieveDataAgain()"
                    class="button-badge"
                    status="danger">
          </nb-badge>
          <div style="display: flex; align-items: center;">
            <span style="flex: 1; text-align: left;" class="market-info-card-title">
                Treasury Yield Curve <span class="footnote">(As Of {{ asOfDateFormatted }})</span>
              </span>
            <span style="flex: 2; text-align: right;">
                Compared To
                <mat-form-field style="width: 170px;">
                  <mat-select (selectionChange)="selectionChanged()" [(value)]="comparisonSelectionValue">
                    <mat-option value="0.5">6 Months Ago</mat-option>
                    <mat-option value="1">1 Year Ago</mat-option>
                    <mat-option value="2">2 Years Ago</mat-option>
                    <mat-option value="3">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="showDatePicker" style="width: 135px;">
                  <label>
                    <input matInput #comparisonDateInput
                           [min]="minDate"
                           [max]="maxDate"
                           [formControl]="dateInputControl"
                           name="comparison_date_picker"
                           placeholder="Date (MM/DD/YYYY)*"
                           [matDatepicker]="datePicker"
                           (change)="checkDateInput()">
                  </label>
                  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                  <mat-datepicker #datePicker (closed)="checkDateInput()"></mat-datepicker>
                </mat-form-field>
              </span>
          </div>
        </nb-card-header>
        <nb-card-body id="yield-curve-card-body">
          <div id="yield-curve-container">
            <canvas baseChart #yieldChart="base-chart"
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [legend]="lineChartLegend"
                    [type]="lineChartType"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
          </div>
          <div class="market-info-blurb">
            The <b>Treasury Yield Curve</b> provides current nominal U.S. government borrowing rates for a range of
            maturities from 1 Month to 30 Years. Comparing yields of different maturities provides valuable information
            concerning the market’s view of the path of future yields (forward rates) and expected inflation.

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="flexing footnote">
            Treasury data provided by <a (click)="openFREDLink()" class="rip-link" style="margin-left: 5px;"> FRED</a>
          </div>
        </nb-card-footer>
      </nb-card>
      <nb-card [nbSpinner]="treasuryRatesUtil.retrievingTreasuryRates">
        <nb-card-header style="position: relative;">
          <nb-badge *ngIf="treasuryRatesUtil.treasuryDataError" text="Error Loading Data. Click here to try again"
                    position="top left" (click)="getTreasuryData()"
                    class="button-badge"
                    status="danger">
          </nb-badge>
          <div style="display: flex; align-items: center;">
            <span style="display: flex; text-align: left;" class="market-info-card-title">
              Treasury Yields and Risk <span class="footnote">(As Of {{ asOfDateFormatted }})</span>
              </span>
          </div>
        </nb-card-header>
        <nb-card-body> <!--class="no-padding-card-body no-scroll-horizontal">-->
          <div style="overflow: auto;">
            <rip-yield-table #yieldTable [rows]="rows"
                             [deviceIsMobile]="deviceIsMobile"
                             [hiddenSharpeAlphaRow]="hiddenSharpeAlphaRow">
            </rip-yield-table>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="market-info-blurb">
            <a *ngIf="deviceIsMobile && !tableReadMore"
               (click)="tableReadMore = true;"
               class="rip-link">
              Learn More
            </a>
            <ul *ngIf="!deviceIsMobile || tableReadMore">

              <li>
                <b>Incremental Compensation</b> is the annual yield increase/decrease from investing in the next highest
                maturity issue.
              </li>

              <li>
                <div>
                  <b>Modified Duration</b> is a measure of interest rate level risk. Often referred to as parallel
                  shifts in the yield curve. It’s an estimate of the rate of return response for a 1% change in the
                  level of yield. Yield up, price and rate of return decline. Yield down, price and rate of return
                  increase.
                </div>
                <div>
                  <img src="assets/img/app/market-info/modified-duration-equation.svg"
                       alt="Modified Duration Equation" class="short-equation">
                </div>
              </li>

              <li>
                <b>Yield Change Volatility</b> (standard deviation) is a measure of dispersion of yield changes around
                its average.
              </li>

              <li>
                <div>
                  <b>Return Volatility</b> (standard deviation) is a risk measure. It is the dispersion of rates of
                  return around its average. It combines the yield change volatility with the sensitivity to changes in
                  yield (Modified Duration).
                </div>
                <div>
                  <img src="assets/img/app/market-info/return-volatility-equation.svg"
                       alt="Modified Duration Equation"
                       class="short-equation">
                </div>
              </li>

              <li>
                <div><b>Expected Sharpe Ratio</b> provides a measure of expected risk-adjusted performance. It is the
                  expected excess yield for a specific maturity less that of the 1 Month Yield per unit of its Return
                  Volatility.
                </div>
                <div>
                  <img src="assets/img/app/market-info/treasury-expected-sharpe-ratio.svg"
                       alt="Expected Sharpe Ratio Equation"
                       class="equation">
                </div>
              </li>

            </ul>
            <a *ngIf="deviceIsMobile && tableReadMore"
               (click)="tableReadMore = false;"
               class="rip-link">
              Read Less
            </a>
          </div>
        </nb-card-footer>

      </nb-card>
      <nb-card [nbSpinner]="loadingComparison">
        <nb-card-header style="position: relative;">
          <nb-badge *ngIf="comparisonError" text="Error Loading Data. Click here to try again"
                    position="top left" (click)="getTreasuryData()"
                    class="button-badge"
                    status="danger">
          </nb-badge>
          <div style="display: flex; align-items: center;">
            <span style="display: flex; text-align: left;" class="market-info-card-title">
              Treasury Yields and Risk <span class="footnote">(As Of {{ comparisonAsOfDateFormatted }})</span>
              </span>
          </div>
        </nb-card-header>
        <nb-card-body> <!--class="no-padding-card-body no-scroll-horizontal">-->
          <div style="overflow: auto;">
            <rip-yield-table #comparisonYieldTable [rows]="comparisonRows"
                             [deviceIsMobile]="deviceIsMobile"
                             [hiddenSharpeAlphaRow]="hiddenSharpeComparisonAlphaRow">

            </rip-yield-table>
          </div>
        </nb-card-body>
      </nb-card>

    </div>

  `,
  styleUrls: [ './us-yield-curve.component.scss' ],
} )

export class UsYieldCurveComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @ViewChild( 'yieldTable' ) yieldTable: YieldTableComponent;
  @ViewChild( 'comparisonYieldTable' ) comparisonYieldTable: YieldTableComponent;
  @ViewChild( 'yieldChart' ) yieldChart: BaseChartDirective;

  showBack: boolean;

  faChartLine = faChartLine;
  faTable = faTable;


  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();


  comparisonColumns: any[] = [];

  rows: any = [];
  comparisonRows: any = [];

  lineChartDatasetDefault: any = {
    data: [],
    label: `Yield Curve ${ moment().format( 'MM/DD/YYYY' ) }`,
    backgroundColor: '#207bbc',
    pointBackgroundColor: '#207bbc',
    borderColor: 'rgba(32,123,188,0.5)',
    fill: false,
    tension: 0.25,
  };

  lineChartData: any = [
    this.lineChartDatasetDefault,
  ];
  lineChartLabels: any = [];
  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        position: 'bottom',
        title: {
          text: 'Maturity',
        },
      },
      y: {
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Treasury Yield (%)',
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },

  };
  lineChartLegend: boolean = true;
  lineChartType: ChartType = 'line';

  comparisonRates: any;
  loadingComparison: boolean = false;
  comparisonError: boolean = false;

  comparisonSelectionValue: string = '0.5';

  showDatePicker: boolean = false;
  dateInputControl: UntypedFormControl = new UntypedFormControl();

  subscriberName: string = 'usYieldCurveComponent';

  asOfDateFormatted: string;
  comparisonAsOfDateFormatted: string;
  maxDate: moment.Moment;
  minDate: moment.Moment = moment().subtract( 10, 'year' );

  deviceIsMobile: boolean = false;

  tableReadMore: boolean = false;

  sharpeMinMax: any;
  sharpeRange: number;
  hiddenSharpeAlphaRow: any = {};
  hiddenSharpeComparisonAlphaRow: any = {};

  constructor( private _state: GlobalState,
               private _cd: ChangeDetectorRef,
               private _mdService: MarketDataService,
               private _auth: Auth,
               private _detectorService: DeviceDetectorService,
               public treasuryRatesUtil: TreasuryRatesUtil ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    // this.setColumns();

    if ( !this._auth.authenticated() ) {
      this.getTreasuryData(); // if this is in the public market info page, we need to make a call to get the treasury data
    }

    // this.setupData();
    if ( environment.env !== 'prod' ) {
      window['ripsaw_usYieldCurveComponent'] = this;
    }

    this._state.subscribe( 'market.calc.settings.changed', () => {
      // recalcData
      this.getTreasuryData(); // get current treasury data again
      this.selectionChanged(); // set the comparison date and grab that treasury data again too
    }, this.subscriberName );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      // this.doChanges();
      if ( this.treasuryRatesUtil.treasuryRates ) {
        this.setColumnsOnTables();
        this.setupData();
      }
      this._state.subscribe( EVENT_NAMES.TREASURIES_RETRIEVED, () => {
        this.setColumnsOnTables();
        this.setupData();
      }, this.subscriberName );

    }, 1000 );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.TREASURIES_RETRIEVED,
      'market.calc.settings.changed' ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  setColumnsOnTables() {
    this.yieldTable?.setColumns();
    this.comparisonYieldTable?.setColumns();
  }

  retrieveDataAgain() {
    if ( this.treasuryRatesUtil.treasuryDataError ) {
      this.getTreasuryData();
    }
    if ( this.comparisonError ) {
      this.selectionChanged();
    }
  }

  // this function only gets used by the retry badge in the yields and risk card. the initial data try is done by the
  // PagesComponent and subscribed to in the AfterViewInit hook of this component, or is already done
  getTreasuryData() {
    this.treasuryRatesUtil.getTreasuryRates();
  }

  setupData() {
    const todaysRates = this.treasuryRatesUtil.treasuryRates;

    if ( todaysRates ) {
      // reset data arrays for the chart
      this.lineChartData = [
        this.lineChartDatasetDefault,
      ];

      this.lineChartData[0].data = [];
      this.lineChartLabels = [];
      const tableData = this.setupTableData( todaysRates, this.lineChartData[0].data, true );
      this.rows = [ ...tableData.transposedRows ];
      this.asOfDateFormatted = tableData.asOfDateFormatted;
      this.maxDate = tableData.maxDate;

      const sharpeMinMax = this.getMaxAndMinForRow( this.rows[5] );
      const sharpeRange = sharpeMinMax.max - sharpeMinMax.min;

      const keys = Object.keys( todaysRates );

      for ( let i = 0; i < keys.length; i++ ) {
        const key = keys[i];
        this.hiddenSharpeAlphaRow[key] = ( sharpeMinMax.min / sharpeRange ) + ( todaysRates[key][0].sharpeRatio / sharpeRange );
      }

      this.selectionChanged();
    }
  }

  getMaxAndMinForRow( row: any ) {
    let min = 0;
    let max = 0;

    for ( const key of Object.keys( row ) ) {
      const val = parseFloat( row[key] );
      if ( !isNaN( val ) ) {
        if ( val > max ) {
          max = val;
        }
        if ( val < min ) {
          min = val;
        }
      }
    }

    return { min, max };
  }

  selectionChanged() {
    const selectedValue = parseFloat( this.comparisonSelectionValue );
    if ( selectedValue === 0 ) {
      this.comparisonRates = undefined;
      delete this.lineChartData[1];
      this.showDatePicker = false;
    } else {
      let date: moment.Moment;
      if ( selectedValue === 3 ) {
        this.showDatePicker = true;
      } else {
        date = moment();
        date.subtract( selectedValue, 'years' );
        this.getComparisonData( date );
      }

    }
  }

  setupTableData( treasuryRatesObj: any, chartData: any, setLabels: boolean ) {


    // setup transposed data table
    const transposedRows = [
      { header: YieldTableComponent.treasuryYieldHeader }, // 0
      { header: YieldTableComponent.deltaHeader }, // 1
      { header: YieldTableComponent.durationHeader }, // 2
      { header: YieldTableComponent.changeHeader }, // 3
      { header: YieldTableComponent.returnHeader }, // 4
      { header: YieldTableComponent.sharpeHeader }, // 5
    ];
    const keys = Object.keys( treasuryRatesObj );

    let asOfDate;

    const tYieldRow = transposedRows[0];
    const deltaRow = transposedRows[1];
    const durationRow = transposedRows[2];
    const yieldVolatilityRow = transposedRows[3];
    const returnRow = transposedRows[4];
    const sharpeRatioRow = transposedRows[5];

    for ( let i = 0; i < keys.length; i++ ) {
      const key = keys[i];
      const prevKey = i === 0 ? undefined : keys[i - 1];
      asOfDate = treasuryRatesObj[key][0].date;


      const y = treasuryRatesObj[key][0].yield;
      tYieldRow[key] = this.ripPercentPipe.transform( y, '2-2' );
      chartData.push( y * 100 ); // need to convert to percent
      if ( setLabels ) {
        this.lineChartLabels.push( YieldTableComponent.COLUMN_DEFINITIONS[key] );
      }


      if ( prevKey ) {
        deltaRow[key] = this.ripPercentPipe.transform( treasuryRatesObj[key][0].yield - treasuryRatesObj[prevKey][0].yield );
      }
      durationRow[key] = this.ripDecimalPipe.transform( treasuryRatesObj[key][0].duration, '3-3' );
      yieldVolatilityRow[key] = this.ripPercentPipe.transform( treasuryRatesObj[key][0].yieldStandardDeviation, '3-3' );
      returnRow[key] = this.ripPercentPipe.transform( treasuryRatesObj[key][0].returnStandardDeviation, '3-3' );
      sharpeRatioRow[key] = this.ripDecimalPipe.transform( treasuryRatesObj[key][0].sharpeRatio, '3-3' );
    }

    const maxDate = moment( asOfDate ).subtract( 5, 'days' );
    const asOfDateFormatted = moment( asOfDate ).format( 'MM/DD/YYYY' );
    const chartDataLabel = `Yield Curve ${ asOfDateFormatted }`;

    // this.lineChartD

    return { transposedRows, asOfDate, maxDate, chartDataLabel, asOfDateFormatted };
  }

  getComparisonData( date: moment.Moment ) {
    this.loadingComparison = true;
    this._mdService.getTreasuryRates( date.toDate() )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // console.log( resp );
          this.comparisonRates = resp.data;
          const keys = Object.keys( this.comparisonRates );
          this.lineChartData[1] = {
            data: [],
            label: `Yield Curve ${ date.format( 'MM/DD/YYYY' ) }`,
            backgroundColor: '#79afd7',
            pointBackgroundColor: '#79afd7',
            borderColor: 'rgba(121,175,215,0.5)',
            fill: false,
            tension: 0.25,
          };


          const tableData = this.setupTableData( this.comparisonRates, this.lineChartData[1].data, false );
          this.comparisonRows = [ ...tableData.transposedRows ];
          this.comparisonAsOfDateFormatted = tableData.asOfDateFormatted;

          const sharpeMinMax = this.getMaxAndMinForRow( this.comparisonRows[5] );
          const sharpeRange = sharpeMinMax.max - sharpeMinMax.min;

          for ( let i = 0; i < keys.length; i++ ) {
            const key = keys[i];
            this.hiddenSharpeComparisonAlphaRow[key] = ( sharpeMinMax.min / sharpeRange ) + ( this.comparisonRates[key][0].sharpeRatio / sharpeRange );
          }

          this.yieldChart.update();
          this.loadingComparison = false;
          this.comparisonError = false;
        }, error: ( err ) => {
          console.error( err );
          this.comparisonError = true;
          this.loadingComparison = false;
        },
      } );
  }

  checkDateInput() {
    if ( this.dateInputControl.value ) {
      this.getComparisonData( moment( this.dateInputControl.value ) );
    } else {
      // error handling
    }
  }

  openFREDLink() {
    Util.openExternalUrl( 'https://fred.stlouisfed.org' );
  }


  chartClicked( e: any ): void {
    // console.log( e );
  }

  chartHovered( e: any ): void {
    // console.log( e );
  }

}
