import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {
  addCreatingIdsWorkspaces,
  addCreatingIdsWorkspacesSuccess,
  getLoadedWorkspacePermissions,
  loadPrimaryWorkspace,
  removeCreatingIdsWorkspaces,
  setLoadedWorkspace,
  setLoadedWorkspacePermissions,
  setMyWorkspaces,
  setSharedWorkspaces,
  updateIsRefreshingWorkspaces,
} from './actions';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs';
import { SharedWorkspaceDto, WorkspacesService, WorkspaceWithUsersDto } from '../../api';
import { AppState } from '../app-state';
import { selectLoadedWorkspace, selectMyWorkspaces, selectSharedWorkspaces } from './selectors';
import { GlobalState } from '../../global.state';

@Injectable()
export class WorkspaceEffects {


  setLoadedWorkspacePermissions = createEffect( () =>
    this.actions$.pipe(
      ofType( getLoadedWorkspacePermissions ),
      concatLatestFrom( () => this.store.select( selectLoadedWorkspace ) ),
      switchMap( ( [ , loaded ] ) => {
        if ( !loaded ) {
          return EMPTY;
        }

        if ( loaded.type === 'my' ) {
          return of( setLoadedWorkspacePermissions( [ 'OWNER' ] ) );
        }

        return this.workspaceEndpoint.getSharedWorkspacePermissions( { workspaceId: loaded.id } )
          .pipe(
            map( ( { data } ) => setLoadedWorkspacePermissions( data ) ),
          );
      } ),
    ),
  );

  loadPrimaryWorkspaces$ = createEffect( () =>
    this.actions$.pipe(
      ofType( loadPrimaryWorkspace ),
      concatLatestFrom( () => this.store.select( selectMyWorkspaces ) ),
      switchMap( ( [ , myWorkspaces ] ) => {
        const primaryWorkspace = myWorkspaces.find( el => el.isPrimary );
        this._state.globalVars.currentWorkspace = primaryWorkspace;
        if ( !primaryWorkspace ) {
          return EMPTY;
        }

        return of( setLoadedWorkspace( primaryWorkspace, 'my' ) );
      } ),
    ),
  );

  updateIsRefreshingWorkspaces$ = createEffect( () =>
    this.actions$.pipe(
      ofType( updateIsRefreshingWorkspaces ),
      switchMap( ( { payload: { is: isRefreshing, types } } ) => {
        if ( !isRefreshing ) {
          return EMPTY;
        }

        return combineLatest( [
          this.getActualMyWorkspaces( types.includes( 'my' ) ),
          this.getActualSharedWorkspaces( types.includes( 'shared' ) ),
          this.store.select( selectLoadedWorkspace ),
        ] )
          .pipe(
            take( 1 ),
            tap( ( [ my, shared, loaded ] ) => {
              const loadedIsStillAvailable = !!shared.find( el => el.id === loaded.id ) || !!my.find( el => el.id === loaded.id );

              if ( !loadedIsStillAvailable ) {
                this.store.dispatch( loadPrimaryWorkspace() );
              }
            } ),
            map( () => updateIsRefreshingWorkspaces( false ) ),
          );
      } ),
    ),
  );

  addCreatingIdsWorkspaces$ = createEffect( () =>
    this.actions$.pipe(
      ofType( addCreatingIdsWorkspaces ),
      map( ( { payload: ids } ) => {
        ids.forEach( id => this.runPoolingToCheckCreatingForComplete( id ) );

        return addCreatingIdsWorkspacesSuccess( ids );
      } ),
    ),
  );

  constructor(
    private actions$: Actions,
    private workspaceEndpoint: WorkspacesService,
    private store: Store<AppState>,
    private _state: GlobalState,
  ) {
  }

  private getActualMyWorkspaces( needRefresh: boolean ): Observable<WorkspaceWithUsersDto[]> {
    return from(
      !needRefresh ?
        this.store.select( selectMyWorkspaces ) :
        this.workspaceEndpoint.getWorkspaces()
          .pipe(
            map( res => res.data ),
            tap( data => this.store.dispatch( setMyWorkspaces( data ) ) ),
          ),
    );
  }

  private getActualSharedWorkspaces( needRefresh: boolean ): Observable<SharedWorkspaceDto[]> {
    return from(
      !needRefresh ?
        this.store.select( selectSharedWorkspaces ) :
        this.workspaceEndpoint.getSharedWorkspaces()
          .pipe(
            map( res => res.data ),
            tap( data => this.store.dispatch( setSharedWorkspaces( data ) ) ),
          ),
    );
  }

  private runPoolingToCheckCreatingForComplete( workspaceId: number ): void {
    const timerId = setInterval( () => {
      this.workspaceEndpoint.getWorkspaceIsCreated( { workspaceId } )
        .subscribe( res => {
          if ( res.data.isCreated ) {
            clearInterval( timerId );
            this.store.dispatch( removeCreatingIdsWorkspaces( [ workspaceId ] ) );
          }
        } );
    }, 2000 );
  }
}
