import { Component, Inject } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { AlertModalService } from '../../../reusableWidgets/alertModal';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faRecycle } from '@fortawesome/free-solid-svg-icons/faRecycle';
import { environment } from '../../../../environments/environment';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Revision, RevisionManagerUtil } from './revisionManager.util';
import { Util } from '../../../utils/util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RipThemeLoadingSpinnerService } from '../../../theme/services';
import { GlobalState } from '../../../global.state';
import { EVENT_NAMES } from '../../../utils/enums';

@Component( {
  templateUrl: './revisionManager.component.html',
  styleUrls: [ '../../accounts/accounts.scss' ],
} )

export class RevisionManagerComponent {

  faTrash = faTrash;
  faRecycle = faRecycle;

  revNameFormControl: UntypedFormControl = new UntypedFormControl( 'New Revision', Validators.compose( [
    Validators.required,
    this.maxRevsValidator( this ),
  ] ) );

  revNameExists() {
    let newColor: ThemePalette;
    if ( this.revManagerUtil.currentRevNames[this.revNameFormControl.value] ) {
      newColor = 'accent';
    } else {
      newColor = 'primary';
    }
    if ( newColor !== this.revManagerUtil.revNameColor ) {
      this.revManagerUtil.revNameColor = newColor;
    }
  }

  maxRevsValidator( rmc: RevisionManagerComponent ): ValidatorFn {
    return ( /*c: AbstractControl*/ ) => {
      if ( this.revManagerUtil?.userRevisions?.length < this.revManagerUtil?.maxRevisions ) {
        return null;
      } else {
        return {
          maxRevsValidator: {
            valid: false,
          },
        };
      }
    };
  }

  spinnerSelector: string = 'revisionManagerSpinner';
  spinnerOptions: any = { repositionSpinner: { top: '42%', left: '46%' } };

  constructor( private _alertService: AlertModalService,
               public dialogRef: MatDialogRef<RevisionManagerComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               public revManagerUtil: RevisionManagerUtil,
               private snackBar: MatSnackBar,
               private _state: GlobalState,
               private _spinnerService: RipThemeLoadingSpinnerService ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_revisionManager'] = this;
    }

    if ( revManagerUtil.revision && revManagerUtil.revision.created_at ) {
      this.revNameFormControl.setValue( revManagerUtil.revision.name );
    }
    this.init( data.saving );
  }

  initCurrentRevNames() {
    this.revManagerUtil.currentRevNames = {};
    for ( const rev of this.revManagerUtil.userRevisions ) {
      this.revManagerUtil.currentRevNames[rev.name] = true;
    }
  }

  init( saving: boolean ) {
    this.revManagerUtil.saving = saving;
    this.revManagerUtil.setupDeleteProgressButtons();
    this.initCurrentRevNames();
    this.revNameExists();
  }

  closeManageRevsModal() {
    this.dialogRef.close();
  }

  clearSaveForm() {
    this.revNameFormControl.setValue( '' );
  }

  useRevName( revision: Revision ) {
    this.revNameFormControl.setValue( revision.name );
    this.revNameExists();
  }

  createNewRevision() {
    this.revManagerUtil.createNewRevision( this.revNameFormControl, ( err, newRev: Revision ) => {
      if ( err ) {
        console.error( err.err );
        this.snackBar.open( `Error saving revision. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
        this.revManagerUtil.saveButtonOptions.active = false;
      } else {
        this._state.notifyDataChanged( EVENT_NAMES.REVISION_CREATED );
        this.closeManageRevsModal();
      }
    } );
  }

  updateRevision() {
    this.revManagerUtil.updateRevision( ( err ) => {
      if ( err ) {
        this.snackBar.open( `Error saving revision. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
        this.revManagerUtil.saveButtonOptions.active = false;
      } else {
        this.closeManageRevsModal();
      }
    } );

  }

  loadSummary( revision: Revision ) {
    this.revManagerUtil.summaryOnly( revision );
    this.closeManageRevsModal();
  }

  loadRevision( revision: Revision ) {
    this._spinnerService.show( this.spinnerSelector, this.spinnerOptions );
    setTimeout( () => {
      this.revManagerUtil.loadRevision( revision );
      setTimeout( () => {
        this._spinnerService.hide( 0, this.spinnerSelector );
        this.closeManageRevsModal();
      } );
    } );


  }


}
