<!-- Headers-->
<div class="form-group">
  <div class="flexing">
    <fa-icon [icon]="faPiggyBank" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
    <h3 class="manual-account-form-H3" style="padding-bottom: 25px;">Add the details about your annuity</h3>

  </div>

</div>


<div *ngIf="form">
  <div class="form-group">
  <span *ngIf="showAnnuityWarning">
    <b>Warning:</b> If this is an existing annuity, it should be added as a permanent manual account (not here in
                  revision mode). This form is for a potential new annuity. The cash equal to the purchase price must
    be available in a bank account or as non-allocated funds in a brokerage account. If not, move the required cash
    into a single account through asset sales and/or cash transfer(s) before completing this form.
  </span>
  </div>
  <!--  <span *ngIf="showPensionWarning()">

    </span>-->
  <div class="form-group">
    <div>
      Fields with an * are required
    </div>
  </div>

  <form [formGroup]="form" #formElement>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="name"
                 [errorStateMatcher]="matcher"
                 placeholder="Account Name / Description*">
        </label>
        <mat-error *ngIf="form.controls.name.hasError('required')">
          Name is required
        </mat-error>
        <mat-label>Account Name / Description*</mat-label>
      </mat-form-field>
    </div>
    <h3 class="manual-account-form-H3">Is it an annuity, pension, or social security?</h3>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Type"
                    formControlName="annuity_type"
                    [errorStateMatcher]="matcher"
                    (selectionChange)="typeChanged()">
          <mat-option>Select an Annuity Type</mat-option>
          <mat-option value="annuity">Annuity</mat-option>
          <mat-option value="pension">Pension</mat-option>
          <mat-option value="social_security">Social Security</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="payment"
                 [errorStateMatcher]="matcher"
                 name="payment"
                 placeholder="Payment ($)*"
                 (change)="publicUtil.updateInputCurrencyFormat( 'payment', form);paymentChanged()">
        </label>
        <mat-error *ngIf="form.controls.payment.hasError('required')">
          Payment is required
        </mat-error>
        <mat-label>Payment ($)*</mat-label>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Payment Frequency*"
                    formControlName="payment_frequency"
                    [errorStateMatcher]="matcher"
                    (selectionChange)="paymentFrequencyChanged()">
          <mat-option *ngFor="let option of paymentFrequencySelectOptions"
                      value="{{option.value}}">{{option.label}}</mat-option>
        </mat-select>
        <mat-label>Payment Frequency*</mat-label>
      </mat-form-field>
    </div>
    <div class="horizontal-form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="birth_date"
                 [errorStateMatcher]="matcher"
                 name="birth_date"
                 placeholder="Birth Date (MM/DD/YYYY)*"
                 [matDatepicker]="birthDatePicker"
                 (change)="birthDateChanged()">
        </label>
        <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #birthDatePicker (closed)="birthDateChanged()"></mat-datepicker>
        <mat-error *ngIf="form.controls.birth_date.hasError('invalidDate')">
          Birth Date must be in 'MM/DD/YYYY' format
        </mat-error>
        <mat-error *ngIf="form.controls.birth_date.hasError('required')">
          Birth Date is required
        </mat-error>
        <mat-label>Birth Date</mat-label>
      </mat-form-field>
      <mat-radio-group aria-label="Select an option" formControlName="gender" (change)="birthDateChanged()">
        <mat-radio-button value="m">Male</mat-radio-button>
        <mat-radio-button value="f">Female</mat-radio-button>
      </mat-radio-group>
      <fa-icon [icon]="faQuestionCircle"
               matTooltip="Select the gender assigned at birth, purely for actuarial calculations"
               matTooltipClass="mat-tooltip-custom"></fa-icon>
    </div>

    <div class="horizontal-form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="joint_birth_date"
                 [errorStateMatcher]="matcher"
                 name="joint_birth_date"
                 placeholder="Joint Birth Date (MM/DD/YYYY) (If Applicable)"
                 [matDatepicker]="jointBirthDatePicker"
                 (change)="jointBirthDateChanged()">
        </label>
        <mat-datepicker-toggle matSuffix [for]="jointBirthDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #jointBirthDatePicker (closed)="jointBirthDateChanged()"></mat-datepicker>
        <mat-error *ngIf="form.controls.joint_birth_date.hasError('invalidDate')">
          Joint Birth Date must be in 'MM/DD/YYYY' format
        </mat-error>
        <mat-label>Joint Birth Date</mat-label>
      </mat-form-field>
      <mat-radio-group aria-label="Select an option" formControlName="joint_gender"
                       (change)="jointBirthDateChanged()">
        <mat-radio-button value="m">Male</mat-radio-button>
        <mat-radio-button value="f">Female</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="horizontal-form-group">
      <mat-form-field>
        <label>
          <input matInput
                 formControlName="expected_remaining_life"
                 [errorStateMatcher]="matcher"
                 name="expected_remaining_life"
                 placeholder="Expected Remaining Life (years)*"
                 (change)="remainingLifeChanged()">
        </label>
        <mat-error *ngIf="form.controls.expected_remaining_life.hasError('required')">
          Expected Remaining Life is required
        </mat-error>
      </mat-form-field>
      <span class="disclaimer form-disclaimer" (click)="openGlossary( 6 )">
        <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
      </span>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="treasury_rate"
                 [errorStateMatcher]="matcher"
                 name="treasury_rate"
                 (change)="treasuryRateChanged()">
        </label>
        <mat-error *ngIf="form.controls.treasury_rate.hasError('required')">
          Duration-Matched Treasury Rate is required
        </mat-error>
        <mat-label>Duration (Interest Rate Risk)-Matched Treasury Rate (Annual %)</mat-label>
      </mat-form-field>
    </div>
    <!--<div class="form-group">
      <mat-form-field>
        <label>
          <input matInput
                 formControlName="price"
                 [errorStateMatcher]="matcher"
                 placeholder="Market Price*"
                 (change)="onPriceChange()">
        </label>
        <mat-error *ngIf="form.controls.price.hasError('required')">
          Price is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <label>
          <input matInput
                 formControlName="quantity"
                 [errorStateMatcher]="matcher"
                 placeholder="Quantity*"
                 (change)="onQuantityChange()">
        </label>
        <mat-error *ngIf="form.controls.quantity.hasError('required')">
          Quantity is required
        </mat-error>
      </mat-form-field>
    </div>-->
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="value"
                 [errorStateMatcher]="matcher"
                 (change)="publicUtil.updateInputCurrencyFormat('value', form)">
        </label>
        <mat-error *ngIf="form.controls.value.hasError('required')">
          Market Value is required
        </mat-error>
        <mat-label>Market Value ($)</mat-label>
      </mat-form-field>
    </div>
    <!-- SOURCE ACCOUNT AND PURCHASE PRICE FOR ANNUITIES ADDED IN A REVISION -->
    <div class="form-group" *ngIf="showSourceControl">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="cost_basis"
                 [errorStateMatcher]="matcher"
                 placeholder="Purchase Price (Cost Basis)*"
                 (change)="publicUtil.updateInputCurrencyFormat('cost_basis', form); this.filterAccounts();">
        </label>
        <mat-error *ngIf="form.controls.value.hasError('required')">
          Purchase Price/Cost Basis is required
        </mat-error>
        <mat-label>Purchase Price (Cost Basis)*</mat-label>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="showSourceControl">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Source Account"
                    panelClass="max-select-box-height"
                    [formControl]="sourceAccountFormControl"
                    [errorStateMatcher]="matcher"
                    (selectionChange)="checkSourceAmount()">
          <mat-option>Select Account</mat-option>
          <mat-option *ngFor="let acct of sourceAccounts" value="{{ acct.account_id }}"
                      title="{{ acct.formattedDescription }}">
            {{ acct.formattedDescription }} ( {{ acct.availableBalance | ripCurrencyPipe }} )
          </mat-option>
        </mat-select>
        <mat-error *ngIf="sourceAccountFormControl?.hasError('required')">
          Source Account is required
        </mat-error>
        <mat-error *ngIf="sourceAccountFormControl?.hasError('availableBalanceValidator')">
          Source Account does not have enough available balance to purchase this annuity
        </mat-error>
      </mat-form-field>
    </div>
    <rip-bond-select-boxes [matcher]="matcher" [form]="form">
    </rip-bond-select-boxes>

  </form>
</div>
