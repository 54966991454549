import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { Util } from './util.service';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawLongPercentPipe, RipsawPercentPipe, } from '../theme/pipes';
import moment from 'moment';
import { GlobalState } from '../global.state';
import { OverrideGroupSetItem, OverrideKeySetItem, } from './dataInterfaces';
import { UntypedFormGroup } from '@angular/forms';
import { Account, FormsUtil, Position } from '@ripsawllc/ripsaw-analyzer';

@Injectable()
export class OverrideHelpers {

  static leftSideColumns = [
    {
      name: 'Ticker',
      prop: 'ticker',
    },
    {
      name: 'Description',
      prop: 'ticker_name',
    },
    {
      name: 'Type',
      longName: 'Security Type',
      prop: 'security_type',
    },
    {
      name: 'Price',
      prop: 'price',
      pipe: new RipsawCurrencyPipe(),
    },
    {
      name: 'Quantity',
      longName: 'Number of Shares',
      prop: 'quantity',
      pipe: new RipsawDecimalPipe(),
    },
    {
      name: 'Value',
      longName: 'Market Value',
      prop: 'value',
      pipe: new RipsawCurrencyPipe(),
    },
    {
      name: 'Allocation',
      prop: 'allocation',
      pipe: new RipsawLongPercentPipe(),
    },
  ];

  /* list of columns to ignore when adding form controls to the form group */
  static ignoreColumn( key: OverrideKeySetItem ) {
    if ( [ 'expenses' ].includes( key.key ) ) {
      return true;
    }
  }

  /**
   * Function to build a set of keys that will go into the form
   * ignoreFunc: Function,
   * position: Position,
   * positionOverrides: any,
   * accountCategory: string,
   * state: GlobalState,
   * account: Account
   * */
  static buildGlobalKeySets( {
                               ignoreFunc,
                               position,
                               positionOverrides,
                               state,
                               account = null,
                               isManual = false,
                             } ): OverrideGroupSetItem[] {

    if ( !ignoreFunc ) {
      ignoreFunc = this.ignoreColumn;
    }

    const keySets = [];

    const descriptiveInfoSet = [
      'Cash, Bonds, Stocks',
      'Yields and Expenses',
    ];

    const bondDetailsSet = [
      'Bond Global Distribution',
      'Credit Quality Distribution',
      'Maturity Range Distribution',
      'Bond Sector Distribution',
    ];
    const stockDetailsSet = [
      'Stock Global Distribution',
      'Capitalization Distribution',
      'Value, Blend, Growth',
      'Stock Sector Distribution',
    ];

    const descKeys = [
      'ticker',
      'ticker_name',
      'security_type',
      'security_style',
      'morningstar_category',
      'price',
      'quantity',
      'value',

    ];

    if ( !isManual ) {
      _.remove( descKeys, ( k: string ) => {
        return [ 'price', 'quantity', 'value' ].includes( k );
      } );
    }

    const descriptiveKeySet: OverrideGroupSetItem = {
      label: 'Descriptive Information',
      keys: descKeys.map( ( k: string ) => {
        return this.createKeyObject( k, position, positionOverrides, state, false, false );
      } ),
      groups: [],
    };

    const extraDescriptiveGroups = [
      {
        label: 'Real Assets',
        columns: [
          'real_assets',
          // 'other',
          'underlying_asset_type',
        ],
        footnote: 'Underlying Asset Type field is to designate when the underlying securities are real assets',
      },
    ];

    this.populateGroupSet( descriptiveInfoSet, descriptiveKeySet, state, position, positionOverrides, extraDescriptiveGroups );

    if ( isManual ) {
      // need cost_basis for manual investment accounts
      descriptiveKeySet?.keys?.push( this.createKeyObject( 'cost_basis', position, positionOverrides, state, false, false ) );
    }

    const bondKeySet = {
      label: 'Bond Details',
      keys: [ this.createKeyObject( 'maturity_date', position, positionOverrides, state, false, false ) ],
      groups: [],
    };

    this.populateGroupSet( bondDetailsSet, bondKeySet, state, position, positionOverrides );

    const stocksKeySet = {
      label: 'Stock Details',
      keys: [],
      groups: [],
    };

    this.populateGroupSet( stockDetailsSet, stocksKeySet, state, position, positionOverrides );


    /*_.remove( bondDetailsSet.keys, ( k: any ) => {
     return ignoreFunc( k, grid );
     } );
     _.remove( stockDetailsSet.keys, ( k: any ) => {
     return ignoreFunc( k, grid );
     } );*/

    keySets.push( descriptiveKeySet );
    keySets.push( bondKeySet );
    keySets.push( stocksKeySet );

    for ( const keySet of keySets ) {
      this.removeIgnoredColumnsFromKeySet( keySet, ignoreFunc, account );
    }

    return keySets;
  }

  static buildLocalKeySet( position: Position, positionOverrides: any, account: Account, state: GlobalState ) {
    const keySet = {
      label: `Position Fields (Applies to ${ position.overridden_ticker ?? position.ticker } in account ${ Util.formatAccountDescription( account ) })`,
      keys: [
        'price',
        'quantity',
        'value',
      ].map( ( k: string ) => {
        return this.createKeyObject( k, position, positionOverrides, state, false, true );
      } ),
      groups: [],
    };

    const accountCategory = account.account_category;

    if ( accountCategory ) {
      switch ( accountCategory.toUpperCase() ) {
        case 'LOAN':
          const loanFieldsMissing: boolean = position?.missing_data && position?.missing_data.includes( 'Loan Fields' );
          const loanGroup = {
            label: 'Loan Fields',
            keys: [ 'original_loan_amount', 'loan_origination_date', 'loan_term', 'coupon', 'current_market_rate' ]
              .map( ( k ) => {
                return this.createKeyObject( k, position, positionOverrides, state, true, false );
              } ),
            groupHasMissingData: loanFieldsMissing,
          };
          keySet?.groups?.push( loanGroup );
          break;
        case 'INVESTMENT':
          // add investment fields if necessary
          break;
        case 'BANKING':
          //  add banking fields if necessary
          break;
        case 'OTHER':
          // add other fields if necessary
          break;
        case 'INSURANCE':
          // add insurance fields if necessary
          break;
        case 'UNKNOWN':
          // add unknown fields if necessary
          break;
      }
    }

    return keySet;
  }

  static populateGroupSet( detailsSet: any, keySet: any, state: GlobalState, position: Position, positionOverrides: any, extraGroups?: any[] ) {
    for ( const g of detailsSet ) {
      const group = _.find( state.columnGroupings, ( cg: any ) => {
        return cg.label === g;
      } );

      keySet.groups.push(
        {
          label: g,
          keys: _.clone( group?.columns ).map( ( k ) => {
            return this.createKeyObject( k, position, positionOverrides, state, false, false );
          } ),
          groupHasMissingData: this.groupHasMissingData( group, position ),
          group,
        } );
    }
    if ( extraGroups ) {
      // these need to be passed in as fully formed column groups
      for ( const g of extraGroups ) {
        keySet.groups.push(
          {
            label: g.label,
            keys: _.clone( g?.columns ).map( ( k ) => {
              return this.createKeyObject( k, position, positionOverrides, state, false, false );
            } ),
            groupHasMissingData: this.groupHasMissingData( g, position ),
            group: g,
          },
        );
      }
    }
  }

  static createKeyObject( key: string, position: Position, positionOverrides: any, state: GlobalState,
                          isLoanField: boolean, isLocalOverride: boolean ): OverrideKeySetItem {
    const keySetItem: OverrideKeySetItem = {
      fieldHasMissingData: this.fieldHasMissingData( key, position ),
      fieldHasOverride: this.doesKeyObjectHaveOverride( key, position, positionOverrides ),
      formattedOldOverrideValue: this.getFormattedOldOverrideValue( key, position, positionOverrides ),
      isLoanField,
      isLocalOverride,
      isLocalProxyOverride: isLocalOverride && this.doesKeyObjectHaveProxyOverride( key, position ),
      inputType: this.determineInputType( key ),
      key,
      colDef: state?.allColumnsObject[ key ] ?
        state?.allColumnsObject[ key ]?.account :
        OverrideHelpers.leftSideColumns.find( ( c: any ) => {
          return c.prop === key;
        } ),
    };
    if ( keySetItem.inputType === 'select' ) {
      keySetItem.selectOptions = this.getSelectOptions( key );
    }
    return keySetItem;
  }

  static doesKeyObjectHaveOverride( key: string, position: Position, positionOverrides: any ): boolean {
    if ( key === 'price' ) {
      return position.overridden_price || positionOverrides.price;
    } else if ( key === 'quantity' ) {
      return position.overridden_quantity || positionOverrides.quantity;
    } else {
      return positionOverrides && positionOverrides[ key ];
    }
  }

  static doesKeyObjectHaveProxyOverride( key: string, position: Position ): boolean {
    if ( key === 'price' ) {
      return position.overridden_price !== undefined;
    }

    if ( key === 'quantity' ) {
      return position.overridden_quantity !== undefined;
    }
  }

  static getFormattedOldOverrideValue( key: string, position: Position, positionOverrides: any ): string {
    if ( key === 'price' && position.overridden_price !== undefined ) {
      return this.formatOldOverrideValue( key, position.overridden_price );
    }

    if ( key === 'quantity' && position.overridden_quantity !== undefined ) {
      return this.formatOldOverrideValue( key, position.overridden_quantity );
    }

    return positionOverrides?.[ key ] ? this.formatOldOverrideValue( key, positionOverrides[ key ].old ) : '';
  }

  static removeIgnoredColumnsFromKeySet( keySet: any, ignoreFunc: Function, account: Account ) {
    for ( const group of keySet.groups ) {
      this.removeIgnoredColumnsFromKeys( group.keys, ignoreFunc, account );
    }
    this.removeIgnoredColumnsFromKeys( keySet.keys, ignoreFunc, account );
  }

  static removeIgnoredColumnsFromKeys( keys: any, ignoreFunc: Function, account: Account ) {
    _.remove( keys, ( k: any ) => {
      return ignoreFunc( k, account );
    } );
  }

  static clearOverrideInfoFromKeySet( keyToClear: string, keySet: OverrideGroupSetItem, position: Position ) {
    keySet.groupHasMissingData = this.groupHasMissingData( keySet.group, position );
    for ( const key of keySet.keys || [] ) {
      if ( key.key === keyToClear ) {
        key.fieldHasOverride = false;
        key.formattedOldOverrideValue = '';
        key.fieldHasMissingData = this.fieldHasMissingData( keyToClear, position );
        return;
      }
    }
    for ( const group of keySet.groups || [] ) {
      this.clearOverrideInfoFromKeySet( keyToClear, group, position );
    }
    return;
  }

  /*
   * Function for sanitizing a value that may have been formatted
   * @param value {object} - value to be sanitized. can be a string or number
   * @param key {String} - the key for the field the value came from. used to determine if the value was a formatted
   * percentage that needs to be divided by 100 before being stored
   * */
  static sanitize( value: any, key: string, returnFloat?: boolean ) {
    if ( this.shouldBeCurrency( key ) || this.shouldBeDecimal( key ) || this.shouldBePercent( key ) ) {
      value = FormsUtil.getSanitizedFloatValue( value );
      if ( isNaN( value ) ) {
        return null;
      }
      if ( returnFloat ) {
        value = parseFloat( value );
      }
      if ( this.shouldBePercent( key ) ) {
        value = value / 100;
      }
      return value;
    }
    return value;
  }

  static formatValue( value, key ) {

    if ( value === undefined || value === null ) {
      return value;
    }

    if ( this.shouldBePercent( key ) ) {
      value = FormsUtil.getSanitizedFloatValue( value );
      const ripPercentPipe = new RipsawPercentPipe();
      return ripPercentPipe.transform( value, '2-3' );
    }
    if ( this.shouldBeCurrency( key ) ) {
      value = FormsUtil.getSanitizedFloatValue( value );
      const ripCurrencyPipe = new RipsawCurrencyPipe();
      return ripCurrencyPipe.transform( value );
    }
    if ( this.shouldBeDecimal( key ) ) {
      value = FormsUtil.getSanitizedFloatValue( value );
      const ripDecimalPipe = new RipsawDecimalPipe();
      return ripDecimalPipe.transform( value, '2-2' );
    }
    if ( this.shouldBeDate( key ) ) {
      return moment( value );
    }
    return value;
  }

  /*
   * Function for formatting an override's old value
   * @param key {String} - the field whose old override value needs to be formatted
   * */
  static formatOldOverrideValue( key: string, oldValue: any ) {
    let value = oldValue;
    if ( this.shouldBePercent( key ) ) {
      value = new RipsawPercentPipe().transform( value, '2-2' );
    }
    if ( this.shouldBeDecimal( key ) ) {
      value = new RipsawDecimalPipe().transform( value, '2-2' );
    }
    if ( this.shouldBeCurrency( key ) ) {
      value = new RipsawCurrencyPipe().transform( value );
    }
    return value;
  }


  static groupHasMissingData( group: any, position: Position ) {
    return position?.missing_data?.includes( group?.label );
  }

  static fieldHasMissingData( field: string, position: Position ) {
    return position?.missing_data?.includes( field );
  }

  /*
   * lists of fields/columns that can be overridden and which type of input and formatting they need
   * */
  static selects = [ 'security_type', 'underlying_asset_type' ];
  static dates = [
    'maturity_date',
    'loan_origination_date',
  ];
  static strings = [ 'ticker', 'ticker_name', 'morningstar_category', 'security_style' ];
  static percents = [
    'allocation',
    'real_assets',
    // 'other',
    'cash',
    'bonds',
    'stocks',
    'large_cap',
    'mid_cap',
    'small_cap',
    'value_stocks',
    'blend_stocks',
    'growth_stocks',
    'aaa',
    'aa',
    'a',
    'bbb',
    'bb',
    'b',
    'below_b',
    'not_rated',
    'us',
    'non_us',
    'bonds_us',
    'bonds_non_us',
    'emerging_markets',
    'bonds_region_emerging_markets',
    'less_than_one_year',
    'one_to_three_years',
    'three_to_five_years',
    'five_to_seven_years',
    'seven_to_ten_years',
    'ten_to_fifteen_years',
    'fifteen_to_twenty_years',
    'twenty_to_thirty_years',
    'over_thirty_years',
    'sector_basic_materials',
    'sector_communication_services',
    'sector_consumer_cyclical',
    'sector_consumer_defensive',
    'sector_energy',
    'sector_financial_services',
    'sector_healthcare',
    'sector_industrials',
    'sector_real_estate',
    'sector_technology',
    'sector_utilities',
    'bond_primary_sector_agency_mbs',
    'bond_primary_sector_abs',
    'bond_primary_sector_bank_loan',
    'bond_primary_sector_commercial_mbs',
    'bond_primary_sector_convertibles',
    'bond_primary_sector_corporate_bond',
    'bond_primary_sector_covered_bond',
    'bond_primary_sector_future_forward',
    'bond_primary_sector_government',
    'bond_primary_sector_government_related',
    'bond_primary_sector_non_agency_residential_mbs',
    'bond_primary_sector_preferred',
    'bond_primary_sector_us_municipal_tax_advantaged',
    'annualized_yield',
    'current_market_rate',
    'coupon',
    'expense_ratio',
  ];
  static currencies = [
    'price',
    'value',
    'original_loan_amount',
    'cost_basis',
  ];
  static decimals = [
    'quantity',
    'loan_term',
    // 'effective_duration',
  ];
  static loanFields = [
    'loan_term',
    'original_loan_amount',
    'loan_origination_date',
  ];

  /*
   * functions that check if the given key should be of the type the function tests for. used in the template for *ngIf
   * expressions for each input type
   * @param key {String} - the key of the field that is being checked
   * @returns a boolean
   * */
  static shouldBeString( key: string ) {
    return this.strings.includes( key );
  }

  static shouldBePercent( key: string ) {
    return this.percents.includes( key );
  }

  static shouldBeCurrency( key: string ) {
    return this.currencies.includes( key );
  }

  static shouldBeDecimal( key: string ) {
    return this.decimals.includes( key );
  }

  static shouldBeDate( key: string ) {
    return this.dates.includes( key );
  }

  static shouldBeSelect( key: string ) {
    return this.selects.includes( key );
  }

  static getSelectOptions( key: string ) {
    return this[ `${ key }_list` ];
  }

  static isLoanField( key: string ) {
    return this.loanFields.includes( key );
  }

  static determineInputType( key: string ): string {
    if ( this.shouldBeString( key ) ) {
      return 'string';
    }
    if ( this.shouldBePercent( key ) ) {
      return 'percent';
    }
    if ( this.shouldBeCurrency( key ) ) {
      return 'currency';
    }
    if ( this.shouldBeDecimal( key ) ) {
      return 'decimal';
    }
    if ( this.shouldBeDate( key ) ) {
      return 'date';
    }
    if ( this.shouldBeSelect( key ) ) {
      return 'select';
    }

    return 'unknown';
  }

  /*
   * list of choices for the security_type select box
   * */
  static security_type_list: string[] = [
    'Alternative',
    'Annuity Fund',
    'Bond',
    'Cash',
    'Certificate of Deposit',
    'Closed-End Fund',
    'Commodity',
    'Currency',
    'Derivative',
    'Employee Stock Option',
    'ETF',
    'ETN',
    'Equity',
    'Foreign Equity',
    'Future',
    'Hedge Fund',
    'Index',
    'Insurance Annuity',
    'Insurance Policy',
    'Loan',
    'Loan Receivable',
    'Money Market',
    'Mutual Fund',
    'Option',
    'Other',
    'Other Equity',
    'Preferred Stock',
    'Real Estate',
    'REMIC',
    'Unit Investment Trust',
    'Unknown',
    'Warrants',
  ];

  static underlying_asset_type_list: string[] = [
    '',
    'Real Estate',
    'Commodities',
    'Crypto Currency',
    'Other Alternatives',
  ];
  /*
   * "CD", // *
   "commodity", // *
   "employeeStockOption", // *
   "future", // *
   "insuranceAnnuity", // *
   "preferredStock",
   "remic", // *
   "unitInvestmentTrust", // *
   "warrants" // *
   * */

  // STATIC helpers
  static doesFieldHaveOverride( key: string, localOverrides: any, globalOverrides: any, origPosition: Position ) {
    if ( OverrideHelpers.isFieldLocal( key, origPosition ) ) {
      return localOverrides && localOverrides[ key ];
    } else {
      return !!globalOverrides[ key ];
    }
  }


  static getOverrideByNewTicker( ticker, overrides ) {
    for ( const key of Object.keys( overrides ) ) {
      if ( overrides[ key ]?.ticker?.new === ticker ) {
        return overrides[ key ];
      }
    }
  }

  /**
   *
   * @param id - position id
   * @param overrides - overrides map for the account
   */
  static getOverridesByPositionId( id, overrides ) {
    return overrides[ id ];
  }

  /**
   *
   * @param state - overrides map for the account
   * @param origPosition - position that may have overrides
   * @param account - Account - account containing the position being overriden
   */
  static getLocalPositionOverrides( state: GlobalState, origPosition: Position, account?: Account ) {
    if ( account ) {
      const mapping = state.globalVars.accountMapping.mapping;
      const accountOverrides = mapping[ account?.connection_id ]?.[ account?.account_id ]?.overrides;

      if ( accountOverrides ) {
        // eventually we can remove the first two expressions here
        return accountOverrides[ origPosition.ticker ]
          || OverrideHelpers.getOverrideByNewTicker( origPosition.ticker, accountOverrides )
          || OverrideHelpers.getOverridesByPositionId( origPosition.id, accountOverrides ) || {};
      } else {
        // no overrides yet, so return an empty object
        return {};
      }
    } else {
      // this must be a global, so we want a null for local fields
      return null;
    }
  }

  static makeNewTempGlobalOverride( origPosition: Position ) {
    const origTicker = origPosition.ticker;
    const origTickerName = origPosition.ticker_name;
    const origSecurityType = origPosition.security_type;
    return {
      original_ticker: origTicker,
      original_ticker_name: origTickerName,
      original_security_type: origSecurityType,
      overrides: {},
    };
  }

  static getGlobalOverride( origPosition: Position, state: GlobalState ) {
    if ( origPosition.global_override_id ) {
      return state.globalVars.globalOverrides[ origPosition.global_override_id ];
    }
  }

  static isFieldLocal( key: string, origPosition: Position ) {
    return [ 'price', 'quantity', 'value' ].includes( key ) || [ 'CUR:USD' ].includes( origPosition.ticker );
  }

  static setWithOldValues( p: Position, overrides: any ) {
    for ( const key of Object.keys( p ) ) {
      if ( overrides[ key ] ) {
        p[ key ] = overrides[ key ].old;
      }
    }
    delete p.overridden_ticker;
  }

  static onSelectionChange( selectBox: any, key: string, form: UntypedFormGroup, origPosition: Position ) {
    if ( key === 'underlying_asset_type' ) {
      const ripPercentPipe = new RipsawPercentPipe();
      if ( selectBox?.value === '' ) {
        form.controls.underlying_asset_type.setValue( null );
        // reset everything using origPosition
        form.controls.cash.setValue( ripPercentPipe.transform( origPosition.cash, '2-2' ) );
        form.controls.stocks.setValue( ripPercentPipe.transform( origPosition.stocks, '2-2' ) );
        form.controls.bonds.setValue( ripPercentPipe.transform( origPosition.bonds, '2-2' ) );
        form.controls.real_assets.setValue( ripPercentPipe.transform( origPosition.real_assets, '2-2' ) );
        // form.controls.other.setValue( ripPercentPipe.transform( origPosition.other, '2-2' ) );
      } else {

        if ( selectBox?.value.toLowerCase() === 'other alternatives' ) {
          // alternatives is all Other, but we aren't doing other right now
          // form.controls.real_assets.setValue( ripPercentPipe.transform( 0, '2-2' ) );
          // form.controls.other.setValue( ripPercentPipe.transform( 1, '2-2' ) );
        } else {
          // the other values are all Real Assets
          form.controls.real_assets.setValue( ripPercentPipe.transform( 1, '2-2' ) );
          // form.controls.other.setValue( ripPercentPipe.transform( 0, '2-2' ) );
          // any choice here is 0 cash, bonds, stocks
          form.controls.cash.setValue( ripPercentPipe.transform( 0, '2-2' ) );
          form.controls.stocks.setValue( ripPercentPipe.transform( 0, '2-2' ) );
          form.controls.bonds.setValue( ripPercentPipe.transform( 0, '2-2' ) );
        }

      }
    }
  }

  static checkForKeySetsForChanges( keySets: OverrideGroupSetItem[], rawValues, overrides, position: Position ) {
    for ( const set of keySets ) {
      const changeInMainKeys = OverrideHelpers.checkKeysForChanges( set.keys, rawValues, overrides, position );
      if ( changeInMainKeys ) {
        return true;
      }
      for ( const group of set.groups ) {
        const changeInGroupKey = OverrideHelpers.checkKeysForChanges( group.keys, rawValues, overrides, position );
        if ( changeInGroupKey ) {
          return true;
        }
      }
    }
    return false;
  }

  static checkKeysForChanges( keys: OverrideKeySetItem[], rawValues, overrides, position: Position ): boolean {
    for ( const key of keys ) {
      const col = key.key;
      // remove any formatting from the raw value
      const sanitizedValue = rawValues[ col ] === '' || rawValues[ col ] === '-' || rawValues[ col ] === null ?
        '' : OverrideHelpers.sanitize( rawValues[ col ], col, true );

      // if there is already an override for this field, check to see if the new value has been changed. also set the
      // value in the position
      if ( overrides[ col ] ) {
        if ( overrides[ col ].new !== sanitizedValue ) {
          return true;
        }
      } else {
        // if there wasn't an override for this field, create one and set both new and old values. also set the value
        // in the position
        // sanitize is changing null to 0, so the comparison to a 0 that is entered is stopping an override of "0" from being applied
        if ( sanitizedValue !== '' && OverrideHelpers.getCurrentValueSanitized( position[ col ], col ) !== sanitizedValue ) {
          return true;
        }
      }
    }
    return false;
  }

  static getCurrentValueSanitized( value, key: string ) {
    if ( value === null || value === undefined ) {
      return '';
    } else {
      return OverrideHelpers.sanitize( value, key, true );
    }
  }
}
