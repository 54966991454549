<nb-card>
    <!--<nb-card-header>
        Expected Return VS Interest Rate Risk and Volatility
    </nb-card-header>-->
    <nb-card-body style="overflow: hidden;">
        <div class="flexing" style="overflow: hidden;">
            <div style="flex:1; overflow: hidden;">
                <rip-risk-return-scatter-plot [datasets]="expRetVsEffDurScatterDatasetToShow.datasets"
                                              [labels]="expRetVsEffDurScatterDatasetToShow.labels"
                                              [xAxisLabel]="'Effective Duration'"
                                              [chartTitle]="'Expected Return vs Interest Rate Sensitivity (Effective Duration)'">

                </rip-risk-return-scatter-plot>
            </div>
            <div style="flex:1; overflow: hidden;">
                <rip-risk-return-scatter-plot [datasets]="expRetVsSDScatterDataToShow.datasets"
                                              [labels]="expRetVsSDScatterDataToShow.labels"
                                              [chartTitle]="'Expected Return vs Total Risk (Volatility)'">

                </rip-risk-return-scatter-plot>
            </div>
        </div>
        <div class="market-info-blurb">
            <a *ngIf="deviceIsMobile && !bondsMainBlurbMobileReadMore"
               (click)="bondsMainBlurbMobileReadMore = true;"
               class="rip-link">
                Learn More
            </a>
            <ul *ngIf="!deviceIsMobile || bondsMainBlurbMobileReadMore">
                <li>
                    <div>
                        <b>Expected Return</b> on each sector of the bond market is the sum of its duration-matched
                        Treasury
                        yield (compensation for interest rate risk) plus the {{ appName }} model of expected risk premium
                        (compensation) for prepayment and/or default risk.
                    </div>
                    <!-- img goes here -->
                    <div>
                        <img src="assets/img/app/market-info/bonds-expected-return-equation.svg"
                             alt="Expected Return Equation"
                             class="short-equation">
                    </div>
                    <!-- -->
                </li>
                <li>
                    <div>
                        <b>Effective Duration</b> is an approximation of interest rate risk.That is the return
                        sensitivity to a change in the level of interest rates (proxied by changes in the 10-year
                        Treasury yield). Government funds only have interest rate risk. Agency Mortgage-Backed
                        Securities (MBS) also have prepayment risk. Corporate bonds also have default risk. The US IG
                        (Investment Grade) fund contains Treasury, MBS and Corporate bonds with all three risk factors:
                        interest rate, prepayment and default.
                    </div>
                </li>
                <li>
                    <div>
                        <b>Total Volatility</b> contains all risk dimensions in a sector fund.
                    </div>
                </li>
            </ul>
            <a *ngIf="deviceIsMobile && bondsMainBlurbMobileReadMore"
               (click)="bondsMainBlurbMobileReadMore = false;"
               class="rip-link">
                Read Less
            </a>
        </div>
    </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="loading" style="padding-bottom: 120px;">
    <nb-card-header style="position: relative;">
        <nb-badge *ngIf="riskReturnError" text="Error Loading Data. Click here to try again"
                  position="top left" (click)="getRiskReturns()"
                  class="button-badge"
                  status="danger">
        </nb-badge>
        <div class="flexing space-between">
            <div class="market-info-card-title">
                Forward-Looking Expected Return and Risk
            </div>
            <div>
                <rip-term-radio (termChanged)="changeTerm($event)" [term]="term">

                </rip-term-radio>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body class="forward-looking-table">

        <ngx-datatable #forwardLookingTable
                       [columns]="forwardLookingTableColumns"
                       [rows]="riskReturnsValues"
                       [columnMode]="ColumnMode.force"
                       [headerHeight]="45"
                       [rowHeight]="40"
                       [cssClasses]="cssClasses"
                       [messages]="messages"
                       [reorderable]="false"
                       [style.height]="'100%'"
                       [style.width]="'100%'"
                       [scrollbarH]="deviceIsMobile"
                       class="material striped"></ngx-datatable>

        <div class="market-info-blurb">
            <a *ngIf="deviceIsMobile && !forwardLookingTableReadMore"
               (click)="forwardLookingTableReadMore = true;"
               class="rip-link">
                Learn More
            </a>
            <div *ngIf="!deviceIsMobile || forwardLookingTableReadMore">
                <div>
                    <b>Expected Sharpe Ratio</b> provides a measure of expected risk-adjusted performance. It is the
                    expected return for a fund less that of the 1 Month Treasury Yield per unit of its Total Volatility
                    (Risk).
                </div>
                <div>
                    <img src="assets/img/app/market-info/bonds-expected-sharpe-ratio-equation.svg"
                         alt="Expected Sharpe Ratio Equation"
                         class="equation">
                </div>
            </div>
            <a *ngIf="deviceIsMobile && forwardLookingTableReadMore"
               (click)="forwardLookingTableReadMore = false;"
               class="rip-link">
                Read Less
            </a>
        </div>
    </nb-card-body>
    <nb-card-footer class="hide-on-mobile">
        <nb-card-header>
            <div class="flexing space-between">
                <div class="market-info-card-title">
                    Bond Sector Return Correlation Matrix
                </div>
                <div>
                    <rip-term-radio (termChanged)="changeTerm($event)" [term]="term">

                    </rip-term-radio>
                </div>
            </div>
        </nb-card-header>
        <rip-correlation-matrix [riskReturnsValues]="riskReturnsValues" [loading]="loading" [term]="term">

        </rip-correlation-matrix>
        <div class="market-info-blurb">
            The<b> Bond Sector Return Correlation Matrix</b> indicates all pairwise return correlations between bond
            sector
            funds. The lower the correlation, the greater the benefit from diversification.
            <a *ngIf="!correlationMatrixReadMore" (click)="correlationMatrixReadMore = true;" class="rip-link">
                Read More</a>
            <span *ngIf="correlationMatrixReadMore">
            The Government funds only
            have interest rate risk. Their correlations are high, but not perfect because we are only including interest
            rate level risk proxied by changes in the 10-year Treasury rate. Interest rate level risk is the most
            explanatory component. The remaining smaller interest rate risks (i.e., slope and curvature) are not
            included at this time. Note the lowest correlations are between the High Yield Corporate sector and the
            others. That is because it has a low interest rate level risk contribution to total volatility and a high
            default (below Investment Grade) risk contribution that is not present in the other sector funds.
            </span>
            <a *ngIf="correlationMatrixReadMore" (click)="correlationMatrixReadMore = false;" class="rip-link">
                Read Less
            </a>
        </div>
    </nb-card-footer>
    <nb-card-footer class="hide-on-mobile">
        <span class="footnote flexing-row-only proxied-footnote">
            <span *ngFor="let key of primeIdentifiersKeysArray " class="proxy-footnote-item">
                {{ primeIdentifiers[ key ].shortName }} is proxied by {{key}}
            </span>
        </span>
    </nb-card-footer>
</nb-card>

<div>
    <rip-trading-view-symbol-overview [tickers]="tvSymbols"
                                      [brandingLabel]="'Bond fund data '"
                                      #tradingViewSymbolChart
                                      [height]="400">

    </rip-trading-view-symbol-overview>
</div>
