import { NgModule } from '@angular/core';
import { routing } from './transactions.routing';
import { NgaModule } from '../../theme/nga.module';
import { TransactionsPageComponent } from './transactions-page.component';

@NgModule( {
  imports: [
    NgaModule,
    routing,
  ],
  declarations: [
    TransactionsPageComponent,
  ],
  providers: [],
  exports: [],
} )
export class TransactionsModule {
}
