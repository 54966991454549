import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { IconPrefix } from '@fortawesome/pro-regular-svg-icons';
import { IconName } from '@fortawesome/pro-light-svg-icons';

export const faOptimizer: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'optimizer' as IconName,
  icon: [
    640,
    512,
    [],
    'f030',
    [ 'M64 240a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64zm88 80h48v-32h-48zm294.4-106.8l19.2 25.6 48-36-19.2-25.6zM576 64a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64z', 'M576 384a63.84 63.84 0 0 0-38.3 13l-96-57.6a109.91 109.91 0 0 0 6.3-35.5 111.94 111.94 0 0 0-112-112 108.64 108.64 0 0 0-24.4 2.9l-40.8-87.4A63.84 63.84 0 1 0 224 128c1.1 0 2.1-.3 3.2-.3l41 87.8C241.5 235.9 224 267.8 224 304a111.71 111.71 0 0 0 193.2 76.7l95.8 57.5a63.87 63.87 0 1 0 63-54.2zm-240-32a48 48 0 1 1 48-48 48 48 0 0 1-48 48z' ],
  ],
};

export const faUmbrellaSlash: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'umbrella-slash' as IconName,
  icon: [
    640,
    512,
    [],
    'f030',
    [ 'M5.10001 9.20001C13.3 -1.19999 28.4 -3.09999 38.8 5.10001C76.9 35 115.1 64.9 153.2 94.8C200 39.8 268.4 7.10001 340.4 4.80001C333 10 326.1 16.1 319.9 23C318.6 24.5 317.2 26 315.9 27.5C281.4 66.1 253.8 110.4 234.4 158.4C243.2 165.3 251.9 172.1 260.7 179C278.9 131.1 305.6 87 339.7 48.9C341 47.4 342.4 45.9 343.7 44.4C367 18.2 403.8 8.90001 436.8 20.6C437.7 21 438.7 21.3 439.6 21.7C471.5 34.3 492.9 64.7 494 99.1C494.1 101.1 494.1 103.1 494.2 105.1C495.9 158.1 487.4 210.8 469.3 260.6C468.2 263.6 467.1 266.7 466 269.7C410.2 249.4 354.5 229.1 298.7 208.8C409.4 295.6 520.1 382.3 630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9C403.8 352.2 206.5 197.6 9.20001 42.9C-1.19999 34.7 -3.09999 19.6 5.10001 9.20001Z',
      'M32 480C32 462.3 46.3 448 64 448C124.7 448 185.3 448 246 448C260.9 407 275.9 366.1 290.8 325.1C308.4 339 326 352.9 343.7 366.8C333.8 393.9 324 421 314.1 448C358.3 448 402.5 448 446.8 448C473.9 469.3 501 490.7 528 512C373.4 512 218.7 512 64 512C46.3 512 32 497.7 32 480Z',
      'M496.1 280.7L499.4 271.6C518.9 218 528 161.2 526.2 104.2C526.1 102.2 526.1 100.2 526 98.2C525.8 89.7 524.6 81.4 522.6 73.4C579.5 126.4 609.9 203.4 603.4 281.9C601.8 301.1 581.9 311.9 563.8 305.3C541.2 297.1 518.7 288.9 496.1 280.7Z' ],
  ],
};
