import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Disclaimer } from '../disclaimers.component';
import { Util } from '../../../../utils/util.service';
import { Logger } from '../../../../utils/logger.service';

@Component( {
  selector: 'rip-disclaimer',
  template: `
    <h4 matLine>{{ disclaimer.title }}</h4>
    <p #disclaimerMsgContainer matLine [ngClass]="{'highlighted': highlight }" [innerHTML]="disclaimer.msg"></p>
  `,
} )

export class DisclaimerComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'disclaimerMsgContainer', { static: false } ) disclaimerMsgContainer: ElementRef;

  @Input() disclaimer: Disclaimer;
  @Input() highlight: boolean;

  private removeListener: () => void;

  openExternal( url: string ) {
    Util.openExternalUrl( url );
  }

  constructor( private vcRef: ViewContainerRef, private renderer: Renderer2 ) {
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      if ( this.disclaimer?.msg?.includes( '<a' ) && Util.isWebkitHandlerAvailable() ) {
        this.removeListener = this.renderer.listen( this.disclaimerMsgContainer.nativeElement, 'click', ( event: any ) => {
          Logger.log( event );
          if ( event.target && event.target.href ) {
            this.openExternal( event.target.href );
          }
        } );
      }
    } );
  }

  ngOnDestroy(): void {
    if ( this.removeListener ) {
      this.removeListener();
    }
  }
}
