import { ScreenerComponent } from '../screener/screener.component';
import { UnderweightExposure } from '../../../../accounts/components/optimizer/components/underweightExposures/underweightExposures.component';
import value from '*.json';
import { GlobalState } from '../../../../../global.state';
import { ScreenerResultsFilterOptions } from '../newInvestmentSelector/newInvestmentSelector.component';


export class InvestmentSelectorUtil {

  /**
   * This is a ridiculous logic tree and we should find a way to refactor this to be less crazy
   * @param prop - property name of exposure
   * @param exposures - all underweight exposures in current minimization
   */
  static getSectorOptions( prop: string, exposures: UnderweightExposure[] ) {
    let tierOptions;
    let tier1Index;
    let screenerSector;
    let filterOptions: ScreenerResultsFilterOptions;
    switch ( prop ) {
      case 'large_cap':
        // large cap is a stocks exposure and it in index 2 of the StocksTierOptions object
        screenerSector = 'stocks';
        tier1Index = 2;
        // If Large Cap Stocks and Value are red underweight, add Large Cap Value tilt
        if ( this.getExposureRow( 'value_stocks', exposures ) ) {
          tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[1] ];
          break;
        }
        // If Large Cap Stocks and Growth are red underweight, add Large Cap Growth tilt
        if ( this.getExposureRow( 'growth_stocks', exposures ) ) {
          tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[2] ];
          break;
        }
        // If Large Cap Stocks is red underweight and Value and Growth are NOT red underweight, add large cap blend tilt
        // value and growth are not underweight, so we want large cap blend
        tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[0] ];
        break;
      case 'mid_cap':
        screenerSector = 'stocks';
        tier1Index = 3;
        // If Mid Cap Stocks and Value are red underweight, add Mid Cap Value tilt
        if ( this.getExposureRow( 'value_stocks', exposures ) ) {
          tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[1] ];
          break;
        }
        // If Mid Cap Stocks and Growth are red underweight, add Mid Cap Growth tilt
        if ( this.getExposureRow( 'growth_stocks', exposures ) ) {
          tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[2] ];
          break;
        }
        // If Mid Cap Stocks is red underweight and Value and Growth are NOT red underweight, add Mid cap blend tilt
        tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[0] ];
        break;
      case 'small_cap':
        screenerSector = 'stocks';
        tier1Index = 4;
        // If Small Cap Stocks and Value are red underweight, add Small Cap Cap Value tilt
        if ( this.getExposureRow( 'value_stocks', exposures ) ) {
          tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[1] ];
          break;
        }
        // If Small Cap Stocks and Growth are red underweight, add Small Cap Growth tilt
        if ( this.getExposureRow( 'growth_stocks', exposures ) ) {
          tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[2] ];
          break;
        }
        // If Small Cap Stocks is red underweight and Value and Growth are NOT red underweight, add Small cap blend tilt
        tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index], ScreenerComponent.MarketCapTierOptions[0] ];
        break;
      case 'us':
        // If US Stocks is red underweight, add Total US Stock Market
        tier1Index = 1;
        tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index] ];
        screenerSector = 'stocks';
        break;
      case 'non_us':
        // If Non-US Stocks is red underweight, add Total International Stock Markets
        tier1Index = 5;
        tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index] ];
        screenerSector = 'stocks';
        break;
      case 'emerging_markets':
        // If Emerging Stocks is red underweight, add Emerging Stock Markets
        tier1Index = 7;
        tierOptions = [ ScreenerComponent.StockTierOptions[tier1Index] ];
        screenerSector = 'stocks';
        break;
      case 'sector_basic_materials':
      case 'sector_communication_services':
      case 'sector_consumer_cyclical':
      case 'sector_consumer_defensive':
      case 'sector_energy':
      case 'sector_financial_services':
      case 'sector_healthcare':
      case 'sector_industrials':
      case 'sector_real_estate':
      case 'sector_technology':
      case 'sector_utilities':
        break;
      case 'aaa':
        screenerSector = 'bonds';
        // If AAA and Agency MBS are red underweight, add Agency MBS
        if ( this.getExposureRow( 'bond_primary_sector_agency_mbs', exposures ) ) {
          tierOptions = [ ScreenerComponent.BondsTierOptions[5] ];
          break;
        }
        break;
      case 'aa':
      case 'a':
      case 'bbb':
        // If AA is red underweight, add Other US Investment Grade Corporates with AA >20%
        if ( prop === 'aa' ) {
          filterOptions = { amount: 0.2, column: 'aa', lessOrMoreThan: '>' };
        }
        // If A is red underweight, add Other US Investment Grade Corporates
        if ( prop === 'a' ) {
          filterOptions = { amount: 0.5, column: 'a', lessOrMoreThan: '>' };
        }
        // If BBB is red underweight, add Other US Investment Grade Corporates with BBB >65%
        if ( prop === 'bbb' ) {
          filterOptions = { amount: 0.5, column: 'bbb', lessOrMoreThan: '>' };
        }
        screenerSector = 'bonds';
        tierOptions = [ ScreenerComponent.BondsTierOptions[4], ScreenerComponent.CreditQualityTierOptions[3] ];
        break;
      case 'bb':
      case 'b':
      case 'below_b':
      case 'not_rated':
        // If BB, B, <B or NR  is red underweight, add US High Yield Bonds
        tierOptions = [ ScreenerComponent.BondsTierOptions[2] ];
        screenerSector = 'bonds';
        break;
      case 'bonds_us':
        // If US Bonds is red underweight, add US Investment Grade
        tierOptions = [ ScreenerComponent.BondsTierOptions[1] ];
        screenerSector = 'bonds';
        break;
      case 'bonds_non_us':
        // If Non-US Bonds is red underweight, add Total International ((Non-US)
        tierOptions = [ ScreenerComponent.BondsTierOptions[7] ];
        screenerSector = 'bonds';
        break;
      case 'bonds_region_emerging_markets':
        // If Emerging Bonds is red underweight, add Emerging Markets Bonds
        tierOptions = [ ScreenerComponent.BondsTierOptions[9] ];
        screenerSector = 'bonds';
        break;
      case 'bond_primary_sector_agency_mbs':
        // If Agency MBS is red underweight, add Agency MBS
        tierOptions = [ ScreenerComponent.BondsTierOptions[5] ];
        screenerSector = 'bonds';
        break;
      /*      case 'bond_primary_sector_government':
              screenerSector = 'bonds';
              tier1Index = 3;
              // If Govt Bonds is red underweight and any short-term maturities are underweight, add Short-Term Treasuries
              if ( this.isShortTermMaturitiesUnderweight( exposures ) ) {
                tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[0] ];
                break;
              }
              // If Govt Bonds is red underweight and any Intermediate-term maturities are underweight, add Intermediate-Term Treasuries
              if ( this.isIntermediateTermMaturitiesUnderweight( exposures ) ) {
                tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[1] ];
                break;
              }
              // If Govt Bonds is red underweight and any Long-term maturities are underweight, add Long-Term Treasuries
              if ( this.isLongTermMaturitiesUnderweight( exposures ) ) {
                tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[2] ];
                break;
              }
              // If Govt Bonds is red underweight and all Short-term, Intermediate-term and Long-term maturities are NOT underweight, add Other Treasuries
              tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[3] ];
              break;*/
      /*      case 'bond_primary_sector_corporate_bond':
              screenerSector = 'bonds';
              tier1Index = 4;
              // If Corporate Bonds is red underweight and any short-term maturities are underweight, add Short-Term US Corporate Investment Grade
              if ( this.isShortTermMaturitiesUnderweight( exposures ) ) {
                tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[0] ];
                break;
              }
              // If Corporate Bonds is red underweight and any Intermediate-term maturities are underweight, add Intermediate-Term US Corporate Investment Grade
              if ( this.isIntermediateTermMaturitiesUnderweight( exposures ) ) {
                tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[1] ];
                break;
              }
              // If Corporate Bonds is red underweight and any Long-term maturities are underweight, add Long-Term US Corporate Investment Grade
              if ( this.isLongTermMaturitiesUnderweight( exposures ) ) {
                tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[2] ];
                break;
              }
              // If Corporate Bonds is red underweight and all Short-term, Intermediate-term and Long-term maturities are NOT underweight, add Other Corporates
              tierOptions = [ ScreenerComponent.BondsTierOptions[tier1Index], ScreenerComponent.CreditQualityTierOptions[3] ];
              break;*/

      case 'less_than_one_year':
        // If<1yr is red underweight, add money market fund
        tierOptions = [];
        screenerSector = 'money_market';
        break;
      case 'one_to_three_years':
        // If 1-3 yrs is red underweight, add US Treasury Short Term
        tierOptions = [ ScreenerComponent.BondsTierOptions[3], ScreenerComponent.CreditQualityTierOptions[0] ];
        screenerSector = 'bonds';
        break;
      case 'three_to_five_years':
      case 'five_to_seven_years':
      case 'seven_to_ten_years':
        // If 3-5 yrs is red underweight, add US Treasury Intermediate Term
        // If 5-7 yrs is red underweight, add US Treasury Intermediate Term
        // If 7-10 yrs is red underweight, add US Treasury Intermediate Term
        tierOptions = [ ScreenerComponent.BondsTierOptions[3], ScreenerComponent.CreditQualityTierOptions[1] ];
        screenerSector = 'bonds';
        break;
      case 'ten_to_fifteen_years':
      case 'fifteen_to_twenty_years':
      case 'twenty_to_thirty_years':
      case 'over_thirty_years':
        // If 10-15 yrs is red underweight, add US Treasury Long Term
        // If 15-20 yrs is red underweight, add US Treasury Long Term
        // If 20-30 yrs is red underweight, add US Treasury Long Term
        // If >30 yrs is red underweight, add US Treasury Long Term
        tierOptions = [ ScreenerComponent.BondsTierOptions[3], ScreenerComponent.CreditQualityTierOptions[2] ];
        screenerSector = 'bonds';
        break;
      // not sure what to do with these yet
      case 'bond_primary_sector_abs':
      case 'bond_primary_sector_bank_loan':
      case 'bond_primary_sector_commercial_mbs':
      case 'bond_primary_sector_convertibles':
      case 'bond_primary_sector_covered_bond':
      case 'bond_primary_sector_future_forward':
      case 'bond_primary_sector_government_related':
      case 'bond_primary_sector_non_agency_residential_mbs':
      case 'bond_primary_sector_preferred':
      case 'bond_primary_sector_us_municipal_tax_advantaged':
        break;
      default:
    }

    return { tier: tierOptions.length + 1, tierOptions, screenerSector, filterOptions };
  }

  static getExposureRow( prop: string, underweightExposures: UnderweightExposure[] ) {
    return underweightExposures.find( ( ue: UnderweightExposure ) => {
      return ue.row.dimensionProperty === prop;
    } );
  }

  static isShortTermMaturitiesUnderweight( exposures: UnderweightExposure[] ): boolean {
    return !!exposures.find( ( ue: UnderweightExposure ) => {
      return ue.row.dimensionProperty === 'one_to_three_years';
    } );
  }

  static isIntermediateTermMaturitiesUnderweight( exposures: UnderweightExposure[] ): boolean {
    return !!exposures.find( ( ue: UnderweightExposure ) => {
      return [ 'three_to_five_years', 'five_to_seven_years', 'seven_to_ten_years' ].includes( ue.row.dimensionProperty );
    } );
  }

  static isLongTermMaturitiesUnderweight( exposures: UnderweightExposure[] ): boolean {
    return !!exposures.find( ( ue: UnderweightExposure ) => {
      return [ 'ten_to_fifteen_years', 'fifteen_to_twenty_years', 'twenty_to_thirty_years', 'over_thirty_years' ].includes( ue.row.dimensionProperty );
    } );
  }

  static isBlendValueGrowth( exposure: UnderweightExposure ): boolean {
    return [ 'value_stocks', 'growth_stocks', 'blend_stocks' ].includes( exposure.row.dimensionProperty );
  }

  static isGovtBonds( exposure: UnderweightExposure ): boolean {
    return exposure.row.dimensionProperty === 'bond_primary_sector_government';
  }

  static isCorporateBonds( exposure: UnderweightExposure ): boolean {
    return exposure.row.dimensionProperty === 'bond_primary_sector_corporate_bond';
  }

  static isAAABonds( exposure: UnderweightExposure ): boolean {
    return exposure.row.dimensionProperty === 'aaa';
  }

  static getMarketCapIndex( marketCapPropString: string ) {
    return [ '', '', 'large_cap', 'mid_cap', 'small_cap' ].indexOf( marketCapPropString );
  }

  static getValueBlendGrowthIndex( valueBlendGrowthProp: string ) {
    return [ 'blend_stocks', 'value_stocks', 'growth_stocks' ].indexOf( valueBlendGrowthProp );
  }

  static getBondsChoiceIndex( bondsProp: string ) {
    if ( bondsProp === 'bond_primary_sector_government' || bondsProp === 'aaa' ) {
      return 3;
    }
    if ( bondsProp === 'bond_primary_sector_corporate_bond' ) {
      return 4;
    }
  }

  static getMaturityChoiceIndex( termValue: string ) {
    return [ 'short_term', 'mid_term', 'long_term', 'other_term' ].indexOf( termValue );
  }

  static shouldAddMultipleExposures( exposure: UnderweightExposure ): boolean {
    return InvestmentSelectorUtil.isBlendValueGrowth( exposure ) ||
      InvestmentSelectorUtil.isGovtBonds( exposure ) ||
      InvestmentSelectorUtil.isCorporateBonds( exposure ) ||
      InvestmentSelectorUtil.isAAABonds( exposure );
  }

  /*
If Blend is red underweight and Value has ANY underweight, add Large Cap Value tilt, Mid Cap Value tilt and Small Cap Value tilt
If Blend is red underweight and Growth has ANY underweight, add Large Cap Growth tilt, Mid Cap Growth tilt and Small Cap Growth tilt
If Blend is red underweight and VALUE AND GROWTH ARE BOTH OVERWEIGHT, add Large Cap Blend tilt, Mid Cap Blend tilt and Small Cap Blend tilt
Note: Any underweight includes green
* */

}
