<div>


  <!-- Headers-->
  <div class="form-group" *ngIf="type === 'Real Estate'">
    <div class="flexing">
      <fa-icon [icon]="faHouse" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
      <h3 class="manual-account-form-H3" style="padding-bottom: 25px;">Add the details about your property</h3>

    </div>
    (add your home, investment properties, etc)
  </div>


  <div class="form-group" *ngIf="type === 'Vehicle'">
    <div class="flexing space-between">
      <fa-icon [icon]="faCar" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
      <h3 class="manual-account-form-H3" style="padding-bottom: 25px;">Add the details about your vehicle</h3>

    </div>
  </div>

  <div class="form-group" *ngIf="type === 'Valuable(s)'">
    <div class="flexing space-between">
      <fa-icon [icon]="faGem" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
      <h3 class="manual-account-form-H3" style="padding-bottom: 25px;">Add the details about the valuable(s).</h3>

    </div>
    (valuables can include precious metals, jewelry, collectables, equipment, treasure, gems, etc)
  </div>


</div>

<div class="form-group">
  <div>
    Fields with an * are required
  </div>
</div>
<div *ngIf="form">
  <form [formGroup]="form" #formElement>
    <div class="form-group" *ngIf="type === 'Real Estate'">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="name"
                 [errorStateMatcher]="matcher"
                 placeholder="Account Name*">
        </label>
        <mat-error *ngIf="form.controls.name.hasError('required')">
          Name is required
        </mat-error>
        <mat-label>Property Name</mat-label>
      </mat-form-field>
    </div>
    <div *ngIf="type === 'Valuable(s)'">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <label>
            <input matInput
                   formControlName="description"
                   [errorStateMatcher]="matcher"
                   placeholder="Description*">
          </label>
          <mat-error *ngIf="form.controls.description.hasError('required')">
            Description is required
          </mat-error>
          <mat-label>Description</mat-label>
        </mat-form-field>
      </div>
    </div>
    <!-- REAL ESTATE SPECIFIC FIELDS -->
    <div *ngIf="type === 'Real Estate'">
      <div><h3 class="manual-account-form-H3">What is the address?</h3>(Autocompletes with data from Google)</div>
      <div class="form-group">
        <!-- address line 1 -->
        <rip-address-autocomplete [form]="form" (addressChanged)="addressChange()"
                                  [addressFormat]="'outline'"></rip-address-autocomplete>

        <!--address 2-->
        <mat-form-field appearance="outline">
          <input matInput placeholder="Address Line 2"
                 name="address_line2"
                 (change)="addressChange()"
                 [formControl]="$any(form).controls.address_line2">
        </mat-form-field>
        <div class="flexing space-between with-gap">
          <!--city-->
          <mat-form-field appearance="outline">
            <input matInput placeholder="City"
                   name="address_city"
                   (change)="addressChange()"
                   [formControl]="$any(form).controls.address_city">
          </mat-form-field>
          <!-- state -->
          <mat-form-field appearance="outline">
            <mat-select [formControl]="$any(form).controls.address_state"
                        placeholder="State"
                        panelClass="state-panel max-select-box-height"
                        (change)="addressChange()"
                        [errorStateMatcher]="matcher" class="state-panel">
              <mat-option>Select State</mat-option>
              <mat-option *ngFor="let state of states" [value]="state.short">{{ state.short }}
                - {{ state.full }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--zip-->
          <mat-form-field appearance="outline">
            <input matInput placeholder="Zip"
                   name="address_zip"
                   (change)="addressChange()"
                   [formControl]="$any(form).controls.address_zip">
          </mat-form-field>
        </div>
      </div>
      <div class="flexing space-between">
        <h3 class="manual-account-form-H3">What is the current estimated valuation?</h3>
        <div>
          <button mat-raised-button
                  class="raised-menu-button"
                  type="button"
                  (click)="goToZillowSite()"
                  [disabled]="goToZillowButtonDisabled">
            See Zillow Valuation
          </button>
        </div>
      </div>
      <!-- COMMENTING OUT ZILLOW BUTTON BECAUSE THE API ENDPOINT IS GONE -->
      <!--<mat-grid-list [cols]="7" [rowHeight]="75">
        <mat-grid-tile [colspan]="2" style="text-align: left;">
          <button mat-raised-button type="button" (click)="goToZillowSite()" [disabled]="goToZillowButtonDisabled">
            Go To Zillow
          </button>
        </mat-grid-tile>
        &lt;!&ndash;<mat-grid-tile [colspan]="2">
          <img src="{{zillowLogoUrl}}" alt="Zillow">
        </mat-grid-tile>
        <mat-grid-tile [colspan]="3"><span class="mr-auto" style="font-size: 10pt;">© Zillow, Inc., 2006-2016. Use is subject to <a
          href="https://www.zillow.com/corp/Terms.htm" target="_blank">Terms of Use</a>
          <br><a href="https://www.zillow.com/wikipages/What-is-a-Zestimate/" target="_blank">What's a Zestimate?</a>
         </span>
        </mat-grid-tile>&ndash;&gt;
      </mat-grid-list>
      <br>-->

    </div>
    <!-- VEHICLE SPECIFIC FIELDS -->
    <div *ngIf="type === 'Vehicle'">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <label>
            <input matInput
                   formControlName="year"
                   [errorStateMatcher]="matcher"
                   placeholder="Year">
          </label>
          <mat-label>Year</mat-label>
        </mat-form-field>
      </div>
      <!--car make-->
      <div class="form-group">
        <mat-form-field appearance="outline">
          <label>
            <input matInput
                   formControlName="make"
                   [errorStateMatcher]="matcher"
                   placeholder="Make">
          </label>
          <mat-label>Make</mat-label>
        </mat-form-field>
      </div>
      <!--car model-->
      <div class="form-group">
        <mat-form-field appearance="outline">
          <label>
            <input matInput
                   formControlName="model"
                   [errorStateMatcher]="matcher"
                   placeholder="Model">
          </label>
          <mat-label>Model</mat-label>
        </mat-form-field>
      </div>
    </div>
    <!-- VALUE field-->
    <div class="form-group">
      <span *ngIf="showZestimateLabel">Zestimate®</span>
      <h3 class="manual-account-form-H3" *ngIf="type === 'Vehicle'">What is the current estimated value of the
        vehicle?</h3>
      <h3 class="manual-account-form-H3" *ngIf="type === 'Valuable(s)'">What is the current estimated value of the
        valuable?</h3>
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="price"
                 [errorStateMatcher]="matcher"
                 placeholder="Market Price*"
                 (change)="onPriceChange()">
        </label>
        <mat-error *ngIf="form.controls.price.hasError('required')">
          Price is required
        </mat-error>
        <mat-label>Market Price</mat-label>
      </mat-form-field>
    </div>
    <div class="form-group flexing">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="quantity"
                 [errorStateMatcher]="matcher"
                 placeholder="Quantity*"
                 (change)="onQuantityChange()">
        </label>
        <mat-error *ngIf="form.controls.quantity.hasError('required')">
          Quantity is required
        </mat-error>
        <mat-label>Quantity</mat-label>
      </mat-form-field>
      <fa-icon [icon]="faInfoCircle"
               class="form-info"
               [fixedWidth]="true"
               size="sm"
               [nbPopover]="realAssetsQuantityPopContent"
               nbPopoverClass="rip-popover">
      </fa-icon>


    </div>
    <!--market value-->
    <div class="form-group flexing">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="value"
                 [errorStateMatcher]="matcher"
                 placeholder="Market Value ($)*"
                 (change)="publicUtil.updateInputCurrencyFormat('value', form);valueChanged()">
        </label>
        <mat-error *ngIf="form.controls.value.hasError('required')">
          Market Value is required
        </mat-error>
        <mat-label>Market Value</mat-label>
      </mat-form-field>
      <span class="disclaimer form-disclaimer" (click)="openGlossary( 19 )">
        <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
      </span>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="cost_basis"
                 [errorStateMatcher]="matcher"
                 placeholder="Purchase Price/Cost Basis ($)*"
                 (change)="publicUtil.updateInputCurrencyFormat('cost_basis', form)">
        </label>
        <mat-error *ngIf="form.controls.cost_basis.hasError('required')">
          Purchase Price/Cost Basis is required
        </mat-error>
        <mat-label>Purchase Price/Cost Basis ($)*</mat-label>
      </mat-form-field>
    </div>
    <div class="horizontal-form-group">
      <!-- Annual cash flows-->
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="dollar_flow"
                 [errorStateMatcher]="matcher"
                 placeholder="Annual Cash Flow (-/+, $)"
                 (change)="dollarFlowChanged()">
        </label>
        <mat-label>Annual Cash Flow (-/+, $)</mat-label>
      </mat-form-field>
      <fa-icon [icon]="faInfoCircle"
               class="form-info"
               [fixedWidth]="true"
               size="sm"
               [nbPopover]="dollarFlowPopContent"
               nbPopoverClass="rip-popover">
      </fa-icon>
    </div>
    <!-- Yield-->
    <div class="horizontal-form-group">
      <mat-form-field appearance="outline">
        <label>
          <input matInput
                 formControlName="annualized_yield"
                 [errorStateMatcher]="matcher"
                 placeholder="Yield"
                 (change)="yieldChanged()">
        </label>
        <mat-label>Yield</mat-label>
      </mat-form-field>
      <fa-icon [icon]="faInfoCircle"
               class="form-info"
               [fixedWidth]="true"
               size="sm"
               [nbPopover]="yieldPopContent"
               nbPopoverClass="rip-popover">
      </fa-icon>
    </div>
    <div *ngIf="type !== 'Valuable(s)'">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Owner Type"
                      formControlName="owner_type"
                      [errorStateMatcher]="matcher">
            <mat-option value="Individual Account">Individual Account</mat-option>
            <mat-option value="Joint Account">Joint Account</mat-option>
            <mat-option value="Trust Account">Trust Account</mat-option>
          </mat-select>
          <mat-label>Owner Type</mat-label>
        </mat-form-field>
      </div>

      <div class="form-group flexing" *ngIf="!hideCorrespondingLiability">
        <div>
          <h3 class="manual-account-form-H3">Does this asset have a corresponding liability? (Mortgage,
            loan, etc)</h3>
        </div>
        <mat-form-field appearance="outline">
          <!--  need an info tool tip for this -->
          <mat-select placeholder="Corresponding Liability"
                      panelClass="max-select-box-height"
                      formControlName="corresponding_liability_id"
                      [errorStateMatcher]="matcher">
            <mat-option
                    *ngIf="allLoanAccounts.length > 0">
              {{ ( hasCorrespondingLiability ) ? 'Remove Account' : 'Choose Account' }}
            </mat-option>
            <mat-option *ngIf="allLoanAccounts.length === 0">No Liabilities to choose from yet</mat-option>
            <mat-option *ngFor="let acct of allLoanAccounts" [value]="acct.account_id">
              {{ acct.formattedDescription }}
            </mat-option>
          </mat-select>
          <mat-label>Corresponding Liability</mat-label>
        </mat-form-field>
        <fa-icon [icon]="faInfoCircle"
                 class="form-info"
                 [fixedWidth]="true"
                 size="sm"
                 [nbPopover]="correspondingLiabilityPopContent"
                 nbPopoverClass="rip-popover">
        </fa-icon>

        <!---->
      </div>

    </div>
  </form>
</div>

<ng-template #dollarFlowPopContent>
  <nb-card class="popover-card">
    <nb-card-header class="popover-title">
      Real Asset Yield
    </nb-card-header>
    <nb-card-body>
      <div class="popover-content">
        Entering a negative number for annualized cost (i.e., maintenance) will be included in your wealth
        portfolio
        dashboard for budgeting purposes. A positive annualized cash flow number can be used for income
        generating
        property.
        This is essentially a user discretion annual net income/loss available for aggregation.
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #yieldPopContent>
  <nb-card class="popover-card">
    <nb-card-header class="popover-title">
      Annual Cash Flow
    </nb-card-header>
    <nb-card-body>
      <div class="popover-content">
        Annual Cash Flow divided by Market Value
      </div>
    </nb-card-body>
  </nb-card>

</ng-template>

<!--market value pop up-->
<ng-template #marketValuePopContent>
  <nb-card class="popover-card">
    <nb-card-header class="popover-title">
      Market Value
    </nb-card-header>
    <nb-card-body>
      <div class="popover-content">
        Market value is the price an asset would fetch in the marketplace, or the value that the investment
        community gives to a particular equity or business.
      </div>
    </nb-card-body>
  </nb-card>

</ng-template>

<!--quantity pop up-->
<ng-template #realAssetsQuantityPopContent>
  <nb-card class="popover-card">
    <nb-card-header class="popover-title">
      Quantity
    </nb-card-header>
    <nb-card-body>
      <div class="popover-content">
        Quantity is the number of real assets you have of this type. Typically for Real Estate the default value
        of 1 should be used if you are the only owner. If you own part of a vacation property for example, you
        would enter 0.5 if you own 50%.
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!--corresponding liability popover-->
<ng-template #correspondingLiabilityPopContent>
  <nb-card class="popover-card">
    <nb-card-header class="popover-title">
      Corresponding Liability
    </nb-card-header>
    <nb-card-body>
      <div class="popover-content">
        Selecting an associated loan will link it to your real asset. When switching from Net Worth to
        Investment mode both will be removed. If you choose not to include the associated loan, then this loan
        (short bond position) will remain in your bond portfolio in both modes.
      </div>
    </nb-card-body>
  </nb-card>

</ng-template>
