import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { RipThemeLoadingSpinnerService } from '../../theme/services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
// import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { environment } from '../../../environments/environment';
import { Account } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-institution-icon',
  template: `
    <span *ngIf="acct.isManual; else aggregatedAccountIconTemplate">
      <img src="{{acct.favicon}}"
           class="institution-icon"
           alt="{{acct.institution_name}}"
           (error)="fallback()"
           *ngIf="acct.favicon"/>
      <fa-icon [icon]="acct.faIcon"
               [ngClass]="{'pulse': refreshing}"
               class="institution-icon"
               *ngIf="acct.faIcon && (!acct.favicon || failed)">
      </fa-icon>
    </span>

    <ng-template #aggregatedAccountIconTemplate>
      <!--      <span matTooltip="Refresh Account{{ enabled ? '' : ' (Disabled While In Revision)' }}"-->
      <!--            matTooltipClass="mat-tooltip-custom" [style.cursor]="enabled ? 'pointer' : 'not-allowed'">-->
      <img src="{{imgSrc}}" *ngIf="loaded"
           class="institution-icon"
           alt="{{acct.institution_name}}"
           [ngClass]="{'pulse': refreshing}" (error)="fallback()"/>

      <div id="{{spinnerSelector}}" class="icon-spinner{{ deviceIsMobile ? ' mobile-spinner' : '' }}"
           *ngIf="spinnerSelector"
           style="display: none;">
        <div></div>
      </div>
      <!--    </span>-->
    </ng-template>

    <fa-icon [icon]="faUniversity" size="sm" fixedWidth="true" *ngIf="!(acct.faIcon && acct.favicon) && failed"
             class="institution-icon"></fa-icon>
  `,
  styleUrls: [ `./institutionIcon.scss` ],
} )

export class InstitutionIconComponent implements AfterViewInit, OnDestroy {

  // faMinusCircle = faMinusCircle;
  faUniversity = faUniversity;

  @Input() acct: any | Account = {};
  @Input() enabled: boolean = true;
  @Input() refreshIndicator: string = 'spinner'; // spinner or pulse

  spinnerSelector: string;
  spinnerOverrides: any = {};
  refreshing: boolean = false;

  imgSrc: string = '';
  loaded: boolean = false;
  failed: boolean = false;

  deviceIsMobile: boolean = false;

  constructor( private _spinnerService: RipThemeLoadingSpinnerService,
               private _detectorService: DeviceDetectorService,
               private _elRef: ElementRef ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.spinnerSelector = `${ this.acct.account_id }_icon_spinner`;
      this.imgSrc = this.acct.favicon;
      this.loaded = true;
      // for testing
      /*setTimeout( () => {
       if ( this.acct.institution_name && this.acct.institution_name.includes( 'Vanguard' ) ) {
       this.fallback();
       }
       }, 4000 );*/
      if ( environment.env !== 'prod' ) {
        window[ `ripsaw_${ this.spinnerSelector }` ] = this;
      }
    } );
  }

  ngOnDestroy() {
    if ( environment.env !== 'prod' ) {
      if ( window[ `ripsaw_${ this.spinnerSelector }` ] ) {
        delete window[ `ripsaw_${ this.spinnerSelector }` ];
      }
    }
  }

  showSpinner() {
    if ( this.refreshIndicator === 'spinner' ) {
      this._spinnerService.show( this.spinnerSelector, {
        overrides: Object.assign( { background: 'transparent', 'z-index': 100 }, this.spinnerOverrides ),
        // size: 25,
      } );
    } else {
      this.refreshing = true;
    }
  }

  hideSpinner() {
    if ( this.refreshIndicator === 'spinner' ) {
      this._spinnerService.hide( 0, this.spinnerSelector );
    } else {
      this.refreshing = false;
    }
  }

  setSpinnerOverrides( overrides: any ) {
    this.spinnerOverrides = overrides;
  }

  getOffsetTop() {
    return this._elRef.nativeElement.offsetTop;
  }

  fallback() {
    this.loaded = false;
    this.failed = true;
  }

}
