import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalState } from '../global.state';
import { AccountManagementDialogComponent } from '../pages/modals/accountManagementDialog';
import { EVENT_NAMES } from './enums';

@Injectable()
export class AccountManagementUtil implements OnDestroy {

  subscriberName: string = 'AccountManagementUtil';
  dialogId: string = 'account-management-dialog';

  // mam = ManualAccountManager
  constructor( private dialog: MatDialog, private _state: GlobalState ) {
    _state.subscribe( EVENT_NAMES.OPEN_ACCOUNT_MANAGEMENT, ( account_id?: string | number ) => {
      this.openAccountManagementDialog( account_id );
    }, this.subscriberName );
  }

  ngOnDestroy() {
    this._state.unsubscribe( EVENT_NAMES.OPEN_ACCOUNT_MANAGEMENT, this.subscriberName );
  }

  openAccountManagementDialog( account_id?: number | string ) {
    if ( !this.dialog.getDialogById( this.dialogId ) ) {

      this.dialog.open( AccountManagementDialogComponent, {
        data: {
          account_id,
        },
        id: this.dialogId,
        minWidth: '80vw',
        maxWidth: '95vw',
      } );
    }
  }
}
