import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Util } from '../../utils/util.service';
import { RipsawCurrencyPipe } from '../../theme/pipes';
import { AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-currency-input',
  template: `
    <div class="flexing-row-only center-vertically space-between with-gap">
      <mat-form-field appearance="{{ appearance }}">
        <mat-label>{{ label }}</mat-label>
        <input matInput [formControl]="$any(control)" (change)="manualChange()" [placeholder]="placeholder"
               (keydown.enter)="ignoreEnter($event)">
      </mat-form-field>
      <div class="flexing vertical-flex currency-input-button-controls">
        <ng-container *ngIf="controlIsEnabled$ | async">
          <button mat-button (click)="up()" class="currency-input-button">
            <mat-icon>arrow_drop_up</mat-icon>
          </button>
          <button mat-button (click)="down()" class="currency-input-button">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: [ './currency-input.component.scss' ],
} )

export class CurrencyInputComponent implements OnChanges, OnDestroy {
  private unsubscribe$$: Subject<void> = new Subject<void>();

  @Input() control: AbstractControl;
  @Input() label: string = '';
  @Input() appearance: string = 'outline';
  @Input() stepSize: number = 1000;
  @Input() noNegative: boolean = false;
  @Input() placeholder: string = '';

  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  controlIsEnabled$: Observable<boolean>;

  ngOnChanges( changes: SimpleChanges ): void {
    if ( changes?.control?.currentValue ) {
      this.unsubscribe$$.next();
      this.controlIsEnabled$ = this.initControlIsEnabledObs();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$$.complete();
  }

  manualChange() {
    this.control.setValue( this.ripCurrencyPipe.transform( this.getFloatValue() ) );
    this.dataChange.emit();
  }

  up() {
    this.control.setValue( this.ripCurrencyPipe.transform( this.getFloatValue() + this.stepSize ) );
    this.dataChange.emit();
  }

  down() {
    const newVal = this.getFloatValue() - this.stepSize;
    if ( !this.noNegative || newVal >= 0 ) {
      this.control.setValue( this.ripCurrencyPipe.transform( newVal ) );
      this.dataChange.emit();
    }
  }

  getFloatValue() {
    return FormsUtil.getSanitizedFloatValue( this.control.value );
  }

  ignoreEnter( event: any ) {
    event?.preventDefault();
    event?.target?.blur();
  }

  private initControlIsEnabledObs(): Observable<boolean> {
    return this.control.statusChanges
      .pipe(
        startWith( this.control.status ),
        map( status => status !== 'DISABLED' ),
        distinctUntilChanged(),
        takeUntil( this.unsubscribe$$ ),
      );
  }

}
