import { AfterViewInit, Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { GlobalState } from '../../../global.state';
import { layoutSizes } from '../../../theme';
import { Auth } from '../../../auth.service';
import { Util } from '../../../utils/util.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { UsersUtil } from '../../../utils/users.util';
import { SubscriptionDetailsUtil } from '../../../utils/subscriptionDetails.util';
import { EVENT_NAMES, PAGE_NAMES } from '../../../utils/enums';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Logger } from '../../../utils/logger.service';

@Component( {
  selector: 'nga-ba-sidebar',
  templateUrl: './baSidebar.html',
  styleUrls: [ './baSidebar.scss' ],
} )
export class BaSidebarComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

  private readonly onDestroy: Subject<void> = new Subject<void>();

  menuHeight: number;
  isMenuCollapsed: boolean = false;

  subscriptionInactive: boolean = false;

  logoUrl: string = '';
  smallLogoUrl: string = '';

  subscriberName: string = 'sidebarComponent';

  showSidebarExpandButton: boolean = true;
  showSidebar: boolean = true;

  sidebarUserPref: boolean = false;
  inWealthFluent: boolean = false;

  showSideBarInWealthFluentPages: PAGE_NAMES[] = [ PAGE_NAMES.ACCOUNTS, PAGE_NAMES.HOLDINGS, PAGE_NAMES.TRANSACTIONS ];

  constructor( private _state: GlobalState,
               private _auth: Auth,
               private router: Router,
               private _subscriptionDetailsUtil: SubscriptionDetailsUtil ) {

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_sidebar' ] = this;
    }

    this._state.subscribe( EVENT_NAMES.MENU_IS_COLLAPSED, ( isCollapsed ) => {
      this.isMenuCollapsed = isCollapsed;
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
      this.setSubscriptionStatus();
    }, this.subscriberName );

    this.logoUrl = environment.common.logoUrl;
    this.smallLogoUrl = environment.common.smallLogoUrl;

    this.router.events
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( e => {
        if ( e instanceof NavigationEnd ) {
          this._insideWealthFluent();
        }
      } );
  }

  ngOnInit(): void {
    this.setSubscriptionStatus();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.inWealthFluent = this._state.globalVars.inWealthFluent;
      this.menuCollapse();
      this._insideWealthFluent();

      // this one needs to go after the inside wealth fluent one so that it still removes the sidebar when on small screens
      if ( this._shouldMenuBeHidden() ) {
        this.showSidebar = false;
      }

      // this.toggleMenu();
    } );

  }

  ngOnChanges( change: SimpleChanges ): void {
    Logger.log( change );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [ EVENT_NAMES.MENU_IS_COLLAPSED, EVENT_NAMES.SUBSCRIPTION_CHANGED ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  @HostListener( 'window:resize' )
  onWindowResize(): void {
    const isMenuShouldBeCollapsed = this._shouldMenuCollapse();


    this.showSidebarExpandButton = !isMenuShouldBeCollapsed;


    if ( isMenuShouldBeCollapsed ) {
      this.menuCollapseStateChange( isMenuShouldBeCollapsed );
    }

    this.showSidebar = !this._shouldMenuBeHidden();
  }

  menuExpand(): void {
    this.menuCollapseStateChange( false );
  }

  menuCollapse(): void {
    this.menuCollapseStateChange( true );
  }

  menuCollapseStateChange( isCollapsed: boolean ): void {
    this.isMenuCollapsed = isCollapsed;
    this._state.globalVars.sidebarCollapsed = this.isMenuCollapsed;
    this._state.notifyDataChanged( EVENT_NAMES.MENU_IS_COLLAPSED, this.isMenuCollapsed );
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= layoutSizes.resWidthCollapseSidebar;
  }

  private _shouldMenuBeHidden(): boolean {
    return window.innerWidth <= layoutSizes.resWidthHideSidebar;
  }

  private _insideWealthFluent(): void {
    if ( this._state.globalVars.inWealthFluent ) {
      this.inWealthFluent = true;
      const body: HTMLElement = document.getElementsByTagName( 'body' )[ 0 ];
      this._state.globalVars.currentPageTitle = this._state.activeLinks[ this.router.url.split( '?' )[ 0 ] ] as PAGE_NAMES;
      if ( this.showSideBarInWealthFluentPages.includes( this._state.globalVars.currentPageTitle ) ) {
        this.showSidebar = true;
        body.style.setProperty( '--sidebar-width', '54px' );
      } else {
        this.showSidebar = false;
        body.style.setProperty( '--sidebar-width', '0' );
      }
    }
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.globalVars.sidebarCollapsed = this.isMenuCollapsed;
    this._state.notifyDataChanged( EVENT_NAMES.MENU_IS_COLLAPSED, this.isMenuCollapsed );
    return false;
  }

  setSubscriptionStatus() {
    const user = Util.getLoggedInUser( this._auth );
    this._subscriptionDetailsUtil.hasActiveSubscription().subscribe( {
      next: ( hasActiveSubscription ) => {
        if ( user && hasActiveSubscription ) {
          this.subscriptionInactive = this._state.globalVars.subscriptionInactive = false;
        } else {
          this.subscriptionInactive = this._state.globalVars.subscriptionInactive = true;
        }
      }, error: ( err ) => {
        console.error( err );
      },
    } );

  }


  /*  onConnectionAdded() {
   this._accountManager.getAccounts();
   }

   onConnectionSynced( connection: any ) {
   this._accountManager.getAccounts();
   this._accountManager.addConnectionToAccountMap( connection );
   }

   addConnectToAccountManager( connect: any ) {
   this._accountManager.setConnect( connect );
   }*/

}
