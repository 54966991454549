import { ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { hasAnyPermissionFn } from '../has-any-permission-fn';
import { takeUntil } from 'rxjs/operators';
import { AppStoreService } from '../../../store';
import { WorkspacePermissionNameStore } from '../../../store/workspace';

@Directive( {
  selector: '[ripPerm]',
} )
export class WorkspacePermissionDirective implements OnChanges, OnInit, OnDestroy {
  private refresher$$: BehaviorSubject<WorkspacePermissionNameStore | WorkspacePermissionNameStore[]> = new BehaviorSubject<WorkspacePermissionNameStore | WorkspacePermissionNameStore[]>( [] );
  private destroyed$$: Subject<void> = new Subject<void>();

  @Input() ripPerm: WorkspacePermissionNameStore | WorkspacePermissionNameStore[];

  @Input() ripPermNgIf: boolean = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private appStoreService: AppStoreService,
  ) {
  }

  ngOnChanges( changes: SimpleChanges ): void {
    const { ripPerm } = changes;

    if ( ripPerm?.currentValue && ( typeof this.ripPerm === 'string' || Array.isArray( this.ripPerm ) ) ) {
      this.refresher$$.next( this.ripPerm );
    }
  }

  ngOnInit(): void {
    combineLatest( [
      this.appStoreService.loadedWorkspacePermissions$,
      this.refresher$$.asObservable(),
    ] )
      .pipe(
        takeUntil( this.destroyed$$ ),
      )
      .subscribe( ( [ all, cur ] ) => {
        this.updateView( hasAnyPermissionFn( all, cur ) );
        this.cd.markForCheck();
      } );
  }

  ngOnDestroy(): void {
    this.refresher$$.complete();
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  private updateView( hasPermission: boolean ): void {
    if ( hasPermission && this.ripPermNgIf ) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView( this.templateRef );
    } else {
      this.viewContainerRef.clear();
    }
  }

}
