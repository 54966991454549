import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faChartPieAlt } from '@fortawesome/pro-light-svg-icons/faChartPieAlt';
import { ColumnDefinition, GlobalState } from '../../../../global.state';
import { Logger } from '../../../../utils/logger.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HoldingsFilterPreset, HoldingsUtil } from '../holdings.util';
import { MatMenuTrigger } from '@angular/material/menu';
import { HoldingsFilterPresetMenuItemComponent } from './holdings-filter-preset-menu-item.component';
import { EVENT_NAMES } from '../../../../utils/enums';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component( {
  selector: 'rip-holdings-toolbar',
  templateUrl: './holdingsToolbar.component.html',
  styleUrls: [ `./holdingsToolbar.scss` ],
} )

export class HoldingsToolbarComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'topBar', { static: false } ) topBarDiv: ElementRef;
  @ViewChild( 'filterPresetsMenuTrigger' ) filterPresetsMenuTrigger: MatMenuTrigger;
  @ViewChild( 'mainPresetsMenu' ) mainPresetsMenu: HoldingsFilterPresetMenuItemComponent;

  faSyncAlt: IconDefinition = faSyncAlt;
  faChartPieAlt: IconDefinition = faChartPieAlt;
  faDollarSign: IconDefinition = faDollarSign;
  @Input() pricesRefreshing: boolean;

  @Input() topBarMargin: number;
  @Input() showTable: boolean = true;
  @Output() refreshPrices: EventEmitter<any> = new EventEmitter<any>();

  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  @Output() expandAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() collapseAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() flipTable: EventEmitter<any> = new EventEmitter<any>();
  subscriberName: string = 'HoldingsToolbarComponent';

  presetGroups: HoldingsFilterPreset[] = [];

  currentColumnForFilter: ColumnDefinition;
  currentOperatorForFilter: string;
  columns: ColumnDefinition[] = [];
  operators: string[] = [ '>', '<', '=' ];
  filterFormControl: UntypedFormControl = new UntypedFormControl( '', Validators.required );
  disableFilterButton: boolean = true;

  columnsToIgnore: any = {
    'gain_loss': true,
    'cost_basis': true,
    'outstanding_balance': true,
    'original_loan_amount': true,
    'loan_origination_date': true,
    'maturity_value': true,
    'payment': true,
    'current_market_rate': true,
    'annualized_payment': true,
    'expected_remaining_life': true,
    'dollar_flow': true,
    'expenses': true,
    'maturity_date': true,
  };

  viewLoaded: boolean = false;

  constructor( private _state: GlobalState ) {
    Object.keys( _state.allColumnsObject ).forEach( ( key: any ) => {
      const def: ColumnDefinition = _state.allColumnsObject[key].account;
      if ( def && def.unit && !this.columnsToIgnore[key] ) {
        this.columns.push( def );
      }
    } );
    this.presetGroups = HoldingsUtil.getHoldingsFilterPresets( _state );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.MENU_IS_COLLAPSED, this.subscriberName );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this._state.subscribe( EVENT_NAMES.MENU_IS_COLLAPSED, ( isCollapsed: boolean ) => {
        /*if ( isCollapsed ) {
          this.topBarDiv.nativeElement.style.width = `calc(100% - ${ this._state.globalVars.sidebarWidth.collapsed } - ${ this._state.globalVars.sidebarWidth.extraMargin })`;
        } else {
          this.topBarDiv.nativeElement.style.width = `calc(100% - ${ this._state.globalVars.sidebarWidth.expanded } - ${ this._state.globalVars.sidebarWidth.extraMargin })`;
        }*/
      }, this.subscriberName );
      this.viewLoaded = true;
    } );
  }

  refreshP() {
    this.refreshPrices.emit();
  }

  setColumn( column: ColumnDefinition ) {
    this.currentColumnForFilter = column;
    this.disableFilterButton = !this.canFilter();
  }

  setOperator( operator: string ) {
    this.currentOperatorForFilter = operator;
    this.disableFilterButton = !this.canFilter();
  }

  filterValueChanged() {
    this.disableFilterButton = !this.canFilter();
  }

  canFilter() {
    return this.currentOperatorForFilter !== undefined &&
      this.currentColumnForFilter !== undefined &&
      this.filterFormControl.valid;
  }

  filterHoldings() {
    Logger.info( `filter holdings with column ${ this.currentColumnForFilter.name } and operator ${ this.currentOperatorForFilter } and value of ${ this.filterFormControl.value }` );
    this.filter.emit( {
      column: this.currentColumnForFilter,
      operator: this.currentOperatorForFilter,
      value: this.filterFormControl.value,
    } );
  }

  clearFilter() {
    this.currentColumnForFilter = undefined;
    this.currentOperatorForFilter = undefined;
    this.filterFormControl.setValue( '' );
    this.clear.emit();
    this.disableFilterButton = !this.canFilter();
  }

  expand() {
    this.expandAll.emit();
  }

  collapse() {
    this.collapseAll.emit();
  }

  flip() {
    this.flipTable.emit();
  }

  choosePresetFilter( preset: HoldingsFilterPreset ) {
    this.currentColumnForFilter = preset.filter.column;
    this.currentOperatorForFilter = preset.filter.operator;
    this.filterFormControl.setValue( preset.filter.value );
    this.filterHoldings();
    this.filterPresetsMenuTrigger.closeMenu();
    this.mainPresetsMenu.clearPressedOnceFlags();
  }
}
