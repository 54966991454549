import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimersComponent } from '../pages/modals/disclaimers/disclaimers.component';

@Injectable()
export class DisclaimersUtil {

  constructor( public dialog: MatDialog ) {

  }

  openDisclaimersDialog( disclaimerToHighlight?: number ) {
    this.dialog.open( DisclaimersComponent, {
      width: '796px',
      autoFocus: false,
      data: {
        disclaimerToHighlight,
      },
    } );
  }

}
