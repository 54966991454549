import { Directive, HostBinding, Input } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive( {
  selector: '[ripProgressSpinner]',
} )
export class ProgressSpinnerDirective {
  private _ripProgressSpinner: boolean;

  @Input()
  @HostBinding( 'class.progress-spinner' )
  get ripProgressSpinner(): boolean {
    return this._ripProgressSpinner;
  }

  set ripProgressSpinner( val: any ) {
    this._ripProgressSpinner = coerceBooleanProperty( val );
  }

  static ngAcceptBooleanType_libProgressSpinner: BooleanInput;

}
