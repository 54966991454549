import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { Util } from '../../../../../utils/util.service';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { GlobalState } from '../../../../../global.state';
import { UntypedFormGroup } from '@angular/forms';
import { faMoneyBillWave } from '@fortawesome/pro-light-svg-icons';

@Component( {
  selector: 'rip-cash-form',
  template: `
<!--   cash header-->
<div>
<div class="form-group">
    <div class="flexing space-between">
      <fa-icon [icon]="faMoneyBillWave" size="3x" class="manual-account-icon" style="margin-right: 5px; padding-bottom: 5px;"></fa-icon>
      <h3 class="manual-account-form-H3">Add the amount of physical dollar currency you have</h3>
    </div>
</div>
</div>
    <div class="form-group">
      <div>
        Fields with an * are required
      </div>
    </div>
    <div *ngIf="form">
      <form [formGroup]="form" #formElement>
        <div class="form-group">
          <mat-form-field appearance="outline">
            <label>
              <input matInput
                     formControlName="name"
                     [errorStateMatcher]="matcher"
                     placeholder="Account Name / Description*">
            </label>
            <mat-error *ngIf="form.controls.name.hasError('required' )">
              Account Name is required
            </mat-error>
            <mat-label>Account Name / Description</mat-label>
          </mat-form-field>
          
        </div>
        <!--<div class="form-group">
          <mat-form-field>
            <mat-select placeholder="Owner Type"
                        formControlName="owner_type"
                        [errorStateMatcher]="matcher">
              <mat-option value="Individual Account">Individual Account</mat-option>
              <mat-option value="Joint Account">Joint Account</mat-option>
              <mat-option value="Trust Account">Trust Account</mat-option>
            </mat-select>
          </mat-form-field>
        </div>-->
        <div class="form-group">
          <mat-form-field appearance="outline">
            <label>
              <input matInput
                     formControlName="value"
                     [errorStateMatcher]="matcher"
                     placeholder="Amount ($)*"
                     (change)="publicUtil.updateInputCurrencyFormat('value', form)">
            </label>
            <mat-error *ngIf="form.controls.value.hasError('required' )">
              Amount is required
            </mat-error>
            <mat-label>Amount ($)</mat-label>
          </mat-form-field>
        </div>
      </form>
    </div>

  `,
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class CashFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  faMoneyBillWave = faMoneyBillWave;

  publicUtil: typeof Util;

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account;

  subscriberName: string = 'cashForm';


  constructor( private _state: GlobalState ) {
    super();
    this.publicUtil = Util;
  }

  ngAfterViewInit(): void {
    // this.form.controls.value.disable(); will need to uncomment this once the formula for bond valuation is implemented
    // Logger.log( this.account );
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    if ( this.account && this.form ) {
      ManualAccountUtil.patchCashForm( this.form, this.account );

    } else {
      // since these aren't shown, but are still required, we need to set them to 1
      this.form.controls.price.setValue( 1 );
      this.form.controls.quantity.setValue( 1 );
      this.form.controls.name.setValue( 'Cash' );
      this.autofocus();
    }
  }

}
