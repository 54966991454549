<div class="flexing-row-only space-between with-gap">
  <div style="flex: 2;">
    <div class="flexing">
      <fa-icon [icon]="faLifeRing" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
      <h3 class="manual-account-form-H3" style="padding-bottom: 25px;">Add the details about your term life
        insurance</h3>

    </div>

    <div *ngIf="form">

      <div class="form-group">
        <div>
          Fields with an * are required
        </div>
      </div>

      <form [formGroup]="form">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <label>
              <input matInput
                     formControlName="name"
                     [errorStateMatcher]="matcher"
                     placeholder="Account Name / Description*">
            </label>
            <mat-error *ngIf="form.controls.name?.hasError('required')">
              Name is required
            </mat-error>
            <mat-label>Account Name / Description*</mat-label>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="outline">
            <label>
              <input matInput
                     formControlName="institution_name"
                     [errorStateMatcher]="matcher"
                     placeholder="Institution Name">
            </label>
            <mat-label>Institution Name</mat-label>
          </mat-form-field>
        </div>
        <div class="form-group">
          <rip-currency-input
                  [control]="form.controls.amount_of_insurance"
                  [stepSize]="10000"
                  [label]="'Life Insurance Amount'"
                  [placeholder]="'Life Insurance Amount*'"
                  [noNegative]="true"
                  (dataChange)="amountOfInsuranceChanged()">
          </rip-currency-input>
          <mat-error *ngIf="!form.controls.amount_of_insurance.pristine &&
                 form.controls.amount_of_insurance.hasError('currencyInputRequired')">
            Life Insurance Amount is required
          </mat-error>
          <mat-error *ngIf="!form.controls.amount_of_insurance.pristine &&
                form.controls.amount_of_insurance.hasError( 'currencyInputGreaterThanZero' )">
            Life Insurance Amount must be positive
          </mat-error>
        </div>
        <div class="horizontal-form-group">
          <div style="height: 65px; display: flex; align-items: start; justify-content: space-evenly;">
            <mat-radio-group aria-label="Select an option" formControlName="gender" (change)="genderChanged()"
                             style="display: flex; flex-direction: column; justify-content: space-between; height: 50px;">
              <mat-radio-button value="m">Male</mat-radio-button>
              <mat-radio-button value="f">Female</mat-radio-button>
            </mat-radio-group>
            <span matTooltip="Select the gender assigned at birth, purely for actuarial calculations"
                  matTooltipClass="mat-tooltip-custom" style="top: 15px; position: relative;">
            <fa-icon [icon]="faQuestionCircle" size="sm" class="info-icon"></fa-icon>
          </span>
          </div>
          <mat-form-field appearance="outline" style="flex: 1;">
            <mat-select (selectionChange)="setBirthDateFromHM($event)"
                        placeholder="Birth Date | Household Member"
                        formControlName="birth_date">
              <mat-option [value]="null">Birth Date | Household Member</mat-option>
              <mat-option [value]="householdMembersState?.investor.birth_date">
                {{ householdMembersState?.investor?.birth_date | ripDatePipe }}
                | {{ householdMembersState?.investor?.name }}
              </mat-option>
              <mat-option *ngFor="let member of householdMembersState?.householdMembers"
                          [value]="member.birth_date">
                {{ member.birth_date | ripDatePipe }} | {{ member.name }}
              </mat-option>
              <mat-option [value]="'add-new'">Add Household Member</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field appearance="outline" style="flex: 5;">
            <label>
              <input matInput
                     formControlName="birth_date"
                     [errorStateMatcher]="matcher"
                     name="birth_date"
                     placeholder="Birth Date (MM/DD/YYYY)*"
                     [matDatepicker]="birthDatePicker"
                     (change)="birthDateChanged()">
            </label>
            <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #birthDatePicker (closed)="birthDateChanged()"></mat-datepicker>
            <mat-error *ngIf="form.controls.birth_date?.hasError('invalidDate')">
              Birth Date must be in 'MM/DD/YYYY' format
            </mat-error>
            <mat-error *ngIf="form.controls.birth_date?.hasError('required')">
              Birth Date is required
            </mat-error>
            <mat-hint *ngIf="currentAge">
              Current Age: {{ currentAge | ripDateInYearAndMonth }}
            </mat-hint>
            <mat-label>Birth Date</mat-label>
          </mat-form-field>-->
        </div>
        <div class="horizontal-form-group">
          <mat-form-field appearance="outline" style="flex: 1;">
            <label>
              <input matInput
                     formControlName="effective_date"
                     [errorStateMatcher]="matcher"
                     name="effective_date"
                     placeholder="Effective Date (MM/DD/YYYY)*"
                     [min]="minEffectiveDate"
                     [matDatepicker]="effectiveDatePicker"
                     (change)="effectiveDateChanged()">
            </label>
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker (closed)="effectiveDateChanged()"></mat-datepicker>
            <mat-error *ngIf="form.controls.effective_date?.hasError('invalidDate')">
              Effective Date must be in 'MM/DD/YYYY' format
            </mat-error>
            <mat-error *ngIf="form.controls.effective_date?.hasError('required')">
              Effective Date is required
            </mat-error>
            <mat-error *ngIf="form.controls.effective_date?.hasError( 'endOfTermIsBeforeToday')">
              Effective Date plus Term must be after today
            </mat-error>
            <mat-label>Effective Date</mat-label>
            <mat-hint *ngIf="end_of_term">End of Term: {{ end_of_term | ripLongDatePipe }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline" style="flex: 1;">
            <label>
              <input matInput
                     (change)="termChanged()"
                     (keydown.enter)="ignoreEnter($event)"
                     formControlName="life_insurance_term"
                     [errorStateMatcher]="matcher"
                     [min]="1"
                     placeholder="Policy Term (Years)"
                     type="text">
            </label>
            <mat-error *ngIf="form.controls?.life_insurance_term?.hasError('required')">
              Term is required
            </mat-error>
            <mat-label>Term (Years)</mat-label>
            <mat-hint *ngIf="remaining_term">Remaining Term: {{ remaining_term | ripDateInYearAndMonth }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="horizontal-form-group">
          <!-- Only collecting this to have for now. It's not actually used just yet, but certainly could be in the future -->
          <mat-form-field appearance="outline" style="flex: 1;">
            <label>
              <input matInput
                     formControlName="premium"
                     [errorStateMatcher]="matcher"
                     name="premium"
                     placeholder="Annual Premium ($)*"
                     (keydown.tab)="tabPressed($event)"
                     (change)="premiumChanged()">
            </label>
            <mat-label>Annual Premium ($)*</mat-label>
          </mat-form-field>
        </div>
        <div class="flexing vertical-flex with-gap">
          <div class="explainer flexing-row-only space-between">
            <div>
              Fair Value of Remaining Policy:
            </div>
            <div>
              {{ fairValue | ripCurrencyPipe }}
            </div>

          </div>
          <div class="explainer flexing-row-only space-between">
            <div>
              Present Value of Premiums:
            </div>
            <div>
              {{ fairValueOfPremiums | ripCurrencyPipe }}
            </div>
          </div>
          <div class="explainer flexing-row-only space-between">
            <div>
              PV of Premiums Less Fair Value:
            </div>
            <div>
              {{ fairValueOfPremiums - fairValue | ripCurrencyPipe }}
            </div>
          </div>
        </div>
        <div class="explainer">
          Fair Value is the present value of the expected payoffs using probability of death in each year from
          actuarial life tables discounted at current Treasury rates during the length (term) of the policy. The
          fair value will be shown in your balance sheet and included in your net worth since term life insurance is
          an asset. This fair value is also useful to compare with an actual policy offer. The offer may be
          higher to include administrative costs and fees but maybe less for the diversification benefit the
          insurance company has across policyholders. It also may help in determining when you may want to consider
          self insuring as your wealth accumulation grows.

          <!-- possible rewrites:
          -
          Fair Value represents the current worth of expected payoffs, considering the probability of death in each
           year as per actuarial life tables. These payoffs are discounted at prevailing Treasury rates over the
           term of the policy. Fair value is reflected on your balance sheet and contributes to your net worth,
           as term life insurance is regarded as an asset. It serves as a benchmark for comparing against actual
            policy offers, which typically factor in administrative costs and fees, potentially resulting in higher
             quotes. However, the offer might be lower due to the diversification benefit enjoyed by the insurance
             company across policyholders. Additionally, understanding fair value can assist in determining the
             feasibility of self-insurance as your wealth accumulates.
             -

             Fair Value is the worth of expected payouts, based on the chance of passing away each year,
             discounted at current Treasury rates. It's shown on your balance sheet and boosts your net worth
             because term life insurance is seen as an asset. This value helps compare with actual policy offers,
             which might be higher due to added costs, or lower because of benefits from having many policyholders.
              It can also guide decisions about when to think about self-insurance as your wealth grows.
              -
             -->
        </div>
      </form>

    </div>

  </div>
  <div style="flex: 1;">
    <rip-insurance-calculator [matcher]="matcher"></rip-insurance-calculator>
  </div>
</div>
