export function getEnumKeys( eNum: any ): string[] {
  return Object.keys( eNum ).filter( ( v ) => isNaN( Number( v ) ) );
}

export enum MarketCalcTerms {
  long = 'long',
  short = 'short',
}

export enum BENCHMARK_PROXY_IDENTIFIERS {
  cash = 'CASH',
  stocks = 'STOCKS',
  bonds = 'BONDS',
}

export enum SCREENER_TAB_NAMES {
  stocks = 'stocks',
  bonds = 'bonds',
  moneyMarket = 'moneyMarket',
  byFundName = 'byFundName',
}

export enum INVESTMENT_EXPERIENCES {
  circle = 'circle',
  square = 'square',
  diamond = 'diamond',
}

export enum EXPECTED_VALUE_SCALES {
  yearsFromToday = 'yearsFromToday',
  calendarYears = 'calendarYears',
  age = 'age',
}

export enum USER_GOAL_FORM_TYPES {
  generic = 'generic',
  retirement = 'retirement',
  noWithdrawal = 'no-withdrawal',
}

export enum USER_GOAL_TYPE_IDS {
  wedding = 'wedding',
  education = 'education',
  vacation = 'vacation',
  retirement = 'retirement',
  real_estate = 'real-estate',
  vehicle = 'vehicle',
  home_improvement = 'home-improvement',
  emergency_fund = 'emergency-fund',
  large_party = 'large-party',
  other = 'other',

}

export enum USER_GOAL_WITHDRAWAL_SCHEDULE_TYPES {
  once = 'once',
  schedule = 'schedule',
}

export enum EVENT_NAMES {
  // TODO: PUT ALL EVENT NAMES HERE SO THERE ISN'T CONFUSION WHEN ADDING A SUBSCRIPTION
  LOGGED_IN = 'logged.in',
  LOGOUT = 'logout',
  SESSION_RENEWED_BY_USER = 'session.renewed.by.user',
  REGISTRATION_COMPLETED = 'registration.completed',
  SELECTED_EXPECTED_VALUE_RECALCULATED = 'selected.expected.value.recalculated',
  EXPECTED_WEALTH_BUCKETS_RECALCULATED = 'expected.wealth.buckets.recalculated',
  WEALTH_CHART_SCROLLED = 'wealth.chart.scrolled',
  TOKENS_RENEWED = 'tokens.renewed',
  STRIPE_PRODUCTS_RETRIEVED = 'stripe.products.retrieved',
  MOBILE_LAYOUT_LOADED = 'mobile.layout.loaded',
  MOBILE_GO_TO_HOLDINGS = 'mobile.go.to.holdings',
  MOBILE_GO_TO_MY_PLAN = 'mobile.go.to.my.plan',
  ACCOUNT_MANAGER_REFRESH_COMPLETE = 'account.manager.refresh.complete',
  SUBSCRIPTION_CHANGED = 'sub.changed',
  MANUAL_ACCOUNT_DELETED = 'manual.account.deleted',
  OPEN_MOBILE_MANUAL_ACCOUNT_MANAGER = 'open.mobile.manual.account.manager',
  OPEN_MOBILE_ROW_DETAIL = 'open.mobile.rowDetail',
  OPEN_MOBILE_ACCOUNT_DETAILS = 'open.mobile.account.details',
  OPEN_MOBILE_ONE_DAY_BREAKDOWN = 'open.mobile.one.day.breakdown',
  OPEN_MOBILE_DISCLAIMERS = 'open.mobile.disclaimers',
  OPEN_MOBILE_CHARTS = 'open.mobile.charts',
  OPEN_MOBILE_FASTLINK = 'open.mobile.fastlink',
  RECALC_REAL_VH = 'recalc.real-vh',
  EDITING_CHANGED = 'editing.changed',
  WARNING_NOTIFICATION = 'warning.notification',
  NEWS_NOTIFICATION = 'news.notification',
  UPDATED_ACCOUNT_RETRIEVED = 'updated.account.retrieved',
  POSITION_REVISED = 'position.revised',
  PRICES_UPDATED = 'prices.updated',
  PROGRESS_UPDATE = 'progress.update',
  WIDGET_RESIZE = 'widget.resize',
  ADVISOR_FEE_UPDATED = 'advisor.fee.updated',
  ACCOUNT_VISIBILITY_CHANGED = 'account.visibility.changed',
  START_REVISION = 'start.revision',
  DETECT_ACCOUNT_CHANGES = 'detect.account.changes',
  EXPAND_ACCOUNT = 'expand.account',
  GO_TO_OPTIMIZER_TIPS = 'go.to.optimizer.tips',
  ACCOUNTS_LOADING_ERROR = 'accounts.loading.error',
  MANUAL_ACCOUNT_CREATED = 'manual.account.created',
  MANUAL_ACCOUNT_UPDATED = 'manual.account.updated',
  REVISION_CREATED = 'revision.created',
  MENU_IS_COLLAPSED = 'menu.isCollapsed',
  COLUMN_SET_CREATED = 'column.set.created',
  CHANGE_INFO_TYPE = 'change.infoType',
  POSITION_OVERRIDDEN = 'position.overridden',
  PROCESS_DATA_QUALITY_ISSUES = 'process.data.quality.issues',
  USER_BENCHMARK_LOADED = 'user.benchmark.loaded',
  USER_BENCHMARK_SAVED = 'benchmark.saved',
  RECALCULATE_ALLOCATIONS = 'recalculate.allocations',
  TREASURIES_RETRIEVED = 'treasuries.retrieved',
  ERROR_RETRIEVING_TREASURY_DATA = 'error.retrieving.treasury.data',
  OPEN_ACCOUNT_MANAGEMENT = 'open.account.management.dialog',
}

export enum MOBILE_PAGE_TITLES {
  CONNECT_ACCOUNTS = 'Connect Accounts',
  ADD_MANUAL_ACCOUNTS = 'Add Manual Accounts',
  BALANCE_SHEET = 'Balance Sheet',
  DASHBOARD = 'Dashboard',
  HOLDINGS = 'Holdings',
  TRANSACTIONS = 'Transactions',
  ONBOARDING = 'Onboarding',
  MY_PLAN = 'My Plan',
  PROFILE = 'Profile',
  LOGOUT = 'Logout',
}

export enum IDLE_STATES {
  NOT_STARTED = 'Not Started',
  ACTIVE = 'Active',
  IDLE = 'Idle',
  WILL_TIMEOUT = 'You have been idle for a while, so you will be signed out in ',
  TIMED_OUT = 'You have been signed out due to inactivity.',
}

export enum HOUSEHOLD_MEMBER_RELATION {
  partner = 'partner',
  child = 'child',
  sibling = 'sibling',
  parent = 'parent',
  other = 'other',
}

export enum FEATURE_NAMES {
  REVISION = 'revision',
  OPTIMIZER = 'optimizer',
  WORKSPACES = 'workspaces',
}

export enum PAGE_NAMES {
  PROFILE = 'Profile',
  ACCOUNTS = 'Wealth Portfolio', // old name: 'Accounts',
  HOLDINGS = 'Holdings',
  BALANCE_SHEET = 'Balance Sheet',
  MARKET_INFORMATION_DASHBOARD = 'Markets',
  TRANSACTIONS = 'Transactions',
  PLAN_BENCHMARK = 'My Plan/Benchmark',
  GOALS = 'Goals',
  WORKSPACES = 'Workspaces',
}

export enum FeeEditor {
  PERCENT,
  DOLLAR,
}

export type ConnectionSyncOption = 'DAILY' | 'WEEKLY' | 'NONE';

export enum GoalColors {
  Red = '#F44336',
  Pink = '#E91E63',
  Purple = '#9C27B0',
  DeepPurple = '#673AB7',
  Indigo = '#3F51B5',
  Blue = '#2196F3',
  LightBlue = '#03A9F4',
  Cyan = '#00BCD4',
  Teal = '#009688',
  Green = '#4CAF50',
  Lime = '#CDDC39',
  // Yellow = "#FFEB3B",
  Amber = '#FFC107',
  Orange = '#FF9800',
  DeepOrange = '#FF5722',
}
