import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

declare const TradingViewEmbed: any;

@Component( {
  selector: 'rip-trading-view-crypto-markets',
  template: `
    <!--      <div id="fundamentals_{{ticker}}" style="height: 300px;"></div>-->
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container" #containerDiv>
      <div class="tradingview-widget-container__widget"></div>

    </div>
    <div class="tradingview-widget-copyright">
      <a (click)="goToTradingView()" class="rip-link">
        <span class="blue-text">Cryptocurrency Markets</span>
      </a> by TradingView
    </div>
    <!-- TradingView Widget END -->
  `,
} )

export class TradingViewCryptoMarketsComponent implements AfterViewInit {

  fundamentals: any;
  @Input() ticker: string = 'AAPL';
  settings: any = {};

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  constructor( private _elRef: ElementRef ) {
  }

  goToTradingView() {
    Util.openExternalUrl( `https://www.tradingview.com/markets/cryptocurrencies/prices-all/` );
  }

  ngAfterViewInit() {
    setTimeout( () => {


      this.settings = {
        height: 500,
        defaultColumn: 'overview',
        screener_type: 'crypto_mkt',
        displayCurrency: 'USD',
        colorTheme: GlobalState.tradingViewColorTheme,
        autosize: true,
        locale: 'en',
      };
      const script = document.createElement( 'script' );
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
      script.async = true;
      script.innerHTML = JSON.stringify( this.settings );
      this.containerDiv.nativeElement.appendChild( script );
      const brandingDiv = document.createElement( 'div' );
      brandingDiv.innerHTML = `
        <div class="tradingview-widget-copyright">
            <a (click)="goToTradingView()">
                <span class="blue-text">Cryptocurrency Markets</span>
            </a> by TradingView
        </div>
    `;
      // this.containerDiv.nativeElement.appendChild( brandingDiv );
    } );
  }

}

/*
* <!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/markets/cryptocurrencies/prices-all/"
  * rel="noopener" target="_blank"><span class="blue-text">Cryptocurrency Markets</span></a> by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-screener.js" async>
  {
  "width": 1000,
  "height": 490,
  "defaultColumn": "overview",
  "screener_type": "crypto_mkt",
  "displayCurrency": "USD",
  "colorTheme": "dark",
  "locale": "en"
}
  </script>
</div>
<!-- TradingView Widget END -->
* */

