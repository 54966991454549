import { RouterModule, Routes } from '@angular/router';
import { HoldingsPageComponent } from './holdings-page.component';

const routes: Routes = [
  {
    path: '',
    component: HoldingsPageComponent,
  },
];
export const routing = RouterModule.forChild( routes );
