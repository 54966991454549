import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpectedWealthBucket } from '../../utils/dataInterfaces';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { BenchmarkState } from '../../utils/benchmark.state';

@Component( {
  selector: 'rip-wealth-plan-analysis',
  template: `

    <div class="flexing vertical-flex space-between" style="height: 100%;">
      <div class="analysis-container">
        <div class="ripsaw-card-header">
          Analysis
        </div>
        <div *ngIf="bucket?.issues?.length > 0" class="flexing vertical-flex space-between">
          <div *ngFor="let issue of bucket?.issues" class="expected-value-analysis-issue">
            <div class="analysis-header">
              <fa-icon [icon]="faExclamationTriangle" fixedWidth size="sm" class="{{ issue?.issueClass }}"></fa-icon>
              {{ issue?.type }}
            </div>
            <!--            <div>{{ issue.text }}</div>-->
            <div class="analysis-body">
              <ol class="numbered-list">
                <li *ngFor="let instruction of issue?.instructions" class="{{issue.issueClass}}">
                  <span [innerHTML]="instruction?.text"></span>
                  <ol *ngIf="instruction.instructions" class="{{ instruction.useStyledList ? 'lettered-list' : 'un-styled-list'}}">
                    <li *ngFor="let innerInstruction of instruction?.instructions" class="{{issue.issueClass}}"
                        [innerHTML]="innerInstruction?.text">
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div *ngIf="bucket?.issues?.length === 0" class="expected-value-analysis-issue">
          <div class="analysis-header">
            <fa-icon [icon]="faCheckCircle" fixedWidth size="sm" class="analysis-issue-success"></fa-icon>
            <span>Good news! </span>
          </div>
          <div class="analysis-body">
            <ol>
              <li class="analysis-issue-success">
                Your goals have been met through retirement with a
                {{ benchmarkState?.benchmark?.probability | ripPercentPipe : '0-0' }}
                downside risk probability of having {{ bucket?.stats?.portfolioEnd?.downsideRisk | ripCurrencyPipe }}
                at the end of retirement.
              </li>
              <li class="analysis-issue-success">
                If you are comfortable with this strategy, continue to Save My Wealth Plan. If you want to hedge against
                other unforeseen risks and/or increase your expected wealth bequest, consider the combination of annual
                contribution increases before retirement, delaying retirement, the reduction of withdrawals in
                retirement and a <b><i>suitable</i></b> expected wealth versus risk tradeoff to achieve more comfort.
              </li>
              <li class="analysis-issue-success">
                <u>Remember:</u> it is your preferred <b><i>joint</i></b> decision of portfolio size, annual
                contributions/savings, net annual withdrawals (retirement income), retirement date, length of retirement
                <b><i>and</i></b> an expected wealth versus risk tradeoff that is required to achieve your lifetime
                financial goals.
              </li>
              <li class="analysis-issue-success">
                <u>Note</u> that preferences in the risk dimension are most influenced by your choice of the probability
                of
                downside risk outcomes (degree of risk aversion) and choice among the expected wealth versus risk
                tradeoffs currently available in the cash, bond, and stock markets. The other profile decisions mainly
                affect your level of expected wealth over time. Given a level of expected wealth at any time in the
                future, risk is defined as the probability distribution of deviations from that expected wealth at that
                time.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="flexing space-around" *ngIf="showButton">
        <button mat-raised-button (click)="bucketChosen.emit(bucket)">
          Select Starting Point
        </button>
      </div>
    </div>

  `,
  styleUrls: [ './wealth-plan-analysis.component.scss' ],
} )
export class WealthPlanAnalysisComponent {

  faExclamationTriangle = faExclamationTriangle;
  faCheckCircle = faCheckCircle;
  faInfoCircle = faInfoCircle;

  @Input() bucket: ExpectedWealthBucket;
  @Input() showButton: boolean = true;

  @Output() bucketChosen: EventEmitter<ExpectedWealthBucket> = new EventEmitter<ExpectedWealthBucket>();

  constructor( public benchmarkState: BenchmarkState ) {

  }
}
