import { Component, Input } from '@angular/core';
import { GlobalState } from '../../global.state';

@Component( {
  selector: 'rip-markets-overview',
  template: `
    <div id="market-info-dashboard-header" class="flexing space-between" *ngIf="!deviceIsMobile"
         [ngClass]="{'vertical-flex': !minimized}">
      <nb-card style="flex: 1; max-height: 33rem; padding-top: 10px; margin-bottom: 10px;">
        <div>
          <div class="flexing vertical-flex space-between market-info-ticker-tape" [hidden]="size !== 'medium'"
               style="max-height: 6.5rem;">
            <rip-rss-app-ticker></rip-rss-app-ticker>
            <rip-trading-view-ticker-tape [symbols]="marketSymbols"
                                          style="flex: 2;"></rip-trading-view-ticker-tape>
          </div>
          <div class="flexing space-between market-info-ticker-tape" [hidden]="size !== 'large'"
               *ngIf="hasBeenExpanded">
            <rip-trading-view-market-overview style="flex: 1;">
            </rip-trading-view-market-overview>

            <rip-rss-app-wall style="flex: 4; overflow-y: scroll; max-height: 100%;">

            </rip-rss-app-wall>
          </div>
        </div>
        <div id="expand-overview" *ngIf="size === 'small'">
          <div class="expand-container" matTooltip="Expand Market Overview" matTooltipClass="mat-tooltip-custom">
            <a style="width: 100%;" class="rip-link" (click)="size = 'medium';">
              <mat-icon>expand_more</mat-icon>
            </a>
          </div>
        </div>
        <div id="expand-overview" *ngIf="size === 'medium'">
          <div class="expand-container" matTooltip="Collapse Market Overview" matTooltipClass="mat-tooltip-custom">
            <a (click)="size = 'small';" class="rip-link">
              <mat-icon>expand_less</mat-icon>
            </a>
          </div>
          <div class="expand-container" matTooltip="Expand Market Overview" matTooltipClass="mat-tooltip-custom">
            <a (click)="size = 'large'; hasBeenExpanded = true;" class="rip-link">
              <mat-icon>expand_more</mat-icon>
            </a>
          </div>
        </div>
        <div id="expand-overview" *ngIf="size === 'large'">
          <div class="expand-container" matTooltip="Collapse Market Overview" matTooltipClass="mat-tooltip-custom">
            <a style="width: 100%;" (click)="size = 'medium';" class="rip-link">
              <mat-icon>expand_less</mat-icon>
            </a>
          </div>
        </div>
      </nb-card>

    </div>

    <div *ngIf="deviceIsMobile" id="market-info-dashboard-header">
      <rip-trading-view-market-overview></rip-trading-view-market-overview>
    </div>

    <div id="market-info-dashboard-title">
      Market Analysis
    </div>
  `,
} )

export class MarketsOverviewComponent {

  @Input() deviceIsMobile: boolean = false;

  minimized: boolean = true;
  // small - only expansion line | medium - tickers and expansion and collapse lines side by side | large - expanded widgets with one collapse line
  size: string = 'medium';
  hasBeenExpanded: boolean = false;
  // deviceIsMobile: boolean = false; // dont' actually need this because we aren't going to show this on mobile

  marketSymbols = GlobalState.marketInfoTickerTapeSymbols;
  /*
    constructor( private _detectorService: DeviceDetectorService ) {
      this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );
    }*/
}
