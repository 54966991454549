import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Util } from '../../../utils/util.service';
import { environment } from '../../../../environments/environment';
import { ManualAccountFormComponent } from './manualAccountFormInterface';
import { ManualAccountManagerState } from '../../../utils/manualAccountManager.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup } from '@angular/forms';
import { ExistingManualAccountForm } from '../../../utils/dataInterfaces';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../shared/workspace-permission';
import { AppStoreService } from '../../../store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AccountManager } from '../../../utils/accountManager';
import { Subject } from 'rxjs';
import { GlobalState } from '../../../global.state';

@Component( {
  selector: 'rip-manual-account-manager',
  templateUrl: './manualAccountManager.component.html',
  styleUrls: [ './manualAccountManager.scss' ],
} )
export class ManualAccountManagerComponent extends WithWorkspacePermission implements OnInit, OnDestroy, AfterViewInit {

  private readonly onDestroy = new Subject<void>();

  @ViewChildren( MatExpansionPanel ) expansionPanels: QueryList<MatExpansionPanel>;
  @ViewChildren( 'existingForm' ) existingFormComponents: QueryList<ManualAccountFormComponent>;

  @Input() accountToOpen: string;

  ngAfterViewInit(): void {

    setTimeout( () => {
      if ( this.accountToOpen ) {
        this.mamState.openAccountPanel( this.accountToOpen );
      }
    }, 1000 );
  }


  publicUtil: typeof Util;
  deviceIsMobile: boolean = false;
  dataRetrieved: boolean = false;
  subscriberName: string = 'ManualAccountManagerComponent';
  inWealthFluent: boolean = false;

  constructor( private _accountManager: AccountManager,
               public mamState: ManualAccountManagerState,
               public snackBar: MatSnackBar,
               private _deviceDetector: DeviceDetectorService,
               private appStoreService: AppStoreService,
               private _state: GlobalState,
  ) {
    super();
    this.inWealthFluent = this._state.globalVars.inWealthFluent;
    this._accountManager.allDataRetrieved
      .pipe( takeUntil( this.onDestroy ) ).subscribe( {
      next: ( val: boolean ) => {
        this.dataRetrieved = val;
      },
    } );
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_manualAccountManager' ] = this;
    }
    if ( this.mamState.manualAccounts.length > 0 ) {
      this.dataRetrieved = true;
    }
    // this.saveNewButtonOptions.disabled = !this.newAccountForm.valid;
    this.publicUtil = Util;
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._deviceDetector );

  }

  ngOnInit(): void {
    this.restrictByPermissions();
  }


  ngOnDestroy(): void {
    const accountIds = this.mamState.manualAccounts.map( el => el.account_id );
    accountIds.forEach( id => {
      const item = this.getFormItem( id );
      item.form.enable();
    } );
    this.onDestroy.next();
  }

  updateManualAccount( account: Account, form: UntypedFormGroup ) {
    this.mamState.showSpinner();
    this.mamState.updateManualAccount( account, form, ( err ) => {
      if ( err ) {
        console.error( err.err );
        this.snackBar.open( `Error updating account. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
      }
      this.mamState.hideSpinner();
    } );
  }

  private restrictByPermissions(): void {
    this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        filter( Boolean ),
        take( 1 ),
      )
      .subscribe( allPerms => {
        const accountIds = this.mamState.manualAccounts.map( el => el.account_id );
        const hasAccountSettingView = hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNT_SETTINGS_VIEW ] );
        const hasAccountView = hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNTS_VIEW ] );

        accountIds.forEach( id => {
          const item = this.getFormItem( id );

          if ( hasAccountSettingView ) {
            item.form.controls.included.disable();
          }

          if ( hasAccountView ) {
            for ( const name in item.form.controls ) {
              if ( name !== 'included' ) {
                item.form.controls[ name ].disable();
              }
            }
          }
        } );
      } );
  }

  private getFormItem( id: string ): ExistingManualAccountForm {
    return this.mamState.existingAccountForms[ id ];
  }


}

