import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utils/util.service';

@Component( {
  selector: 'rip-mobile-logo-link',
  template: `
    <a (click)="openHomepage()">
      <img src="{{smallLogoUrl}}" class="mobile-logo"/>
    </a>
  `,
  styles: [ `
    .mobile-logo {
      height: 30px;
      margin-left: 5px;
    }
  ` ],
} )

export class MobileLogoLinkComponent {

  smallLogoUrl: string = '';
  website: string = environment.common.website;

  constructor() {
    this.smallLogoUrl = environment.common.smallLogoUrl;
  }

  openHomepage() {
    Util.openExternalUrl( this.website );
  }
}
