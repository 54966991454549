import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormGroup } from '@angular/forms';
import { ManualAccountFormsHelper } from '../../manualAccountFormsHelper';

@Component( {
  selector: 'rip-bond-select-boxes',
  template: `
    <div [formGroup]="form">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Bond Credit Quality"
                      formControlName="credit_quality"
                      panelClass="max-select-box-height"
                      [errorStateMatcher]="matcher">
            <mat-option *ngFor="let option of bondCreditQualitySelectOptions"
                        value="{{option.value}}">{{option.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Bond Sector"
                      formControlName="sector"
                      panelClass="max-select-box-height"
                      [errorStateMatcher]="matcher">
            <mat-option *ngFor="let option of bondSectorSelectOptions"
                        value="{{option.value}}">{{option.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-select placeholder="US/Non US"
                      formControlName="us_non_us"
                      [errorStateMatcher]="matcher">
            <mat-option value="us">US</mat-option>
            <mat-option value="non_us">Non US</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="form.controls.us_non_us.value === 'non_us'">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Non US Developed/Emerging Markets"
                      formControlName="emerging_markets"
                      [errorStateMatcher]="matcher">
            <mat-option value="developed">Non US Developed</mat-option>
            <mat-option value="emerging">Emerging Markets</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  `,
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class BondSelectBoxesComponent {

  @Input() matcher: ErrorStateMatcher;
  @Input() form: UntypedFormGroup;
  @Input() config: any = {};


  bondSectorSelectOptions: any = ManualAccountFormsHelper.getBondSectorSelectOptions();
  bondCreditQualitySelectOptions: any = ManualAccountFormsHelper.getBondCreditQualitySelectOptions();

}
