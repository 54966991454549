import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { Util } from '../../../utils/util.service';

export interface Definition {
  title: string;
  def: string;
}

@Component( {
  template: `
    <div *ngIf="!deviceIsMobile">

      <h3 mat-dialog-title>Glossary</h3>
      <mat-divider class="modal-header-divider"></mat-divider>
      <div mat-dialog-content class="modal-body">
        <ng-container *ngTemplateOutlet="glossaryTemplate"></ng-container>
      </div>
      <mat-divider class="modal-footer-divider"></mat-divider>
      <div mat-dialog-actions>
        <button mat-button (click)="dialogRef.close()">Close</button>
      </div>

    </div>

    <div *ngIf="deviceIsMobile">
      <ng-container *ngTemplateOutlet="glossaryTemplate"></ng-container>
    </div>

    <ng-template #glossaryTemplate>
      <div class="glossary-container">
        <mat-list #glossaryList>
          <mat-list-item *ngFor="let definition of definitions; let i = index" id="definition_{{i}}"
                         class="definition-item">
            <rip-definition [definition]="definition" [highlight]="i === definitionToHighlight"></rip-definition>
          </mat-list-item>
        </mat-list>
      </div>
    </ng-template>
  `,
  styleUrls: [ './glossary.component.scss' ],
} )

export class GlossaryComponent implements OnInit, AfterViewInit {

  deviceIsMobile: boolean = false;
  webKitHandlerAvailable: boolean = false;

  definitionToHighlight: number;

  appName: string = environment.appName;
  companyName: string = environment.common.companyName;

  constructor( private _elRef: ElementRef,
               private _cd: ChangeDetectorRef,
               private _detectorService: DeviceDetectorService,
               public dialogRef: MatDialogRef<GlossaryComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any ) {
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_glossary' ] = this;
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    this.webKitHandlerAvailable = Util.isWebkitHandlerAvailable();
    if ( this.data ) {
      this.definitionToHighlight = this.data.definitionToHighlight;
    }
  }

  ngOnInit() {
    if ( this.deviceIsMobile ) {
      setTimeout( () => {
        this.checkForHighlight();
      }, 500 );
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.checkForHighlight();
    } );
  }

  checkForHighlight() {
    if ( this.definitionToHighlight >= 0 ) {
      setTimeout( () => {
        const item = this._elRef.nativeElement.ownerDocument.getElementById( `definition_${ this.definitionToHighlight }` );
        if ( item ) {
          item.scrollIntoView( { behavior: 'smooth' } );
        }
      }, 500 );
    }
    this.doChanges();
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    this._cd.reattach();
  }

  openExternal( url: string ) {
    Util.openExternalUrl( url );
  }

  definitions = [
    // index 0
    {
      def: `The ${ this.appName } Optimizer™ is a powerful tool to help you manage revision decisions yourself. Results of the ${ this.appName } Optimizer™ are not to be viewed as financial advice under any circumstances. The feasibility of solutions provided via the optimization algorithm is dependent on the quality of inputs and compatibility of constraints. Always make sure that any solution offered by this mathematical optimization makes investment and financial sense before implementing at your financial institution(s).`,
      title: `${ this.appName } Optimizer`,
    },
    {
      // index 1
      def: 'Each of the 56 risk dimensions that define the risk composition of the bond and stock sub portfolios and the aggregate cash, bond and stock composition.',
      title: 'Risk Exposure',
    },
    {
      // index 2
      def: 'Each risk exposure’s percentage deviation in the current portfolio (before revision) from that of the custom benchmark.',
      title: 'Current Deviation',
    },
    {
      // index 3
      def: 'Optimization results for each risk exposure’s percentage deviation from that in the custom benchmark.',
      title: 'Minimized Deviation (%)',
    },
    {
      // index 4
      def: 'Optimization results for each risk exposure’s dollar deviation from that in the custom benchmark.',
      title: 'Minimized Deviation ($)',
    },
    // index 5
    {
      def: 'The percentage reduction in aggregate deviations from the benchmark is a measure of the benefit of moving from the current portfolio to the optimized portfolio. A 100% value would be a perfect match by eliminating all deviations.',
      title: 'Reduction in Deviation',
    },
    // index 6
    {
      def: `Expected Remaining Life for annuities is calculated using this 
<a href="https://www.ssa.gov/OACT/STATS/table4c6.html" class="rip-link" target="_blank">
actuarial table
</a>
 from the Social Security`,
      title: 'Expected Remaining Life',
    },
    // index 7
    {
      def: `Entering a negative number for annualized cost (i.e., maintenance) will be included in your wealth portfolio dashboard for budgeting purposes. A positive annualized cash flow number can be used for income generating property. This is essentially a user discretion annual net income/loss available for aggregation.`,
      title: 'Annual Cash Flow',
    },
    // index 8
    {
      def: `Annual Cash Flow divided by Market Value.`,
      title: 'Real Asset Yield',
    },
    // index 9
    {
      def: `The Treasury Yield Curve provides current nominal U.S. government borrowing rates for a range of maturities from 1 Month to 30 Years. Comparing yields of different maturities provides valuable information concerning the market’s view of the path of future yields (forward rates) and expected inflation.`,
      title: 'Treasury Yield Curve',
    },
    // index 10
    {
      def: `The annual yield increase/decrease from investing in the next highest maturity issue.`,
      title: 'Incremental Compensation (Fixed Income)',
    },
    // index 11
    {
      def: `A measure of interest rate risk. It’s an estimate of the rate of return response for a 1% change in the level of yield, Rate of Return = - ModDuration*Change in Yield.`,
      title: 'Modified Duration (Fixed Income)',
    },
    // index 12
    {
      def: `(standard deviation) a measure of dispersion of yields around its average.`,
      title: 'Yield Volatility (Fixed Income)',
    },
    // index 13
    {
      def: `(standard deviation) is a risk measure. It is the dispersion of rates of return around its average. It combines the yield volatility with the sensitivity to changes in yield (Modified Duration). Return Volatility = - ModDuration*Yield Volatility.`,
      title: 'Return Volatility (Fixed Income)',
    },
    // index 14
    {
      def: `Provides a measure of expected risk-adjusted performance. It is the expected excess yield for a specific maturity less that of the 1 Month Yield per unit of its Return Volatility. Expected Sharpe Ratio = (Yield for a specific maturity - 1 Month Treasury Yield)/Return Volatility.`,
      title: 'Expected Sharpe Ratio (Fixed Income)',
    },
    // index 15
    {
      def: `Expected Return on each sector of the bond market is the sum of its duration-matched Treasury yield (compensation for interest rate risk) plus the ${ this.appName } model of expected risk premia (compensation) for prepayment and/or default risk. Expected Return = Duration-Matched Treasury Yield + Risk Premia.`,
      title: 'Expected Return (Fixed Income)',
    },
    // index 16
    {
      def: `An approximation of interest rate risk. That is the return sensitivity to a change in the level of interest rates (proxied by changes in the 10-year Treasury yield). Government funds only have interest rate risk. Agency Mortgage-Backed Securities (MBS) also have prepayment risk. Corporate bonds also have default risk. The US IG (Investment Grade) fund contains Treasury, MBS and Corporate bonds with all three risk factors: interest rate, prepayment and default.`,
      title: 'Effective Duration',
    },
    // index 17
    {
      def: `Contains all risk dimensions in a sector fund.`,
      title: 'Total Volatility',
    },
    // index 18
    {
      def: `Expected Sharpe Ratio provides a measure of expected risk-adjusted performance. It is the expected return for a fund less that of the 1 Month Treasury Yield per unit of its Total Volatility (Risk).  Expected Sharpe Ratio = (Expected Return - 1 Month Treasury Yield)/Total Volatility.`,
      title: 'Expected Sharpe Ratio (Fixed Income)',
    },
    // index 19
    {
      def: `Market value is the price an asset would fetch in the marketplace, or the value that the investment community gives to a particular equity or business.`,
      title: 'Market Value',
    },
    // index 20
    {
      def: `Downside Risk and upside potential are the expected wealth outcomes conditional on being in the lower tail or upper tail, respectively, of the probability distribution of wealth outcomes. The size of the tail of the distribution is defined by the choice of probability (i.e., 1%, 5% or 10%). Other common names for this measure are conditional tail expectation and expected shortfall.`,
      title: 'Downside Risk and Upside Potential ',
    },
    // index 21
    {
      def: `Expected Wealth gives you a look at future expected returns for your wealth portfolio. At any future date from now, T, wealth outcomes <img src="assets/img/app/plan/math-expectedwealth-1.svg"> depend on the compounding of each wealth portfolio rate of return, <img src="assets/img/app/plan/math-expectedwealth-2.svg"> , along the way. The probability distribution of wealth outcomes is described by its expected wealth measure of central tendency, <img src="assets/img/app/plan/math-expectedwealth-3.svg"> , and standard deviation of wealth from expected wealth as a measure of risk, <img src="assets/img/app/plan/math-expectedwealth-4.svg">. `,
      title: 'Expected Wealth',
    },
    // index 22
    {
      def: `Years in retirement is the estimated length of your retirement (Retirement Date to End of life). The initial calculated value is Expected Remaining Life*1.25(using this 
<a href="https://www.ssa.gov/OACT/STATS/table4c6.html" class="rip-link" target="_blank">actuarial table)</a> - Retirement Age.`,
      title: 'Years in Retirement',
    },
    // index 23
    {
      def: 'The term investment strategy refers to a set of principles designed to help an investor achieve their financial and investment goals. This plan is what guides an investors decisions based on goals, risk tolerance, and future needs for capital.',
      title: 'Investment Strategy',
    },
    // index 24
    {
      def: 'Protect your money and avoid capital loss inside your portfolio',
      title: 'Investment Strategy - Preservation',
    },
    // index 25
    {
      def: 'Focus on low risk to principal and investment income',
      title: 'Investment Strategy - Conservative',
    },
    // index 26
    {
      def: 'Seek a balance between capital preservation and growth with moderate risk',
      title: 'Investment Strategy - Moderate',
    },
    // index 27
    {
      def: 'Invest more in stocks to increase portfolio expected return but comes with more than moderate risk',
      title: 'Investment Strategy - Growth',
    },
    // index 28
    {
      def: 'Very high risk strategy to maximize portfolio expected return',
      title: 'Investment Strategy - Aggressive',
    },
    // index 29
    {
      def: 'A benchmark is a standard against which the performance of an investment portfolio (and its manager) can be measured. A benchmark is a basket of investable securities in the form of a strategic asset allocation.',
      title: 'Benchmark (Strategic Asset Allocation)',
    },
    // index 30
    {
      def: 'A portfolio is a collection of financial investments like stocks, bonds, commodities, cash, and cash equivalents, including closed-end funds and exchange traded funds (ETFs). Generally containing stocks, bonds, and cash comprise the core of a portfolio. A larger wealth portfolio (all assets and liabilities) can also include a wide range of assets including real estate, art, and private investments.',
      title: 'Investment Portfolio',
    },
    // Index 31
    {
      def: 'A stock is a security that represents a fractional ownership in a company. When you buy a company`s stock, you`re purchasing a small piece of that company, called a share.',
      title: 'Stock',
    },
    // Index 32
    {
      def: 'A bond is a fixed-income instrument that represents a loan made by an investor to a borrower (typically corporate or governmental). A bond could be thought of as an I.O.U. between the lender and borrower that includes the details of the loan and its payments. Bonds are used by companies, municipalities, states, and sovereign governments to finance projects and operations. Owners of bonds are debt holders, or creditors, of the issuer.',
      title: 'Bond',
    },
    // Index 33
    {
      def: 'In portfolio management, a benchmark is a standard against which the performance of a portfolio is measured. A benchmark represents a particular set of the market or asset classes and serves as a reference point for evaluating the performance of a portfolio.',
      title: 'Benchmark',
    },
    // Index 34
    {
      def: 'Wealth management involves constructing, monitoring, and revising investments that satisfy your objectives. A convenient way to summarize your investment objectives is your unique benchmark (strategic asset allocation).  This benchmark should be a low cost, well-diversified, readily obtainable investment strategy requiring no special information that is consistent with your investment objectives.  Given the variety of account restrictions and differential tax treatments, it is unlikely that you can or want to match your benchmark exactly in each account, but you can get close at the aggregate wealth portfolio level. Getting close is how you can avoid uncompensated risk and unintended risk exposures',
      title: 'Benchmark Asset Allocation',
    },
    // Index 35
    {
      def: `Benchmark composition refers to the specific components or holdings that make up a benchmark. In the context of investment management, a benchmark composition is a list of securities or other assets that are included in a particular benchmark. \n 
<p>In ${ this.appName } your benchmark is composed of ETFs or other securities representing various parts of the market.</p>`,
      title: 'Benchmark Composition',
    },


    /*

     {
     // index 0
     def: '',
     title: '',
     },
     * */
  ];

}
