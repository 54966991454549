import { Component } from '@angular/core';

@Component({
  selector: 'rip-holdings-page',
  template: `
    <rip-holdings></rip-holdings>
  `,
})
export class HoldingsPageComponent {

}