import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { DisclaimersComponent } from '../../../pages/modals/disclaimers/disclaimers.component';
import { GlobalState } from '../../../global.state';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component( {
  selector: 'rip-mobile-disclaimers',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">Disclaimers</div>
      <span><button mat-raised-button (click)="closeMobileDisclaimers()" class="mobile-close-btn">Close</button></span>
    </div>
    <div class="mobile-disclaimers-container bottom-sheet-content" id="mobile-disclaimers-bottom-sheet-content">
      <rip-disclaimers #disclaimerComponent></rip-disclaimers>
    </div>

  `,
} )

export class MobileDisclaimersComponent implements AfterViewInit {

  @ViewChild( 'disclaimerComponent', { static: false } ) disclaimerComponent: DisclaimersComponent;

  disclaimerIndex: number;

  constructor( private _state: GlobalState,
               private _bottomSheetRef: MatBottomSheetRef<MobileDisclaimersComponent>,
               @Inject(MAT_BOTTOM_SHEET_DATA) public data: any ) {
    this.disclaimerIndex = data.disclaimerIndex;
  }

  ngAfterViewInit(): void {
    this.disclaimerComponent.disclaimerToHighlight = this.disclaimerIndex;
  }

  closeMobileDisclaimers() {
    this._bottomSheetRef.dismiss();
  }
}
