import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function DateValidator( format = 'MM/DD/YYYY' ): ValidatorFn {

  return ( c: AbstractControl ): { [key: string]: any } | null => {
    const val = moment( c.value, format, true );

    if ( !val.isValid() ) {
      return { invalidDate: true };
    }

    return null;
  };

}
