import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Auth } from '../../../../../../../../../auth.service';
import { GlobalState } from '../../../../../../../../../global.state';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { environment } from '../../../../../../../../../../environments/environment';
import { MatStepper } from '@angular/material/stepper';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { Util } from '../../../../../../../../../utils/util.service';
import { Subject } from 'rxjs';
import { NotificationsService } from '../../../../../../../../../globalData';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsUtil } from '../../../../../../../../../utils/notifications.util';
import { Notification } from '../../../../../../../../../utils/dataInterfaces';


@Component( {
  selector: 'rip-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: [ './notifications.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class NotificationsComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @ViewChild( 'stepper', { static: false } ) stepper: MatStepper;

  static notificationIcons: any = {
    news: faNewspaper,
    warning: faExclamationCircle,
    dataQuality: faDatabase,
    read: faEnvelopeOpen,
    unread: faEnvelope,
  };

  notificationIcons: any = NotificationsComponent.notificationIcons;

  currentNotificationId;
  clickedToggleRead: boolean;

  notifications: Notification[] = [];
  currentNotification: Notification;

  subscriberName: string = 'NotificationsComponent';

  firstLoadDone: boolean = false;

  constructor( private notificationsUtil: NotificationsUtil,
               private _auth: Auth,
               private _state: GlobalState,
               private _notificationsService: NotificationsService,
               public dialogRef: MatDialogRef<NotificationsComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private _ngZone: NgZone ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_notifications'] = { component: this, zone: _ngZone };
    }

    this.notifications = notificationsUtil.notifications;
  }

  ngAfterViewInit() {
    setTimeout( () => {
      const notification = this.data.notification;
      this.currentNotificationId = notification ? notification.id : undefined;
      if ( notification ) {
        this.openNotification( notification );
      }
    } );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }


  close() {
    this.dialogRef.close();
    this.closeNotification();
  }

  closed() {
    this.closeNotification();
  }

  static getIcon( type: string ) {
    return NotificationsComponent.notificationIcons[type];
  }

  toggleRead( notification: Notification ) {
    this.clickedToggleRead = true;
    notification.read = !notification.read;
    this.notificationsUtil.updateUserNotifications();
  }

  markAllRead() {
    this.notifications.forEach( ( notification: Notification ) => {
      notification.read = true;
    } );
    this.notificationsUtil.updateUserNotifications();
  }

  openNotification( notification: Notification ) {
    if ( this.clickedToggleRead ) {
      this.clickedToggleRead = false;
    } else {
      this.currentNotification = this.notifications.find( ( n: Notification ) => {
        return notification.id === n.id;
      } );
      setTimeout( () => {
        this.stepper.next();
        this.currentNotification.read = true;
        this.notificationsUtil.updateUserNotifications();
      } );
    }
  }

  closeNotification() {
    this.currentNotification = undefined;
    this.currentNotificationId = undefined;
    this.stepper.previous();
  }

  getSupportEmail() {
    return Util.getSupportAddress();
  }


}
