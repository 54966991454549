import { Component, Inject, OnDestroy } from '@angular/core';
import { Holding } from '@ripsawllc/ripsaw-analyzer';
import { GlobalState } from '../../../global.state';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component( {
  selector: 'rip-mobile-row-detail',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">{{ holding?.ticker || 'Holding' }}</div>
      <span><button mat-raised-button (click)="closeRowDetailPage()" class="mobile-close-btn">Close</button></span>
    </div>
    <div class="bottom-sheet-content">
      <rip-holding-detail [holding]="holding"></rip-holding-detail>
    </div>
  `,
  styleUrls: [ '../mobileLayout.scss' ],
} )

export class MobileRowDetailComponent implements OnDestroy {

  holding: Holding;

  subscriberName: string = 'mobileRowDetailComponent';

  constructor( private _state: GlobalState,
               private _bottomSheetRef: MatBottomSheetRef<MobileRowDetailComponent>,
               @Inject( MAT_BOTTOM_SHEET_DATA ) public data: any ) {
    this.holding = data.holding;
    _state.subscribe( 'close.mobile.rowDetail', () => {
      this.closeRowDetailPage();
    }, this.subscriberName );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'close.mobile.rowDetail', this.subscriberName );
  }

  closeRowDetailPage() {
    this._bottomSheetRef.dismiss();
  }

}
