import { Component, Input } from '@angular/core';
import { MarketInfoUtil } from '../../../../utils/market-info.util';
import { MarketCalcTerms } from '../../../../utils/enums';
import { Auth } from '../../../../auth.service';

@Component( {
  selector: 'rip-forward-looking-header',
  template: `
    <div class="flexing-row-only space-around" style="width: 50%;">
      <div class="forward-looking-subheader">
        <!-- nothing goes here-->
      </div>
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-label">
          Market Volatility (%)
        </div>
      </div>
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-label">
          Market Risk Premium (%)
        </div>
      </div>

    </div>
    <div class="flexing-row-only space-around" style="width: 50%;">
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-label">
          Long-Term
        </div>
      </div>
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-number">
          {{ ( marketInfoUtil.longTermStandardDeviation ) | ripDecimalPipe : '0-2' }}
          <span class="footnote"
                *ngIf="marketInfoUtil.longTermStandardDeviation !== defaultLongTermStandardDeviation">†</span>
        </div>
      </div>
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-number">
          {{ marketInfoUtil.longTermRiskPremium }}
          <span class="footnote" *ngIf="marketInfoUtil.longTermRiskPremium !== defaultLongTermRiskPremium">†</span>
        </div>
      </div>

    </div>
    <div class="flexing-row-only space-around" style="width: 50%; margin-top: 15px;" *ngIf="term === 'short'">
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-label">
          Short-Term
        </div>
      </div>
      <div class="forward-looking-subheader">

        <div class="forward-looking-subheader-number">
          {{ ( marketInfoUtil.shortTermStandardDeviation ) | ripDecimalPipe : '2-2' }}
          <span class="footnote"
                *ngIf="marketInfoUtil.shortTermStandardDeviation !== marketInfoUtil.currentVixQuote?.price">†</span>
        </div>
      </div>
      <div class="forward-looking-subheader">
        <div class="forward-looking-subheader-number">
          {{ marketInfoUtil.shortTermRiskPremium | ripDecimalPipe : '2-2' }}
          <span *ngIf="marketInfoUtil.shortTermRiskPremium !== marketInfoUtil.calculatedShortTermRiskPremium">†</span>
        </div>
      </div>
    </div>
    <div class="flexing-row-only space-around" style="width: 50%; margin-top: 15px;"
         *ngIf="authenticated && (marketInfoUtil.longTermStandardDeviation !== defaultLongTermStandardDeviation || 
    marketInfoUtil.longTermRiskPremium !== defaultLongTermRiskPremium ||
    marketInfoUtil.shortTermStandardDeviation !== marketInfoUtil.currentVixQuote?.price ||
    marketInfoUtil.shortTermRiskPremium !== marketInfoUtil.calculatedShortTermRiskPremium )">
      <div class="forward-looking-subheader"></div>
      <div class="forward-looking-subheader">
        <div class="footnote" style="text-align: left;">
          † Indicates that you have customized the number
        </div>
      </div>
      <div class="forward-looking-subheader"></div>
    </div>
  `,
} )

export class ForwardLookingHeaderComponent {

  @Input() term: MarketCalcTerms = MarketCalcTerms.short;

  readonly defaultLongTermStandardDeviation = MarketInfoUtil.defaultLongTermStandardDeviation;
  readonly defaultLongTermRiskPremium = MarketInfoUtil.defaultLongTermRiskPremium;

  authenticated: boolean = false;

  constructor( public marketInfoUtil: MarketInfoUtil, private _auth: Auth ) {
    this.authenticated = _auth.authenticated();
  }

}
