import { Injectable } from '@angular/core';
import { FastLinkComponent } from '../pages/modals/fastLink/fastLink.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class FastLinkUtil {

  constructor( public dialog: MatDialog ) {

  }

  openFastlinkDialog( institutionId? ) {
    this.dialog.open( FastLinkComponent, {
      width: '796px',
      data: {
        institutionId,
      },
      hasBackdrop: true,
      disableClose: true,
    } );
  }

}
