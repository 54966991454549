import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { Util } from '../../../utils/util.service';
import { Auth } from '../../../auth.service';
import { User } from '../../../utils/dataInterfaces';


@Component( {
  selector: 'rip-benchmark-save-confirmation-dialog',
  template: `
    <h3 mat-dialog-title>A few things to think about before you save your plan:</h3>
    <mat-divider class="modal-header-divider"></mat-divider>
    <div mat-dialog-content class="row modal-body save-benchmark-dialog">
      <ul>
        <li>
          Make sure annual spending of  {{ benchmarkState.benchmark?.annualWithdrawals | ripCurrencyPipe }} is a realistic retirement goal.
        </li>
        <li>
          Make sure the annual retirement contribution of {{ benchmarkState.benchmark?.annualContributions | ripCurrencyPipe }} is manageable for you.
        </li>
        <li *ngIf="benchmarkState.selectedBucket?.issues?.length > 0">
          Do you agree with the statement that, for the lowest {{ benchmarkState.benchmark?.probability | percent }} of
          outcomes, <br />
          you may exhaust your
          funds {{ benchmarkState.selectedBucket.stats.yearAtZero | ripDecimalPipe : '0-2' }}
          years before reaching the end of your retirement?
          <!--<ul>
            <li *ngFor="let issue of benchmarkState.selectedBucket?.issues">
              {{ issue.instructions[ 0 ]?.text }}
            </li>
          </ul>-->
        </li>
        <li>
          Are you comfortable with your overall set of goals and the probability of achieving them?
        </li>
        <li>
          Do you feel the savings for the sum of all goals is attainable?
        </li>

        <li>
          If you're unsure about any of these items, consider revisiting and adjusting your goals <br />
          and your choice of risk versus expected return strategy until you are completely content. <br />
          If you don’t have time now, no worries, just save and come back later. <br />
          This is a dynamic and iterative process.
        </li>
      </ul>
    </div>
    <mat-divider class="modal-footer-divider"></mat-divider>
    <div mat-dialog-actions style="justify-content: end;">
      <button mat-button (click)="cancel()">Back</button>
      <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
  `,
  styles: [`
    .save-benchmark-dialog ul {
      list-style: circle;
      overflow: auto;
      
    }
  `],
} )

export class SaveBenchmarkConfirmationDialogComponent {

  user: User;

  constructor( public dialog: MatDialog,
               private dialogRef: MatDialogRef<SaveBenchmarkConfirmationDialogComponent>,
               public benchmarkState: BenchmarkState,
               private _auth: Auth ) {
    this.user = Util.getLoggedInUser( _auth );
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close( true );
  }
}
