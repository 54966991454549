import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { RipsawPercentPipe } from '../../theme/pipes';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
import { BondHelpers } from '../../utils/bondHelpers';
import { takeUntil } from 'rxjs/operators';
import { GlobalDataService } from '../../globalData/globalData.service';
import { Subject } from 'rxjs';
import { Util } from '../../utils/util.service';
import { Auth } from '../../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component( {
  selector: 'rip-zillow-rate-picker',
  templateUrl: './zillowRatePicker.component.html',
  styleUrls: [ './zillowRatePicker.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )

export class ZillowRatePickerComponent implements OnDestroy {

  @Output() rateChosen = new EventEmitter<any>();

  private readonly onDestroy = new Subject<void>();

  zillowRates: any[] = [];
  ripPercentPipe = new RipsawPercentPipe();
  zillowLogoUrl: string;

  tryAgainButtonOptions: any = {
    active: false,
    text: 'Try Again',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: false,
    mode: 'indeterminate',
    disabled: false,
  };

  constructor( private _state: GlobalState,
               private _gdService: GlobalDataService,
               private _auth: Auth,
               public snackBar: MatSnackBar,
               private _cd: ChangeDetectorRef ) {
    this.zillowLogoUrl = environment.common.zillowLogoUrl;
    this.zillowRates = BondHelpers.formatZillowRates( this._state.globalVars.mortgageRates );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  goToZillow( url: string ) {
    Util.openExternalUrl( url );
  }

  chooseRate( rate: any ) {
    this.rateChosen.emit( rate );
  }

  retrieveRates() {
    this.tryAgainButtonOptions.active = true;
    const user = Util.getLoggedInUser( this._auth );
    if ( user ) {
      const state = user.state || 'US';
      this._gdService.getCurrentMortgageRates( state )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this.tryAgainButtonOptions.active = false;
            this._state.globalVars.mortgageRates = resp.data;
            this.zillowRates = BondHelpers.formatZillowRates( this._state.globalVars.mortgageRates );
            this._cd.detach();
            this._cd.detectChanges();
            // this._cd.markForCheck();
            this._cd.reattach();
          }, error: ( err ) => {
            console.error( err.err );
            this.tryAgainButtonOptions.active = false;
            this.snackBar.open( `Error retrieving market rates from Zillow. ${ err.refCode ? Util.getRefCodeSupportString( err.refCode ) : Util.getContactSupportString() }` );
          },
        } );
    }
  }
}
