<!--header-->

<div class="form-group">

    <div class="flexing">
        <fa-icon [icon]="faAnalytics" size="2x" class="manual-account-icon">
        </fa-icon>
        <fa-icon [icon]="faAlarmClock" size="2x" class="manual-account-icon" style="margin-right: 5px;">
        </fa-icon>
        <h3 class=" manual-account-form-H3" style="padding-bottom: 15px;">Add the details about the stock option</h3>

    </div>
    This account type can be used for stock options
</div>

<div class="form-group">
    <div>
        Fields with an * are required
    </div>
</div>
<div *ngIf="retrievingTreasuryRates || patchingForm" style="margin: 10px;" class="refreshing-indicator">
    Updating Option Price...
</div>
<div *ngIf="form" [nbSpinner]="retrievingTreasuryRates || patchingForm">
    <form [formGroup]="form" #formElement>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="name"
                           [errorStateMatcher]="matcher"
                           placeholder="Account Name / Description*">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required' )">
                    Account Name is required
                </mat-error>
                <mat-label>Account Name / Description*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Owner Type"
                            formControlName="owner_type"
                            [errorStateMatcher]="matcher">
                    <mat-option value="Individual Account">Individual Account</mat-option>
                    <mat-option value="Joint Account">Joint Account</mat-option>
                    <mat-option value="Trust Account">Trust Account</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group flexing">
            <rip-security-query-box
                    style="flex: 1;"
                    #queryBox
                    (fundSelected)="getPublicStockDetails($event)"
                    (changed)="tickerChanged($event)"
                    [ignore]="'funds'"
                    [startingValue]="form.controls.underlying_stock.value"
                    [placeholder]="'Underlying Stock Ticker / Proxy Ticker / Identifier'">
            </rip-security-query-box>
            <fa-icon [icon]="faInfoCircle"
                     class="form-info"
                     [fixedWidth]="true"
                     size="sm"
                     [nbPopover]="proxyTickerPopContent"
                     nbPopoverClass="rip-popover">
            </fa-icon>
        </div>
        <!-- TODO: add this text in an info tooltip: If non traded company, choose a similar traded company for quantitative risk analysis -->
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="underlying_price"
                           [errorStateMatcher]="matcher"
                           placeholder="Underlying Stock Price Per Share ($)*"
                           (change)="underlyingPriceChanged() ">
                </label>
                <mat-error
                        *ngIf="form.controls.underlying_price.hasError('required') || form.controls.underlying_price.hasError('min')">
                    Stock price not available for the given ticker, please provide one
                </mat-error>
                <mat-label>Underlying Stock Price Per Share ($)*</mat-label>
            </mat-form-field>
        </div>
        <!-- try and get this price via api based on ticker-->
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="US/Non US"
                            formControlName="us_non_us"
                            [errorStateMatcher]="matcher">
                    <mat-option value="us">US</mat-option>
                    <mat-option value="non_us">Non US</mat-option>
                </mat-select>
                <mat-label>US/Non US</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group" *ngIf="form.controls.us_non_us.value === 'non_us'">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Non US Developed/Emerging Markets"
                            formControlName="emerging_markets"
                            [errorStateMatcher]="matcher">
                    <mat-option value=0>Non US Developed</mat-option>
                    <mat-option value=1>Emerging Markets</mat-option>
                </mat-select>
                <mat-label>Non US Developed/Emerging Markets</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select
                        formControlName="company_market_cap"
                        [errorStateMatcher]="matcher"
                        placeholder="Company Market Capitalization">
                    <mat-option value="large">Large (More than $8 Billion)</mat-option>
                    <mat-option value="mid">Mid ($1 to $8 Billion)</mat-option>
                    <mat-option value="small">Small (Less than $1 Billion)</mat-option>
                </mat-select>
                <mat-label>Company Market Capitalization</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Stock Sector"
                            formControlName="sector"
                            panelClass="max-select-box-height"
                            [errorStateMatcher]="matcher">
                    <mat-option value="sector_basic_materials">Basic Materials</mat-option>
                    <mat-option value="sector_communication_services">Communication Services</mat-option>
                    <mat-option value="sector_consumer_cyclical">Consumer Cyclical</mat-option>
                    <mat-option value="sector_consumer_defensive">Consumer Defensive</mat-option>
                    <mat-option value="sector_energy">Energy</mat-option>
                    <mat-option value="sector_financial_services">Financial Services</mat-option>
                    <mat-option value="sector_healthcare">Healthcare</mat-option>
                    <mat-option value="sector_industrials">Industrials</mat-option>
                    <mat-option value="sector_real_estate">Real Estate</mat-option>
                    <mat-option value="sector_technology">Technology</mat-option>
                    <mat-option value="sector_utilities">Utilities</mat-option>
                </mat-select>
                <mat-label>Stock Sector</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select
                        formControlName="growth_value"
                        [errorStateMatcher]="matcher"
                        placeholder="Value/Growth/Blend Stocks">
                    <mat-option value="value_stocks">Value</mat-option>
                    <mat-option value="blend_stocks">Blend</mat-option>
                    <mat-option value="growth_stocks">Growth</mat-option>
                </mat-select>
                <mat-label>Value/Growth/Blend Stocks</mat-label>
            </mat-form-field>
        </div>

        <!-- OPTION TERMS -------------------------------------------------------------------------------------------------- -->
        <h3 class=" manual-account-form-H3" style="padding-bottom: 15px;">Option Terms</h3>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select
                        formControlName="call_put"
                        [errorStateMatcher]="matcher"
                        placeholder="Option Type*"
                        (selectionChange)="calculatePrice()">
                    <mat-option value="call">Call</mat-option>
                    <mat-option value="put">Put</mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls.call_put.hasError('required' )">
                    Option Type is required
                </mat-error>
                <mat-label>Option Type*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="exercise_price"
                           [errorStateMatcher]="matcher"
                           placeholder="Exercise Stock Price ($)*"
                           (change)="exercisePriceChanged()">
                </label>
                <mat-error *ngIf="form.controls.exercise_price.hasError('required' )">
                    Exercise Price is required
                </mat-error>
                <mat-label>Exercise Stock Price ($)*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="maturity_date"
                           [errorStateMatcher]="matcher"
                           placeholder="Maturity Date*"
                           (change)="maturityDateChanged()"
                           [matDatepicker]="maturityDatepicker">
                </label>
                <mat-datepicker-toggle matSuffix [for]="maturityDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #maturityDatepicker (closed)="maturityDateChanged()"></mat-datepicker>
                <mat-error *ngIf="form.controls.maturity_date.hasError('invalidDate')">
                    Date must be in 'MM/DD/YYYY' format
                </mat-error>
                <mat-error *ngIf="form.controls.maturity_date.hasError('isFutureDateValidator')">
                    Maturity Date must be greater than today's date
                </mat-error>
                <mat-error *ngIf="form.controls.maturity_date.hasError('required' )">
                    Maturity Date is required
                </mat-error>
                <mat-label>Maturity Date*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Dividend Frequency*" [(value)]="selectedDividendFrequencyValue"
                            formControlName="dividend_frequency"
                            (selectionChange)="dividendFrequencyChanged()">
                    <mat-option *ngFor="let divFrequency of dividendFrequencyOptions"
                                [value]="divFrequency.value">
                        {{divFrequency.label}}
                    </mat-option>
                </mat-select>
                <mat-label>Dividend Frequency*</mat-label>
            </mat-form-field>
            <div class="form-group" *ngIf="selectedDividendFrequencyValue==='Custom'">
                <mat-list>
                    <mat-list-item *ngFor="let dividend of dividendList">
                        <table>
                            <td>
                                <mat-form-field>
                                    <label>
                                        <input #divDate matInput placeholder="Dividend Payment Date"
                                               (change)="setDividendDate(dividend.id, divDate.value)"
                                               [matDatepicker]="dividendDatepicker">
                                    </label>
                                    <mat-datepicker-toggle matSuffix [for]="dividendDatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #dividendDatepicker
                                                    (closed)="setDividendDate(dividend.id, divDate.value)"></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <label>
                                        <input #divAmount matInput placeholder="Dividend Amount in $"
                                               (change)="setCustomDividendValue(dividend.id, divAmount)">
                                    </label>
                                </mat-form-field>
                            </td>
                            <td><span><mat-icon (click)="removeDividend(dividend.id)">delete</mat-icon></span></td>
                        </table>
                    </mat-list-item>
                </mat-list>
                <button mat-button (click)="addNewDividend()" type="button">
                    {{dividendList.length > 0 ? 'Add Another Projected Dividend' : 'Add First Projected Dividend'}}
                </button>
            </div>
            <div class="form-group"
                 *ngIf="selectedDividendFrequencyValue && selectedDividendFrequencyValue !== 'Custom' && selectedDividendFrequencyValue !== 'None'">
                <mat-form-field>
                    <label>
                        <input matInput #dividendAmt
                               formControlName="dividend_per_share"
                               [errorStateMatcher]="matcher"
                               placeholder="Dividend Per Share ($)"
                               (change)="dividendPerShareChanged(dividendAmt)">
                    </label>
                </mat-form-field>
            </div>
        </div>

        <!-- OPTION VALUATION ---------------------------------------------------------------------------------------------- -->
        <!-- will need to do a screen if proxy -->
        <h3 class=" manual-account-form-H3" style="padding-bottom: 15px;">Option Valuation</h3>
        <!-- this would only be useful for public traded options which would be pretty rare for a manual account
        <div class="form-group">
          <mat-form-field>
            <input matInput
                   formControlName="ticker"
                   [errorStateMatcher]="matcher"
                   placeholder="Option Ticker (If Available)">
          </mat-form-field>
        </div>-->
        <div class="form-group">
            <mat-form-field>
                <label>
                    <input matInput
                           formControlName="treasury_rate"
                           [errorStateMatcher]="matcher"
                           placeholder="Treasury Yield to Option Maturity (Annual %)*">
                </label>
                <mat-error *ngIf="form.controls.treasury_rate.hasError('required' )">
                    Treasury Yield is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group form-group-with-loading-indicator">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="volatility"
                           [errorStateMatcher]="matcher"
                           placeholder="Stock Volatility (Annual %)*"
                           (change)="volatilityChanged()">
                </label>
                <mat-error *ngIf="form.controls.volatility.hasError('required' )">
                    Stock Volatility is required
                </mat-error>
                <mat-label>Stock Volatility (Annual %)*</mat-label>
            </mat-form-field>
            <span *ngIf="retrievingStockRiskReturn" class="refreshing-indicator">Retrieving Stock Data...</span>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="price_per_share"
                           [errorStateMatcher]="matcher"
                           placeholder="Option Price Per Share ($)*"
                           (change)="pricePerShareChanged()">
                </label>
                <mat-error *ngIf="form.controls.price_per_share.hasError('required' )">
                    Option Price Per Share is required
                </mat-error>
                <mat-label>Option Price Per Share ($)*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <input matInput
                       formControlName="shares_per_option"
                       [errorStateMatcher]="matcher"
                       placeholder="Number of Shares Per Option Contract*"
                       (change)="sharesPerOptionChanged()">
                <mat-label>Number of Shares Per Option Contract*</mat-label>

                <mat-error *ngIf="form.controls.shares_per_option.hasError('required' )">
                    Shares Per Option is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <input matInput
                       formControlName="price"
                       [errorStateMatcher]="matcher"
                       placeholder="Option Contract Price*"
                       (change)="priceChanged()">
                <mat-error *ngIf="form.controls.price.hasError('required' )">
                    Option Contract Price is required
                </mat-error>
                <mat-label>Option Contract Price*</mat-label>
            </mat-form-field>
        </div>
        <!--

          <div *ngIf="optimalExerciseDate">
            <span>Optimal option exercise date is {{optimalExerciseDate | ripDatePipe}}</span>
          </div>
        -->

        <!---------------------------------------------------------------------------------------->
        <h5></h5>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="quantity"
                           [errorStateMatcher]="matcher"
                           placeholder="Number of Option Contracts*"
                           (change)="quantityChanged()">
                </label>
                <mat-error *ngIf="form.controls.quantity.hasError('required' )">
                    Number of Options is required
                </mat-error>
                <mat-label>Number of Option Contracts*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <label>
                    <input matInput
                           formControlName="value"
                           [errorStateMatcher]="matcher"
                           placeholder="Market Value ($)*">
                </label>
                <mat-error *ngIf="form.controls.value.hasError('required' )">
                    Market Value is required
                </mat-error>
            </mat-form-field>
        </div>


        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="cost_basis"
                           [errorStateMatcher]="matcher"
                           placeholder="Cost Basis ($)*"
                           (change)="costBasisChanged()">
                </label>
                <mat-label>Cost Basis ($)*</mat-label>
            </mat-form-field>
        </div>
    </form>
</div>


<!--proxy security popover-->
<ng-template #proxyTickerPopContent>
    <nb-card class="popover-card">
        <nb-card-header class="popover-title">
            Proxy Ticker
        </nb-card-header>
        <nb-card-body>
            <div class="popover-content">
                You can choose a proxy for any security. All the fields in the security will be populated with those of
                the proxy. This allows you to track the proxy instead (or because your security is not public and it's
                details cannot be retrieved).
            </div>
        </nb-card-body>
    </nb-card>

</ng-template>
