import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalState } from '../../../../../global.state';
import { NewInvestmentFinderUserInterface } from '../../../newInvestmentSelectorModal/components/newInvestmentFinderUserInterface';
import { SecDataStoredInterface } from '../../../newInvestmentSelectorModal/components/newInvestmentSelector/newInvestmentSelector.component';
import { GlobalDataService } from '../../../../../globalData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Util } from '../../../../../utils/util.service';
import { AccountManager } from '../../../../../utils/accountManager';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';


import { faLandmark } from '@fortawesome/pro-light-svg-icons';
import { RipsawCurrencyPipe } from '../../../../../theme/pipes';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { NewInvestmentSelectorModalComponent } from '../../../newInvestmentSelectorModal/newInvestmentSelectorModal.component';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimersUtil } from '../../../../../utils/disclaimers.util';
import { ManualSecurityEditorComponent } from '../../../manualSecurityEditor';
import { FormsUtil, Position } from '@ripsawllc/ripsaw-analyzer';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { hasAnyPermissionFn, WorkspacePermission } from '../../../../../shared/workspace-permission';
import { AppStoreService } from '../../../../../store';
import { take } from 'rxjs/operators';
import { Logger } from '../../../../../utils/logger.service';

@Component( {
  selector: 'rip-investment-form',
  templateUrl: './investmentForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss', './investmentForm.component.scss' ],
} )

export class InvestmentFormComponent extends ManualAccountFormComponent implements NewInvestmentFinderUserInterface, AfterViewInit, OnDestroy {

  faQuestionCircle = faQuestionCircle;
  faLandmark = faLandmark;

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account: any = {};
  @Input() positionToEdit: Position;

  investmentFinderMessage: string;
  loadingSecurities: boolean = false;

  positionQuantityInputs: any = {};
  positionCostBasisInputs: any = {};

  investmentAccountTypes: any[] = ManualAccountUtil.getInvestmentAccountTypes( 'Investment' );

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();

  showForm: boolean = false;

  subscriberName: string = 'investmentForm';
  perm = WorkspacePermission;

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _gdService: GlobalDataService,
               private snackBar: MatSnackBar,
               public dialog: MatDialog,
               private disclaimersUtil: DisclaimersUtil,
               private appStoreService: AppStoreService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();
      this.restrictByPermissions();
      this.setupUpdateSubscription( this._state );
      if ( this.positionToEdit ) {
        this.openManualSecurityEditor( this.positionToEdit );
      }
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    if ( this.account.account_id && this.form ) {

      ManualAccountUtil.patchInvestmentForm( this.form, this.account );


      this.loadingSecurities = false;
      for ( const p of this.form.controls.positions.value || [] ) {
        this.positionQuantityInputs[ p.ticker ] = new UntypedFormControl( p.quantity );
      }
      for ( const p of this.form.controls.positions.value || [] ) {
        this.positionCostBasisInputs[ p.ticker ] = new UntypedFormControl( new RipsawCurrencyPipe().transform( p.cost_basis ) );
      }
    } else {
      this.form.controls.value.setValue( 0 );
      Util.updateInputCurrencyFormat( 'value', this.form );
      this.form.controls.name.setValue( 'My Investment Account' );
    }
    this.checkPositionsLength( this.form.controls.positions.value );
    this.showForm = true;
    setTimeout( () => {
      if ( !this.account.account_id ) {
        this.autofocus();
      }
    } );
  }


  accountTypeChanged() {
    if ( this.form.controls.investment_account_type.value ) {
      this.form.controls.is_taxable.setValue( this.form.controls.investment_account_type.value.is_taxable );
    }
  }

  openInvestmentSelector() {
    // if ( this._state.globalVars.investmentSelector ) {
    // this._state.globalVars.investmentSelector.open( this );

    this.dialog.open( NewInvestmentSelectorModalComponent, {
      data: {
        finderUser: this,
      },
      width: '85vw',
      disableClose: false,
      hasBackdrop: true,
    } );
  }

  openManualSecurityEditor( position: any ) {
    Logger.log( 'edit manual security' );
    const dontSave = !( !!this.account.account_id );
    this.dialog.open( ManualSecurityEditorComponent, {
      data: {
        position,
        account: this.account,
        options: {
          dontSave,
          callback: ( p: Position ) => {
            this._accountManager.scanPositionsForMissingData( [ p ] );
            this.positionQuantityInputs[ p.ticker ]?.setValue( p.quantity );
          },
        },
      },
    } );
  }

  newSecuritiesChosen( chosenSecurityData: SecDataStoredInterface[] ) {
    this.loadingSecurities = true;
    if ( chosenSecurityData && chosenSecurityData.length > 0 ) {
      let positions = this.form.controls.positions.value;
      if ( !positions || positions === '' ) {
        positions = [];
      }
      chosenSecurityData.forEach( ( newSec: SecDataStoredInterface ) => {
        // need to figure out if this is the right place to do this, or if we should remove these fields elsewhere
        delete newSec.securityData.asOfDate;
        delete newSec.securityData.average_credit_quality;
        delete newSec.securityData.avg_price;
        delete newSec.securityData.investment_strategy;
        delete newSec.securityData.market_code;
        delete newSec.securityData.min_investment;
        delete newSec.securityData.other;
        delete newSec.securityData.tsd_yield;
        delete newSec.securityData.type;
        delete newSec.securityData.effective_duration;
        delete newSec.securityData.id;
        delete newSec.securityData.value;
        // delete newSec.securityData.gain_loss;
        // delete newSec.securityData.revised_allocation;
        // delete newSec.securityData.revised_difference;
        // delete newSec.securityData.revised_quantity;
        // delete newSec.securityData.revised_value;
        // delete newSec.securityData.buySell;
        delete newSec.securityData.AsOf_Date;
        delete newSec.securityData.currency;
        delete newSec.securityData.cusip;
        delete newSec.securityData.fxrate;
        delete newSec.securityData.asset_class;
        delete newSec.securityData.exchange;
        delete newSec.securityData.fund_data_loaded;
        positions.splice( 1, 0, Object.assign( {}, newSec.securityData, {
          account_id: this.account ? this.account.account_id : undefined,
          // cost_basis: 0,
          // gain_loss: 0,
          value: 0,
          quantity: 0,
        } ) );
        this.positionQuantityInputs[ newSec.securityData.ticker ] = new UntypedFormControl( 0 );
        this.positionCostBasisInputs[ newSec.securityData.ticker ] = new UntypedFormControl( '$0.00' );
      } );

      this._accountManager.scanPositionsForMissingData( positions );
      this.form.controls.positions.setValue( positions );
      if ( this._state.globalVars.investmentSelector ) {
        this._state.globalVars.investmentSelector.close();
      }
    }
    this.loadingSecurities = false;
  }

  quantityChanged( input, ticker ) {
    const positions = this.form.controls.positions.value;
    const position = positions.find( ( p: any ) => {
      return p.ticker === ticker;
    } );
    position.quantity = input.value;
    this.form.controls.positions.setValue( positions );
    this.getAccountTotal();
  }

  costBasisChanged( input, ticker ) {
    const positions = this.form.controls.positions.value;
    const position = positions.find( ( p: any ) => {
      return p.ticker === ticker;
    } );
    position.cost_basis = FormsUtil.getSanitizedFloatValue( input.value );
    this.positionCostBasisInputs[ ticker ].setValue( this.ripCurrencyPipe.transform( position.cost_basis ) );

  }

  getAccountTotal() {
    const positions = this.form.controls.positions.value;
    let total = 0;
    for ( const p of positions ) {
      total += p.quantity * p.price;
    }
    this.account.value = total;
    for ( const p of positions ) {
      p.allocation = ( p.quantity * p.price ) / total;
    }
    this.form.controls.positions.setValue( positions );
    this.form.controls.value.setValue( total );
  }

  deletePosition( position: Position, index: number ) {
    if ( this.account.account_id ) {
      this._gdService.deleteManualPosition( position )
        .subscribe( {
          next: ( resp: any ) => {
            const positions: any[] = this.form.controls.positions.value;
            positions.splice( index, 1 );
            this.checkPositionsLength( positions );
            this.form.controls.positions.setValue( positions );
            this.getAccountTotal();
            this.snackBar.open( 'Manual Position Deleted', null, Util.getSnackBarOptions() );
          }, error: ( err ) => {
            this.snackBar.open( `Error deleting manual position: ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
          },
        } );
    } else {
      const positions: any[] = this.form.controls.positions.value;
      positions.splice( index, 1 );
      this.checkPositionsLength( positions );
      this.form.controls.positions.setValue( positions );
      this.getAccountTotal();
    }

  }

  checkPositionsLength( positions: Position[] ) {
    /*if ( positions?.length > 0 ) {
     this.form.controls.positions.setErrors( null );
     } else {
     this.form.controls.positions.setErrors( { investmentPositionsLengthNonZero: false } );
     }*/
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openDisclaimerModal( index: number ) {
    this.disclaimersUtil.openDisclaimersDialog( index );
  }

  private restrictByPermissions(): void {
    this.appStoreService.loadedWorkspacePermissions$
      .pipe( take( 1 ) )
      .subscribe( allPerms => {
        const hasAccountView = hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNTS_VIEW ] );
        if ( hasAccountView ) {
          for ( const name in this.positionQuantityInputs ) {
            const control = this.positionQuantityInputs[ name ] as UntypedFormControl;
            control.disable();
          }

          for ( const name in this.positionCostBasisInputs ) {
            const control = this.positionCostBasisInputs[ name ] as UntypedFormControl;
            control.disable();
          }
        }
      } );
  }
}
