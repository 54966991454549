<button mat-raised-button color="basic"
        [matMenuTriggerFor]="goToGroupMenu"
        matTooltip="Scroll To Column Group"
        matTooltipClass="mat-tooltip-custom"
        class="go-to-group-menu primary-btn"
        [ngClass]="{'mobile-class': deviceIsMobile}">
  <span *ngIf="deviceIsDesktop">Go To Column Group...</span>
  <span *ngIf="deviceIsMobile">
    <!--<mat-icon>redo</mat-icon>-->
    {{ currentGroup?.label }}
  </span>
  <fa-layers size="lg" [fixedWidth]="true" class="deviation-badge">
    <fa-icon [icon]="faCircle" [style.color]="badge?.color?.hex" [fixedWidth]="true"></fa-icon>
    <fa-layers-text content="{{badge.number}}" transform="shrink-4"></fa-layers-text>
  </fa-layers>
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #goToGroupMenu="matMenu">
  <button mat-menu-item *ngFor="let group of columnGroups" (click)="goToGroup(group)">
    <span *ngIf="groupDeviations[group?.label]" class="deviation-warning-container"
          [style.color]="groupDeviations[group?.label].color.hex">
      <fa-icon [icon]="getIcon(group)"
               matTooltip="{{getDeviationTooltip(groupDeviations[group?.label]?.color?.name)}}"
               matTooltipClass="mat-tooltip-custom"
               matTooltipPosition="left"
               class="deviation-warning">
      </fa-icon>
    </span>
    <span>{{group?.label}}</span>
  </button>
  <button mat-menu-item (click)="goToBeginning()" *ngIf="!deviceIsMobile">
    <span>Scroll To Beginning</span>
  </button>
</mat-menu>
