import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Util } from '../../utils/util.service';
import { catchError, map } from 'rxjs/operators';
import { GlobalState } from '../../global.state';
import { User } from '../../utils/dataInterfaces';
import { Observable } from 'rxjs';
import { HouseholdMember } from '@ripsawllc/ripsaw-analyzer';

@Injectable()
export class ProfileService {


  private readonly lambdaUsersUrl: string;
  private readonly lambdaWorkspaceAggUrl: string;
  private readonly lambdaStripeUrl: string;
  private readonly lambdaHouseholdMembersUrl: string;
  private readonly lambdaTaskManagerUrl: string = `${ environment.ripsawAPIBaseUrl }/task-manager`;

  constructor( private _http: HttpClient, private _state: GlobalState ) {
    this.lambdaUsersUrl = `${ environment.ripsawAPIBaseUrl }/users`;
    this.lambdaWorkspaceAggUrl = `${ environment.ripsawAPIBaseUrl }/workspace-aggregator`;
    this.lambdaStripeUrl = `${ environment.ripsawAPIBaseUrl }/stripe`;
    this.lambdaHouseholdMembersUrl = `${ environment.ripsawAPIBaseUrl }/workspace-household-members`;
  }

  updateUser( user: User ): Observable<any> {
    // console.log( 'updating user:' );
    // console.log( user );
    const url: string = `${ this.lambdaUsersUrl }/update`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, user, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  uploadToS3( url: string, file ) {
    return this._http.post( url, file )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  authorizeCard( stripeToken: string ) {
    const url: string = `${ this.lambdaStripeUrl }/cc`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, { stripeToken }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateUserCreditCard( stripeToken: string ) {
    const url: string = `${ this.lambdaStripeUrl }/cc`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, { stripeToken }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getCurrentCardInfo() {
    const url: string = `${ this.lambdaStripeUrl }/cc`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  addPaymentMethod( paymentMethod ) {
    const url: string = `${ this.lambdaStripeUrl }/paymentMethod/add`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, { paymentMethod }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getSubscription() {
    const url: string = `${ this.lambdaStripeUrl }/subscription`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getProducts() {
    const url: string = `${ this.lambdaStripeUrl }/products`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getAllSubscriptions() {
    const url: string = `${ this.lambdaStripeUrl }/subscription/all`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateUserSubscription( newPlan: any ) {
    const url: string = `${ this.lambdaStripeUrl }/subscription`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, { newPlan }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  cancelSubscription() {
    const url: string = `${ this.lambdaStripeUrl }/subscription`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.delete( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  reactivateSubscription( newPlan: any ) {
    const url: string = `${ this.lambdaStripeUrl }/subscription/reactivate`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, { newPlan }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateUserWithAggregator( user: User ) {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/user/update`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );

    return this._http.put( url, user, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getAggregatorUserDetails(): Observable<any> {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/user`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getHouseholdMembers() {
    const url: string = `${ this.lambdaHouseholdMembersUrl }/get`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getHouseholdMemberTasks(): Observable<{}> {
    const url: string = `${ this.lambdaTaskManagerUrl }/get-by-category?category=household_members`;
    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  createHouseholdMember( member: HouseholdMember ) {
    const url: string = `${ this.lambdaHouseholdMembersUrl }/create`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, member, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateHouseholdMember( member: HouseholdMember ) {
    const url: string = `${ this.lambdaHouseholdMembersUrl }/update`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, member, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  deleteHouseholdMember( member: HouseholdMember ) {
    const url: string = `${ this.lambdaHouseholdMembersUrl }/delete/${ member.id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.delete( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
