import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { GlobalDataService } from '../../globalData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash-es';
import { Util } from '../../utils/util.service';

@Component( {
  selector: 'rip-crypto-query-box',
  template: `
    <form>
      <div class="horizontal-form-group crypto-query-form-group">
        <mat-form-field appearance="outline">
          <input matInput #inputRef class="crypto-query-box-input"
                 [formControl]="queryControl"
                 [placeholder]="placeholder"
                 [matAutocomplete]="auto"
                 (input)="filter()"
                 (blur)="blurred.emit()">
          <span *ngIf="searching" class="search-spinner" matSuffix><mat-spinner [diameter]="24"></mat-spinner></span>

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected()">
            <mat-optgroup *ngIf="exactMatch" label="Top Result">
              <mat-option [value]="exactMatch">
                {{ exactMatch.Code + ' | ' + exactMatch.Name + ' | ' + (exactMatch.previousClose | ripCurrencyPipe) }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Search Results" *ngIf="filteredSecurities.length > 0">
              <mat-option *ngFor="let security of filteredSecurities" [value]="security">
                {{ security.Code + ' | ' + security.Name + ' | ' + (security.previousClose | ripCurrencyPipe)  }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
          <mat-error *ngIf="!queryControl.pristine && queryControl.value === ''">
            Ticker is required
          </mat-error>
          <mat-label>Ticker or Name (autocompletes)</mat-label>
        </mat-form-field>
        <span *ngIf="retrieving" class="refreshing-indicator">Retrieving Crypto Data...</span>
      </div>
    </form>
  `,
  styleUrls: [ './cryptoQueryBox.component.scss' ],
} )

export class CryptoQueryBoxComponent {

  @Output() coinSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Output() blurred: EventEmitter<any> = new EventEmitter<any>();

  @Input() placeholder: string = 'Ticker or Name (autocompletes)';
  @Input() startingValue: string = '';

  queryControl: UntypedFormControl = new UntypedFormControl( '', Validators.compose( [
    Validators.required,
  ] ) );

  filteredSecurities: any[] = [];
  exactMatch: any;

  somethingWasChosen: boolean = false;
  detailsRetrieved: boolean = false;


  searching: boolean = false;

  retrieving: boolean = false;

  constructor( private _gdService: GlobalDataService, private snackBar: MatSnackBar ) {
    if ( environment.env !== 'prod' ) {
      window[`ripsaw_queryBox_${ Math.random() }`] = this;
    }
  }

  showRetrieving() {
    this.retrieving = true;
  }

  hideRetrieving() {
    this.retrieving = false;
  }

  filter() {
    this.somethingWasChosen = false;
    this.detailsRetrieved = false;
    this.searchFunds( this.queryControl.value );
    this.changed.emit( this.queryControl.value );
    // this.getSecurity( this.queryControl.value );
  }

  searchFunds( query: string ) {
    if ( query.length > 0 ) {
      // using this to determine if the user has typed more characters while waiting for results to come back. So we
      // don't run into issues where older searches come back after newer searches
      const currentSearch = query;
      this.searching = true;

      const options: any = {
        exchange: 'CC',
      };

      this._gdService.searchEOD( query, options ).subscribe( ( searchResults: any ) => {
        // this.snackBar.open( `Security Data retrieved`, 'dismiss', Util.getSnackBarOptions() );
        if ( query !== currentSearch ) {
          return;
        }
        const queryMatch = _.remove( searchResults.data, ( s: any ) => {
          return s.Code.toLowerCase() === query.toLowerCase();
        } );
        if ( searchResults ) {
          if ( queryMatch && queryMatch.length > 0 ) {
            this.exactMatch = queryMatch[0];
          } else {
            this.exactMatch = undefined;
          }
          this.filteredSecurities = searchResults.data;
        }
        this.searching = false;
      }, ( err ) => {
        console.error( err.err );
        console.error( `Error Reference Code: ${ err.refCode }` );
        this.snackBar.open( `Error Retrieving Crypto Data: ${ err.err }. ${ Util.getContactSupportString() }`, 'dismiss', Util.getSnackBarOptions() );
        // console.log( `Did not find any search results matching query: ${query}` );
      } );
    }
  }

  optionSelected() {
    this.somethingWasChosen = true;
    this.coinSelected.emit( this.queryControl.value );
  }

  displayFn( security?: any ): string | undefined {
    return security ? security.ticker : undefined;
  }

  resetQueryControl( val?: string ) {
    this.somethingWasChosen = false;
    this.queryControl.setValue( { ticker: val || '' } );
  }

}
