import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

@Component( {
  selector: 'rip-trading-view-fundamentals',
  template: `
    <div class="tradingview-widget-container" style="height: 300px;" #containerDiv>
      <div class="tradingview-widget-container__widget"></div>

    </div>
    <div class="tradingview-widget-copyright">

      <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
      by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
    </div>
  `,
} )

export class TradingViewFundamentalsComponent implements AfterViewInit {

  @Output() errorLoading: EventEmitter<any> = new EventEmitter<any>();
  // allows for loading with any symbol
  @Input() symbol: string = '';
  @Input() height: number = 300;
  settings: any = {};
  // id for being able to check for errors using postMessage
  widgetId: string = '';

  // wanted to be able to hide the widget if the symbol passed in was invalid (don't love their sad cloud face)
  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  constructor( private _elRef: ElementRef ) {
  }

  gotToTradingView() {
    Util.openExternalUrl( `https://www.tradingview.com/symbols/${ this.symbol }/` );
  }

  ngAfterViewInit() {
    // need to do this in AfterViewInit because of the Input
    setTimeout( () => {
      this.widgetId = `${ this.symbol }_fundamnetals`;

      const dimension = this.height - 10;

      // postMessage listener for handling errors
      if ( window.addEventListener ) {
        window.addEventListener( 'message', ( e: any ) => {
            if ( e && e.data ) {
              // console.log( e );
              const payload = e.data;
              // if the frameElementId is from this component, the symbol was no good and we should hide the widget
              if ( payload.name === 'tv-widget-no-data' && payload.frameElementId === this.widgetId ) {
                this.containerDiv.nativeElement.style.display = 'none';
                this.errorLoading.emit();
              }
            }
          },
          false,
        );
      }


      this.settings = {
        symbol: this.symbol,
        colorTheme: GlobalState.tradingViewColorTheme,
        isTransparent: false,
        largeChartUrl: '',
        displayMode: 'regular',
        height: dimension,
        width: dimension,
        autosize: true,
        locale: 'en',
      };
      const script = document.createElement( 'script' );
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
      script.async = true;
      script.id = this.widgetId;
      script.innerHTML = JSON.stringify( this.settings );
      this.containerDiv.nativeElement.appendChild( script );
      const brandingDiv = document.createElement( 'div' );
      brandingDiv.innerHTML = `
    <div class="tradingview-widget-copyright">
    <a (click)="goToTradingView()">
    <span class="blue-text">${ this.symbol } Fundamental Data</span></a>
              by TradingView
          </div>
`;

    } );
  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }

}
