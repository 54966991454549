import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChartType, Chart, ChartOptions, TooltipItem } from 'chart.js';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { RipsawDecimalPipe } from '../../theme/pipes';
import { environment } from '../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { GlobalState } from '../../global.state';
import { BaseChartDirective } from 'ng2-charts';

@Component( {
  selector: 'rip-risk-return-scatter-plot',
  template: `
    <div class="risk-return-scatter-container" [style.max-width]="maxWidth">
      <div class="flexing-row-only risk-return-scatter-chart-wrapper" [style.height]="chartWrapperHeight + 'px'">
        <div class="rotate chart-arrow-container vertical-arrow" style="overflow: hidden;">
          <div>{{ yAxisArrowLabel | uppercase }}</div>
          <div class="arrow-up">
          </div>
        </div>
        <div style="display: block; flex-grow: 1; overflow: hidden;">
          <canvas baseChart #scatterPlot
                  [datasets]="datasets"
                  [options]="scatterChartOptions"
                  [type]="scatterChartType"
                  (chartClick)="chartClicked($event)"
                  *ngIf="!loading && datasets?.length > 0">

          </canvas>
        </div>
      </div>
      <div class="chart-arrow-container horizontal-arrow">
        <div style="width: 35px;"></div>
        <div style="margin-right: 40px; white-space: nowrap;">{{ xAxisArrowLabel | uppercase }}</div>
        <div class="arrow-right">
        </div>
      </div>
    </div>
  `,
  styleUrls: [ './risk-return-scatter-plot.component.scss' ],
} )

export class RiskReturnScatterPlotComponent implements AfterViewInit, OnInit, OnDestroy {

  @HostListener( 'window:resize', [ '$event' ] )
  onResize( /*event: Event*/ ) {
    this.scatterPlot?.chart?.update();
  }

  @ViewChild( BaseChartDirective ) scatterPlot: BaseChartDirective;

  @Input() datasets: any[] = [];
  @Input() labels: string[] = [];
  @Input() xAxisLabel: string = 'Total Volatility';
  @Input() yAxisLabel: string = 'Expected Return';
  @Input() xAxisArrowLabel: string = 'More Risk';
  @Input() yAxisArrowLabel: string = 'More Expected Reward';
  @Input() chartTitle: string;
  @Input() interactive: boolean = false;
  @Input() defaultFrontierPointRadius: number = 1.5;
  @Input() defaultFrontierPointColor: string = '#79bfd7';
  @Input() chartWrapperHeight: number = 800; // in pixels
  @Input() maxWidth: string = '';

  @Output() pointClicked: EventEmitter<any> = new EventEmitter<any>();

  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  loading: boolean = true;

  scatterChartOptions: ChartOptions;

  scatterChartType: ChartType = 'scatter';

  xMin: number = 0;
  xMax: number = 20;
  yMin: number = 0;
  yMax: number = 20;

  axesDrawn: number = 0;

  deviceIsMobile: boolean = false;

  selectedPoint: any;
  frontierIndex: number;

  subscriberName: string = `riskReturnScatterPlot_${ Math.floor( Math.random() * 100000 ) }`;

  constructor( private _detectorService: DeviceDetectorService, private _state: GlobalState ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

    // this should only get called for the benchmark setup scatter with the efficient frontier
    this._state.subscribe( 'frontier.point.selected', ( frontierIndex: number ) => {
      this.setSelectedPoint( frontierIndex );
    }, this.subscriberName );

    this._state.subscribe( 'set.risk.scatter.min.and.max', () => {
      this.setMinAndMaxAxisValues();
    }, this.subscriberName );
  }

  ngOnInit() {
    Chart.register( Annotation );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [
      'frontier.point.selected',
      'set.risk.scatter.min.and.max',
    ].join( ' | ' ), this.subscriberName );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.loading = false;

      this.scatterChartOptions = {
        responsive: true,
        // aspectRatio: 1,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            callbacks: {
              label: ( tooltipItem: TooltipItem<ChartType> ) => {
                let label = this.labels[tooltipItem.datasetIndex] ?? '';
                if ( label ) {
                  label += ': ';
                }
                label = `${ label }${ this.ripDecimalPipe.transform( tooltipItem.raw['x'], '0-2' ) }, ${ this.ripDecimalPipe.transform( tooltipItem.raw['y'], '0-2' ) }`;
                return label;
              },
            },
          },
          title: {
            display: !!this.chartTitle,
            text: this.chartTitle,
            color: '#79afd7', // these three lines will need updating once we can move to chartjs 3.x
            font: {
              weight: 'bolder',
              size: 20,
            },
          },
          annotation: {
            annotations: {},
          },
          legend: {
            display: !this.deviceIsMobile,
          },
        },

        scales: {
          x: {
            display: true,
            position: 'bottom',
            title: {
              display: true,
              text: this.xAxisLabel,
            },
            beginAtZero: true,
          },
          y: {
            display: true,
            position: 'left',
            title: {
              display: true,
              text: this.yAxisLabel,
            },
            suggestedMin: 0,
          },
        },
      };
      this.setMinAndMaxAxisValues();
      this.setAnnotations();

      if ( environment.env !== 'prod' ) {
        window[this.subscriberName] = this;
      }

    }, 1000 );
  }


  setMinAndMaxAxisValues() {
    this.xMax = 0;
    this.yMax = 0;
    this.yMin = 0;


    this.datasets.forEach( ( set, index ) => {

      if ( set.data[0].x > this.xMax ) {
        this.xMax = set.data[0].x;
      }
      if ( set.data[0].y > this.yMax ) {
        this.yMax = set.data[0].y;
      }

      if ( set.data[0].y < this.yMin ) {
        this.yMin = set.data[0].yMin;
      }

      if ( set.data.length > 1 ) { // this should only be true for the frontier
        this.frontierIndex = index;
      }
    } );
    if ( this.scatterChartOptions ) {
      this.scatterChartOptions.scales.x['suggestedMax'] = this.xMax + 1;
      this.scatterChartOptions.scales.y['suggestedMax'] = this.yMax + ( this.yMax / 5 );
      this.scatterChartOptions.scales.y['suggestedMin'] = this.yMin - 1;
    }
  }

  chartClicked( event ) {
    if ( this.interactive ) {

      let chartElement;
      if ( event.active?.length > 0 ) {
        chartElement = event.active[0];
      } else {
        const activeElements = event.event?.chart?.getElementsAtEventForMode( event.event, 'index', { intersect: false }, false );
        activeElements.forEach( ( el ) => {
          if ( el.datasetIndex === this.frontierIndex ) {
            chartElement = el;
          }
        } );
      }
      if ( chartElement?.datasetIndex === this.frontierIndex ) {

        let index = chartElement.index;
        // for some reason, this element is sometimes returned with a 0 index even when clicked nowhere near the left side
        // so we need to check the index and offset and compare. if they don't make sense, get an index that makes more sense
        if ( chartElement?.index === 0 ) {
          const width = chartElement.chart.width;
          const offsetX = event.event.offsetX;
          const ratioOfOffset = offsetX / width;
          if ( ratioOfOffset > 0.05 ) {
            index = Math.floor( ratioOfOffset * this.datasets[this.frontierIndex].data.length );
          }
        }
        this.selectedPoint = {
          dataSet: chartElement.datasetIndex,
          index,
        };
        this.pointClicked.emit( this.selectedPoint );

        this.scatterPlot?.chart?.update();

      }
    }
  }

  setSelectedPoint( index: number ) {
    if ( !this.frontierIndex ) {
      this.setMinAndMaxAxisValues();
    }

    this.selectedPoint = {
      dataSet: this.frontierIndex,
      index,
    };
    this.scatterPlot?.chart?.update();
  }

  setAnnotations() {
    /*this.scatterChartOptions.plugins.annotation.annotations['horizontalLine'] =
      {
        type: 'line',
        scaleID: 'x',
        value: 15, // ( this.xMin + this.xMax ) / 2,
        borderColor: 'black',
        borderWidth: 2,
        label: {
          // position: 'center',
          // yAdjust: -13,
          enabled: false,
          // color: 'black',
          // content: `Historical High (${ this.vixSeriesStats?.historical_high })`,
          // font: {
          //   weight: 'bold',
          // },
        },
      };
    this.scatterChartOptions.plugins.annotation.annotations['verticalLine'] = {
      type: 'line',
      scaleID: 'y',
      value: 10, // ( this.yMin + this.yMax ) / 2,
      borderColor: 'black',
      borderWidth: 2,
      label: {
        // position: 'center',
        // yAdjust: -13,
        enabled: false,
        // color: 'black',
        // content: `Historical High (${ this.vixSeriesStats?.historical_high })`,
        // font: {
        //   weight: 'bold',
        // },
      },
    };*/
    /*setTimeout( () => {
      this.scatterPlot?.chart?.update();
    } );*/
  }
}
