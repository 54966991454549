import { Component, NgZone } from '@angular/core';
import { GlobalState } from '../../../../../global.state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Auth } from '../../../../../auth.service';
import { Util } from '../../../../../utils/util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RipsawPercentPipe } from '../../../../../theme/pipes';
import { environment } from '../../../../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../../utils/mobileUtil.service';
import { UsersUtil } from '../../../../../utils/users.util';
import { WithWorkspacePermission, WorkspacePermissionService } from '../../../../../shared/workspace-permission';
import { take } from 'rxjs/operators';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';


@Component( {
  selector: 'rip-deviation-thresholds',
  templateUrl: './deviationThresholds.component.html',
  styleUrls: [ './deviationThresholds.component.scss' ],
} )

export class DeviationThresholdsComponent extends WithWorkspacePermission {


  columnGroups: any = [];
  thresholdForm: UntypedFormGroup = new UntypedFormGroup( {} );

  currentThresholds: any = {};
  canCancelChanges: boolean = false;

  saveThresholdsButtonOptions: any = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: true,
    mode: 'indeterminate',
    disabled: true,
  };

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();

  deviceIsMobile: boolean = false;

  constructor( private _state: GlobalState,
               private _auth: Auth,
               private snackBar: MatSnackBar,
               private _detectorService: DeviceDetectorService,
               private _ngZone: NgZone,
               private _usersUtil: UsersUtil,
               private wpService: WorkspacePermissionService,
  ) {
    super();
    this.columnGroups = _state.columnGroupings;
    for ( const group of this.columnGroups ) {
      this.thresholdForm.addControl( group.label, new UntypedFormControl() );
    }
    this.setThresholds();
    this.restrictByPermissions();

    if ( environment.env !== 'prod' ) {
      window['ripsaw_deviationThresholds'] = { component: this, zone: _ngZone };
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

  }

  setThresholds() {
    const userPrefs = UsersUtil.checkPreferences( this._auth, 'thresholds' );
    if ( userPrefs?.thresholds[this.columnGroups[0].label] ) {
      for ( const group of this.columnGroups ) {
        if ( userPrefs.thresholds[group.label] ) {
          this.thresholdForm.controls[group.label].setValue( this.ripPercentPipe.transform( userPrefs.thresholds[group.label] ) );
        } else {
          this.thresholdForm.controls[group.label].setValue( this.ripPercentPipe.transform( group.defaultThreshold ) );
        }

      }
    } else {
      for ( const group of this.columnGroups ) {
        this.thresholdForm.controls[group.label].setValue( this.ripPercentPipe.transform( group.defaultThreshold ) );
      }
    }
    this.currentThresholds = this.thresholdForm.getRawValue();
  }

  resetThresholds( toDefault: boolean ) {
    if ( toDefault ) {
      for ( const group of this.columnGroups ) {
        this.thresholdForm.controls[group.label].setValue( this.ripPercentPipe.transform( group.defaultThreshold ) );
      }
      this.saveThresholdsButtonOptions.disabled = false;
      this.canCancelChanges = true;
    } else {
      for ( const group of this.columnGroups ) {
        // don't need to transform because they are stored as transformed percentages using getRawValue()
        this.thresholdForm.controls[group.label].setValue( this.currentThresholds[group.label] );
      }
      this.canCancelChanges = false;
      this.saveThresholdsButtonOptions.disabled = true;
    }

  }

  saveThresholds() {
    this.saveThresholdsButtonOptions.active = true;
    const userPrefs = UsersUtil.checkPreferences( this._auth, 'thresholds' );
    this.setPrefsThresholds( userPrefs );

    this._usersUtil.savePreferences( userPrefs, ( err ) => {
      if ( err ) {
        console.error( err.err );
        this.snackBar.open( `Error saving user preferences. ${ Util.getRefCodeSupportString( err.refCode ) }`,
          'dismiss',
          Util.getSnackBarOptions( true ) );
      } else {
        this.snackBar.open( `User preferences saved `, null, Util.getSnackBarOptions() );
        this._state.globalVars.allocWidget.thresholds = userPrefs.thresholds;
        this._state.globalVars.allocWidget.recalc();
        this.canCancelChanges = false;
        this.saveThresholdsButtonOptions.disabled = true;
      }
      this.saveThresholdsButtonOptions.active = false;
    } );
  }

  setPrefsThresholds( userPrefs: any ) {
    for ( const group of this.columnGroups ) {
      const sanitizedValue = FormsUtil.sanitizeInput( this.thresholdForm.controls[group.label].value );
      userPrefs.thresholds[group.label] = parseFloat( sanitizedValue ) / 100;
    }
  }

  formatInput( input ) {
    input.value = FormsUtil.getSanitizedFloatValue( input.value );
    input.value = this.ripPercentPipe.transform( input.value / 100 );
    this.canCancelChanges = this.thresholdsChanged();
    if ( this.canCancelChanges ) {
      this.saveThresholdsButtonOptions.disabled = false;
    }
  }

  thresholdsChanged() {
    return this.currentThresholds !== this.thresholdForm.getRawValue();
  }

  private restrictByPermissions(): void {
    this.wpService.hasAny([this.perm.OWNER, this.perm.SETTINGS_EDIT])
      .pipe(take(1))
      .subscribe(hasPermissions => {
        if (!hasPermissions) {
          this.thresholdForm.disable();
        }
      });
  }

}

