import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ManualAccountErrorStateMatcher } from '../../../../../../utils/manualAccountManager.state';
import * as parseAddress from 'parse-address';
import { Logger } from '../../../../../../utils/logger.service';

@Component( {
  selector: 'rip-city-state-zip-form',
  template: `
    <div class="form-horizontal" [formGroup]="localForm">
      <mat-form-field>
        <label>
          <input matInput
                 formControlName="city"
                 [errorStateMatcher]="matcher"
                 (change)="addressChange()"
                 placeholder="City">
        </label>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input matInput
                 formControlName="state"
                 [errorStateMatcher]="matcher"
                 (change)="addressChange()"
                 placeholder="State">
        </label>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input matInput
                 formControlName="zip"
                 [errorStateMatcher]="matcher"
                 (change)="addressChange()"
                 placeholder="Zip">
        </label>
      </mat-form-field>
    </div>
  `,
  styles: [ `
    mat-form-field {
      margin: 0 5px;
    }
  ` ],

} )

export class CityStateZipFormComponent {

  @Input() form: UntypedFormGroup;
  @Input() matcher: ManualAccountErrorStateMatcher;

  @Output() cityStateZipChange: EventEmitter<any> = new EventEmitter<any>();

  localForm: UntypedFormGroup = new UntypedFormGroup( {
    'city': new UntypedFormControl( '' ),
    'state': new UntypedFormControl( '' ),
    'zip': new UntypedFormControl( '' ),
  } );

  parseCityStateZip() {
    const val = `${ this.form.controls.address.value } ${ this.form.controls.cityStateZip.value }`;

    const parsed = parseAddress.parseLocation( val );

    Logger.log( parsed );

    if ( parsed.city ) {
      this.localForm.controls.city.setValue( parsed.city );
    }
    if ( parsed.zip ) {
      this.localForm.controls.zip.setValue( parsed.zip );
    }
    if ( parsed.state ) {
      this.localForm.controls.state.setValue( parsed.state );
    }


  }

  addressChange() {
    const city: string = this.localForm.controls.city.value;
    const state: string = this.localForm.controls.state.value;
    const zip: string = this.localForm.controls.zip.value;

    this.form.controls.cityStateZip.setValue( `${ city }, ${ state } ${ zip }` );
    this.cityStateZipChange.emit();
  }
}
