<mat-card class="settings-card" [ngClass]="{ 'mobile-class': deviceIsMobile }">
    <mat-card-content>
        <div class="row">
            <div class="col-12">
                <h5 class="deviation-threshold-title">
                    Deviation Tracking Thresholds:
                </h5>
                <div class="row flexing">
                    <div class="col" style="text-align: left;">
                        <h6 class="deviation-threshold-msg">
                            <i>Portfolio benchmark deviation indicators help avoid drifting too far away from your
                                objectives. These deviation tracking thresholds show on the Wealth Portfolio Dashboard
                                to alert you when your portfolio has deviated from your chosen benchmark. Adjust
                                deviation thresholds to personal preferences.</i>

                        </h6>
                    </div>
                    <div class="col" style="text-align: center;">
                        <img src="assets/img/portfolio_deviation_indicators.png" height="125" alt="deviation_indicators"
                             style="border: 3px solid #207bbc; border-radius: 15px; max-width: 100%;">

                    </div>
                </div>
                <div class="row"><br></div>
                <form>
                    <div class="form-group">
                        <mat-form-field *ngFor="let group of columnGroups">
                            <!--<span>{{group.label}}</span>-->
                            <input matInput #thresholdInput [formControl]="$any(thresholdForm).controls[group.label]"
                                   (change)="formatInput(thresholdInput)" placeholder="{{group.label}}"/>
                        </mat-form-field>
                    </div>
                </form>

                <ng-container *ripPerm="[perm.OWNER, perm.SETTINGS_EDIT]">
                  <rip-progress-bar-button [options]="saveThresholdsButtonOptions"
                                           (onClick)="saveThresholds()"></rip-progress-bar-button>
                  <button mat-raised-button (click)="resetThresholds(true)">Reset To Defaults</button>
                  <button mat-raised-button (click)="resetThresholds(false)" *ngIf="canCancelChanges">Cancel Changes
                  </button>
                </ng-container>
            </div>
        </div>
    </mat-card-content>
</mat-card>
