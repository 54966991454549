import { Account, ExpectedRemainingLife, FormsUtil, Position } from '@ripsawllc/ripsaw-analyzer';
import { ExistingManualAccountForm } from './dataInterfaces';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Util } from './util.service';
import moment from 'moment';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { DividendInterface, StockOptionFormComponent } from '../pages/modals/manualAccountManager/components';
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faGem } from '@fortawesome/pro-light-svg-icons/faGem';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faLandmark } from '@fortawesome/pro-light-svg-icons/faLandmark';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity';
import { faHandHoldingUsd } from '@fortawesome/pro-light-svg-icons/faHandHoldingUsd';
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons/faMoneyBill';
import { faCoin } from '@fortawesome/pro-light-svg-icons/faCoin';
import { MortgageHelpers } from './mortgageHelpers';
import { BondHelpers } from './bondHelpers';
import _ from 'lodash-es';
import * as math from 'mathjs';
import * as actuaryTable from '../../assets/json/ss_actuary_table-2020.json';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { Moment } from 'moment/moment';

export class ManualAccountUtil {

  private static cryptoIconsBaseUrl: string = 'https://cryptoicons.org/api/icon/'; // https://cryptoicon-api.vercel.app/api/icon/';

  private static investmentAccountTypeCategories: any[] = [
    {
      label: 'Taxable Accounts',
      types: [
        {
          type: 'Checking',
          category: 'Banking',
          is_taxable: 1,
        },
        {
          type: 'Savings',
          category: 'Banking',
          is_taxable: 1,
        },
        {
          type: 'Certificate of Deposit',
          category: 'Banking',
          is_taxable: 1,
        },
        {
          type: 'Brokerage Account',
          category: 'Investment',
          is_taxable: 1,
        },
        /*{
         type: 'Limited Partnership',
         category: 'Investment',
         is_taxable: 1,
         },*/
        {
          type: 'Minor Custodial Account',
          category: 'Investment',
          is_taxable: 1,
        },
        {
          type: 'UGMA',
          category: 'Investment',
          is_taxable: 1,
        },
        {
          type: 'UTMA',
          category: 'Investment',
          is_taxable: 1,
        },
        /*{
         type: 'Pension',
         category: 'Investment',
         is_taxable: 1,
         },*/
        /*        {
         type: 'Variable Annuity',
         category: 'Investment',
         is_taxable: 1,
         },*/
        /*{
         type: 'Mutual Fund',
         category: 'Investment',
         is_taxable: 1,
         // not in quovo dictionary for account types
         },*/
        {
          type: 'Private Investments',
          category: 'Investment',
          is_taxable: 1,
          // not in quovo dictionary for account types
        },
        {
          type: 'Real Estate',
          category: 'Other',
          is_taxable: 1,
        },
        /*        {
         type: 'Alternative',
         category: 'Other',
         is_taxable: 1,
         },
         {
         type: 'Misc',
         category: 'Other',
         is_taxable: 1,
         },*/
      ],
    },
    {
      label: 'Tax-Deferred Accounts',
      types: [
        {
          type: 'IRA',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'SEP IRA',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Simple IRA',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Roth IRA',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Roth 401k',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: '401k',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: '401a',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: '403b',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: '457b',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Non-Taxable Brokerage Account',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Money Purchase Plan',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Profit Sharing Plan',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Stock Plan',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Thrift Savings Plan',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: '529',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Health Savings Account',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Education Savings Account',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Health Reimbursement Arrangement',
          category: 'Investment',
          is_taxable: 0,
        },
        {
          type: 'Misc Banking',
          category: 'Banking',
          is_taxable: 0,
        },
        /*      {
         type: 'Annuity',
         category: 'Insurance',
         is_taxable: 0,
         },*/
        /*        {
         type: 'Fixed Annuity',
         category: 'Insurance',
         is_taxable: 0,
         },*/
        /*       {
         type: 'Whole Life Insurance',
         category: 'Insurance',
         is_taxable: 0,
         },
         {
         type: 'Term Life Insurance',
         category: 'Insurance',
         is_taxable: 0,
         },
         {
         type: 'Universal Life Insurance',
         category: 'Insurance',
         is_taxable: 0,
         },
         {
         type: 'Variable Life Insurance',
         category: 'Insurance',
         is_taxable: 0,
         },
         {
         type: 'Insurance',
         category: 'Insurance',
         is_taxable: 0,
         },*/
        {
          type: 'Unknown',
          category: 'Unknown',
          is_taxable: 0,
        },
      ],
    },
    /*{
     label: 'Liability Accounts',
     types: [
     {
     type: 'Mortgage',
     category: 'Loan',
     is_taxable: 0,
     },
     {
     type: 'HELOC',
     category: 'Loan',
     is_taxable: 0,
     },
     {
     type: 'Loan',
     category: 'Loan',
     is_taxable: 0,
     },
     {
     type: 'Auto Loan',
     category: 'Loan',
     is_taxable: 1,
     },
     {
     type: 'Student Loan',
     category: 'Loan',
     is_taxable: 0,
     },
     {
     type: 'Credit Card',
     category: 'Banking',
     is_taxable: 1,
     },
     ],
     },*/
  ];

  static getInvestmentAccountTypes( category?: string ): any[] {
    const categories: any[] = [];
    for ( const cat of this.investmentAccountTypeCategories ) {
      const newCat = {
        label: cat.label,
        types: category ? cat.types.filter( ( t: any ) => {
          return t.category === category;
        } ) : cat.types,
      };
      categories.push( newCat );
    }
    return categories;
  }

  static getInvestmentAccountType( typeLabel: string ) {
    for ( const cat of this.getInvestmentAccountTypes( 'Investment' ) ) {
      const type = cat.types.find( ( t: any ) => {
        return t.type === typeLabel;
      } );
      if ( type ) {
        return type;
      }
    }
  }

  static getCreditQualityProp( position: Position ) {
    // credit quality
    let creditQualityValue: string;
    if ( position.aaa === 1 ) {
      creditQualityValue = 'aaa';
    } else if ( position.aa === 1 ) {
      creditQualityValue = 'aa';
    } else if ( position.a === 1 ) {
      creditQualityValue = 'a';
    } else if ( position.bbb === 1 ) {
      creditQualityValue = 'bbb';
    } else if ( position.bb === 1 ) {
      creditQualityValue = 'bb';
    } else if ( position.b === 1 ) {
      creditQualityValue = 'b';
    } else if ( position.below_b === 1 ) {
      creditQualityValue = 'below_b';
    } else if ( position.not_rated === 1 ) {
      creditQualityValue = 'not_rated';
    }

    return creditQualityValue;
  }

  static getSectorProp( position: Position ) {
    // bond sector
    let sectorValue: string;
    if ( position.bond_primary_sector_agency_mbs === 1 ) {
      sectorValue = 'bond_primary_sector_agency_mbs';
    } else if ( position.bond_primary_sector_abs === 1 ) {
      sectorValue = 'bond_primary_sector_abs';
    } else if ( position.bond_primary_sector_bank_loan === 1 ) {
      sectorValue = 'bond_primary_sector_bank_loan';
    } else if ( position.bond_primary_sector_commercial_mbs === 1 ) {
      sectorValue = 'bond_primary_sector_commercial_mbs';
    } else if ( position.bond_primary_sector_convertibles === 1 ) {
      sectorValue = 'bond_primary_sector_convertibles';
    } else if ( position.bond_primary_sector_corporate_bond === 1 ) {
      sectorValue = 'bond_primary_sector_corporate_bond';
    } else if ( position.bond_primary_sector_covered_bond === 1 ) {
      sectorValue = 'bond_primary_sector_covered_bond';
    } else if ( position.bond_primary_sector_future_forward === 1 ) {
      sectorValue = 'bond_primary_sector_future_forward';
    } else if ( position.bond_primary_sector_government === 1 ) {
      sectorValue = 'bond_primary_sector_government';
    } else if ( position.bond_primary_sector_government_related === 1 ) {
      sectorValue = 'bond_primary_sector_government_related';
    } else if ( position.bond_primary_sector_non_agency_residential_mbs === 1 ) {
      sectorValue = 'bond_primary_sector_non_agency_residential_mbs';
    } else if ( position.bond_primary_sector_preferred === 1 ) {
      sectorValue = 'bond_primary_sector_preferred';
    } else if ( position.bond_primary_sector_us_municipal_tax_advantaged === 1 ) {
      sectorValue = 'bond_primary_sector_us_municipal_tax_advantaged';
    }

    return sectorValue;
  }

  static patchBondGlobalFields( position: Position, form: FormGroup ) {
    if ( position.bonds_us === 1 ) {
      form.patchValue( { us_non_us: 'us' } );
    } else if ( position.bonds_non_us === 1 ) {
      form.patchValue( { us_non_us: 'non_us' } );
      setTimeout( () => {
        form.patchValue( { emerging_markets: position.bonds_region_emerging_markets ? 'emerging' : 'developed' } );
      } );
    }
  }

  // FORM PATCHING FUNCTIONS

  static patchForm( existingAccountForm: ExistingManualAccountForm, account: Account ) {
    const patchFunctions = {
      'annuity': ManualAccountUtil.patchAnnuityForm,
      'bank': ManualAccountUtil.patchBankForm,
      'bond': ManualAccountUtil.patchBondForm,
      'cash': ManualAccountUtil.patchCashForm,
      'crypto': ManualAccountUtil.patchCryptoForm,
      'investment': ManualAccountUtil.patchInvestmentForm,
      'loan': ManualAccountUtil.patchLoanForm,
      'realAsset': ManualAccountUtil.patchRealAssetForm,
      'stock': ManualAccountUtil.patchStockForm,
      'stockOption': ManualAccountUtil.patchStockOptionForm,
      'term-life-insurance': ManualAccountUtil.patchTermLifeInsuranceForm,
    };

    patchFunctions[ existingAccountForm?.formType ]( existingAccountForm.form, account );

  }

  static patchAnnuityForm( form: FormGroup, account: Account ) {
    // patch in values manually because there is too much that needs to be parsed and pulled out
    form.controls.name.setValue( account.name );
    form.controls.annuity_type.setValue( account.annuity_type );
    const paymentInfo = typeof account.annuity_payment_info === 'string' ?
      JSON.parse( account.annuity_payment_info ) : account.annuity_payment_info;
    form.controls.payment.setValue( paymentInfo.payment );
    Util.updateInputCurrencyFormat( 'payment', form );
    form.controls.payment_frequency.setValue( paymentInfo.payment_frequency );
    const remainingLife = typeof account.expected_remaining_life === 'string' ?
      JSON.parse( account.expected_remaining_life ) : account.expected_remaining_life;
    form.controls.birth_date.setValue( moment( remainingLife.birth_date ).utc() );
    form.controls.gender.setValue( remainingLife.gender );
    if ( remainingLife.joint_birth_date ) {
      form.controls.joint_birth_date.setValue( moment( remainingLife.joint_birth_date ).utc() );
      form.controls.joint_gender.setValue( remainingLife.joint_gender );
    }

    const position = account.positions[ 0 ];

    if ( position ) {

      form.patchValue( { credit_quality: ManualAccountUtil.getCreditQualityProp( position ) } );

      form.patchValue( { sector: ManualAccountUtil.getSectorProp( position ) } );

      // US / Non-US / Emerging markets
      ManualAccountUtil.patchBondGlobalFields( position, form );
    }
  }

  static patchTermLifeInsuranceForm( form: FormGroup, account: Account ): void {
    form.controls.name.setValue( account.name );
    const remainingLife = typeof account.expected_remaining_life === 'string' ?
      JSON.parse( account.expected_remaining_life ) : account.expected_remaining_life;
    const insuranceInfo = typeof account.insurance_info === 'string' ?
      JSON.parse( account.insurance_info ) : account.insurance_info;
    form.patchValue( {
      birth_date: ManualAccountUtil.formatDate( remainingLife.birth_date ),
      gender: remainingLife.gender,
      life_insurance_term: insuranceInfo.life_insurance_term,
      effective_date: moment( insuranceInfo.effective_date ).utc(),
      amount_of_insurance: insuranceInfo.amount_of_insurance,
      premium: insuranceInfo.premium,
      institution_name: account.institution_name,
    } );
    Util.updateInputCurrencyFormat( 'amount_of_insurance', form );
    Util.updateInputCurrencyFormat( 'premium', form );

  }

  static formatDate( date: Moment | Date | string ) {
    return moment( date ).utc().format( 'YYYY-MM-DD' );
  }

  static patchBankForm( form: UntypedFormGroup, account: Account ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );
    if ( position ) {
      form.patchValue( position );
      Util.updateInputCurrencyFormat( 'value', form );
      if ( position.annualized_yield ) {
        form.controls.annualized_yield.patchValue( position.annualized_yield * 100 );
        Util.updateInputPercentFormat( 'annualized_yield', form, true );
      }
    } else {
      // TODO: need to inform user that the position has been lost for some reason
      console.error( `ERROR LOADING POSITION FOR MANUAL BANK ACCOUNT: ${ account.name }` );
    }
  }

  static patchCashForm( form: UntypedFormGroup, account: Account ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );
    Util.updateInputCurrencyFormat( 'value', form );
    if ( position ) {
      form.patchValue( position );
    } else {
      // TODO: need to inform user that the position has been lost for some reason
      console.error( `ERROR LOADING POSITION FOR MANUAL CASH ACCOUNT: ${ account.name }` );
    }
  }

  static patchCryptoForm( form: UntypedFormGroup, account: Account ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );
    if ( position ) {
      form.patchValue( position );
      Util.updateInputCurrencyFormat( 'value', form );
      Util.updateInputCurrencyFormat( 'price', form );
      Util.updateInputCurrencyFormat( 'cost_basis', form );
    } else {
      console.error( `ERROR LOADING POSITION FOR MANUAL CRYPTO ACCOUNT: ${ account.name }` );
    }
  }

  static commonBondPatchFormHelper( form: UntypedFormGroup, position: Position, selectedLoanTermUnits?: MatButtonToggleGroup ) {
    form.patchValue( position );


    if ( position.loan_term ) {
      // need a short term fix for existing loans that aren't stored in months
      const calcedTerm = moment( position.maturity_date ).diff( moment( position.loan_origination_date ), 'months' );
      if ( calcedTerm !== position.loan_term ) {
        position.loan_term = calcedTerm;
        form.controls.loan_term.setValue( calcedTerm );
      }

      form.controls.loan_term_in_months.setValue( position.loan_term );
      if ( position.loan_term % 12 === 0 ) {
        // term was probably in years, so let's divide by 12 and set the unit to years
        form.controls.loan_term.setValue( position.loan_term / 12 );
        if ( selectedLoanTermUnits ) {
          selectedLoanTermUnits.value = 'years';
        }
      } else {
        if ( selectedLoanTermUnits ) {
          selectedLoanTermUnits.value = 'months';
        }
      }
    }

    if ( position.maturity_date ) {
      form.controls.maturity_date.setValue( moment( position.maturity_date ).utc() );  // must use moment object
    }
    if ( position.loan_origination_date ) {
      form.controls.loan_origination_date.setValue( moment( position.loan_origination_date ).utc() );  // must use moment object
    }
  }

  static patchBondForm( form: UntypedFormGroup, account: Account, selectedLoanTermUnits?: MatButtonToggleGroup ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );

    if ( position ) {

      ManualAccountUtil.commonBondPatchFormHelper( form, position, selectedLoanTermUnits );

      form.patchValue( { credit_quality: ManualAccountUtil.getCreditQualityProp( position ) } );

      form.patchValue( { sector: ManualAccountUtil.getSectorProp( position ) } );

      // US / Non-US / Emerging markets
      ManualAccountUtil.patchBondGlobalFields( position, form );

      // patch values in percent manually since they are stored in decimal
      form.patchValue( { coupon: position.coupon * 100 } );
      // form.patchValue( { annualized_yield: position.annualized_yield * 100 } );
      // form.patchValue( { treasury_rate: position.treasury_rate * 100 } );
      // form.patchValue( { risk_premium: position.risk_premium * 100 } );
      form.patchValue( { current_market_rate: position.current_market_rate * 100 } );

      // update formatting
      Util.updateInputCurrencyFormat( 'maturity_value', form );
      Util.updateInputCurrencyFormat( 'cost_basis', form );
      Util.updateInputCurrencyFormat( 'price', form );
      Util.updateInputCurrencyFormat( 'value', form );
      Util.updateInputPercentFormat( 'coupon', form, true );
      Util.updateInputPercentFormat( 'current_market_rate', form, true );
      // Util.updateInputPercentFormat( 'treasury_rate', form, true );
      // Util.updateInputPercentFormat( 'risk_premium', form, true );
      Util.updateInputDecimalFormat( 'quantity', form );

      // getClosestRate();

    } else {
      // TODO: need to inform user that the position has been lost for some reason
      console.error( `ERROR LOADING POSITION FOR MANUAL BOND: ${ account.name }` );
    }
  }

  static patchInvestmentForm( form: UntypedFormGroup, account: Account ) {
    form.patchValue( account );
    form.controls.investment_account_type.setValue( ManualAccountUtil.getInvestmentAccountType( account.investment_account_type ) );
    form.controls.positions.setValue( account.positions );
  }

  static patchLoanForm( form: UntypedFormGroup, account: Account, selectedLoanTermUnits?: MatButtonToggleGroup ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );

    if ( position ) {

      ManualAccountUtil.commonBondPatchFormHelper( form, position, selectedLoanTermUnits );

      if ( position.maturity_date ) {
        form.controls.maturity_date.setValue( moment( position.maturity_date ).utc() );  // must use moment object
      }
      if ( position.loan_origination_date ) {
        form.controls.loan_origination_date.setValue( moment( position.loan_origination_date ).utc() );  // must use moment object
      }
      // patch values in percent manually since they are stored in decimal
      form.patchValue( { coupon: position.coupon * 100 } );
      form.patchValue( { annualized_yield: position.annualized_yield * 100 } );
      // form.patchValue( { treasury_rate: position.treasury_rate * 100 } );
      // form.patchValue( { risk_premium: position.risk_premium * 100 } );
      form.patchValue( { current_market_rate: position.current_market_rate * 100 } );

      form.patchValue( { credit_quality: ManualAccountUtil.getCreditQualityProp( position ) } );

      form.patchValue( { sector: ManualAccountUtil.getSectorProp( position ) } );

      // US / Non-US / Emerging markets
      ManualAccountUtil.patchBondGlobalFields( position, form );


      // cost basis is no longer part of the form and is auto set
      // Util.updateInputCurrencyFormat( 'cost_basis', form );
      // update formatting
      Util.updateInputCurrencyFormat( 'price', form );
      Util.updateInputCurrencyFormat( 'value', form );
      Util.updateInputCurrencyFormat( 'loan_principal_paydown', form );
      Util.updateInputCurrencyFormat( 'outstanding_balance', form );
      Util.updateInputPercentFormat( 'coupon', form, true );
      Util.updateInputPercentFormat( 'current_market_rate', form, true );
      // Helpers.updateInputPercentFormat( 'annualized_yield', form );
      // Util.updateInputPercentFormat( 'treasury_rate', form );
      // Util.updateInputPercentFormat( 'risk_premium', form );
      Util.updateInputDecimalFormat( 'quantity', form );
      Util.updateInputDecimalFormat( 'original_loan_amount', form );

      // update computed values
      // maturityDateChanged();

    } else {
      // TODO: need to inform user that the position has been lost for some reason
      console.error( `ERROR LOADING POSITION FOR MANUAL LOAN: ${ account.name }` );
    }
  }

  /**
   *
   * @param account - account whose form is being loaded
   * @param form - form group
   * @param correspondingAccounts - array of potential corresponding accounts
   * @param key - {string} - corresponding_asset_id or corresponding_liability_id, depending on what form is calling
   */
  static checkForCorrespondingAccount( account: Account, form: UntypedFormGroup, correspondingAccounts: Account[], key: string ): void {
    if ( !form.controls[ key ]?.value || form.controls[ key ]?.value === 0 || form.controls[ key ]?.value === '0' ) {
      // need the opposite key for the comparison
      const otherKey = ( key === 'corresponding_asset_id' ) ? 'corresponding_liability_id' : 'corresponding_asset_id';
      // if none already set, see if there is another account pointing at this one
      const correspondingId = correspondingAccounts.find( ( a: any ) => {
        return a[ otherKey ] === account.account_id;
      } )?.account_id;
      if ( correspondingId ) {
        form.controls[ key ]?.setValue( correspondingId );
      }
    }
  }

  static patchRealAssetForm( form: UntypedFormGroup, account: Account ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );

    if ( position ) {
      form.patchValue( position );

      form.patchValue( { annualized_yield: position.annualized_yield * 100 } );
      Util.updateInputCurrencyFormat( 'value', form );
      Util.updateInputCurrencyFormat( 'price', form );
      Util.updateInputCurrencyFormat( 'cost_basis', form );
      Util.updateInputCurrencyFormat( 'dollar_flow', form );
      Util.updateInputPercentFormat( 'annualized_yield', form, true );


    } else {
      // TODO: need to inform user that the position has been lost for some reason
      console.error( `ERROR LOADING POSITION FOR MANUAL REAL ASSET: ${ account.name }` );
    }
  }

  static commonStockPatchHelper( form: UntypedFormGroup, position: Position ) {
    // US / Non-US / Emerging markets
    if ( position.us === 1 ) {
      form.patchValue( { us_non_us: 'us' } );
    } else if ( position.non_us === 1 ) {
      form.patchValue( { us_non_us: 'non_us' } );

      // why doesn't this work?
      form.patchValue( { emerging_markets: parseInt( String( position.emerging_markets ) ) } );
    }

    // patch market cap
    let marketCap;
    if ( position.large_cap === 1 ) {
      marketCap = 'large';
    } else if ( position.mid_cap === 1 ) {
      marketCap = 'mid';
    } else if ( position.small_cap === 1 ) {
      marketCap = 'small';
    }
    if ( marketCap ) {
      form.patchValue( { company_market_cap: marketCap } );
    }

    // patch stock sector
    let sectorValue;
    if ( position.sector_basic_materials === 1 ) {
      sectorValue = 'sector_basic_materials';
    } else if ( position.sector_communication_services === 1 ) {
      sectorValue = 'sector_communication_services';
    } else if ( position.sector_consumer_cyclical === 1 ) {
      sectorValue = 'sector_consumer_cyclical';
    } else if ( position.sector_consumer_defensive === 1 ) {
      sectorValue = 'sector_consumer_defensive';
    } else if ( position.sector_energy === 1 ) {
      sectorValue = 'sector_energy';
    } else if ( position.sector_financial_services === 1 ) {
      sectorValue = 'sector_financial_services';
    } else if ( position.sector_healthcare === 1 ) {
      sectorValue = 'sector_healthcare';
    } else if ( position.sector_industrials === 1 ) {
      sectorValue = 'sector_industrials';
    } else if ( position.sector_real_estate === 1 ) {
      sectorValue = 'sector_real_estate';
    } else if ( position.sector_technology === 1 ) {
      sectorValue = 'sector_technology';
    } else if ( position.sector_utilities === 1 ) {
      sectorValue = 'sector_utilities';
    }
    form.patchValue( { sector: sectorValue } );

    // patch value/growth/blend
    let value_growth;
    if ( position.value_stocks === 1 ) {
      value_growth = 'value_stocks';
    } else if ( position.blend_stocks === 1 ) {
      value_growth = 'blend_stocks';
    } else if ( position.growth_stocks === 1 ) {
      value_growth = 'growth_stocks';
    }
    if ( value_growth ) {
      form.patchValue( { growth_value: value_growth } );
    }

  }

  static patchStockForm( form: UntypedFormGroup, account: Account ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );
    if ( position ) {
      form.patchValue( position );


      ManualAccountUtil.commonStockPatchHelper( form, position );

      // patch values in percent manually since they are stored in decimal
      form.patchValue( { annualized_yield: position.annualized_yield * 100 } );

      // update formatting
      Util.updateInputCurrencyFormat( 'cost_basis', form );
      Util.updateInputCurrencyFormat( 'price', form );
      Util.updateInputCurrencyFormat( 'value', form );
      Util.updateInputCurrencyFormat( 'dividend_per_share', form );
      Util.updateInputPercentFormat( 'annualized_yield', form, true );
      Util.updateInputDecimalFormat( 'quantity', form );
    }
  }

  static patchStockOptionForm( form: UntypedFormGroup, account: Account ) {
    const position = account.positions[ 0 ];
    form.patchValue( account );

    if ( position ) {
      form.patchValue( position );


      if ( position.maturity_date ) {
        form.controls.maturity_date.setValue( moment( position.maturity_date )/*.utc()*/ ); // must use moment object
      }

      ManualAccountUtil.commonStockPatchHelper( form, position );

      // patch values in percent manually since they are stored in decimal
      form.patchValue( { volatility: position.volatility * 100 } );
      form.patchValue( { treasury_rate: position.treasury_rate * 100 } );

      // update formatting
      Util.updateInputCurrencyFormat( 'cost_basis', form );
      Util.updateInputCurrencyFormat( 'price', form );
      Util.updateInputCurrencyFormat( 'value', form );
      Util.updateInputCurrencyFormat( 'exercise_price', form );
      Util.updateInputCurrencyFormat( 'underlying_price', form );
      Util.updateInputPercentFormat( 'treasury_rate', form, true );
      Util.updateInputPercentFormat( 'volatility', form, true );
      Util.updateInputDecimalFormat( 'quantity', form );
      Util.updateInputDecimalFormat( 'shares_per_option', form );
    }
  }

  static parseDividendList( dividendList: any ): DividendInterface[] {
    let list: DividendInterface[];
    if ( typeof dividendList === 'string' ) {
      list = JSON.parse( dividendList );
    }
    list.forEach( ( div: DividendInterface ) => {
      div.dividendDate = moment( div.dividendDate );
    } );

    return list;
  }

  /**
   *
   * @param rates - treasury rates
   * @param duration - years
   */
  static interpolateDurationMatchedTreasuryRate( rates, duration ) {
    const keys = Object.keys( rates );
    let cursorKey = keys[ 0 ];
    const absDuration = Math.abs( duration );
    for ( let i = 1; i <= keys.length; i++ ) {

      const rateInfo = rates[ cursorKey ].yield || !Array.isArray( rates[ cursorKey ] ) ? rates[ cursorKey ] : rates[ cursorKey ][ 0 ];
      // this is the case where the loop has gotten to the end and the remainingLife is larger than the 30-year duration
      if ( i === keys.length ) {
        return duration < 0 ? rateInfo.yield * -1 : rateInfo.yield;
      }
      // if we are not at the end of the loop, check if remaining life is between cursorKey's duration and nextKey's duration
      const nextKey = keys[ i ];
      const nextRateInfo = rates[ nextKey ].yield || !Array.isArray( rates[ nextKey ] ) ? rates[ nextKey ] : rates[ nextKey ][ 0 ];
      if ( rateInfo.duration < absDuration ) { // duration is greater than cursorKey duration
        if ( nextRateInfo.duration > absDuration ) { // duration is less than nextKey duration. we've found the right range
          const numerator = nextRateInfo.duration - absDuration; // (D^+ - D) or (D - D^-)
          const denominator = nextRateInfo.duration - rateInfo.duration; // (D^+ - D^-) or (D^- - D^+)
          const xStar = numerator / denominator; //  xStar = (D^+ - D) / (D^+ - D^-)
          // const yieldRange = nextRateInfo.yield - rateInfo.yield;
          const matchedYield = ( xStar * rateInfo.yield ) + ( ( 1 - xStar ) * nextRateInfo.yield ); // yd = x^* * y^- + (1 - x^*) * y^+
          // return rateInfo.yield + (yieldRange * xStar);
          return duration < 0 ? matchedYield * -1 : matchedYield;
        }
      }
      cursorKey = nextKey;
    }
  }

  /// ANNUITY HELPER FUNCTIONS

  /**
   * this function assumes all the necessary variables are set in the account. It was written for updating the value
   * of an existing annuity on load
   * @param annuityAccount - the account to value
   * @param rates - current treasury rates
   */
  static getPVOfAnnuity( annuityAccount: Account, rates: any ): number {
    const paymentInfo = typeof annuityAccount.annuity_payment_info === 'string' ?
      JSON.parse( annuityAccount.annuity_payment_info ) : annuityAccount.annuity_payment_info;
    const expected_remaining_life = this.getCurrentExpectedRemainingLife( annuityAccount );
    const duration_matched_treasury_rate = this.interpolateDurationMatchedTreasuryRate( rates, expected_remaining_life );
    // p * ((1 - (1 + r) ^ -n) / r)
    const freq = MortgageHelpers.translatePeriodType( paymentInfo.payment_frequency );
    const P = parseFloat( FormsUtil.sanitizeInput( paymentInfo.payment ) );
    const r = duration_matched_treasury_rate.toPrecision( 3 ) / ( freq ); // need to round the rate because we do that in the form by using a two
                                                                          // decimal percent format
    const n = expected_remaining_life *
      freq;
    const numerator = 1 - Math.pow( ( 1 + r ), -1 * n );
    return P * ( numerator / r );
  }

  static getPVOFTermLifeInsurance( tliAccount: Account, rates: any ): any {
    const expected_remaining_life: ExpectedRemainingLife = typeof tliAccount.expected_remaining_life === 'string' ?
      JSON.parse( tliAccount.expected_remaining_life ) : tliAccount.expected_remaining_life;
    const insuranceInfo = typeof tliAccount.insurance_info === 'string' ?
      JSON.parse( tliAccount.insurance_info ) : tliAccount.insurance_info;
    const bd = expected_remaining_life.birth_date;
    const age = Math.floor( Util.convertBirthdateToAge( bd ) );
    const end_of_term: Moment = moment( insuranceInfo.effective_date ).add( insuranceInfo.life_insurance_term, 'years' ); // could this be in months?
    const remainingTerm = end_of_term
      .diff( moment(), 'years' );
    const data: any[] = [];
    let totalPV: number = 0;
    let pvOfPremiums: number = 0;
    let csv = `age,year, treasury rate, probability of death, expected payoff, discount rate, pv of payoff, pv of premium`;
    let totalExpectedPayoff: number = 0;
    for ( let i = 1; i <= remainingTerm; i++ ) {
      const currentAge: number = Math.floor( age + i );
      const probOfDeath: number = this.lifeExpectancyMap[ currentAge ]?.[ expected_remaining_life.gender ]?.death_probability;
      const interpolatedTreasuryRate: number = this.interpolateDurationMatchedTreasuryRate( rates, i - 0.5 );
      const expectedPayoff: number = insuranceInfo.amount_of_insurance * probOfDeath;
      const discountRate: number = Math.pow( ( 1 + ( interpolatedTreasuryRate / 2 ) ), ( 1 + ( 2 * ( i - 1 ) ) ) );
      const pv = expectedPayoff / discountRate;
      totalPV += pv;
      totalExpectedPayoff += expectedPayoff;
      const pvOfPremium = i < remainingTerm ? tliAccount.insurance_info?.premium / Math.pow( 1 + interpolatedTreasuryRate, i ) : 0;
      pvOfPremiums += pvOfPremium;
      data.push( {
        age: currentAge,
        probOfDeath,
        treasuryRate: interpolatedTreasuryRate,
        expectedPayoff,
        discountRate,
        pv,
        pvOfPremium,
        yearsFromNow: i,
      } );
      csv = `${ csv }\n${ currentAge },${ i },${ interpolatedTreasuryRate },${ probOfDeath },${ expectedPayoff },${ discountRate },${ pv },${ pvOfPremium }`;
    }

    // console.log( 'data: ', data );

    return { pv: totalPV, data, totalEP: totalExpectedPayoff, pvOfPremiums, csv };
  }

  static TLIMaturityStructure( data: any ) {
    const maturityStructure = {
      less_than_one_year: 0,
      one_to_three_years: 0,
      three_to_five_years: 0,
      five_to_seven_years: 0,
      seven_to_ten_years: 0,
      ten_to_fifteen_years: 0,
      fifteen_to_twenty_years: 0,
      twenty_to_thirty_years: 0,
      over_thirty_years: 0,
    };
    let cursorSum = 0;

    function check( i: number ) {
      if ( i === 2 ) {
        maturityStructure.one_to_three_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
      if ( i === 4 ) {
        maturityStructure.three_to_five_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
      if ( i === 6 ) {
        maturityStructure.five_to_seven_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
      if ( i === 9 ) {
        maturityStructure.seven_to_ten_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
      if ( i === 14 ) {
        maturityStructure.ten_to_fifteen_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
      if ( i === 19 ) {
        maturityStructure.fifteen_to_twenty_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
      if ( i === 29 ) {
        maturityStructure.twenty_to_thirty_years = cursorSum / data.totalEP;
        cursorSum = 0;
      }
    }

    function lastYear( i: number ) {
      if ( i > 29 ) {
        maturityStructure.over_thirty_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 19 ) {
        maturityStructure.twenty_to_thirty_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 14 ) {
        maturityStructure.fifteen_to_twenty_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 9 ) {
        maturityStructure.ten_to_fifteen_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 6 ) {
        maturityStructure.seven_to_ten_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 4 ) {
        maturityStructure.five_to_seven_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 2 ) {
        maturityStructure.three_to_five_years = cursorSum / data.totalEP;
        return;
      }
      if ( i > 0 ) {
        maturityStructure.one_to_three_years = cursorSum / data.totalEP;
        return;
      }

    }

    for ( let i = 0; i < data.data.length; i++ ) {
      if ( i === 0 ) {
        maturityStructure.less_than_one_year = data.data[ i ].expectedPayoff / data.totalEP;
      } else {
        check( i );
        cursorSum += data.data[ i ].expectedPayoff;

        if ( i === data.data.length - 1 ) {
          lastYear( i );
          break;
        }
      }
    }
    return maturityStructure;
  }

  /**
   * This function calculates the sum of the present values of the future incomes from now till the end of the term
   * @param {number} income - dollar amount of current income
   * @param {number} income_growth - rate at which income is expected to grow
   * @param {number} term - the length of the term of desired insurance
   * @param {object} treasuryRates - today's treasury rates
   */
  static calcLifeInsuranceAmount( income: number, income_growth: number, term: number, treasuryRates: any ): number {

    let sumOfPVs = 0;

    for ( let i = 1; i <= term; i++ ) {
      const futureValue = income * Math.pow( 1 + income_growth, i );
      const interpolatedTreasuryRate = ManualAccountUtil.interpolateDurationMatchedTreasuryRate( treasuryRates, i - 0.5 );
      const discountRate: number = Math.pow( ( 1 + ( interpolatedTreasuryRate / 2 ) ), ( 1 + ( 2 * ( i - 1 ) ) ) );
      const pv = futureValue / discountRate;
      sumOfPVs += pv;
    }
    return sumOfPVs;
  }

  static getCurrentExpectedRemainingLife( annuityAccount: Account ) {
    const expected_remaining_life = typeof annuityAccount.expected_remaining_life === 'string' ?
      JSON.parse( annuityAccount.expected_remaining_life ) : annuityAccount.expected_remaining_life;


    if ( expected_remaining_life.joint_birth_date ) {
      return this.compareRemainingLifeExpectancies( expected_remaining_life );
    } else {
      if ( expected_remaining_life.birth_date ) {
        const age = Util.convertBirthdateToAge( expected_remaining_life.birth_date );
        return ManualAccountUtil.lifeExpectancyMap[ Math.floor( age ) ][ expected_remaining_life.gender ]?.life_expectancy;
      }
    }
  }

  static compareRemainingLifeExpectancies( expected_remaining_life: ExpectedRemainingLife ) {
    if ( expected_remaining_life.birth_date && expected_remaining_life.joint_birth_date ) {
      const jointAge = Util.convertBirthdateToAge( expected_remaining_life.joint_birth_date );
      const jointRemainingLife = ManualAccountUtil.lifeExpectancyMap[ Math.floor( jointAge ) ][ expected_remaining_life.joint_gender ]?.life_expectancy;
      const age = Util.convertBirthdateToAge( expected_remaining_life.birth_date );
      const primaryExpectancy = ManualAccountUtil.lifeExpectancyMap[ Math.floor( age ) ][ expected_remaining_life.gender ]?.life_expectancy;
      if ( jointRemainingLife > primaryExpectancy ) {
        return jointRemainingLife;
      } else {
        return primaryExpectancy;
      }

    } else {
      console.warn( 'Both birth dates are not yet filled out. cannot compare' );
      return 0;
    }
  }

  static setIcon( account: Account ) {
    const defaultIcon = 'favicon.png';
    if ( ManualAccountUtil.typeIsCryptoAccount( account.account_type ) ) {
      const position = account.positions[ 0 ];
      if ( position ) {
        account.favicon = `${ this.cryptoIconsBaseUrl }${ position.ticker.split( '-' )[ 0 ].toLowerCase() }/200`;
      }
      account.faIcon = faCoin;
    } else if ( ManualAccountUtil.typeIsCashAccount( account.account_type ) ) {
      account.faIcon = faMoneyBill;
    } else if ( ManualAccountUtil.typeIsBankAccount( account.account_category, account.account_type ) ) {
      account.faIcon = faUniversity;
    } else if ( account.account_type === 'Real Estate' ) {
      account.faIcon = faHouse;
    } else if ( account.account_type === 'Vehicle' ) {
      account.faIcon = faCar;
    } else if ( account.account_type === 'Valuable(s)' ) {
      account.faIcon = faGem;
    } else if ( ManualAccountUtil.typeIsAnnuity( account.account_type ) ) {
      account.faIcon = faPiggyBank;
    } else if ( ManualAccountUtil.typeIsTermLifeInsurance( account.account_type ) ) {
      account.faIcon = faLifeRing;
    } else if ( ManualAccountUtil.typeIsInvestment( account.account_type ) ) {
      account.faIcon = faLandmark;
    } else if ( ManualAccountUtil.typeIsStock( account.account_type ) ||
      ManualAccountUtil.typeIsStockOption( account.account_type ) ) {
      account.faIcon = faAnalytics;
    } else if ( ManualAccountUtil.typeIsInvestment( account.account_type ) ) {
      account.faIcon = faLandmark;
    } else if ( ManualAccountUtil.typeIsLoan( account.account_type ) ||
      ManualAccountUtil.typeIsPrivateLending( account.account_type ) ) {
      account.faIcon = faHandHoldingUsd;
    } else if ( ManualAccountUtil.typeIsBond( account.account_type ) ) {
      account.faIcon = faFileCertificate;
    } else {
      account.favicon = defaultIcon;
    }
  }

  static typeIsStock( type: string ): boolean {
    return [ 'Stock', 'Restricted Stock' ].includes( type );
  }

  static typeIsStockOption( type: string ): boolean {
    return [ 'Stock Option' ].includes( type );
  }

  static typeIsRealAsset( category: string, type: string ): boolean {
    return category === 'Other' && !this.typeIsCryptoAccount( type );
  }

  static typeIsBond( type: string ): boolean {
    return [ 'Private Borrowing', 'Bond' ].includes( type );
  }

  static typeIsPrivateLending( type: string ): boolean {
    return 'Private Lending' === type;
  }

  static typeIsBankAccount( category: string, type: string ): boolean {
    return category === 'Banking' && !ManualAccountUtil.typeIsCashAccount( type );
  }

  static typeIsCashAccount( type: string ): boolean {
    return type === 'Cash';
  }

  static typeIsCryptoAccount( type: string ): boolean {
    return type === 'Crypto';
  }

  static typeIsInvestment( type: string ): boolean {
    return type === 'Investment';
  }

  static typeIsLoan( type: string ): boolean {
    return [ 'Mortgage Loan', 'Auto Loan' ].includes( type );
  }

  static typeIsAnnuity( type: string ): boolean {
    return type === 'Annuity';
  }

  static typeIsTermLifeInsurance( type: string ): boolean {
    return type === 'Term Life Insurance';
  }

  static lifeExpectancyMap: any = actuaryTable;

  static getAverageLifeExpectancy( age: number ): number {
    const obj = this.lifeExpectancyMap[ age ]?.life_expectancy;
    return ( obj.m + obj.f ) / 2;
  }

  static getLifeExpectancy( age: number, gender: string ): number {
    return this.lifeExpectancyMap[ age ][ gender ]?.life_expectancy;
  }

  static calculateStockOptionPrice( formComponent: StockOptionFormComponent ) {
    const call_put = formComponent.form.controls.call_put.value;
    const underlying_price = FormsUtil.getSanitizedFloatValue( formComponent.form.controls.underlying_price.value, false );
    const exercise_price = FormsUtil.getSanitizedFloatValue( formComponent.form.controls.exercise_price.value, false );
    const maturity_date = moment( formComponent.form.controls.maturity_date.value );
    const volatility = FormsUtil.getSanitizedFloatValue( formComponent.form.controls.volatility.value, true );
    const treasury_rate = formComponent.treasuryRate;
    const matDate = maturity_date;
    const today = BondHelpers.getTodayAsNewMoment();
    const tMaturity = BondHelpers.calcTimeToMaturityDate( matDate );
    let i;
    let adjustedStockPrice = underlying_price;
    let optimalExerciseDate = matDate;
    let dividendListCopy: DividendInterface[];

    if ( formComponent.selectedDividendFrequencyValue === 'Continuous' ) {
      // reduce adjustedStockPrice by PV dividends
      adjustedStockPrice *= Math.exp( -( formComponent.regularDividendAmount / underlying_price ) * tMaturity );
    } else if ( formComponent.dividendList && formComponent.dividendList.length > 0 ) {
      formComponent.dividendList = _.sortBy( formComponent.dividendList, 'dividendDate' ); // sort list by date ascending
      // create copy of dividend list with only valid dividend date/amount pairs
      dividendListCopy = [];
      // adjust stock price by present value of dividends
      for ( i = 0; i < formComponent.dividendList.length; i++ ) {
        // subtract the present value of each dividend payment
        if ( formComponent.dividendList[ i ].dividendDate && formComponent.dividendList[ i ].dividendAmount > 0 ) {
          formComponent.dividendList[ i ].isValid = true;
          formComponent.dividendList[ i ].t = formComponent.dividendList[ i ].dividendDate.diff( today, 'days' ) / 365;
          dividendListCopy.push( formComponent.dividendList[ i ] );
          adjustedStockPrice -= dividendListCopy[ i ].dividendAmount * Math.exp( -treasury_rate * dividendListCopy[ i ].t );
        }
      }
    }

    // calculate the main option value at maturity
    let optionValue = ManualAccountUtil.calcBlackScholesValue( call_put, adjustedStockPrice, exercise_price, treasury_rate, volatility, tMaturity );

    if ( dividendListCopy ) {
      // calculate the option value and the max of value as expiration and value at each dividend date
      let adjustedExercisePrice;
      let optionPricePreDividend;
      let j;
      for ( i = dividendListCopy.length; i > 0; i-- ) {
        adjustedExercisePrice = exercise_price - dividendListCopy[ i - 1 ].dividendAmount;

        if ( i < dividendListCopy.length ) {
          for ( j = dividendListCopy.length; j > i; j-- ) {
            const d1 = dividendListCopy[ i - 1 ].dividendDate;
            const d2 = dividendListCopy[ j - 1 ].dividendDate;
            adjustedExercisePrice -= dividendListCopy[ j - 1 ].dividendAmount * Math.exp( -treasury_rate * d2.diff( d1, 'days' ) / 365 );
          }
        }

        optionPricePreDividend = ManualAccountUtil.calcBlackScholesValue( call_put, adjustedStockPrice, adjustedExercisePrice, treasury_rate, volatility, dividendListCopy[ i - 1 ].t );
        if ( optionPricePreDividend > optionValue ) {
          optionValue = optionPricePreDividend; // set final value of the option equal to the max value at dividend dates and expiration
          optimalExerciseDate = dividendListCopy[ i - 1 ].dividendDate;
        }
      }

    }

    formComponent.optimalExerciseDate = optimalExerciseDate;
    // store the dividends used in the computation
    ManualAccountUtil.storeAdditionalData( dividendListCopy, formComponent.form );

    return optionValue;
  }

  static storeAdditionalData( localDividendList: any, form: FormGroup ) {
    if ( localDividendList && localDividendList.length > 0 ) {
      form.controls.dividend_list.setValue( JSON.stringify( localDividendList ).toString() );
    }
  }

  private static calcCumulativeNormalDistribution( x ) {
    return 0.5 * ( 1 + math.erf( x / Math.sqrt( 2 ) ) );
  }

  private static calcBlackScholesValue( call_put, stockPrice, exercisePrice, riskFreeRate, volatility, t ) {
    let sign;
    if ( call_put.toString().toLowerCase() === 'put' ) {
      sign = -1;
    } else {
      sign = 1;
    }

    const d1 = ( Math.log( stockPrice / exercisePrice ) + ( riskFreeRate + Math.pow( volatility, 2 ) / 2 ) * t ) /
      ( volatility * Math.sqrt( t ) );

    const d2 = ( Math.log( stockPrice / exercisePrice ) + ( riskFreeRate - Math.pow( volatility, 2 ) / 2 ) * t ) /
      ( volatility * Math.sqrt( t ) );

    const n_d1 = this.calcCumulativeNormalDistribution( sign * d1 );
    const n_d2 = this.calcCumulativeNormalDistribution( sign * d2 );

    return sign * ( stockPrice * n_d1 - exercisePrice * Math.exp( -riskFreeRate * t ) * n_d2 );

  }
}
