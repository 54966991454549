<div class="investment-selector-container">
  <div class="row">
    <div class="col-12" *ngIf="finderUser && finderUser.investmentFinderMessage">
      {{ finderUser.investmentFinderMessage }}
      <div *ngIf="finderUser.needConfirmation" style="margin: 10px auto;">
        <button mat-raised-button color="primary" (click)="addNewInvestments( true )">Confirm</button>
        <button mat-raised-button (click)="reset()">Reset</button>
      </div>
    </div>
  </div>

  <!-- Expense Ratio, Index/Active and ETF/Mutual Fund options -->
  <div class="screener-form-container">
    <div class="form-group" *ngIf="form && !finderUser?.needConfirmation">
      <div>
        <mat-form-field class="fund-family-input">
          <mat-chip-list #chipList class="fund-family-chip-list">
            <mat-chip
              *ngFor="let family of fundFamiliesSelected"
              [selectable]="true"
              [removable]="true"
              (removed)="removeFamily(family)">
              {{family}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input matInput #fundFamilyInput class="rip-autocomplete"
                   [formControl]="fundFamilyControl"
                   type="text"
                   name="fundFamilyControl"
                   placeholder="Fund Families"
                   [matAutocomplete]="auto"
                   (focus)="fundFamilyListFocused()"
                   (blur)="fundFamilyListBlurred()"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="true"
                   (matChipInputTokenEnd)="addFamilyChip($event)">
          </mat-chip-list>

          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setFundFamilyOption($event)">
            <mat-option *ngFor="let family of filteredFundFamilyList | async" [value]="family">
              {{family}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="expense-ratio-input screener-input">
          <input matInput
                 id="expense_ratio"
                 [formControl]="$any(form.controls.expense_ratio)"
                 name="expense_ratio"
                 placeholder="Max Expense Ratio"
                 (change)="expenseRatioChanged()">
        </mat-form-field>
        <mat-form-field class="dropdown-input screener-input">
          <mat-select placeholder="Select Index and/or Active Fund"
                      id="index_active"
                      name="index_active"
                      [formControl]="$any(form.controls.index_active)"
                      multiple required>
            <mat-option *ngFor="let ind_act of index_active_options" [value]="ind_act">{{ind_act}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.index_active.hasError('required')">Please select at least one option
          </mat-error>
        </mat-form-field>
        <mat-form-field class="dropdown-input screener-input">
          <mat-select placeholder="Select ETF, Mutual Fund, and/or Closed End Fund"
                      id="etf_mutualfund"
                      name="etf_mutualfund"
                      [formControl]="$any(form.controls.fund_types)"
                      multiple required>
            <mat-option *ngFor="let fund_type of fund_type_selection_options"
                        [value]="fund_type">{{fund_type}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.fund_types.hasError('required')">Please select at least one option
          </mat-error>
        </mat-form-field>
        <button mat-button color="accent" [disabled]="(!form?.valid || !form?.dirty) && !fundFamiliesChanged"
                (click)="applySettings()">
          Apply Settings
        </button>
        <button mat-button *ngIf="form?.dirty || fundFamiliesChanged" (click)="resetSettings()">
          Reset
        </button>
      </div>

    </div>
  </div>
  <div>

  </div>

  <!--<div class="modal-body">-->
  <mat-tab-group *ngIf="!chosenSecurityData" #tabGroup (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Security/Fund by Name">
      <div class="screener-tab-body">
        <rip-security-finder (selected)="specificSecuritySelected($event)"></rip-security-finder>
      </div>
    </mat-tab>
    <mat-tab label="Stock Fund" *ngIf="!tabsToHide?.stocks">
      <div *ngIf="selectedTab === 'Stock Fund'" class="screener-tab-body">
        <rip-screener [mainSector]="'stocks'"
                      [fundFamiliesSelected]="fundFamiliesSelected"
                      [fundTypeOptionsSelected]="fundTypeOptionsSelected"
                      [multiSelect]="multiSelect"
                      [filterOptions]="filterOptions"
                      [forBenchmarkProxy]="forBenchmarkProxy"
                      (initComplete)="screenerInitialized($event)"
                      (securitiesSelected)="securitiesSelected($event)">

        </rip-screener>
      </div>
    </mat-tab>
    <mat-tab label="Bond Fund" *ngIf="!tabsToHide?.bonds">
      <div *ngIf="selectedTab === 'Bond Fund'" class="screener-tab-body">
        <rip-screener [mainSector]="'bonds'"
                      [fundFamiliesSelected]="fundFamiliesSelected"
                      [fundTypeOptionsSelected]="fundTypeOptionsSelected"
                      [multiSelect]="multiSelect"
                      [filterOptions]="filterOptions"
                      [forBenchmarkProxy]="forBenchmarkProxy"
                      (initComplete)="screenerInitialized($event)"
                      (securitiesSelected)="securitiesSelected($event)">

        </rip-screener>
      </div>
    </mat-tab>
    <mat-tab label="Money Market Fund" *ngIf="!tabsToHide?.moneyMarket">
      <div *ngIf="selectedTab === 'Money Market Fund'" class="screener-tab-body">
        <rip-screener [mainSector]="'money_market'"
                      [fundFamiliesSelected]="fundFamiliesSelected"
                      [fundTypeOptionsSelected]="fundTypeOptionsSelected"
                      [multiSelect]="multiSelect"
                      [filterOptions]="filterOptions"
                      [forBenchmarkProxy]="forBenchmarkProxy"
                      (initComplete)="screenerInitialized($event)"
                      (securitiesSelected)="securitiesSelected($event)">
          <!-- no fundTypeOptions required for money markets-->

        </rip-screener>
      </div>
    </mat-tab>
    <!--    <mat-tab label="Other Fund">
          <div>
            <rip-screener [mainSector]="'other'"
                          [fundFamiliesSelected]="fundFamiliesSelected"
                          [fundTypeOptionsSelected]="fundTypeOptionsSelected"
                          (securitiesSelected)="securitiesSelected($event)"
                          (fundFamilySelectionChange)="fundFamilySelectionChange($event)"
                          (fundTypeOptionSelectionsChange)="fundTypeOptionSelectionsChange($event)">

            </rip-screener>
          </div>
        </mat-tab>-->
  </mat-tab-group>

</div>
