import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RipsawCurrencyPipe, RipsawLongPercentPipe } from '../../../../../theme/pipes';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { Util } from '../../../../../utils/util.service';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../../../shared/workspace-permission';
import { AppStoreService } from '../../../../../store';
import { take } from 'rxjs/operators';
import { Account, FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-account-fees-editor',
  template: `
    <div class="account-fee-container" *ngIf="accountTypeValid">
      <span class="account-fee-form-fields flexing center-vertically">
        <button mat-raised-button class="icon-button" (click)="changeType()" type="button"
                matTooltip="Click To Change Between $ and %">
          <fa-icon [icon]="type === 'dollar' ? faDollarSign : faPercent" [fixedWidth]="true" size="sm"></fa-icon>
        </button>
        <mat-form-field *ngIf="type === 'percent'" appearance="outline">
          <input matInput
                 type="text"
                 placeholder="Annualized Account Fees (%)"
                 [formControl]="percentControl"
                 (change)="percentValueChanged()"
          />
          <mat-label>Annualized Account Fees (%)</mat-label>
        </mat-form-field>
        <mat-form-field *ngIf="type === 'dollar'" appearance="outline">
          <input matInput
                 type="text"
                 placeholder="Annualized Account Fees ($)"
                 [formControl]="dollarControl"
                 (change)="percentValueChanged()"
          />
          <mat-label>Annualized Account Fees ($)</mat-label>
        </mat-form-field>
      </span>
      <!--
      (blur)="updateFee($event, 'percent')"
      (blur)="updateFee($event, 'dollar')"
      -->

    </div>
  `,
  styleUrls: [ `./account-fees-editor.component.scss` ],
} )

export class AccountFeesEditorComponent extends WithWorkspacePermission implements OnInit, AfterViewInit {

  faDollarSign = faDollarSign;
  faPercent = faPercent;

  @Input() account: Account;
  @Input() accountForm: UntypedFormGroup;
  percentControl: UntypedFormControl;
  dollarControl: UntypedFormControl;
  currentPercentValue: any;
  currentDollarValue: any;
  type: string = 'percent';
  ripPercentPipe: RipsawLongPercentPipe;
  ripDollarPipe: RipsawCurrencyPipe;

  accountTypeValid: boolean = true;

  constructor(
    private appStoreService: AppStoreService,
  ) {
    super();

    this.ripPercentPipe = new RipsawLongPercentPipe();
    this.ripDollarPipe = new RipsawCurrencyPipe();
    this.percentControl = new UntypedFormControl( 0 );
    this.dollarControl = new UntypedFormControl( 0 );
  }

  ngOnInit(): void {
    this.restrictByPermission();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.accountTypeValid = this.account && Util.accountCanHaveFees( this.account );
      if ( this.account && this.account.management_fee ) {
        this.percentControl.setValue( this.ripPercentPipe.transform( this.account.management_fee ) );
      } else {
        this.percentControl.setValue( 0 );
      }
    } );
  }

  changeType() {
    if ( this.type === 'dollar' ) {
      this.type = 'percent';
      const sanitized: any = parseFloat( FormsUtil.sanitizeInput( this.dollarControl.value ) ) || 0;
      const percent = sanitized / this.account.value;
      this.percentControl.setValue( this.ripPercentPipe.transform( percent ) );
    } else {
      this.type = 'dollar';
      const sanitized: any = parseFloat( FormsUtil.sanitizeInput( this.percentControl.value ) ) || 0;
      const dollar = ( sanitized / 100 ) * this.account.value;
      this.dollarControl.setValue( this.ripDollarPipe.transform( dollar ) );
    }
  }

  percentValueChanged() {
    const sanitized: any = parseFloat( FormsUtil.sanitizeInput( this.percentControl.value ) ) || 0;
    this.accountForm.controls.management_fee.setValue( ( sanitized / 100 ) );
  }

  dollarValueChanged() {
    const sanitized: any = parseFloat( FormsUtil.sanitizeInput( this.dollarControl.value ) ) || 0;
    const percent = sanitized / this.account.value;
    this.accountForm.controls.management_fee.setValue( percent );
  }

  private restrictByPermission(): void {
    this.appStoreService.loadedWorkspacePermissions$
      .pipe( take( 1 ) )
      .subscribe( allPerms => {
        const hasAccountView = hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNTS_VIEW ] );
        if ( hasAccountView ) {
          this.percentControl.disable();
          this.dollarControl.disable();
        }
      } );
  }


}
