import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GlobalState } from '../../../../global.state';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { environment } from '../../../../../environments/environment';
import { EVENT_NAMES } from '../../../../utils/enums';

@Component( {
  selector: 'rip-manual-account-form',
  template: `
    <div></div>`,
  styles: [ '' ],
} )

export class ManualAccountFormComponent {

  @Input() form?: UntypedFormGroup;
  @Input() label?: string;
  @Input() matcher?;
  @Input() type?;
  @Input() account?;
  @Input() inRevision?: boolean = false;

  @ViewChild( 'formElement', { static: false } ) formElement: ElementRef;

  subscriberName: string = '';

  patchForm() {
  }

  autofocus() {
    if ( this.form ) {

      let controlName = 'description';
      if ( this.type === 'Vehicle' ) {
        controlName = 'year';
      } else if ( this.form.controls.name ) {
        controlName = 'name';
      }

      if ( this.formElement ) {
        const element = this.formElement.nativeElement.querySelector( `.mat-input-element[formControlName="${ controlName }"]` );
        if ( element ) {
          setTimeout( () => element.focus(), 0 );
        }
      }
    }
  }

  setupUpdateSubscription( state: GlobalState ) {
    this.subscriberName += this.account?.account_id ? `_${ this.account.account_id }` : '_new';
    if ( environment.env !== 'prod' ) {
      window['ripsaw_' + this.subscriberName] = this;
    }
    state.subscribe( EVENT_NAMES.MANUAL_ACCOUNT_UPDATED,( account: Account ) => {
      if ( this.account && account.account_id === this.account.account_id ) {
        this.account = account;
        this.patchForm();
      }
    }, this.subscriberName );
  }

  unsubscribeFromUpdate( state: GlobalState ) {
    state.unsubscribe( EVENT_NAMES.MANUAL_ACCOUNT_UPDATED, this.subscriberName );
    if ( environment.env !== 'prod' ) {
      delete window['ripsaw' + this.subscriberName];
    }
  }


}
