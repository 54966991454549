import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Util } from '../utils/util.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Auth } from '../auth.service';


@Injectable()
export class PromoCodeService {

  private readonly lambdaStripeUrl: string;
  private readonly baseApiPath: string;

  constructor( private _http: HttpClient ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaStripeUrl = `${ this.baseApiPath }/stripe`;
  }

  getPromoCode( code: string ) {
    const url = `${ this.lambdaStripeUrl }/promoCode/${ code }`;
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  applyPromoCode( promo: any, user_id: string, subscription_id: string ) {
    const url = `${ this.lambdaStripeUrl }/promoCode`;
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    return this._http.put( url, { promo, user_id, subscription_id }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  removeDiscount( subscription_id: string ) {
    const url = `${ this.lambdaStripeUrl }/discount/${ subscription_id }`;
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    return this._http.delete( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  /**
   *
   * @param dealId - string - id of the deal being requested
   * should return a StripeDeal
   */
  lookupDeal( dealId: string ): Observable<any> {
    const url: string = `${ this.lambdaStripeUrl }/deals/${ dealId }`;
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
