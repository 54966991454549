import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { GlossaryUtil } from '../../../utils/glossary.util';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { GlobalState } from '../../../global.state';
import { ExpectedWealthIssue } from '../../../utils/dataInterfaces';
import { environment } from '../../../../environments/environment';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../shared/workspace-permission';
import { Subject } from 'rxjs';
import { AppStoreService } from '../../../store';
import { takeUntil } from 'rxjs/operators';

@Component( {
  selector: 'rip-probability-control',
  template: `
    <div class="chart-control-item">
      <div>Probability of Downside and Upside Wealth Outcomes <span class="disclaimer form-disclaimer"
                                                                    (click)="openGlossary( 20 )">
        <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
      </span></div>
      <div class="form-group" style="position: relative;">
        <mat-form-field appearance="outline">
          <mat-select [formControl]="probabilitySelectControl" (selectionChange)="updateProbability()">
            <mat-option [value]="0.01">1%</mat-option>
            <mat-option [value]="0.05">5%</mat-option>
            <mat-option [value]="0.1">10%</mat-option>
            <mat-option [value]="0.15">15%</mat-option>
            <mat-option [value]="0.25">25%</mat-option>
          </mat-select>
        </mat-form-field>
        <nb-badge [status]="'warning'"
                  text="2"
                  class="investor-info-analysis-badge"
                  *ngIf="issues?.length > 0"></nb-badge>
      </div>
    </div>
  `,
} )

export class ProbabilityControlComponent extends WithWorkspacePermission implements OnInit, OnDestroy {
  private destroyed$$ = new Subject<void>();

  @Input() issues: ExpectedWealthIssue[];
  @Input() actionsAvailable: boolean;

  probabilitySelectControl: UntypedFormControl = new UntypedFormControl();

  subscriberName: string = 'probabilityControl';

  constructor( public benchmarkState: BenchmarkState,
               private glossaryUtil: GlossaryUtil,
               private _state: GlobalState,
               private appStoreService: AppStoreService,
               ) {
    super();
    this.probabilitySelectControl.setValue(
      benchmarkState.benchmark.probability ?? benchmarkState.riskPotentialDefaultProbability,
    );

    this.subscriberName = `${ this.subscriberName }_${ Math.floor( Math.random() * 1000 ) }`;

    this._state.subscribe( 'probability.updated', ( sourceSubscriberName: string ) => {
      if ( sourceSubscriberName !== this.subscriberName ) {
        this.probabilitySelectControl.setValue( benchmarkState.benchmark.probability );
      }
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window['probabilityControl'] = this;
    }
  }

  ngOnInit(): void {
    this.applyPermissions();
  }

  ngOnDestroy() {
    this._state.unsubscribe( 'probability.updated', this.subscriberName );
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  faQuestionCircle = faQuestionCircle;

  updateProbability() {
    this.benchmarkState.benchmark.probability = parseFloat( this.probabilitySelectControl.value );

    this.benchmarkState?.setupExpectedWealthDataForSelectedFrontierPoint();
    this.benchmarkState?.setupExpectedWealthBuckets();
    this._state.notifyDataChanged( 'probability.updated', this.subscriberName );
  }

  /*
* Function to open the disclaimer modal for displaying the disclaimers
* */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }

  private applyPermissions(): void {
    this.appStoreService.loadedWorkspacePermissions$
      .pipe(takeUntil(this.destroyed$$))
      .subscribe(allPerms => {
        const hasPermissions = hasAnyPermissionFn(allPerms, [this.perm.OWNER, this.perm.INVESTOR_PROFILE_EDIT]);
        if (this.actionsAvailable || hasPermissions) {
          this.probabilitySelectControl.enable();
        } else {
          this.probabilitySelectControl.disable();
        }
      });
  }

}
