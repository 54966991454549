import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class MobileUtil {

  static deviceIsDesktop( detectorService: DeviceDetectorService ): boolean {
    return detectorService.isDesktop();
  }

  static deviceIsTablet( detectorService: DeviceDetectorService ): boolean {
    return detectorService.isTablet();
  }

  static deviceIsMobile( detectorService: DeviceDetectorService ): boolean {
    return detectorService.device === 'iPhone' || detectorService.isMobile() || !detectorService.device || detectorService.device === '';
  }

  static getBottomSheetConfig( data: any ) {
    return {
      panelClass: 'bottom-sheet-panel',
      data,
    };
  }

}
