<!-- Headers-->
<div class="form-group">
    <div class="flexing">
        <fa-icon [icon]="faCoin" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
        <h3 class="manual-account-form-H3" style="padding-bottom: 25px;">Add the details about your digital asset</h3>

    </div>
    (add each coin as it's own account, prices will automatically update)
</div>

<div class="form-group">
    <div>
        Fields with an * are required
    </div>
</div>
<div *ngIf="form" [nbSpinner]="retrievingPrice">
    <form [formGroup]="form" #formElement>
        <rip-trading-view-single-ticker *ngIf="coinHasBeenSelected && form.controls?.ticker?.value?.length > 0"
                                        [ticker]="form.controls?.ticker?.value"
                                        [exchange]="'COINBASE'">

        </rip-trading-view-single-ticker>
        <rip-crypto-query-box #queryBox (coinSelected)="coinSelected($event)" (blurred)="queryBoxBlurred()">
        </rip-crypto-query-box>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="name"
                           [errorStateMatcher]="matcher"
                           placeholder="Name/Description*">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required' )">
                    Description is required
                </mat-error>
                <mat-label>Name/Description*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="price"
                           [errorStateMatcher]="matcher"
                           placeholder="Price ($)*"
                           (change)="priceChanged()">
                </label>
                <mat-error *ngIf="form.controls.price.hasError('required' )">
                    Price is required
                </mat-error>
                <mat-label>Price ($)</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="quantity"
                           [errorStateMatcher]="matcher"
                           placeholder="Quantity*"
                           (change)="quantityChanged()">
                </label>
                <mat-error *ngIf="form.controls.quantity.hasError('required' )">
                    Quantity is required
                </mat-error>
                <mat-label>Quantity*</mat-label>
            </mat-form-field>
        </div>


        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="value"
                           [errorStateMatcher]="matcher"
                           placeholder="Value ($)"
                           (change)="publicUtil.updateInputCurrencyFormat('value', form)">
                </label>
                <mat-label>Value ($)</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="cost_basis"
                           [errorStateMatcher]="matcher"
                           name="cost_basis"
                           placeholder="Cost Basis ($)*"
                           (change)="costBasisChanged()">
                </label>
                <mat-error *ngIf="form.controls.cost_basis.hasError('required')">
                    Cost Basis is required
                </mat-error>
                <mat-label>Cost Basis ($)</mat-label>
            </mat-form-field>
        </div>

    </form>
</div>
