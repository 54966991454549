<cdk-accordion cdkMenu multi [style.display]="'block'" [ripProgressSpinner]="isRefreshing">
  <ng-container *ngFor="let group of menuGroups$ | async; let idx = index">
    <cdk-accordion-item *ngIf="group.items.length"
                        cdkMenuGroup
                        expanded
                        #accordionItem="cdkAccordionItem"
                        [attr.id]="'accordion-header-' + idx"
    >
      <!--group name-->
      <button class="item group-header"
              (click)="accordionItem.toggle()"
      >
        {{ group.name }}
        <mat-icon>{{ accordionItem.expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>

      <!--workspaces-->
      <ng-container *ngIf="accordionItem.expanded">
        <button *ngFor="let item of group.items"
                class="item group-item"
                [title]="item.name"
                [ngClass]="{'taggable': item.isPrimary, 'group-item--progress': item.isCreating}"
                cdkMenuItemRadio
                [cdkMenuItemChecked]="loadedWorkspace?.id === item.id"
                (cdkMenuItemTriggered)="loadedWorkspace.id !== item.id && loadWorkspace.emit({type: group.key, workspace: item})"
        >
          {{ item.name }}

          <rip-tag *ngIf="item.isPrimary"
                   color="primary"
                   weight="medium"
                   class="tag"
          >Primary</rip-tag>
        </button>

        <!--to dashboard-->
        <div class="item more">
          <a [routerLink]="group.dashboardPath"
             cdkMenuItem
          >
            Open Dashboard
            <mat-icon>arrow_forward</mat-icon>
          </a>
        </div>
      </ng-container>
    </cdk-accordion-item>
  </ng-container>
</cdk-accordion>
