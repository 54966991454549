import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';
import {
  RipsawCurrencyPipe,
  RipsawDatePipe,
  RipsawDecimalPipe,
  RipsawLongPercentPipe,
  RipsawPercentPipe,
} from './theme/pipes';
import { Util } from './utils/util.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faGem } from '@fortawesome/pro-light-svg-icons/faGem';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faLandmark } from '@fortawesome/pro-light-svg-icons/faLandmark';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity';
import { faHandHoldingUsd } from '@fortawesome/pro-light-svg-icons/faHandHoldingUsd';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons/faMoneyBill';
import { faCoin } from '@fortawesome/pro-light-svg-icons/faCoin';
import { getHolidays, Holiday } from 'nyse-holidays';
import { environment } from '../environments/environment';
import { AccountType, AvailableFeature, Security } from '@ripsawllc/ripsaw-analyzer';
import moment from 'moment';
import { BenchmarkUtil } from '@ripsawllc/ripsaw-analyzer';
import { EVENT_NAMES, PAGE_NAMES } from './utils/enums';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { Logger } from './utils/logger.service';

export interface ColumnDefinition {
  cellClass?: Function | string;
  cellTemplate?: TemplateRef<any>;
  disclaimer?: number;
  draggable?: boolean;
  excludeFromOptimizer?: boolean;
  frozenLeft?: boolean;
  headerClass?: string;
  headerTemplate?: TemplateRef<any>;
  longName?: string;
  maxWidth?: number | string;
  minWidth?: number | string;
  name?: string;
  pipe?: any;
  prop?: string;
  sortable?: boolean;
  unit?: string;
  width?: number | string;
}

export interface AllColumnDefinition {
  account?: ColumnDefinition;
  alloc?: ColumnDefinition;
}

export interface ColumnGroup {
  label: string;
  id: number;
  columns: string[];
  defaultThreshold: number;
  parent?: string;
  footnote?: string;
}

export interface ColumnSet {
  label?: string;
  columns?: string[];
}

@Injectable()
export class GlobalState {
  activeLinks: any = {
    '/pages/profile': PAGE_NAMES.PROFILE,
    '/pages/balanceSheet': PAGE_NAMES.BALANCE_SHEET,
    '/pages/accounts': PAGE_NAMES.ACCOUNTS,
    '/pages/holdings': PAGE_NAMES.HOLDINGS,
    '/pages/market-information-dashboard':
      PAGE_NAMES.MARKET_INFORMATION_DASHBOARD,
    '/pages/transactions': PAGE_NAMES.TRANSACTIONS,
    '/pages/benchmark': PAGE_NAMES.PLAN_BENCHMARK,
    '/pages/workspaces': PAGE_NAMES.WORKSPACES,
    '/pages/goals-dashboard': PAGE_NAMES.GOALS,
  };

  defaultColumnSetLabel: string = 'Descriptive Information';

  private _data: Subject<Object> = new Subject<Object>();

  private _subscriptions: Map<string, any[]> = new Map<string, any[]>();

  redirectUrl: string;
  currentPageName: PAGE_NAMES;

  globalVars: any;

  /*
  * Function for initializing or re-initializing the global variables that are used throughout the app
  * */
  resetGlobalVars(): void {
    Logger.log(`RESETTING GLOBAL VARS IN RIPSAW ===========================================================================================`);
    this.globalVars = {
      accountCategories: [],
      manualAccountCategories: [],
      allConnections: [],
      connectionsWithoutAccounts: [],
      allAccounts: [],
      allManualAccounts: [],
      allManualAccountsOriginal: [],
      allAccountsOriginal: [],
      allAccountsFromAgg: [],
      securities: [],
      unsavedChanges: false,
      infoType: this.defaultColumnSetLabel,
      editing: false,
      showRE: true,
      loadingAccounts: true,
      benchmark: BenchmarkUtil.defaultBenchmark(),
      accountMapping: {},
      globalOverrides: {},
      userColumnSets: [],
      columnSets: this.defaultColumnSets,
      overrides: {},
      overridesWithNewValues: [],
      globalDashboardHeight: 195,
      loggedInUser: null,
      currentExpiration: null,
      sidebarWidth: {
        expanded: '190px',
        collapsed: '52px',
        extraMargin: '25px',
      },
      sidebarCollapsed: true,
      accountDataGrids: {},
      accountAllocWidgets: {},
      todayIsTradingDay: this.dayIsTradingDay(moment()), // can test other days by passing a string into the moment() function
      dataQualityIssues: [],
      indexBreakoutRows: [],
    };
  }

  private featuresConfig: Map<string, AvailableFeature> = new Map<
    string,
    AvailableFeature
  >();

  setFeaturesConfig(features: AvailableFeature[]) {
    this.featuresConfig = new Map<string, AvailableFeature>();
    for (const feature of features) {
      this.featuresConfig.set(feature.name, feature);
    }
  }

  featureIsOn(name: string): boolean {
    const feature = this.featuresConfig.get(name.toLowerCase());
    return feature?.status === 'on';
  }

  defaultDashboardHeight: number = 195;

  getGlobalDashboardHeight() {
    let height = this.defaultDashboardHeight;
    if (
      this.globalVars.globalDashboardHeight &&
      this.globalVars.globalDashboardHeight > this.defaultDashboardHeight
    ) {
      height = this.globalVars.globalDashboardHeight;
    }
    if (
      this.globalVars.allocWidget &&
      this.globalVars.allocWidget.getHeight() > this.defaultDashboardHeight
    ) {
      height = this.globalVars.allocWidget.getHeight();
    }
    return height;
  }

  chartTitleHeight: number = 20;

  // TODO: change this to a MAP for quicker and easier access
  addToSecuritiesCache(securities: Security[]) {
    if (!(securities instanceof Array)) {
      if (securities['ticker']) {
        // this must be a single instance of a security instead of an array, but it really should be an array
        securities = [securities];
      } else {
        return;
      }
    }
    for (const s of securities) {
      const exists = !!this.globalVars.securities.find((x: any) => {
        return x?.ticker === s?.ticker;
      });
      if (!exists) {
        this.globalVars.securities.push(s);
      }
    }
  }

  securitiesNotInCache(securities: string[]): string[] {
    if (!(securities instanceof Array)) {
      securities = [securities];
    }
    const filteredSecurities = [];
    for (const s of securities) {
      const exists = !!this.globalVars.securities.find((x: any) => {
        return x?.ticker === s;
      });
      if (!exists && s !== null && s !== undefined) {
        filteredSecurities.push(s);
      }
    }
    return filteredSecurities;
  }

  constructor() {
    this.resetGlobalVars();
    if (environment.env !== 'prod') {
      window['ripsawGlobalState'] = this;
      window['ripsawMoment'] = moment;
    }
  }

  /*
   * Function for notifying all subscribers when an event is triggered
   * @param event {String} - event being triggered
   * @param value {Object} - value being passed with the event
   * */
  notifyDataChanged(event: string, value?: any) {
    if (event === EVENT_NAMES.ACCOUNTS_LOADING_ERROR) {
      this.globalVars.accountsLoadingError = true;
    }
    // const current = this._data[event];
    // if ( current !== value ) { // don't think we need this as it causes issues with the global dashboard.
    // seems like it was to prevent events from firing that already had,
    // but we sometimes need that to happen and I don't want to make it more complicated
    this._data[event] = value;
    // Logger.log( `notifyDataChanged: ${event}`);
    this._onEvent({ event, data: this._data[event] });
    // }
  }

  /*
   * Function for letting observers subscribe to events
   * @param event {String} - event the observer wants to subscribe to
   * @param callback {Function} - function to be called by subscriber when the event is triggered
   * @param subscriberName {String} - unique name of a subscriber so they can unsubscribe later
   * */
  subscribe(events: string, callback: Function, subscriberName?: string) {
    if (!subscriberName) {
      console.warn(
        `Creating a subscription with no subscriberName for events: ${events}`,
      );
    }
    const eventsArray = events.split('|');
    for (let e of eventsArray) {
      e = e.trim();
      const subscribers = this._subscriptions.get(e) || [];
      if (subscriberName) {
        const existingSubscription = _.find(subscribers, (s: any) => {
          return s.subscriberName === subscriberName;
        });
        if (existingSubscription) {
          existingSubscription.callback = callback;
        } else {
          subscribers.push({ callback, subscriberName });
        }
      } else {
        subscribers.push({ callback, subscriberName });
      }
      this._subscriptions.set(e, subscribers);
    }
  }

  /*
   * Function for letting observers unsubscribe from events to optimize memory consumption
   * @param event {String} - event the observer wants to subscribe to
   * @param subscriberName {String} - unique name of a subscriber
   * */
  unsubscribe(events: string, subscriberName: string) {
    if (!subscriberName) {
      console.warn(`no subscriberName for events: ${events}`);
    }
    const eventsArray = events.split('|');
    for (let e of eventsArray) {
      e = e.trim();
      const subscribers = this._subscriptions.get(e) || [];
      _.remove(subscribers, (s: any) => {
        return s.subscriberName === subscriberName;
      });
    }
  }

  /*
   * Function for calling all callback functions for an event when it is fired
   * @param data {Object} - data passed by the event notifier that is passed to the callback functions
   * */
  _onEvent(data: any) {
    const subscribers = this._subscriptions.get(data['event']) || [];
    subscribers.forEach(subscriber => {
      subscriber.callback.call(null, data['data']);
    });
  }

  /*
   * Function for creating a column set from the list of columns in allColumnsObject
   * @param cols {Array[String]} - and array of column names to be turned into a set of column objects
   * @param columnType {String} - type of the column to be returned in the set. Value can be either 'alloc' or 'account'
   * @returns returns an array of a column objects that can be used in a ngx-datatable grid
   * */
  makeColumnSet(cols: string[], columnType: string): any {
    const self = this;
    let columnSet = [];
    for (const c of cols) {
      const column = self.allColumnsObject[c];
      if (column) {
        const colDef = _.clone(self.allColumnsObject[c][columnType]);
        if (colDef) {
          columnSet.push(colDef);
        }
      } else {
        const group: any = _.find(self.columnGroupings, { label: c });
        if (group) {
          group.columns.forEach((gc: string) => {
            if (self.allColumnsObject[gc]) {
              const colDef = _.clone(self.allColumnsObject[gc][columnType]);
              if (colDef) {
                columnSet.push(colDef);
              }
            }
          });
        } else {
          console.warn(`could not find column group with label: ${c}`);
        }
      }
    }
    columnSet = _.uniqBy(columnSet, 'prop');
    return columnSet;
  }

  /**
   * returns an array of columnSets that combines defaults with user created
   */
  getAllColumnSets() {
    return [...this.defaultColumnSets, ...this.globalVars.userColumnSets];
  }

  // default set of columnSets
  defaultColumnSets: ColumnSet[] = [
    {
      label: this.defaultColumnSetLabel,
      columns: [
        'real_assets',
        'Cash, Bonds, Stocks',
        // 'security_style',
        // 'morningstar_category',
        'Yields and Expenses',
        'cost_basis',
        'gain_loss',
        // 'maturity_date',
      ],
    },
    {
      label: 'Bond Details',
      columns: [
        'real_assets',
        'Cash, Bonds, Stocks',
        // 'security_style',
        // 'morningstar_category',
        'Bond Global Distribution',
        'Credit Quality Distribution',
        'Maturity Range Distribution',
        'Bond Sector Distribution',
      ],
    },
    {
      label: 'Stock Details',
      columns: [
        'real_assets',
        'Cash, Bonds, Stocks',
        // 'security_style',
        // 'morningstar_category',
        'Stock Global Distribution',
        'Capitalization Distribution',
        'Value, Blend, Growth',
        'Stock Sector Distribution',
      ],
    },
    {
      label: 'All Columns',
      columns: [
        'cost_basis',
        'gain_loss',
        'security_style',
        'morningstar_category',
        'inception_date',
        'effective_duration',
        'real_assets',
        'Cash, Bonds, Stocks',
        'Yields and Expenses',
        'Bond Global Distribution',
        'Credit Quality Distribution',
        'Maturity Range Distribution',
        'Bond Sector Distribution',
        'Stock Global Distribution',
        'Capitalization Distribution',
        'Value, Blend, Growth',
        'Stock Sector Distribution',
        // 'maturity_date',
      ],
    },
    /* {
       label: 'Quantitative Analysis',
       columns: [
         'hist_avg_return',
         'std_dev',
         'eff_duration',
         'slope_duration',
         'var',
         // 'sortino_ratio',
         'risk_factor_exposures',
         'performance_measures',
         'r_squared',
       ],
     },*/
  ];

  /*
   * Function to get a column group using a column name
   * @param column { string } - name of the column whose group should be returned
   * */
  getGroupFromColumn(column: string) {
    if (column === 'real_assets') {
      // real_assets isn't actually in a group in the groupings object below, so we need to change it to cash to give the 'correct' group
      column = 'cash';
    }

    return _.find(this.columnGroupings, (g: any) => {
      const col = _.find(g.columns, (c: any) => {
        return c === column;
      });
      return !!col;
    });
  }

  // groupings for columns that must be kept together because it makes no sense to look at the individual columns
  // without the rest
  columnGroupings: ColumnGroup[] = [
    {
      label: 'Cash, Bonds, Stocks',
      columns: ['cash', 'bonds', 'stocks'],
      defaultThreshold: 0.04,
      id: 1,
    },
    /*{
      label: 'Real Assets, Other and Underlying Asset Type',
      columns: [
        'real_assets',
        'underlying_asset_type',
      ],
      defaultThreshold: 0.05,
      id: 11,
    },*/
    {
      label: 'Yields and Expenses',
      columns: ['annualized_yield', 'expenses', 'expense_ratio'],
      defaultThreshold: 0.01,
      id: 2,
    },
    {
      label: 'Bond Global Distribution',
      columns: ['bonds_us', 'bonds_non_us', 'bonds_region_emerging_markets'],
      defaultThreshold: 0.04,
      id: 3,
      parent: 'bonds',
    },
    {
      label: 'Credit Quality Distribution',
      columns: ['aaa', 'aa', 'a', 'bbb', 'bb', 'b', 'below_b', 'not_rated'],
      defaultThreshold: 0.04,
      id: 4,
      parent: 'bonds',
    },
    {
      label: 'Maturity Range Distribution',
      columns: [
        'less_than_one_year',
        'one_to_three_years',
        'three_to_five_years',
        'five_to_seven_years',
        'seven_to_ten_years',
        'ten_to_fifteen_years',
        'fifteen_to_twenty_years',
        'twenty_to_thirty_years',
        'over_thirty_years',
      ],
      defaultThreshold: 0.04,
      id: 5,
      parent: 'bonds',
    },
    {
      label: 'Bond Sector Distribution',
      columns: [
        'bond_primary_sector_agency_mbs',
        'bond_primary_sector_abs',
        'bond_primary_sector_bank_loan',
        'bond_primary_sector_commercial_mbs',
        'bond_primary_sector_convertibles',
        'bond_primary_sector_corporate_bond',
        'bond_primary_sector_covered_bond',
        'bond_primary_sector_future_forward',
        'bond_primary_sector_government',
        'bond_primary_sector_government_related',
        'bond_primary_sector_non_agency_residential_mbs',
        'bond_primary_sector_preferred',
        'bond_primary_sector_us_municipal_tax_advantaged',
      ],
      defaultThreshold: 0.04,
      id: 6,
      parent: 'bonds',
    },
    {
      label: 'Stock Global Distribution',
      columns: ['us', 'non_us', 'emerging_markets'],
      defaultThreshold: 0.04,
      id: 7,
      parent: 'stocks',
    },
    {
      label: 'Capitalization Distribution',
      columns: ['large_cap', 'mid_cap', 'small_cap'],
      defaultThreshold: 0.04,
      id: 8,
      parent: 'stocks',
    },
    {
      label: 'Value, Blend, Growth',
      columns: ['value_stocks', 'blend_stocks', 'growth_stocks'],
      defaultThreshold: 0.04,
      id: 9,
      parent: 'stocks',
    },

    {
      label: 'Stock Sector Distribution',
      columns: [
        'sector_basic_materials',
        'sector_communication_services',
        'sector_consumer_cyclical',
        'sector_consumer_defensive',
        'sector_energy',
        'sector_financial_services',
        'sector_healthcare',
        'sector_industrials',
        'sector_real_estate',
        'sector_technology',
        'sector_utilities',
      ],
      defaultThreshold: 0.04,
      id: 10,
      parent: 'stocks',
    },
  ];

  // list of all the available columns that can be added to a custom column set
  allColumnsList: string[] = [
    'real_assets',
    'Cash, Bonds, Stocks',
    'Yields and Expenses',
    'cost_basis',
    'gain_loss',
    'Bond Global Distribution',
    'Credit Quality Distribution',
    'Maturity Range Distribution',
    'Bond Sector Distribution',
    'Stock Global Distribution',
    'Capitalization Distribution',
    'Value, Blend, Growth',
    'Stock Sector Distribution',
    'effective_duration',
    'morningstar_category',
    'security_style',
    // 'maturity_date', // will get avg maturity at some point
    // 'avg_maturity',
    // 'strike_price',
  ];

  // instantiated pipes
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripLongPercentPipe: RipsawLongPercentPipe = new RipsawLongPercentPipe();
  ripDatePipe: RipsawDatePipe = new RipsawDatePipe();

  // object containing the definitions of all the possible columns that can be used in ngx-datatable grid
  // each column has a definition for use in an account detail grid that lists individual holdings of an account
  // and an allocation widget grid that lists aggregated data
  allColumnsObject: any = {
    security_type: {
      account: {
        name: 'Type',
        longName: 'Security Type',
        prop: 'security_type',
        unit: 'string',
        excludeFromOptimizer: true,
      },
    },
    // this field comes from xignite/morningstar and will give the user more info, but won't be used by the system
    morningstar_category: {
      account: {
        name: 'Morningstar Category',
        prop: 'morningstar_category',
        cellClass: Util.getCellClass,
        unit: 'string',
        minWidth: 200,
        excludeFromOptimizer: true,
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // this field comes from yodlee and will give the user more info, but won't be used by the system
    security_style: {
      account: {
        name: 'Aggregator Category',
        prop: 'security_style',
        unit: 'string',
        cellClass: Util.getCellClass,
        minWidth: 200,
        excludeFromOptimizer: true,
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // new override only field that will be used for sub typing a security, ie an ETF that is full of commodities or real estate
    underlying_asset_type: {
      account: {
        name: 'Underlying Asset Type',
        prop: 'underlying_asset_type',
        unit: 'string',
        excludeFromOptimizer: true,
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // used mostly for determining if a fund is old enough to be used in benchmark or not, but can also be useful for other things in the future
    inception_date: {
      account: {
        name: 'Inception Date',
        prop: 'inception_date',
        unit: 'date',
        excludeFromOptimizer: true,
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // Descriptive
    annualized_yield: {
      alloc: {
        name: 'Yield',
        prop: 'annualized_yield',
        cellClass: Util.getCellClass,
        disclaimer: 1,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Yield',
        prop: 'annualized_yield',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        disclaimer: 1,
        minWidth: 110,
        unit: '%',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    coupon: {
      account: {
        name: 'Coupon',
        prop: 'coupon',
        pipe: this.ripPercentPipe,
        unit: '%',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    current_market_rate: {
      account: {
        longName: 'Current Market Rate',
        name: 'Market Rate',
        prop: 'current_market_rate',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    treasury_rate: {
      account: {
        name: 'Treasury Rate',
        prop: 'treasury_rate',
        pipe: this.ripPercentPipe,
        minWidth: 110,
        unit: '%',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    annualized_payment: {
      account: {
        name: 'Payment',
        longName: 'Annualized Payment',
        prop: 'annualized_payment',
        pipe: this.ripCurrencyPipe,
        minWidth: 110,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    expected_remaining_life: {
      account: {
        name: 'Remaining Life',
        longName: 'Expected Remaining Life',
        prop: 'expected_remaining_life',
        minWidth: 110,
        pipe: {
          transform: value => {
            return `${this.ripDecimalPipe.transform(value, '0-2')} years`;
          },
        },
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    dollar_flow: {
      account: {
        name: 'Cash Flow (-/+)',
        longName: 'Net Income/Loss',
        prop: 'dollar_flow',
        pipe: this.ripCurrencyPipe,
        minWidth: 120,
        definition: 7,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    effective_duration: {
      /*alloc: {
        name: 'Effective Duration',
        prop: 'effective_duration',
        cellClass: Util.getCellClass,
      },*/
      account: {
        name: 'Effective Duration',
        prop: 'effective_duration',
        pipe: this.ripDecimalPipe,
        cellClass: Util.getCellClass,
      },
    },
    expense_ratio: {
      account: {
        name: 'Expense Ratio',
        prop: 'expense_ratio',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    expenses: {
      alloc: {
        name: 'Expenses',
        longName: 'Total Expenses',
        prop: 'expenses',
        // cellTemplate: this.expenseTemplate,
        sortable: false,
        cellClass: Util.getCellClass,
        disclaimer: 4,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    cost_basis: {
      alloc: {
        name: 'Cost Basis',
        prop: 'cost_basis',
        pipe: this.ripCurrencyPipe,
        unit: '$',
      } as ColumnDefinition,
      account: {
        name: 'Cost Basis',
        prop: 'cost_basis',
        pipe: this.ripCurrencyPipe,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    gain_loss: {
      alloc: {
        name: 'Gain/Loss',
        longName: 'Unrealized Gain/Loss',
        prop: 'gain_loss',
        pipe: this.ripCurrencyPipe,
        unit: '$',
      } as ColumnDefinition,
      account: {
        name: 'Gain/Loss',
        longName: 'Unrealized Gain/Loss',
        prop: 'gain_loss',
        pipe: this.ripCurrencyPipe,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    outstanding_balance: {
      account: {
        name: 'Outstanding Principal',
        longName: 'Outstanding Principal Balance',
        prop: 'outstanding_balance',
        pipe: this.ripCurrencyPipe,
        cellClass: Util.getCellClass,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    maturity_value: {
      account: {
        name: 'Maturity Value',
        prop: 'maturity_value',
        pipe: this.ripCurrencyPipe,
        cellClass: Util.getCellClass,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    original_loan_amount: {
      alloc: {
        name: 'Original Amount',
        longName: 'Original Loan Amount',
        prop: 'original_loan_amount',
        cellClass: Util.getCellClass,
        unit: '$',
      } as ColumnDefinition,
      account: {
        name: 'Original Amount',
        longName: 'Original Loan Amount',
        prop: 'original_loan_amount',
        pipe: this.ripCurrencyPipe,
        cellClass: Util.getCellClass,
        unit: '$',
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    loan_origination_date: {
      alloc: {
        name: 'Origination Date',
        longName: 'Loan Origination Date',
        prop: 'loan_origination_date',
        cellClass: Util.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Origination Date',
        longName: 'Loan Origination Date',
        prop: 'loan_origination_date',
        pipe: this.ripDatePipe,
        cellClass: Util.getCellClass,
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    loan_term: {
      alloc: {
        name: 'Loan Term',
        prop: 'loan_term',
        cellClass: Util.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Loan Term',
        prop: 'loan_term',
        pipe: this.ripDecimalPipe,
        cellClass: Util.getCellClass,
        excludeFromOptimizer: true,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    real_assets: {
      alloc: {
        name: 'Real Assets',
        longName: 'Real Assets',
        prop: 'real_assets',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Real Assets',
        longName: 'Real Assets',
        prop: 'real_assets',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // asset mix
    cash: {
      alloc: {
        name: 'Cash',
        prop: 'cash',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Cash',
        prop: 'cash',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bonds: {
      alloc: {
        name: 'Bonds',
        prop: 'bonds',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Bonds',
        prop: 'bonds',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    stocks: {
      alloc: {
        name: 'Stocks',
        prop: 'stocks',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Stocks',
        prop: 'stocks',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    /*other: {
      // commenting this out for now so it doesn't show up in the widget. We will add it back soon once we have full support of other
      /!*alloc: {
        name: 'Other',
        prop: 'other',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,*!/
      account: {
        name: 'Other',
        prop: 'other',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,*/
    // market cap
    large_cap: {
      alloc: {
        name: 'Large Cap',
        prop: 'large_cap',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Large Cap',
        prop: 'large_cap',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    mid_cap: {
      alloc: {
        name: 'Mid Cap',
        prop: 'mid_cap',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Mid Cap',
        prop: 'mid_cap',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    small_cap: {
      alloc: {
        name: 'Small Cap',
        prop: 'small_cap',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Small Cap',
        prop: 'small_cap',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // value/blend/growth
    value_stocks: {
      alloc: {
        name: 'Value',
        prop: 'value_stocks',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Value',
        prop: 'value_stocks',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    blend_stocks: {
      alloc: {
        name: 'Blend',
        prop: 'blend_stocks',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Blend',
        prop: 'blend_stocks',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    growth_stocks: {
      alloc: {
        name: 'Growth',
        prop: 'growth_stocks',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Growth',
        prop: 'growth_stocks',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // region distributions
    us: {
      alloc: {
        name: 'US Stocks',
        prop: 'us',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'US Stocks',
        prop: 'us',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    non_us: {
      alloc: {
        name: 'Non-US Stocks',
        prop: 'non_us',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Non-US Stocks',
        prop: 'non_us',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bonds_us: {
      alloc: {
        name: 'US Bonds',
        prop: 'bonds_us',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'US Bonds',
        prop: 'bonds_us',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bonds_non_us: {
      alloc: {
        name: 'Non-US Bonds ',
        prop: 'bonds_non_us',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Non-US Bonds',
        prop: 'bonds_non_us',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    emerging_markets: {
      alloc: {
        name: 'Emerging Stocks',
        longName: 'Non-US Emerging Stocks',
        prop: 'emerging_markets',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Emerging Stocks',
        longName: 'Non-US Emerging Stocks',
        prop: 'emerging_markets',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bonds_region_emerging_markets: {
      alloc: {
        name: 'Emerging Bonds',
        longName: 'Non-US Emerging Bonds',
        prop: 'bonds_region_emerging_markets',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Emerging Bonds',
        longName: 'Non-US Emerging Bonds',
        prop: 'bonds_region_emerging_markets',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // credit quality
    aaa: {
      alloc: {
        name: 'AAA',
        prop: 'aaa',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'AAA',
        prop: 'aaa',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    aa: {
      alloc: {
        name: 'AA',
        prop: 'aa',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'AA',
        prop: 'aa',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    a: {
      alloc: {
        name: 'A',
        prop: 'a',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'A',
        prop: 'a',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bbb: {
      alloc: {
        name: 'BBB',
        prop: 'bbb',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'BBB',
        prop: 'bbb',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bb: {
      alloc: {
        name: 'BB',
        prop: 'bb',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'BB',
        prop: 'bb',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    b: {
      alloc: {
        name: 'B',
        prop: 'b',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'B',
        prop: 'b',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    below_b: {
      alloc: {
        name: '< B',
        longName: 'Below B',
        prop: 'below_b',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '< B',
        longName: 'Below B',
        prop: 'below_b',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    not_rated: {
      alloc: {
        name: 'NR',
        longName: 'Not Rated',
        prop: 'not_rated',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'NR',
        longName: 'Not Rated',
        prop: 'not_rated',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // maturity distribution
    less_than_one_year: {
      alloc: {
        name: '< 1 Year',
        longName: 'Less Than One Year',
        prop: 'less_than_one_year',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: '< 1 Year',
        longName: 'Less Than One Year',
        prop: 'less_than_one_year',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    one_to_three_years: {
      alloc: {
        name: '1 to 3 Years',
        prop: 'one_to_three_years',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: '1 to 3 Years',
        prop: 'one_to_three_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    three_to_five_years: {
      alloc: {
        name: '3 to 5 Years',
        prop: 'three_to_five_years',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '3 to 5 Years',
        prop: 'three_to_five_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    five_to_seven_years: {
      alloc: {
        name: '5 to 7 Years',
        prop: 'five_to_seven_years',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '5 to 7 Years',
        prop: 'five_to_seven_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    seven_to_ten_years: {
      alloc: {
        name: '7 to 10 Years',
        prop: 'seven_to_ten_years',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '7 to 10 Years',
        prop: 'seven_to_ten_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    ten_to_fifteen_years: {
      alloc: {
        name: '10 to 15 Years',
        prop: 'ten_to_fifteen_years',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '10 to 15 Years',
        prop: 'ten_to_fifteen_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    fifteen_to_twenty_years: {
      alloc: {
        name: '15 to 20 Years',
        prop: 'fifteen_to_twenty_years',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '15 to 20 Years',
        prop: 'fifteen_to_twenty_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    twenty_to_thirty_years: {
      alloc: {
        name: '20 to 30 Years',
        prop: 'twenty_to_thirty_years',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: '20 to 30 Years',
        prop: 'twenty_to_thirty_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    over_thirty_years: {
      alloc: {
        name: 'Over 30 Years',
        prop: 'over_thirty_years',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Over 30 Years',
        prop: 'over_thirty_years',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // stock sectors
    sector_basic_materials: {
      alloc: {
        name: 'Basic Materials',
        prop: 'sector_basic_materials',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Basic Materials',
        prop: 'sector_basic_materials',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_communication_services: {
      alloc: {
        name: 'Communication Services',
        prop: 'sector_communication_services',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Communication Services',
        prop: 'sector_communication_services',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_consumer_cyclical: {
      alloc: {
        name: 'Consumer Cyclical',
        prop: 'sector_consumer_cyclical',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Consumer Cyclical',
        prop: 'sector_consumer_cyclical',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_consumer_defensive: {
      alloc: {
        name: 'Consumer Defensive',
        prop: 'sector_consumer_defensive',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Consumer Defensive',
        prop: 'sector_consumer_defensive',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_energy: {
      alloc: {
        name: 'Energy',
        prop: 'sector_energy',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Energy',
        prop: 'sector_energy',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_financial_services: {
      alloc: {
        name: 'Financial Services',
        prop: 'sector_financial_services',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Financial Services',
        prop: 'sector_financial_services',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_healthcare: {
      alloc: {
        name: 'Healthcare',
        prop: 'sector_healthcare',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Healthcare',
        prop: 'sector_healthcare',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_industrials: {
      alloc: {
        name: 'Industrials',
        prop: 'sector_industrials',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Industrials',
        prop: 'sector_industrials',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_real_estate: {
      alloc: {
        name: 'Real Estate',
        prop: 'sector_real_estate',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Real Estate',
        prop: 'sector_real_estate',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_technology: {
      alloc: {
        name: 'Technology',
        prop: 'sector_technology',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Technology',
        prop: 'sector_technology',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    sector_utilities: {
      alloc: {
        name: 'Utilities',
        prop: 'sector_utilities',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Utilities',
        prop: 'sector_utilities',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    // bond primary sectors
    bond_primary_sector_agency_mbs: {
      alloc: {
        name: 'Agency MBS',
        prop: 'bond_primary_sector_agency_mbs',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Agency MBS',
        prop: 'bond_primary_sector_agency_mbs',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_abs: {
      alloc: {
        name: 'ABS',
        longName: 'Asset Backed Securities',
        prop: 'bond_primary_sector_abs',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'ABS',
        longName: 'Asset Backed Securities',
        prop: 'bond_primary_sector_abs',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_bank_loan: {
      alloc: {
        name: 'Bank Loans',
        prop: 'bond_primary_sector_bank_loan',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Bank Loans',
        prop: 'bond_primary_sector_bank_loan',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_commercial_mbs: {
      alloc: {
        name: 'CMBS',
        longName: 'Commercial MBS',
        prop: 'bond_primary_sector_commercial_mbs',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'CMBS',
        longName: 'Commercial MBS',
        prop: 'bond_primary_sector_commercial_mbs',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_convertibles: {
      alloc: {
        name: 'Convertible Bonds',
        prop: 'bond_primary_sector_convertibles',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Convertible Bonds',
        prop: 'bond_primary_sector_convertibles',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_corporate_bond: {
      alloc: {
        name: 'Corporate Bonds',
        prop: 'bond_primary_sector_corporate_bond',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Corporate Bonds',
        prop: 'bond_primary_sector_corporate_bond',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_covered_bond: {
      alloc: {
        name: 'Covered Bonds',
        prop: 'bond_primary_sector_covered_bond',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Covered Bonds',
        prop: 'bond_primary_sector_covered_bond',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_future_forward: {
      alloc: {
        name: 'Futures/Forwards',
        prop: 'bond_primary_sector_future_forward',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Futures/Forwards',
        prop: 'bond_primary_sector_future_forward',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_government: {
      alloc: {
        name: 'Govt Bonds',
        longName: 'Government Bonds',
        prop: 'bond_primary_sector_government',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Govt Bonds',
        prop: 'bond_primary_sector_government',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_government_related: {
      alloc: {
        name: 'Govt Related Bonds',
        longName: 'Government Related Bonds',
        prop: 'bond_primary_sector_government_related',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Govt Related Bonds',
        longName: 'Government Related Bonds',
        prop: 'bond_primary_sector_government_related',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_non_agency_residential_mbs: {
      alloc: {
        name: 'Non Agency MBS',
        prop: 'bond_primary_sector_non_agency_residential_mbs',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Non Agency MBS',
        prop: 'bond_primary_sector_non_agency_residential_mbs',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_preferred: {
      alloc: {
        name: 'Preferreds',
        prop: 'bond_primary_sector_preferred',
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
      account: {
        name: 'Preferreds',
        prop: 'bond_primary_sector_preferred',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
      } as ColumnDefinition,
    } as AllColumnDefinition,
    bond_primary_sector_us_municipal_tax_advantaged: {
      alloc: {
        name: 'US Municipal Bonds',
        prop: 'bond_primary_sector_us_municipal_tax_advantaged',
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'US Municipal Bonds',
        prop: 'bond_primary_sector_us_municipal_tax_advantaged',
        pipe: this.ripPercentPipe,
        cellClass: Util.getCellClass,
        unit: '%',
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,

    // misc
    maturity_date: {
      alloc: {
        name: 'Maturity',
        longName: 'Maturity Date',
        prop: 'maturity_date',
        cellClass: Util.getCellClass,
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
      account: {
        name: 'Maturity',
        longName: 'Maturity Date',
        prop: 'maturity_date',
        pipe: this.ripDatePipe,
        cellClass: Util.getCellClass,
        excludeFromOptimizer: true,
        // headerClass: Helpers.getCellClass,
      } as ColumnDefinition,
    } as AllColumnDefinition,
  };

  /*
   * Function for retrieving a single column definition
   * @param colName {String} - name of the column definition to be retrieved
   * @param type {String} - type of definition to retrieve (alloc or account)
   * @returns a single column definition object
   * */
  getColumn(colName: string, type: string) {
    return this.allColumnsObject[colName][type];
  }

  getAccountOverride(account) {
    const mapping = this.globalVars.accountMapping.mapping;
    const conn = mapping[account.connection_id];
    if (conn) {
      const acc = conn[account.account_id];
      if (acc) {
        return acc;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  refreshInstructions: string =
    'Click the refresh button or check back later to see complete updated account information.';

  fixAccountInstructions: string =
    'Click here to try updating your credentials to reconnect to your account(s).';

  statusMap: any = {
    LOGIN_IN_PROGRESS: {
      msg: `Login to provider site in progress.`,
      color: '#207bbc',
      icon: faInfoCircle,
      faClass: 'fa-regular fa-right-to-bracket',
      chipClass: 'login-in-progress',
    },
    USER_INPUT_REQUIRED: {
      msg: `Additional authentication information is needed. ${this.fixAccountInstructions}`,
      color: '#FAC505',
      icon: faExclamationTriangle,
      faClass: 'fa-regular fa-memo-circle-info',
      chipClass: 'input-required',
    },
    IN_PROGRESS: {
      msg: `Login is successful and data aggregation is in progress.`,
      color: '#207bbc',
      icon: faInfoCircle,
      faClass: 'fa-regular fa-clock',
      chipClass: 'aggregation-in-progress',
    },
    PARTIAL_SUCCESS: {
      msg: `Data has been partially retrieved. ${this.refreshInstructions}`,
      color: '#207bbc',
      icon: faInfoCircle,
      faClass: 'fa-regular fa-circle-exclamation-check',
      chipClass: 'partial-success',
    },
    SUCCESS: {
      msg: 'Success: Account data has been retrieved successfully.',
      color: 'green',
      icon: faCheckSquare,
      faClass: 'fa-regular fa-square-check',
      chipClass: 'success',
    },
    FAILED: {
      msg: `Connection failed: Additional authentication information is needed. Note: Some institutions block temporarily due to volume, especially during market hours. ${this.fixAccountInstructions}`,
      color: 'red',
      icon: faExclamationTriangle,
      faClass: 'fa-regular fa-file-xmark',
      chipClass: 'failed',
    },
    FAILED_RECENTLY: {
      msg: `Connection issue: Some institutions temporarily block updates due to volume, especially when the market is open. This account’s data has been updated within the last 24 hours. Prices are still updating on existing investments from other data sources. See price source details in disclaimers. ${this.fixAccountInstructions}`,
      color: '#FAC505',
      icon: faInfoCircle,
      faClass: 'fa-regular fa-file-exclamation',
      chipClass: 'failed-recently',
    },
  };

  syncTimeStampFormat: string = 'MMM d, y, h:mm a Z';

  states: any[] = [
    { full: 'Alaska', short: 'AK' },
    { full: 'Alabama', short: 'AL' },
    { full: 'American Samoa', short: 'AS' },
    { full: 'Arkansas', short: 'AR' },
    { full: 'Arizona', short: 'AZ' },
    { full: 'California', short: 'CA' },
    { full: 'Colorado', short: 'CO' },
    { full: 'Connecticut', short: 'CT' },
    { full: 'District of Columbia', short: 'DC' },
    { full: 'Delaware', short: 'DE' },
    { full: 'Florida', short: 'FL' },
    { full: 'Georgia', short: 'GA' },
    { full: 'Guam', short: 'GU' },
    { full: 'Hawaii', short: 'HI' },
    { full: 'Iowa', short: 'IA' },
    { full: 'Idaho', short: 'ID' },
    { full: 'Illinois', short: 'IL' },
    { full: 'Indiana', short: 'IN' },
    { full: 'Kansas', short: 'KS' },
    { full: 'Kentucky', short: 'KY' },
    { full: 'Louisiana', short: 'LA' },
    { full: 'Massachusetts', short: 'MA' },
    { full: 'Maryland', short: 'MD' },
    { full: 'Maine', short: 'ME' },
    // { full: '', short: 'MH' },
    { full: 'Michigan', short: 'MI' },
    { full: 'Minnesota', short: 'MN' },
    { full: 'Missouri', short: 'MO' },
    { full: 'Northern Mariana Islands', short: 'MP' },
    { full: 'Mississippi', short: 'MS' },
    { full: 'Montana', short: 'MT' },
    { full: 'North Carolina', short: 'NC' },
    { full: 'North Dakota', short: 'ND' },
    { full: 'Nebraska', short: 'NE' },
    { full: 'New Hampshire', short: 'NH' },
    { full: 'New Jersey', short: 'NJ' },
    { full: 'New Mexico', short: 'NM' },
    { full: 'Nevada', short: 'NV' },
    { full: 'New York', short: 'NY' },
    { full: 'Ohio', short: 'OH' },
    { full: 'Oklahoma', short: 'OK' },
    { full: 'Oregon', short: 'OR' },
    { full: 'Pennsylvania', short: 'PA' },
    { full: 'Puerto Rico', short: 'PR' },
    { full: 'Rhode Island', short: 'RI' },
    { full: 'South Carolina', short: 'SC' },
    { full: 'South Dakota', short: 'SD' },
    { full: 'Tennessee', short: 'TN' },
    { full: 'Texas', short: 'TX' },
    { full: 'Utah', short: 'UT' },
    { full: 'Virgina', short: 'VA' },
    { full: 'Virgin Islands, U.S.', short: 'VI' },
    { full: 'Vermont', short: 'VT' },
    { full: 'Washington', short: 'WA' },
    { full: 'Wisconsin', short: 'WI' },
    { full: 'West Virgina', short: 'WV' },
    { full: 'Wyoming', short: 'WY' },
    // { full: '', short: 'US' },
  ];

  defaultThreshold: number = 0.04;

  getDefaultThreshold(groupLabel: string) {
    const group = _.find(this.columnGroupings, (g: any) => {
      return g.label === groupLabel;
    });
    return group ? group.defaultThreshold : 0.04;
  }

  /*
  * bank: piggy-bank or university
investment account: landmark
stocks and bonds: color flavors of fa-analytics ?
icon:  faHandsHelping // private lending
 icon: faUniversity
icon: faHandHoldingUsd
* icon: faHandHoldingUsd
* */

  private assetAccountTypes: AccountType[] = [
    // row 1
    {
      label: 'Real Estate',
      type: 'Real Estate',
      account_category: 'Other',
      taxable: true,
      icon: faHouse,
      form: 'realAsset',
    },
    {
      label: 'Vehicle',
      type: 'Vehicle',
      account_category: 'Other',
      taxable: true,
      icon: faCar,
      form: 'realAsset',
    },
    {
      label: 'Valuable(s)',
      type: 'Valuable(s)',
      account_category: 'Other',
      taxable: true,
      icon: faGem,
      form: 'realAsset',
    },
    // row 2
    {
      label: 'Cash',
      type: 'Cash',
      account_category: 'Banking',
      taxable: true,
      icon: faMoneyBill,
      form: 'cash',
    },
    {
      label: 'Bank Account',
      type: 'Bank Account',
      account_category: 'Banking',
      taxable: true,
      icon: faUniversity,
      form: 'bank',
    },
    {
      label: 'Investment Account',
      type: 'Investment',
      account_category: 'Investment',
      taxable: true,
      icon: faLandmark,
      form: 'investment',
    },
    // row 3
    {
      label: 'Stock',
      type: 'Stock',
      account_category: 'Investment',
      taxable: true,
      icon: faAnalytics,
      form: 'stock',
    },
    {
      label: 'Restricted Stock',
      type: 'Restricted Stock',
      account_category: 'Investment',
      taxable: true,
      icon: faAnalytics,
      icon2: faLockAlt,
      form: 'stock',
    },
    {
      label: 'Stock Option',
      type: 'Stock Option',
      account_category: 'Investment',
      taxable: true,
      icon: faAnalytics,
      icon2: faAlarmClock,
      form: 'stockOption',
    },
    // row 4
    {
      label: 'Private Lending',
      labelTwo: '(Interest Only)',
      type: 'Bond',
      amortization_type: 'interest-only',
      account_category: 'Investment',
      taxable: true,
      icon: faHandHoldingUsd,
      form: 'bond',
    },
    {
      label: 'Private Lending',
      labelTwo: '(Fully Amortizing)',
      type: 'Private Lending',
      amortization_type: 'fully-amortizing',
      account_category: 'Investment',
      taxable: true,
      icon: faHandHoldingUsd,
      form: 'loan',
    },
    {
      label: 'Bond',
      type: 'Bond',
      amortization_type: 'interest-only',
      account_category: 'Investment',
      taxable: true,
      icon: faFileCertificate,
      icon2: faDollarSign,
      form: 'bond',
    },
    // row 5
    {
      label: 'Annuities, Pensions ',
      labelTwo: 'and Social Security',
      type: 'Annuity',
      amortization_type: 'fully-amortizing',
      account_category: 'Investment',
      taxable: true,
      icon: faPiggyBank,
      form: 'annuity',
    },
    {
      label: 'Term Life Insurance',
      type: 'Term Life Insurance',
      amortization_type: 'fully-amortizing',
      account_category: 'Insurance',
      taxable: true,
      icon: faLifeRing,
      form: 'term-life-insurance',
    },
    {
      label: 'Crypto Currency',
      type: 'Crypto',
      account_category: 'Other',
      taxable: true,
      icon: faCoin,
      form: 'crypto',
    },
  ];

  getManualAssetAccountTypes(): AccountType[] {
    return _.cloneDeep( this.assetAccountTypes );
  }

  private liabilityAccountTypes: AccountType[] = [
    {
      label: 'Mortgage Loan',
      labelTwo: '(Fully Amortizing)',
      type: 'Mortgage Loan',
      amortization_type: 'fully-amortizing',
      account_category: 'Loan',
      taxable: true,
      icon: faHouse,
      icon2: faDollarSign,
      form: 'loan',
    },
    {
      label: 'Auto Loan',
      labelTwo: '(Fully Amortizing)',
      type: 'Auto Loan',
      amortization_type: 'fully-amortizing',
      account_category: 'Loan',
      taxable: true,
      icon: faCar,
      icon2: faDollarSign,
      form: 'loan',
    },
    {
      label: 'Private Loan',
      labelTwo: '(Interest Only)',
      type: 'Private Borrowing',
      amortization_type: 'interest-only',
      account_category: 'Loan',
      taxable: true,
      icon: faHandHoldingUsd,
      form: 'bond',
    },
  ];

  getManualLiabilityAccountTypes(): AccountType[] {
    return _.cloneDeep( this.liabilityAccountTypes );
  }

  // get this year's market holidays
  marketHolidays: Holiday[] = getHolidays( new Date().getFullYear() );

  dayIsTradingDay(day: moment.Moment) {
    day = day.startOf('day');
    const dayOfWeek = day.day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return false;
    }

    const dateString = day.format('YYYY-MM-DD');

    for (const holiday of this.marketHolidays) {
      if (holiday.dateString === dateString) {
        return false;
      }
    }

    return true;
  }

  static marketInfoCryptoSymbols: string [][] = [
    [
      'Bitcoin',
      'COINBASE:BTCUSD|1D',
    ],
    [
      'Ethereum',
      'COINBASE:ETHUSD|1D',
    ],
    [
      'USDT',
      'COINBASE:USDTUSD|1D',
    ],
    [
      'LUNA',
      'BITFINEX:LUNAUSD|1D',
    ],
    [
      'DOGE',
      'COINBASE:DOGEUSD|1D',
    ],
    [
      'MATIC',
      'COINBASE:MATICUSD|1D',
    ],
    [
      'SOL',
      'COINBASE:SOLUSD|1D',
    ],
    [
      'BNB',
      'BINANCE:BNBBUSD|1D',
    ],
    [
      'USDC',
      'CURRENCYCOM:USDCUSD|1D',
    ],
    [
      'SHIB',
      'COINBASE:SHIBUSD|1D',
    ],
  ];

  static marketInfoTickerTapeSymbols: {description: string; proName: string}[] = [
    {
      description: 'S&P 500',
      proName: 'FOREXCOM:SPXUSD',
    },
    {
      description: 'RUSSELL 2000 VOL',
      proName: 'CBOE:RVX',
    },
    {
      description: 'Vanguard Short Term Treasury',
      proName: 'NASDAQ:VGSH',
    },
    {
      description: 'Vanguard Intermediate Treasury',
      proName: 'NASDAQ:VGIT',
    },
    {
      description: 'Vanguard Long-Term Treasury',
      proName: 'NASDAQ:VGLT',
    },
    {
      description: 'Ext Duration Treasury ETF',
      proName: 'AMEX:EDV',
    },
    {
      description: 'Total Corp Bond',
      proName: 'NASDAQ:VTC',
    },
    {
      description: 'Total Bond Market ',
      proName: 'NASDAQ:BND',
    },
    {
      description: 'Total Stock Market',
      proName: 'AMEX:VTI',
    },
    {
      description: 'Total International Stock',
      proName: 'NASDAQ:VXUS',
    },
    {
      description: 'Bitcoin',
      proName: 'BITSTAMP:BTCUSD',
    },
    /*{
      'proName': 'BITSTAMP:ETHUSD',
      'title': 'Ethereum',
    },
    {
      'description': 'Doge Coin',
      'proName': 'BITFINEX:DOGEUSD',
    },*/
  ];

  static marketOverviewTabs: any = [
    {
      title: 'Indices',
      symbols: [
        {
          s: 'FOREXCOM:SPXUSD',
          d: 'S&P 500',
        },
        {
          s: 'FOREXCOM:NSXUSD',
          d: 'US 100',
        },
        {
          s: 'FOREXCOM:DJI',
          d: 'Dow 30',
        },
        {
          s: 'INDEX:NKY',
          d: 'Nikkei 225',
        },
        {
          s: 'INDEX:DEU40',
          d: 'DAX Index',
        },
        {
          s: 'FOREXCOM:UKXGBP',
          d: 'UK 100',
        },
      ],
    },
    {
      title: 'Stocks',
      symbols: [
        {
          d: 'Total Stock Market',
          s: 'AMEX:VTI',
        },
        {
          d: 'Total International Stock',
          s: 'NASDAQ:VXUS',
        },
        {
          d: 'S&P 500',
          s: 'FOREXCOM:SPXUSD',
        },
        {
          s: 'AMEX:VV',
          d: 'Vanguard Large-Cap ETF',
        },
        {
          s: 'NASDAQ:VONG',
          d: 'Russell 1000 Growth ETF',
        },
        {
          s: 'NASDAQ:VONV',
          d: 'Russell 1000 Value ETF',
        },
        {
          s: 'NASDAQ:VONE',
          d: 'Russell 1000 ETF',
        },
        {
          d: 'RUSSELL 2000 VOL',
          s: 'CBOE:RVX',
        },
        {
          s: 'AMEX:SPY',
          d: 'SPY SP500 ETF',
        },
        {
          s: 'NASDAQ:AAPL',
          d: 'Apple',
        },
        {
          s: 'NASDAQ:TSLA',
          d: 'Tesla',
        },
        {
          s: 'NASDAQ:AMZN',
          d: 'Amazon',
        },
        {
          s: 'NASDAQ:NFLX',
          d: 'Netflix',
        },
        {
          s: 'NASDAQ:MSFT',
          d: 'Microsoft',
        },
        {
          s: 'NASDAQ:FB',
          d: 'Meta',
        },
        {
          s: 'AMEX:VV',
          d: 'Vanguard Large-Cap ETF',
        },
        {
          s: 'NASDAQ:VONG',
          d: 'Russell 1000 Growth ETF',
        },
        {
          s: 'NASDAQ:VONV',
          d: 'Russell 1000 Value ETF',
        },
        {
          s: 'NASDAQ:VONE',
          d: 'Russell 1000 ETF',
        },
      ],
    },
    {
      title: 'Bonds',
      symbols: [
        {
          d: 'Vanguard Short Term Treasury',
          s: 'NASDAQ:VGSH',
        },
        {
          d: 'Vanguard Intermediate Treasury',
          s: 'NASDAQ:VGIT',
        },
        {
          d: 'Vanguard Long-Term Treasury',
          s: 'NASDAQ:VGLT',
        },
        {
          d: 'Ext Duration Treasury ETF',
          s: 'AMEX:EDV',
        },
        {
          d: 'Total Corp Bond',
          s: 'NASDAQ:VTC',
        },
        {
          d: 'Total Bond Market ',
          s: 'NASDAQ:BND',
        },
        {
          d: 'Total Stock Market',
          s: 'AMEX:VTI',
        },
        {
          s: 'CBOT:ZB1!',
          d: 'T-Bond',
        },
        {
          s: 'CBOT:UB1!',
          d: 'Ultra T-Bond',
        },
      ],
    },
    {
      title: 'Crypto',
      symbols: [
        {
          d: 'Bitcoin',
          s: 'COINBASE:BTCUSD',
        },
        {
          s: 'COINBASE:ETHUSD',
          d: 'Ethereum',
        },
        {
          d: 'Doge Coin',
          s: 'BITFINEX:DOGEUSD',
        },
        {
          s: 'COINBASE:USDTUSD',
          d: 'Tether',
        },
        {
          s: 'FTX:SOLUSD',
          d: 'Solana',
        },
        {
          s: 'BINANCE:MATICUSDT',
          d: 'Matic',
        },
        {
          s: 'COINBASE:ATOMUSD',
          d: 'ATOM',
        },
        {
          s: 'CURRENCYCOM:USDCUSD',
          d: 'USDC',
        },
      ],
    },
    {
      title: 'Futures',
      symbols: [
        {
          s: 'CME_MINI:ES1!',
          d: 'S&P 500',
        },
        {
          s: 'CME:6E1!',
          d: 'Euro',
        },
        {
          s: 'COMEX:GC1!',
          d: 'Gold',
        },
        {
          s: 'NYMEX:CL1!',
          d: 'Crude Oil',
        },
        {
          s: 'NYMEX:NG1!',
          d: 'Natural Gas',
        },
        {
          s: 'CBOT:ZC1!',
          d: 'Corn',
        },
      ],
    },
    {
      title: 'Forex',
      symbols: [
        {
          s: 'FX:EURUSD',
          d: 'EUR/USD',
        },
        {
          s: 'FX:GBPUSD',
          d: 'GBP/USD',
        },
        {
          s: 'FX:USDJPY',
          d: 'USD/JPY',
        },
        {
          s: 'FX:USDCHF',
          d: 'USD/CHF',
        },
        {
          s: 'FX:AUDUSD',
          d: 'AUD/USD',
        },
        {
          s: 'FX:USDCAD',
          d: 'USD/CAD',
        },
      ],
    },
  ];

  static tradingViewColorTheme: string = 'light';
}
