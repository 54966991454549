import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { delay, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { AppStoreService } from './store';
import { WorkspacesService, WorkspaceWithUsersDto } from './api';
import { catchError, take, tap } from 'rxjs/operators';

// guard is used instead of resolver because of order of executing guards and resolver
// in framework resolvers are executing after guards so there is no possibility to preload data with resolver

@Injectable( {
  providedIn: 'root',
} )
export class WorkspacesGuard implements CanActivate {

  constructor(
    private appStoreService: AppStoreService,
    private workspacesEndpoint: WorkspacesService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.manageWorkspaces();
  }

  private manageWorkspaces(): Observable<boolean | UrlTree> {
    return this.appStoreService.loadedWorkspace$
      .pipe(
        take( 1 ),
        switchMap( loadedFromStore => {
          if ( !loadedFromStore ) {
            return forkJoin( [
              this.workspacesEndpoint.getWorkspaces(),
              this.workspacesEndpoint.getSharedWorkspaces(),
            ] )
              .pipe(
                tap( ( [ myRes, sharedRes ] ) => {
                  this.appStoreService.setMyWorkspaces( myRes.data );
                  this.appStoreService.setSharedWorkspaces( sharedRes.data );
                  this.appStoreService.loadPrimaryWorkspace();

                  const creatingIds = this.defineCreatingIds( myRes.data );
                  if ( creatingIds.length ) {
                    this.appStoreService.addCreatingIdsWorkspaces( creatingIds );
                  }
                } ),
                delay( 50 ),
                map( () => true ),
                catchError( () => of( true ) ),
              );
          }

          return of( true )
            .pipe( delay( 50 ) );
        } ),
      );
  }

  private defineCreatingIds( my: WorkspaceWithUsersDto[] ): number[] {
    const curTime = new Date().getTime();
    const shouldCheck = ( compareTime: number ) => curTime - compareTime <= 0.5 * 60 * 1000;

    return my
      .filter( el => shouldCheck( new Date( el.createdAt ).getTime() ) )
      .map( el => el.id );
  }
}
