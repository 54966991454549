<h3 mat-dialog-title>Manage Security Overrides</h3>
<mat-divider class="modal-header-divider"></mat-divider>
<div mat-dialog-content id="global-overrides-dialog-content" [nbSpinner]="loading">
  <div class="row" style="margin: 0 5px 10px 5px;" *ngIf="!loading">
    <h5 id="global-data-quality-header">
      Data Quality Issues
      <fa-icon [icon]="overridesUtil.positionsWithMissingData?.length > 0 ? faExclamationCircle : faCheckCircle"
               fixedWidth="true" size="1x"
               [ngClass]="{
                     'warn': overridesUtil.positionsWithMissingData?.length > 0,
                     'success': overridesUtil.positionsWithMissingData?.length === 0
                     }">

      </fa-icon>
    </h5>
    <div class="col-12" *ngIf="overridesUtil.positionsWithMissingData?.length > 0">
      <p>
        These issues could be fixed by adding a security override.
      </p>
    </div>
    <div class="col-12">
      <mat-list>
        <mat-list-item *ngFor="let position of overridesUtil.positionsWithMissingData">
                    <span class="data-issue-item">
                        <span class="rip-link data-issue-ticker" (click)="goToAccount( position )">
                            <fa-icon [icon]="faCircle" [fixedWidth]="true" size="xs"></fa-icon>
                          {{ position?.ticker === 'CUR:USD' ? position?.formatted_account_description : position?.ticker }}
                        </span>
                        <span *ngIf="position?.ticker !== 'CUR:USD'">
                            in {{ position?.formatted_account_description }}
                        </span>
                    </span>
        </mat-list-item>
      </mat-list>
      <div *ngIf="overridesUtil.positionsWithMissingData?.length === 0">
        No issues that need your attention right now.
      </div>
    </div>
  </div>
  <div class="row" style="margin: 5px;" *ngIf="!loading">
    <h5>Existing Security Overrides</h5>
    <div class="col-12"
         *ngIf="overridesUtil.globalOverrideItems?.length === 0 && overridesUtil.localOverrideItems?.length === 0">
      No Security Overrides Yet
    </div>
    <p class="global-sub-header" *ngIf="overridesUtil.globalOverrideItems?.length > 0">
      For descriptive, bond and stock information for the same security to be applied in multiple
      accounts
    </p>
    <mat-accordion class="manager-accordion">
      <mat-expansion-panel class="global-override-row" *ngFor="let item of overridesUtil.globalOverrideItems"
                           [nbSpinner]="item.spinning">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <fa-icon [icon]="faEdit" size="1x" [fixedWidth]="true" class="edit-global-icon"
                     (click)="editGlobalOverride(item)"
                     *ngIf="item?.accounts?.length > 0"
                     title="Edit Security Override"></fa-icon>
            <fa-icon [icon]="faTrashAlt" size="1x" [fixedWidth]="true" class="delete-global-icon"
                     (click)="confirmRemoveOverride($event, item)"
                     title="Delete Security Override"></fa-icon>
            <span>{{ item.override.original_ticker }}</span>
          </mat-panel-title>
          <mat-panel-description>
            <fa-icon [icon]="faInfoCircle"
                             [fixedWidth]="true" size="1x"
                             style="color: #fac505; margin-right: 5px;"
                             *ngIf="item?.accounts?.length === 0"
                             matTooltip="This override is not being used in any account. You may want to remove it."
                             matTooltipClass="mat-tooltip-custom"
                             matTooltipPosition="after">
            </fa-icon>
            <span *ngIf="item.override.original_ticker_name">{{ item.override.original_ticker_name }}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col">
            <div class="global-details-header">
              Accounts Where This Security Override is in Use
            </div>
            <div class="row">
              <!-- list of positions where the override is being applied -->
              <div class="col-12" *ngFor="let a of item.accounts">
                                <span class="rip-link local-security-list-item" (click)="goToLocalOverride(a, item)">
                                  <fa-icon [icon]="faCircle" [fixedWidth]="true" size="xs" class="rip-link"></fa-icon>
                                  {{ a?.formattedDescription }}
                                </span>
              </div>
              <div class="col-12" *ngIf="item.accounts?.length === 0">
                This override is not currently used in any accounts.
              </div>
            </div>
          </div>
          <div class="col legend-container right">
            <rip-override-legend></rip-override-legend>
          </div>
        </div>
        <div class="row">

          <!-- overrides go here-->
          <rip-override-display [globalItem]="item">

          </rip-override-display>


          <div *ngIf="item.overridesEmpty" class="col-12">
            This Override has no details. Click the edit button to add some, or the delete button to remove
            this
            override
          </div>

        </div>
        <!--                <div class="row">-->
        <!--                    <div class="col-12 legend-container">-->
        <!--                        -->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="row">
          <div class="col" style="margin-top: 25px; display: flex;">
            <button mat-raised-button
                    (click)="editGlobalOverride(item)"
                    [disabled]="item?.accounts?.length === 0"
                    [title]="item?.accounts?.length === 0 ?
                                 'Cannot edit an override that is not in use' : 'Edit Security Override'"
                    color="primary">
              <fa-icon [icon]="faEdit" size="1x" [fixedWidth]="true"></fa-icon>
              Edit Security Override
            </button>
            <button mat-raised-button
                    (click)="confirmRemoveOverride($event, item)"
                    [nbSpinner]="item.spinning"
                    [disabled]="item.spinning"
                    color="warn">
              <fa-icon [icon]="faTrashAlt" size="1x" [fixedWidth]="true"></fa-icon>
              Delete Security Override
            </button>
          </div>
          <div class="col legend-container right">
            <rip-override-legend></rip-override-legend>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <p class="global-sub-header" *ngIf="overridesUtil.localOverrideItems?.length > 0">
      For unique security information (i.e., price and quantity) in a specific account.
    </p>
    <mat-accordion class="manager-accordion">
      <mat-expansion-panel class="global-override-row" *ngFor="let item of overridesUtil.localOverrideItems"
                           [nbSpinner]="item.spinning">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <fa-icon [icon]="faEdit" size="1x" [fixedWidth]="true" class="edit-global-icon"
                     (click)="editLocalOverride(item)"
                     title="Edit Security Override"></fa-icon>
            <span *ngIf="item.position.ticker">{{ item.position.ticker }}</span>
          </mat-panel-title>
          <mat-panel-description>
            <span *ngIf="item.position.ticker_name">{{ item.position.ticker_name }}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col legend-container right">
            <rip-override-legend></rip-override-legend>
          </div>
        </div>
        <div class="row">
          <!-- overrides go here-->
          <rip-override-display [localItem]="item">

          </rip-override-display>
        </div>
        <div class="row">
          <div class="col" style="margin-top: 25px; display: flex;">
            <button mat-raised-button
                    (click)="editLocalOverride(item)"
                    title="Edit Security Override"
                    color="primary">
              <fa-icon [icon]="faEdit" size="1x" [fixedWidth]="true"></fa-icon>
              Edit Security Override
            </button>
            <!--<button mat-raised-button
                    (click)="confirmRemoveOverride($event, item)"
                    [nbSpinner]="item.spinning"
                    [disabled]="item.spinning"
                    color="warn">
                <fa-icon [icon]="faTrashAlt" size="1x" [fixedWidth]="true"></fa-icon>
                Delete Security Override
            </button>-->
          </div>
          <div class="col legend-container right">
            <rip-override-legend></rip-override-legend>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
  <!--<button mat-raised-button color="warn"
          *ngIf="selectionList.selectedOptions.selected.length > 0"
          (click)="removeSelectedOverrides()">
    Remove Selected Overrides
  </button>-->
</div>
