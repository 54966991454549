import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()

export class ServiceErrorHandlingUtil {

  /*
* Function for handling http response errors
* @param error {HttpErrorResponse} - response with an error
* @returns a Promise with the error
* */
  static optimizerHandleError( error: HttpErrorResponse | any ) {
    let errMsg: string;

    errMsg = error.error ? error.error.error : ( error.message ? error.message : error.toString() );

    const refCode = error.error ? error.error.referenceCode : null;
    const errorCode = error.error ? error.error.errorCode : null;
    // console.error( errMsg );
    return Promise.reject( { err: errMsg, refCode, errorCode } );
  }

}
