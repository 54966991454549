import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component( {
  selector: 'rip-yield-table',
  template: `
    <ngx-datatable #yieldTable [rows]="rows"
                   [columns]="columns"
                   [rowHeight]="45"
                   [headerHeight]="45"
                   [columnMode]="'force'"
                   [reorderable]="false"
                   [scrollbarH]="deviceIsMobile"
                   class="material striped rip-yield-table"></ngx-datatable>

    <ng-template ngx-datatable-cell-template #yieldTableCellTemplate let-row="row" let-column="column"
                 let-value="value">
      <div *ngIf="row.header === instanceSharpeHeader" style="padding: 10px;"
           [style.color]="hiddenSharpeAlphaRow[column.prop] > 0.5 ? 'white' : ''"
           [style.background-color]="'rgba(32, 123, 188, ' + hiddenSharpeAlphaRow[column.prop] + ')'">
        {{ value }}
      </div>
      <div *ngIf="row.header !== instanceSharpeHeader">
        {{ value }}
      </div>
    </ng-template>
  `,
} )

export class YieldTableComponent implements AfterViewInit {

  static COLUMN_DEFINITIONS = {
    'DGS1MO': '1 Month',
    'DGS3MO': '3 Month',
    'DGS6MO': '6 Month',
    'DGS1': '1 Year',
    'DGS2': '2 Year',
    'DGS5': '5 Year',
    'DGS7': '7 Year',
    'DGS10': '10 Year',
    'DGS20': '20 Year',
    'DGS30': '30 Year',
  };
  @Input() deviceIsMobile: boolean = false;
  @ViewChild( 'yieldTableCellTemplate' ) yieldTableCellTemplate: TemplateRef<any>;
  @ViewChild( 'yieldTable' ) tableComponent: DatatableComponent;

  @Input() rows: any[] = [];
  @Input() hiddenSharpeAlphaRow: any;

  columns: any[] = [];

  static readonly treasuryYieldHeader = 'Treasury Yield';
  static readonly deltaHeader = 'Incremental Compensation';
  static readonly durationHeader = 'Modified Duration';
  static readonly changeHeader = 'Yield Change Volatility';
  static readonly returnHeader = 'Return Volatility';
  static readonly sharpeHeader = 'Expected Sharpe Ratio';

  instanceSharpeHeader = YieldTableComponent.sharpeHeader;

  columnWidth: number = 80;

  ngAfterViewInit() {
    setTimeout( () => {
      this.setColumns();
    } );
  }

  getCellClass() {
    return ( { row, column } ) => {
      return {
        'no-padding-cell': row.header === YieldTableComponent.sharpeHeader,
        'boxed-table-cell': row.header === YieldTableComponent.sharpeHeader || row.header === YieldTableComponent.treasuryYieldHeader,
        'boxed-table-cell-first': ( row.header === YieldTableComponent.sharpeHeader || row.header === YieldTableComponent.treasuryYieldHeader ) && column.prop === 'DGS1MO',
        'boxed-table-cell-last': ( row.header === YieldTableComponent.sharpeHeader || row.header === YieldTableComponent.treasuryYieldHeader ) && column.prop === 'DGS30',
      };
    };
  }

  setColumns() {
    this.columns = [
      {
        name: '',
        prop: 'header',
        // frozenLeft: this.deviceIsMobile,
        sortable: false,
        width: 210,
        maxWidth: 250,
        minWidth: 210,
      },
    ];

    Object.keys( YieldTableComponent.COLUMN_DEFINITIONS ).forEach( ( key ) => {
      this.columns.push( {
        prop: key,
        name: YieldTableComponent.COLUMN_DEFINITIONS[key],
        sortable: false,
        cellTemplate: this.yieldTableCellTemplate,
        cellClass: this.getCellClass(),
      } );
    } );

    if ( this.deviceIsMobile ) {
      for ( let i = 1; i < this.columns.length; i++ ) {
        this.columns[i].width = this.columns[i].minWidth = this.columns[i].maxWidth = this.columnWidth;
      }
    }
  }
}
