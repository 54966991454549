import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketCalcTerms } from '../../utils/enums';


@Component( {
  selector: 'rip-term-radio',
  template: `
    <mat-button-toggle-group #termRadio class="term-button-group"
                             [value]="term"
                             (valueChange)="emitChange($event)">
      <mat-button-toggle *ngFor="let option of terms" [value]="option">
        {{ option | titlecase }} Term
      </mat-button-toggle>
    </mat-button-toggle-group>
  `,
} )

export class TermRadioComponent {

  @Output() termChanged: EventEmitter<MarketCalcTerms> = new EventEmitter<MarketCalcTerms>();

  @Input() term: MarketCalcTerms = MarketCalcTerms.short;
  terms: MarketCalcTerms[] = [ MarketCalcTerms.short, MarketCalcTerms.long ];

  instanceName: string;

  constructor() {
    this.instanceName = `termRadio_${ Math.random() * 100 }`;
  }

  emitChange( event: any ) {
    // console.log( `${this.instanceName} | emitChange event:`, event );
    if ( event && event !== this.term ) {
      this.termChanged.emit( event );
    }
  }
}
