import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utils/util.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class BenchmarkService {

  lambdaUserBaseUrl: string;

  constructor( private _http: HttpClient ) {
    this.lambdaUserBaseUrl = `${environment.ripsawAPIBaseUrl}/workspace-benchmarks`;
  }

  createBenchmark( benchmark: any ) {
    const url = `${this.lambdaUserBaseUrl}/create`;

    return this._http.post( url, benchmark )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getBenchmark() {
    const url = `${this.lambdaUserBaseUrl}/get`;
    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateBenchmark( benchmark: any ) {
    const url = `${this.lambdaUserBaseUrl}/update/${benchmark.id}`;
    return this._http.put( url, benchmark )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}

