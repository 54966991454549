import { Directive, Injector } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Directive()
export abstract class DialogBaseAbstract<T = any, R = any> {
  private dialogRef = this.injector.get(DialogRef);
  data = this.injector.get<T>(DIALOG_DATA);

  constructor(protected injector: Injector) {
  }

  close(output: R = null): void {
    this.dialogRef.close(output);
  }

}
