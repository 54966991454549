import { Injectable } from '@angular/core';
import  moment from 'moment';
import * as newtonRaphson from 'newton-raphson-method';
import { Util } from './util.service';
import { MortgageHelpers } from './mortgageHelpers';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ManualAccountUtil } from './manualAccount.util';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

export interface BondInterface {
  price: number;
  modifiedDuration: number;
  yieldToMaturity: number;
}


@Injectable()
export class BondHelpers {

  static calcPriceForInterestOnlyType( maturityDate: moment.Moment, maturityValue: number, coupon: number, couponFrequency: string, currentRate: number ) {
    const today = moment();
    const freq = this.getPayFreqIntFromString( couponFrequency );
    const freqUnit: moment.unitOfTime.DurationConstructor = this.getTimeUnitsForFrequency( couponFrequency );

    let remainingPeriods = Math.abs( maturityDate.diff( today, freqUnit, false ) );

    if ( couponFrequency === 'semi-annual' ) {
      remainingPeriods /= 2;
    }

    const couponPayment = maturityValue * coupon / freq;
    const periodCurrentRate = currentRate / freq;

    let pvOfCouponPayments = couponPayment * ( 1 - Math.pow( 1 + periodCurrentRate, -remainingPeriods ) ) / periodCurrentRate;
    const pvOfPrincipalPayment = maturityValue / Math.pow( 1 + periodCurrentRate, remainingPeriods );

    if ( isNaN( pvOfCouponPayments ) ) {
      pvOfCouponPayments = 0;
    }

    return pvOfCouponPayments + pvOfPrincipalPayment;
  }

  static calcBondAnalyticValuesFromYtm( principalAmount, coupon, maturityDate: Date, payFreq: string, ytm ) {

    // create a 'Bond' object to hold related data items
    // initialize yieldToMaturity with ytm and price and dur to 0
    const myBond: BondInterface = { yieldToMaturity: ytm, price: 0, modifiedDuration: 0 };

    const payFreqInt = this.getPayFreqIntFromString( ( payFreq ) );
    const couponPmt = principalAmount * coupon / payFreqInt;
    const numMonthsBetweenPmts = 12 / payFreqInt;

    const matDate = moment( maturityDate );
    const asOfDate = this.getTodayAsNewMoment();

    // calculate cashflows, currently stored in reverse order, need to change this?
    const cashflowDates = this.getCashflowDateList( asOfDate, matDate, payFreqInt );
    const nextCfDate = cashflowDates[cashflowDates.length - 1];
    const prevCfDate = nextCfDate.clone();
    prevCfDate.subtract( numMonthsBetweenPmts, 'months' );

    const numRemainingPmts = cashflowDates.length;

    let t = nextCfDate.diff( asOfDate, 'days' ) / 365;
    let df;
    let dirtyPrice = 0;
    let macauleyDur = 0;

    for ( let i = 0; i < numRemainingPmts; i++ ) {
      df = this.calcDiscountFactor( ytm, t );
      dirtyPrice += df * couponPmt;
      macauleyDur += df * couponPmt * t;

      if ( i === numRemainingPmts - 1 ) {
        // add back principal at maturity
        dirtyPrice += principalAmount * df;
        macauleyDur += principalAmount * df * t;
      }
      // adjust 't' for the next cf date
      t += ( 1 / payFreqInt ); // time in years
    }

    // finalize calculation of Macauley duration and use it to determine modified dur
    macauleyDur /= dirtyPrice;

    myBond.modifiedDuration = macauleyDur / ( 1 + ytm / payFreqInt );

    // determine the accrued interest reference date, which is the prev cf date (or issue date for non seasoned bonds)
    const accruedIntFactor = asOfDate.diff( prevCfDate, 'days' ) / nextCfDate.diff( prevCfDate, 'days' );
    const accruedInterest = accruedIntFactor * couponPmt;

    // return the clean price of the bond
    // return dirtyPrice - accruedInterest;
    myBond.price = dirtyPrice - accruedInterest;

    return myBond;
  }

  static calcBondAnalyticValuesFromPrice( principalAmount, coupon, maturityDate, payFreq, cleanPrice, ytmGuess ) {

    // create a 'Bond' object to hold related data items
    // initialize price with cleanPrice and ytm and dur to 0
    let myBond: BondInterface = { yieldToMaturity: 0, price: cleanPrice, modifiedDuration: 0 };

    const pv = function ( ytm ) {
      myBond = BondHelpers.calcBondAnalyticValuesFromYtm( principalAmount, coupon, maturityDate, payFreq, ytm );
      const err = myBond.price - cleanPrice;
      return err;
    };

    const nrOptions = { tolerance: 0.001, maxIter: 100 };
    const irrResult = newtonRaphson( pv, ytmGuess, nrOptions ); // use 1% as starting guess
    if ( irrResult === false ) {
      myBond.yieldToMaturity = null;
    } else {
      myBond.yieldToMaturity = irrResult;
    }

    return myBond;
  }

  static getMomentAtStartOfDay( date: Date | moment.Moment ): moment.Moment {
    return moment( date ).startOf( 'date' );
  }

  /*
  The logic below may eventually be moved to a separate quant finance helper class
  Currently it is only utilized by bonds, but could be applicable to other security types
   */
  static calcTimeToMaturityDate( maturityDate: moment.Moment ): number {
    // const matDate = moment( maturityDate ); // BondHelpers.getMomentAtStartOfDay( maturityDate );
    const asOfDate = this.getTodayAsNewMoment();
    return maturityDate.diff( asOfDate, 'days' ) / 365;
  }

  // this logic should be moved to wherever the live treasury data comes from, TO DO: get actual rate data
  static getDurationMatchedTreasuryRateInPercent( duration, rates ) {
    if ( typeof duration === 'string' ) {
      duration = FormsUtil.getSanitizedFloatValue( duration );
    }
    return ManualAccountUtil.interpolateDurationMatchedTreasuryRate( rates, duration );
  }

  static geTimeToMaturityMatchedTreasuryRateInPercent( maturityDate, rates ) {
    // modified duration can only be calculated after price/yield, which requires the treasury rate
    // therefore, use time to maturity as a proxy for duration to determine the correct treasury rate
    const timeToMaturity = this.calcTimeToMaturityDate( maturityDate );
    return this.getDurationMatchedTreasuryRateInPercent( timeToMaturity, rates );
  }

  static calcLoanTermFromMaturityDate( form: UntypedFormGroup, selectedLoanTermUnits: MatButtonToggleGroup ) {
    if ( form.controls.loan_origination_date.value && form.controls.maturity_date.value ) {
      form.controls.loan_term_in_months.setValue(
        MortgageHelpers.calcOrigTermFromMaturityDate(
          form.controls.loan_origination_date.value,
          form.controls.maturity_date.value ) );
      if ( form.controls.loan_term_in_months.value % 12 === 0 ) {
        form.controls.loan_term.setValue( form.controls.loan_term_in_months.value * 12 );
        selectedLoanTermUnits.value = 'years';
      } else {
        form.controls.loan_term.setValue( form.controls.loan_term_in_months.value );
        selectedLoanTermUnits.value = 'months';
      }

    }
  }

  static calcMaturityDateFromLoanTerm( form: UntypedFormGroup, selectedLoanTermUnits: string ) {
    if ( form.controls.loan_origination_date.value && form.controls.loan_term_in_months.value ) {

      if ( selectedLoanTermUnits === 'months' ) {
        form.controls.loan_term_in_months.setValue( form.controls.loan_term.value );
      } else {
        form.controls.loan_term_in_months.setValue( form.controls.loan_term.value * 12 );
      }
      const matDate: moment.Moment = MortgageHelpers.calcMaturityDateFromOrigTerm( form.controls.loan_origination_date.value, form.controls.loan_term_in_months.value );
      if ( matDate ) {
        form.controls.maturity_date.setValue( matDate.utc() );  // must use moment object
      }
      // this.form.controls.maturity_date.setValue(matDate);
    }

  }

  static calcOriginationDate( form: UntypedFormGroup, selectedLoanTermUnits: string ) {
    if ( form.controls.loan_term.value && form.controls.maturity_date.value ) {

      if ( selectedLoanTermUnits === 'months' ) {
        form.controls.loan_term_in_months.setValue( form.controls.loan_term.value );
      } else {
        form.controls.loan_term_in_months.setValue( form.controls.loan_term.value * 12 );
      }
      const origDate: moment.Moment = MortgageHelpers.calcOriginationDateFromMaturityDateAndTerm( form.controls.maturity_date.value, form.controls.loan_term_in_months.value );
      if ( origDate ) {
        form.controls.loan_origination_date.setValue( origDate.utc() );  // must use moment object
      }
    }
  }

  static calculateTimeToMaturityInYears( form: UntypedFormGroup ) {
    if ( form.controls.maturity_date.value ) {
      const maturityInYears = BondHelpers.calcTimeToMaturityDate( form.controls.maturity_date.value );
      Util.setFormattedValue( 'maturity_in_years', 'Decimal', maturityInYears, form );
    }
  }

  static calculateMarketValue( form: UntypedFormGroup ) {
    if ( form.controls.price.value ) {

      // set the market value as price times quantity, assume quantity = 1 if not provided
      if ( form.controls.quantity.value.toString() === '' ) {
        form.controls.quantity.setValue( 1 );
      }
      const marketValue =
        FormsUtil.getSanitizedFloatValue( form.controls.price.value, false ) * FormsUtil.getSanitizedFloatValue( form.controls.quantity.value, false );
      Util.setFormattedValue( 'value', 'Currency', marketValue, form );

    }
  }

  private static getPayFreqIntFromString( payFreq ) {
    let payFreqInt = 12;

    if ( payFreq === 'annual' ) {
      payFreqInt = 1;
    } else if ( payFreq === 'semi-annual' ) {
      payFreqInt = 2;
    } else if ( payFreq === 'quarter' ) {
      payFreqInt = 4;
    } else if ( payFreq === 'month' ) {
      payFreqInt = 12;
    }

    return payFreqInt;
  }

  private static getTimeUnitsForFrequency( payFreq ): moment.unitOfTime.DurationConstructor {
    let payFreqUnit: moment.unitOfTime.DurationConstructor;

    if ( payFreq === 'annual' ) {
      payFreqUnit = 'years';
    } else if ( payFreq === 'semi-annual' ) {
      payFreqUnit = 'years';
    } else if ( payFreq === 'quarter' ) {
      payFreqUnit = 'quarters';
    } else if ( payFreq === 'month' ) {
      payFreqUnit = 'months';
    }

    return payFreqUnit;
  }

  static getTodayAsNewMoment(): moment.Moment {
    return moment().startOf( 'date' );
  }

  private static getCashflowDateList( startDate, maturityDate, payFreqInt ): moment.Moment[] {

    const numMonthsBetweenPmts = 12 / payFreqInt;

    // create an array of cashflow dates, where index = 0 corresponds to maturitydate
    const cashFlowDates: moment.Moment[] = [];
    let checkDate = maturityDate.clone();
    let index = 0;
    while ( startDate.isBefore( checkDate ) ) {
      cashFlowDates[index] = checkDate.clone();
      checkDate = checkDate.subtract( numMonthsBetweenPmts, 'months' );
      index++;
    }

    return cashFlowDates;
  }

  private static calcDiscountFactor( rate, accrualFactor ) {
    /*
    Assume semi-annual compounding and act/365 day count.  Can be expanded if desired
    rate is assumed to be in percent format
    */
    const compoundingFreq = 2;
    return 1 / ( Math.pow( ( 1 + rate / compoundingFreq ), accrualFactor * compoundingFreq ) );
  }

  static getTodaysRate( maturityDate, todaysRates ) {

    const yearsLeft = moment( maturityDate ).diff( moment( new Date() ), 'years', true );
    let rate;
    if ( yearsLeft > 20 ) { // use 30 year rate
      rate = todaysRates.Fixed30Year.refi.rate;
    } else if ( yearsLeft > 15 ) { // use 20 year rate
      rate = todaysRates.Fixed20Year.refi.rate;
    } else if ( yearsLeft > 10 ) {  // use 15 year rate
      rate = todaysRates.Fixed15Year.refi.rate;
    } else {
      rate = todaysRates.Fixed10Year.refi.rate;
    }
    /*
    else if ( yearsLeft > 7 ) { // use 10 year rate
      rate = todaysRates.Fixed10Year.refi.rate;
    } else if ( yearsLeft > 5 ) { // use 7 year arm rate
      rate = todaysRates.ARM7.refi.rate;
    } else if ( yearsLeft > 3 ) { // use 5 year arm rate
      rate = todaysRates.ARM5.refi.rate;
    } else { // use 3 year arm rate
      rate = todaysRates.ARM3.refi.rate;
      if ( rate === 0 ) { // have seen this one be 0, but not others
        rate = todaysRates.ARM5.refi.rate;
      }
    }*/

    return rate;
  }

  static calcMaturityDate( originationDate, term ) {
    const origDate = moment( originationDate );

    return origDate.add( term, 'years' ).format( 'YYYY-MM-DD' );
  }

  static formatZillowRates( todaysRates ) {
    const rates = [];
    const keys = Object.keys( todaysRates ).sort();

    for ( const key of keys ) {
      const rate = todaysRates[key];
      switch ( key ) {
        /*case 'ARM3':
          rate.name = '3 Year ARM';
          break;
        case 'ARM5':
          rate.name = '5 Year ARM';
          break;
        case 'ARM7':
          rate.name = '7 Year ARM';
          break;*/
        case 'Fixed10Year':
          rate.name = '10 Year Fixed';
          break;
        case 'Fixed15Year':
          rate.name = '15 Year Fixed';
          break;
        case 'Fixed20Year':
          rate.name = '20 Year Fixed';
          break;
        case 'Fixed30Year':
          rate.name = '30 Year Fixed';
          break;

      }
      if ( rate.refi.rate !== 0 && rate.name ) {
        rates.push( rate );
      }
    }
    return rates;
  }

}
