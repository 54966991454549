import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GlobalDataService } from '../../../../../globalData';
import { GlobalState } from '../../../../../global.state';
import { Util } from '../../../../../utils/util.service';
import * as _ from 'lodash-es';
import { environment } from '../../../../../../environments/environment';
import { RipsawCurrencyPipe, RipsawDecimalPipe } from '../../../../../theme/pipes';
import { AccountManager } from '../../../../../utils/accountManager';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { CityStateZipFormComponent } from './components/cityStateZipForm.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../../utils/mobileUtil.service';
import { ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import * as parseAddress from 'parse-address';
import { AddressAutocompleteComponent } from '../../../../../reusableWidgets/addressAutocomplete/address-autocomplete.component';
import { faCar, faGem, faHouse, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { GlossaryUtil } from '../../../../../utils/glossary.util';
import { Account, FormsUtil } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from '../../../../../utils/logger.service';

@Component( {
  selector: 'rip-real-asset-form',
  templateUrl: './realAssetForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class RealAssetFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'cityStateZipComponent', { static: false } ) cityStateZipComponent: CityStateZipFormComponent;

  faInfoCircle = faInfoCircle;
  faHouse = faHouse;
  faCar = faCar;
  faGem = faGem;
  faQuestionCircle = faQuestionCircle;

  @Input() form: UntypedFormGroup;
  @Input() matcher: any;
  @Input() type: string;
  @Input() account: Account;
  @Input() addingInRevision: boolean = false;
  @Input() hideCorrespondingLiability: boolean = false;

  showZestimateLabel: boolean = false;
  zestimateValue;

  zillowLogoUrl: string;

  publicUtil: typeof Util;

  ripCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe = new RipsawDecimalPipe();

  allLoanAccounts: any[] = [];

  subscriberName: string = 'realAssetForm';

  goToZillowButtonDisabled: boolean = true;
  states: any[] = [];

  hasCorrespondingLiability: boolean;

  deviceIsMobile: boolean = false;

  constructor( private _gdService: GlobalDataService,
               private _state: GlobalState,
               private _accountManager: AccountManager,
               private _deviceDetector: DeviceDetectorService,
               private glossaryUtil: GlossaryUtil ) {
    super();
    this.zillowLogoUrl = environment.common.zillowLogoUrl;
    this.publicUtil = Util;
    this.deviceIsMobile = MobileUtil.deviceIsMobile( _deviceDetector );

    this.states = _state.states;
  }

  ngAfterViewInit(): void {
    // Logger.log( this.account );
    setTimeout( () => {
      this.patchForm();
    } );
    this.setupUpdateSubscription( this._state );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    super.patchForm();
    this.form.controls.value.disable();
    this.setAllLoanAccounts();

    if ( this.account && this.form ) {
      ManualAccountUtil.patchRealAssetForm( this.form, this.account );
      if ( this.type === 'Real Estate' ) {
        const val = `${ this.form.controls.address.value } ${ this.form.controls.cityStateZip.value }`;

        const parsed = parseAddress.parseLocation( val );

        Logger.log( parsed );

        if ( parsed.number && parsed.street ) {
          this.form.controls.address_line1.setValue( AddressAutocompleteComponent.combineParsedStreetAddress( parsed ) );
        }

        if ( parsed.city ) {
          this.form.controls.address_city.setValue( parsed.city );
        }
        if ( parsed.zip ) {
          this.form.controls.address_zip.setValue( parsed.zip );
        }
        if ( parsed.state ) {
          this.form.controls.address_state.setValue( parsed.state );
        }
      }
      if ( this.account.address && this.account.cityStateZip ) {
        this.goToZillowButtonDisabled = false;
      }

      ManualAccountUtil.checkForCorrespondingAccount( this.account, this.form, this.allLoanAccounts, 'corresponding_liability_id' );
      if ( this.form.controls.corresponding_liability_id?.value?.length > 0 ) {
        this.hasCorrespondingLiability = !!this._accountManager.getOriginalAccountFromId( this.form.controls.corresponding_liability_id?.value );
      }
    } else {

      this.form.controls.name?.setValue( 'My Home' );

      this.form.controls.owner_type.setValue( 'Individual Account' );
      if ( this.addingInRevision && this.isValuables() ) {
        this.form.controls.quantity.setValue( 0 );
      } else {
        this.form.controls.quantity.setValue( 1 );
      }
      this.form.controls.annualized_yield.setValue( 0 );
      Util.updateInputPercentFormat( 'annualized_yield', this.form, true );
      this.autofocus();
    }
  }

  isValuables() {
    return this.type === 'Valuable(s)';
  }

  onPriceChange() {
    const price = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value );
    if ( this.form.controls.quantity.value ) {
      this.form.controls.value.setValue( this.ripCurrencyPipe.transform( price * this.form.controls.quantity.value ) );
      this.valueChanged();
    }
    this.form.controls.price.setValue( this.ripCurrencyPipe.transform( price ) );
    this.showZestimateLabel = this.form.controls.value.value === this.zestimateValue;
  }

  onQuantityChange() {
    if ( this.form.controls.price.value ) {
      const price = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value );
      this.form.controls.value.setValue( this.ripCurrencyPipe.transform( price * this.form.controls.quantity.value ) );
    }
    this.form.controls.quantity.setValue( this.ripDecimalPipe.transform( this.form.controls.quantity.value, '0-2' ) );
    this.valueChanged();
  }

  goToZillowSite() {

    const address = this.form.controls.address_line1.value?.split( ' ' ).join( '-' ).trim();
    const address2 = this.form.controls.address_line2.value?.split( ' ' ).join( '-' ).trim();
    const city = this.form.controls.address_city.value?.trim();
    const state = this.form.controls.address_state.value?.trim();
    const zip = this.form.controls.address_zip.value?.trim();

    let url = `${ ManualAccountManagerState.zillowUrl }/${ address.split( ' ' ).join( '-' ) }`;

    if ( address2 && address2.length > 0 ) {
      url = `${ url },${ address2 }`;
    }

    if ( city && city.length > 0 ) {
      url = `${ url },${ city }`;
    }
    if ( state && state.length > 0 ) {
      url = `${ url },${ state }`;
    }
    if ( zip && zip.length > 0 ) {
      url = `${ url },${ zip }`;
    }

    Util.openExternalUrl( `${ url }_rb` );

    // Old zillow zestimate code
    /*this._gdService.getRealEstateValue( this.form.controls.address.value, this.form.controls.cityStateZip.value )
     .subscribe( ( resp: any ) => {
     // Logger.log( resp );
     if ( resp.data.amount ) {
     this.form.controls.price.setValue( this.ripCurrencyPipe.transform( resp.data.amount ) );
     this.form.controls.value.setValue( this.ripCurrencyPipe.transform( resp.data.amount * this.form.controls.quantity.value ) );
     this.valueChanged();
     this.zestimateValue = resp.data.amount;
     this.showZestimateLabel = true;
     this.zestimateButtonOptions.active = false;
     } else {
     this.zestimateButtonOptions.active = false;
     this.snackBar.open( 'Zillow did not return a price for the address entered',
     null,
     Util.getSnackBarOptions() );
     }
     }, ( err ) => {
     this.snackBar.open( err.err, null, Util.getSnackBarOptions() );
     this.zestimateButtonOptions.active = false;
     } );*/

  }

  setAllLoanAccounts() {
    this.allLoanAccounts = _.filter(
      this.addingInRevision ? this._accountManager.getAllRevisableAccounts() : this._accountManager.getAllOriginalAccounts(),
      ( a: any ) => {
        return a.account_category === 'Loan';
      } ) || [];
  }

  addressChange() {
    this.goToZillowButtonDisabled = !( this.form.controls.address_line1.value.length > 5 && this.form.controls.address_city.value.length > 2 && this.form.controls.address_state.value.length >= 2 && this.form.controls.address_zip.value.length >= 5 );
    // these two lines are crucial for keeping the form controls that are used in the setFields function of the manualAccountManagerState up to date.
    this.form.controls.address.setValue( this.form.controls.address_line1.value );
    this.form.controls.cityStateZip.setValue( `${ this.form.controls.address_city.value }, ${ this.form.controls.address_state.value } ${ this.form.controls.address_zip.value }` );
  }

  dollarFlowChanged() {
    Util.updateInputCurrencyFormat( 'dollar_flow', this.form );
    if ( this.form.controls.value.value ) {
      const sanitizedDollarFlow = FormsUtil.getSanitizedFloatValue( this.form.controls.dollar_flow.value );
      this.form.controls.annualized_yield.setValue( sanitizedDollarFlow / FormsUtil.getSanitizedFloatValue( this.form.controls.value.value ) * 100 );
      Util.updateInputPercentFormat( 'annualized_yield', this.form, true );
    }
  }

  yieldChanged() {
    Util.updateInputPercentFormat( 'annualized_yield', this.form, true );
    if ( this.form.controls.value.value ) {
      const sanitizedYield = FormsUtil.getSanitizedFloatValue( this.form.controls.annualized_yield.value ) / 100;
      const sanitizedValue = FormsUtil.getSanitizedFloatValue( this.form.controls.value.value );
      this.form.controls.dollar_flow.setValue( sanitizedYield * sanitizedValue );
      Util.updateInputCurrencyFormat( 'dollar_flow', this.form );
    }
  }

  valueChanged() {
    if ( this.form.controls.dollar_flow.value ) {
      this.dollarFlowChanged();
    }
    // want the cost basis for new real assets to be set to the value and then the user can change it if they want
    if ( this.addingInRevision ) {
      this.form.controls.cost_basis.setValue( this.form.controls.value.value );
    }
  }

  /*
   * Function to open the disclaimer modal for displaying the disclaimers
   * */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }


}
