<div class="container">
  <div class="row">
    <div class="col-5">
      <h6 class="proxy-list-item">
        Current Proxy: {{weight.proxy || 'No Proxy Chosen Yet'}}
      </h6>
    </div>
    <div class="col-7 proxy-picker-buttons">
      <button mat-raised-button
              type="button"
              class="choose-proxy-button"
              (click)="chooseButtonPressed()"
              matTooltip="Click to choose a new security"
              matTooltipClass="mat-tooltip-custom">
        Choose Security
      </button>
      <button mat-raised-button type="button" nbStepperPrevious>Cancel</button>
    </div>
  </div>
</div>
