import { Injectable } from '@angular/core';
import { GlobalState } from '../../global.state';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertModalComponent } from './alertModal.component';

@Injectable()
export class AlertModalService {
  constructor( private _state: GlobalState, private dialog: MatDialog ) {

  }

  /**
   *
   * @param {object} params
   * @param {string} params.title - text to display at the header of the modal - default is ''
   * @param {string} params.message -text to display in the body of the modal - default is ''
   * @param {string} params.buttonLabel - ''
   * @param {string} params.closeButtonLabel - text to display on the button that closes the alert - default is 'Close'
   * @param {boolean} params.showCloseButton whether or not to show a close button
   * @param {Function} params.callback if provided, will be run when button is clicked
   * @param {number} params.disclaimerIndex - if not null, will display link to open disclaimers dialog to specified index
   * @param {object} params.clickHere object containing information about the clickable link
   * @param {string} params.clickHere.message - text to display on clickHere link to disclaimers dialog
   */
  showAlert( params: any ): MatDialogRef<any> {
    // {title: string, message: string, buttonLabel?: string, callback?: Function}
    return this.dialog.open( AlertModalComponent, {
      data: params,
    } );
  }
}
