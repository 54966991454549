import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Util } from '../../utils/util.service';
import { catchError, map } from 'rxjs/operators';
import { GlobalState } from '../../global.state';
import { Observable } from 'rxjs';
import { Auth } from '../../auth.service';
import { PaymentMethod } from '@stripe/stripe-js';

export interface RegisterDataInterface {
  email: string;
  name: string;
  password: string;
  agreed_to_terms: boolean;
  plan_id?: string;
  pricing_id?: string;
  birth_date?: Date;
}

@Injectable()
export class RegisterService {

  private readonly lambdaUsersUrl: string;
  private readonly lambdaStripeUrl: string;
  private readonly baseApiPath: string;

  constructor( private _http: HttpClient, private _state: GlobalState ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaUsersUrl = `${ this.baseApiPath }/users`;
    this.lambdaStripeUrl = `${ this.baseApiPath }/stripe`;
  }

  register( data: RegisterDataInterface ): Observable<any> {
    const url = `${ this.lambdaUsersUrl }/register`;
    const headers = new HttpHeaders( { 'Content-Type': 'application/json' } );
    // console.log( `data: ${JSON.stringify( data )}` );
    return this._http.post( url, data, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  createPaymentSubscription( promo: any, trialDays: number, stripeToken: string, paymentMethod: PaymentMethod ) {
    const url = `${ this.lambdaStripeUrl }/subscription`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );

    const body: any = { promo: [promo], trialDays };

    if ( stripeToken ) {
      body.stripeToken = stripeToken;
    }

    if ( paymentMethod ) {
      body.paymentMethod = paymentMethod;
    }

    return this._http.post( url, body, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
