<div class="existing-manual-accounts-col" [nbSpinner]="!dataRetrieved">

  <!--        <p>Refreshing Accounts...</p>-->
  <div
      *ngIf="(!mamState.manualAccounts || mamState.manualAccounts.length === 0) && dataRetrieved; else manualAccountsAccordion"
      class="empty-placeholder">
    <p class="empty-placeholder-header">No accounts yet</p>
    <p class="empty-placeholder-text" *ngIf="!inWealthFluent; else wealthFluentMessage">
      Explore the Add Manual Accounts tab to begin
    </p>
    <ng-template #wealthFluentMessage>
      <p class="empty-placeholder-text">
        Explore the task manager in the dashboard to begin
      </p>
    </ng-template>
  </div>
  <ng-template #manualAccountsAccordion>
    <mat-accordion class="manager-accordion" *ngIf="dataRetrieved">
      <ng-container *ngIf="mamState.manualAccounts | accountVisibilityBySettingPermission | async as accounts">
        <!-- EXISTING MANUAL ACCOUNT START -->
        <mat-expansion-panel *ngFor="let account of accounts" [expanded]="account.expanded"
                             (opened)="account.expanded = true"
                             (closed)="account.expanded = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <rip-institution-icon #institutionIcon
                                    [acct]="account"
                                    [enabled]="true">

              </rip-institution-icon>
              {{ account.formattedDescription }}
            </mat-panel-title>
            <mat-panel-description>
              <span class="pull-right">{{ account.value | currency : 'USD' : 'symbol' }}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>


          <div *ngIf="mamState.existingAccountForms[account.account_id].form">
            <div class="row account-header-row">
              <div class="col col-12">
                <mat-horizontal-stepper class="no-header-stepper">
                  <mat-step>
                    <div>
                      <button *ripPerm="[perm.OWNER, perm.ACCOUNTS_DELETE]" mat-button color="warn"
                              class="pull-right"
                              matStepperNext>
                        Remove Account
                      </button>
                    </div>
                  </mat-step>
                  <mat-step>
                    <div style="text-align: right;">
                      Clicking REMOVE will remove this manual account from {{ mamState.appName }}.
                      You cannot undo this.
                    </div>
                    <rip-progress-bar-button
                        [options]="mamState.existingAccountForms[account.account_id].removeButtonOptions"
                        (click)="mamState.deleteManualAccount(account)"
                        class="pull-right">

                    </rip-progress-bar-button>
                    <button mat-button matStepperPrevious class="pull-right">
                      Cancel
                    </button>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>
              <div class="col col-12">
                <div class="flexing space-around">
                  <div *ripPerm="[perm.OWNER, perm.ACCOUNT_SETTINGS_VIEW, perm.ACCOUNT_SETTINGS_EDIT]">
                    <label
                        [style.opacity]="mamState.existingAccountForms[account.account_id].form.controls.included?.disabled ? '0.4' : ''"
                    >Include</label>
                    <mat-checkbox
                        [formControl]="mamState.existingAccountForms[account.account_id].form.controls.included"
                        (change)="mamState.toggleIncluded(account, mamState.existingAccountForms[account.account_id].form)">

                    </mat-checkbox>
                  </div>
                  <div>
                    <rip-account-fees-editor [account]="account"
                                             [accountForm]="mamState.existingAccountForms[account.account_id].form">

                    </rip-account-fees-editor>
                  </div>
                </div>
              </div>
            </div>
            <!-- use account.type or account.category to determine which component to use to complete the form -->
            <div [ngSwitch]="mamState.existingAccountForms[account.account_id]?.formType">
              <div *ngSwitchCase="'realAsset'">
                <rip-real-asset-form [form]="mamState.existingAccountForms[account.account_id].form"
                                     [matcher]="mamState.matcher"
                                     [type]="account.account_type"
                                     [account]="account"
                                     #existingForm>
                </rip-real-asset-form>
              </div>
              <div *ngSwitchCase="'stock'">
                <rip-stock-form [form]="mamState.existingAccountForms[account.account_id].form"
                                [matcher]="mamState.matcher"
                                [type]="account.account_type"
                                [account]="account"
                                #existingForm>
                </rip-stock-form>
              </div>
              <div *ngSwitchCase="'stockOption'">
                <rip-stock-option-form [form]="mamState.existingAccountForms[account.account_id].form"
                                       [matcher]="mamState.matcher"
                                       [type]="account.account_type"
                                       [account]="account"
                                       #existingForm>
                </rip-stock-option-form>
              </div>
              <div *ngSwitchCase="'bond'">
                <rip-bond-form [form]="mamState.existingAccountForms[account.account_id].form"
                               [matcher]="mamState.matcher"
                               [type]="account.account_type"
                               [account]="account"
                               [amortizationType]="account.amortization_type"
                               #existingForm>
                </rip-bond-form>
              </div>
              <div *ngSwitchCase="'bank'">
                <rip-bank-form [form]="mamState.existingAccountForms[account.account_id].form"
                               [matcher]="mamState.matcher"
                               [type]="account.account_type"
                               [account]="account"
                               #existingForm>
                </rip-bank-form>
              </div>
              <div *ngSwitchCase="'cash'">
                <rip-cash-form [form]="mamState.existingAccountForms[account.account_id].form"
                               [matcher]="mamState.matcher"
                               [type]="account.account_type"
                               [account]="account"
                               #existingForm>
                </rip-cash-form>
              </div>
              <div *ngSwitchCase="'crypto'">
                <rip-crypto-form [form]="mamState.existingAccountForms[account.account_id].form"
                                 [matcher]="mamState.matcher"
                                 [type]="account.account_type"
                                 [account]="account"
                                 #existingForm>
                </rip-crypto-form>
              </div>
              <div *ngSwitchCase="'loan'">
                <rip-loan-form [form]="mamState.existingAccountForms[account.account_id].form"
                               [matcher]="mamState.matcher"
                               [type]="account.account_type"
                               [account]="account"
                               [amortizationType]="account.amortization_type"
                               #existingForm>
                </rip-loan-form>
              </div>
              <div *ngSwitchCase="'investment'">
                <rip-investment-form
                    [form]="mamState.existingAccountForms[account.account_id].form"
                    [matcher]="mamState.matcher"
                    [type]="account.account_type"
                    [account]="account"
                    #existingForm>
                </rip-investment-form>
              </div>
              <div *ngSwitchCase="'annuity'">
                <rip-annuity-form
                    [form]="mamState.existingAccountForms[account.account_id].form"
                    [matcher]="mamState.matcher"
                    [type]="account.account_type"
                    [account]="account"
                    #existingForm>
                </rip-annuity-form>
              </div>
              <div *ngSwitchCase="'term-life-insurance'">
                <rip-term-life-insurance-form
                    [form]="mamState.existingAccountForms[account.account_id].form"
                    [matcher]="mamState.matcher"
                    [type]="mamState.accountType.type"
                    [account]="account"
                    #existingForm>
                </rip-term-life-insurance-form>
              </div>
            </div>
            <div class="flexing end">
              <rip-progress-bar-button
                  *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]"
                  [options]="mamState.existingAccountForms[account.account_id].saveButtonOptions"
                  (onClick)="updateManualAccount(account, mamState.existingAccountForms[account.account_id].form)">
              </rip-progress-bar-button>
              <span *ngIf="mamState.existingAccountForms[account.account_id]?.showInvalidMessage"
                    class="invalid-message">{{ mamState.invalidMessage }}</span>
              <br>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- EXISTING MANUAL ACCOUNT END -->
      </ng-container>
    </mat-accordion>
  </ng-template>
</div>
