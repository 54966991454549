import { SharedWorkspaceDto, WorkspaceWithUsersDto } from '../../../../../../../../api';
import { WorkspaceLoadedStore } from '../../../../../../../../store/workspace';

type WorkspaceMenuGroupItem = (WorkspaceWithUsersDto | SharedWorkspaceDto) & {
  isCreating: boolean;
};

export interface WorkspaceMenuGroup {
  name: string;
  key: WorkspaceLoadedStore['type'];
  items: WorkspaceMenuGroupItem[];
  dashboardPath: string;
}

export const getWorkspaceMenuGroups = (userIsFinancialAdvisor: boolean): WorkspaceMenuGroup[] => [
  {
    name: 'My workspaces',
    key: 'my',
    items: [],
    dashboardPath: '/pages/workspaces/my',
  },
  {
    name: userIsFinancialAdvisor ? 'Client Workspaces' : 'Shared with me',
    key: 'shared',
    items: [],
    dashboardPath: '/pages/workspaces/shared',
  },
];
