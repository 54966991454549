import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component( {
  template: `
    <div mat-dialog-title>{{ title }}</div>
    <mat-divider class="modal-header-divider"></mat-divider>
    <div mat-dialog-content>
      {{ message }}
    </div>
    <mat-divider class="modal-footer-divider"></mat-divider>
    <div mat-dialog-actions>
      <button mat-button (click)="onClickCancel()">{{ cancelButtonLabel }}</button>
      <button mat-raised-button class="confirm-button" color="warn" (click)="onClickConfirm()">
        {{ confirmButtonLabel }}
      </button>
    </div>
  `,
  styleUrls: [ './confirmation-dialog.component.scss' ],
} )
export class ConfirmationComponent {

  title: string = 'You have unsaved changes';
  message: string = 'What would you like to do?';
  confirmButtonLabel: string = 'LEAVE WITHOUT SAVING';
  cancelButtonLabel: string = 'Stay Here';

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmationComponent, boolean>,
    @Inject( MAT_DIALOG_DATA ) public data: any ) {
    if ( data?.title ) {
      this.title = data.title;
    }
    if ( data?.message ) {
      this.message = data.message;
    }
    if ( data?.confirmButtonLabel ) {
      this.confirmButtonLabel = data.confirmButtonLabel;
    }
    if ( data?.cancelButtonLabel ) {
      this.cancelButtonLabel = data.cancelButtonLabel;
    }
  }

  onClickCancel() {
    this.dialogRef.close( false );
  }

  onClickConfirm() {
    this.dialogRef.close( true );
  }

}
