import { AfterViewInit, Component, Input, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Account, Holding } from '@ripsawllc/ripsaw-analyzer';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { RipsawCurrencyPipe } from '../../../theme/pipes';
import { environment } from '../../../../environments/environment';
import { GlobalState } from '../../../global.state';
import { MobileAccountDetailsComponent } from './mobileAccountDetails.component';
import { AccountManager } from '../../../utils/accountManager';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component( {
  selector: 'rip-holding-detail',
  template: `
    <div style="padding: 40px 5px;">

      <div class="col-12" style="display: flex; margin: 5px 0;">
        <span style="flex-grow: 2;">
          <strong>Price</strong>: {{ holding?.price | ripCurrencyPipe }}
        </span>
        <span style="flex-grow: 2;">
          <strong>Quantity</strong>: {{ holding?.quantity | ripDecimalPipe : '2-4' }}
        </span>
      </div>
      <div class="col-12" style="display: flex; margin: 5px 0;">
        <span style="flex-grow: 2;">
          <strong>Allocation</strong>: {{ holding?.allocation | ripPercentPipe }}
        </span>
        <span style="flex-grow: 2;">
          <strong>Yield</strong>: {{ holding?.annualized_yield | ripPercentPipe }}
        </span>
      </div>
      <div class="col-12" style="display: flex; margin: 5px 0;">
        <span style="flex-grow: 2;">
          <strong>Expense</strong>: {{
            holding?.expense_ratio !== undefined && holding?.expense_ratio !== null ?
              ( holding?.expense_ratio | ripPercentPipe ) : 'N/A'
          }}
        </span>
        <span style="flex-grow: 2;">
          <strong>Type</strong>: {{ holding?.security_type }}
        </span>
      </div>

      <!-- ACCOUNTS -->

      <ngx-datatable id="accounts-table"
                     *ngIf="holding?.positions"
                     [rows]="rows"
                     [columns]="columns"
                     [rowHeight]="60"
                     (activate)="rowClicked($event)"
                     class="material"></ngx-datatable>

      <!-- END OF ACCOUNTS -->

      <!-- FUND DETAILS -->
      <div class="col-12">
        <div class="row" *ngIf="!holding?.shouldBeFullWidth">
          <div *ngIf="holding?.security_type === 'Mutual Fund' || holding?.security_type === 'ETF'">
            <h5>
              Fund Investment Strategy:
            </h5>
            <p>
              {{ holding?.investment_strategy || 'No Investment Strategy Provided' }}
            </p>
          </div>
          <div *ngIf="holding?.security_type === 'Equity'">
            <p>
              <rip-trading-view-fundamentals [symbol]="holding?.ticker"
                                             (errorLoading)="holding.hideFundamentals = true;"
                                             [height]="screenWidth"></rip-trading-view-fundamentals>
            </p>
            <p *ngIf="holding?.hideFundamentals">No Financials Data Found For This Security</p>
          </div>
        </div>
        <div class="row" *ngIf="!holding?.shouldBeFullWidth">
          <div
            *ngIf="holding?.security_type === 'ETF' || holding?.security_type === 'Equity' || holding?.security_type === 'Crypto'">
            <!--<rip-trading-view-chart [ticker]="holding.ticker"></rip-trading-view-chart>-->
            <!--                        <rip-stockdio-chart [ticker]="holding.ticker"></rip-stockdio-chart>-->
            <!--                        <rip-trading-view-mini-chart [ticker]="holding.ticker"></rip-trading-view-mini-chart>-->
            <rip-trading-view-symbol-overview [ticker]="holding?.ticker"
                                              [description]="holding?.ticker_name"
                                              [brandingLabel]="holding?.ticker + ' data '"
                                              [height]="screenWidth" [initChartRightAway]="true">

            </rip-trading-view-symbol-overview>
          </div>
        </div>
      </div>
    </div>

    <ng-template #gainLossTemplate ngx-datatable-cell-template let-row="row">
      <div *ngIf="row?.position?.gain_loss" class="gain-loss" [ngClass]="{
                                'up': row.position.gain_loss > 0,
                                'down': row.position.gain_loss < 0
                              }">
        <mat-grid-list cols="1" rowHeight="20px">
          <mat-grid-tile [colspan]="1" [rowspan]="1">
                                  <span *ngIf="row?.position?.gain_loss > 0">
                                    <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
                                  </span>
            <span *ngIf="row.position.gain_loss < 0">
                                    <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
                                  </span>
            {{ row?.position?.cost_basis !== undefined ? ( row?.position?.gain_loss / row?.position?.cost_basis | ripPercentPipe : '0-2' ) : 'N/A' }}
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="1">
            {{ row?.position?.cost_basis !== undefined ? ( row?.position?.gain_loss | ripCurrencyPipe ) : 'N/A' }}
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </ng-template>

    <ng-template #costBasisTemplate ngx-datatable-cell-template let-row="row">
      <span>
        {{ row?.position?.cost_basis | ripCurrencyPipe }}
      </span>
    </ng-template>

    <ng-template #marketValueTemplate ngx-datatable-cell-template let-row="row">
      <span>
        {{ row?.position?.value | ripCurrencyPipe }}
      </span>
    </ng-template>

    <ng-template #accountTemplate ngx-datatable-cell-template let-value="value">
      <span>
        <!-- value is an account-->
        {{ value?.formattedDescription }}
      </span>
    </ng-template>
  `,
  styleUrls: [ `./mobileLayoutComponents.scss`, `../mobileLayout.scss` ],
  encapsulation: ViewEncapsulation.None,
} )

export class HoldingDetailComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'gainLossTemplate', { static: false } ) gainLossTemplate: TemplateRef<any>;
  @ViewChild( 'costBasisTemplate', { static: false } ) costBasisTemplate: TemplateRef<any>;
  @ViewChild( 'marketValueTemplate', { static: false } ) marketValueTemplate: TemplateRef<any>;
  @ViewChild( 'accountTemplate', { static: false } ) accountTemplate: TemplateRef<any>;

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faQuestionCircle = faQuestionCircle;
  faUniversity = faUniversity;

  @Input() holding: Holding;

  truncationNumber: number = 30;

  screenWidth: number = 300;

  rows: any[] = [];

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();

  columns: any[] = [];

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _bottomSheet: MatBottomSheet ) {
    this.screenWidth = window.innerWidth;
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_mobileHoldingsDetails' ] = this;
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.rows = this.holding.positions;
      const maxWidth = this.screenWidth / 4;

      this.columns = [
        {
          name: 'Account',
          prop: 'account',
          cellTemplate: this.accountTemplate,
          cellClass: 'mobile-table-cell-class',
          maxWidth,
        },
        {
          name: 'Value',
          longName: 'Market Value',
          prop: 'value',
          cellTemplate: this.marketValueTemplate,
          cellClass: 'mobile-table-cell-class',
          maxWidth,
        },
        {
          name: 'Cost Basis',
          longName: 'Cost Basis',
          prop: 'cost_basis',
          cellTemplate: this.costBasisTemplate,
          cellClass: 'mobile-table-cell-class',
          maxWidth: maxWidth * 0.8,
        },
        {
          name: 'Gain/Loss',
          prop: 'gain_loss',
          cellTemplate: this.gainLossTemplate,
          cellClass: 'mobile-table-cell-class',
          maxWidth: maxWidth * 1.2,
        },
      ];
    } );
  }

  ngOnDestroy(): void {
    delete window[ 'mobileHoldingsDetails' ];
  }

  rowClicked( event: any ) {
    // event.row.position.account_id
    const account = this._accountManager.getAllOriginalAccounts().find( ( a: Account ) => {
      return a.account_id === event.row.position.account_id;
    } );
    if ( account ) {
      this._bottomSheet.dismiss();
      this._bottomSheet.open( MobileAccountDetailsComponent, { data: { account } } );
      // this._navigator.element.pushPage( MobileAccountDetailsComponent, { animation: 'lift', data: { account } } );
    } // shouldn't need an else
  }

}
