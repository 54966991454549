import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../utils/util.service';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { GlobalState } from '../global.state';
import { Auth } from '../auth.service';
import { UsersUtil } from '../utils/users.util';


@Injectable()
export class MarketDataService {

  constructor( private _http: HttpClient, private _state: GlobalState, private _auth: Auth ) {
  }

  baseUrl = environment.ripsawAPIBaseUrl;

  getTreasuryRates( date?: Date ) {

    const numberOfObservations = this._state.globalVars.numberOfObservations ?? '60';

    let url = `${ this.baseUrl }/marketDataJS/treasuryRates?observations=${ numberOfObservations }`;

    if ( date ) {
      url = `${ url }&date=${ formatDate( date, 'yyyy-MM-dd', 'en-US' ) }`;
    }

    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

/*  getRiskReturn( identifier: string, type: string ) {
    const url = `${ this.baseUrl }/marketDataJS/riskReturn?identifier=${ identifier }&type=${ type }`;

    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }*/

  /**
   *
   * @param securities - array of security objects
   * @param type - 'stocks' - when all funds are stocks | 'bond' - when all funds are bonds | 'mixed' - when funds are a mix of stock and bond
   */
  getRiskReturns( securities: any[], type: string ) {

    const prefs = {};
    if ( this._auth.authenticated() ) {
      const userPrefs = UsersUtil.checkPreferences( this._auth, 'marketInfo' );

      Object.keys( userPrefs?.marketInfo ).forEach( ( key ) => {
        prefs[key] = userPrefs?.marketInfo?.[key]?.value;
      } );
    }
    const url = `${ this.baseUrl }/marketDataJS/riskReturns?type=${ type }`;

    return this._http.post( url, { securities, prefs } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );

  }

  /**
   *
   * @param securities - array of security objects
   * @param bondSecurities [Optional] - only used when type === 'mixed'
   // * @param cashIdentifier - [Optional] - identifier to label the cash component
   */
  getBenchmarkRiskReturns( securities: any[], bondSecurities: any[] ) { // }, cashIdentifier: string) {
    const numberOfObservations = this._state.globalVars.numberOfObservations ?? '60';

    const url = `${ this.baseUrl }/marketDataJS/benchmarkRiskReturns?observations=${ numberOfObservations }`; // &cashIdentifier=${ cashIdentifier }`;

    return this._http.post( url, { securities, bondSecurities } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getHistoricalVix() {

    const numberOfObservations = this._state.globalVars.numberOfObservations ?? '60';
    const url = `${ this.baseUrl }/historical/daily?identifier=VIX&exchange=INDX&computeStatistics=true&observations=${ numberOfObservations }`;

    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }
}
