import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

// declaration so Typescript knows about the TradingView object loaded in from index.html
declare const TradingView: any;

@Component( {
  selector: 'rip-trading-view-symbol-overview',
  template: `
    <div #containerDiv id="overview_{{widgetId}}" class="tradingview-widget-container"></div>
    <div class="tradingview-widget-copyright">
      
<!--        <span class="blue-text">{{ brandingLabel }}</span>-->
       by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
    </div>
  `,
} )

export class TradingViewSymbolOverviewComponent implements AfterViewInit {

  chart: any;
  // allows for loading with any symbol and description
  @Input() ticker: string = '';
  @Input() tickers: string[][];
  @Input() description: string = '';
  @Input() height: number = 300;
  @Input() initChartRightAway: boolean = false;
  @Input() width: string = '100%';
  @Input() brandingLabel: string = 'Market Data';
  // id for being able to check for errors using postMessage
  widgetId: string = '';

  // wanted to be able to hide the widget if the symbol passed in was invalid (don't love their sad cloud face)
  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  constructor() {
    this.widgetId = `${ Math.floor( Math.random() * 10000 ) }`;
  }

  ngAfterViewInit() {
    // need to do this in AfterViewInit because of the Input
    setTimeout( () => {
      if ( this.initChartRightAway ) {
        this.initChart();
      }
    } );
  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
   /* if ( !this.tickers ) {
      const path = `markets/symbols/${ this.ticker }`;
      url = `${ url }${ path }`;
    }*/
    Util.openExternalUrl( url );
  }

  initChart() {
    if ( this.containerDiv ) {
      const dimension = this.height - 10;

      // postMessage listener for handling errors
      if ( window.addEventListener ) {
        window.addEventListener( 'message', ( e: any ) => {
            if ( e && e.data ) {
              // console.log( e );
              const payload = e.data;
              if (
                // if the frameElementId is from this component, the symbol was no good and we should hide the widget
                payload.name === 'tv-widget-no-data' && payload.frameElementId === this.widgetId ) {
                // console.log( 'No data available for the symbol profile widget' );
                this.containerDiv.nativeElement.style.display = 'none';
              }
            }
          },
          false,
        );
      }

      let symbol = this.ticker;
      if ( symbol.includes( 'MANUALSTOCKOPTION_' ) ) {
        symbol = this.ticker.split( '_' )[1];
      }

      symbol = `${ this.ticker.split( '-' ).join( '' ) }`;

      const symbols = this.tickers ? this.tickers : [
        [
          symbol,

        ],

        // could load more symbols, but we just needed the one at a time for now
        // [
        //   'Google',
        //   'GOOGL',
        // ],
        // [
        //   'Microsoft',
        //   'MSFT',
        // ],
      ];

      this.chart = new TradingView.MediumWidget( {
        colorTheme: GlobalState.tradingViewColorTheme,
        container_id: `overview_${ this.widgetId }`,
        symbols,
        id: this.widgetId,
        chartOnly: false,
        width: this.width,
        height: this.height,
        autosize: true,
        locale: 'en',
        showVolume: false,
        gridLineColor: '#F0F3FA',
        trendLineColor: '#1b66ae',
        fontColor: '#787B86',
        underLineColor: 'rgba(145,196,242,0.35)',
        isTransparent: false,
        valuesTracking: 2,
      } );
    } else {
      setTimeout( () => {
        this.initChart();
      }, 1000 );
    }
  }

}

