import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { GlobalState } from '../../../global.state';
import { AccountManager } from '../../../utils/accountManager';
import { environment } from '../../../../environments/environment';
import { Auth } from '../../../auth.service';
import { Util } from '../../../utils/util.service';
import { takeUntil } from 'rxjs/operators';
import { GlobalDataService } from '../../../globalData';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { ProfileService } from '../../profile/profile.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Logger } from '../../../utils/logger.service';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../shared/workspace-permission';
import { GenericError, RipsawAPIGenericResponse } from '../../../utils/dataInterfaces';
import { AppStoreService } from '../../../store';
import { WealthFluentMessageService } from '../../../theme/services';
import { CrossAppMessageType } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-fast-link',
  template: `
    <div *ngIf="this.userCanAccessFastLink; else noPermTemplate">
      <ng-container *ngTemplateOutlet="fastLinkTemplate"></ng-container>

      <ng-template #fastLinkTemplate>
        <div id="registration-message" class="registration-message" *ngIf="registering">
          <div *ngIf="showRegistrationError">
            <p>{{ registrationError }}</p>
            <p>
              <button mat-button (click)="registerUserWithYodlee()">Try Again</button>
            </p>
          </div>
          <div *ngIf="!showRegistrationError">
            <p *ngIf="!registrationComplete">
              Registering With Our Aggregator, <a href="https://yodlee.com" target="_blank">
              <img src="{{yodleeLogoUrl}}" alt="Envestnet Yodlee" style="max-height: 50px; width: auto;"/>
            </a>...
            </p>
            <p *ngIf="registrationComplete">
              Registration Complete!
            </p>
          </div>
        </div>
        <div id="initializing-message" class="initializing-fast-link-message" *ngIf="initializingFastLink">
          <div style="margin: 10px;">Initializing Yodlee FastLink...</div>
          <div style="margin: 10px;">
            <img src="{{yodleeLogoUrl}}" alt="Envestnet Yodlee" style="max-height: 50px; width: auto;"/>
            + <img src="{{ripsawLogoUrl}}" alt="Envestnet Yodlee" style="max-height: 40px; width: auto;"/>
          </div>
        </div>
        <div id="container-fastlink" class="container-fastlink" #fastlinkContainer>
          <div style="text-align: center;"></div>
        </div>
      </ng-template>
    </div>
    <ng-template #noPermTemplate>
      <h3 mat-dialog-title>Resolving Connection Issues</h3>
      <mat-divider class="modal-header-divider"></mat-divider>
      <div mat-dialog-content>
        <div>
          To maintain security, resolving connection issues is the responsibility of the workspace owner. Please let
          them know to repair the connection if needed.
        </div>
      </div>
      <!--  <div mat-dialog-actions>
            <span style="flex-grow: 2;">
              <button mat-button type="button" (click)="close()" class="close-fastlink-button">
                Close
              </button>
            </span>
        </div>-->

    </ng-template>
  `,
  styleUrls: [ './fastLink.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
} )

export class FastLinkComponent extends WithWorkspacePermission implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();
  private refresher$$ = new BehaviorSubject<void>( null );

  private wfMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  @ViewChild( 'fastlinkContainer' ) fastlinkContainer: ElementRef;


  @Input() restartOnClose: boolean = false;

  fastLink;

  initializingFastLink: boolean = true;
  registering: boolean;
  registrationComplete: boolean;
  showRegistrationError: boolean;
  registrationError: string;
  loggedInUser: any;

  yodleeLogoUrl: string = '';
  ripsawLogoUrl: string = '';

  deviceIsMobile: boolean = false;

  fastlinkLoadSuccesses: number = 0;
  userCanAccessFastLink: boolean = true;
  institution_id_for_fixing_connection: string;

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _cd: ChangeDetectorRef,
               private _gdService: GlobalDataService,
               private _profileService: ProfileService,
               private _detectorService: DeviceDetectorService,
               public dialogRef: MatDialogRef<FastLinkComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private appStoreService: AppStoreService,
               private _auth: Auth ) {
    super();
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_fastLinkComponent' ] = this;
    }

    const self = this;
    const onMessage = ( cb ) => {
      if ( window.addEventListener ) {
        window.addEventListener( 'message', ( e ) => {
            cb( e );
          },
          false,
        );
      }
    };
    const callbackFun = ( e ) => {
      if ( e && e.data && e.data.height ) {
        // Logger.log( e.data );
        self.updateHeight( e.data.height );
      }

      // Logger.log( e );
      if ( e?.data?.type === 'OPEN_EXTERNAL_URL' ) {

        const url = `${ e.data.data.url }`;
        Util.openExternalUrl( url );
      }

    };

    /*    const resizeFloater = ( resizeData ) => {
     $( 'iframe' ).attr( 'height', resizeData.height );
     };*/
    onMessage( callbackFun );


    this.yodleeLogoUrl = environment.common.yodleeLogoUrl;
    this.ripsawLogoUrl = environment.common.blueLogoUrl;

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.setupFastLink();
      this.applyWorkspacePermissions();
    } );
  }

  ngOnDestroy(): void {
    this.close();
    this.onDestroy.next();
  }

  close() {
    this._cd.detach();
    try {
      //  fastLink.open === true just means that the script is there and has an open function not that the iframe is there
      if ( this.fastlinkIsOpen() && this.fastlinkLoadSuccesses > 0 ) {
        this.fastLink?.close();
      }

      if ( !this.restartOnClose ) {
        this.dialogRef?.close();
      }
    } catch ( e ) {
      // if an error is thrown here because there is no dialogRef or fastlink was already closed, ignore it so it doesn't break everything
      Logger.log( e );
    }
    this._cd.markForCheck();
    this._cd.reattach();

  }

  fastlinkIsOpen() {
    return this.fastlinkContainer?.nativeElement?.children?.length > 1;
  }

  async init( institution_id?: any ) {
    if ( this.fastlinkIsOpen() ) {
      // fastlink already open
      if ( institution_id && !this.institution_id_for_fixing_connection ) {
        await this.fastLink.close();
        await this.init( institution_id );
        return;
      } else {
        return;
      }
    }
    this.institution_id_for_fixing_connection = institution_id;
    this._cd.detach();
    this.loggedInUser = Util.getLoggedInUser( this._auth );
    if ( this.loggedInUser.yodlee_user_id ) {
      if ( !this._state.globalVars.aggUserToken || this._state.globalVars.aggUserToken.expires < ( Date.now() / 1000 ) ) {
        // if there is no userToken yet, then await one and set it before trying to open fastlink
        const aggUserTokenResponse: RipsawAPIGenericResponse = await firstValueFrom( this._gdService.getAggUserToken()
          .pipe( takeUntil( this.onDestroy ) ) );
        this._state.globalVars.aggUserToken = aggUserTokenResponse.data;
      }
      // user already has been registered with yodlee and we have their yodlee user id so we can just open fastlink
      this.openFastLink( institution_id );
    } else {
      // if the user doesn't have a yodlee id, then we should try to register them.
      // if registration fails because there is already a user for them, then we should get the user's yodlee details
      // and update the user with the yodlee if so we don't do it again and maybe update the yodlee user with the user's info

      try {
        // if we're here, then the get user details endpoint didn't return anything because there is no user. let's create one
        this.registering = true;
        await this.registerUserWithYodlee();
      } catch ( err ) {
        console.error( err );
        // so registration failed, probably because the user is already registered, so we need to get the user token
        // and then get their details and update the user record with their yodlee id
        const aggUserTokenResponse: RipsawAPIGenericResponse = await firstValueFrom( this._gdService.getAggUserToken()
          .pipe( takeUntil( this.onDestroy ) ) );
        this._state.globalVars.aggUserToken = aggUserTokenResponse.data;

        const userDetails: any = await this.getYodleeUserDetails();
        this.updateUserWithYodleeId( userDetails.id );
      } finally {
        setTimeout( () => {
          this.initializingFastLink = false;
          this.registrationComplete = true;
          this.registering = false;
          this.openFastLink();
          this._cd.markForCheck();
          this._cd.reattach();
        } );
      }

    }
    this._cd.markForCheck();
    this._cd.reattach();
  }

  setupFastLink() {
    if ( ( <any> window ).fastlink ) {
      this.fastLink = ( <any> window ).fastlink;
    } else {
      Logger.log( 'fastlink is not loaded yet. Will try again when the modal is opened' );
    }
  }

  openFastLink( institution_id?: any ) {
    if ( this.userCanAccessFastLink ) {
      if ( !this.fastLink ) {
        this.setupFastLink();
      }
      const params: any = {
        configName: this._state.globalVars.inWealthFluent ? 'aggregation' : 'Aggregation', // should be lower case for fingoal
        isIFrameMounted: true,
      };

      if ( Util.isWebkitHandlerAvailable() ) {
        Logger.log( 'using params.intentUrl: ripsaw://backtoripsaw' );
        params.intentUrl = 'ripsaw://backtoripsaw';
      } else {
        Logger.log( 'using params.intentUrl: javascript: window.close();' );
        params.intentUrl = 'javascript:window.close();';
      }

      if ( institution_id ) {
        params.providerAccountId = institution_id;
        params.flow = 'refresh';
      }


      const fastLinkObject: any = {
        fastLinkURL: this._state.globalVars.inWealthFluent ? environment.finGoalFastLinkUrl : environment.yodleeFastLinkUrl,
        accessToken: `Bearer ${ this._state.globalVars.aggUserToken.token }`,
        params,
        forceIframe: true, // this forces the fastlink widget into an embedded iFrame (their default for mobile is a new tab/window for some dumb
                           // reason)
        onSuccess: ( data: { providerAccountId: number } ): void => {
          Logger.log( `CONNECTION SUCCEEDED` );

          this._gdService.addAggConnectionToWorkspace( data.providerAccountId )
            .pipe( takeUntil( this.onDestroy ) )
            .subscribe( async () => {
              this._accountManager.setNewlyAddedConnection( data.providerAccountId );
              // TODO: this shouldn't need to get all accounts again, just pull this connection and insert it in, then call recalc
              await this._accountManager.getAccounts(); // for now we need to get all the accounts because we don't have the new accounts yet at
                                                        // all.
              this.wfMessageService.messageWealthFluent( {
                type: CrossAppMessageType.refreshAccounts,
                message: 'Refresh Accounts Now',
                data: {},
              } );
              this._accountManager.addConnectionToAccountMap( String( data.providerAccountId ) );
            } );

        },
        onError: async ( data: { message: string | string[] } ): Promise<void> => {
          // Logger.log( 'ERROR CONNECTING' );
          if ( data?.message?.includes( 'FastLink already' ) ) {
            // tried to open fastlink even though there is already an open instance
            if ( this.institution_id_for_fixing_connection ) {
              await this.fastLink.close();
              await this.init( this.institution_id_for_fixing_connection );
            }
          } else {
            Logger.log( data );
            // this._accountManager.getAccounts();
          }
        },
        onClose: async ( /*data*/ ): Promise<void> => {
          // Logger.log( 'FASTLINK CLOSED' );
          // Logger.log( data );
          if ( this.deviceIsMobile ) {
            this._state.notifyDataChanged( 'close.mobile.fastlink', {} );
          } else {
            if ( this.restartOnClose ) {
              await this.init();
            } else if ( this.dialogRef ) {
              this.dialogRef.close();
            }
          }
        },
        onEvent: async ( data: { status: string; fnToCall: string } ): Promise<void> => {
          // Logger.log( 'FASTLINK EVENT' );
          // Logger.log( data );
          // Logger.log( 'fastlink event done' );
          if ( data?.status && data?.status === 'FAILED' ) {
            await this._accountManager.getAccounts();
          }

          if ( data?.fnToCall === 'renewClientSession' ) {
            this.fastlinkLoadSuccesses++;
            this._cd.detectChanges();
          }
          // this.updateHeight();
        },
      };
      try {
        this.fastLink.open( fastLinkObject, 'container-fastlink' );
        this.initializingFastLink = false;
      } catch ( e ) {
        Logger.log( e );
      }
    } else {
      this._cd.detectChanges();
    }
  }


  async registerUserWithYodlee() {
    this.showRegistrationError = false;
    const user = this.loggedInUser;
    const yodleeUser = {
      email: user.email,
      name: this.breakUpUserName( user.name ),
      address: {
        address1: user.address_line1 || '',
        state: user.address_state || '',
        city: user.address_city || '',
        zip: user.address_zip || '',
        country: 'US', // will change this when we go international
      },
    };
    try {
      const aggRegistrationResp: RipsawAPIGenericResponse = await firstValueFrom(
        this._gdService.registerUserWithAggregator( yodleeUser ).pipe( takeUntil( this.onDestroy ) ) );
      Logger.log( 'INFO: Yodlee registration completed. Updating User' );
      user.yodlee_user_id = aggRegistrationResp.data.id;
    } catch ( err ) {
      console.error( err );
      const errString = err.err ?? err.message ?? err.msg ?? err;
      this.registering = false;
      if ( errString.includes( 'already exists' ) ) {
        throw new Error( 'User already registered. Need to just get a token for them' );
        // the catch in the parent function should handle getting the token and details and updating the user with their yodlee_user_id
      }
    }

    const userUpdateResp: RipsawAPIGenericResponse = await firstValueFrom( this._profileService.updateUser( user ).pipe( takeUntil( this.onDestroy ) ) );
    const data = userUpdateResp.data;
    if ( data.token ) {
      // set session
      this._auth.setNewToken( data.token );
      const tokenResponse: RipsawAPIGenericResponse = await firstValueFrom( this._gdService.getAggUserToken()
        .pipe( takeUntil( this.onDestroy ) ) );

      // this will have a userToken too, which we'll need to get yodlee data after redirecting to the balance sheet
      this._state.globalVars.aggUserToken = tokenResponse.data;
    } else {
      this.showAggregatorRegistrationError( { err: 'No token came back from the api' } );
    }

  }

  updateUserWithYodleeId( id: number ) {
    this.loggedInUser.yodlee_user_id = id;
    this._profileService.updateUser( this.loggedInUser )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( /*resp: any*/ ) => {
          Logger.log( 'updated user with yodlee user id' );
        },
        error: ( err ) => {
          console.warn( err );
          console.warn( 'Could not update the user. will try again next time fastlink is opened' );
        },
      } );
  }

  /* Function that gets the user details from the aggregator service
   * returns a promise that resolves if data come back from the service, and rejects if no data is returned */
  async getYodleeUserDetails() {
    try {
      const resp: RipsawAPIGenericResponse = await firstValueFrom( this._profileService.getAggregatorUserDetails()
        .pipe( takeUntil( this.onDestroy ) ) );
      return resp.data;
    } catch ( err ) {
      this.showAggregatorGetUserDetailsError( err );
    }

  }

  breakUpUserName( name: string ) {
    const nameArray = name.split( ' ' );
    return {
      first: nameArray[ 0 ],
      last: nameArray[ nameArray.length - 1 ],
    };
  }

  /*
   * Function for opening alert modal for aggregator registration error
   */
  showAggregatorRegistrationError( err: GenericError ) {
    console.error( err.err );
    this.showRegistrationError = true;
    this.registrationError = `We had a problem registering you with our aggregator. ${ Util.getRefCodeSupportString( err.refCode ) }`;
    this._cd.markForCheck();
    this._cd.reattach();
  }

  showAggregatorGetUserDetailsError( err: GenericError ) {
    console.error( err.err );
    this.showRegistrationError = true;
    this.registrationError = `We had a problem retrieving your user account from our aggregator. ${ Util.getRefCodeSupportString( err.refCode ) }`;
    this._cd.markForCheck();
    this._cd.reattach();
  }

  lastTwoHeights: { last: number; secondToLast: number } = { last: 0, secondToLast: 0 };
  lastHeight: boolean = true;

  updateHeight( height: number ) {
    // this nonsense is for some weird safari issue that caused fastlink to keep resizing itself
    if ( height === this.lastTwoHeights.last || height === this.lastTwoHeights.secondToLast ) {
      return;
    } else {
      if ( this.lastHeight ) {
        this.lastTwoHeights.last = height;
        this.lastHeight = false;
      } else {
        this.lastTwoHeights.secondToLast = height;
        this.lastHeight = true;
      }
    }
    height = height - 6; // the height of the iframe - its main-container div (to remove the extra gray box)
    const iframe = document.getElementsByTagName( 'iframe' )[ 0 ];
    let currentStyle = this.removeExistingMinHeight( iframe.getAttribute( 'style' ) );
    if ( currentStyle[ currentStyle.length - 1 ] !== ';' ) {
      currentStyle += ';';
    }
    iframe.setAttribute( 'style', `${ currentStyle } min-height: ${ height }px !important` );
    // Logger.log( `height: ${ height }` );
  }

  removeExistingMinHeight( style: string ) {
    const styles = style.split( ';' );
    const stylesWithoutMinHeight = [];
    for ( const s of styles ) {
      if ( !s.includes( 'min-height' ) ) {
        stylesWithoutMinHeight.push( s );
      }
    }
    return stylesWithoutMinHeight.join( ';' );
  }

  private applyWorkspacePermissions(): void {
    combineLatest( [
      this.appStoreService.loadedWorkspacePermissions$,
      this.refresher$$.asObservable(),
    ] )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( async ( [ allPerms ] ) => {
        const hasPermissions = hasAnyPermissionFn( allPerms, [ this.perm.OWNER ] );
        if ( hasPermissions ) {
          this.userCanAccessFastLink = true;
          await this.init( this.data.institutionId );
        } else {
          this.userCanAccessFastLink = false;
          this._cd.detectChanges();
        }
      } );
  }

}
