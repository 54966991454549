import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';
import { RipsawCurrencyPipe } from '../../theme/pipes';

@Component( {
  selector: 'rip-month-or-year-currency-inputs',
  template: `

    <div class="flexing-row-only">
      <rip-currency-input [control]="monthlyFormControl"
                          [stepSize]="annualStepSize / 10"
                          [label]="monthlyLabel"
                          [placeholder]="monthlyPlaceholder"
                          [noNegative]="true"
                          (dataChange)="monthlyNumberUpdated()" style="flex: 1;"></rip-currency-input>

      <rip-currency-input *ngIf="showAnnualInput"
                          [control]="annualFormControl"
                          [stepSize]="annualStepSize"
                          [label]="annualLabel"
                          [placeholder]="annualPlaceholder"
                          [noNegative]="true"
                          (dataChange)="annualNumberUpdated()"
                          style="flex: 1; margin-left: 15px;"></rip-currency-input>
    </div>`,
  styleUrls: [],
} )

export class MonthOrYearCurrencyInputsComponent implements AfterViewInit {
  @Input() showAnnualInput: boolean = true;
  @Input() annualLabel: string = 'Annual Savings';
  @Input() monthlyLabel: string = 'Monthly Savings';
  @Input() annualPlaceholder: string = 'Annual Contributions/Savings';
  @Input() monthlyPlaceholder: string = 'Monthly Contributions/Savings';
  @Input() monthlyFormControl: FormControl<string>;
  @Input() annualFormControl: FormControl<string>;
  @Input() calcAnnualNumberAfterLoad: boolean = false;
  // annual step size will be divided by ten for monthly step size
  @Input() annualStepSize: number = 1000;

  @Output() numbersUpdated: EventEmitter<any> = new EventEmitter<any>();

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();

  constructor() {
  }

  ngAfterViewInit() {
    setTimeout( () => {
      if ( this.calcAnnualNumberAfterLoad ) {
        this.monthlyNumberUpdated();
      }
    } );
  }

  init( annualAmount?: number ) {
    this.annualFormControl.setValue( this.ripCurrencyPipe.transform( annualAmount ) );
    this.annualNumberUpdated();
  }

  monthlyNumberUpdated() {
    const monthlyNumber = FormsUtil.getSanitizedFloatValue( this.monthlyFormControl.value );
    this.annualFormControl.setValue( this.ripCurrencyPipe.transform( monthlyNumber * 12 ) );
    this.numbersUpdated.emit();
  }

  annualNumberUpdated() {
    const annualNumber = FormsUtil.getSanitizedFloatValue( this.annualFormControl.value );
    this.monthlyFormControl.setValue( this.ripCurrencyPipe.transform( annualNumber / 12 ) );
    this.numbersUpdated.emit();
  }
}
