import { ButtonOpts } from '../button-options.interface';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
  selector: 'rip-progress-bar-button',
  template: `
    <button mat-button type="button" tabIndex="-1"
            (click)="btnClick($event)"
            [color]="options.buttonColor"
            [class.active]="options.active"
            [class.mat-raised-button]="options.raised"
            [disabled]="options.active || options.disabled || disabled">

      <span *ngIf="options.text">{{ options.text }}</span>
      <span *ngIf="options.mat_icon"><mat-icon>{{options.mat_icon}}</mat-icon></span>
      <span *ngIf="options.fa_icon"><fa-icon [icon]="options.fa_icon" size="lg" [fixedWidth]="true"></fa-icon></span>

      <mat-progress-bar
        *ngIf="options.active"
        [color]="options.barColor"
        class="bar"
        [mode]="options.mode"
        [value]="options.value">
      </mat-progress-bar>

    </button>
  `,
  styles: [`
    button.active {
      cursor: not-allowed
    }

    .bar {
      position: absolute;
      top: 0;
      left: 0;
    }
  `],
} )
export class ProgressBarButtonComponent {
  @Input() options: ButtonOpts;
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  btnClick( e: Event ): void {
    this.onClick.emit( e );
  }
}
