<!-- DESKTOP LAYOUT -->
<div *ngIf="!deviceIsMobile">

  <mat-card class="widgetWrapper" *ngIf="!loadingAccounts || account">
    <mat-card-content>
      <div class="row no-gutters" #widgetRowDiv [ngClass]="widgetRowClass">

        <div class="col-12">
          <rip-widget-menu (toggleRE)="toggleRE()"
                           (flipTable)="flipTable()"
                           (toggleBenchmarkValues)="toggleBenchmarkValues()"
                           (minMaximize)="minimize()"
                           [showRE]="showRE"
                           [showTable]="showTable"
                           [showDiffs]="showDiffs"
                           [global]="!account"
                           [minimized]="minimized"></rip-widget-menu>
        </div>
        <div class="col col-12" id="allocTableContainer" #allocTableContainer
             [ngClass]="{ 'minimized': minimized }">

          <nb-flip-card [flipped]="!showTable" [showToggleButton]="false">
            <nb-card-front style="border-radius: 15px;">
              <nb-card style="border-radius: 15px;">
                <nb-card-body style="border-radius: 15px;">
                  <ng-template #skeleton>
                    <div class="flexing-row-only vertical-flex space-around" style="margin:3px 5px;">
                      <div class="flexing-row-only" style="padding: 5px 0 5px 5px;">
                        <p-skeleton borderRadius="15px" height="50px" width="6rem" styleClass="mr-2"
                                    *ngFor="let s of Array.from(Array(20))"></p-skeleton>
                      </div>
                      <div class="flexing-row-only" style="padding: 5px 0 5px 5px;">
                        <p-skeleton borderRadius="2px" height="50px" width="6rem" styleClass="mr-2"
                                    *ngFor="let s of Array.from(Array(20))"></p-skeleton>
                      </div>
                    </div>
                  </ng-template>
                  <div *ngIf="overrideSecurityDataLoaded; else skeleton">
                    <div *ngIf="showTable" [style.min-height]="!account ? '150px' : ''">
                      <ngx-datatable #allocWidgetTable [rows]="rows"
                                     [columns]="columns"
                                     [scrollbarH]="true"
                                     [columnMode]="'force'"
                                     [headerHeight]="tableHeaderHeight"
                                     [rowHeight]="tableRowHeight"
                                     [reorderable]="false"
                                     class="material striped"></ngx-datatable>
                    </div>
                  </div>
                </nb-card-body>
              </nb-card>
            </nb-card-front>
            <nb-card-back>
              <nb-card>
                <nb-card-body>
                  <div *ngIf="!showTable" class="allocation-charts">

                    <div id="{{chartsSpinnerSelector}}" class="spinner">
                      <div></div>
                    </div>

                    <rip-allocation-charts #allocationCharts [slides]="slides"
                                           [prevLabel]="prevLabel"
                                           [nextLabel]="nextLabel"
                                           (slideChanged)="notifyHeightChanged(); updatedIndex();">

                    </rip-allocation-charts>
                  </div>
                </nb-card-body>
              </nb-card>
            </nb-card-back>
          </nb-flip-card>
          <!--<div *ngIf="showGauges" class="allocation-gauges">
            Gauges go here
          </div>-->
        </div>

      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="container-fluid alloc-widget-spinner widgetWrapper" *ngIf="loadingAccounts && !account"
            #loadingMessageDiv>
    <mat-card-content>
      <div class="row no-gutters">
        <div class="flexing vertical-flex center-horizontally center-vertically" style="width: 100%; height: 200px;">
          <div class="spinner-label">{{ progressMessage[ progressValue ] }}</div>
          <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

</div>
<!-- END DESKTOP LAYOUT -->

<!-- MOBILE LAYOUT -->
<div *ngIf="deviceIsMobile" class="mobile-dashboard-container">
  <mat-card *ngIf="loadingAccounts && !account">
    <div class="spinner-label">{{ progressMessage[ progressValue ] }}</div>
    <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>

  </mat-card>
  <ng-template #skeleton>
    <mat-card>
      <div>
        <div class="flexing vertical-flex space-around">
          <div class="flexing-row-only space-around" style="margin:3px 10px;">
            <p-skeleton borderRadius="10px" height="60px" width="8rem" styleClass="mr-2"></p-skeleton>
          </div>
          <div class="flexing-row-only space-around" style="margin:3px 10px;">
            <p-skeleton width="12rem" height="40px" styleClass="mb-2"></p-skeleton>
          </div>
          <div style="margin:3px 10px;">
            <p-skeleton width="100%" height="2rem" styleClass="mb-2"></p-skeleton>
            <br></div>
          <div style="margin:3px 10px;">
            <p-skeleton width="100%" height="2rem" styleClass="mb-2"></p-skeleton>
          </div>

          <div class="flexing-row-only center-horizontally mt-3" style="margin:3px 10px;">
            <p-skeleton height="2rem" width="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton height="2rem" width="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton height="2rem" width="2rem" styleClass="mr-2"></p-skeleton>
            <p-skeleton height="2rem" width="6rem" styleClass="mr-2"></p-skeleton>
          </div>
          <div style="margin:3px 10px;">
            <p-skeleton width="100%" height="250px"></p-skeleton>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-template>

  <div *ngIf="overrideSecurityDataLoaded; else skeleton">
    <mat-card *ngIf="!loadingAccounts">
      <mat-card-subtitle>
        <div class="col-12">
          <div class="row">
            <div class="col-12" style="margin: auto;">
              <span class="mobile-dashboard-title-label">
                 <span style="font-weight:bold; font-size:20px;">{{ getNetWorthName() }}</span>
              </span>

            </div>

            <div class="col-12">
              (Market Value)
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="margin: 25px 0; text-align:center;">
            <span class="mobile-net-worth">
            {{ getNetWorth().dollar | ripCurrencyPipe }} </span>
            </div>


          </div>
        </div>
        <div class="col-12">
          <div class="row mobile-dashboard-header-row">
            <div class="col-5 mobile-dashboard-header-left">
              <span class="mobile-dashboard-title-label">
                Your 1-Day Return:
              </span>
            </div>
            <div class="col-1 mobile-disclaimer-container">
            <span class="disclaimer" (click)="openMobileDisclaimers( 0 )">
              <fa-icon [icon]="faQuestionCircle"></fa-icon>
            </span>
            </div>
            <div class="col-6 mobile-dashboard-header-right">
              <ng-container
                      *ngTemplateOutlet="oneDayChangeNumbersMobileTemplate; context: {one_day_change: getOneDayReturn()}">
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row mobile-dashboard-header-row">
            <div class="col-5 mobile-dashboard-header-left">
              <span class="mobile-dashboard-title-label">
                Benchmark 1-Day Return:
              </span>
            </div>
            <div class="col-1 mobile-disclaimer-container">
            <span class="disclaimer" (click)="openOneDayMobileBreakdown()">
              <fa-icon [icon]="faColumns"></fa-icon>
            </span>
            </div>
            <div class="col-6 mobile-dashboard-header-right">
              <ng-container
                      *ngTemplateOutlet="benchmarkOneDayChangeTemplate; context: {one_day_change: getBenchmarkOneDayReturn()}">
              </ng-container>
            </div>
          </div>
        </div>
        <!-- don't need to check for global cause this is only on mobile where we don't do accounts page -->
        <!--      <rip-go-to-group-menu></rip-go-to-group-menu>-->
        <rip-mobile-dashboard-menu (toggleRE)="toggleRE()"
                                   (flipTable)="openMobileCharts()"
                                   (toggleBenchmarkValues)="toggleBenchmarkValues()"
                                   [showRE]="showRE"
                                   [showTable]="showTable"
                                   [showDiffs]="showDiffs"></rip-mobile-dashboard-menu>
      </mat-card-subtitle>
    </mat-card>
    <mat-card *ngIf="!loadingAccounts">
      <mat-card-content>
        <div class="mobile-dashboard-table-container">
          <ngx-datatable #mobileAllocWidgetTable [rows]="mobileRows"
                         [columns]="mobileColumns"
                         [scrollbarH]="false"
                         [columnMode]="'force'"
                         [headerHeight]="mobileTableHeaderHeight"
                         [rowHeight]="mobileTableRowHeight"
                         [reorderable]="false"
                         class="material striped"></ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
    <rip-mobile-one-day-change *ngIf="showMobileOneDayPerf"></rip-mobile-one-day-change>
  </div>
</div>
<!-- END MOBILE LAYOUT -->

<!-- Header Templates-->

<ng-template ngx-datatable-header-template #headerTemplate let-column="column">
  <div *ngIf="column?.prop !== 'header' && column?.disclaimer === undefined">
    <a class="chart-flip-button" (click)="flipTable( column )" matTooltip="Show Chart"
       matTooltipClass="mat-tooltip-custom"
       *ngIf="shouldColHaveChartButton( column.prop )">
      <mat-icon>insert_chart</mat-icon>
    </a>
    <span [ngClass]="getColumnClass( column.prop )"
          matTooltip="{{ column.longName ? column.longName : column.name }}"
          matTooltipClass="mat-tooltip-custom">
      {{ column.name }}
    </span>
  </div>
  <div *ngIf="column?.disclaimer !== undefined || column?.definition !== undefined">
    <span *ngIf="column.prop === 'one_day_change'" class="one-day-change-breakdown-icon">
      <span>
        <a (click)="openOneDayBreakdownDialog()" class="two-columns-icon-button"
           matTooltip="One Day Change Breakdown" matTooltipClass="mat-tooltip-custom">
          <fa-icon [icon]="faColumns" [fixedWidth]="true" size="xs"></fa-icon>
        </a>
      </span>
    </span>
    <span [ngClass]="getColumnClass( column.prop )"
          matTooltip="{{ column.longName ? column.longName : column.name }}"
          matTooltipClass="mat-tooltip-custom"
          class="widget-title-with-disclaimer">
      {{ column.name }}
      <span class="disclaimer" (click)="openDisclaimerModal( column.disclaimer )"
            *ngIf="column.disclaimer !== undefined">
        <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
      </span>
      <span class="definition" (click)="openGlossaryDialog( column.definition )"
            *ngIf="column.definition !== undefined">
        <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
      </span>
    </span>
  </div>

  <div *ngIf="column.prop === 'header'" class="header-buttons">
    <!--<rip-widget-menu (toggleRE)="toggleRE()"
                     (flipTable)="flipTable()"
                     (toggleBenchmarkValues)="toggleBenchmarkValues()"
                     [showRE]="showRE"
                     [showTable]="showTable"
                     [showDiffs]="showDiffs"></rip-widget-menu>
    &lt;!&ndash; menu for selecting column sets &ndash;&gt;-->
  </div>
</ng-template>

<!-- Cell Templates -->
<!-- TOTAL EXPENSES TEMPLATE -->
<ng-template #totalExpensesTemplate let-value="value" let-title="title">
  <ng-template #dollarPopContent let-title>
    <nb-card>
      <nb-card-header class="popover-title">
        {{ title }}
      </nb-card-header>
      <nb-card-body>
        <div class="popover-content">
          <p>Account Fees: {{ value.dollar.advisorFees || 0 | ripCurrencyPipe }} </p>
          <p>Expense Ratio: {{ value.dollar.expenseRatio || 0 | ripCurrencyPipe }}</p>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button type="button" mat-button color="primary" class="popover-close"
                (click)="closePopovers($event)">
          Close
        </button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
  <ng-template #percentPopContent let-title>
    <nb-card>
      <nb-card-header class="popover-title">
        {{ title }}
      </nb-card-header>
      <nb-card-body>
        <div class="popover-content">
          <p>Account Fees: {{ value.percent.advisorFees | ripPercentPipe : '2-2' }} </p>
          <p>Expense Ratio: {{ value.percent.expenseRatio | ripPercentPipe : '2-2' }}</p>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button type="button" mat-button color="primary" class="popover-close"
                (click)="closePopovers($event)">
          Close
        </button>
      </nb-card-footer>
    </nb-card>
  </ng-template>

  <div *ngIf="value.dollar">
    <span>
      {{ value.dollar.totalExpenses | ripCurrencyPipe }}
      <fa-icon [icon]="faInfoCircle"
               class="expense-info"
               [fixedWidth]="true"
               size="sm"
               [nbPopover]="dollarPopContent"
               [nbPopoverContext]="title"
               nbPopoverClass="rip-popover">
      </fa-icon>
    </span>
    <br>
    <span>
      <span *ngIf="value.percent.color" [style.color]="value.percent.color.hex">
          <fa-icon [icon]="value.percent.color === 'red' ? faExclamationCircle : faCircle"
                   matTooltip="{{getDeviationTooltip(value.percent.color.name)}}"
                   matTooltipClass="mat-tooltip-custom"
                   matTooltipPosition="below">
            </fa-icon>
        </span>
      {{ value.percent.totalExpenses | ripPercentPipe : '2-2' }}
      <fa-icon [icon]="faInfoCircle"
               class="expense-info"
               [fixedWidth]="true"
               size="sm"
               [nbPopover]="percentPopContent"
               [nbPopoverContext]="title"
               nbPopoverClass="rip-popover">
    </fa-icon>
  </span>
  </div>
  <div *ngIf="!value.dollar">
    <span>
      {{ value.percent }}
    </span>
  </div>
</ng-template>

<!-- EXPENSES TEMPLATE-->
<ng-template #expensesTemplate ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">

  <div *ngIf="value.bm">
    <ng-container *ngTemplateOutlet="totalExpensesTemplate; context: {
    value: {
      percent: {
        totalExpenses: showDiffs ? value.percent.totalExpenses.diff : value.percent.totalExpenses.bm,
        advisorFees: showDiffs ? value.percent.advisorFees.diff : value.percent.advisorFees.bm,
        expenseRatio: showDiffs ? value.percent.expenseRatio.diff : value.percent.expenseRatio.bm,
        color: value.percent.totalExpenses.color
      },
      dollar: {
        totalExpenses: showDiffs ? value.dollar.totalExpenses.diff : value.dollar.totalExpenses.bm,
        advisorFees: showDiffs ? value.dollar.advisorFees.diff : value.dollar.advisorFees.bm,
        expenseRatio: showDiffs ? value.dollar.expenseRatio.diff : value.dollar.expenseRatio.bm
      }
    },
    title: showDiffs ? 'Difference in Fee Breakdowns' : 'Fee Breakdown'
    }"></ng-container>
  </div>
  <div *ngIf="!value.bm">
    <ng-container *ngTemplateOutlet="totalExpensesTemplate; context: {
    value: value,
    title: 'Fee Breakdown'
    }"></ng-container>
  </div>

</ng-template>

<!-- Real Asset breakdown template -->
<ng-template #realAssetsBreakdownTemplate>
  <nb-card>
    <nb-card-header class="popover-title">
      Real Assets Breakdown
    </nb-card-header>
    <nb-card-body>
      <div class="row" id="real-assets-breakdown-container">
        <div class="col-8">
          <ngx-datatable [rows]="realAssetsBreakdown.rows"
                         [columns]="realAssetBreakdownColumns"
                         [headerHeight]="50"
                         [columnMode]="'force'"
                         [rowHeight]="50"
                         class="material striped">

          </ngx-datatable>
        </div>
        <div class="col-4">
          <div style="height: 250px;">
            <canvas baseChart #realAssetsBreakdownChart="base-chart"
                    [datasets]="realAssetsBreakdown.chart.chartData"
                    [labels]="realAssetsBreakdown.chart.chartLabels"
                    [options]="realAssetChartOptions"
                    [legend]="false"
                    [type]="realAssetChartType">
            </canvas>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button type="button" mat-button color="primary" class="popover-close"
              (click)="closePopovers($event)">
        Close
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>


<!-- VERTICAL HEADER TEMPLATE -->
<ng-template #verticalHeaderTemplate ngx-datatable-cell-template let-value="value" let-row="row">
  <div *ngIf="row?.sortValue === 3" class="benchmark-label">
    {{ showDiffs ? 'Difference from ' + value : value }}<br>
  </div>
  <div *ngIf="row?.sortValue !== 3">
    {{ value }}
    <br>
    (Market Value)
  </div>
</ng-template>

<!-- CAROUSEL ITEM TEMPLATE-->
<ng-template #carouselItemTemplate let-chartObject="chartObject">
  <div class="col-12" *ngIf="showDiffs">
    <button mat-raised-button class="icon-button"
            (click)="toggleShowPercentDiffs()"
            matTooltip="Show differences as {{ showPercentDiffs ? 'dollars' : 'percentages' }}"
            matTooltipClass="mat-tooltip-custom">
      <fa-icon [icon]="showPercentDiffs ? faDollarSign : faPercent" fixedWidth="true" size="lg"></fa-icon>
    </button>
  </div>
  <div class="col-12"><!-- style="max-height: 50vh;">-->
    <div class="chart-wrapper" style="width: 100%;"
         [style.maxHeight]="deviceIsMobile ? '90vh' : '50vh'"
         [style.height.px]="chartObject.chartHeight">
      <canvas baseChart
              [datasets]="chartObject.datasets"
              [labels]="chartObject.labels"
              [options]="chartObject.options"
              [type]="chartObject.chartType">
      </canvas>
    </div>
  </div>
</ng-template>

<!-- CARET TEMPLATE -->
<ng-template #caretTemplate ngx-datatable-cell-template let-value="value" let-row="row">
  {{ value }}
  <span *ngIf="row?.dollarChange > 0">
    <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
  </span>
  <span *ngIf="row?.dollarChange < 0">
    <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
  </span>
</ng-template>

<!-- ONE DAY CHANGE NUMBERS TEMPLATE -->
<ng-template #oneDayChangeNumbersTemplate let-one_day_change="one_day_change">
  <div *ngIf="one_day_change" class="one-day-change" [ngClass]="{
          'up': one_day_change.dollar > 0,
          'down': one_day_change.dollar < 0
        }">
    <mat-grid-list cols="1" rowHeight="20px">

      <mat-grid-tile [colspan]="1" [rowspan]="1" [style.text.align]="'left'">
        <!--<span *ngIf="one_day_change.bm">
          <fa-icon [icon]="faInfoCircle" [fixedWidth]="true" size="sm"
                   (click)="oneDayBreakdownModal.open(true)"></fa-icon>
        </span>-->
        <span *ngIf="one_day_change?.dollar > 0">
          <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="one_day_change?.dollar < 0">
          <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        {{ one_day_change.dollar | ripCurrencyPipe }}
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        {{ one_day_change.percent | ripLongPercentPipe }}
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-template>

<!-- ONE DAY CHANGE TEMPLATE -->
<ng-template #oneDayChangeTemplate ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
  <div *ngIf="row.one_day_change.bm">
    <ng-container *ngTemplateOutlet="oneDayChangeNumbersTemplate; context: { one_day_change: showDiffs ?
      {
      dollar: row.one_day_change.dollar.diff,
      percent: row.one_day_change.percent.diff,
      bm: true,
      breakdown: row.one_day_change.breakdown
      } : {
      dollar: row.one_day_change.dollar.bm,
      percent: row.one_day_change.percent.bm,
      bm: true,
      breakdown: row.one_day_change.breakdown
      } }">
    </ng-container>
  </div>
  <div *ngIf="!row.one_day_change.bm">
    <ng-container *ngTemplateOutlet="oneDayChangeNumbersTemplate; context: { one_day_change: row.one_day_change }">
    </ng-container>
  </div>
</ng-template>

<!-- GENERIC TEMPLATE -->
<ng-template #genericTemplate ngx-datatable-cell-template let-value="value" let-column="column" let-row="row"
             let-rowIndex="rowIndex">
  <!-- Simple Value Cell -->
  <div *ngIf="value.value || value.value === 0">{{ value.value | ripDecimalPipe : '1.2-2' }}</div>

  <!-- Benchmark Row -->
  <ng-template #diffs let-value="value">
    <div *ngIf="value.dollar.diff || value.dollar.diff === 0; else noDollar">
      {{ value.dollar.diff | ripCurrencyPipe }}
    </div>

    <div *ngIf="value.percent.diff || value.percent.diff === 0; else noPercent">
        <span *ngIf="value.color" [style.color]="value.color.hex">
          <fa-icon [icon]="value.color === 'red' ? faExclamationCircle : faCircle"
                   matTooltip="{{getDeviationTooltip(value.color.name)}}"
                   matTooltipClass="mat-tooltip-custom"
                   matTooltipPosition="below">
            </fa-icon>
        </span>
      {{ value.percent.diff | ripPercentPipe }}
    </div>
  </ng-template>
  <ng-template #notDiffs let-value="value">
    <div *ngIf="value.dollar.bm || value.dollar.bm === 0; else noDollar">
      {{ value.dollar.bm | ripCurrencyPipe }}
    </div>

    <div *ngIf="value.percent.bm || value.percent.bm === 0; else noPercent">
      <span *ngIf="value.color" [style.color]="value.color.hex">
          <fa-icon [icon]="value.color === 'red' ? faExclamationCircle : faCircle"
                   matTooltip="{{getDeviationTooltip(value.color.name)}}"
                   matTooltipClass="mat-tooltip-custom"
                   matTooltipPosition="below"
                   class="deviation-warning">
            </fa-icon>
        </span>
      {{ value.percent.bm | ripPercentPipe }}
    </div>
  </ng-template>
  <ng-template #bm let-value="value">
    <ng-container *ngTemplateOutlet="value.showDiffs ? diffs : notDiffs; context: {value: value}"></ng-container>
  </ng-template>

  <ng-template #notBm let-value="value">
    <div *ngIf="value.dollar || value.dollar === 0; else noDollar">
      {{ value.dollar | ripCurrencyPipe }}
    </div>

    <div *ngIf="value.percent || value.percent === 0; else noPercent">
      {{ value.percent | ripPercentPipe }}
      <!-- Popover for cash. bonds, stocks not being 100% -->
      <span *ngIf="column?.prop === 'net_worth' && value?.percent < 0.99">
                <ng-template #allocationTotalWarning>
                  <nb-card>
                    <nb-card-body>
                      <div class="popover-content">
                        Cash, Bonds, Stocks allocations don't add up to 100%. Check your
                        <a (click)="openGlobalOverridesDialog()" class="legal-link">
                          data quality issues.
                        </a>
                        <button type="button" mat-button color="primary" align="end" class="popover-close"
                                (click)="closePopovers($event)">
                        Close
                      </button>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </ng-template>
                <fa-icon [icon]="faExclamationCircle" [style.color]="missingDataIndicatorColor.hex"
                         fixedWidth
                         [nbPopover]="allocationTotalWarning"
                         nbPopoverClass="rip-popover">

                </fa-icon>
          </span>
      <!-- Popover for real assets breakdown -->
      <span *ngIf="column?.prop === 'real_assets'">
            <fa-icon [icon]="faInfoCircle"
                     fixedWidth
                     class="real-assets-info-icon"
                     [nbPopover]="realAssetsBreakdownTemplate"
                     nbPopoverClass="rip-popover"
                     [nbPopoverPlacement]="bottomPlacement">

            </fa-icon>
          </span>
    </div>
  </ng-template>

  <!-- CURRENT OR REVISED ROW -->
  <ng-container *ngTemplateOutlet="!value.bm ? notBm : bm; context: {value: value}">

  </ng-container>
</ng-template>

<ng-template #oneDayChangeNumbersMobileTemplate let-one_day_change="one_day_change">
  <div *ngIf="one_day_change" class="one-day-change" [ngClass]="{
          'up': one_day_change.dollar > 0,
          'down': one_day_change.dollar < 0
        }">
    <div class="row">
      <div class="col-10 mobile-one-day-return-column-left" style="text-align: right;">
        {{ one_day_change.dollar | ripCurrencyPipe }}
      </div>
      <div class="col-1 mobile-one-day-return-column-right">
        <span *ngIf="one_day_change.dollar > 0">
          <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="one_day_change.dollar < 0">
          <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-10 mobile-one-day-return-column-left" style="text-align: right;">
        {{ one_day_change.percent | ripLongPercentPipe }}
      </div>
      <div class="col-1 mobile-one-day-return-column-right">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #benchmarkOneDayChangeTemplate ngx-datatable-cell-template let-one_day_change="one_day_change">
  <div *ngIf="one_day_change" style="width: 100%;">
    <ng-container *ngTemplateOutlet="oneDayChangeNumbersMobileTemplate; context: { one_day_change: one_day_change.showDiffs ?
      {
      dollar: one_day_change.dollar.diff,
      percent: one_day_change.percent.diff
      } : {
      dollar: one_day_change.dollar.bm,
      percent: one_day_change.percent.bm
      } }">
    </ng-container>
  </div>
</ng-template>

<ng-template #genericMobileTemplate ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">

  <ng-container
          *ngTemplateOutlet="value.dollar?.totalExpenses ? expensesTemplate : genericTemplate; context: {value: value}">
  </ng-container>

</ng-template>

<ng-template #dimensionTemplate ngx-datatable-cell-template let-value="value" let-row="row"
             let-rowIndex="rowIndex">
  {{ value.name }}
  <div *ngIf="value?.disclaimer !== undefined">
    <span [ngClass]="getColumnClass( value.prop )" class="widget-title-with-disclaimer">
      <span class="disclaimer" (click)="openMobileDisclaimers( value.disclaimer )">
        <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
      </span>
    </span>
  </div>
</ng-template>


<ng-template #noDollar>
  <div>$0.00</div>
</ng-template>
<ng-template #noPercent>
  <div>0.00%</div>
</ng-template>

<ng-template #legendTemplate ngx-datatable-cell-template let-value="value" let-row="row">
  <div style="display: flex; justify-content: left; width: 100%;">
    <fa-icon [icon]="faSquare" [style.color]="row.color" style="margin-right: 5px;"></fa-icon>
    <span>{{ value }}</span>
  </div>
</ng-template>
