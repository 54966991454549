import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BenchmarkErrorStateMatcher } from '../../../../utils/benchmark.state';
import * as _ from 'lodash-es';

@Component( {
  selector: 'app-add-benchmark-weights',
  template: `
    <h3 mat-dialog-title>Add Benchmark Sector</h3>
    <mat-divider class="modal-header-divider"></mat-divider>
    <div mat-dialog-content class="modal-body new-benchmark-weight-modal-body">
      <mat-form-field class="new-benchmark-weight-name-field">
        <label for="benchmarkWeightName"></label>
        <input matInput id="benchmarkWeightName"
               name="benchmarkWeightName"
               placeholder="Name"
               [formControl]="benchmarkWeightNameFormControl"
               [errorStateMatcher]="benchmarkMatcher">
        <mat-error *ngIf="benchmarkWeightNameFormControl.hasError('required')">
          Name is required
        </mat-error>
      </mat-form-field>
    </div>
    <mat-divider class="modal-footer-divider"></mat-divider>
    <div mat-dialog-actions>
      <button mat-button type="button" [disabled]="benchmarkWeightNameFormControl.invalid"
              (click)="addBenchmarkWeight()">
        Add
      </button>
      <button mat-button type="button" (click)="close()">Cancel</button>
    </div>
  `,
  styleUrls: [ './add-benchmark-weights.component.scss' ],
} )
export class AddBenchmarkWeightsComponent {

  benchmarkWeightNameFormControl: UntypedFormControl = new UntypedFormControl( '', Validators.required );

  benchmarkMatcher = new BenchmarkErrorStateMatcher();

  constructor( private dialogRef: MatDialogRef<AddBenchmarkWeightsComponent> ) {
  }

  close() {
    this.dialogRef.close();
  }

  addBenchmarkWeight() {
    const newWeight = {
      allocation: 0,
      proxy: '',
      label: this.benchmarkWeightNameFormControl.value,
      key: _.camelCase( this.benchmarkWeightNameFormControl.value ),
    };
    this.dialogRef.close( newWeight );
  }

}
