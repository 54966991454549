import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Util } from '../../../../utils/util.service';
import { Definition } from '../glossary.component';
import { Logger } from '../../../../utils/logger.service';

@Component( {
  selector: 'rip-definition',
  template: `
    <h4 matLine>{{ definition.title }}</h4>
    <p #defPara matLine [ngClass]="{'highlighted': highlight }"
       [innerHTML]="definition.def"></p>
  `,
} )

export class DefinitionComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'defPara', { static: false } ) defPara: ElementRef;

  @Input() definition: Definition;
  @Input() highlight: boolean;

  private removeListener: () => void;

  openExternal( url: string ) {
    Util.openExternalUrl( url );
  }

  constructor( private vcRef: ViewContainerRef, private renderer: Renderer2 ) {
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      if ( this.definition.def.includes( '<a' ) && Util.isWebkitHandlerAvailable() ) {
        this.removeListener = this.renderer.listen( this.defPara.nativeElement, 'click', ( event: any ) => {
          Logger.log( event );
          if ( event.target && event.target.href ) {
            this.openExternal( event.target.href );
          }
        } );
      }
    } );
  }

  ngOnDestroy(): void {
    if ( this.removeListener ) {
      this.removeListener();
    }
  }
}
