import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BondHelpers } from '../../../../../utils/bondHelpers';
import { Util } from '../../../../../utils/util.service';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../../../../../theme/pipes';
import * as _ from 'lodash-es';
import { AccountManager } from '../../../../../utils/accountManager';
import moment from 'moment';
import { GlobalState } from '../../../../../global.state';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ManualAccountFormsHelper } from '../../manualAccountFormsHelper';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { MobileUtil } from '../../../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faHandHoldingUsd, faFileCertificate, faDollarSign } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';


@Component( {
  selector: 'rip-bond-form',
  templateUrl: './bondForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )
export class BondFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() label: string;
  @Input() matcher;
  @Input() type;
  @Input() account;
  @Input() amortizationType = 'interest-only';
  @Input() addingInRevision: boolean = false;

  @ViewChild( 'selectedLoanTermUnits', { static: false } ) selectedLoanTermUnits: MatButtonToggleGroup;

// font awesome icon stuff
  faFileCertificate = faFileCertificate;
  faDollarSign = faDollarSign;
  faHandHoldingUsd = faHandHoldingUsd;
  faInfoCircle = faInfoCircle;


  zillowRates;
  publicUtil: typeof Util;

  allAssetAccounts: any[] = [];

  bondCouponFrequencySelectOptions: any[] = ManualAccountFormsHelper.getFrequencySelectOptions();

  deviceIsMobile: boolean = false;

  hasCorrespondingAsset: boolean;

  subscriberName: string = 'bondForm';

  constructor( private _accountManager: AccountManager,
               private _deviceDetector: DeviceDetectorService,
               private _state: GlobalState ) {
    super();
    this.zillowRates = BondHelpers.formatZillowRates( this._state.globalVars.mortgageRates );
    this.publicUtil = Util;

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _deviceDetector );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    this.form.controls.value.disable();
    // this.form.controls.treasury_rate.disable();
    // this.form.controls.modified_duration.disable();
    this.form.controls.maturity_in_years.disable();

    this.getAllAssetAccounts();

    if ( this.account && this.form ) {
      ManualAccountUtil.patchBondForm( this.form, this.account, this.selectedLoanTermUnits );

      if ( this.type !== 'Bond' ) {
        ManualAccountUtil.checkForCorrespondingAccount( this.account, this.form, this.allAssetAccounts, 'corresponding_asset_id' );
        if ( this.form.controls.corresponding_asset_id.value?.length > 0 ) {
          this.hasCorrespondingAsset = !!this._accountManager.getOriginalAccountFromId( this.form.controls.corresponding_asset_id.value );
        }
      }
    } else {
      // this is a new account
      this.selectedLoanTermUnits.value = 'years';
      this.autofocus();
    }


  }

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  maturityInYears;

  isPrivateBorrowing(): boolean {
    return ( this.type === 'Private Borrowing' );
  }

  changeSignForDebt() {
    if ( this.isPrivateBorrowing() ) {
      const price = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value, false );
      Util.setFormattedValue( 'price', 'Currency', Math.abs( price ) * -1, this.form );
    }
  }

  /*  calcTotalDiscountRate() {
      if ( this.form.controls.treasury_rate.value && this.form.controls.risk_premium.value ) {
        const treasRate = Helpers.getSanitizedFloatValue( this.form.controls.treasury_rate.value, false ); // get number in %, not decimal format
        const riskPrem = Helpers.getSanitizedFloatValue( this.form.controls.risk_premium.value, false );
        Helpers.setFormattedValue( 'current_market_rate', 'Percent', treasRate + riskPrem, this.form );
      }
    }*/

  /*
  updateProxyRiskPremium() {
    if ( this.form.controls.credit_quality.value ) {
      // to do : get this from live data source based on credit quality
      Logger.log( 'inside updateProxyRiskPremium' );
      this.form.controls.risk_premium.setValue(1.5 );
    }
  }
  */

  /* recalculateRiskPremiumFromTrsAndYtm() {
     // recalculate risk_premium as YTM - Treasury rate
     if ( this.form.controls.treasury_rate && this.form.controls.current_market_rate ) {
       const newRiskPremium =
         Helpers.getSanitizedFloatValue( this.form.controls.current_market_rate.value, false ) - Helpers.getSanitizedFloatValue( this.form.controls.treasury_rate.value, false );
       Helpers.setFormattedValue( 'risk_premium', 'Percent', newRiskPremium, this.form );
     }
   }

   updateDurationMatchedTreasuryRate() {
     if ( this.form.controls.modified_duration.value ) {
       const treasuryRate = BondHelpers.getDurationMatchedTreasuryRateInPercent( this.form.controls.modified_duration.value );
       Helpers.setFormattedValue( 'treasury_rate', 'Percent', treasuryRate, this.form );
     }
   }*/


  /*calculateYtmAndDuration() {

    if (
      this.form.controls.maturity_value.value && this.form.controls.coupon.value &&
      this.form.controls.maturity_date.value && this.form.controls.coupon_frequency.value &&
      this.form.controls.price.value ) {

      let ytmGuess;
      if ( this.form.controls.current_market_rate ) {
        ytmGuess = Helpers.getSanitizedFloatValue( this.form.controls.current_market_rate.value, true );
      } else {
        ytmGuess = 1;
      }

      let bondPrice = Helpers.getSanitizedFloatValue( this.form.controls.price.value, false );
      if ( this.isPrivateBorrowing() && bondPrice < 0 ) {
        bondPrice *= -1;
      }

      const bond: BondInterface = BondHelpers.calcBondAnalyticValuesFromPrice(
        Helpers.getSanitizedFloatValue( this.form.controls.maturity_value.value, false ),
        Helpers.getSanitizedFloatValue( this.form.controls.coupon.value, true ),
        this.form.controls.maturity_date.value,
        this.form.controls.coupon_frequency.value,
        bondPrice,
        ytmGuess );

      // set YTM and duration
      Helpers.setFormattedValue( 'current_market_rate', 'Percent', bond.yieldToMaturity * 100, this.form );
      Helpers.setFormattedValue( 'modified_duration', 'Decimal', bond.modifiedDuration, this.form );

      this.calculateTimeToMaturityInYears();

      // this.recalculateRiskPremiumFromTrsAndYtm();
      this.changeSignForDebt();
      // after calculating price also recalculate market value
      this.calculateMarketValue();
    }
  }*/

  getAllAssetAccounts() {
    this.allAssetAccounts = _.filter(
      this.addingInRevision ? this._accountManager.getAllRevisableAccounts() : this._accountManager.getAllOriginalAccounts(),
      ( a: any ) => {
        return a.account_category.toUpperCase() !== 'LOAN';
      } ) || [];
  }

  calcPriceForInterestOnlyType() {
    if ( this.form.controls.maturity_value.value && this.form.controls.coupon.value && this.form.controls.maturity_date.value && this.form.controls.current_market_rate.value ) {
      let price = BondHelpers.calcPriceForInterestOnlyType(
        moment( this.form.controls.maturity_date.value ),
        FormsUtil.getSanitizedFloatValue( this.form.controls.maturity_value.value ),
        FormsUtil.getSanitizedFloatValue( this.form.controls.coupon.value ) / 100,
        this.form.controls.coupon_frequency.value,
        FormsUtil.getSanitizedFloatValue( this.form.controls.current_market_rate.value ) / 100 );
      if ( this.type === 'Private Borrowing' ) {
        price = Math.abs( price ) * -1;
      }
      this.form.controls.price.setValue( price );
      Util.updateInputCurrencyFormat( 'price', this.form );
      this.form.controls.value.setValue( price * this.form.controls.quantity.value );
      Util.updateInputCurrencyFormat( 'value', this.form );
    }
  }

  // will bring this back once we have the treasury rate picker
  /*
    chooseRate( rate: any ) {
      this.form.controls.current_market_rate.setValue( rate.refi.rate * 100 );
      Util.updateInputPercentFormat( 'current_market_rate', this.form, true );
      this.currentRateChanged();
    }
  */

  originationDateChanged() {
    BondHelpers.calcMaturityDateFromLoanTerm( this.form, this.selectedLoanTermUnits.value );
    this.calcPriceForInterestOnlyType();
    this.getClosestRate();
  }

  termChanged() {
    if ( this.selectedLoanTermUnits.value === 'years' ) {
      this.form.controls.loan_term_in_months.setValue( this.form.controls.loan_term.value * 12 );
    } else {
      this.form.controls.loan_term_in_months.setValue( this.form.controls.loan_term.value );
    }

    BondHelpers.calcMaturityDateFromLoanTerm( this.form, this.selectedLoanTermUnits.value );
    BondHelpers.calcOriginationDate( this.form, this.selectedLoanTermUnits.value );
    this.calcPriceForInterestOnlyType();
    this.getClosestRate();
  }

  termUnitChanged() {
    if ( this.selectedLoanTermUnits.value === 'years' ) {
      this.form.controls.loan_term.setValue( this.form.controls.loan_term.value / 12 );
    } else {
      this.form.controls.loan_term.setValue( this.form.controls.loan_term.value * 12 );
    }
    this.termChanged();
  }

  maturityDateChanged() {
    BondHelpers.calcLoanTermFromMaturityDate( this.form, this.selectedLoanTermUnits.value );
    BondHelpers.calcOriginationDate( this.form, this.selectedLoanTermUnits.value );
    // BondHelpers.calculateTimeToMaturityInYears( this.form );
    this.getClosestRate();
    this.currentRateChanged();
    this.calcPriceForInterestOnlyType();
    // TODO: calc maturity bucket and set in form
  }

  maturityValueChanged() {
    Util.updateInputCurrencyFormat( 'maturity_value', this.form );
    this.calcPriceForInterestOnlyType();
  }

  couponChanged() {
    Util.updateInputPercentFormat( 'coupon', this.form, true );
    this.getClosestRate();
    this.calcPriceForInterestOnlyType();
  }

  couponFrequencyChanged() {
    this.calcPriceForInterestOnlyType();
  }

  costBasisChanged() {
    Util.updateInputCurrencyFormat( 'cost_basis', this.form );
  }

  currentRateChanged() {
    Util.updateInputPercentFormat( 'current_market_rate', this.form, true );
    this.calcPriceForInterestOnlyType();
  }

  priceChanged() {
    Util.updateInputCurrencyFormat( 'price', this.form );
    this.changeSignForDebt();
    BondHelpers.calculateMarketValue( this.form );
    // this.calculateYtmAndDuration();
  }

  quantityChanged() {
    Util.updateInputDecimalFormat( 'quantity', this.form );
    // this.changeSignForDebt();
    BondHelpers.calculateMarketValue( this.form );
  }

  getClosestRate() {
    const closestRate = BondHelpers.getTodaysRate( this.form.controls.maturity_date.value, this._state.globalVars.mortgageRates ) * 100;
    if ( closestRate !== 0 && this.useTreasuryRates() ) {
      this.form.controls.current_market_rate.setValue( closestRate );
      this.currentRateChanged();
    } else {
      this.setCurrentRateToCoupon();
    }
  }

  setCurrentRateToCoupon() {
    if ( this.form.controls.coupon.value ) {
      this.form.controls.current_market_rate.setValue( this.form.controls.coupon.value );
      this.currentRateChanged();
    }
  }

  useTreasuryRates() {
    return false;
    // will bring this back once we have the treasury rate picker
    /*if ( this.account ) {
      const p = this.account.positions[0];
      if ( p ) {
        return p.ticker !== 'MANUALBOND';
      }
      return false;
    } else {
      return this.label !== 'Bond';
    }*/
  }

}

