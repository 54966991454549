import { createSelector } from '@ngrx/store';
import { appSelector } from '../app-selector';

const workspaceSelector = createSelector( appSelector, state => state.workspace );
export const selectLoadedWorkspace = createSelector( workspaceSelector, state => state?.loaded || null );
export const selectLoadedWorkspacePermissions = createSelector( workspaceSelector, state => state?.loadedPermissions || [] );
export const selectMyWorkspaces = createSelector( workspaceSelector, state => state?.my || [] );
export const selectSharedWorkspaces = createSelector( workspaceSelector, state => state?.shared || [] );
export const selectIsRefreshingWorkspaces = createSelector( workspaceSelector, state => state?.isRefreshing?.is );
export const selectSelectedShareeWorkspaces = createSelector( workspaceSelector, state => state?.selectedSharee );
export const selectCreatingIdsWorkspaces = createSelector( workspaceSelector, state => state?.creatingIds || [] );
export const selectLoadedWorkspaceIsPrimary = createSelector( workspaceSelector, state => state?.loaded?.isPrimary );
