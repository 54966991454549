<nb-layout>
    <nb-layout-column class="main-layout-column">

        <div *ngIf="!deviceIsMobile">
            <ng-container *ngTemplateOutlet="warningBannerTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="subscriptionBannerTemplate"></ng-container>

            <nga-ba-sidebar></nga-ba-sidebar>


            <div class="al-main">
                <div class="al-content">
                    <nga-ba-content-top></nga-ba-content-top>
                    <router-outlet></router-outlet>
                </div>
            </div>

            <rip-app-footer [authenticated]="true"></rip-app-footer>

            <nga-ba-back-top position="200"></nga-ba-back-top>


            <!-- MODALS (ones that need to be on top of everything), but only for desktop/tablet -->

        </div>

        <!-- MOBILE LAYOUT -->
        <div *ngIf="deviceIsMobile">
            <rip-nebular-mobile-layout
                    [bannerTemplate]="warningBannerTemplate || subscriptionBannerTemplate"></rip-nebular-mobile-layout>
        </div>

    </nb-layout-column>
</nb-layout>

<ng-template #warningBannerTemplate>
    <nb-alert status="danger" *ngIf="warningNotification">
        <span style="text-align: center; width: 100%;">
            {{ warningNotification.title }}
            <button mat-raised-button (click)="openNotification(warningNotification)">More Details</button>
        </span>
    </nb-alert>
</ng-template>
