import { Action, createReducer, on } from '@ngrx/store';
import { initialCurrentUserState } from './state';
import { clearCurrentUser, setCurrentUserIsFinancialAdvisor } from './actions';
import { CurrentUserStore } from './models';

export function currentUserReducerFn(state: CurrentUserStore, action: Action): CurrentUserStore {
  const reducer = createReducer(
    initialCurrentUserState,
    on(setCurrentUserIsFinancialAdvisor, ( s, { payload }): CurrentUserStore => ({...s, isFinancialAdvisor: payload})),
    on(clearCurrentUser, () => initialCurrentUserState),
  );

  return reducer(state, action);
}
