import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { GlobalState } from '../../../../global.state';
import { AlertModalService } from '../../../alertModal';
import { environment } from '../../../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../utils/mobileUtil.service';
import { MatDialog } from '@angular/material/dialog';
import { ColumnSelectorComponent } from '../../../../pages/modals/columnSelector';
import { EVENT_NAMES } from '../../../../utils/enums';


@Component( {
  selector: 'rip-column-selector-menu',
  templateUrl: './columnSelectorMenu.component.html',
  styleUrls: [ './columnSelectorMenu.scss', '../widgetMenu/widgetMenu.scss' ],
} )

export class ColumnSelectorMenuComponent implements AfterViewInit, OnDestroy {

  infoType: string;
  columnSets: any;
  appName: string;

  subscriptionInactive: boolean = false;

  deviceIsDesktop: boolean = true;
  deviceIsMobile: boolean = false;

  subscriberName: string = 'columnSelectorMenuComponent';

  constructor( private _state: GlobalState,
               private _alertService: AlertModalService,
               public dialog: MatDialog,
               private _detectorService: DeviceDetectorService ) {
    this.infoType = this._state.globalVars.infoType;
    this.columnSets = [ ...this._state.globalVars.columnSets, ...this._state.globalVars.userColumnSets ];
    this.appName = environment.appName;

    this.deviceIsDesktop = MobileUtil.deviceIsDesktop( this._detectorService );
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {

      this._state.subscribe( EVENT_NAMES.CHANGE_INFO_TYPE, ( type: string ) => {
        this.changeInfoType( type );
      }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.COLUMN_SET_CREATED, () => {
        this.columnSets = [ ...this._state.globalVars.columnSets, ...this._state.globalVars.userColumnSets ];
      }, this.subscriberName );

      this.subscriptionInactive = this._state.globalVars.subscriptionInactive;
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.CHANGE_INFO_TYPE, 'columnSelectorMenuComponent' );
  }

  openColumnSelectorModal() {
    this.dialog.open( ColumnSelectorComponent, {
      width: '650px',
    } );
  }

  changeInfoType( type: string ) {
    if ( type === 'Quantitative Analysis' ) {
      const alertTitle = `Upgrade to ${ this.appName } Pro`;
      const alertMessage = `This version of ${ this.appName } does not include Quantitative Analysis.
      Upgrade to the Risk Management Tier version now to unlock the full power of ${ this.appName }! (Coming soon...)`;
      this._alertService.showAlert( { title: alertTitle, message: alertMessage } );
      return;
    }
    this._state.globalVars.infoType = type;
    this.infoType = type;
    this._state.notifyDataChanged( 'infoType.changed', type );
  }

}
