import {
  AfterViewInit,
  Component, ElementRef, EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { GlobalState } from '../../../../../global.state';
import { NewInvestmentFinderUserInterface } from '../newInvestmentFinderUserInterface';
import { Auth } from '../../../../../auth.service';
import { ProfileService } from '../../../../profile/profile.service';
import { Util } from '../../../../../utils/util.service';
import { ScreenerComponent } from '../screener/screener.component';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from '../../../../../../environments/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as _ from 'lodash-es';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalDataService } from '../../../../../globalData';
import { RipsawPercentPipe } from '../../../../../theme/pipes';
import { UnderweightExposure } from '../../../../accounts/components/optimizer/components/underweightExposures/underweightExposures.component';
import { InvestmentSelectorUtil } from '../utils/investmentSelectorUtil';
import { EVENT_NAMES } from '../../../../../utils/enums';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from '../../../../../utils/logger.service';

export interface SecDataStoredInterface {
  ticker: string;
  securityDetailRetrieved: boolean;
  securityData: any;
}

export interface ScreenerResultsFilterOptions {
  column: string;
  amount: number;
  lessOrMoreThan: string;
}

@Component( {
  selector: 'rip-new-investment-selector',
  templateUrl: './newInvestmentSelector.component.html',
} )
export class NewInvestmentSelectorComponent implements AfterViewInit, OnDestroy {

  @ViewChildren( ScreenerComponent ) screeners: QueryList<ScreenerComponent>;
  @ViewChild( 'tabGroup', { static: false } ) tabGroup: MatTabGroup;

  @ViewChild( MatAutocompleteTrigger, { static: false } ) trigger: MatAutocompleteTrigger;
  @ViewChild( 'auto', { static: false } ) matAutocomplete: MatAutocomplete;
  @ViewChild( 'fundFamilyInput', { static: false } ) fundFamilyInput: ElementRef<HTMLInputElement>;

  private readonly includeAllLabel = ' Include All ';

  selectedTab;

  finderUser: NewInvestmentFinderUserInterface;

  subscriberName: string = 'screenerComponent';

  chosenSecurities: any[];
  chosenSecurityData: SecDataStoredInterface[];

  multiSelect: boolean = true;
  forBenchmarkProxy: boolean = false;

  separatorKeysCodes: number[] = [ ENTER, COMMA ];

  index_active_options: string[] = [ 'Index', 'Active' ];
  fund_type_selection_options: string[] = [ 'ETF', 'Mutual Fund', 'Closed End Fund' ];
  form: UntypedFormGroup;
  fundFamilyControl: UntypedFormControl;

  fundFamiliesChanged: boolean = false;

  filteredFundFamilyList: Observable<string[]>;
  fundFamilyList: string[] = [];

  private _fundFamiliesSelected: string[] = [ this.includeAllLabel ];
  @Input()
  set fundFamiliesSelected( families: string[] ) {
    this._fundFamiliesSelected = families;
  }

  get fundFamiliesSelected(): string[] {
    return this._fundFamiliesSelected;
  }

  private _fundTypeOptionsSelected: any = {};
  @Input()
  set fundTypeOptionsSelected( options: any ) {
    this._fundTypeOptionsSelected = options;
    // Logger.log( `set fundTypeOptionsSelected called with ${JSON.stringify( options )}` );
    this.setFundTypeOptionFormValues( this._fundTypeOptionsSelected );
    this.screeners.forEach( ( screener: ScreenerComponent ) => {
      screener.fundTypeOptionsSelected = this._fundTypeOptionsSelected;
    } );
  }

  get fundTypeOptionsSelected(): any {
    return this._fundTypeOptionsSelected;
  }

  private _filter( value: string ): string[] {
    // Logger.log( `filtering on : ${value}` );
    const filterValue = value ? value.toLowerCase() : '';

    return this.fundFamilyList.filter( family => family.toLowerCase().startsWith( filterValue ) );
  }

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();

  filterOptions: ScreenerResultsFilterOptions;

  currentTierOptions: any;
  currentAutoSelectedSector: string;
  currentBenchmarkWeight: any;

  tabsToHide: any = {};

  constructor( private _auth: Auth,
               private _profileService: ProfileService,
               private _state: GlobalState,
               private _gdService: GlobalDataService,
               private _ngZone: NgZone ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_investmentSelector'] = { component: this, zone: _ngZone };
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.getAllFundFamilies();
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, this.subscriberName );
  }

  selectTabByIndex( index: number ) {
    this.tabGroup.selectedIndex = index;
  }

  /**
   *
   * @param sector - tab short name or empty string for first tab
   */
  selectTabBySector( sector: string ) {
    switch ( sector.toLowerCase() ) {
      case 'stocks':
        this.selectTabByIndex( 1 );
        break;
      case 'bonds':
        this.selectTabByIndex( 2 );
        break;
      case 'money market':
        this.selectTabByIndex( 3 );
        break;
      default:
        this.selectTabByIndex( 0 );
    }
  }

  startSelection( finderUser: NewInvestmentFinderUserInterface, params?: any ) {

    if ( params?.multiSelect ) {
      this.multiSelect = params.multiSelect;
    }

    this.forBenchmarkProxy = params?.forBenchmarkProxy;

    this.tabsToHide = params?.tabsToHide;

    this.finderUser = finderUser;
    this.reset();
    this.getUserPreferences();
  }

  finishSelection() {
    this.multiSelect = true;
    this.finderUser = undefined;
  }

  reset() {
    this.initializeVariables();
    this.screeners.forEach( ( s ) => {
      s.initializeVariables();
    } );
    this.finderUser.investmentFinderMessage = '';
    this.chosenSecurities = null;
    this.chosenSecurityData = null;
    this.currentTierOptions = null;
    this.currentAutoSelectedSector = undefined;
    this.currentBenchmarkWeight = null;
  }

  getUserPreferences() {
    const user = Util.getLoggedInUser( this._auth );
    if ( user.screener_preferences ) {
      try {
        const preferences = JSON.parse( user.screener_preferences );
        if ( preferences && Object.keys( preferences ).length > 0 ) {
          // need to assign a value to fundTypeOptionsSelected so the setter will update the form
          this.fundTypeOptionsSelected = Object.assign( {}, this.fundTypeOptionsSelected, preferences );
          // should only use fund families from user prefs if it actually has families selected, otherwise should be include all
          if ( !preferences.fund_families ||
            preferences.fund_families.length === 0 ||
            ( preferences.fund_families.length === 1 && preferences.fund_families[0] === '' ) ) {
            this.fundFamiliesSelected = [ this.includeAllLabel ];
          } else {
            this.fundFamiliesSelected = preferences.fund_families;
          }
        } else {
          this.setScreenerDefaults();
        }
      } catch ( e ) {
        console.error( e );
      }
    } else {
      this.setScreenerDefaults();
    }
  }

  setScreenerDefaults() {
    this.fundFamiliesSelected = [ this.includeAllLabel ];
    const prefs: any = {
      index: 1,
      active: 1,
      etf: 1,
      mutual_fund: 1,
      closed_end_fund: 1,
      maxExpenseRatio: 0.01,
    };

    this.fundTypeOptionsSelected = Object.assign( {}, prefs );
    this.setFundTypeOptionFormValues( this.fundTypeOptionsSelected );
  }


  storeUserPreferences() {
    if ( this.form && this.form.valid ) {
      const preferences: any = {};
      if ( this.fundFamiliesSelected ) {
        preferences.fund_families = this.fundFamiliesSelected;
      }
      if ( !this.fundTypeOptionsSelected ) {
        this.initializeFundOptionsSelected();
      }
      preferences.index = this.fundTypeOptionsSelected.index;
      preferences.active = this.fundTypeOptionsSelected.active;
      preferences.etf = this.fundTypeOptionsSelected.etf;
      preferences.mutual_fund = this.fundTypeOptionsSelected.mutual_fund;
      preferences.closed_end_fund = this.fundTypeOptionsSelected.closed_end_fund;
      preferences.maxExpenseRatio = this.fundTypeOptionsSelected.maxExpenseRatio;


      const user = this._auth.getTokenItem( 'user' );
      user.screener_preferences = JSON.stringify( preferences );
      this._profileService.updateUser( user )
        .subscribe( ( response: any ) => {
            // Logger.log( response );
            const responseData = response.data;
            if ( responseData.token ) {
              // set session
              this._auth.setNewToken( responseData.token );
            }
          },
          ( err ) => {
            console.error( err.err );
            Logger.log( `Error Saving Profile: ${ Util.getRefCodeSupportString( err.refCode ) }` );
          } );
    }
  }

  addNewInvestments( confirmed?: boolean ) {
    if ( this.finderUser.checkSelections && !confirmed ) {
      if ( !this.finderUser.checkSelections( this.chosenSecurityData ) ) {
        return;
      }
    }
    this.finderUser.newSecuritiesChosen( this.chosenSecurityData );
    this.chosenSecurities = null;
    this.chosenSecurityData = null;
  }

  getAllFundFamilies() {
    this.fundFamilyList = [];
    this.fundFamilyList.push( this.includeAllLabel );
    this._gdService.getFundFamilyList().subscribe( ( response: any ) => {
      if ( response && response.data && response.data.length > 0 ) {
        response.data.forEach( ( r: any ) => {
          if ( r.fund_family ) {
            this.fundFamilyList.push( r.fund_family.toString() );
          }
        } );
        this.fundFamilyList.sort( function ( a, b ) {
          return a.toLowerCase().localeCompare( b.toLowerCase() );
        } );
      }
    }, ( err ) => {
      Logger.log( err.err );
    } );
  }

  setFundFamilyOption( event: MatAutocompleteSelectedEvent ): void {
    // event.option.value is the fund family
    const family = event.option.viewValue;
    if ( family ) {
      if ( family.includes( 'Include All' ) ) {
        this.fundFamiliesSelected = [ 'Include All' ];
      } else {
        if ( this.fundFamiliesSelected.length === 1 && this.fundFamiliesSelected[0].includes( 'Include' ) ) {
          this.fundFamiliesSelected = [];
        }
        this.addFamily( family );
      }
      // this.fundFamilySelectionChange.emit( this.fundFamiliesSelected );
      this.fundFamilyInput.nativeElement.value = '';
      this.fundFamilyControl.setValue( null );
    }
  }

  addFamilyChip( event: MatChipInputEvent ): void {
    // Add family only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected event
    if ( !this.matAutocomplete.isOpen ) {
      const input = event.input;
      const value = event.value;

      // Add family
      if ( ( value || '' ).trim() ) {
        this.addFamily( value.trim() );
      }

      // Reset the input family
      if ( input ) {
        input.value = '';
      }

      this.fundFamilyControl.reset( null );
    }
  }

  resetSettings() {
    this.getUserPreferences();
    this.applySettings();
  }

  applySettings() {
    this.setNewFundTypeOptions();
    this.screeners.forEach( ( screener: ScreenerComponent ) => {
      screener.applyNewSettings();
    } );
    this.fundFamiliesChanged = false;
    this.storeUserPreferences();
  }

  setNewFundTypeOptions() {
    const options: any = {
      index: 0,
      active: 0,
      etf: 0,
      mutual_fund: 0,
      closed_end_fund: 0,
      maxExpenseRatio: 0.0025,
    };

    if ( this.form ) {
      if ( this.form.controls.index_active ) {
        const index_active_selections: any[] = this.form.controls.index_active.value;
        if ( index_active_selections.includes( 'Index' ) ) {
          options.index = 1;
        }
        if ( index_active_selections.includes( 'Active' ) ) {
          options.active = 1;
        }
      }
      if ( this.form.controls.fund_types ) {
        const fund_types_selections: any[] = this.form.controls.fund_types.value;
        if ( fund_types_selections.includes( 'ETF' ) ) {
          options.etf = 1;
        }
        if ( fund_types_selections.includes( 'Mutual Fund' ) ) {
          options.mutual_fund = 1;
        }
        if ( fund_types_selections.includes( 'Closed End Fund' ) ) {
          options.closed_end_fund = 1;
        }
      }
      if ( this.form.controls.expense_ratio ) {
        options.maxExpenseRatio = FormsUtil.getSanitizedFloatValue( this.form.controls.expense_ratio.value, true );
      }
    }
    this.fundTypeOptionsSelected = options;

    this.form.markAsPristine();

  }

  initializeVariables() {
    this.initializeForm();
    this.filteredFundFamilyList = this.fundFamilyControl.valueChanges.pipe(
      startWith( '' ),
      map( value => this._filter( value ) ) );
    this.chosenSecurities = null;
    this.chosenSecurityData = null;
    this.fundFamiliesSelected = [ this.includeAllLabel ];
    this.initializeFundOptionsSelected();
    this.filterOptions = undefined;
  }

  initializeFundOptionsSelected() {
    this.fundTypeOptionsSelected = {
      index: 0,
      active: 0,
      etf: 0,
      mutual_fund: 0,
      closed_end_fund: 0,
      maxExpenseRatio: 0.0025,
    };
  }

  securitiesSelected( selectedSecurityList ) {
    this.chosenSecurities = selectedSecurityList;
    this.chosenSecurityData = [];
    for ( const sec of this.chosenSecurities ) {
      delete sec.fund_family;
      delete sec.fund_data_loaded;
      this.chosenSecurityData.push( { ticker: sec.ticker, securityDetailRetrieved: true, securityData: sec } );
    }
    this.addNewInvestments();
    // this.getSecurityDataForTickerList();
  }

  specificSecuritySelected( sec ) {
    this.chosenSecurityData = [ { ticker: sec.ticker, securityDetailRetrieved: true, securityData: sec } ];
    this.addNewInvestments();
  }

  addFamily( fundFamily: string ) {
    if ( typeof fundFamily === 'string' ) {
      if ( !_.includes( this._fundFamiliesSelected, fundFamily ) ) {
        this._fundFamiliesSelected.push( fundFamily );
        this.fundFamiliesChanged = true;
      }
    } else {
      Logger.log( `adding non string family: ${ fundFamily }` );
    }
  }

  removeFamily( fundFamily: string ) {
    const removed = _.remove( this._fundFamiliesSelected, ( f: string ) => {
      return f === fundFamily;
    } );
    if ( removed && removed.length > 0 ) {
      this.fundFamiliesChanged = true;
    }
  }

  fundFamilyListFocused() {
    this.trigger._onChange( '' );
    this.trigger.openPanel();
  }

  fundFamilyListBlurred() {
    if ( this.fundFamiliesSelected.length === 0 ) {
      this.addFamily( this.includeAllLabel );
    }
  }

  fundFamilySelectionChange( fundFamilies ) {
    this.fundFamiliesSelected = fundFamilies;
  }

  fundTypeOptionSelectionsChange( options ) {
    this.fundTypeOptionsSelected = options;
  }

  tabChanged( event ) {
    this.selectedTab = event.tab.textLabel;
  }

  initializeForm() {
    // the form object isn't needed for money market funds
    this.form = new UntypedFormGroup( {
      index_active: new UntypedFormControl( '', Validators.required ),
      fund_types: new UntypedFormControl( '', Validators.required ),
      // add closed end funds control
      expense_ratio: new UntypedFormControl( '' ),
      fund_family: new UntypedFormControl( '' ),
    } );
    this.setFundTypeOptionFormValues( this.fundTypeOptionsSelected );
    // fund family control is needed for all fund types
    this.fundFamilyControl = new UntypedFormControl( '' );
  }

  setFundTypeOptionFormValues( options: any ) {
    if ( options && this.form ) {
      if ( this.form.controls.index_active ) {
        const index_active = [];
        if ( options.index && options.index === 1 ) {
          index_active.push( 'Index' );
        }
        if ( options.active && options.active === 1 ) {
          index_active.push( 'Active' );
        }
        this.form.controls.index_active.setValue( index_active );
      }

      if ( this.form.controls.fund_types ) {
        const fund_types = [];
        if ( options.etf && options.etf === 1 ) {
          fund_types.push( 'ETF' );
        }
        if ( options.mutual_fund && options.mutual_fund === 1 ) {
          fund_types.push( 'Mutual Fund' );
        }
        if ( options.closed_end_fund && options.closed_end_fund === 1 ) {
          fund_types.push( 'Closed End Fund' );
        }
        this.form.controls.fund_types.setValue( fund_types );
      }

      if ( this.form.controls.expense_ratio && options.maxExpenseRatio ) {
        this.form.controls.expense_ratio.setValue( this.ripPercentPipe.transform( options.maxExpenseRatio, '2-2' ) );
      }
    }
  }

  expenseRatioChanged() {
    Util.updateInputPercentFormat( 'expense_ratio', this.form );
  }

  chooseScreenerAndTileByUnderweightExposure( exposure: UnderweightExposure, exposures: UnderweightExposure[] ) {
    const prop = exposure.row.dimensionProperty;

    const sectorAndOptions = InvestmentSelectorUtil.getSectorOptions( prop, exposures );

    this.filterOptions = sectorAndOptions.filterOptions;

    this.currentTierOptions = sectorAndOptions.tierOptions;
    this.currentAutoSelectedSector = sectorAndOptions.screenerSector;

    this.selectTabBySector( sectorAndOptions.screenerSector );

    if ( this.screeners.first && this.screeners.first.mainSector === sectorAndOptions.screenerSector ) {
      this.screenerInitialized( this.screeners.first );
    }

  }

  screenerInitialized( screener: ScreenerComponent ) {
    if ( this.currentTierOptions && this.currentAutoSelectedSector === screener.mainSector ) {
      screener.setSelectorSelectionByTierOptions( this.currentTierOptions.length + 1, this.currentTierOptions );
    }
    if ( this.currentBenchmarkWeight ) {
      screener.setSectorSelectionByBenchmarkWeight( this.currentBenchmarkWeight );
    }
  }

}
