import { ChangeDetectionStrategy, Component, HostBinding, Injector, Input } from '@angular/core';
import { DialogBaseAbstract } from './dialog-base.abstract';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

export type DialogSize = 'medium';

@Component( {
  selector: 'rip-dialog-frame',
  template: `
    <div class="p-dialog-header">
     <span class="p-dialog-title">
       <ng-content select=[dialogHeader]></ng-content>
     </span>

      <button *ngIf="closeButton"
              type="button"
              pButton
              class="p-button-text p-button-plain"
              [style.width.rem]="1.625"
              icon="pi pi-times"
              (click)="close()"
      ></button>
    </div>

    <div class="p-dialog-content">
      <ng-content select=[dialogBody]></ng-content>
    </div>

    <div class="p-dialog-footer">
      <ng-content select=[dialogFooter]></ng-content>
    </div>
  `,
  styleUrls: [ './dialog-frame.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'p-dialog p-component',
  },
} )
export class DialogFrameComponent extends DialogBaseAbstract {
  private _closeButton: boolean;

  @Input()
  get closeButton(): boolean {
    return this._closeButton;
  }

  set closeButton( val: any ) {
    this._closeButton = coerceBooleanProperty( val );
  }

  static ngAcceptBooleanType_closeButton: BooleanInput;

  @Input() size: DialogSize = 'medium';

  @Input() class = '';

  @HostBinding( 'class' )
  get hostClasses(): string {
    return [
      this.class,
      this.size,
    ].join( ' ' );
  }

  constructor( protected override injector: Injector ) {
    super( injector );
  }

}
