<a *ngIf="{data: loadedWorkspace$ | async} as workspace"
   routerLink="/pages/workspaces"
   [ngClass]="{'taggable': workspace.data?.isPrimary, 'collapsed': sidebarCollapsed}"
   matTooltip="{{PAGE_NAMES.WORKSPACES}}"
   matTooltipClass="mat-tooltip-custom"
   matTooltipPosition="right"
>
  <img src="/assets/img/workspace.svg"
       alt="workspace"
       class="icon"
  >

  <ng-container *ngIf="workspace.data else noWorkspace">
    <span *ngIf="!sidebarCollapsed"
          class="name"
          [title]="workspace.data.name"
    >{{ workspace.data.name }}</span>

    <button mat-button
            disableRipple
            class="more"
            [cdkMenuTriggerFor]="menu"
            [cdkMenuPosition]="menuPosition"
            (click)="refresh($event)"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>

    <rip-tag *ngIf="!sidebarCollapsed && workspace.data.isPrimary"
             color="primary"
             weight="medium"
             class="tag"
    >Primary
    </rip-tag>

    <!--menu-->
    <ng-template #menu>
      <rip-workspace-menu [loadedWorkspace]="workspace.data"
                          [isRefreshing]="isRefreshing$ | async"
                          (loadWorkspace)="loadWorkspace($event)"
      ></rip-workspace-menu>
    </ng-template>
  </ng-container>

  <ng-template #noWorkspace>
    <span class="name">No workspaces</span>
  </ng-template>
</a>
