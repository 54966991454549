import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FastLinkComponent } from '../../../pages/modals/fastLink/fastLink.component';
import { GlobalState } from '../../../global.state';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component( {
  selector: 'rip-mobile-fastlink',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">Connect Accounts</div>
      <span>
        <button mat-raised-button (click)="closeFastLinkPage()" class="mobile-close-btn">
        Close
      </button>
      </span>
    </div>
    <div class="bottom-sheet-content">
      <div class="mobile-fastlink-container">
        <rip-fast-link #fastLinkComponent></rip-fast-link>
      </div>
    </div>
  `,
  styleUrls: [ '../mobileLayout.scss' ],
} )

export class MobileFastlinkComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'fastLinkComponent' ) fastLinkComponent: FastLinkComponent;

  institutionId: any;

  constructor( private _state: GlobalState,
               private _bottomSheetRef: MatBottomSheetRef<MobileFastlinkComponent>,
               @Inject( MAT_BOTTOM_SHEET_DATA ) public data: any ) {
    this.institutionId = data.institution_id;
    _state.subscribe( 'close.mobile.fastlink', () => {
      this.closeFastLinkPage();
    }, 'fastlinkMobileComponent' );
  }

  ngAfterViewInit(): void {
    setTimeout( async () => {
      await this.fastLinkComponent.init( this.institutionId );
    } );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'close.mobile.fastlink', 'fastlinkMobileComponent' );
  }

  closeFastLinkPage() {
    this._bottomSheetRef.dismiss();
  }

}
