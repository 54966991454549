import { Injectable } from '@angular/core';

@Injectable()
export class ServiceUtil {

  /**
   *
   * @param baseUrl (string) - url to be added to
   * @param params (any) - query params to add to the url
   * @return {string} - url with query string parameters added
   */
  static addQueryParams( baseUrl: string, params: any ): string {
    let url = baseUrl;
    if ( url[url.length - 1] !== '?' ) {
      url = `${ url }?`;
    }
    const keys = Object.keys( params );
    for ( let i = 0; i < keys.length; i++ ) {
      const paramKey = keys[i];
      const paramValue = params[paramKey];

      url = `${ url }${ paramKey }=${ paramValue }`;
      if ( i < keys.length - 1 ) {
        url = `${ url }&`;
      }

    }
    if ( url[url.length - 1] === '&' ) {
      url = url.substring( 0, url.length - 1 );
    }
    return url;
  }
}
