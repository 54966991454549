import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { HouseholdMember, User } from '../../../utils/dataInterfaces';
import { GoalsUtil } from '../../../utils/goals.util';
import { InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from '../../../utils/logger.service';
import moment from 'moment';

@Pipe( { name: 'ripCurrencyPipe', pure: true } )
export class RipsawCurrencyPipe implements PipeTransform {
  transform( value: any, hideDecimals?: boolean ) {
    if ( value === null || value === undefined || value === '' || isNaN( value ) ) {
      return ``;
    }
    return new CurrencyPipe( 'en-US' ).transform( value, 'USD', 'symbol', hideDecimals ? '1.0-0' : '1.2-2' );
  }
}

@Pipe( {
  name: 'rip-abbreviate-number',
} )
export class RipsawAbbreviatedNumberPipe implements PipeTransform {

  transform( number: number, symbol?: any ): any {
    if ( isNaN( number ) ) {
      return null;
    } // will only work value is a number
    if ( number === null ) {
      return null;
    }
    if ( number === 0 ) {
      return '$0';
    }
    let abs = Math.abs( number );
    const rounder = Math.pow( 10, 1 );
    const isNegative = number < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow( 10, 15 ) },
      { key: 'T', value: Math.pow( 10, 12 ) },
      { key: 'B', value: Math.pow( 10, 9 ) },
      { key: 'M', value: Math.pow( 10, 6 ) },
      { key: 'K', value: 1000 },
    ];

    for ( let i = 0; i < powers.length; i++ ) {
      let reduced = abs / powers[ i ].value;
      reduced = Math.round( reduced * rounder ) / rounder;
      if ( reduced >= 1 ) {
        abs = reduced;
        key = powers[ i ].key;
        break;
      }
    }
    return ( isNegative ? '-' : '' ) + ( symbol || '' ) + abs + key;
  }
}

@Pipe( { name: 'ripCreditDebitPipe', pure: true } )
export class RipsawCreditDebitPipe implements PipeTransform {
  transform( value: any, creditOrDebit: string ) {
    let val: number = Math.abs( value );
    let className = '';
    if ( creditOrDebit === 'DEBIT' ) { // debit, make negative (these should always come in as absolute values
      val *= -1;
      className = 'negative';
    } else {
      className = 'positive';
    }
    const str = new RipsawCurrencyPipe().transform( val ); // transform to currency

    return `<span class="${ className }-dollar-amount">${ str }</span>`;
  }
}

@Pipe( { name: 'ripDatePipe', pure: true } )
export class RipsawDatePipe implements PipeTransform {
  transform( value: any, ...args: any[] ) {
    if ( value === null || value === undefined ) {
      return ``;
    }
    if ( value === 0 || value === '' || isNaN( new Date( value ).getTime() ) ) {
      return 'NA';
    } else {
      return new DatePipe( 'en-US' ).transform( value, 'shortDate' );
    }
  }
}

@Pipe( { name: 'ripLongDatePipe', pure: true } )
export class RipsawLongDatePipe implements PipeTransform {
  transform( value: any, ...args: any[] ) {
    if ( value === null || value === undefined ) {
      return ``;
    }
    if ( value === 0 || value === '' || isNaN( new Date( value ).getTime() ) ) {
      return 'NA';
    } else {
      return moment( value ).format( 'MM/DD/YYYY' );
    }
  }
}

@Pipe( { name: 'ripDecimalPipe', pure: true } )
export class RipsawDecimalPipe implements PipeTransform {
  transform( value: any, decimals: string ) {
    if ( value === null || value === undefined || value === '' || isNaN( value ) ) {
      return ``;
    }
    decimals = decimals || '0-4';
    return new DecimalPipe( 'en-US' ).transform( value, `1.${ decimals }` );
  }
}

@Pipe( { name: 'ripPercentPipe', pure: true } )
export class RipsawPercentPipe implements PipeTransform {
  transform( value: any, decimals?: string ) {
    if ( value === null || value === undefined || value === '' || isNaN( value ) ) {
      return ``;
    }
    if ( decimals ) {
      return new PercentPipe( 'en-US' ).transform( value, `1.${ decimals }` );
    } else {
      let decPlaces = 3;
      if ( typeof value === 'string' ) {
        if ( value && value.indexOf( '%' ) >= 0 ) {
          value = value.replace( '%', '' );
        }
        const decimalSplit = value.split( '.' );
        if ( decimalSplit.length > 1 ) {
          const percentSplit = decimalSplit[ 1 ].split( '%' );
          decPlaces = percentSplit[ 0 ].length;
        }

      }

      return new PercentPipe( 'en-US' ).transform( value, `1.0-${ decPlaces }` );
    }
  }
}

@Pipe( { name: 'ripTwoPercentPipe', pure: true } )
export class RipsawTwoPercentPipe implements PipeTransform {
  transform( value: any ) {
    if ( value === null || value === undefined || value === '' || isNaN( value ) ) {
      return ``;
    }
    if ( typeof value === 'string' && value && value.indexOf( '%' ) >= 0 ) {
      value = value.replace( '%', '' );
    }
    return new PercentPipe( 'en-US' ).transform( value, `1.2-2` );
  }
}

@Pipe( { name: 'ripLongPercentPipe', pure: true } )
export class RipsawLongPercentPipe implements PipeTransform {
  transform( value: any ) {
    if ( value === null || value === undefined || value === '' || isNaN( value ) ) {
      return ``;
    }
    return new PercentPipe( 'en-US' ).transform( value, '1.2-4' );
  }
}

@Pipe( { name: 'ripTruncatePipe', pure: true } )
export class RipsawTruncatePipe implements PipeTransform {
  transform( value: any, length: number ): any {
    if ( value.length < length ) {
      return value;
    } else {
      return `${ value.substring( 0, length ) }...`;
    }
  }
}

@Pipe( { name: 'ripRemoveUnderscoresPipe', pure: true } )
export class RipsawRemoveUnderscoresPipe implements PipeTransform {
  transform( value: any ): any {
    return typeof value === 'string' ? value.replace( /_/g, ' ' ) : undefined;
  }
}

@Pipe( { name: 'ripGoalOwnersNames', pure: false } )
export class RipsawGoalOwnerNamesPipe implements PipeTransform {
  transform( goal: InvestorGoal, householdMembers: HouseholdMember[], user: User ): any {
    if ( !goal || !householdMembers || !user ) {
      Logger.log( 'MUST PROVIDE goal, householdMembers and user for RipsawGoalOwnerNamesPipe to return anything' );
      return '';
    }
    return GoalsUtil.getOwnerNames( goal, householdMembers, user ).join( ', ' );
  }
}

@Pipe( { name: 'ripUnHyphenatePipe', pure: true } )
export class RipsawUnHyphenatePipe implements PipeTransform {
  transform( value: any ): any {
    return typeof value === 'string' ? value.replace( /-/g, ' ' ) : undefined;
  }
}

@Pipe( {
  name: 'ripCalendarYearPipe',
} )
export class RipsawCalendarYearPipePipe implements PipeTransform {

  transform( val: number ): string {
    if ( val !== undefined && val !== null ) {
      // here we just remove the commas from value
      return val.toString().replace( /,/g, '' ).split( '.' )[ 0 ];
    } else {
      return '';
    }
  }
}

@Pipe( {
  name: 'ripDateInYearAndMonth',
} )
export class RipDateInYearAndMonthPipe implements PipeTransform {

  /**
   * val should be a number of months, so divide by 12 and floor for the integer of years and then modulo 12 for the months
   * @param val
   */
  transform( val: number ): string {
    if ( val !== undefined && val !== null ) {
      return `${ String( Math.floor( val / 12 ) ) } years ${ val % 12 } months`;
    } else {
      return '';
    }
  }
}
