<div #topBar class="top-bar scrollable-toolbar" [style.top.px]="topBarMargin">
    <div class="scrollable-toolbar-content">
        <button mat-raised-button
                class="icon-button"
                matTooltip="Show {{ showTable ? 'Chart' : 'Table' }}"
                matTooltipClass="mat-tooltip-custom"
                (click)="flip()">
            <!--    <mat-icon *ngIf="showTable">pie_chart</mat-icon>-->
            <fa-icon [icon]="faChartPieAlt" [fixedWidth]="true" *ngIf="showTable"></fa-icon>
            <mat-icon *ngIf="!showTable">table_chart</mat-icon>
        </button>
        <button mat-raised-button class="icon-button"
                (click)="expand()"
                matTooltip="Expand All"
                matTooltipClass="mat-tooltip-custom"
                *ngIf="showTable">
            <mat-icon>unfold_more</mat-icon>
        </button>
        <button mat-raised-button class="icon-button"
                (click)="collapse()"
                matTooltip="Collapse All"
                matTooltipClass="mat-tooltip-custom"
                *ngIf="showTable">
            <mat-icon>unfold_less</mat-icon>
        </button>
        <button mat-raised-button class="icon-button get-prices-button"
                (click)="refreshP()"
                matTooltip="Get Latest Prices"
                matTooltipClass="mat-tooltip-custom">
    <span class="fa-stack">
      <fa-layers [fixedWidth]="true">
        <fa-icon [icon]="faSyncAlt" [fixedWidth]="true" size="lg" [spin]="this.pricesRefreshing"
                 class="refresh-prices-icon"></fa-icon>
        <fa-icon [icon]="faDollarSign" [fixedWidth]="true" transform="shrink-6"
                 class="refresh-prices-dollar-icon"></fa-icon>
      </fa-layers>
   </span>
        </button>

        Filter Holdings:
        <button mat-raised-button
                [matMenuTriggerFor]="filterColumnsMenu"
                class="raised-menu-button">
            {{ currentColumnForFilter?.name || 'Select A Column' }}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #filterColumnsMenu="matMenu">
            <button mat-menu-item *ngFor="let column of columns" (click)="setColumn(column)">
                <span>{{ column.name }}</span>
            </button>
        </mat-menu>
        <button mat-raised-button
                [matMenuTriggerFor]="filterOperatorsMenu"
                class="raised-menu-button">
            {{ currentOperatorForFilter || 'Select An Operator' }}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #filterOperatorsMenu="matMenu">
            <button mat-menu-item *ngFor="let operator of operators" (click)="setOperator(operator)">
                <span>{{ operator }}</span>
            </button>
        </mat-menu>

        <mat-form-field id="filter-form-field">
            <input matInput [formControl]="filterFormControl" placeholder="Filter Value" (input)="filterValueChanged()">
        </mat-form-field>
        <label>{{ currentColumnForFilter?.unit || ''}}</label>


        <button mat-raised-button (click)="filterHoldings()" class="raised-menu-button"
                [disabled]="disableFilterButton">
            Filter
        </button>

        <button mat-raised-button (click)="clearFilter()" class="raised-menu-button">Clear</button>

        <button mat-raised-button #filterPresetsMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="mainPresetsMenu.menu"
                class="raised-menu-button"
                *ngIf="viewLoaded">
            <span>Filter Presets</span>
        </button>

        <rip-holdings-filter-preset-menu-item #mainPresetsMenu [presetGroups]="presetGroups"
                                              (choosePresetFilter)="choosePresetFilter($event)">

        </rip-holdings-filter-preset-menu-item>
    </div>
</div>
