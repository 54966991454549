import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RiskReturn } from '../../utils/dataInterfaces';
import { environment } from '../../../environments/environment';
import { MarketCalcTerms } from '../../utils/enums';

@Component( {
  selector: 'rip-correlation-matrix',
  template: `

      <div class="correlation-matrix" [nbSpinner]="loading">
          <div class="thead">
              <div class="tr">
                  <div class="th"></div>
                  <div class="th" *ngFor="let rr of riskReturnsValues" [ngClass]="{'cell-highlight': rr.hovered }">
                      <div>{{ rr.label }}</div>
                  </div>
                  <!-- going to change to short name of category names (truncate ticker names for user added funds)-->
              </div>
          </div>
          <div class="tbody">
              <div class="tr" *ngFor="let rr of riskReturnsValues; index as i">
                  <div class="th" [ngClass]="{'cell-highlight': rr.hovered }">
                      <div>{{ rr.label }}</div>
                  </div>
                  <div class="td" *ngFor="let correlation of (term === termLong  ? rr.longTermCorrelations : rr.correlations); index as j"
                       [style.background-color]="i === j ? '#F26722' : correlation.correlation.color"
                       [style.color]="correlation.correlation.total > -0.25 ? 'white' : 'black'"
                       (mouseenter)="correlation.hovered = true; markCorrespondingCorrelationHovered( correlation.identifier, rr.identifier, true )"
                       (mouseleave)="correlation.hovered = false; markCorrespondingCorrelationHovered( correlation.identifier, rr.identifier, false )"
                       [ngClass]="{'cell-highlight': correlation.hovered }">
                      <ng-container
                              *ngTemplateOutlet="(i < j && correlation.correlation.market !== undefined && !combinedTable) ? correlationBreakdownTemplate : i === j ? correlationFullTemplate : correlationTotalTemplate; context:{correlation: correlation.correlation}">

                      </ng-container>
                  </div>
              </div>
          </div>
      </div>
      <div class="flexing-row-only space-between"><span>Lowest Correlation</span> <span>Highest Correlation</span>
      </div>
      <div class="flexing-row-only correlation-color-legend-container">
          <div>-1</div>
          <div class="correlation-color-legend">
            
            <span class="correlation-vertical-bar" *ngIf="hoveredCorrelationScaled !== undefined"
                  [style.left]="hoveredCorrelationScaled * 100 + '%'"></span>

          </div>
          <div>+1</div>
      </div>
      <div class="flexing-row-only space-between"><span>Most Diversification</span>
          <span>Least Diversification</span>
      </div>
      <ng-template #correlationBreakdownTemplate let-correlation="correlation">
          <div class="correlation-breakdown-container-wrapper">
              <div class="correlation-breakdown-container">
                  <div><!-- class="flexing-desktop-only space-between vertical-flex">-->
                      <div class="footnote">Market</div>
                      <div>{{ correlation?.market | ripPercentPipe : '0-2'}}</div>
                      <div class="footnote">Non-Market</div>
                      <div>{{ correlation?.nonMarket | ripPercentPipe : '0-2'}}</div>
                  </div>
              </div>
          </div>
      </ng-template>

      <ng-template #correlationFullTemplate let-correlation="correlation">
          <div class="correlation-container">
              <!--1.000--> {{ correlation?.total | ripDecimalPipe : '3-3' }}
          </div>
      </ng-template>

      <ng-template #correlationTotalTemplate let-correlation="correlation">
          <div class="correlation-container">
              {{ correlation?.total | ripDecimalPipe : '3-3' }}
          </div>
      </ng-template>
  `,
  styleUrls: [ './correlation-matrix.component.scss' ],
} )

export class CorrelationMatrixComponent {

  @Input() riskReturnsValues: RiskReturn[] = [];
  @Input() loading: boolean = true;
  @Input() errorLoadingData: boolean = false;
  @Input() combinedTable: boolean = false;
  @Input() term: MarketCalcTerms = MarketCalcTerms.short;

  termShort: MarketCalcTerms = MarketCalcTerms.short;
  termLong: MarketCalcTerms = MarketCalcTerms.long;

  @Output() retryDataLoad: EventEmitter<any> = new EventEmitter<any>();

  hoveredCorrelationScaled: number;

  constructor() {
    if ( environment.env !== 'prod' ) {
      window[`ripsaw_correlationMatrix_${ Math.random() }`] = this;
    }
  }

  markCorrespondingCorrelationHovered( correlationIdentifier: string, rrIdentifier: string, status: boolean ) {
    for ( const rr of this.riskReturnsValues ) {
      if ( rr.identifier === correlationIdentifier ) {
        rr.hovered = status;
        for ( const c of rr.correlations ) {
          if ( c.identifier === rrIdentifier ) {
            c.hovered = status;
            if ( status ) {
              this.hoveredCorrelationScaled = ( c.correlation.total * 0.5 ) + 0.5;
            } else {
              this.hoveredCorrelationScaled = undefined;
            }
          }
        }
      }
      if ( rr.identifier === rrIdentifier ) {
        rr.hovered = status;
      }
    }
  }

}

