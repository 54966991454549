import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { WorkspacePermissionService } from '../service/workspace-permission.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LibWorkspacePermissionModule } from '../workspace-permission.module';
import { WorkspacePermissionNameStore } from '../../../store/workspace';

@Injectable( {
  providedIn: LibWorkspacePermissionModule,
} )
export class WorkspacePermissionGuard implements CanActivate {

  constructor(
    private wpService: WorkspacePermissionService,
    private router: Router,
  ) {
  }

  canActivate( route: ActivatedRouteSnapshot ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.accessGranted( route );
  }

  private accessGranted( route: ActivatedRouteSnapshot ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const perms: WorkspacePermissionNameStore | WorkspacePermissionNameStore[] = route?.data?.permissions;

    if ( !perms ) {
      console.error( `PermissionsGuard is used but no permissions were provided to check the path: '${ route.url }'.
      Provide 'permissions' in 'data' for routeConfig` );

      return this.router.createUrlTree( [ '/' ] );
    }

    return this.wpService.hasAny( perms )
      .pipe(
        map( hasAccess => hasAccess || this.router.createUrlTree( [ '/' ] ) ),
      );
  }

}
