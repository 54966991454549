/**
 * Created by joshua on 12/7/16.
 */
import { Injectable } from '@angular/core';


import { environment } from '../../../../environments/environment';
import { Util } from '../../../utils/util.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RevisionService {
  // API path
  lambdaUserBaseUrl: string;

  constructor( private _http: HttpClient ) {
    this.lambdaUserBaseUrl = `${environment.ripsawAPIBaseUrl}/workspace-revisions`;
  }

  createRevision( revision: any ) {
    const url = `${this.lambdaUserBaseUrl}/create`;
    return this._http.post( url, revision )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getRevisions() {
    const url = `${this.lambdaUserBaseUrl}/getAll`;
    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateRevision( revision: any ) {
    const url = `${this.lambdaUserBaseUrl}/update/${revision.id}`;
    return this._http.put( url, revision )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  deleteRevision( revision: any ) {
    const url = `${this.lambdaUserBaseUrl}/remove/${revision.id}`;
    return this._http.delete( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
