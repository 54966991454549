import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faChevronDown,
  faChevronRight,
  faEllipsis,
  faEye,
  faLink,
  faQuestionCircle,
  faRotate,
  faTrash,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { GlobalState } from '../../../global.state';
import { AccountManager } from '../../../utils/accountManager';
import * as _ from 'lodash-es';
import { GlobalDataService } from '../../../globalData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Util } from '../../../utils/util.service';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { AdvisorFeeEditorComponent } from './advisorFeeEditor';
import { NicknameEditorComponent } from './nicknameEditor';
import { MatDialogRef } from '@angular/material/dialog';
import { ConnectionSyncOption, EVENT_NAMES } from '../../../utils/enums';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../shared/workspace-permission';
import { AppStoreService } from '../../../store';
import { finalize, map, take, takeUntil } from 'rxjs/operators';
import { WorkspacePermissionNameStore } from '../../../store/workspace';
import { faEyeSlash, faPenToSquare, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Connection } from '../../../utils/dataInterfaces';
import { FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { WealthFluentMessageService } from '../../../theme/services/wealthFluentMessage';

@Component( {
  selector: 'rip-agg-connection-manager',
  templateUrl: './aggConnectionManager.component.html',
  styleUrls: [ './aggConnectionManager.scss' ],
} )
export class AggConnectionManagerComponent extends WithWorkspacePermission implements OnDestroy, OnInit {
  protected readonly faPenToSquare: IconDefinition = faPenToSquare;
  protected readonly faEdit: IconDefinition = faEdit;
  protected readonly faAngleUp: IconDefinition = faAngleUp;
  protected readonly faAngleDown: IconDefinition = faAngleDown;
  protected readonly faEllipsis: IconDefinition = faEllipsis;
  protected readonly faTrash: IconDefinition = faTrash;
  protected readonly faRotate: IconDefinition = faRotate;
  protected readonly faCheck: IconDefinition = faCheck;
  protected readonly faXMark: IconDefinition = faXmark;
  protected readonly faChevronDown: IconDefinition = faChevronDown;
  protected readonly faChevronRight: IconDefinition = faChevronRight;
  protected readonly faLink: IconDefinition = faLink;
  protected readonly faEyeSlash: IconDefinition = faEyeSlash;
  protected readonly faEye: IconDefinition = faEye;
  protected readonly faQuestionCircle: IconDefinition = faQuestionCircle;

  protected isLoading: boolean = false;

  private readonly onDestroy: Subject<void> = new Subject<void>();
  protected accordionState: Record<string, boolean> = {};

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  connections: Connection[];

  @Output() fixConnection: EventEmitter<number> = new EventEmitter<number>();

  spinnerSelector: string = 'agg-connection-manager-spinner';
  publicHelpers: Util;

  statusMap: any = {};
  dateFormat: string = 'MM/dd/yy h:mm a';
  accountSettingIsDisabled$: Observable<boolean>;

  inWealthFluent: boolean = false;

  protected syncOptionsMap: Record<string, string> = {
    WEEKLY: 'Weekly',
    DAILY: 'Nightly',
  };

  dataRetrieved: boolean = false;
  subscriberName: string = 'aggConnectionManagerComponent';
  appName: string = environment.appName;
  isSyncingOnLogin: boolean = false;

  constructor(
    private _state: GlobalState,
    private _accountManager: AccountManager,
    private _gdService: GlobalDataService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AggConnectionManagerComponent>,
    private appStoreService: AppStoreService,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {
    super();
    this.inWealthFluent = this._state.globalVars.inWealthFluent;
    this.dataRetrieved = !this._state.globalVars?.loadingAccounts;
    this._accountManager.isSyncingOnLogin.subscribe( ( value: boolean ) => {
      this.isSyncingOnLogin = value;
    } );
    this._accountManager.allDataRetrieved
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( val: boolean ) => {
          if ( !this._accountManager.isSyncingOnLogin.value ) {
            this.dataRetrieved = val;
          }
        },
      } );

    this.publicHelpers = Util;
    this.statusMap = _state.statusMap;
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_connectionManager' ] = this;
    }
    // this.dateFormat = _state.syncTimeStampFormat;
    this.setConnections( this._state.globalVars.allConnections );
    if ( this.connections.length > 0 ) {
      this.dataRetrieved = true;
    }
    this._state.subscribe(
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      () => {
        // get the connections that should be in the globalVars by the time this event is notified
        this.dataRetrieved = true;
        this.setConnections( this._state.globalVars.allConnections );
      },
      this.subscriberName,
    );

    this._state.subscribe(
      EVENT_NAMES.ACCOUNTS_LOADING_ERROR,
      () => {
        this.dataRetrieved = true;
      },
      this.subscriberName,
    );
  }

  ngOnInit(): void {
    this.accountSettingIsDisabled$ = this.initAccountSettingIsDisabledObs();
  }

  setConnections( connections: Connection[] ): void {
    this.connections = connections;
    this.accordionState = connections.reduce(
      ( acc: Record<string, boolean>, curr: any ) => ( {
        ...acc,
        [ curr.id ]: this.accordionState[ curr.id ] ?? false,
      } ),
      {},
    );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe(
      EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE,
      'aggConnectionManagerComponent',
    );
    this.onDestroy.next();
  }

  expandAll(): void {
    this.accordionState = Object.keys( this.accordionState ).reduce(
      ( acc: Record<string, boolean>, id: string ) => ( {
        ...acc,
        [ id ]: true,
      } ),
      {},
    );
  }

  collapseAll(): void {
    this.accordionState = Object.keys( this.accordionState ).reduce(
      ( acc: Record<string, boolean>, id: string ) => ( {
        ...acc,
        [ id ]: false,
      } ),
      {},
    );
  }

  isAllClosed(): boolean {
    return !Object.values( this.accordionState ).includes( true );
  }

  getConnectionAccounts( connection: any ) {
    return _.filter( this._state.globalVars.allAccountsFromAgg, ( a: any ) => {
      return a.connection_id === connection.id;
    } );
  }

  deleteConnection( connection: any ): void {
    this.dataRetrieved = false;
    this._gdService
      .deleteAggConnection( connection.id )
      .pipe(
        finalize( () => {
          this.dataRetrieved = true;
        } ),
      )
      .subscribe(
        {
          next:
            (/*resp: any*/ ) => {
              this.messageService.add( {
                severity: 'success',
                summary: 'Success',
                detail: `Connection to ${ connection.institution_name } Deleted Successfully`,
              } );
              const newConnections = this.connections.filter( ( { id } ) => {
                return id !== connection.id;
              } );
              this.setConnections( newConnections );
              this._accountManager.removeConnectionFromMapping( connection );
              this._accountManager.getAccounts();
              this.wealthFluentMessageService.connectionRemoved( connection);
            },
          error: err => {
            this.messageService.add( {
              severity: 'error',
              summary: 'Error',
              detail: `Error while removing connection: ${ err.err }`,
            } );
          },
        },
      );
  }

  deleteClickHandle( event: Event, next: any ): void {
    event.stopPropagation();
    next();
  }

  accountIncluded( account: any ): boolean {
    const mapping = this._state.globalVars.accountMapping.mapping;
    if ( mapping ) {
      const mapConnection = mapping[ account.connection_id ];
      if ( mapConnection ) {
        const mapAccount = mapConnection[ account.account_id ];
        if ( mapAccount ) {
          return mapAccount.include;
        }
      }
    }
    // in case the account or connection isn't in the map, include the account
    return true;
  }

  includeAccount( account: any, isDisabled: boolean ): void {
    if ( isDisabled ) {
      return;
    }

    this._accountManager.includeAccount( account, include => {
      this.snackBar.open(
        `Account ${ include ? 'Included' : 'Hidden' } Successfully`,
        null,
        Util.getSnackBarOptions(),
      );
    } );
  }

  includeAccountNew( account: any, option: boolean | null ) {
    this._accountManager.includeAccountNew(
      account,
      include => {
        this.messageService.add( {
          severity: 'success',
          summary: 'Success',
          detail: `Account was ${
            include ? 'included' : 'hidden'
          } successfully!`,
        } );
      },
      option,
    );
  }

  openFastLinkForRepairingConnection( connection: Connection, event: any ) {
    this.appStoreService.loadedWorkspacePermissions$
      .pipe( take( 1 ) )
      .subscribe( ( allPerms: WorkspacePermissionNameStore[] ) => {
        const hasPermission = hasAnyPermissionFn( allPerms, [
          this.perm.OWNER,
          this.perm.ACCOUNTS_ADD,
        ] );
        if ( hasPermission ) {
          event.stopPropagation();
          this.fixConnection.emit( connection.id );
          // old implementation when this was in its own dialog. Now just need to change tabs
          // this.fastLinkUtil.openFastlinkDialog( connection.id );
          // this.dialogRef.close();
        }
      } );
  }

  getStatusLabel( status: string ): string {
    return _.startCase( _.toLower( status ) );
  }

  editConnectedAccount(
    advisorFeeEditor: AdvisorFeeEditorComponent,
    nicknameEditor: NicknameEditorComponent,
  ): void {
    advisorFeeEditor.edit();
    nicknameEditor.edit();
  }

  updateConnectedAccount(
    advisorFeeEditor: AdvisorFeeEditorComponent,
    nicknameEditor: NicknameEditorComponent,
  ): void {
    advisorFeeEditor.updateFee();
    nicknameEditor.updateNickname();
  }

  cancelChanges(
    advisorFeeEditor: AdvisorFeeEditorComponent,
    nicknameEditor: NicknameEditorComponent,
  ): void {
    advisorFeeEditor.reset();
    nicknameEditor.cancelUpdateNickname();
  }

  private initAccountSettingIsDisabledObs(): Observable<boolean> {
    return this.appStoreService.loadedWorkspacePermissions$.pipe(
      map( allPerms =>
        hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNT_SETTINGS_VIEW ] ),
      ),
    );
  }

  toggleMenu( event: Event ): void {
    event.stopPropagation();
  }

  toggleActionMenu( event: Event, previous: any ): void {
    event.stopPropagation();
    previous();
  }

  cancelDeletion( stepper ): void {
    setTimeout( () => {
      stepper.previous();
    }, 200 );
  }

  toggleSyncOnLogin( event: Event, connection: Connection ): void {
    event.stopPropagation();
    const { syncLogin, syncNightly } = connection;
    connection.syncLogin = !syncLogin;
    this._gdService
      .setConnectionSyncOptions( connection.id, {
        syncLogin: !syncLogin,
        syncNightly,
      } )
      .subscribe( {
        next: () => {
        },
        error: () => {
          connection.syncLogin = syncLogin;
          this.messageService.add( {
            severity: 'error',
            summary: 'Error',
            detail: `Error while setting sync schedule`,
          } );
        },
      } );
  }

  setSyncByScheduleOption(
    event: Event,
    connection: Connection,
    option: ConnectionSyncOption,
  ): void {
    // event.stopPropagation();
    const { syncLogin, syncNightly } = connection;
    let newSyncNightly = option;
    connection.syncNightly = newSyncNightly;
    this._gdService
      .setConnectionSyncOptions( connection.id, {
        syncLogin,
        syncNightly: newSyncNightly,
      } )
      .subscribe( {
        next: () => {
        },
        error: () => {
          connection.syncNightly = syncNightly;
          this.messageService.add( {
            severity: 'error',
            summary: 'Error',
            detail: `Error while setting sync schedule`,
          } );
        },
      } );
  }

  toggleSyncBySchedule(
    event: Event,
    connection: Connection,
    menuTrigger?: any,
  ): void {
    event.stopPropagation();
    const { syncLogin, syncNightly } = connection;
    let newSyncNightly = syncNightly;
    newSyncNightly = syncNightly === 'NONE' ? 'DAILY' : 'NONE';
    connection.syncNightly = newSyncNightly;
    this._gdService
      .setConnectionSyncOptions( connection.id, {
        syncLogin,
        syncNightly: newSyncNightly,
      } )
      .subscribe( {
        next: () => {
        },
        error: () => {
          connection.syncNightly = syncNightly;
          this.messageService.add( {
            severity: 'error',
            summary: 'Error',
            detail: `Error while setting sync schedule`,
          } );
        },
      } );
  }

  refresh(): void {
    this._accountManager.getAccounts();
  }

  refreshAll(): void {
    this.messageService.add( {
      severity: 'info',
      summary: 'Info',
      detail: `Sync started.`,
    } );
    this._gdService.syncAllConnections().subscribe( {
      next: () => {
        this.isLoading = false;
        this.refresh();
      },
      error: err => {
        this.isLoading = false;
        this.messageService.add( {
          severity: 'error',
          summary: 'Error',
          detail: `${ err.err }.`,
        } );
      },
    } );
  }

  syncConnection( id: number ): void {
    this.messageService.add( {
      severity: 'info',
      summary: 'Info',
      detail: `Sync started.`,
    } );
    this.isLoading = true;
    this._gdService.syncConnection( id ).subscribe( {
      next: () => {
        this.isLoading = false;
        this.refresh();
      },
      error: err => {
        this.isLoading = false;
        this.messageService.add( {
          severity: 'error',
          summary: 'Error',
          detail: `${ err.err }.`,
        } );
      },
    } );
  }

  handleAccordionToggleClick(): void {
    if ( this.isAllClosed() ) {
      this.expandAll();
    } else {
      this.collapseAll();
    }
  }

}
