import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class MortgageHelpers {

  static calcMortgageAnalyticValues( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate, paydown, periodType? ) {
    periodType = periodType || 'month'; // must be one of 'month', 'quarter', 'semi-annual', or 'annual'
    const numPeriodsInAYear = this.translatePeriodType( periodType );

    origLoanAmount = Math.abs( origLoanAmount );
    outstandingBalance = outstandingBalance === '' ? undefined : Math.abs( outstandingBalance );
    const periodRate = rate / numPeriodsInAYear; // previously monthlyRate
    const totalNumPeriods = term * numPeriodsInAYear;


    let periodPayment;
    if ( rate === 0 ) {
      periodPayment = origLoanAmount / totalNumPeriods;
    } else {
      const pvAnnuityFactor = this.getPVAnnuityFactor( periodRate, totalNumPeriods );
      periodPayment = origLoanAmount / pvAnnuityFactor;
    }


    const newPeriodRate = newRate / numPeriodsInAYear; // may need to do an extra step based on whether the newRate is for a different period type
    // console.log( `monthly rate: ${monthlyRate}` );
    // console.log( `number of periods: ${numPeriods}` );
    // console.log( `PV Annuity Factor: ${pvAnnuityFactor}` );
    // console.log( `monthly payment: ${monthlyPayment}` );

    const endOfPeriod = MortgageHelpers.getEndOfPeriod( periodType, today );

    const currentPeriod = this.getPeriodDiff( endOfPeriod, origLoanDate, numPeriodsInAYear );
    // console.log( `current month: ${currentMonth}` );

    const amoritization = [];
    const mortgageAnalyticOutputs: any = {
      presentValue: 0,
      outstandingBalance: outstandingBalance || origLoanAmount,
      weightedAvgLife: 0,
      maturityDistribution:
        {
          less_than_one_year: 0,
          one_to_three_years: 0,
          three_to_five_years: 0,
          five_to_seven_years: 0,
          seven_to_ten_years: 0,
          ten_to_fifteen_years: 0,
          fifteen_to_twenty_years: 0,
          twenty_to_thirty_years: 0,
          over_thirty_years: 0,
        },
    };

    for ( let i = 0; i <= totalNumPeriods; i++ ) {
      const obj: any = {};
      if ( amoritization[i - 1] ) {
        const prevPeriodBalance = amoritization[i - 1].balance;

        obj.month = i;
        obj.interest = prevPeriodBalance * periodRate;

        if ( prevPeriodBalance < periodPayment ) {
          obj.payment = prevPeriodBalance + obj.interest;
          obj.balance = 0;
        } else {
          obj.payment = periodPayment;
          obj.balance = prevPeriodBalance - (periodPayment - obj.interest);
          if ( i === currentPeriod ) {
            // console.log(`current period is ${currentPeriod}, obj.balance is ${obj.balance}, outstanding balance is ${outstandingBalance}`);
            // obj.balance = (outstandingBalance >= 0 ? outstandingBalance : obj.balance) - paydown;
            obj.balance -= paydown;
            mortgageAnalyticOutputs.outstandingBalance = obj.balance;
          }
        }
      } else {
        obj.balance = origLoanAmount - paydown;
      }
      if ( i > currentPeriod ) {
        obj.pv = this.getPeriodPresentValue( obj.payment, newPeriodRate, i, currentPeriod );
        obj.principal = periodPayment - obj.interest;
        obj.timePeriodInYears = (i - currentPeriod) / numPeriodsInAYear;

        mortgageAnalyticOutputs.presentValue += obj.pv;
        mortgageAnalyticOutputs.weightedAvgLife += obj.principal * obj.timePeriodInYears;

        if ( obj.timePeriodInYears < 1 ) {
          mortgageAnalyticOutputs.maturityDistribution.less_than_one_year += obj.principal;
        } else if ( obj.timePeriodInYears >= 1 && obj.timePeriodInYears < 3 ) {
          mortgageAnalyticOutputs.maturityDistribution.one_to_three_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 3 && obj.timePeriodInYears < 5 ) {
          mortgageAnalyticOutputs.maturityDistribution.three_to_five_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 5 && obj.timePeriodInYears < 7 ) {
          mortgageAnalyticOutputs.maturityDistribution.five_to_seven_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 7 && obj.timePeriodInYears < 10 ) {
          mortgageAnalyticOutputs.maturityDistribution.seven_to_ten_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 10 && obj.timePeriodInYears < 15 ) {
          mortgageAnalyticOutputs.maturityDistribution.ten_to_fifteen_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 15 && obj.timePeriodInYears < 20 ) {
          mortgageAnalyticOutputs.maturityDistribution.fifteen_to_twenty_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 20 && obj.timePeriodInYears < 30 ) {
          mortgageAnalyticOutputs.maturityDistribution.twenty_to_thirty_years += obj.principal;
        } else if ( obj.timePeriodInYears >= 30 ) {
          mortgageAnalyticOutputs.maturityDistribution.over_thirty_years += obj.principal;
        }

      }

      amoritization[i] = obj;
      if ( obj.balance <= 0 ) {
        break;
      }
      // console.log( JSON.stringify( obj ) );
    }

    if (currentPeriod > totalNumPeriods) {
      mortgageAnalyticOutputs.outstandingBalance = 0;
    }
    // finalize weightedAvgLife by dividing but outstanding balance and numPeriodsInAYear to convert to an annual number
    mortgageAnalyticOutputs.weightedAvgLife /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.less_than_one_year /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.one_to_three_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.three_to_five_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.five_to_seven_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.seven_to_ten_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.ten_to_fifteen_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.fifteen_to_twenty_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.twenty_to_thirty_years /= mortgageAnalyticOutputs.outstandingBalance;
    mortgageAnalyticOutputs.maturityDistribution.over_thirty_years /= mortgageAnalyticOutputs.outstandingBalance;
    // return the amoritization schedule
    mortgageAnalyticOutputs.amoritization = amoritization;

    return mortgageAnalyticOutputs;
  }

  static getPresentValue( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate, paydown, periodType? ) {
    const mortgageAnalyticOutputs = this.calcMortgageAnalyticValues( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate, paydown, periodType );
    return mortgageAnalyticOutputs.presentValue;
  }

  static calcDuration( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate, paydown, periodType? ) {
    const pvDown = this.getPresentValue( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate - 0.0025, paydown, periodType );
    const pvUp = this.getPresentValue( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate + 0.0025, paydown, periodType );
    const pvBase = this.getPresentValue( origLoanAmount, outstandingBalance, origLoanDate, maturityDate, today, rate, term, newRate, paydown, periodType );

    // console.log(`down ${pvDown}, up ${pvUp}, base ${pvBase}`);
    return ((pvDown - pvUp) / pvBase) * 200; // return duration in years
  }

  static calcMaturityDateFromOrigTerm( origLoanDate, origTermInYears ) {
    return moment( origLoanDate ).add( origTermInYears, 'months' );
  }

  static calcOriginationDateFromMaturityDateAndTerm( maturityDate, origTermInYears ) {
    return moment( maturityDate ).subtract( origTermInYears, 'months' );
  }

  static calcOrigTermFromMaturityDate( origLoanDate, maturityDate ) {
    return this.getPeriodDiff( maturityDate, origLoanDate, 12 );
  }

  static getPeriodPresentValue( payment, rate, period, curPeriod ) {
    return payment / Math.pow( (1 + rate), (period - curPeriod) );
  }


  static getPeriodDiff( current, origination, numberPeriodsInAYear ) {
    switch ( numberPeriodsInAYear ) {
      case 1:
        return Math.floor( moment( current ).diff( moment( origination ), 'months' ) / 12 );
      case 2:
        return Math.floor( moment( current ).diff( moment( origination ), 'months' ) / 6 );
      case 4:
        return Math.floor( moment( current ).diff( moment( origination ), 'months' ) / 3 );
      case 12:
        return moment( current ).diff( moment( origination ), 'months' );
    }
  }

  static getPVAnnuityFactor( periodRate, numPeriods ) {
    return (1 - (1 / (Math.pow( (1 + periodRate), numPeriods )))) / periodRate;
  }

  static getEndOfPeriod( periodType, date ) {
    const month = date.getMonth();
    let endMonth;
    let addYearFlag = false;
    switch ( periodType ) {
      case 'month':
        endMonth = month + 1;
        break;
      case 'quarter':
        if ( month >= 0 && month < 3 ) {
          endMonth = 3;
        }
        if ( month >= 3 && month < 6 ) {
          endMonth = 6;
        }
        if ( month >= 6 && month < 9 ) {
          endMonth = 9;
        }
        if ( month >= 9 && month < 12 ) {
          endMonth = 0;
          addYearFlag = true;
        }
        break;
      case 'semi-annual':
        if ( month >= 0 && month < 6 ) {
          endMonth = 6;
        }
        if ( month >= 6 && month < 12 ) {
          endMonth = 0;
          addYearFlag = true;
        }
        break;
      case 'annual':
        endMonth = 0;
        break;
    }
    if ( endMonth === 0 ) {
      return new Date( date.getFullYear() + 1, endMonth, 0 );
    } else {
      return new Date( date.getFullYear(), endMonth, 0 );
    }

  }

  static translatePeriodType( type ) {
    switch ( type ) {
      case 'month':
        return 12;
      case 'quarter':
        return 4;
      case 'semi-annual':
        return 2;
      case 'annual':
        return 1;
      default:
        return 12;
    }
  }

}
