import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CustomStepDefinition, Options } from '@angular-slider/ngx-slider';
import { GlobalState } from '../../global.state';
import { BenchmarkState } from '../../utils/benchmark.state';
import { BenchmarkUtil } from '@ripsawllc/ripsaw-analyzer';
import { ExpectedWealthIssue, RiskLevel } from '../../utils/dataInterfaces';
import { environment } from '../../../environments/environment';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../shared/workspace-permission';
import { AppStoreService } from '../../store';


@Component( {
  selector: 'rip-risk-tolerance-slider',
  template: `
    <nb-card id="risk-tolerance-slider-container">
      <nb-card-body class="no-padding-card-body no-scroll">
        <!-- <div class="flexing{{ mobileDeviceIsPortrait ? '' : '-row-only' }} space-between risk-tolerance-charts-row"
              style="padding-top: 10px;">-->
        <div class="flexing-row-only space-between risk-tolerance-charts-row"
             style="padding-top: 10px;">
          <button mat-button *ngFor="let bucket of benchmarkState?.expectedWealthBuckets; let index = index;"
                  (click)="changeSlider(bucket?.riskLevel?.riskNumber); bucketChosen();"
                  [disabled]="actionsAreDisabled$ | async"
                  class="wealth-chart-button" style="flex: 1;">

            <div class="risk-level-icon">

              <div *ngIf="!deviceIsMobile">
                <fa-icon [icon]="bucket?.riskLevel?.icon" size="2x" fixedWidth>
                </fa-icon>
              </div>
              <div *ngIf="deviceIsMobile">
                <fa-icon [icon]="bucket?.riskLevel?.icon" size="2x" fixedWidth style="padding: 0;">
                </fa-icon>
              </div>


              <div>{{bucket?.riskLevel?.label}}</div>
            </div>
          </button>
        </div>
        <div class="ripsaw-card-header" style="padding: 0;">
          <div class="ripsaw-card-subheader" style="padding: 0;">
            <span style="padding: 10px 10px; position: relative;" id="the-slider">
              Expected Wealth vs Risk Tradeoffs
              <nb-badge [status]="issues?.length > 1 ? 'danger' : 'warning'" text="3"
                        *ngIf="issues?.length > 0"></nb-badge>
            </span>
          </div>
        </div>
        <div class="flexing-row-only center-vertically risk-tolerance-slider-row custom-slider"
             *ngIf="!deviceIsMobile || !mobileDeviceIsPortrait">
          <ngx-slider [value]="riskLevel" [options]="sliderOptions$ | async" id="risk-tolerance-slider"
                      (userChange)="sliderChanged($event)"></ngx-slider> <!-- (valueChange)="sliderChanged($event)"  -->
        </div>
      </nb-card-body>

    </nb-card>
  `,
  styleUrls: [ './risk-tolerance-slider.component.scss' ],
} )

export class RiskToleranceSliderComponent extends WithWorkspacePermission implements OnInit, AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  faQuestionCircle = faQuestionCircle;

  @Output() riskLevelChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickedBucket: EventEmitter<any> = new EventEmitter<any>();

  @Input() hideBucketsOnClick: boolean = false;
  @Input() hideBucketsOnStart: boolean = true;
  @Input() issues: ExpectedWealthIssue[];
  @Input() deviceIsMobile: boolean;
  @Input() actionsAvailable: boolean;

  chartsMinimized: boolean = false;

  riskLevel: number = 50;
  riskLabel: string = '';

  sliderOptions$: Observable<Options>;

  mobileDeviceIsPortrait: boolean;

  subscriberName: string = 'riskToleranceSliderComponent';
  actionsAreDisabled$: Observable<boolean>;

  constructor( private _state: GlobalState,
               public benchmarkState: BenchmarkState,
               private bpObserver: BreakpointObserver,
               private appStoreService: AppStoreService,
  ) {
    super();

    _state.subscribe( 'risk.slider.set.level', ( level: number ) => {
      this.setRiskLevel( level );
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window['ripsaw_riskToleranceSlider'] = this;
    }

  }

  ngOnInit(): void {
    this.actionsAreDisabled$ = this.initActionsAreDisabledObs();
    this.sliderOptions$ = this.initSliderOptionsObs();
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.chartsMinimized = this.hideBucketsOnStart;

      if ( this.deviceIsMobile ) {
        // check for current orientation
        this.mobileDeviceIsPortrait = this.bpObserver.isMatched( '(orientation: portrait)' );
        this.bpObserver.observe( [
          '(orientation: portrait)',
        ] )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: ( result ) => {
              this.mobileDeviceIsPortrait = result.matches;
            },
          } );
      }
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( 'risk.slider.set.level', this.subscriberName );
  }

  setRiskLevel( level: number ) {
    this.riskLevel = level;
    this.updateRiskLabel();
  }

  mainRiskLevels: RiskLevel[] = BenchmarkUtil.mainRiskLevels;

  bucketChosen() {
    if ( this.hideBucketsOnClick ) {
      this.chartsMinimized = true;
    }
    this.clickedBucket.emit();
  }

  sliderChanged( event ) {
    // console.log( 'risk level:', val );
    this.riskLevel = event?.value;
    this.riskLevelChanged.emit( event?.value );
    this.updateRiskLabel();
  }

  changeSlider( val ) {
    this.riskLevel = val;
    this.riskLevelChanged.emit( this.riskLevel );
    this.updateRiskLabel();
  }

  updateRiskLabel() {
    this.riskLabel = this.benchmarkState?.getBenchmarkName( this.riskLevel );
  }

  private initActionsAreDisabledObs(): Observable<boolean> {
    return this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        map(allPerms => {
          return this.actionsAvailable ? false : !hasAnyPermissionFn(allPerms, [this.perm.OWNER, this.perm.INVESTOR_PROFILE_EDIT]);
        }),
      );
  }

  private initSliderOptionsObs(): Observable<Options> {
    const initialOptions = this.setupSliderInitialOptions();

    return this.actionsAreDisabled$
      .pipe(
        map(disabled => ({
          ...initialOptions,
          disabled,
        })),
      );
  }

  private setupSliderInitialOptions(): Options {
    const initialOptions: Options = {
      floor: 0,
      ceil: 100,
      hideLimitLabels: true,
      hidePointerLabels: false,
      showTicks: true,
      showTicksValues: false,
      animateOnMove: true,
      translate: () => {
        return this.riskLabel;
      },
    };

    const steps: CustomStepDefinition[] = [];
    const rlArray = BenchmarkUtil.mainRiskLevels;

    // const ticksBetweenBuckets = benchmarkState?.frontierTicks / 7; // frontier ticks divided by the number of buckets + space before first and after last
    for ( let i = initialOptions.floor; i <= initialOptions.ceil; i++ ) {
      const riskLevel = rlArray.find( ( rl ) => {
        return rl.riskNumber === i;
      } );

      if ( riskLevel ) {
        steps.push( {
          legend: riskLevel.riskLabel,
          value: riskLevel.riskNumber,
        } );
      } else {
        steps.push( {
          value: i,
        } );
      }

    }
    initialOptions.stepsArray = steps;

    return initialOptions;
  }
}
