import { Injectable } from '@angular/core';
import { NotificationIssue } from './dataInterfaces';
import { AccountManager } from './accountManager';

@Injectable()
export class DataQualityChecker {

  static pqDiffThreshold = 5;
  static cbsDiffThreshold = 0.01;

  static checkPositionForPQDiff( position, _accountManager: AccountManager ): NotificationIssue {
    const account: any = _accountManager.getRevisableAccountFromId( position.account_id );
    if ( account && account.included ) {
      // Logger.log( `checking position for pq diff: ${ position.ticker } in account ${ account.account_id }` );
      // For now, we are just check price * quantity = value from yodlee_original_data
      // check to see if price * quantity - value is within the threshold
      if ( position.yodlee_original_data ) {
        const diff = Math.abs(
          ( position.yodlee_original_data.price * position.yodlee_original_data.quantity ) - position.yodlee_original_data.value,
        );
        if ( diff > DataQualityChecker.pqDiffThreshold ) {
          // return issue
          return {
            ticker: position.ticker,
            quantity: position.yodlee_original_data.quantity,
            value: position.yodlee_original_data.value,
            price: position.yodlee_original_data.price,
            account_id: account.account_id,
            account_name: `${ account.name }${ account.name && account.name.length > 0 ? ' - ' : '' }${ account.description }`,
            diff,
            type: 'pqDiff',
          } as NotificationIssue;
        }
      }
    }
  }

  static checkPositionForCBSDiff( position, _accountManager: AccountManager ): NotificationIssue {
    const account: any = _accountManager.getRevisableAccountFromId( position.account_id );
    if ( account && account.included ) {
      // Logger.log( `checking position for cbs diff: ${ position.ticker } in account ${ account.account_id }` );
      const cashBondsStocksDiff = Math.abs( 1 - ( ( position.stocks || 0 ) + ( position.bonds || 0 ) + ( position.cash || 0 ) + ( position.real_assets || 0 ) ) );
      if ( cashBondsStocksDiff >= DataQualityChecker.cbsDiffThreshold ) {
        // cash, bonds, stocks alloc off by more than a percent
        return {
          ticker: position.ticker,
          cash: position.cash,
          bonds: position.bonds,
          stocks: position.stocks,
          account_id: account.account_id,
          account_name: `${ account.name }${ account.name?.length > 0 ? ' - ' : '' }${ account.description }`,
          diff: cashBondsStocksDiff,
          type: 'cbsDiff',
        } as NotificationIssue;
      }
    }
  }

}
