import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceLoadDialogComponent } from './workspace-load-dialog.component';
import { LibDialogModule } from '../dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    WorkspaceLoadDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibDialogModule,
    ButtonModule,
    CheckboxModule,
  ],
  exports: [
    WorkspaceLoadDialogComponent,
  ],
})
export class LibWorkspaceLoadDialogModule { }
