import { WorkspaceStore } from './models';

export const initialWorkspaceState: WorkspaceStore = {
  loaded: null,
  loadedPermissions: [],
  my: [],
  shared: [],
  isRefreshing: { is: false },
  selectedSharee: null,
  creatingIds: [],
};
