import { Injectable } from '@angular/core';
import { Account, Position } from '@ripsawllc/ripsaw-analyzer';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faRecycle } from '@fortawesome/free-solid-svg-icons/faRecycle';
import { ThemePalette } from '@angular/material/core';
import { Util } from '../../../utils/util.service';
import * as _ from 'lodash-es';
import { Auth } from '../../../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalState } from '../../../global.state';
import { AccountManager } from '../../../utils/accountManager';
import { RipsawCurrencyPipe, RipsawDecimalPipe } from '../../../theme/pipes';
import { UntypedFormControl } from '@angular/forms';
import { RevisionService } from './revision.service';
import { environment } from '../../../../environments/environment';
import { EVENT_NAMES } from '../../../utils/enums';
import { Subject } from 'rxjs';

export interface Revision {
  accounts?: Account[];
  created_at?: string | Date;
  id?: number;
  name: string;
  summary?: AccountChange[];
  updated_at?: string | Date;
  loadState?: string;
  author_user_id?: string;
  author_email?: string;
  author_user_name?: string;
  workspace_id?: number;
}

export interface PositionChange {
  text?: string;
  positions?: string[];
}

export interface AccountChange {
  name: string;
  icon?: string;
  url?: string;
  changes: PositionChange[];
  isSetup?: boolean;
  hasTransfers?: boolean;
  hasSells?: boolean;
  hasBuys?: boolean;
}

@Injectable()
export class RevisionManagerUtil {

  faTrash = faTrash;
  faRecycle = faRecycle;

  currentCallback: Function;

  userRevisions$: Subject<Revision[]> = new Subject<Revision[]>();

  userRevisions: Revision[] = [];
  revision: Revision;

  revNameColor: ThemePalette;
  maxRevisions = 10;
  saving: boolean = true;

  saveButtonOptions = {
    active: false,
    text: 'Save',
    buttonColor: 'primary',
    spinnerColor: 'primary',
    raised: false,
    mode: 'indeterminate',
    disabled: false,
  };

  deleteButtonsOptions = {};

  loadingRevision: boolean = false;

  currentRevNames: any = {};

  constructor( private _revisionService: RevisionService,
               private _auth: Auth,
               private snackBar: MatSnackBar,
               private _state: GlobalState,
               private _accountManager: AccountManager ) {
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_revManager' ] = this;
    }
  }

  subscribeToEvents( callback: Function ) {
    this.currentCallback = callback;
  }

  resetAccounts( loadingRevision?: boolean ) {
    this.currentCallback( { fnName: 'resetAccounts', data: loadingRevision } );
  }

  openSummary( rev: Revision, msg?: string ) {
    this.currentCallback( { fnName: 'openSummary', data: { rev, message: msg } } );
  }

  toggleEdit() {
    this.currentCallback( { fnName: 'toggleEditMode' } );
  }

  summaryOnly( rev: Revision ) {
    this.currentCallback( { fnName: 'exitEditMode', data: rev } );
  }

  revisionFinishedLoading( rev: Revision ) {
    this.currentCallback( { fnName: 'revisionFinishedLoading', data: rev } );
  }

  deleteRevision( rev: Revision ) {
    this.deleteButtonsOptions[ rev.id ].active = true;
    this._revisionService.deleteRevision( rev )
      .subscribe( {
        next: ( /*response: any*/ ) => {
          // Logger.log( response );
          this.deleteButtonsOptions[ rev.id ].active = false;
          _.remove( this.userRevisions, ( r: Revision ) => {
            return r.id === rev.id;
          } );
          if ( rev.id === this.revision.id ) {
            this.resetAccounts();
          }
          this.snackBar.open( `${ rev.name } Deleted Successfully`, null, Util.getSnackBarOptions() );
          // this.doChanges();
        }, error: ( err ) => {
          console.error( err.err );
          this.snackBar.open( `Error deleting revision. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
          this.deleteButtonsOptions[ rev.id ].active = false;
          // this.doChanges();
        },
      } );
  }

  updateRevision( callback: Function ) {
    const updatedRev = this.updateRevisionObject();
    updatedRev.summary = this.createSummary( updatedRev );
    this._revisionService.updateRevision( updatedRev )
      .subscribe( {
        next: ( response: any ) => {
          // Logger.log( response );
          if ( !response.data?.id ) {
            this._state.globalVars.unsavedChanges = false;
            this.revision = response.data;
            const localRevIndex = this.userRevisions.findIndex( ( r: Revision ) => {
              return r.id === this.revision.id;
            } );
            this.userRevisions[ localRevIndex ] = this.revision;
            this.snackBar.open( `${ this.revision.name } Saved Successfully`, null, Util.getSnackBarOptions() );
            this._state.globalVars.currentRevision = this.revision;
            this.openSummary( updatedRev );
            this.saveButtonOptions.active = false;
            callback();
          } else {
            callback( 'Error updating revision' );
          }
        }, error: ( err ) => {
          callback( err );
        },
      } );
  }

  createNewRevision( revNameFormControl: UntypedFormControl, callback: Function ) {
    this.saveButtonOptions.active = true;
    // Logger.log( `lets save the revision with name: ${this.revNameFormControl.value}` );
    const existingRev = this.userRevisions.find( r => revNameFormControl.value === r.name );
    if ( existingRev ) {
      this.revision = existingRev;
      this.updateRevision( callback );
    } else {
      this.revision = this.createRevisionObject( revNameFormControl.value );
      this.revision.summary = this.createSummary( this.revision );
      this._revisionService.createRevision( this.revision )
        .subscribe( {
          next: ( response: any ) => {
            // Logger.log( response );
            if ( response.data?.id ) {
              this.revision = response.data;
              this.userRevisions.push( this.revision );
              this.snackBar.open( `${ revNameFormControl.value } Saved Successfully`, null, Util.getSnackBarOptions() );
              this._state.globalVars.unsavedChanges = false;
              this._state.globalVars.currentRevision = this.revision;
              this.openSummary( this.revision );
              this.saveButtonOptions.active = false;
              this._auth.renewTokens();
              callback( null, this.revision );
            } else {
              callback( 'Error creating revision.' );
            }
          }, error: ( err ) => {
            callback( err );
          },
        } );
    }

    // this.toggleEditMode();
  }

  loadRevision( rev: Revision ): boolean {
    // Logger.log( `load revision: ${rev.name}` );
    this.loadingRevision = true;
    try {
      if ( !this._state.globalVars.editing ) {
        this.toggleEdit();
      }
      this.resetAccounts( true );

      const newAccounts = [];

      for ( const ra of rev.accounts ) {
        const a = _.find( this._accountManager.getAllRevisableAccounts(), ( x: Account ) => {
          return x.account_id === ra.account_id;
        } );
        if ( a ) {
          const newAccount: Account = Object.assign( {}, a );
          newAccount.positions = [];
          newAccount.value = 0;

          newAccount.cashFromOtherAccounts = ra.cashFromOtherAccounts;
          newAccount.transferTargetAccounts = ra.transferTargetAccounts;
          newAccount.secTransfersTargetAccounts = ra.secTransfersTargetAccounts;
          for ( const revisionPosition of ra.positions ) {
            const pIndex = a.positions.findIndex( ( y: Position ) => {
              return revisionPosition.ticker === y.ticker;
            } );
            if ( pIndex >= 0 ) {
              // this means the security was already in the account
              newAccount.positions.push( Object.assign( {}, a.positions[ pIndex ], revisionPosition ) );
            } else {
              // this means that the security was added during the revision
              newAccount.positions.push( Object.assign( {
                account_id: a.account_id,
                quantity: 0,
                value: 0,
                allocation: 0,
                // cost_basis: 0,
                // gain_loss: 0,
              }, revisionPosition ) );
            }
            newAccount.value += revisionPosition.revised_value;

            if ( a.account_type === 'Loan' ) {
              newAccount.value -= ra.cashFromOtherAccounts;
            } else {
              newAccount.value += ra.cashFromOtherAccounts;
            }
          }
          this.setupAccountFromRevision( newAccount, false );
          newAccounts.push( newAccount );
        } else {
          if ( ra.added_in_revision ) {
            this.setupAccountFromRevision( ra, true );
            newAccounts.push( ra );
          } else {
            rev.loadState = 'bad';
            this.summaryOnly( rev );
            return;
          }
        }
      }

      this._state.globalVars.allAccounts = [ ..._.filter( newAccounts, ( a: Account ) => {
        return !a.isManual;
      } ) ];
      this._state.globalVars.allManualAccounts = [ ..._.filter( newAccounts, ( a: Account ) => {
        return a.isManual;
      } ) ];
      this._accountManager.categorizeAccounts( true );
      this.revision = rev;
      this._state.globalVars.currentRevision = this.revision;
      this._state.globalVars.unsavedChanges = false;
      this._state.notifyDataChanged( 're-render-tables', {} );
      this._state.notifyDataChanged( EVENT_NAMES.RECALCULATE_ALLOCATIONS, {} );
      // this._accountManager.refreshPrices();  // prices should be up-to-date enough by now. and this causes a call to WF and back which basically
      // undoes everything
      this.snackBar.open( `${ rev.name } Loaded Successfully`, null, Util.getSnackBarOptions() );
      this.revisionFinishedLoading( this.revision );
      this.loadingRevision = false;
      return true;
    } catch ( err ) {
      console.error( err.err );
      this.snackBar.open( `Error Loading Revision. ${ Util.getContactSupportString() }`, 'dismiss', Util.getSnackBarOptions( true ) );
      this.loadingRevision = false;
      this.resetAccounts();
      return false;
    }

  }

  private createRevisionObject( name: string ): Revision {
    const revision: Revision = {
      name,
      accounts: [],
    };
    for ( const account of this._accountManager.getAllRevisableAccounts() ) {
      const revisedAccount: Account = _.cloneDeep( account );
      revision.accounts.push( revisedAccount );
    }
    return revision;
  }

  private updateRevisionObject() {
    const updatedRevision = this.createRevisionObject( this.revision.name );
    updatedRevision.id = this.revision.id;
    updatedRevision.author_user_id = this.revision.author_user_id;
    updatedRevision.created_at = this.revision.created_at;
    return updatedRevision;
  }

  private setupAccountFromRevision( account: Account, isNew: boolean ) {
    const newPositions = [];
    account.value = 0;
    for ( const p of account.positions ) {
      account.value += p.revised_value;
      if ( isNaN( p.revised_value ) || ( p.revised_value < 0.01 && p.revised_value > -0.01 ) ) {
        p.revised_value = 0;
      }
      if ( isNaN( p.revised_difference ) || ( p.revised_difference < 0.01 && p.revised_difference > -0.01 ) ) {
        p.revised_difference = 0;
      }
      if ( isNaN( p.revised_quantity ) || ( p.revised_quantity < 0.01 && p.revised_quantity > -0.01 ) ) {
        p.revised_quantity = 0;
      }
      if ( isNaN( p.revised_allocation ) || ( p.revised_allocation < 0.0001 && p.revised_allocation > -0.0001 ) ) {
        p.revised_allocation = 0;
      }
      if ( isNew ) {
        Util.mergeSecurityAndPosition( this._state.globalVars.securities, p );
        const newP = Object.assign( {}, p, {
          quantity: 0,
          value: 0,
          allocation: 0,
          // cost_basis: 0,
          // gain_loss: 0,
        } );
        newPositions.push( newP );
      }
    }
    if ( isNew ) {
      account.positions = newPositions;
    }
    if ( account.account_category === 'Investment' ) {
      // account.non_allocated_funds.revised_allocation = 0;
      // account.non_allocated_funds.revised_value = 0;
    }

  }

  checkForNonAllocatedFunds() {
    const accountsWithUAF = [];
    for ( const a of this._accountManager.getAllRevisableAccounts() ) {
      if ( a.non_allocated_funds && ( a.non_allocated_funds.revised_value > 0.01 || a.non_allocated_funds.revised_value < -0.01 ) ) {
        accountsWithUAF.push( Util.formatAccountDescription( a ) );
      }
    }
    return accountsWithUAF;
  }

  // Summary functions ================================
  createSummary( revision: Revision ): AccountChange[] {
    // todo: find a way to get the order of the accounts right, so the transfers are printed before the buys in accounts that need the funding before
    // the buys can be made
    const allChanges: AccountChange[] = [ {
      name: 'Setup New Accounts',
      changes: [],
      isSetup: true,
    } ];
    for ( const a of revision.accounts ) {
      if ( a.added_in_revision ) {
        allChanges[ 0 ].changes.push( this.createSetup( a ) );
      }
      const accountChange: AccountChange = {
        changes: [],
        name: Util.formatAccountDescription( a ),
        icon: a.favicon || undefined,
        url: a.institution_url || undefined,
      };
      const buys = [],
        sells = [],
        transfers = [];
      if ( !Util.accountIsRealAssetWizardGenerated( a ) ) {
        // don't want to do position
        for ( const p of a.positions ) {
          if ( Util.accountIsNewInvestment( a ) ) {
            const op = _.find( a.originalPositions, ( x: Position ) => {
              return x.position_id === p.position_id;
            } );
            if ( op ) {
              this.analyzeNewPosition( p, op, sells, accountChange, buys );
            } else {
              this.analyzePosition( p, a, sells, accountChange, buys );
            }
          } else if ( p.revised_difference !== 0 ) {
            // check to see if the account was added in revision. if so, check to see if the cashFromOtherAccounts is the
            // same as the revised difference of the position. should mean that the position is the original from when
            // the account was added
            this.analyzePosition( p, a, sells, accountChange, buys );
          }
        }
      }
      for ( const target of Object.keys( a.transferTargetAccounts ) ) {
        if ( a.transferTargetAccounts[ target ] !== 0 ) {
          if ( Util.accountIsRealAsset( a ) ) {
            transfers.push( this.createSaleAndTransfer( a, target ) );
          } else {
            transfers.push( this.createTransfer( a, target ) );
          }
          accountChange.hasTransfers = true;
        }
      }
      for ( const target of Object.keys( a.secTransfersTargetAccounts ) ) {
        transfers.push( this.createSecurityTransfer( a, target ) );
        accountChange.hasTransfers = true;
      }
      accountChange.changes = sells.concat( transfers, buys );
      if ( accountChange.changes.length > 0 ) {
        allChanges.push( accountChange );
      }

    }
    if ( allChanges[ 0 ].changes.length === 0 ) {
      allChanges.shift();
    }
    allChanges.sort( this.sortAccountChanges );
    return allChanges;
  }

  private analyzePosition( p: Position, a: Account, sells: any[], accountChange: AccountChange, buys: any[] ) {
    if ( !a.added_in_revision || ( Math.abs( p.revised_difference ) !== Math.abs( a.cashFromOtherAccounts ) && a.positions.length === 1 ) ) {
      if ( Util.accountIsLoan( a ) || Util.accountIsCreditCard( a ) ) {
        // taking out paydowns for now because they are covered by cash transfers from the source account. may add back later
        /* let diff = p.revised_difference;
         if ( a.added_in_revision ) {
         const op = a.originalPositions[0];
         diff = Math.abs( op.revised_difference ) - Math.abs( p.revised_difference );
         }
         if ( diff > 0 ) {
         sells.push( this.createLoanPaydown( diff ) );
         accountChange.hasSells = true;
         }*/
      } else if ( Util.accountIsBanking( a ) ) {
        // for banking that is not credit card, we don't want to display a 'buy' or 'sell' since the only thing
        // that can happen is a transfer in or out. so we do nothing here
      } else {
        // this is only for buys and sells
        if ( p.buySell === 'Buy' ) {
          buys.push( this.createTrade( p, a ) );
          accountChange.hasBuys = true;
        } else if ( p.buySell === 'Sell' ) {
          sells.push( this.createTrade( p, a ) );
          accountChange.hasSells = true;
        }
      }
    }
  }

  private analyzeNewPosition( p: Position, op: Position, sells: any[], accountChange: AccountChange, buys: any[] ) {
    const diff = p.revised_quantity - op.revised_quantity;
    const unit = op.ticker_name.toLowerCase().includes( 'bond' ) ? 'bond(s)' : 'share(s)';
    if ( diff > 0 ) {
      buys.push( `Buy ${ this.format( diff, 'number' ) } ${ unit } (${ this.format( diff * p.price, 'currency' ) }) of ${ p.ticker }.` );
      accountChange.hasBuys = true;
    } else if ( diff < 0 ) {
      sells.push( `Sell ${ this.format( diff, 'number' ) } ${ unit } (${ this.format( diff * p.price, 'currency' ) }) of ${ p.ticker }.` );
      accountChange.hasSells = true;
    }
  }

  sortAccountChanges( a: AccountChange, b: AccountChange ) {
    // -1 means a should be before b and 1 means b should be before a
    // setup is always first
    if ( a.isSetup ) {
      return -1;
    }
    if ( b.isSetup ) {
      return 1;
    }
    // then sells
    if ( a.hasSells && !b.hasSells ) {
      return -1;
    }
    if ( a.hasSells && b.hasSells ) {
      return 0;
    }
    if ( !a.hasSells && b.hasSells ) {
      return 1;
    }
    // then transfers
    if ( a.hasTransfers && !b.hasTransfers ) {
      return -1;
    }
    if ( a.hasTransfers && b.hasTransfers ) {
      return 0;
    }
    if ( !a.hasTransfers && b.hasTransfers ) {
      return 1;
    }
    // if none of the above conditions happen, every other scenario is equal
    return 0;
  }

  private createTrade( p: Position, a: Account ) {
    const unit = a.account_type === 'Bond' ? 'bond(s)' : 'share(s)';
    return {
      text: `${ p.buySell } ${ this.format( p.revised_quantity, 'number' ) } ${ unit } (${ this.format( p.revised_difference, 'currency' ) }) of ${ p.ticker }.`,
    };
  }

  private createSaleAndTransfer( source: any, targetKey: any ) {
    return { text: `Sell asset and transfer proceeds of ${ this.format( source.transferTargetAccounts[ targetKey ], 'currency' ) } to ${ this.formatAccountName( targetKey ) }` };
  }

  private createTransfer( source: any, targetKey: any ) {
    return { text: `Transfer ${ this.format( source.transferTargetAccounts[ targetKey ], 'currency' ) } to ${ this.formatAccountName( targetKey ) }.` };
  }

  private createSecurityTransfer( source: any, targetKey: any ) {
    return { text: `Transfer ${ this.formatSecList( source.secTransfersTargetAccounts[ targetKey ] ) } to ${ this.formatAccountName( targetKey ) }.` };
  }

  private createLoanPaydown( diff ) {
    return { text: `Paydown ${ this.format( diff, 'currency' ) } ` };
  }

  private createSetup( a: Account ) {
    const change: PositionChange = {};
    if ( Util.accountIsInvestment( a ) ) {
      if ( a.account_type === 'Investment' ) {
        change.text = `Setup ${ Util.formatAccountDescription( a ) } ${ a.institution_name ? `at ${ a.institution_name }` : '' }`;
        change.positions = [];
        for ( const p of a.originalPositions ) {
          change.positions.push( this.createPositionSetup( p ) );
        }
      } else if ( Util.accountIsStock( a ) ) { // stock
        const position = a.originalPositions[ 0 ];
        change.text = `Setup ${ this.format( position.revised_quantity, 'decimal' ) } shares of ${ position.ticker_name } (${ position.ticker })`;
      } else if ( Util.accountIsStockOption( a ) ) { // stock
        const position = a.originalPositions[ 0 ];
        change.text = `Setup ${ this.format( position.revised_quantity, 'decimal' ) } of ${ position.underlying_stock } ${ position.call_put } option contracts`;
      } else if ( Util.accountIsManualBond( a ) ) { // bond
        const position = a.originalPositions[ 0 ];
        change.text = `Setup Bond ${ a.name } for ${ this.format( position.cost_basis, 'currency' ) }`;
      } else if ( Util.accountIsAnnuity( a ) ) {
        change.text = `${ a.annuity_type === 'annuity' ? 'Purchase' : 'Add' } ${ Util.formatAccountDescription( a ) }`;
        if ( a.purchasePrice ) {
          // get the source account and the add to the change text with the amount and account for the annuity purchase
          const sourceAccount = this._state.globalVars.accountDataGrids[ a.purchasePrice.source ].account;
          change.text = `${ change.text } with a purchase price of ${ this.format( a.purchasePrice.amount, 'currency' ) }
        from ${ Util.formatAccountDescription( sourceAccount, true ) }`;
        }
      }
    } else if ( Util.accountIsBanking( a ) ) {
      change.text = `Setup ${ Util.formatAccountDescription( a ) } with ${ this.format( a.valueAtSetup, 'currency' ) }`;
    } else if ( Util.accountIsRealAsset( a ) ) {
      change.text = `Purchase ${ Util.formatAccountDescription( a ) } for ${ this.format( a.positions[ 0 ].cost_basis, 'currency' ) } `;
      if ( a.downPayment ) {
        // get the source account and the add to the change text with the amount and account for the down payment of
        // the real asset purchase
        const sourceAccount = this._state.globalVars.accountDataGrids[ a.downPayment.source ].account;
        change.text = `${ change.text } with a down payment of ${ this.format( a.downPayment.amount, 'currency' ) }
        from ${ Util.formatAccountDescription( sourceAccount, true ) }`;
      }
    } else if ( Util.accountIsCreditCard( a ) ) {
      change.text = `Open ${ Util.formatAccountDescription( a ) }`;
    } else if ( Util.accountIsLoan( a ) ) {
      change.text = `Borrow ${ Util.formatAccountDescription( a ) } in the amount of ${ this.format( a.positions[ 0 ].original_loan_amount, 'currency' ) }`;
    }
    return change;
  }

  createPositionSetup( p: Position ) {
    return `Add ${ p.revised_quantity } shares of ${ p.ticker }`;
  }

  formatSecList( tickers ) {
    const lastSec = tickers.pop();
    return tickers.length === 0 ? lastSec : `${ tickers.join( ',' ) } and ${ lastSec }`;
  }

  format( num: number, type: string ) {
    if ( type === 'currency' ) {
      const ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
      return ripCurrencyPipe.transform( Math.abs( num ).toString() );
    } else {
      const ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
      return ripDecimalPipe.transform( Math.abs( num ), '0-2' );
    }
  }

  formatAccountName( id: string | number ) {
    const grid = this._state.globalVars.accountDataGrids[ id ];
    if ( grid ) {
      return Util.formatAccountDescription( grid.account, true, 30 );
    } else {
      return '';
    }
  }

  setupDeleteProgressButtons() {
    this.deleteButtonsOptions = {};
    for ( const r of this.userRevisions ) {
      this.deleteButtonsOptions[ r.id ] = {
        active: false,
        fa_icon: faTrash,
        buttonColor: 'warn',
        spinnerColor: 'primary',
        raised: true,
        mode: 'indeterminate',
        disabled: false,
      };
    }
  }

}
