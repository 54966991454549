<nb-card [nbSpinner]="transactionsState.loading">
    <nb-card-header>
        Details
    </nb-card-header>
    <nb-card-body>


        <div>
            <div class="toolbar-layout flexing">
                <!--
                <div class="expand-buttons">
                    <button mat-raised-button class="icon-button"
                            (click)="expandAll()"
                            matTooltip="Expand All"
                            matTooltipClass="mat-tooltip-custom">
                        <mat-icon>unfold_more</mat-icon>
                    </button>
                    <button mat-raised-button class="icon-button"
                            (click)="collapseAll()"
                            matTooltip="Collapse All"
                            matTooltipClass="mat-tooltip-custom">
                        <mat-icon>unfold_less</mat-icon>
                    </button>
                </div>
                -->
                <!--                <form>-->
                <div class="form-group flexing space-between" id="transactions-table-toolbar">


                    <mat-form-field appearance="outline" style="flex: 1;">
                        <mat-label>Filter by Category</mat-label>
                        <mat-select [(value)]="categoryLabel" (selectionChange)="categorySelectionChange()"
                                    panelClass="medium-panel">
                            <mat-option [value]="allCategories">
                                {{ allCategories.label }}
                            </mat-option>
                            <mat-optgroup>
                                Income Categories
                                <mat-option *ngFor="let c of incomeCategories " [value]="c">
                                    {{ c }}
                                </mat-option>
                            </mat-optgroup>
                            <mat-optgroup>
                                Expense Categories
                                <mat-option *ngFor="let c of expenseCategories " [value]="c">
                                    {{ c }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="flex: 2;">
                        <input matInput [formControl]="searchBoxControl"
                               placeholder="Search transactions"
                               name="searchTrans"
                               type="search"
                               (keyup)="filter()">
                        <mat-label>
                            Search
                        </mat-label>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="flex: 1;">
                        <mat-label>Filter by Account</mat-label>
                        <mat-select [(value)]="selectedAccount" (selectionChange)="filter()"
                                    panelClass="medium-panel">
                            <mat-option>{{ selectBoxNoValueLabel }}</mat-option>
                            <mat-option *ngFor="let a of accountList" [value]="a">
                                {{ transactionsState.accountMap[a] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--                </form>-->
                <!--
                 <div class="category-picker">
                    <mat-form-field>
                        <mat-select [(value)]="columnToGroupBy" (selectionChange)="setColumnsWhenGrouping($event)"
                                    placeholder="Group By">
                            <mat-option [value]="undefined">No Grouping</mat-option>
                            <mat-option [value]="col.prop"
                                        *ngFor="let col of columnsForGrouping">{{ col.name }}</mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>
                -->


            </div>
            <!--[groupRowsBy]="columnToGroupBy"-->
            <ngx-datatable #transactionsTable id="transactions-table"
                           class="material striped"
                           [rows]="filteredTransactions"
                           [columns]="columnsToShow"
                           [columnMode]="ColumnMode.force"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           [rowHeight]="45"
                           [scrollbarV]="true"
                           [reorderable]="reorderable"
                           [sorts]="[{ prop: 'date', dir: 'desc' }]">

                <!-- Group Header Template -->
                <!--
                <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader (toggle)="onDetailToggle($event)">
                    <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                        <div style="padding-left:5px;">
                    <span title="Expand/Collapse Group"
                          (click)="toggleExpandGroup(group)">
                        <mat-icon *ngIf="!expanded">expand_more</mat-icon>
                        <mat-icon *ngIf="expanded">expand_less</mat-icon>
                        <b>{{ group.value[0][columnToGroupBy] }}</b>
                    </span>
                        </div>
                    </ng-template>
                </ngx-datatable-group-header>
                -->

                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template
                                 let-rowCount="rowCount"
                                 let-pageSize="pageSize"
                                 let-curPage="curPage"
                                 let-offset="offset">
                        <div class="flexing space-between" style="width: 100%;">
                            <div>
                                Transactions: {{ rowCount }}
                            </div>
                            <div>
                                Total: {{ transactionsTotalDollar | ripCurrencyPipe }}
                            </div>
                            <div>
                                <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="curPage"
                                        [size]="pageSize"
                                        [count]="rowCount"
                                        [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="transactionsTable.onFooterPage($event)">
                                </datatable-pager>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>


            <ng-template ngx-datatable-cell-template #amountTemplate let-value="value" let-column="column"
                         let-row="row">
        <span [innerHtml]=" value.amount | ripCreditDebitPipe : row.baseType ">
        </span>
                <!--[ngClass]="{
                         'positive': row.baseType === 'CREDIT',
                         'negative': row.baseType === 'DEBIT'
                       }">-->

            </ng-template>


            <ng-template ngx-datatable-cell-template #descriptionTemplate let-value="value" let-rowIndex="rowIndex"
                         let-row="row">
                <span matTooltip="{{ value.original }}" matTooltipClass="mat-tooltip-custom">
                    {{ value.original }}
                </span>

            </ng-template>

            <ng-template ngx-datatable-cell-template #merchantTemplate let-value="value" let-rowIndex="rowIndex"
                         let-row="row">
                <span matTooltip="{{ value.name }}" matTooltipClass="mat-tooltip-custom">
                    <img src="{{ value.logoURL }}" height="20"/> {{ value.name }}
                </span>

            </ng-template>

        </div>

    </nb-card-body>
</nb-card>
