import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Util } from '../../../../utils/util.service';
import * as _ from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { GlobalState } from '../../../../global.state';
import { GlobalDataService } from '../../../../globalData';
import { Subject } from 'rxjs';
import { Auth } from '../../../../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountManager } from '../../../../utils/accountManager';
import { EVENT_NAMES } from '../../../../utils/enums';

@Component( {
  selector: 'rip-nickname-editor',
  template: `
    <div style="display: inline; margin-right: 10px;" *ngIf="!editing">

  <span class="account-name" title="{{ account.formattedDescription }}">
                  {{ formattedName }}
                </span>
    </div>
    <div *ngIf="editing" class="nickname-wrapper">
      <mat-form-field class="account-nickname">
        <input matInput
               placeholder="Nickname"
               id="{{account.name}}NicknameInput"
               [formControl]="nicknameFormControl">
      </mat-form-field>
    </div>
  `,
  styleUrls: [`./nicknameEditor.component.scss`],
} )

export class NicknameEditorComponent implements OnDestroy, AfterViewInit {

  private readonly onDestroy = new Subject<void>();

  @Input() account;
  @Input() editing: boolean = false;

  publicHelpers;

  nicknameFormControl: UntypedFormControl;

  formattedName: string = '';

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _gdService: GlobalDataService,
               private _auth: Auth,
               private _elRef: ElementRef,
               private snackBar: MatSnackBar ) {
    this.publicHelpers = Util;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }


  ngAfterViewInit(): void {
    setTimeout( () => {
      this.nicknameFormControl = new UntypedFormControl( this.account.description );
      this.formattedName = this.formatAccountName( this.account );
      // Logger.log( `formattedName: ${ this.formattedName }` );
    } );
  }

  edit() {
    this.editing = true;
  }

  updateNickname() {
    // get the new nickname from the form control
    const nickname = this.nicknameFormControl.value;
    // set the description on the global account
    const globalAccount: any = _.find( this._state.globalVars.allAccounts, { account_id: this.account.account_id } );
    if ( globalAccount ) {
      globalAccount.description = nickname;
    } else {
      // serious problem if globalAccount is undefined
    }
    // turn off the editing for this nickname
    this.editing = false;
    // get the override for the account
    const overrides = this._state.getAccountOverride( this.account );
    // check if there is already a nickname for this account
    if ( overrides.nickname ) {
      overrides.nickname.new = nickname; // don't need to store the old description since it's not the original
    } else {
      overrides.nickname = { new: nickname, old: this.account.description, oldName: this.account.name }; // let's hang on to the old description so we can reset it
    }

    this.account.description = nickname; // set the new nickname on the account so it shows up right away.
    this.account.name = '';

    // update the mapping on the backend
    this._gdService.updateUserAccountMapping( this._state.globalVars.accountMapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( resp: any ) => {
        // Logger.log(resp);
        this.snackBar.open( 'Account Nickname Updated', null, Util.getSnackBarOptions() );
        this._accountManager.setNameGlobally( nickname, this.account );
        this.formattedName = this.formatAccountName( this.account );
        this._state.notifyDataChanged( EVENT_NAMES.DETECT_ACCOUNT_CHANGES, {} );
      } );
  }

  cancelUpdateNickname() {
    // turn off editing for this account without saving the change
    this.editing = false;
    // reset the form control value
    this.nicknameFormControl.setValue( this.account.description );
  }

  resetNickname() {
    // get the overrides for the account
    const overrides = this._state.getAccountOverride( this.account );
    // the nickname is reset to the old one we stored the first time the user created a nickname
    const nickname = overrides.nickname.old;
    this.account.name = overrides.nickname.oldName;
    // set the form control value to the old nickname
    this.nicknameFormControl.setValue( nickname );
    // set the description on the global account
    const globalAccount: any = _.find( this._state.globalVars.allAccounts, { account_id: this.account.account_id } );
    if ( globalAccount ) {
      globalAccount.description = nickname;
    } else {
      // serious problem if globalAccount is undefined
    }
    this.editing = false;

    // delete the nickname override from the mapping
    delete overrides.nickname;

    // set the new nickname on the account so it shows up right away.
    this.account.description = nickname;

    // update the mapping on the backend
    this._gdService.updateUserAccountMapping( this._state.globalVars.accountMapping )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( resp: any ) => {
        // Logger.log(resp);
        this.snackBar.open( 'Account Nickname Updated', null, Util.getSnackBarOptions() );
        this._state.notifyDataChanged( EVENT_NAMES.DETECT_ACCOUNT_CHANGES, {} );
      } );
  }

  onEnterKey( event: any ) {
    this.updateNickname();
  }

  formatAccountName( account: any ) {
    const listItems = this._elRef.nativeElement.getElementsByClassName( 'connection-list-item' );
    if ( listItems && listItems.length > 0 ) {
      const width = listItems[0].scrollWidth;
      return Util.formatAccountDescription( account, true, width / 8 / 2 ); // 1 character is about 8 pixels wide. divide by 2 for half the list item width
    } else {
      return Util.formatAccountDescription( account, true );
    }

  }

}
