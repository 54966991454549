import { Pipe, PipeTransform } from '@angular/core';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { Observable } from 'rxjs';
import { AppStoreService } from '../../../store';
import { map } from 'rxjs/operators';
import { hasAnyPermissionFn, WorkspacePermission } from '../../../shared/workspace-permission';

@Pipe( {
  name: 'accountVisibilityBySettingPermission',
} )
export class AccountVisibilityBySettingPermissionPipe implements PipeTransform {

  constructor( private appStoreService: AppStoreService ) {
  }

  transform( accounts: Account[] ): Observable<Account[]> {
    return this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        map( allPerms => accounts.filter( acc => acc.included || !hasAnyPermissionFn( allPerms, [ WorkspacePermission.ACCOUNT_SETTINGS_NO_ACCESS ] ) ) ),
      );
  }

}
