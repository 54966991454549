import { AfterViewInit, Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import * as parseAddress from 'parse-address';


@Component( {
  selector: 'rip-address-autocomplete',
  template: `

    
      <mat-form-field appearance="{{addressFormat}}" class="address-autocomplete-mat-form-field">
        <input matInput type="text" placeholder="Address" [formControl]="$any(form.controls.address_line1)" #addressInput
               id="address-autocomplete-input{{idSuffix}}">
        <mat-error *ngIf="form?.controls?.address_line1?.hasError('required') && validate">
          Address line 1 is required
        </mat-error>
        <mat-label>
          Address
        </mat-label>
      </mat-form-field>
    
  `,

} )

export class AddressAutocompleteComponent implements AfterViewInit {


  @ViewChild( 'addressInput' ) addressInput: ElementRef;

  @Input() form: UntypedFormGroup;
  @Input() validate: boolean = false;
  @Output() addressChanged: EventEmitter<any> = new EventEmitter<any>();

  idSuffix: string = `_${ Math.random() }`;

  @Input() addressFormat: string = '';
  apiKey: string = environment.common.googleApiKey;

  constructor() {

  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.setup();
    } );

  }

  setup() {

    if ( window['google'] ) {

      const input = this.addressInput.nativeElement;

      const options = {
        // componentRestrictions: { country: "us" },
        fields: [ 'address_components', 'icon', 'name', 'formatted_address' ],
        strictBounds: false,
        // types: [ 'establishment' ],
      };

      const autocomplete = new window['google'].maps.places.Autocomplete( input, options );

      autocomplete.addListener( 'place_changed', () => {
        const place = autocomplete.getPlace();
        console.log( place );
        AddressAutocompleteComponent.translatePlaceToAddress( place.formatted_address, this.form );
        this.addressChanged.emit();
      } );
    } else {
      setTimeout( () => {
        this.setup();
      }, 500 );
    }
  }

  static translatePlaceToAddress( formattedAddress: string, form: UntypedFormGroup ) {

    const parsed = parseAddress.parseLocation( formattedAddress );

    console.log( parsed );

    if ( parsed.number && parsed.street ) {
      form.controls.address_line1.setValue( AddressAutocompleteComponent.combineParsedStreetAddress( parsed ) );
    }

    if ( parsed.city ) {
      form.controls.address_city.setValue( parsed.city );
    }
    if ( parsed.zip ) {
      form.controls.address_zip.setValue( parsed.zip );
    }
    if ( parsed.state ) {
      form.controls.address_state.setValue( parsed.state );
    }
  }

  static combineParsedStreetAddress( parsed ) {
    let address = ``;
    if ( parsed.number ) {
      address = `${ parsed.number }`;
    }
    if ( parsed.prefix ) {
      address = `${ address } ${ parsed.prefix }`;
    }

    if ( parsed.street ) {
      address = `${ address } ${ parsed.street }`;
    }

    if ( parsed.type ) {
      address = `${ address } ${ parsed.type }`;
    }

    return address;
  }
}
