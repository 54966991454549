import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Util } from '../../../../../utils/util.service';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../../../../../theme/pipes';
import * as _ from 'lodash-es';
import { GlobalState } from '../../../../../global.state';
import { MortgageHelpers } from '../../../../../utils/mortgageHelpers';
import { BondHelpers } from '../../../../../utils/bondHelpers';
import { environment } from '../../../../../../environments/environment';
import { AccountManager } from '../../../../../utils/accountManager';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { MobileUtil } from '../../../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faHandHoldingUsd, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ManualAccountFormsHelper } from '../../manualAccountFormsHelper';
import { Account, FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-loan-form',
  templateUrl: './loanForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )
export class LoanFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account;
  @Input() amortizationType = 'fully-amortizing';
  @Input() addingInRevision: boolean = false;
  @Input() hideCorrespondingAsset: boolean = false;

  @ViewChild( 'selectedLoanTermUnits', { static: false } ) selectedLoanTermUnits: MatButtonToggleGroup;

  faInfoCircle = faInfoCircle;
  faHandHoldingUsd = faHandHoldingUsd;


  zillowLogoUrl: string;
  zillowRates;

  publicUtil: typeof Util;

  allAssetAccounts: Account[] = [];

  deviceIsMobile: boolean = false;

  hasCorrespondingAsset: boolean;

  subscriberName: string = 'loanForm';

  bondCouponFrequencySelectOptions: any[] = ManualAccountFormsHelper.getFrequencySelectOptions();

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private _deviceDetector: DeviceDetectorService,
               private _elRef: ElementRef ) {
    super();
    this.zillowLogoUrl = environment.common.zillowLogoUrl;
    this.zillowRates = BondHelpers.formatZillowRates( this._state.globalVars.mortgageRates );
    this.publicUtil = Util;

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _deviceDetector );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    this.form.controls.value.disable();
    // this.form.controls.treasury_rate.disable();
    // this.form.controls.modified_duration.disable();
    // this.form.controls.maturity_in_years.disable();

    this.getAllAssetAccounts();

    if ( this.account && this.form ) {
      ManualAccountUtil.patchLoanForm( this.form, this.account, this.selectedLoanTermUnits );

      if ( this.type !== 'Private Lending' && !this.hideCorrespondingAsset ) {
        ManualAccountUtil.checkForCorrespondingAccount( this.account, this.form, this.allAssetAccounts, 'corresponding_asset_id' );
        if ( this.form.controls.corresponding_asset_id.value?.length > 0 ) {
          this.hasCorrespondingAsset = !!this._accountManager.getOriginalAccountFromId( this.form.controls.corresponding_asset_id.value );
        }
      }

    } else {
      // this is a new account
      // defaults:
      this.selectedLoanTermUnits.value = 'years';
      this.form.controls.credit_quality.setValue( 'aaa' );
      this.form.controls.sector.setValue( 'bond_primary_sector_government_related' );
      this.form.controls.us_non_us.setValue( 'us' );
      this.autofocus();
    }
  }


  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  calculatedRemainingBalance = 0;
  paydownAmount = 0;

  updatePaydownAmount() {
    if ( this.form.controls.loan_principal_paydown.value ) {
      this.paydownAmount = Math.max( 0, FormsUtil.getSanitizedFloatValue( this.form.controls.loan_principal_paydown.value, false ) );
    }
  }

  calculatePaydownAmount() {
    // store the paydownAmount (difference between calculated and supplied remaining balance) to be stored in the database for future calculations
    // to do: determine what to do if the user supplied an amount greater than the calculated remaining balance
    if ( this.calculatedRemainingBalance && this.form.controls.outstanding_balance.value ) {
      this.paydownAmount = Math.max( 0, this.calculatedRemainingBalance - FormsUtil.getSanitizedFloatValue( this.form.controls.outstanding_balance.value, false ) );
      Util.setFormattedValue( 'loan_principal_paydown', 'Currency', this.paydownAmount, this.form );
    }
  }

// we are just setting this in the setAccountAndPositionFields function in manualAccountManager
  /*  setCostBasis() {
   if ( this.form.controls.outstanding_balance.value ) {
   let cb = FormsUtil.getSanitizedFloatValue( this.form.controls.outstanding_balance.value, false );
   if ( this.isDebt() ) {
   cb = Math.abs( cb ) * -1;
   }
   Util.setFormattedValue( 'cost_basis', 'Currency', cb, this.form );
   }
   }*/

  /*  updateDurationMatchedTreasuryRate() {
   if ( this.form.controls.modified_duration.value ) {
   const treasuryRate = BondHelpers.getDurationMatchedTreasuryRateInPercent( this.form.controls.modified_duration.value );
   Util.setFormattedValue( 'treasury_rate', 'Percent', treasuryRate, this.form );
   }
   }*/

  calculatePriceAndDuration() {
    if (
      this.form.controls.loan_term_in_months.value &&
      this.form.controls.original_loan_amount.value && this.form.controls.current_market_rate.value &&
      this.form.controls.coupon.value && this.form.controls.coupon_frequency.value &&
      this.form.controls.loan_origination_date.value && this.form.controls.maturity_date.value ) {

      const mortgageAnalyticOutputs = MortgageHelpers.calcMortgageAnalyticValues(
        FormsUtil.getSanitizedFloatValue( this.form.controls.original_loan_amount.value, false ),
        '',
        new Date( this.form.controls.loan_origination_date.value ),
        new Date( this.form.controls.maturity_date.value ),
        new Date(),
        FormsUtil.getSanitizedFloatValue( this.form.controls.coupon.value, true ),
        this.form.controls.loan_term_in_months.value / 12,
        FormsUtil.getSanitizedFloatValue( this.form.controls.current_market_rate.value, true ),
        this.paydownAmount,
        this.form.controls.coupon_frequency.value );

      /*      const duration = MortgageHelpers.calcDuration(
       FormsUtil.getSanitizedFloatValue( this.form.controls.original_loan_amount.value, false ),
       '',
       new Date( this.form.controls.loan_origination_date.value ),
       new Date( this.form.controls.maturity_date.value ),
       new Date(),
       FormsUtil.getSanitizedFloatValue( this.form.controls.coupon.value, true ),
       this.form.controls.loan_term_in_months.value / 12,
       FormsUtil.getSanitizedFloatValue( this.form.controls.current_market_rate.value, true ),
       this.paydownAmount,
       this.form.controls.coupon_frequency.value );*/

      const pv = this.isDebt() ? -mortgageAnalyticOutputs.presentValue : mortgageAnalyticOutputs.presentValue;


      Util.setFormattedValue( 'price', 'Currency', pv, this.form );
      // Util.setFormattedValue( 'maturity_in_years', 'Decimal', mortgageAnalyticOutputs.weightedAvgLife, this.form );

      // store remaining balance and re-calc paydown if applicable
      this.calculatedRemainingBalance = mortgageAnalyticOutputs.outstandingBalance + this.paydownAmount; // total implied remaining balance without
                                                                                                         // paydown
      if ( this.paydownAmount === 0 ) {
        // only store the calculated remaining balance if it hasn't already been overwritten by the user
        Util.setFormattedValue( 'outstanding_balance', 'Currency', this.calculatedRemainingBalance, this.form );
      } else {
        // recalculate paydown based on current valuation for remaining loan balance
        this.calculatePaydownAmount();
        if ( !this.form.controls.outstanding_balance.value ) {
          // store remaining balance in the form if it doesn't exist
          Util.setFormattedValue( 'outstanding_balance', 'Currency', mortgageAnalyticOutputs.outstandingBalance, this.form );
        }
      }
      // Util.setFormattedValue( 'modified_duration', 'Decimal', duration, this.form ); // to do: calc duration
      // this.updateDurationMatchedTreasuryRate();
      // this.setCostBasis();

      // this.calculateTimeToMaturityInYears(); is this needed?

      // after calculating price also recalculate market value
      BondHelpers.calculateMarketValue( this.form );
    }
  }

  getAllAssetAccounts() {
    this.allAssetAccounts = _.filter(
      this.addingInRevision ? this._accountManager.getAllRevisableAccounts() : this._accountManager.getAllOriginalAccounts(),
      ( a: any ) => {
        return a.account_category.toUpperCase() !== 'LOAN';
      } ) || [];
  }

  chooseRate( rate: any ) {
    this.form.controls.current_market_rate.setValue( rate.refi.rate * 100 );
    Util.updateInputPercentFormat( 'current_market_rate', this.form, true );
    this.currentRateChanged();
  }

  originationDateChanged() {
    BondHelpers.calcMaturityDateFromLoanTerm( this.form, this.selectedLoanTermUnits.value );
    this.calculatePriceAndDuration();
    this.getClosestRate();
  }

  termChanged() {

    if ( this.selectedLoanTermUnits.value === 'years' ) {
      this.form.controls.loan_term_in_months.setValue( this.form.controls.loan_term.value * 12 );
    } else {
      this.form.controls.loan_term_in_months.setValue( this.form.controls.loan_term.value );
    }

    BondHelpers.calcMaturityDateFromLoanTerm( this.form, this.selectedLoanTermUnits.value );
    BondHelpers.calcOriginationDate( this.form, this.selectedLoanTermUnits.value );
    this.calculatePriceAndDuration();
    this.getClosestRate();
  }

  termUnitChanged() {
    if ( this.selectedLoanTermUnits.value === 'years' ) {
      this.form.controls.loan_term.setValue( this.form.controls.loan_term.value / 12 );
    } else {
      this.form.controls.loan_term.setValue( this.form.controls.loan_term.value * 12 );
    }
    this.termChanged();
  }

  maturityDateChanged() {
    BondHelpers.calcLoanTermFromMaturityDate( this.form, this.selectedLoanTermUnits );
    BondHelpers.calcOriginationDate( this.form, this.selectedLoanTermUnits.value );
    this.calculatePriceAndDuration();
    BondHelpers.calculateTimeToMaturityInYears( this.form );
    this.getClosestRate();
  }

  originalLoanAmountChanged() {
    Util.updateInputCurrencyFormat( 'original_loan_amount', this.form );
    this.calculatePriceAndDuration();
  }

  couponChanged() {
    Util.updateInputPercentFormat( 'coupon', this.form, true );
    if ( FormsUtil.getSanitizedFloatValue( this.form.controls.current_market_rate.value, true ) === 0 && !this.addingInRevision ) {
      this.getClosestRate();
    } else {
      this.setCurrentRateToCoupon();
    }
    this.calculatePriceAndDuration();
  }

  remainingLoanBalanceChanged() {
    Util.updateInputCurrencyFormat( 'outstanding_balance', this.form );
    this.calculatePaydownAmount();
    this.calculatePriceAndDuration();
    // try and focus on today's rate
  }

  paydownChanged() {
    this.updatePaydownAmount();
    this.calculatePriceAndDuration();
  }

  currentRateChanged() {
    Util.updateInputPercentFormat( 'current_market_rate', this.form, true );
    this.calculatePriceAndDuration();
  }

  priceChanged() {
    if ( this.isDebt() ) {
      this.form.controls.price.setValue( Math.abs( this.form.controls.price.value ) * -1 );
    }
    Util.updateInputCurrencyFormat( 'price', this.form );
    BondHelpers.calculateMarketValue( this.form );
  }

  quantityChanged() {
    Util.updateInputDecimalFormat( 'quantity', this.form );
    BondHelpers.calculateMarketValue( this.form );
  }

  isMortgage() {
    return this.type === 'Mortgage Loan' || this.type === 'Private Lending';
  }

  isDebt() {
    return this.type === 'Mortgage Loan' || this.type === 'Auto Loan';
  }

  getClosestRate() {
    // if mortgage or not in revision, try to get today's rate, unless the closestRate is 0, meaning today's rate couldn't be retrieved
    if ( this.isMortgage() || !this._state.globalVars.editing ) {
      const closestRate = BondHelpers.getTodaysRate( this.form.controls.maturity_date.value, this._state.globalVars.mortgageRates ) * 100;
      if ( closestRate !== 0 && !this.addingInRevision ) {
        this.form.controls.current_market_rate.setValue( closestRate );
        this.currentRateChanged();
      } else {
        this.setCurrentRateToCoupon();
      }
    } else {
      this.setCurrentRateToCoupon();
    }

  }

  setCurrentRateToCoupon() {
    if ( this.form.controls.coupon.value ) {
      this.form.controls.current_market_rate.setValue( this.form.controls.coupon.value );
      this.currentRateChanged();
    }
  }

}
