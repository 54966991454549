import { Component, Input } from '@angular/core';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';

@Component( {
  selector: 'rip-override-legend',
  template: `
    <div>
      <div class="missing-data">
        <fa-icon [icon]="faSquare" size="1x" fixedWidth="true" *ngIf="align === 'left'"></fa-icon>
        Missing Data
        <fa-icon [icon]="faSquare" size="1x" fixedWidth="true" *ngIf="align === 'right'"></fa-icon>
      </div>
      <div class="override-in-place">
        <fa-icon [icon]="faSquare" size="1x" fixedWidth="true" *ngIf="align === 'left'"></fa-icon>
        Override in Place
        <fa-icon [icon]="faSquare" size="1x" fixedWidth="true" *ngIf="align === 'right'"></fa-icon>
      </div>
      <div class="original-value">
        <fa-icon [icon]="faSquare" size="1x" fixedWidth="true" *ngIf="align === 'left'"></fa-icon>
        Original Value
        <fa-icon [icon]="faSquare" size="1x" fixedWidth="true" *ngIf="align === 'right'"></fa-icon>
      </div>
    </div>
  `,

} )

export class OverrideLegendComponent {

  faSquare = faSquare;

  @Input() align: string = 'right';
}
