import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppStoreService } from '../../../store';
import { hasAnyPermissionFn } from '../has-any-permission-fn';
import { LibWorkspacePermissionModule } from '../workspace-permission.module';
import { WorkspacePermissionNameStore } from '../../../store/workspace';

@Injectable( {
  providedIn: LibWorkspacePermissionModule,
} )
export class WorkspacePermissionService {

  constructor(
    private appStoreService: AppStoreService,
  ) {
  }

  hasAny( value: WorkspacePermissionNameStore | WorkspacePermissionNameStore[] ): Observable<boolean> {
    return this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        take( 1 ),
        map( all => hasAnyPermissionFn( all, value ) ),
      );
  }
}
