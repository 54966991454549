import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnSet } from '../../../../../../../global.state';

@Component({
  selector: 'rip-screener-nav',
  template: `
    <div class="selection-header-wrapper">
      <div class="screener-selection-header">
        <span>{{selectionHeader}}</span>
      </div>
      <div class="screener-nav-btns">
        <button mat-button (click)="goBackOneStep.emit()" *ngIf="!hideNavButtons">Go Back</button>
        <button mat-button (click)="startOver.emit()" *ngIf="!hideNavButtons">Start Over</button>

        <button mat-raised-button type="button" *ngIf="showAddButton"
                [disabled]="addButtonDisabled"
                color="primary"
                (click)="addSelectedFunds.emit()">
          Add/Choose Selected Investment(s)
        </button>

      </div>

      <button mat-raised-button [matMenuTriggerFor]="columnMenu" class="column-menu" *ngIf="columnSets">
        {{selectedColumnSet.label}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #columnMenu>
        <button mat-menu-item *ngFor="let set of columnSets" (click)="selectColumnSet.emit(set)">
          {{ set.label }}
        </button>
      </mat-menu>
    </div>
  `,
})

export class ScreenerNavComponent {

  @Input() selectionHeader: string = '';
  @Input() hideNavButtons: boolean = false;
  @Input() selectedColumnSet: ColumnSet;
  @Input() columnSets: ColumnSet[];
  @Input() addButtonDisabled: boolean = true;
  @Input() showAddButton: boolean = false;

  @Output() goBackOneStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() startOver: EventEmitter<any> = new EventEmitter<any>();
  @Output() addSelectedFunds: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectColumnSet: EventEmitter<ColumnSet> = new EventEmitter<ColumnSet>();

}
