import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Transaction } from './dataInterfaces';
import { TransactionsService } from '../theme/services';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalState } from '../global.state';
import { ChartColorUtil } from './chart-color.util';
import { Util } from './util.service';
import {
  faAd,
  faAnalytics,
  faBadgeDollar,
  faBadgePercent,
  faBagsShopping,
  faBolt,
  faBriefcase,
  faBrowser,
  faBuilding,
  faCar,
  faCarCrash,
  faChild,
  faDollarSign,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faGamepadAlt,
  faGifts,
  faGraduationCap,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHandsUsd,
  faHotTub,
  faHouse,
  faHouseDay,
  faMedkit,
  faMobile,
  faMoneyBillWave,
  faMoneyBillWaveAlt,
  faMoneyCheckAlt,
  faMoneyCheckEditAlt,
  faOilCan,
  faPlaneDeparture,
  faPopcorn,
  faReceipt,
  faRepeat,
  faSackDollar,
  faSatelliteDish,
  faSearchDollar,
  faShippingFast,
  faShoppingCart,
  faTools,
  faTreasureChest,
  faTshirt,
  faUmbrellaBeach,
  faUsdCircle,
  faUtensilsAlt,
} from '@fortawesome/pro-light-svg-icons';
import { ChartData, ChartDataset, ChartType } from 'chart.js';
import { environment } from '../../environments/environment';
import { EVENT_NAMES, GoalColors } from './enums';
import { Logger } from './logger.service';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Moment } from 'moment/moment';

@Injectable()
export class TransactionsState implements OnDestroy {


  faAnalytics: IconDefinition = faAnalytics;
  faDollarSign: IconDefinition = faDollarSign;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  readonly changes: Subject<void> = new Subject<void>();

  allTransactionsIncludingHidden: Transaction[] = [];
  allTransactions: Transaction[] = [];
  transactionCount: number;
  totalPages: number;
  completionCounter: number = 0;

  loading: boolean = true;
  errorLoadingTransactions: boolean = false;

  dateRange;
  datePickerForm: UntypedFormGroup;
  minDate: moment.Moment = moment().subtract( 2, 'years' );
  maxDate: moment.Moment = moment();

  minDateReturned;
  maxDateReturned;

  dateRangeRadioOptions: any[] = [
    { value: { unitsBack: '1', unit: 'month' }, label: '1M' },
    { value: { unitsBack: '3', unit: 'month' }, label: '3M' },
    { value: { unitsBack: '6', unit: 'month' }, label: '6M' },
    { value: { unitsBack: '1', unit: 'year' }, label: '1Y' },
  ];
  currentDateRangeOption: any;
  accountMap: any = {};
  categoriesMap: any = {};

  subscriberName: string = 'transactionsState';

  // Net Income Bar Chart setup
  netBarChartLabels = [];
  netBarChartData: ChartData = {
    labels: [],
    datasets: [],
  };

  // Expenses Doughnut Chart setup
  expenseDoughnutChartLabels: string[];
  expensesDoughnutChartData: ChartData = {
    labels: [],
    datasets: [],
  };
  doughnutChartType: ChartType = 'doughnut';

  // Income Doughnut Chart setup
  incomeDoughnutChartLabels: string[];
  incomeDoughnutChartData: ChartData = {
    labels: [],
    datasets: [],
  };
  incomeDoughnutChartType: ChartType = 'doughnut';


  incomeMap: Map<string, any> = new Map<string, any>();
  incomeCategories: any[] = [];
  incomeTotal: number;

  expenseMap: Map<string, any> = new Map<string, any>();
  expenseCategories: any[] = [];
  expenseTotal: number;


  monthlyMap: any = {};

  transactionsHaveBeenLoadedAtLeastOnce: boolean = false;

  constructor( private transactionsService: TransactionsService, private _state: GlobalState ) {

    this.resetDateRange();

    this._state.subscribe( [
      EVENT_NAMES.ACCOUNT_VISIBILITY_CHANGED,
    ].join( ' | ' ), () => {
      this.initialProcess();
      this.processTransactions();
      this.changes.next();
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_transactionsState' ] = this;
    }
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.ACCOUNT_VISIBILITY_CHANGED,
    ].join( ' | ' ), this.subscriberName );

    this.onDestroy.next();
  }

  getTransactionCount() {
    this.loading = true;
    const dateRange = {
      // going back 5 years for now, may tune to a shorter or longer timespan
      fromDate: moment().subtract( 5, 'year' ).format( 'YYYY-MM-DD' ),
      toDate: moment().format( 'YYYY-MM-DD' ),
    };

    this.transactionsService.getTransactionCount( dateRange )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this.transactionCount = resp.data;
          this.getAllPagesOfTransactions( dateRange );
        },
        error: ( err ) => {
          console.error( err );
          this.errorLoadingTransactions = true;
          this.loading = false;
        },
      } );
  }

  getAllPagesOfTransactions( params: any ) {
    this.allTransactionsIncludingHidden = [];

    let skip = 500; // has to be 500, that is the max yodlee returns at once
    this.completionCounter = 0;
    this.totalPages = this.transactionCount / skip;

    if ( this.totalPages === 0 ) {
      this.loading = false;
      return;
    }

    for ( let i = 0; i < this.totalPages; i++ ) {
      this.getTransactions( Object.assign( { skip: skip * i }, params ) );
    }
  }

  transactionsRetrieved() {

    if ( this.completionCounter >= this.totalPages ) {
      this.initialProcess();

      this.loading = false;
      this.errorLoadingTransactions = false;

      this.transactionsHaveBeenLoadedAtLeastOnce = true; // this will keep getting set, but we only care in the beginning
    } else {
      // console.log( 'transaction page counter: ', this.completionCounter );
    }

  }

  getTransactions( params: any ): Subscription {

    return this.transactionsService.getTransactions( params )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this.allTransactionsIncludingHidden.push( ...resp.data );
          this.completionCounter++;
          this.transactionsRetrieved();
        }, error: ( err ) => {
          console.error( err );
          this.errorLoadingTransactions = true;
          this.loading = false;
        },
      } );
  }

  initialProcess() {
    this.categoriesMap = {};
    this.setAccountMap();

    let minDateReturned: Moment, maxDateReturned: Moment;

    this.allTransactions = this.allTransactionsIncludingHidden.filter( ( t: Transaction ) => {
      // do some useful stuff to the data while filtering
      t.accountName = this.accountMap[ t.accountId ];
      this.categoriesMap[ t.category ] = true;

      // process date range
      const transactionDate = moment( t.date );

      if ( !minDateReturned || transactionDate.isBefore( minDateReturned ) ) {
        minDateReturned = transactionDate;
      }

      if ( !maxDateReturned || transactionDate.isAfter( maxDateReturned ) ) {
        maxDateReturned = transactionDate;
      }

      // only want to include stuff in the list that is in the accountMap (accounts that are included)
      return this.accountMap[ t.accountId ];
    } );

    this.minDateReturned = minDateReturned;
    this.maxDateReturned = maxDateReturned;


    // console.log( this.allTransactions[0] );

  }

  resetDateRange() {
    this.dateRange = {
      fromDate: moment().subtract( 90, 'days' ).format( 'YYYY-MM-DD' ),
      toDate: this.maxDate.format( 'YYYY-MM-DD' ),
    };
    this.currentDateRangeOption = this.dateRangeRadioOptions[ 1 ];
    this.datePickerForm = new UntypedFormGroup( {
      'fromDate': new UntypedFormControl( this.dateRange.fromDate ),
      'toDate': new UntypedFormControl( this.dateRange.toDate ),
    } );
  }

  checkDatePickers() {
    this.dateRange.fromDate = this.getFormattedDate( this.datePickerForm.controls.fromDate );
    this.dateRange.toDate = this.getFormattedDate( this.datePickerForm.controls.toDate );

    this.processTransactions();
  }

  getFormattedDate( control: AbstractControl ) {
    return moment( control?.value ).format( 'YYYY-MM-DD' );
  }

  changeSeriesRange() {
    if ( this.currentDateRangeOption ) {
      this.dateRange = {
        fromDate: moment().subtract(
          this.currentDateRangeOption.unitsBack,
          this.currentDateRangeOption.unit )
          .format( 'YYYY-MM-DD' ),
        toDate: this.maxDate.format( 'YYYY-MM-DD' ),
      };
      this.datePickerForm = new UntypedFormGroup( {
        'fromDate': new UntypedFormControl( this.dateRange.fromDate ),
        'toDate': new UntypedFormControl( this.dateRange.toDate ),
      } );
      this.processTransactions();
    }
  }

  setAccountMap() {
    // allAccounts should not include hidden accounts
    this.accountMap = {};

    for ( const a of this._state.globalVars.allAccounts ) {
      this.accountMap[ a.account_id ] = a.formattedDescription;
    }
  }

  processTransactions() {
    this.loading = true;

    // only want to look at transactions in this date range
    const fromDate: moment.Moment = moment( this.dateRange.fromDate );
    const toDate: moment.Moment = moment( this.dateRange.toDate );


    this.incomeMap = new Map<string, any>();
    this.expenseMap = new Map<string, any>();

    this.monthlyMap = {};

    // create totals
    this.incomeTotal = 0;
    this.expenseTotal = 0;


    // start loop of transactions
    this.allTransactions.forEach( ( transaction ) => {
      const transactionDate = moment( transaction.date );
      if ( Util.isInDateRange( transactionDate, fromDate, toDate ) ) {

        const monthlyMapKey = `${ transactionDate.month() }-${ transactionDate.year() }`;

        if ( !this.monthlyMap[ monthlyMapKey ] ) {
          this.monthlyMap[ monthlyMapKey ] = {
            incomeTotal: 0,
            expenseTotal: 0,
            net: 0,
          };
        }

        // All credits
        if ( transaction.baseType === 'CREDIT' ) {

          // if ( transaction.categoryType === 'INCOME' ) {
          const categoryObject = this.incomeMap.get( transaction.category );

          // a first check to look at category or type or something and say, this should really go in a different specific category

          if ( categoryObject === undefined ) {
            const iconInfo: any = this.getCategoryIcon( transaction.category );
            this.incomeMap.set( transaction.category, {
              total: transaction.amount.amount,
              label: transaction.category,
              icon: iconInfo.icon ? iconInfo.icon : iconInfo,
              color: iconInfo.color ? iconInfo.color : ChartColorUtil.getRandomColor(),
              transactions: [ transaction ],
              baseType: 'CREDIT',
            } );
          } else {
            categoryObject.total += transaction.amount.amount;
            categoryObject.transactions.push( transaction );
            this.incomeMap.set( transaction.category, categoryObject );
          }
          this.incomeTotal += transaction.amount.amount;
          this.monthlyMap[ monthlyMapKey ].incomeTotal += transaction.amount.amount;
          this.monthlyMap[ monthlyMapKey ].net += transaction.amount.amount;
          // }

        } else {
          // all debits

          if ( transaction.baseType === 'DEBIT' ) {

            // if ( transaction.categoryType === 'EXPENSE' ) {
            const categoryObject = this.expenseMap.get( transaction.category );

            // a first check to look at category or type or something and say, this should really go in a different specific category

            if ( categoryObject === undefined ) {
              const iconInfo: any = this.getCategoryIcon( transaction.category );
              this.expenseMap.set( transaction.category, {
                total: transaction.amount.amount,
                label: transaction.category,
                icon: iconInfo.icon ? iconInfo.icon : iconInfo,
                color: iconInfo.color ? iconInfo.color : ChartColorUtil.getRandomColor(),
                transactions: [ transaction ],
                baseType: 'DEBIT',
              } );
            } else {
              categoryObject.total += transaction.amount.amount;
              categoryObject.transactions.push( transaction );
              this.expenseMap.set( transaction.category, categoryObject );
            }

            this.expenseTotal += transaction.amount.amount;
            this.monthlyMap[ monthlyMapKey ].expenseTotal += transaction.amount.amount;
            this.monthlyMap[ monthlyMapKey ].net -= transaction.amount.amount;
            // }

          }
        }
      }


    } ); // end forEach


    // bar Chart datasets
    const incomeDataset: ChartDataset = {
      data: [],
      label: 'Inflows',
      fill: true,
      stack: 'a',
      barPercentage: 0.5,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      backgroundColor: GoalColors.Indigo,
      borderColor: GoalColors.Indigo,
      pointBackgroundColor: GoalColors.Indigo,
      pointBorderColor: GoalColors.Indigo,
      pointHoverBackgroundColor: GoalColors.Indigo,
      pointHoverBorderColor: GoalColors.Indigo,
      order: 2,
    };
    const expenseDataset: ChartDataset = {
      data: [],
      label: 'Outflows',
      fill: true,
      stack: 'a',
      barPercentage: 0.5,
      borderRadius: {
        bottomLeft: 5,
        bottomRight: 5,
      },
      backgroundColor: GoalColors.Pink,
      borderColor: GoalColors.Pink,
      pointBackgroundColor: GoalColors.Pink,
      pointBorderColor: GoalColors.Pink,
      pointHoverBackgroundColor: GoalColors.Pink,
      pointHoverBorderColor: GoalColors.Pink,
      order: 2,

    };
    const netDataset: ChartDataset = {
      data: [],
      label: 'Net',
      fill: false,
      type: 'line',
      tension: 0.5,
      backgroundColor: GoalColors.Amber,
      borderColor: GoalColors.Amber,
      pointBackgroundColor: GoalColors.Amber,
      pointBorderColor: GoalColors.Amber,
      pointHoverBackgroundColor: GoalColors.Amber,
      pointHoverBorderColor: GoalColors.Amber,
      order: 1,
      borderWidth: 4,
    };

    this.netBarChartLabels = [];
    this.netBarChartData.labels = [];

    const makeMomentFromKey = ( key: string ) => {
      const mon = parseInt( key.split( '-' )[ 0 ] );
      const year = parseInt( key.split( '-' )[ 1 ] );

      return moment( { year, month: mon } ).startOf( 'month' );
    };

    const keys: string[] = Object.keys( this.monthlyMap );
    keys.sort( ( a: string, b: string ) => {
      const first: Moment = makeMomentFromKey( a );
      const second: Moment = makeMomentFromKey( b );
      return first.isBefore( second ) ? -1 : 1;

    } );

    for ( const key of keys ) {
      const momentObject = makeMomentFromKey( key );
      this.netBarChartLabels.push( momentObject );
      this.netBarChartData.labels.push( momentObject );

      incomeDataset.data.push( this.monthlyMap[ key ].incomeTotal );

      expenseDataset.data.push( -this.monthlyMap[ key ].expenseTotal );

      netDataset.data.push( this.monthlyMap[ key ].net );

      /*incomeDataset.data.push( { x: momentObject, y: this.monthlyMap[key].incomeTotal } );

       expenseDataset.data.push( { x: momentObject, y: -this.monthlyMap[key].expenseTotal } );

       netDataset.data.push( { x: momentObject, y: this.monthlyMap[key].net } );*/
    }
// populate data for net income chart
    this.netBarChartData.datasets = [ incomeDataset, expenseDataset, netDataset ];


    Logger.log( this.incomeMap );
    this.incomeCategories = Array.from( this.incomeMap.values() );

    Logger.log( this.expenseMap );
    this.expenseCategories = Array.from( this.expenseMap.values() );

    // populate Expense Donut Chartdata array


    // const colors = ChartColorUtil.getColorGradients(this.expenseCategories.length);
    const expenseColors = [];
    const expenseChartData = [];
    const expenseChartLabels = [];
    this.expenseCategories.forEach( ( c, index ) => {
      expenseChartData.push( c.total );
      expenseChartLabels.push( c.label );
      expenseColors[ index ] = c.color;
    } );

    this.expensesDoughnutChartData.labels = expenseChartLabels;
    this.expenseDoughnutChartLabels = expenseChartLabels;
    this.expensesDoughnutChartData.datasets = [ {
      data: expenseChartData,
      backgroundColor: expenseColors,
      hoverBackgroundColor: expenseColors,
      hoverOffset: 20,
    } ];


    // populate Income Donut Chart data array

    const icolors = [];
    const incomeChartData = [];
    const incomeChartLabels = [];
    this.incomeCategories.forEach( ( c, index ) => {
      incomeChartData.push( c.total );
      incomeChartLabels.push( c.label );
      icolors[ index ] = c.color;
    } );

    this.incomeDoughnutChartData.labels = incomeChartLabels;
    this.incomeDoughnutChartLabels = incomeChartLabels;
    this.incomeDoughnutChartData.datasets = [ {
      data: incomeChartData,
      backgroundColor: icolors,
      hoverBackgroundColor: icolors,
      hoverOffset: 20,
    } ];

    // TODO: is some kind of event that processing is done needed?

    this.loading = false;
  }

  getCategoryIcon( category: string ) {
    switch ( category ) {
      // income categories
      case 'Paychecks/Salary':
        return { icon: faUsdCircle, color: '#90EE90' };
      case 'Rewards':
        return { icon: faTreasureChest, color: '#009DCE' };
      case 'Deposits':
        return { icon: faMoneyCheckAlt, color: '#0097FA' };
      case 'Interest':
        return { icon: faBadgePercent, color: '#008D7C' };
      case 'Other Income':
        return { icon: faBadgeDollar, color: '#7193BC' };
      case 'Investment Income':
        return { icon: faAnalytics, color: '#35C191' };
      case 'Expense Reimbursement':
        return { icon: faReceipt, color: '#9364AF' };
      case 'Consulting':
        return { icon: faBriefcase, color: '#7193BC' };
      case 'Retirement Income':
        return { icon: faUmbrellaBeach, color: '#36C190' };
      case 'Refunds/Adjustments':
        return { icon: faHandsUsd, color: '#008A5E' };
      case 'Sales':
        return { icon: faMoneyBillWaveAlt, color: '#008A5E' };
      case 'Services':
        return { icon: faHandsUsd, color: '#EEE8A9' };

      // Expense Categories
      case 'Travel':
        return { icon: faPlaneDeparture, color: '#207bbc' };
      case 'Entertainment':
        return { icon: faPopcorn, color: '#e69660' };
      case 'Education':
        return { icon: faGraduationCap, color: '#B16989' };
      case 'Utilities':
        return { icon: faBolt, color: '#7E608A' };
      case'Gifts':
        return { icon: faGifts, color: '#4D5777' };
      case 'Charitable Giving':
        return { icon: faHandHoldingHeart, color: '#79afd7' };
      case 'Loans':
        return { icon: faHandHoldingUsd, color: '#3494cc' };
      case 'Automotive Expenses':
        return { icon: faCar, color: '#AB632F' };
      case 'Healthcare/Medical':
        return { icon: faMedkit, color: '#4DC6E3' };
      case 'Restaurants/Dining':
        return { icon: faUtensilsAlt, color: '#C7514E' };
      case 'Online Services':
        return { icon: faBrowser, color: '#9CADBC' };
      case 'Advertising':
        return { icon: faAd, color: '#D199B7' };
      case 'Home Improvement':
        return { icon: faHouseDay, color: '#E89B26' };
      case 'Home Maintenance':
        return { icon: faTools, color: '#E89B35' };
      case 'Groceries':
        return { icon: faShoppingCart, color: '#C74D50' };
      case 'General Merchandise':
        return { icon: faBagsShopping, color: '#BF5736' };
      case 'Checks':
        return { icon: faMoneyCheckEditAlt, color: '#8A7456' };
      case 'Clothing/Shoes':
        return { icon: faTshirt, color: '#B15D7A' };
      case 'Gasoline/Fuel':
        return { icon: faOilCan, color: '#002555' };
      case 'Postage and Shipping':
        return { icon: faShippingFast, color: '#77AFEF' };
      case 'Printing':
        return { icon: faShippingFast, color: '#3b67f1' };
      case 'Insurance':
        return { icon: faCarCrash, color: '#EE8CB0' };
      /*case 'Mortgages':
       return { icon: [faHouse, faDollarSign], color: '#DFA01E' };*/
      case 'Mortgages':
        return { icon: faHouse, color: '#C5497D' };
      case 'ATM/Cash Withdrawals':
        return { icon: faMoneyBillWave, color: '#4A827C' };
      case 'Service Charges/Fees':
        return { icon: faSearchDollar, color: '#746DBE' };
      case 'Other Expenses':
        return { icon: faBadgeDollar, color: '#BDA5AC' };
      case 'Wages Paid':
        return { icon: faBadgeDollar, color: '#207bbc' };
      case 'Other Bills':
        return { icon: faFileInvoiceDollar, color: '#C25432' };
      case 'Taxes':
        return { icon: faSackDollar, color: '#3b7bb7' };

      case 'Business Miscellaneous':
        return { icon: faBuilding, color: '#8832c2' };
      case 'Office Maintenance':
        return { icon: faBuilding };
      case 'Office Supplies':
        return { icon: faBuilding };
      case 'Cable/Satellite Services':
        return { icon: faSatelliteDish, color: '#3B67F1' };
      case 'Rent':
        return { icon: faHouse, color: '#C5497D' };
      case 'Telephone Services':
        return { icon: faMobile, color: '#7193BC' };
      case 'Child/Dependent Expenses':
        return { icon: faChild, color: '#E09F1F' };
      case 'Dues and Subscriptions':
        return { icon: faEnvelopeOpenText, color: '#3a0062' };
      case 'Electronics':
        return { icon: faGamepadAlt, color: '#002555' };
      case 'Hobbies':
        return { icon: faGamepadAlt, color: '#5F4206' };
      case 'Personal Care':
        return { icon: faHotTub, color: '#B85E3D' };


      // default icon if not assigned
      default:
        return { icon: faRepeat };


    }
  }

}

