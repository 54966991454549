import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BalanceSheetComponent } from './balanceSheet.component';
import { routing } from './balanceSheet.routing';
import { NgaModule } from '../../theme/nga.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule( {
  imports: [
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    NgaModule,
    FontAwesomeModule,
    routing,
    MatIconModule,
    SkeletonModule,
  ],
  declarations: [
    BalanceSheetComponent,
  ],
  providers: [
    DatePipe,
  ],
  exports: [
    BalanceSheetComponent,
  ],
} )
export class BalanceSheetModule {
}
