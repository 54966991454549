import { Component, Input } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { ExpectedWealthIssue } from '../../utils/dataInterfaces';
import { BenchmarkState } from '../../utils/benchmark.state';
import { EXPECTED_VALUE_SCALES } from '../../utils/enums';

@Component( {
  selector: 'rip-wealth-probability-numbers',
  template: `
    <div
        class="flexing{{ direction === 'horizontal' ? '-row-only' : ' vertical-flex' }} space-between with-gap">
      <div class="wealth-probability-header">
        <!-- name of time period goes here -->
        <div>
          <div *ngIf="benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears">
            {{ data?.n | ripCalendarYearPipe }}
          </div>
          <div *ngIf="benchmarkState.expectedWealthScale !== EXPECTED_VALUE_SCALES.calendarYears">
            {{ data?.n | ripDecimalPipe : '0-2' }}
          </div>
          <div>({{ data?.label }})</div>
        </div>
      </div>
      <!--<div *ngIf="showLabels" class="upside-potential">
        Upside Potential
        <span class="disclaimer form-disclaimer" (click)="openGlossary( 20 )">
            <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
        </span> :
      </div>-->
      <div class="wealth-probability-number">
        {{ data?.upsidePotential | ripCurrencyPipe : true }}
      </div>

      <div style="position: relative;"
           class="wealth-probability-number">
        <!--<div *ngIf="showLabels" class="expected-wealth">
          Expected Wealth
          <span class="disclaimer form-disclaimer" (click)="openGlossary( 21 )">
            <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
          </span>:
        </div>-->
        <div> {{ data?.expectedWealth | ripCurrencyPipe : true }}</div>
        <nb-badge [status]="'danger'" text="1" *ngIf="issues?.length > 1 && data?.expectedWealth === 0"
                  class="far-right"></nb-badge>
      </div>
      <div style="position: relative;" class="wealth-probability-number">
        <div>
          {{ data?.downsideRisk | ripCurrencyPipe : true }}
        </div>
        <nb-badge [status]="'warning'" text="1" *ngIf="issues?.length > 0 && data?.downsideRisk === 0"
                  class="far-right"></nb-badge>
      </div>
    </div>`,
  styleUrls: [ './wealth-probability-numbers.component.scss' ],
} )

export class WealthProbabilityNumbersComponent {

  @Input() data: any = {};
  @Input() issues: ExpectedWealthIssue[];
  @Input() showLabels: boolean = false;
  @Input() direction: string = 'horizontal';

  faQuestionCircle = faQuestionCircle;

  EXPECTED_VALUE_SCALES = EXPECTED_VALUE_SCALES;

  constructor( public benchmarkState: BenchmarkState ) {
  }

}
