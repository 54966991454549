/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AddUserToWorkspaceRequest } from '../../models/add-user-to-workspace-request';
import { AddUserToWorkspaceResponse } from '../../models/add-user-to-workspace-response';

export interface AddUserToWorkspace$Params {

/**
 * Workspace ID
 */
  workspaceId: number;

/**
 * User ID
 */
  userId: string;
  
    /**
     * User permissions
     */
    body: AddUserToWorkspaceRequest
}

export function addUserToWorkspace(http: HttpClient, rootUrl: string, params: AddUserToWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<AddUserToWorkspaceResponse>> {
  const rb = new RequestBuilder(rootUrl, addUserToWorkspace.PATH, 'post');
  if (params) {
    rb.path('workspaceId', params.workspaceId, {});
    rb.path('userId', params.userId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AddUserToWorkspaceResponse>;
    })
  );
}

addUserToWorkspace.PATH = '/workspaces/{workspaceId}/user/{userId}';
