/**
 * Created by joshua on 1/4/24.
 */

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { SubscriptionDetailsUtil, SubscriptionStatus } from './utils/subscriptionDetails.util';
import { GlobalState } from './global.state';
import { FEATURE_NAMES, PAGE_NAMES } from './utils/enums';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { WealthFluentMessageService } from './theme/services/wealthFluentMessage';
import { CrossAppMessageType, DialogName } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from './utils/logger.service';

@Injectable( {
  providedIn: 'root',
} )
export class WorkspacesDashboardGuard implements CanActivate {

  appName: string = environment.appName;

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  constructor( private _subscriptionDetailsUtil: SubscriptionDetailsUtil,
               private _state: GlobalState,
               public dialog: MatDialog,
               private router: Router ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    if ( this._subscriptionDetailsUtil.hasRetrievedSubscription ) {
      const canAccessWorkspacesDashboard = this._state.featureIsOn( FEATURE_NAMES.WORKSPACES );
      if ( !canAccessWorkspacesDashboard ) {
        this.upsell();
      }
      return [ SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING ].includes( this._subscriptionDetailsUtil.status ) && canAccessWorkspacesDashboard;
    } else {
      this._subscriptionDetailsUtil.hasActiveSubscription().subscribe( {
        next: ( hasActiveSubscription ) => {
          if ( hasActiveSubscription ) {
            const canAccessWorkspacesDashboard = this._state.featureIsOn( FEATURE_NAMES.WORKSPACES );
            if ( !canAccessWorkspacesDashboard ) {
              this.upsell();
              if ( state.url.toLowerCase().includes( PAGE_NAMES.WORKSPACES.toLowerCase() ) ) {
                this.router.navigateByUrl( '/pages' ).then( () => Logger.log( 'done routing to pages' ) ).catch( err => console.error( err ) );
              }
            }
            return canAccessWorkspacesDashboard;
          } // don't need to do much if they don't have an active sub because the normal auth guard will kick them out
          return false;
        }, error: ( err ) => {
          console.error( err );
          return false;
        },
      } );

    }
  }

  upsell() {
    this.wealthFluentMessageService.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      data: {
        dialogName: DialogName.UPSELL,
        featureName: FEATURE_NAMES.WORKSPACES,
      },
      message: 'Open the upsell dialog',
    } );
  }
}
