import { Component, Input, OnInit } from '@angular/core';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { EXPECTED_VALUE_SCALES } from '../../../utils/enums';
import { Observable } from 'rxjs';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../shared/workspace-permission';
import { AppStoreService } from '../../../store';
import { map } from 'rxjs/operators';

@Component( {
  selector: 'rip-scale-control',
  template: `

    <div class="chart-control-item">
      <div>Show Time In:</div>
      <mat-button-toggle-group #dateRadio id="date-range-button-group"
                               [(value)]="currentScale"
                               [disabled]="hasNotPermissions$ | async"
                               (valueChange)="scaleChanged($event)">
        <mat-button-toggle *ngFor="let option of scaleOptions" [value]="option.value">
          {{option.label}}
        </mat-button-toggle>

      </mat-button-toggle-group>
    </div>
  `,
} )

export class ScaleControlComponent extends WithWorkspacePermission implements OnInit {

  @Input() actionsAvailable: boolean;
  currentScale: EXPECTED_VALUE_SCALES;

  scaleOptions: any[] = [
    {
      value: EXPECTED_VALUE_SCALES.age,
      label: 'Your Age',
    },
    {
      value: EXPECTED_VALUE_SCALES.calendarYears,
      label: 'Calendar Years',
    },
    {
      value: EXPECTED_VALUE_SCALES.yearsFromToday,
      label: 'Years From Today',
    },
  ];
  hasNotPermissions$: Observable<boolean>;

  constructor(
    public benchmarkState: BenchmarkState,
    private appStoreService: AppStoreService,
  ) {
    super();
    this.currentScale = benchmarkState.expectedWealthScale;
  }

  ngOnInit(): void {
    this.hasNotPermissions$ = this.initHasNotPermissionsObs();
  }

  scaleChanged( newScale: EXPECTED_VALUE_SCALES ) {
    this.benchmarkState.expectedWealthScaleChange( this.currentScale );
  }

  private initHasNotPermissionsObs(): Observable<boolean> {
    return this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        map(allPerms => {
          const hasPermissions = hasAnyPermissionFn(allPerms, [this.perm.OWNER, this.perm.INVESTOR_PROFILE_EDIT]);
          return this.actionsAvailable ? false : !hasPermissions;
        }),
      );
  }

}
