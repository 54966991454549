import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild, } from '@angular/core';
import * as _ from 'lodash-es';
import moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { GlobalState } from '../../../../global.state';
import { AccountManager } from '../../../../utils/accountManager';
import { RipsawLongDatePipe, RipsawRemoveUnderscoresPipe } from '../../../../theme/pipes';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { TransactionsService } from '../../../../theme/services';
import { environment } from '../../../../../environments/environment';
import { Util } from '../../../../utils/util.service';
import { TransactionsState } from '../../../../utils/transactions.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../utils/mobileUtil.service';
import { Logger } from '../../../../utils/logger.service';


@Component( {
  selector: 'rip-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: [ './transactions-table.component.scss' ],
} )
export class TransactionsTableComponent implements AfterViewInit {

  filteredTransactions: any[] = [];

  accountList = [];
  incomeCategories = [];
  expenseCategories = [];

  reorderable = true;

  @ViewChild( 'amountTemplate', { static: false } ) amountTemplate: TemplateRef<any>;
  @ViewChild( 'descriptionTemplate', { static: false } ) descriptionTemplate: TemplateRef<any>;
  @ViewChild( 'merchantTemplate', { static: false } ) merchantTemplate: TemplateRef<any>;

  @ViewChild( 'transactionsTable', { static: false } ) transactionsTable: DatatableComponent;

  searchBoxControl: UntypedFormControl = new UntypedFormControl();

  columns: any[] = [];
  columnsToShow: any[] = [];
  columnsForGrouping: any[] = [];

  ColumnMode = ColumnMode;

  datePipe: RipsawLongDatePipe = new RipsawLongDatePipe();
  removeUnderscoresPipe: RipsawRemoveUnderscoresPipe = new RipsawRemoveUnderscoresPipe();

  columnToGroupBy: string = undefined;


  subscriberName: string = 'transactionsTableComponent';


  category: any = {};
  categoryLabel: string;
  allCategories: any = { label: 'All Categories' };
  selectBoxNoValueLabel: string = 'All Accounts';
  selectedAccount: any;
  transactionsTotalDollar: number = 0;
  deviceIsMobile: boolean;


  constructor( private transactionsService: TransactionsService,
               private _state: GlobalState,
               private _accountManager: AccountManager,
               private _elRef: ElementRef,
               public transactionsState: TransactionsState,
               private deviceDetector: DeviceDetectorService ) {


    this.deviceIsMobile = MobileUtil.deviceIsMobile( this.deviceDetector );
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_transactionsTableComponent' ] = this;
    }

    transactionsState.changes.subscribe( () => {
      setTimeout( () => {
        // reset selectedAccount if it was the one that was hidden
        if ( this.selectedAccount && !this.transactionsState.accountMap[ this.selectedAccount ] ) {
          this.selectedAccount = undefined;
        }
        this.processTransactions();
      } );
    } );

  }


  processTransactions() {
    this.filter();
    this.makeAccountAndCategoryLists();
  }

  ngAfterViewInit() {
    setTimeout( () => {

      this.columns = [

        {
          prop: 'date',
          name: 'Date',
          pipe: this.datePipe,
          canGroupBy: true,
        },
        {
          prop: 'amount',
          name: 'Amount',
          cellTemplate: this.amountTemplate,
          cellClass: this.getCellClass,
          comparator: ( a, b ) => {
            if ( a.amount === b.amount ) {
              return 0;
            }
            return a.amount > b.amount ? 1 : -1;
          },
        },


        {
          prop: 'description',
          name: 'Description',
          cellTemplate: this.descriptionTemplate,
          comparator: ( a, b ) => {
            if ( a.original === b.original ) {
              return 0;
            }
            return a.original > b.original ? 1 : -1;
          },
        },

      ];
      if ( !this.deviceIsMobile ) {
        this.columns = this.columns.concat( [

          {
            prop: 'baseType',
            name: 'Credit/Debit',
          },

          {
            prop: 'category',
            name: 'Category',
            canGroupBy: true,
          },
          {
            prop: 'accountName',
            name: 'Account',
            canGroupBy: true,
          },
          {
            prop: 'merchant',
            name: 'Merchant',
            cellTemplate: this.merchantTemplate,
            canGroupBy: true,
            comparator: ( a, b ) => {
              if ( a.name === b.name ) {
                return 0;
              }
              return a.name > b.name ? 1 : -1;
            },
          },
          {
            prop: 'type',
            name: 'Type',
            canGroupBy: true,
            pipe: this.removeUnderscoresPipe,
          },
          {
            prop: 'categoryType',
            name: 'Category Type',
            canGroupBy: true,

          },


        ] );
      }

      this.columnsForGrouping = _.remove( _.clone( this.columns ), ( c ) => {
        return c.canGroupBy;
      } );

      this.setColumnsWhenGrouping( this.columnToGroupBy );
    } );
  }


  setColumnsWhenGrouping( event: any ) {
    /*const col = this.columns.find( ( c ) => {
     return c.prop === event.value;
     } );*/
    Logger.log( 'edit columns' );

    function predicate( c ) {
      return c.prop === event?.value;
    }

    this.columnsToShow = _.clone( this.columns );
    _.remove( this.columnsToShow, predicate );
    this.transactionsTable.recalculate();
  }

  getCellClass( { row, column, value } ) {
    return {
      'positive': column.prop === 'amount' && row.baseType === 'CREDIT',
      'negative': column.prop === 'amount' && row.baseType === 'DEBIT',
    };
  }

  onDetailToggle( event: any ) {
    console.log( event );
  }

  toggleExpandGroup( group: any ) {
    console.log( group );
    this.transactionsTable.groupHeader.toggleExpandGroup( group );
  }

  expandAll() {
    this.transactionsTable.groupHeader.expandAllGroups();
  }

  collapseAll() {
    this.transactionsTable.groupHeader.collapseAllGroups();
  }

  categorySelectionChange() {
    this.category = this.transactionsState.incomeMap.get( this.categoryLabel ) ?? this.transactionsState.expenseMap.get( this.categoryLabel );
    this.filter();
  }

  filter( category?: any ) {

    this.transactionsTotalDollar = 0;

    this.category = category ?? this.category;
    this.categoryLabel = category?.label ?? this.categoryLabel;

    if ( this.categoryLabel && !this.category ) {
      this.category = this.transactionsState.incomeMap.get( this.categoryLabel ) ?? this.transactionsState.expenseMap.get( this.categoryLabel );
    }

    if ( this.category?.transactions ) {
      this.filteredTransactions = this.category.transactions;
    } else {
      this.filteredTransactions = this.transactionsState.allTransactions;
    }

    const fromDate: moment.Moment = moment( this.transactionsState.dateRange.fromDate );
    const toDate: moment.Moment = moment( this.transactionsState.dateRange.toDate );

    // filter all transactions into the filteredTransactions object which is passed to the datatable
    this.filteredTransactions = this.filteredTransactions.filter( ( transaction: any ) => {
      // return True here if you want it in the filtered list
      const transactionDate = moment( transaction.date );

      const query = this.searchBoxControl?.value?.toLowerCase();
      let include;
      if ( query?.length > 0 ) {
        include = ( transaction?.merchant?.name?.toLowerCase().includes( query ) ||
            transaction?.description?.original?.toLowerCase().includes( query ) ||
            transaction?.accountName?.toLowerCase().includes( query ) ||
            transaction?.type?.original?.toLowerCase().includes( query ) ||
            transaction?.category?.original?.toLowerCase().includes( query ) ||
            transaction?.categoryType?.original?.toLowerCase().includes( query ) ||
            transaction?.date?.original?.toDate.includes( query ) )
          && Util.isInDateRange( transactionDate, fromDate, toDate );
      } else {
        include = Util.isInDateRange( transactionDate, fromDate, toDate );
      }

      if ( this.selectedAccount ) {
        include = include && String( transaction?.accountId ) === String( this.selectedAccount );
      }

      if ( include ) {
        if ( transaction.baseType === 'CREDIT' ) {
          this.transactionsTotalDollar += transaction.amount.amount;
        } else {
          this.transactionsTotalDollar -= transaction.amount.amount;
        }
      }
      return include;
    } );

    // console.table( this.filteredTransactions );
  }

  makeAccountAndCategoryLists() {
    this.accountList = [ { name: this.selectBoxNoValueLabel } ];
    this.accountList = Object.keys( this.transactionsState.accountMap );
    /* .map( ( key ) => {
     this.accountList.push( { account_id: key, name: this.accountMap[key] } );
     } );
     */
    this.incomeCategories = Array.from( this.transactionsState.incomeMap.keys() );
    this.expenseCategories = Array.from( this.transactionsState.expenseMap.keys() );
  }

  scrollToTable() {
    this._elRef.nativeElement.scrollIntoView( { behavior: 'smooth' } );
  }


}
