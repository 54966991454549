import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';
import { MatStepper } from '@angular/material/stepper';
import { Util } from '../../../../../utils/util.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component( {
  selector: 'rip-add-manual-account-widget',
  template: `
    <mat-horizontal-stepper #stepper="matStepper"
                            id="add-manual-account-stepper"
                            (selectionChange)="selectionChanged($event)"
                            [linear]="true"><!-- linear=true is for disabling the header navigation controls -->
      <mat-step [completed]="false">
        <ng-template matStepLabel>
          <div matTooltip="Select Account Type" matTooltipClass="mat-tooltip-custom">
            Select an Account Type
          </div>
        </ng-template>
        <mat-grid-list [cols]="deviceIsMobile ? 1 : 3" rowHeight="80px">
          <mat-grid-tile [colspan]="deviceIsMobile ? 1 : 3" [rowspan]="1">
                  <span>
                    Assets
                  </span>
          </mat-grid-tile>
          <mat-grid-tile *ngFor="let type of mamState.assetAccountTypes" [colspan]="1" [rowspan]="1"
                         class="account-type-grid-tile">
            <button mat-raised-button type="button" (click)="typeSelected(type)" class="account-type-tile-button">
              <!--matTooltip="{{ type.label }}" matTooltipClass="mat-tooltip-custom"
              matTooltipPosition="above">-->
              <span>
                <span style="font-size: 1rem;">
                    <fa-icon [icon]="type.icon" *ngIf="type.icon" class="manual-account-icon" size="lg">
                    </fa-icon>
                    <fa-icon [icon]="type.icon2" *ngIf="type.icon2" class="manual-account-icon" size="lg"
                             style="margin-left: 2px;">
                    </fa-icon>
                </span>
                {{ type.label }}
                </span>
              <span *ngIf="type.labelTwo"><br>{{ type.labelTwo }}</span>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="deviceIsMobile ? 1 : 3" [rowspan]="1">
            <mat-divider style="width: 100%;"></mat-divider>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="deviceIsMobile ? 1 : 3" [rowspan]="1">
                  <span>
                    Liabilities
                  </span>
          </mat-grid-tile>
          <mat-grid-tile *ngFor="let type of mamState.liabilityAccountTypes" [colspan]="1" [rowspan]="1">
            <button mat-raised-button type="button" (click)="typeSelected(type)"
                    class="account-type-tile-button">
              <!-- matTooltip="{{ type.label }}" matTooltipClass="mat-tooltip-custom"
                    matTooltipPosition="above">-->
              <span>
                  <fa-icon [icon]="type.icon" *ngIf="type.icon" class="manual-account-icon"></fa-icon>
                  <fa-icon [icon]="type.icon2" *ngIf="type.icon2" class="manual-account-icon"></fa-icon>
                {{ type.label }}
                </span>
              <span *ngIf="type.labelTwo"><br>{{ type.labelTwo }}</span>
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="deviceIsMobile ? 1 : 3" [rowspan]="1">
            <mat-divider style="width: 100%;"></mat-divider>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel>
          <div matTooltip="Enter Account Details" matTooltipClass="mat-tooltip-custom">
            {{ mamState.accountType.type }} Account Details
          </div>
        </ng-template>
        <div style="display: flex;">
          <span style="flex: 1;">
            <button mat-button (click)="cancel()" [disabled]="mamState.saveNewButtonOptions.active">
              <mat-icon>chevron_left</mat-icon>
              Go Back
            </button>
          </span>
          <span style="flex: 2; text-align: center;">
            New {{ mamState.accountType.type }} Account
          </span>
          <span style="flex: 1;"></span>
        </div>
        <div *ngIf="mamState.newAccountForm" id="new-manual-account-form-container">
          <!-- use accountType to determine which component to use to complete the form -->
          <rip-real-asset-form
            *ngIf="mamState.accountType.form === 'realAsset'"
            [form]="mamState.newAccountForm"
            [matcher]="mamState.matcher"
            [type]="mamState.accountType.type">
          </rip-real-asset-form>
          <rip-stock-form *ngIf="mamState.accountType.form === 'stock'"
                          [form]="mamState.newAccountForm"
                          [matcher]="mamState.matcher"
                          [type]="mamState.accountType.type">
          </rip-stock-form>
          <rip-stock-option-form *ngIf="mamState.accountType.form === 'stockOption'"
                                 [form]="mamState.newAccountForm"
                                 [matcher]="mamState.matcher"
                                 [type]="mamState.accountType.type">
          </rip-stock-option-form>
          <rip-bond-form *ngIf="mamState.accountType.form === 'bond'"
                         [form]="mamState.newAccountForm"
                         [matcher]="mamState.matcher"
                         [type]="mamState.accountType.type"
                         [label]="mamState.accountType.label"
                         [amortizationType]="mamState.accountType.amortization_type">
          </rip-bond-form>
          <rip-bank-form
            *ngIf="mamState.accountType.form === 'bank'"
            [form]="mamState.newAccountForm"
            [matcher]="mamState.matcher"
            [type]="mamState.accountType.type">
          </rip-bank-form>
          <rip-cash-form *ngIf="mamState.accountType.form === 'cash'"
                         [form]="mamState.newAccountForm"
                         [matcher]="mamState.matcher"
                         [type]="mamState.accountType.type">
          </rip-cash-form>
          <rip-crypto-form *ngIf="mamState.accountType.form === 'crypto'"
                           [form]="mamState.newAccountForm"
                           [matcher]="mamState.matcher"
                           [type]="mamState.accountType.type">
          </rip-crypto-form>
          <rip-investment-form *ngIf="mamState.accountType.form === 'investment'"
                               [form]="mamState.newAccountForm"
                               [matcher]="mamState.matcher"
                               [type]="mamState.accountType.type">
          </rip-investment-form>
          <rip-loan-form
            *ngIf="mamState.accountType.form === 'loan'"
            [form]="mamState.newAccountForm"
            [matcher]="mamState.matcher"
            [type]="mamState.accountType.type"
            [amortizationType]="mamState.accountType.amortization_type">
          </rip-loan-form>
          <rip-annuity-form *ngIf="mamState.accountType.form === 'annuity'"
                            [form]="mamState.newAccountForm"
                            [matcher]="mamState.matcher"
                            [type]="mamState.accountType.type">
          </rip-annuity-form>
          <rip-term-life-insurance-form *ngIf="mamState.accountType.form === 'term-life-insurance'"
                                        [form]="mamState.newAccountForm"
                                        [matcher]="mamState.matcher"
                                        [type]="mamState.accountType.type">
          </rip-term-life-insurance-form>
          <span
            *ngIf="!mamState.accountType.type">You must choose an account type. Please click cancel to go back</span>
          <div class="form-group">
            <rip-account-fees-editor [accountForm]="mamState.newAccountForm">

            </rip-account-fees-editor>
          </div>
        </div>
        <div class="flexing end">
          <button mat-raised-button (click)="cancel()" [disabled]="mamState.saveNewButtonOptions.active">Cancel</button>
          <rip-progress-bar-button [options]="mamState.saveNewButtonOptions"
                                   (onClick)="createManualAccount()"></rip-progress-bar-button>
          <span *ngIf="!mamState.newAccountForm?.valid" class="invalid-message">{{ mamState.invalidMessage }}</span>
        </div>
        <br>
        <br>
      </mat-step>
    </mat-horizontal-stepper>
  `,
  styleUrls: [ '../../manualAccountManager.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class AddManualAccountWidgetComponent {

  @Input() deviceIsMobile: boolean = false;

  @ViewChild( 'stepper', { static: false } ) stepper: MatStepper;

  constructor( public mamState: ManualAccountManagerState, public snackBar: MatSnackBar ) {
  }

  selectionChanged( event: any ) {
    if ( event.selectedIndex === 0 ) {
      this.mamState.clearNewForm();
      this.stepper.steps.first.completed = false;
    }
  }

  cancel() {
    this.mamState.clearNewForm();
    this.stepper.previous();
    this.stepper.steps.first.completed = false;
  }

  typeSelected( type: any ) {
    this.mamState.accountType = type;
    if ( this.mamState.newAccountFormValueChangeSubscription ) {
      this.mamState.newAccountFormValueChangeSubscription.unsubscribe();
    }
    this.mamState.newAccountForm = ManualAccountManagerState.createNewForm( this.mamState.accountType );
    this.mamState.newAccountFormValueChangeSubscription = this.mamState.newAccountForm.valueChanges.subscribe( () => {
      this.mamState.saveNewButtonOptions.disabled = this.mamState.newAccountForm.invalid;
    } );
    this.stepper.steps.first.completed = true;
    this.stepper.next();
  }

  createManualAccount() {
    this.mamState.showSpinner();
    this.mamState.createManualAccount( ( err ) => {
      if ( err ) {
        console.error( err.err );
        this.snackBar.open( `Error creating account. ${ Util.getRefCodeSupportString( err.refCode ) }`, 'dismiss', Util.getSnackBarOptions( true ) );
      } else {
        this.stepper.previous();
        this.stepper.steps.first.completed = false;
      }
      this.mamState.hideSpinner();
    } );
  }

}
