import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { AllocationChartsComponent } from '../../../reusableWidgets/allocationWidget/components/allocationCharts';
import { MobileDashboardMenuComponent } from '../../../reusableWidgets/allocationWidget/components/mobileDashboardMenu';
import { GlobalState } from '../../../global.state';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash-es';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component( {
  selector: 'rip-mobile-dashboard-charts',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">Allocation Charts</div>
      <span><button mat-raised-button (click)="closeCharts()" class="mobile-close-btn">Close</button></span>
    </div>
    <div class="bottom-sheet-content">
      <div class="mobile-group-menu-container">
        <!--              <rip-go-to-group-menu #columnGroupMenu></rip-go-to-group-menu>-->
        <rip-mobile-dashboard-menu #mobileDashboardMenu
                                   (toggleRE)="toggleRE()"
                                   (flipTable)="closeCharts()"
                                   (toggleBenchmarkValues)="toggleBenchmarkValues()"
                                   [showRE]="showRE"
                                   [showTable]="showTable"
                                   [showDiffs]="showDiffs"></rip-mobile-dashboard-menu>
      </div>
      <rip-allocation-charts #allocationCharts [slides]="slides" (slideChanged)="updateGroupMenu()">

      </rip-allocation-charts>
    </div>
  `,
  styles: [ `
    .mobile-group-menu-container {
      text-align: center;
      margin-top: 5px;
    }
  ` ],
} )

export class MobileDashboardChartsComponent implements OnDestroy {
  slides: any = {};
  @ViewChild( 'allocationCharts', { static: false } ) allocationCharts: AllocationChartsComponent;
  @ViewChild( 'mobileDashboardMenu', { static: false } ) mobileDashboardMenu: MobileDashboardMenuComponent;

  subscriberName: string = 'MobileChartsComponent';

  showRE: boolean;
  showTable: boolean;
  showDiffs: boolean;

  constructor( private _state: GlobalState,
               private _bottomSheetRef: MatBottomSheetRef<MobileDashboardChartsComponent>,
               private _ngZone: NgZone,
               private _cd: ChangeDetectorRef ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_mobileCharts'] = { component: this, zone: this._ngZone };
    }
    this.slides = this._state.globalVars.allocWidget.slides;
    this.showRE = this._state.globalVars.allocWidget.showRE;
    this.showTable = this._state.globalVars.allocWidget.showTable;
    this.showDiffs = this._state.globalVars.allocWidget.showDiffs;
    _state.subscribe( 'slides.generated', ( slides ) => {
      this.updateCharts( slides );
    }, this.subscriberName );
    _state.subscribe( 'goto.column.group', () => {
      this.updateCharts();
    }, this.subscriberName );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'slides.generated | goto.column.group', this.subscriberName );
  }

  updateCharts( newSlides?: any ) {
    if ( newSlides ) {
      this.slides = [ ...newSlides ];
      this.allocationCharts.chartCarousel.slides = [ ...newSlides ];
    }
    const index = _.findIndex( this.slides, ( slide: any ) => {
      return slide.group === this.mobileDashboardMenu?.columnGroupMenu?.currentGroup?.label;
    } );
    this.allocationCharts.goToCarouselSlide( index );
    setTimeout( () => {
      this.doChanges();
    } );
  }

  updateGroupMenu() {
    const currentSlideIndex = this.allocationCharts.getCurrentSlideIndex();
    this.mobileDashboardMenu.columnGroupMenu.currentGroup = _.find( this._state.columnGroupings, ( group: any ) => {
      return this.slides[currentSlideIndex].group === group.label;
    } );
    setTimeout( () => {
      this.doChanges();
    } );
  }

  closeCharts() {
    this._state.globalVars.allocWidget.showTable = true;
    this._bottomSheetRef.dismiss();
  }

  doChanges() {
    this._cd.detach();
    this.slides = [ ...this.slides ];
    this._cd.markForCheck();
    this._cd.detectChanges();
    this._cd.reattach();
  }

  toggleRE() {
    this.showRE = !this.showRE;
    this._state.notifyDataChanged( 'RE.toggled', this.showRE );
    setTimeout( () => {
      this.doChanges();
    } );
  }

  toggleBenchmarkValues() {
    this.showDiffs = !this.showDiffs;
    this._state.notifyDataChanged( 'toggle.benchmark.values', {} );
    setTimeout( () => {
      this.doChanges();
    } );
  }
}
