<div *ngIf="!deviceIsMobile">

  <h3 mat-dialog-title>Disclaimers</h3>
  <mat-divider class="modal-header-divider"></mat-divider>
  <div mat-dialog-content class="modal-body">
    <ng-container *ngTemplateOutlet="disclaimersTemplate"></ng-container>
  </div>
  <mat-divider class="modal-footer-divider"></mat-divider>
  <div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">Close</button>
  </div>

</div>

<div *ngIf="deviceIsMobile">
  <ng-container *ngTemplateOutlet="disclaimersTemplate"></ng-container>
</div>

<ng-template #disclaimersTemplate>
  <div class="disclaimers-container">
    <mat-list #disclaimerList>
      <mat-list-item *ngFor="let disclaimer of disclaimers; let i = index" id="disclaimer_{{i}}"
                     class="disclaimer-item">
        <rip-disclaimer [disclaimer]="disclaimer" [highlight]="i === disclaimerToHighlight"></rip-disclaimer>
      </mat-list-item>
    </mat-list>
  </div>
</ng-template>
