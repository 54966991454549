/**
 * Created by joshua on 12/12/16.
 */
// auth-guard.service.ts

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Auth } from './auth.service';
import { GlobalState } from './global.state';
import { Util } from './utils/util.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from './utils/mobileUtil.service';
import { SubscriptionDetailsUtil, SubscriptionStatus } from './utils/subscriptionDetails.util';

@Injectable()
export class AuthGuard implements CanActivate {

  // this is probably temporary, at least for profile and settings as they should be able to be mobile friendly.
  // but accounts most likely won't be
  nonMobilePages: string[] = [ 'accounts', 'holdings', 'goals-dashboard' ];

  pageNotMobileFriendly( url: string ) {
    const baseUrl = url.split( '?' )[ 0 ];
    for ( const page of this.nonMobilePages ) {
      if ( baseUrl.includes( page ) ) {
        return true;
      }
    }
    return false;
  }

  constructor( private router: Router,
               private _auth: Auth,
               private _state: GlobalState,
               private _detectorService: DeviceDetectorService,
               private _subscriptionDetailsUtil: SubscriptionDetailsUtil ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    // console.info('auth guard activated...');
    if ( this._auth.authenticated() ) {

      if ( this._subscriptionDetailsUtil.hasRetrievedSubscription ) {
        return [ SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING ].includes( this._subscriptionDetailsUtil.status );
      } else {
        this._subscriptionDetailsUtil.hasActiveSubscription().subscribe( {
          next: ( hasActiveSubscription ) => {
            if ( hasActiveSubscription ) {
              if ( MobileUtil.deviceIsMobile( this._detectorService ) && this.pageNotMobileFriendly( state.url ) ) {
                const queryParams = route.queryParams;
                this.router.navigate( [ '/pages' ], { queryParams } ).catch( ( err ) => {
                  console.error( err );
                } );
              }
              this._auth.renewTokens();
              return true;
            } else {
              this._state.globalVars.subscriptionInactive = true;
              this.router.navigate( [ '/pages/profile' ] ).catch( ( err ) => {
                console.error( err );
              } );
              return false;
            }
          }, error: ( err ) => {
            console.error( err );
            return false;
          },
        } );

      }
    } else {
      Util.setRedirectUrl( window.location.hash, this._state );
      this.router.navigate( [ 'login' ] ).catch( ( err ) => {
        console.error( err );
      } );
      return false;
    }
  }
}

@Injectable()
export class ProfileAuthGuard implements CanActivate {
  constructor( private router: Router,
               private _auth: Auth,
               private _state: GlobalState,
               private _subscriptionDetailsUtil: SubscriptionDetailsUtil ) {
  }

  canActivate( route: ActivatedRouteSnapshot ) {
    // Logger.log('profile auth guard activated...');
    if ( this._auth.authenticated() ) {
      this._subscriptionDetailsUtil.hasActiveSubscription().subscribe( {
        next: ( hasActiveSubscription ) => {
          if ( hasActiveSubscription ) {
            this._auth.renewTokens();
          } else {
            this._state.globalVars.subscriptionInactive = true;
          }
        }, error: ( err ) => {
          console.error( err );
          return false;
        },
      } );
    } else {
      Util.setRedirectUrl( window.location.hash, this._state );
      this.router.navigate( [ 'login' ] ).catch( ( err ) => {
        console.error( err );
      } );
      return false;
    }
  }
}

