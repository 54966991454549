import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app-state';
import {
  addCreatingIdsWorkspaces,
  addMyWorkspace,
  clearWorkspace,
  deleteMyWorkspaces,
  getLoadedWorkspacePermissions,
  loadPrimaryWorkspace,
  removeSharedWorkspaces,
  selectCreatingIdsWorkspaces,
  selectIsRefreshingWorkspaces,
  selectLoadedWorkspace,
  selectLoadedWorkspaceIsPrimary,
  selectLoadedWorkspacePermissions,
  selectMyWorkspaces,
  selectSelectedShareeWorkspaces,
  selectSharedWorkspaces,
  setLoadedWorkspace,
  setMyWorkspaces,
  setSharedWorkspaces,
  updateIsRefreshingWorkspaces,
  updateMyWorkspace,
  updateSelectedShareeWorkspaces,
  updateSharedWorkspace,
  WorkspaceLoadedStore,
} from './workspace';
import { SharedWorkspaceDto, UserDto, WorkspaceWithUsersDto } from '../api';
import { clearCurrentUser, selectCurrentUserIsFinancialAdvisor, setCurrentUserIsFinancialAdvisor } from './current-user';
import { GlobalState } from '../global.state';
import { Subject } from 'rxjs';
import { WorkspaceEvent } from '../utils/dataInterfaces';

@Injectable( {
  providedIn: 'root',
} )
export class AppStoreService {
  currentUserIsFinancialAdvisor$ = this.store.select( selectCurrentUserIsFinancialAdvisor );
  loadedWorkspaceIsPrimary$ = this.store.select( selectLoadedWorkspaceIsPrimary );

  loadedWorkspace$ = this.store.select( selectLoadedWorkspace );
  loadedWorkspacePermissions$ = this.store.select( selectLoadedWorkspacePermissions );
  myWorkspaces$ = this.store.select( selectMyWorkspaces );
  sharedWorkspaces$ = this.store.select( selectSharedWorkspaces );
  isRefreshingWorkspaces$ = this.store.select( selectIsRefreshingWorkspaces );
  selectedShareeWorkspaces$ = this.store.select( selectSelectedShareeWorkspaces );
  creatingIdsWorkspaces$ = this.store.select( selectCreatingIdsWorkspaces );

  workspaceLoadedNotFromWFMessage: Subject<WorkspaceEvent> = new Subject<WorkspaceEvent>();
  workspaceCreated: Subject<WorkspaceEvent> = new Subject<WorkspaceEvent>();
  workspaceRemoved: Subject<WorkspaceEvent> = new Subject<WorkspaceEvent>();
  workspaceUpdated: Subject<WorkspaceEvent> = new Subject<WorkspaceEvent>();

  constructor( private store: Store<AppState>,
               private _state: GlobalState ) {
  }

  setCurrentUserIsFinancialAdvisor( isFinancialAdvisor: boolean ): void {
    this.store.dispatch( setCurrentUserIsFinancialAdvisor( !!isFinancialAdvisor ) );
  }

  loadWorkspace( workspace: WorkspaceWithUsersDto | SharedWorkspaceDto, type: WorkspaceLoadedStore['type'], fromWFMessage?: boolean ): void {
    this.store.dispatch( setLoadedWorkspace( workspace, type ) );
    // if the workspace being loaded has an owner property, it is a shared workspace, so we want to know if the owner has a yodlee id
    // otherwise, we want the user's yodlee id
    this._state.globalVars.currentWorkspace = workspace;
    if ( !fromWFMessage ) {
      const event: WorkspaceEvent = { workspace, type };
      this.workspaceLoadedNotFromWFMessage.next( event );
    }
    this.store.dispatch( getLoadedWorkspacePermissions() );
  }

  loadPrimaryWorkspace(): void {
    this.store.dispatch( loadPrimaryWorkspace() );
    this.store.dispatch( getLoadedWorkspacePermissions() );
  }

  setMyWorkspaces( workspaces: WorkspaceWithUsersDto[] ): void {
    this.store.dispatch( setMyWorkspaces( workspaces ) );
  }

  addMyWorkspace( workspace: WorkspaceWithUsersDto ): void {
    this.workspaceCreated.next( { workspace, type: 'my' } );
    this.store.dispatch( addMyWorkspace( workspace ) );
  }

  updateMyWorkspace( workspace: WorkspaceWithUsersDto ): void {
    this.workspaceUpdated.next( { workspace, type: 'my' } );
    this.store.dispatch( updateMyWorkspace( workspace ) );
  }

  deleteMyWorkspace( workspaces: WorkspaceWithUsersDto[] ): void {
    workspaces.forEach( ( w: WorkspaceWithUsersDto ) => {
      this.workspaceRemoved.next( { type: 'my', workspace: w } );
    } );
    this.store.dispatch( deleteMyWorkspaces( workspaces.map( ( w: WorkspaceWithUsersDto ) => w.id ) ) );
  }

  setSharedWorkspaces( workspaces: SharedWorkspaceDto[] ): void {
    this.store.dispatch( setSharedWorkspaces( workspaces ) );
  }

  updateSharedWorkspace( workspace: SharedWorkspaceDto ): void {
    this.store.dispatch( updateSharedWorkspace( workspace ) );
  }

  removeSharedWorkspace( ids: SharedWorkspaceDto['id'][] ): void {
    this.store.dispatch( removeSharedWorkspaces( ids ) );
  }

  updateIsRefreshingWorkspaces( is: boolean, types: WorkspaceLoadedStore['type'][] ): void {
    this.store.dispatch( updateIsRefreshingWorkspaces( is, types ) );
  }

  updateSelectedShareeWorkspaces( user: UserDto ): void {
    this.store.dispatch( updateSelectedShareeWorkspaces( user ) );
  }

  addCreatingIdsWorkspaces( ids: number[] ): void {
    this.store.dispatch( addCreatingIdsWorkspaces( ids ) );
  }

  clear(): void {
    this.store.dispatch( clearWorkspace() );
    this.store.dispatch( clearCurrentUser() );
  }
}
