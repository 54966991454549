<!-- Level 2 and up tier selection -->
<rip-screener-nav *ngIf="!showResults"
                  [hideNavButtons]="currentTier === 1"
                  [selectionHeader]="selectionHeader"
                  (startOver)="startOver()"
                  (goBackOneStep)="goBackOneStep()">
</rip-screener-nav>
<!--<div class="selection-header-wrapper" *ngIf="showNextLevelOptions || showResults && !matchingFunds">
  <div class="screener-selection-header">
    <span>{{ selectionHeader }}</span>
  </div>
  <div *ngIf="currentTier > 1" class="screener-nav-btns">
    <button mat-button (click)="goBackOneStep()">Go Back</button>
    <button mat-button (click)="startOver()">Start Over</button>
  </div>
</div>-->
<div class="container screener-form-container screener-text-align" *ngIf="showNextLevelOptions">
  <!-- sector selections without a specified column -->
  <div class="row" *ngIf="next_tier_sector_options && next_tier_sector_options[0].anyColumnChoices; else useColumns">
    <div class="col-lg-4" *ngFor="let tierOptions of next_tier_sector_options">
      <button *ngIf="tierOptions.anyColumnChoices" mat-raised-button type="button" color="primary"
              (click)="setSectorSelection(tierOptions.anyColumnChoices);goForwardOneStep()" class="screener-tile">
        {{ tierOptions.anyColumnChoices.label }}
      </button>
    </div>
  </div>

  <ng-template #useColumns>
    <div class="row" *ngIf="showColumnHeaders">
      <div class="col-lg-4"><span class="screener-col-headings">US Funds</span></div>
      <div class="col-lg-4"><span class="screener-col-headings">Global (US and Non-US) Funds</span></div>
      <div class="col-lg-4"><span class="screener-col-headings">Non-US Funds</span></div>
    </div>
    <div class="row" *ngFor="let tierOptions of next_tier_sector_options">
      <!-- sector selections with column equal to 1 (left side) -->
      <div class="col-lg-4">
        <button *ngIf="tierOptions.leftSideChoices" mat-raised-button type="button" color="primary"
                (click)="setSectorSelection(tierOptions.leftSideChoices);goForwardOneStep()" class="screener-tile">
          {{ tierOptions.leftSideChoices.label }}
        </button>
      </div>
      <!-- sector selections with column equal to 2 (center) -->
      <div class="col-lg-4">
        <button *ngIf="tierOptions.centerChoices" mat-raised-button type="button" color="primary"
                (click)="setSectorSelection(tierOptions.centerChoices);goForwardOneStep()" class="screener-tile">
          {{ tierOptions.centerChoices.label }}
        </button>
      </div>
      <!-- sector selections with column equal to 3 (right side) -->
      <div class="col-lg-4">
        <button *ngIf="tierOptions.rightSideChoices" mat-raised-button type="button" color="primary"
                (click)="setSectorSelection(tierOptions.rightSideChoices);goForwardOneStep()" class="screener-tile">
          {{ tierOptions.rightSideChoices.label }}
        </button>
      </div>
    </div>
  </ng-template>

</div>

<div id="{{spinnerSelector}}" class="spinner" style="z-index:10000; display: none;">
  <div></div>
</div>


<div class="screener-form-container"
     *ngIf="showNoResultsMessage && !showErrorMessage && !showResults && !showNextLevelOptions">
  <!--queryScreenerStatus === 'finished' && !matchingFunds">-->
  No Matching Funds Found. Try changing your settings to get more results.
</div>
<div class="screener-form-container"
     *ngIf="showErrorMessage && !showResults && !showNoResultsMessage && !showNextLevelOptions">
  <!--queryScreenerStatus === 'failed' && !matchingFunds">-->
  Error retrieving results
</div>
<div class="matching-fund-data-source-container"
     *ngIf="showResults && !showErrorMessage && !showNoResultsMessage && !showNextLevelOptions">
  <rip-matching-fund-data-source #matchingFundDataSource [matchingFunds]="matchingFunds"
                                 [multiSelect]="multiSelect"
                                 [fundTypeOptionsSelected]="fundTypeOptionsSelected"
                                 [selectionHeader]="selectionHeader"
                                 [filterOptions]="filterOptions"
                                 (goBackOneStep)="goBackOneStep()"
                                 (startOver)="startOver()"
                                 (securitiesSelected)="emitSelectedSecurities($event)"></rip-matching-fund-data-source>
</div>


