<div class="spinner" id="{{spinnerSelector}}" style="display: none;">
  <div></div>
</div>
<h3 mat-dialog-title>Manage Revisions</h3>
<mat-divider class="modal-header-divider"></mat-divider>
<div mat-dialog-content class="container">
  <div class="row">
    <div class="col" style="flex-grow: 1;" *ngIf="revManagerUtil?.saving">
      <h5>Create New Revision</h5>
      <div class="row">
        <div class="col">
          <form #saveRevForm autocomplete="off">
            <div class="form-group rev-name-form-group">
              <mat-form-field [color]="revManagerUtil?.revNameColor">
                <input matInput id="revName" name="revName" min="1"
                       (keyup)="revNameExists()" placeholder="Revision Name" [formControl]="revNameFormControl">
                <mat-error *ngIf="revNameFormControl.hasError('required')">
                  A name is required for the revision
                </mat-error>
                <mat-error *ngIf="revNameFormControl.hasError('maxRevsValidator')">
                  Cannot save more than {{ revManagerUtil?.maxRevisions }} revisions
                </mat-error>
                <mat-hint *ngIf="revManagerUtil?.revNameColor === 'accent'">
                  Warning: A revision with this name already exists. If you click save, you will overwrite that
                  revision
                </mat-hint>
              </mat-form-field>

            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col" style="flex-grow: 2;">
      <div id="manage-revs">
        <h5>Existing Revisions</h5>
        <div style="display: flex;" *ngFor="let rev of revManagerUtil?.userRevisions">
          <button mat-raised-button class="icon-button" *ngIf="revManagerUtil?.saving"
                  (click)="useRevName(rev)"
                  title="Reuse"
                  matTooltip="Reuse"
                  matTooltipClass="mat-tooltip-custom">
            <fa-icon [icon]="faRecycle" [fixedWidth]="true" size="lg"></fa-icon>
          </button>

          <button mat-raised-button class="icon-button" *ngIf="!revManagerUtil?.saving"
                  (click)="loadRevision(rev)"
                  title="Load Revision"
                  matTooltip="Load Revision"
                  matTooltipClass="mat-tooltip-custom">
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button mat-raised-button class="icon-button" *ngIf="!revManagerUtil?.saving"
                  (click)="loadSummary(rev)"
                  title="Load Summary"
                  [matTooltip]="'Load Summary'"
                  [matTooltipClass]="'mat-tooltip-custom'">
            <mat-icon>list</mat-icon>
          </button>

          <span class="revision-title">
            {{rev.name}} - {{rev.updated_at | date}}
          </span>
          <div class="delete-rev-btns">
            <rip-progress-bar-button *ngIf="revManagerUtil?.deleteButtonsOptions[rev.id]"
                                     (onClick)="revManagerUtil?.deleteRevision(rev)"
                                     [options]="revManagerUtil?.deleteButtonsOptions[rev.id]"
                                     title="Delete Revision"
                                     matTooltip="Delete Revision"
                                     matTooltipClass="mat-tooltip-custom">

            </rip-progress-bar-button>
            <!--<button mat-raised-button (click)="deleteRevision(rev)" color="warn"
                    matTooltip="Delete Revision"
                    matTooltipClass="mat-tooltip-custom">
              <fa-icon [icon]="faTrash" [fixedWidth]="true" size="lg"></fa-icon>
            </button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider class="modal-footer-divider"></mat-divider>
<div mat-dialog-actions>
  <button mat-button (click)="closeManageRevsModal()">{{ revManagerUtil?.saving ? 'Cancel' : 'Close' }}</button>
  <rip-progress-bar-button *ngIf="revManagerUtil?.saving" [options]="revManagerUtil?.saveButtonOptions"
                           class="modal-footer-progress-btn"
                           (onClick)="createNewRevision()">
  </rip-progress-bar-button>
</div>
