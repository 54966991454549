import { Injectable } from '@angular/core';

@Injectable()

export class ManualAccountFormsHelper {

  /**
   * static function to return bond sector select options
   */
  static getBondSectorSelectOptions() {

    return [
      { value: 'bond_primary_sector_agency_mbs', label: 'Agency MBS' },
      { value: 'bond_primary_sector_abs', label: 'ABS' },
      { value: 'bond_primary_sector_bank_loan', label: 'Bank Loan' },
      { value: 'bond_primary_sector_commercial_mbs', label: 'Commercial MBS' },
      { value: 'bond_primary_sector_convertibles', label: 'Convertible Bond' },
      { value: 'bond_primary_sector_corporate_bond', label: 'Corporate Bond' },
      { value: 'bond_primary_sector_covered_bond', label: 'Covered Bond' },
      { value: 'bond_primary_sector_future_forward', label: 'Future/Forward' },
      { value: 'bond_primary_sector_government', label: 'Government Bond' },
      { value: 'bond_primary_sector_government_related', label: 'Government Related Bond' },
      { value: 'bond_primary_sector_non_agency_residential_mbs', label: 'Non Agency MBS' },
      { value: 'bond_primary_sector_preferred', label: 'Preferred Bond' },
      { value: 'bond_primary_sector_us_municipal_tax_advantaged', label: 'US Municipal Bond' },
    ];
  }

  /**
   * static function to return bond credit quality select options
   */
  static getBondCreditQualitySelectOptions() {
    return [
      { value: 'aaa', label: 'AAA' },
      { value: 'aa', label: 'AA' },
      { value: 'a', label: 'A' },
      { value: 'bbb', label: 'BBB' },
      { value: 'bb', label: 'BB' },
      { value: 'b', label: 'B' },
      { value: 'below_b', label: 'Below B' },
      { value: 'not_rated', label: 'Not Rated' },
    ];
  }

  /**
   * static function to return bond coupon frequency select options
   */
  static getFrequencySelectOptions() {
    return [
      { value: 'annual', label: 'Annual' },
      { value: 'semi-annual', label: 'Semi-Annual' },
      { value: 'quarter', label: 'Quarterly' },
      { value: 'month', label: 'Monthly' },
    ];
  }

}
