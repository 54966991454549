import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlossaryComponent } from '../pages/modals/glossary/glossary.component';

@Injectable()
export class GlossaryUtil {

  constructor( public dialog: MatDialog ) {

  }

  openGlossaryDialog( definitionToHighlight?: number ) {
    this.dialog.open( GlossaryComponent, {
      width: '796px',
      data: {
        definitionToHighlight,
      },
    } );
  }

}
