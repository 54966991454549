import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UnderweightExposure } from '../../../../accounts/components/optimizer/components/underweightExposures/underweightExposures.component';
import { GlobalState } from '../../../../../global.state';
import { environment } from '../../../../../../environments/environment';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import {
  ScreenerComponent,
  SubSectorOptionData,
} from '../../../newInvestmentSelectorModal/components/screener/screener.component';
import { InvestmentSelectorUtil } from '../../../newInvestmentSelectorModal/components/utils/investmentSelectorUtil';
import { Logger } from '../../../../../utils/logger.service';

@Component( {
  selector: 'rip-exposure-choices',
  template: `
    <div class="exposure" style="min-width: 500px;">
      <!-- headers -->
      <div class="row">
        <div class="col-11 weight-label-column">
          Exposure
        </div>
        <div class="col-1"></div>
      </div>
      <div *ngFor="let choice of choices">
        <div class="row exposure-list-item">
          <div class="col-11">
            <button mat-raised-button (click)="addExposure(choice)" class="exposure-choice-btn"
                    [style.width]="btnWidth">
              {{ choice.label }} {{ exposure.riskExposure }}
            </button>
          </div>
          <div class="col-1 check-column">
            <fa-icon [icon]="faCheckCircle" fixedWidth="true" size="2x"
                     *ngIf="selectedChoices[choice.value]" class="exposure-added-icon"
                     matTooltip="Exposure Has Been Added">
            </fa-icon>
            <!--<fa-icon [icon]="faCircle" fixedWidth="true" size="2x"
                     *ngIf="!selectedChoices[choice.prop]" matTooltip="Exposure Has Not Been Added Yet">
            </fa-icon>-->
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [],
} )

export class ExposureChoicesComponent implements AfterViewInit {

  @Input() exposure: UnderweightExposure;
  @Input() allUnderweightExposures: UnderweightExposure[];

  choices: SubSectorOptionData[] = [];

  @Output() goToScreener: EventEmitter<any> = new EventEmitter<any>();

  selectedChoices: any = {};
  lastChosenExposure: SubSectorOptionData;

  faCheckCircle = faCheckCircle;
  faCircle = faCircle;

  btnWidth: string = '135px';

  constructor( private _state: GlobalState, private _cd: ChangeDetectorRef ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_exposureChoicesComponent'] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      Logger.log( this.exposure );

      if ( InvestmentSelectorUtil.isBlendValueGrowth( this.exposure ) ) {
        this.choices = ScreenerComponent.ValueBlendGrowthTierOptions;
      } else {
        // adding a bond exposure. need to add all underweight maturities too
        if ( InvestmentSelectorUtil.isShortTermMaturitiesUnderweight( this.allUnderweightExposures ) ) {
          this.choices.push( ScreenerComponent.CreditQualityTierOptions[0] );
        }
        // If Govt Bonds is red underweight and any Intermediate-term maturities are underweight, add Intermediate-Term Treasuries
        if ( InvestmentSelectorUtil.isIntermediateTermMaturitiesUnderweight( this.allUnderweightExposures ) ) {
          this.choices.push( ScreenerComponent.CreditQualityTierOptions[1] );
        }
        // If Govt Bonds is red underweight and any Long-term maturities are underweight, add Long-Term Treasuries
        if ( InvestmentSelectorUtil.isLongTermMaturitiesUnderweight( this.allUnderweightExposures ) ) {
          this.choices.push( ScreenerComponent.CreditQualityTierOptions[2] );
        }

        if ( this.choices.length === 0 ) {
          this.choices.push( ScreenerComponent.CreditQualityTierOptions[3] );
        }
      }
      this.btnWidth = this.getMaxChars();
      this.doChanges();
    } );
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    // this._cd.markForCheck();
    this._cd.reattach();
  }

  addExposure( exposureChoice: any ) {
    this.lastChosenExposure = exposureChoice;
    this.goToScreener.emit( exposureChoice );
  }

  selectExposure( exposureChoice: SubSectorOptionData ) {
    this.selectedChoices[exposureChoice.value] = true;
  }

  unSelectExposure( exposureChoice: SubSectorOptionData ) {
    delete this.selectedChoices[exposureChoice.value];
  }

  allExposuresChosen() {
    return Object.keys( this.selectedChoices ).length === this.choices.length;
  }

  allButOneExposureChosen() {
    return Object.keys( this.selectedChoices ).length === this.choices.length - 1;
  }

  getMaxChars(): string {
    let max = 0;
    for ( const choice of this.choices ) {
      if ( choice.label.length > max ) {
        max = choice.label.length;
      }
    }
    // 8 seems to be a good multiple of character width
    return `${ ( max + this.exposure.riskExposure.length ) * 10 }px`;
  }

}
