<h3 mat-dialog-title class="account-management-dialog-title">Manage Accounts</h3>
<fa-icon class="modal-close-icon" (click)="close()" [icon]="faXmark"></fa-icon>
<mat-divider></mat-divider>

<div mat-dialog-content class="modal-body" id="account-management-dialog-body">
  <p-tabView [(activeIndex)]="index">
    <p-tabPanel *ripPerm="[perm.OWNER]; ngIf: !inWealthFluent" [selected]="currentTab === 'Connect accounts'">
      <ng-template pTemplate="header">
        <fa-icon [icon]="faLink" size="lg" class="tab-header-icon"></fa-icon>
        Connect accounts
      </ng-template>
      <div class="fastlink-container">
        <rip-fast-link #fastLinkComponent [restartOnClose]="true"></rip-fast-link>
      </div>
    </p-tabPanel>
    <p-tabPanel
      *ripPerm="[perm.OWNER, perm.ACCOUNT_SETTINGS_EDIT, perm.ACCOUNT_SETTINGS_VIEW]"
      [selected]="currentTab === 'Manage connections'"
    >
      <ng-template pTemplate="header">
        <fa-layers [fixedWidth]="true" size="lg" class="tab-header-icon">
          <fa-stack>
            <fa-icon [icon]="faLink" stackItemSize="2x" style="font-size: 0.3rem" [fixedWidth]="true"></fa-icon>
            <fa-icon [icon]="faCog" stackItemSize="2x" class="icon-subscript-right"></fa-icon>
          </fa-stack>
        </fa-layers>
        Manage connections
      </ng-template>
      <rip-agg-connection-manager (fixConnection)="goToFastlinkTab($event)"></rip-agg-connection-manager>
    </p-tabPanel>
    <p-tabPanel [selected]="currentTab === 'Add a manual account'" *ngIf="!inWealthFluent">
      <ng-template pTemplate="header">
        <!-- <fa-layers [fixedWidth]="true" size="lg" class="tab-header-icon">
          <fa-icon
            [icon]="faMoneyCheckPen"
            stackItemSize="2x"
            style="font-size: 0.3rem"
            [fixedWidth]="true"></fa-icon>
        </fa-layers> -->
        <fa-layers [fixedWidth]="true" size="lg" class="tab-header-icon">
          <fa-stack>
            <fa-icon [icon]="faUniversity" stackItemSize="2x" style="font-size: 0.3rem" [fixedWidth]="true"></fa-icon>
            <fa-icon [icon]="faPlus" stackItemSize="2x" class="icon-subscript-left"></fa-icon>
          </fa-stack>
        </fa-layers>
        Add a manual account
      </ng-template>
      <rip-add-manual-account-widget *ripPerm="[perm.OWNER, perm.ACCOUNTS_ADD]" [deviceIsMobile]="deviceIsMobile">
      </rip-add-manual-account-widget>
    </p-tabPanel>
    <p-tabPanel [selected]="currentTab === 'Existing Manual Accounts'">
      <ng-template pTemplate="header">
        <fa-layers [fixedWidth]="true" size="lg" class="tab-header-icon">
          <fa-stack>
            <fa-icon [icon]="faUniversity" stackItemSize="2x" style="font-size: 0.3rem" [fixedWidth]="true"></fa-icon>
            <fa-icon [icon]="faCog" stackItemSize="2x" class="icon-subscript-right"></fa-icon>
          </fa-stack>
        </fa-layers>
        Manage manual accounts
      </ng-template>
      <rip-manual-account-manager [accountToOpen]="data.account_id"></rip-manual-account-manager>
    </p-tabPanel>
  </p-tabView>
</div>
<!-- <mat-divider class="modal-footer-divider"></mat-divider> -->
<!-- <div mat-dialog-actions> -->
<!-- </div> -->
