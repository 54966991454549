<h3 mat-dialog-title>{{ benchmarkState.title }}</h3>
<mat-divider class="modal-header-divider"></mat-divider>
<div mat-dialog-content class="row benchmark-editor-body modal-body">
    <div class="col-12"> <!--*ngIf="!benchmarkState.benchmarkFormLoading">-->
        <form autocomplete="off">
            <div class="benchmark-form-container" *ngIf="benchmarkState.benchmark">
                <div>
                    Select the composition of these sector portfolios. The default is US Centric sectors. There is also
                    a Global Centric alternative. You can further customize your benchmark sectors by adding/subtracting
                    proxies and adjusting weights below. Note: adjusting proxies below may affect both risk aversion and
                    your efficient frontier.

                </div>

                <mat-divider class="benchmark-divider"></mat-divider>

                <!-- SAMPLE MARKET PROXIES -->
                <h6 id="sample-market-sector-header">Sample Market Sector Proxies</h6>
                <div class="sample-proxy-buttons row" *ngFor="let defaultBenchmark of benchmarkState.defaultBenchmarks">
                    <button mat-raised-button (click)="benchmarkState.loadBenchmark(defaultBenchmark, true)"
                            matTooltip="Click to load this benchmark"
                            matTooltipClass="mat-tooltip-custom"
                            type="button"
                            color="{{ benchmarkState.benchmark.benchmarkData.name === defaultBenchmark.benchmarkData.name ?
                             'primary' : '' }}">
                        {{defaultBenchmark.name}}
                    </button>
                </div>
                <!-- CASH PROXY -->
                <div class="row">
                    <div class="col-12 proxy-container cash-proxy-container">
                        <nb-stepper #cashStepper>
                            <nb-step>
                                <div class="row cash-benchmark-item-row">
                                    <div class="col cash-benchmark-label">
                                        <h4 class="benchmark-market-sector-label">
                                            {{ benchmarkState.benchmark.benchmarkData.cash.label }}
                                        </h4>
                                    </div>
                                    <div class="col benchmark-item-right">
                                        <div class="benchmark-form-field pull-right">
                                            <h4>
                                                100%
                                            </h4>
                                        </div>
                                        <button mat-raised-button class="cash-proxy-button pull-right" type="button"
                                                matTooltip="{{ benchmarkState.proxyButtonLabel }}"
                                                matTooltipClass="mat-tooltip-custom" nbStepperNext>
                                            {{ benchmarkState.benchmark.benchmarkData.cash?.proxy || 'No Proxy Chosen' }}
                                        </button>
                                    </div>
                                </div>
                            </nb-step>
                            <nb-step>
                                <rip-proxy-picker [weight]="benchmarkState.benchmark.benchmarkData.cash"
                                                  [isBondOrCash]="true"
                                                  [stepper]="cashStepper"
                                                  [screenerTabToUse]="moneyMarketTab">

                                </rip-proxy-picker>
                            </nb-step>
                        </nb-stepper>
                    </div>
                </div>
                <!-- END CASH PROXY -->
                <mat-divider class="benchmark-divider"></mat-divider>

                <!-- BOND PROXIES -->
                <h3>{{benchmarkState.benchmark.benchmarkData.bond.label}}
                    <fa-icon [icon]="faPlusCircle" [fixedWidth]="true" size="sm"
                             (click)="openBenchmarkWeightModal('bond')"
                             matTooltip="Click to add a bond sector"
                             matTooltipClass="mat-tooltip-custom"></fa-icon>
                </h3>

                <div class="row">
                    <div class="col-12 proxy-container">
                        <nb-stepper *ngFor="let weight of benchmarkState.benchmark.benchmarkData.bond.weights"
                                    #bondStepper>
                            <nb-step>
                                <div class="row benchmark-item-row">
                                    <div class="col benchmark-item-label">
                                        <h4 class="benchmark-market-sector-label">{{ weight.label }}
                                            <fa-icon [icon]="faMinusCircle" [fixedWidth]="true" size="sm"
                                                     (click)="benchmarkState.removeBenchmarkWeight(weight, 'bond')"
                                                     matTooltip="Remove bond sector"
                                                     matTooltipClass="mat-tooltip-custom"></fa-icon>
                                        </h4>
                                    </div>
                                    <div class="col benchmark-item-right">
                                        <mat-form-field class="benchmark-form-field pull-right">
                                            <label for="{{weight.key}}"></label>
                                            <input matInput #weightInput
                                                   placeholder=""
                                                   class="benchmark-input"
                                                   (change)="benchmarkState.updateInput(weightInput, weight.key, 'percent', 'bond')"
                                                   name="{{weight.key}}"
                                                   id="{{weight.key}}"
                                                   [formControl]="$any(benchmarkState.form.controls[weight.key])"
                                                   [errorStateMatcher]="benchmarkState.benchmarkMatcher">
                                            <mat-error
                                                    *ngIf="benchmarkState.form.controls[weight.key].hasError('required')">
                                                {{ weight.label }} Weight is required
                                            </mat-error>
                                            <mat-error
                                                    *ngIf="benchmarkState.form.controls[weight.key].hasError('benchmarkValidator')">
                                                Benchmark value must be a number
                                            </mat-error>
                                        </mat-form-field>
                                        <button mat-raised-button class="proxy-button pull-right" type="button"
                                                matTooltip="{{ benchmarkState.proxyButtonLabel }}"
                                                matTooltipClass="mat-tooltip-custom"
                                                nbStepperNext>
                                            {{ weight.proxy || 'No Proxy Chosen' }}
                                        </button>
                                    </div>
                                </div>
                            </nb-step>
                            <nb-step>
                                <rip-proxy-picker [weight]="weight"
                                                  [isBondOrCash]="true"
                                                  [stepper]="bondStepper"
                                                  [screenerTabToUse]="bondsTab">
                                </rip-proxy-picker>
                            </nb-step>
                        </nb-stepper>
                        <div class="row benchmark-item-row total-row"
                             [ngClass]="{'has-danger': !helpers.checkGroupTotal(benchmarkState.benchmark.benchmarkData.bond.total) }">
                            <div class="col-8">
                                Total
                            </div>
                            <div class="col total">
                                {{ benchmarkState.benchmark.benchmarkData.bond.total | ripPercentPipe : '2-2' }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- END BOND PROXIES -->
                <mat-divider class="benchmark-divider"></mat-divider>

                <!-- STOCK PROXIES -->
                <h3>{{ benchmarkState.benchmark.benchmarkData.stock.label }}
                    <fa-icon [icon]="faPlusCircle" [fixedWidth]="true" size="sm"
                             (click)="openBenchmarkWeightModal('stock')"
                             matTooltip="Click to add a stock sector"
                             matTooltipClass="mat-tooltip-custom"></fa-icon>
                </h3>
                <div class="row">
                    <div class="col-12 proxy-container">
                        <nb-stepper *ngFor="let weight of benchmarkState.benchmark.benchmarkData.stock.weights"
                                    #stockStepper>
                            <nb-step>
                                <div class="row benchmark-item-row">
                                    <div class="col benchmark-item-label">
                                        <h4 class="benchmark-market-sector-label">{{ weight.label }}
                                            <fa-icon [icon]="faMinusCircle" [fixedWidth]="true" size="sm"
                                                     (click)="benchmarkState.removeBenchmarkWeight(weight, 'stock')"
                                                     matTooltip="Remove stock sector"
                                                     matTooltipClass="mat-tooltip-custom"></fa-icon>
                                        </h4>
                                    </div>
                                    <div class="col benchmark-item-right">
                                        <mat-form-field class="benchmark-form-field pull-right">
                                            <label for="{{ weight.key}}"></label>
                                            <input matInput #weightInput
                                                   placeholder=""
                                                   class="benchmark-input"
                                                   (change)="benchmarkState.updateInput(weightInput, weight.key, 'percent', 'stock')"
                                                   name="{{weight.key}}"
                                                   id="{{ weight.key}}"
                                                   [formControl]="$any(benchmarkState.form.controls[weight.key])"
                                                   [errorStateMatcher]="benchmarkState.benchmarkMatcher">
                                            <mat-error
                                                    *ngIf="benchmarkState.form.controls[weight.key].hasError('required')">
                                                {{ weight.label }} Weight is required
                                            </mat-error>
                                            <mat-error
                                                    *ngIf="benchmarkState.form.controls[weight.key].hasError('benchmarkValidator')">
                                                Benchmark value must be a number
                                            </mat-error>
                                        </mat-form-field>
                                        <button mat-raised-button class="proxy-button pull-right" type="button"
                                                matTooltip="{{ benchmarkState.proxyButtonLabel }}"
                                                matTooltipClass="mat-tooltip-custom"
                                                nbStepperNext>
                                            {{ weight.proxy || 'No Proxy Chosen' }}
                                        </button>
                                    </div>
                                </div>
                            </nb-step>
                            <nb-step>
                                <rip-proxy-picker [weight]="weight"
                                                  [stepper]="stockStepper"
                                                  [screenerTabToUse]="stocksTab">
                                </rip-proxy-picker>
                            </nb-step>
                        </nb-stepper>
                        <div class="row benchmark-item-row total-row"
                             [ngClass]="{'has-danger': !helpers.checkGroupTotal(benchmarkState.benchmark.benchmarkData.stock.total) }">
                            <div class="col-8">
                                Total
                            </div>
                            <div class="col total">
                                {{ benchmarkState.benchmark.benchmarkData.stock.total | ripPercentPipe : '2-2' }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END STOCK PROXIES -->
            </div>
        </form>
    </div>
</div>
<!-- FOOTER -->
<mat-divider class="modal-footer-divider"></mat-divider>

<div class="row">
    <!--    <div class="col-2">
            <button mat-raised-button type="button" (click)="goToStepTwo()">
                <mat-icon>chevron_left</mat-icon>
                Back
            </button>
        </div>-->
    <div class="col date-col">Last Updated: {{ benchmarkState.benchmark.updated_at | date : 'medium'}}</div>
    <div class="col-5 modal-footer-btn-right">
        <!-- submit -->
        <button mat-button type="button"
                (click)="benchmarkState.cancel()">
            Cancel Changes
        </button>
        <button mat-button type="button" (click)="benchmarkState.resetBenchmarkToDefault()">
            Reset To Default
        </button>
        <!--<rip-progress-bar-button [options]="benchmarkState.saveButtonOptions"
                                 (onClick)="benchmarkState.saveBenchmark()">
        </rip-progress-bar-button>-->
    </div>
</div>
<div mat-dialog-actions style="margin-top: 10px;">
    <button mat-button (click)="closeModal()">Close</button>
</div>
