import { Component, Input } from '@angular/core';
import { BenchmarkState } from '../../utils/benchmark.state';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { EXPECTED_VALUE_SCALES } from '../../utils/enums';
import { GlossaryUtil } from '../../utils/glossary.util';

@Component( {
  selector: 'rip-wealth-forecast',
  template: `
    <div class="flexing vertical-flex space-between expected-value-timeline-numbers">
      <div class="planning-sidebar-titles">
        Plan Transitions
      </div>
      <div class="flexing-row-only space-between with-gap wealth-forecast-row">
        <div class="wealth-probability-header">
          {{ benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.age ?
            'Age' : '' }}
          {{ benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears ?
            'Year' : '' }}
          {{ benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.yearsFromToday ?
            'Years From Today' : '' }}
        </div>
        <div class="upside-potential">
          <div>
            <span class="disclaimer form-disclaimer" (click)="openGlossary( 20 )">
              <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
            </span>
            Upside
          </div>
          <div>
            Potential
          </div>
        </div>
        <div class="expected-wealth">
          <div>
            <span class="disclaimer form-disclaimer" (click)="openGlossary( 21 )">
              <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
            </span>
            Expected
          </div>
          <div>
            Wealth
          </div>
        </div>
        <div class="downside-risk">
          <div>
            <span class="disclaimer form-disclaimer" (click)="openGlossary( 20 )">
              <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
            </span>
            Downside
          </div>
          <div>
            Risk
          </div>
        </div>
        <!--<div class="wealth-probability-header">
          <div>At Retirement</div>
          <div>({{ benchmarkState?.selectedBucket?.stats?.inflectionPoint?.n }})
          </div>
        </div>
        <div class="wealth-probability-header">
          <div>Portfolio End</div>
          <div>({{ benchmarkState?.selectedBucket?.stats?.portfolioEnd?.n }})</div>
        </div>-->
      </div>
      <!-- FIRST YEAR WEALTH -->

      <rip-wealth-probability-numbers class="wealth-forecast-row"
                                      [showLabels]="true"
                                      [data]="benchmarkState?.selectedBucket?.stats?.firstYear"
                                      [issues]="benchmarkState?.selectedBucket?.issues">

      </rip-wealth-probability-numbers>

      <!-- at inflection point (retirement)-->

      <rip-wealth-probability-numbers class="wealth-forecast-row"
                                      *ngIf="!retired && benchmarkState?.selectedBucket?.stats?.inflectionPoint"
                                      [showLabels]="true"
                                      [data]="benchmarkState?.selectedBucket?.stats?.inflectionPoint"
                                      [issues]="benchmarkState?.selectedBucket?.issues">

      </rip-wealth-probability-numbers>
      <!-- Post retirement goal withdrawals -->
      <!-- <div *ngFor="let w of benchmarkState?.selectedBucket?.stats?.postRetirementGoalWithdrawals">
           <div class="wealth-probability-header">
               <div>{{ w.name }}</div>
               <div>({{ w.n }})</div>
           </div>
           <rip-wealth-probability-numbers
                   [data]="w">
           </rip-wealth-probability-numbers>
       </div>-->
      <!-- at end -->

      <rip-wealth-probability-numbers class="wealth-forecast-row"
                                      [showLabels]="true"
                                      [data]="benchmarkState?.selectedBucket?.stats?.portfolioEnd"
                                      [issues]="benchmarkState?.selectedBucket?.issues">

      </rip-wealth-probability-numbers>
    </div>

  `,
  styleUrls: [],
} )

export class WealthForecastComponent {

  @Input() retired: boolean;
  constructor( private glossaryUtil: GlossaryUtil, public benchmarkState: BenchmarkState ) {
  }

  protected readonly faQuestionCircle = faQuestionCircle;
  protected readonly EXPECTED_VALUE_SCALES = EXPECTED_VALUE_SCALES;

  /*
* Function to open the disclaimer modal for displaying the disclaimers
* */
  openGlossary( index: number ) {
    this.glossaryUtil.openGlossaryDialog( index );
  }
}
