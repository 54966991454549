<nb-card>

    <nb-card-header class="ripsaw-page-header flexing vertical-flex" style="position: relative; align-items: start" [nbSpinner]="transactionsState.loading">
        <nb-badge *ngIf="transactionsState.errorLoadingTransactions" text="Error Loading Data. Click here to try again"
                  position="top left" (click)="transactionsState.getTransactionCount()"
                  class="button-badge"
                  status="danger">
        </nb-badge>
        <div *ngIf="!deviceIsMobile"  class="transactions" style="padding-bottom: 25px;">
            Transactions
        </div>
        <div>
            <div class="flexing" style="font-size: 1rem;">

                <div class="date-picker-section" [formGroup]="transactionsState.datePickerForm">
                    <mat-form-field>
                        <input matInput
                               placeholder="Start Date (MM/DD/YYYY)"
                               name="fromDate"
                               formControlName="fromDate"
                               [matDatepicker]="$any(fromDatePicker)"
                               [max]="transactionsState.maxDate"
                               [min]="transactionsState.minDate"
                               (dateChange)="transactionsState.checkDatePickers()">
                        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePicker></mat-datepicker>
                        <mat-error *ngIf="transactionsState.datePickerForm.controls.fromDate.hasError('invalidDate')">
                            Date must be in 'MM/DD/YYYY' format
                        </mat-error>
                    </mat-form-field>
                    to
                    <mat-form-field>
                        <input matInput
                               placeholder="End Date (MM/DD/YYYY)"
                               name="toDate"
                               formControlName="toDate"
                               [matDatepicker]="$any(toDatePicker)"
                               [max]="transactionsState.maxDate"
                               [min]="transactionsState.minDate"
                               (dateChange)="transactionsState.checkDatePickers()">
                        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #toDatePicker></mat-datepicker>
                        <mat-error *ngIf="transactionsState.datePickerForm.controls.toDate.hasError('invalidDate')">
                            Date must be in 'MM/DD/YYYY' format
                        </mat-error>
                    </mat-form-field>
                </div>
                <div style="margin-left: 10px;">
                    <mat-button-toggle-group #dateRadio id="date-range-button-group"
                                             [(value)]="transactionsState.currentDateRangeOption"
                                             (valueChange)="transactionsState.changeSeriesRange()">
                        <mat-button-toggle *ngFor="let option of transactionsState.dateRangeRadioOptions" [value]="option.value">
                            {{option.label}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body id="transaction-card-body">
        <div [style.margin-top.px]="transactionsMarginTop" id="transactions-container">


            <rip-income-statement #incomeStatementComponent
                                  (categoryClicked)="filterTableByCategory($event)">

            </rip-income-statement>


        </div>

        <div>

            <rip-transactions-table #transactionsTableComponent>

            </rip-transactions-table>
        </div>
    </nb-card-body>
    <nb-card-footer id="transactions-card-footer">
        <div class="footnote">
            *Date range of available data: {{ transactionsState.minDateReturned | date : 'shortDate' }}
            - {{ transactionsState.maxDateReturned | date : 'shortDate' }} Note: this date range is based on the range of transactions available in any of your accounts in {{ appName }}. The availability of transaction data is on an account basis based on the data provided by the institution.
        </div>
    </nb-card-footer>
</nb-card>
