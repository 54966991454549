<!--Headers-->
<div class="form-group" *ngIf="label === 'Private Lending'">
    <div class="flexing">
        <fa-icon [icon]="faHandHoldingUsd" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
        <h3 class="manual-account-form-H3" style="padding-bottom: 15px;">Private Lending</h3>

    </div>
    This account type can be used for interest only private lending (you lent money to someone else, thus it should be
    as an asset)
</div>

<div class="form-group" *ngIf="label === 'Bond'">
    <div class="flexing center-vertically">
        <fa-icon [icon]="faFileCertificate" size="2x" class="manual-account-icon">
        </fa-icon>
        <fa-icon [icon]=" faDollarSign" size="2x" class="manual-account-icon" style="margin-right: 5px;">
        </fa-icon>
        <h3 class="manual-account-form-H3" style="padding-bottom: 15px;">Bond</h3>

    </div>
    This account type can be used for a single bond
</div>


<div class="form-group">
    <div>
        Fields with an * are required
    </div>
</div>
<div *ngIf="form">
    <form [formGroup]="form" #formElement>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="name"
                           [errorStateMatcher]="matcher"
                           name="name"
                           placeholder="Account Name / Description*">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required')">
                    Name is required
                </mat-error>
                <mat-label>Account Name / Description*</mat-label>
            </mat-form-field>
        </div>
        <!-- BOND TERMS SECTION -->
        <h3 class="manual-account-form-H3"
            style="padding-bottom: 10px;">{{ type === 'Bond' ? 'Bond Terms' : 'Debt Terms' }}</h3>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="loan_origination_date"
                           [errorStateMatcher]="matcher"
                           name="loan_origination_date"
                           placeholder="{{ type === 'Bond' ? '' : 'Loan ' }}Origination Date (MM/DD/YYYY)*"
                           [matDatepicker]="origDatepicker"
                           (change)="originationDateChanged()">
                </label>
                <mat-datepicker-toggle matSuffix [for]="origDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #origDatepicker (closed)="originationDateChanged()"></mat-datepicker>
                <mat-error *ngIf="form.controls.loan_origination_date.hasError('invalidDate')">
                    Origination Date must be in 'MM/DD/YYYY' format
                </mat-error>
                <mat-error *ngIf="form.controls.loan_origination_date.hasError('required')">
                    Origination Date is required
                </mat-error>
                <mat-label>{{ type === 'Bond' ? '' : 'Loan ' }}Origination Date (MM/DD/YYYY)*</mat-label>
            </mat-form-field>
        </div>

        <div [ngClass]="{'horizontal-form-group': !deviceIsMobile, 'form-group': deviceIsMobile }">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <label>
                        <input matInput
                               formControlName="loan_term"
                               [errorStateMatcher]="matcher"
                               name="loan_term"
                               placeholder="Original Term*"
                               (change)="termChanged()">
                    </label>

                    <mat-error *ngIf="form.controls.loan_term.hasError('isFloatGreaterThanZeroValidator')">
                        Original Loan Term must be greater than zero
                    </mat-error>
                    <mat-error *ngIf="form.controls.loan_term.hasError('required')">
                        Term is required
                    </mat-error>
                    <mat-label>Original Term*</mat-label>
                </mat-form-field>
            </div>
            <div>
                <span style="margin: 0 15px;"> In </span>
                <mat-button-toggle-group #selectedLoanTermUnits="matButtonToggleGroup"
                                         (change)="termUnitChanged()">
                    <mat-button-toggle value="months" class="term-toggle">Months</mat-button-toggle>
                    <mat-button-toggle value="years" class="term-toggle">Years</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="maturity_date"
                           [errorStateMatcher]="matcher"
                           name="maturity_date"
                           placeholder="Maturity Date (MM/DD/YYYY)*"
                           [matDatepicker]="maturityDatepicker"
                           (change)="maturityDateChanged()">
                </label>
                <mat-label>Maturity Date (MM/DD/YYYY)*</mat-label>
                <mat-datepicker-toggle matSuffix [for]="maturityDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #maturityDatepicker (closed)="maturityDateChanged()"></mat-datepicker>
                <mat-error *ngIf="form.controls.maturity_date.hasError('invalidDate')">
                    Date must be in 'MM/DD/YYYY' format
                </mat-error>
                <mat-error *ngIf="form.controls.maturity_date.hasError('isFutureDateValidator')">
                    Maturity Date must be greater than today's date
                </mat-error>
                <mat-error *ngIf="form.controls.maturity_date.hasError('required')">
                    Maturity Date is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="maturity_value"
                           [errorStateMatcher]="matcher"
                           name="maturity_value"
                           placeholder="Maturity Value ($)*"
                           (change)="maturityValueChanged()">
                </label>
                <mat-label>Maturity Value ($)*</mat-label>
                <mat-error *ngIf="form.controls.maturity_value.hasError('required')">
                    Maturity Value is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="coupon"
                           [errorStateMatcher]="matcher"
                           name="coupon"
                           placeholder="Coupon (Annual %)*"
                           (change)="couponChanged()">
                </label>
                <mat-error *ngIf="form.controls.coupon.hasError('isValidPctRangeValidator')">
                    Coupon must be between 0 and 30 percent
                </mat-error>
                <mat-error *ngIf="form.controls.coupon.hasError('required')">
                    Coupon is required
                </mat-error>
                <mat-label>Coupon (Annual %)*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Coupon Frequency*"
                            formControlName="coupon_frequency"
                            [errorStateMatcher]="matcher"
                            (selectionChange)="couponFrequencyChanged()">
                    <mat-option *ngFor="let option of bondCouponFrequencySelectOptions"
                                value="{{option.value}}">{{option.label}}</mat-option>
                </mat-select>
                <mat-label>Coupon Frequency*</mat-label>
            </mat-form-field>
        </div>

        <!-- BOND VALUATION SECTION -->
        <h3 class="manual-account-form-H3"
            style="padding-bottom: 10px;">{{ type === 'Bond' ? 'Bond Valuation' : 'Debt Valuation' }}</h3>
        <div class="form-group" *ngIf="!addingInRevision">
            <!--<table class="form-group bond-valuation-table">
              <tr>
                <td>-->
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="current_market_rate"
                           [errorStateMatcher]="matcher"
                           name="current_market_rate"
                           placeholder="Input Today's {{ type === 'Bond' ? 'Yield' : 'Rate' }} (Annual %)*"
                           matTooltip="{{ useTreasuryRates() ? 'A mortgage rate from Zillow for the remaining loan life is used to estimate current value. The user can replace this rate with one that is more fully representative (i.e., use a higher rate for more default risk and amount).' : '' }}"
                           matTooltipClass="mat-tooltip-custom"
                           (change)="currentRateChanged()">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required')">
                    Today's Rate is required
                </mat-error>
                <mat-label>Input Today's {{ type === 'Bond' ? 'Yield' : 'Rate' }} (Annual %)*</mat-label>
            </mat-form-field>
            <!--</td>
            <td>
              <span>=</span>
            </td>
            <td>
              &lt;!&ndash; treasury rate (retrieved from data service) &ndash;&gt;
              <mat-form-field>
                <input matInput
                       formControlName="treasury_rate"
                       [errorStateMatcher]="matcher"
                       name="treasury_rate"
                       placeholder="Treasury Rate (Annual %)"
                       (change)="updateInputPercentFormat('treasury_rate'); recalculateRiskPremiumFromTrsAndYtm(); calcTotalDiscountRate(); calculatePriceAndDuration()">
              </mat-form-field>
            </td>
            <td>
              <span>+</span>
            </td>
            <td>
              &lt;!&ndash; risk premium ( input by user, but we can suggest values based on question) &ndash;&gt;
              <mat-form-field>
                <input matInput
                       formControlName="risk_premium"
                       [errorStateMatcher]="matcher"
                       name="risk_premium"
                       placeholder="Spread (Annual %)"
                       (change)="updateInputPercentFormat('risk_premium'); calcTotalDiscountRate(); calculatePriceAndDuration()">
              </mat-form-field>
            </td>
          </tr>
        </table>-->
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="price"
                           [errorStateMatcher]="matcher"
                           name="price"
                           placeholder="{{ type === 'Bond' ? 'Bond' : 'Debt' }} Price ($)*"
                           (change)="priceChanged()">
                </label>
                <mat-error *ngIf="form.controls.price.hasError('required')">
                    Price is required
                </mat-error>
                <mat-label>{{ type === 'Bond' ? 'Bond' : 'Debt' }} Price ($)*</mat-label>
            </mat-form-field>
        </div>


        <!-- modified duration -->
        <!--  <div class="form-group">
            <mat-form-field>
              <input matInput
                     formControlName="modified_duration"
                     [errorStateMatcher]="matcher"
                     name="modified_duration"
                     placeholder="Duration in Years">
            </mat-form-field>
          </div>-->

        <!-- maturity in years -->
        <div class="form-group" *ngIf="maturityInYears > 0">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="maturity_in_years"
                           [errorStateMatcher]="matcher"
                           name="maturity_in_years"
                           placeholder="Time to Maturity in Years">
                </label>
                <mat-label>Time to Maturity in Years</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="quantity"
                           [errorStateMatcher]="matcher"
                           name="quantity"
                           placeholder="Quantity*"
                           (change)="quantityChanged()">
                </label>
                <mat-error *ngIf="form.controls.quantity.hasError('required')">
                    Quantity is required
                </mat-error>
                <mat-label>Quantity*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="value"
                           [errorStateMatcher]="matcher"
                           placeholder="Market Value ($)*">
                </label>
                <mat-error *ngIf="form.controls.value.hasError('required')">
                    Market Value is required
                </mat-error>
                <mat-label>Market Value ($)*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="cost_basis"
                           [errorStateMatcher]="matcher"
                           name="cost_basis"
                           placeholder="Cost Basis ($)*"
                           (change)="costBasisChanged()">
                </label>
                <mat-error *ngIf="form.controls.cost_basis.hasError('required')">
                    Cost Basis is required
                </mat-error>
                <mat-label>Cost Basis ($)*</mat-label>
            </mat-form-field>
        </div>

        <!-- Corresponding asset -->
        <div class="form-group flexing" *ngIf="type !== 'Bond'">
            <mat-form-field appearance="outline">
                <!--  need an info tool tip for this -->
                <mat-select placeholder="Corresponding Asset"
                            panelClass="max-select-box-height"
                            formControlName="corresponding_asset_id"
                            [errorStateMatcher]="matcher">
                    <mat-option
                            *ngIf="allAssetAccounts.length > 0">{{hasCorrespondingAsset ? 'Remove Account' : 'Choose Account'}}</mat-option>
                    <mat-option *ngIf="allAssetAccounts.length === 0">No assets to choose from yet</mat-option>
                    <mat-option *ngFor="let acct of allAssetAccounts" [value]="acct.account_id">
                        {{ acct.formattedDescription }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <fa-icon [icon]="faInfoCircle"
                     class="form-info"
                     [fixedWidth]="true"
                     size="sm"
                     [nbPopover]="correspondingAssetPopContent"
                     nbPopoverClass="rip-popover">
            </fa-icon>
        </div>
        <rip-bond-select-boxes [matcher]="matcher" [form]="form">
        </rip-bond-select-boxes>
    </form>
</div>


<!--corresponding asset popover-->
<ng-template #correspondingAssetPopContent>
    <nb-card class="popover-card">
        <nb-card-header class="popover-title">
            Corresponding Asset
        </nb-card-header>
        <nb-card-body>
            <div class="popover-content">
                Selecting an associated real asset will link your loan to this asset. When switching from Net Worth to
                Investment mode both will be removed. If you choose not to include an associated asset, then this loan
                (short bond position) will remain in your bond portfolio in both modes.
            </div>
        </nb-card-body>
    </nb-card>

</ng-template>
