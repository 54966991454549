import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsPanelComponent } from './actions-panel.component';
import { ActionWidthDirective } from './action-width/action-width.directive';

@NgModule({
  declarations: [
    ActionsPanelComponent,
    ActionWidthDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ActionsPanelComponent,
    ActionWidthDirective,
  ],
})
export class LibActionsPanelModule { }
