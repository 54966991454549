import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { DialogBaseAbstract } from '../dialog';
import { MessageService } from 'primeng/api';
import { SharedWorkspaceDto, WorkspaceWithUsersDto } from '../../api';

export interface WorkspaceLoadDialogInput {
  workspace: WorkspaceWithUsersDto | SharedWorkspaceDto;
}

export type WorkspaceLoadDialogOutput = {
  dontShowDialogAgain: boolean;
}  | null;

@Component({
  selector: 'rip-workspace-load-dialog',
  templateUrl: './workspace-load-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    .name {
      word-break: break-word;
    }
    .description {
      margin-bottom: 1.5rem;
    }
  `],
})
export class WorkspaceLoadDialogComponent extends DialogBaseAbstract<WorkspaceLoadDialogInput, WorkspaceLoadDialogOutput> {
  dontShowAgain = false;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
  }

  load(): void {
    this.close({ dontShowDialogAgain: this.dontShowAgain });
  }

}
