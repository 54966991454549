import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
  name: 'activePricesPipe',
  pure: false,
} )
export class ActivePricesPipe implements PipeTransform {
  transform( prices: any[] ): any {
    if ( !prices ) {
      return prices;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return prices.filter( price => price.active );
  }
}
