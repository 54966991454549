import { Injectable } from '@angular/core';
import { ColumnDefinition, GlobalState } from '../../../global.state';
import * as _ from 'lodash-es';

export interface HoldingsFilter {
  column: ColumnDefinition;
  operator: string;
  value: string;
}

export interface HoldingsFilterPreset {
  label: string;
  filter?: HoldingsFilter;
  presets?: HoldingsFilterPreset[];
}

@Injectable()
export class HoldingsUtil {
  static getHoldingsFilterPresets( _state: GlobalState ) {
    return [
      {
        label: 'Cash',
        filter: {
          column: _state.allColumnsObject['cash'].account,
          operator: '>',
          value: '95',
        },
      },
      {
        label: 'Stocks',
        filter: {
          column: _state.allColumnsObject['stocks'].account,
          operator: '>',
          value: '95',
        },
        presets: HoldingsUtil.generateGroupPresets( _state, [
          'Stock Global Distribution',
          'Capitalization Distribution',
          'Value, Blend, Growth',
          'Stock Sector Distribution',
        ] ),
      },
      {
        label: 'Bonds',
        filter: {
          column: _state.allColumnsObject['bonds'].account,
          operator: '>',
          value: '95',
        },
        presets: HoldingsUtil.generateGroupPresets( _state, [
          'Bond Global Distribution',
          'Credit Quality Distribution',
          'Maturity Range Distribution',
          'Bond Sector Distribution',
        ] ),
      },
      {
        label: 'ETF',
        filter: {
          column: _state.allColumnsObject['security_type'].account,
          operator: '=',
          value: 'ETF',
        },
      },
      {
        label: 'Mutual Fund',
        filter: {
          column: {
            prop: 'security_type',
          },
          operator: '=',
          value: 'Mutual Fund',
        },
      },

    ];
  }

  static getColumnPreset( _state: GlobalState, col: string ) {
    return {
      label: _state.allColumnsObject[col].account.name,
      filter: {
        column: _state.allColumnsObject[col].account,
        operator: '>',
        value: '95',
      },
    };
  }

  static getGroupPresets( _state: GlobalState, groupLabel: string ) {
    const groupPresets = [];

    const group = _.find( _state.columnGroupings, ( cg: any ) => {
      return cg.label === groupLabel;
    } );

    for ( const col of group.columns ) {
      groupPresets.push( HoldingsUtil.getColumnPreset( _state, col ) );
    }

    return groupPresets;
  }

  static generateGroupPresets( _state: GlobalState, groups: string[] ) {
    const presets = [];
    for ( const group of groups ) {
      presets.push( { label: group, presets: HoldingsUtil.getGroupPresets( _state, group ) } );
    }

    return presets;
  }

}
