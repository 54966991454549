<rip-holdings-toolbar #holdingsToolbar *ngIf="!deviceIsMobile"
                      [topBarMargin]="topBarMargin"
                      [pricesRefreshing]="pricesRefreshing"
                      [showTable]="showTable"
                      (refreshPrices)="refreshPrices()"
                      (filter)="filterHoldings($event)"
                      (clear)="clearFilter()"
                      (expandAll)="expandAll()"
                      (collapseAll)="collapseAll()"
                      (flipTable)="flipTable()">

</rip-holdings-toolbar>
<div class="holdings-container" *ngIf="!deviceIsMobile">
  <ng-container *ngTemplateOutlet="holdingsCard"></ng-container>

</div>

<div class="holdings-container mobile-container" *ngIf="deviceIsMobile">
  <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
</div>

<ng-template #holdingsCard>
  <nb-flip-card [showToggleButton]="false" [flipped]="!showTable" *ngIf="dataRetrieved; else listSkeleton">
    <!--<mat-card-header>
      <mat-card-title>
        Holdings
      </mat-card-title>
    </mat-card-header>-->
    <nb-card-front>
      <nb-card>
        <nb-card-body>
          <div class="holdings-table-container">
            <div *ngIf="showTable">
              <ngx-datatable #dataTable
                             [rows]="rows"
                             [columns]="columns"
                             [scrollbarH]="!deviceIsMobile"
                             [columnMode]="'force'"
                             [headerHeight]="tableHeaderHeight"
                             [rowHeight]="tableRowHeight"
                             [footerHeight]="50"
                             [messages]="messages"
                             [cssClasses]="cssClasses"
                             [reorderable]="false"
                             [rowClass]="getRowClass"
                             [virtualization]="false"
                             class="material striped expandable">
                <!-- ROW DETAIL TEMPLATE -->
                <ngx-datatable-row-detail [rowHeight]="375" #myDetailRow *ngIf="!deviceIsMobile">
                  <ng-template let-row="row" let-expanded="expanded"
                               ngx-datatable-row-detail-template>
                    <div class="row no-gutters row-detail-container">
                      <div class="col-12">
                        <div class="row no-gutters">
                          <div class="row-detail-column" [ngClass]="{
                                                            'col-5': !row.shouldBeFullWidth,
                                                            'col-12': row.shouldBeFullWidth
                                                            }">
                            <div>
                              <div class="row no-gutters">
                                <div class="col-1">

                                </div>

                                <div class="col-4 account-name-column holdings-account-table-column"
                                     title="Account">
                                  Account
                                </div>
                                <div class="col-2 value-column holdings-account-table-column"
                                     style="font-weight: bold;"
                                     title="Market Value">
                                  Market Value
                                </div>
                                <div class="col-2 value-column holdings-account-table-column"
                                     style="font-weight: bold;"
                                     title="{{ row.isLoan ? 'Outstanding Balance' : 'Cost Basis'}}"
                                     *ngIf="!row.isCredit && !row.isSSorPension">
                                  {{ row.isLoan ? 'Outstanding Balance' : 'Cost Basis' }}
                                </div>
                                <div class="col-2 gain-loss-column holdings-account-table-column"
                                     style="font-weight: bold;"
                                     title="Unrealized Gain/Loss"
                                     *ngIf="!row.isCredit && !row.isSSorPension">
                                  Gain/Loss
                                </div>
                              </div>
                            </div>
                            <div *ngFor="let p of row.positions; let i = index">
                              <div class="row no-gutters">
                                <div class="col-1 holdings-account-table-column">
                                  <button mat-raised-button
                                          class="go-to-account-button"
                                          (click)="goToAccount(p.position.account_id)"
                                          matTooltip="Go To Account"
                                          matTooltipClass="mat-tooltip-custom"
                                          matTooltipPosition="right">
                                    <fa-icon [icon]="faUniversity" fixedWidth
                                             size="lg"></fa-icon>
                                  </button>
                                </div>
                                <div class="col-4 account-name-column holdings-account-table-column"
                                     title="{{p.account.formattedDescription}}">
                                  {{ p.account.formattedDescription | ripTruncatePipe : ( row.shouldBeFullWidth ? truncationNumber * 3 : truncationNumber ) }}
                                </div>
                                <div class="col-2 value-column holdings-account-table-column"
                                     title="{{ p.position.value | ripCurrencyPipe }}">
                                  {{ p.position.value | ripCurrencyPipe }}
                                </div>
                                <div class="col-2 value-column holdings-account-table-column"
                                     title="{{ p.position.cost_basis | ripCurrencyPipe }}"
                                     *ngIf="!row.isCredit && !row.isSSorPension">
                                  {{ p.position.cost_basis | ripCurrencyPipe }}
                                </div>
                                <div class="col-2 value-column holdings-account-table-column"
                                     title="{{ p.position.gain_loss | ripCurrencyPipe }}"
                                     *ngIf="!row.isCredit && !row.isSSorPension">
                                  <div *ngIf="p.position.gain_loss" class="gain-loss"
                                       [ngClass]="{
                                'up': p.position.gain_loss > 0,
                                'down': p.position.gain_loss < 0
                              }">
                                    <mat-grid-list cols="1" rowHeight="20px">
                                      <mat-grid-tile [colspan]="1" [rowspan]="1">
                                  <span *ngIf="p.position.gain_loss > 0">
                                    <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
                                  </span>
                                        <span *ngIf="p.position.gain_loss < 0">
                                    <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
                                  </span>
                                        {{ p.position.cost_basis !== undefined ? ( p.position.gain_loss / p.position.cost_basis | ripLongPercentPipe ) : 'N/A' }}
                                      </mat-grid-tile>
                                      <mat-grid-tile [colspan]="1" [rowspan]="1">
                                        {{ p.position.cost_basis !== undefined ? ( p.position.gain_loss | ripCurrencyPipe ) : 'N/A' }}
                                      </mat-grid-tile>
                                    </mat-grid-list>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 row-detail-column"
                               style="white-space: pre-wrap; padding-top: 18px;"
                               *ngIf="!row.shouldBeFullWidth">
                            <div *ngIf="row.security_type === 'Mutual Fund' || row.security_type === 'ETF'">
                              <h5>
                                Fund Investment Strategy:
                              </h5>
                              <p>
                                {{ row.investment_strategy || 'No Investment Strategy Provided' }}
                              </p>
                            </div>
                            <div *ngIf="row.security_type === 'Equity'">
                              <p>
                                <rip-trading-view-fundamentals [symbol]="row.ticker"
                                                               (errorLoading)="row.hideFundamentals = true;"></rip-trading-view-fundamentals>
                              </p>
                              <p *ngIf="row.hideFundamentals">No Financials Data Found For
                                This Security</p>
                            </div>
                          </div>
                          <div class="col-4 row-detail-column"
                               style="white-space: pre-wrap; padding-top: 18px;"
                               *ngIf="!row.shouldBeFullWidth">
                            <div *ngIf="row.security_type === 'ETF' || row.security_type === 'Equity' || row.security_type === 'Crypto'">
                              <!--<rip-trading-view-chart [ticker]="row.ticker"></rip-trading-view-chart>-->
                              <!--                        <rip-stockdio-chart [ticker]="row.ticker"></rip-stockdio-chart>-->
                              <!--<rip-trading-view-mini-chart [ticker]="row.ticker">

                              </rip-trading-view-mini-chart>-->
                              <rip-trading-view-symbol-overview [ticker]="row.ticker"
                                                                [description]="row.ticker_name"
                                                                [brandingLabel]="row.ticker + ' data'"
                                                                [initChartRightAway]="true">

                              </rip-trading-view-symbol-overview>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-row-detail>
                <ngx-datatable-footer>
                  <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
                    <div style="padding: 5px 10px;">
                      <div>
                        <strong>Holdings</strong>: {{ rowCount }} |
                        <strong>Value</strong>: {{ total | ripCurrencyPipe }}
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-card-front>
    <nb-card-back>
      <nb-card>
        <nb-card-body>
          <div class="ripsaw-card-header" style="padding: 20px">Holdings Allocation</div>
          <div class="holdings-table-container">
            <div *ngIf="!showTable" id="holdings-chart-container">

              <div *ngIf="rows.length === 0">No data matches current filter</div>
              <canvas baseChart #holdingsChart="base-chart" *ngIf="rows.length > 0"
                      [datasets]="chartData"
                      [labels]="chartLabels"
                      [options]="chartOptions"
                      [plugins]="plugins"
                      [legend]="legend"
                      [type]="chartType">
              </canvas>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-card-back>
  </nb-flip-card>
</ng-template>

<ng-template ngx-datatable-cell-template #rowDetailCellTemplate let-row="row" let-expanded="expanded">
  <a
          href="javascript:void(0)"
          [class.datatable-icon-right]="!expanded"
          [class.datatable-icon-down]="expanded"
          title="Expand/Collapse Row"
          (click)="toggleExpandRow(row, expanded)"
  >
  </a>
</ng-template>

<ng-template ngx-datatable-header-template #headerTemplate let-column="column">
    <span matTooltip="{{ column.longName ? column.longName : column.name }}"
          matTooltipClass="mat-tooltip-custom"
          [ngClass]="{
          'title-with-disclaimer': column.disclaimer !== undefined,
          'sortable': column.sortable
          }"
          (click)="column.sortable ? onSort($event) : ''"
          id="{{column.prop}}">
      {{ column.name }}
      <mat-icon *ngIf="lastSort.sort === column.prop && lastSort.dir === 'dsc'" [style.font-size]="'12px'">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="lastSort.sort === column.prop && lastSort.dir  === 'asc'" [style.font-size]="'12px'">keyboard_arrow_up</mat-icon>
      <span class=" disclaimer" (click)="openDisclaimerModal( column.disclaimer )"
            *ngIf="column.disclaimer !== undefined">
        <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
      </span>
    </span>
  <!-- <span matTooltip="{{ column.longName ? column.longName : column.name }}"
         matTooltipClass="mat-tooltip-custom"
         *ngIf="!column.sortable">
   {{ column.name }}
   </span>-->
</ng-template>

<ng-template ngx-datatable-cell-template #oneDayChangeTemplate let-row="row" let-rowIndex="rowIndex">
  <div *ngIf="row.percentChange && row.security_type !== 'Money Market' && row.security_type !== 'Money Market Fund'"
       class="one-day-change" [ngClass]="{
          'up': row.percentChange > 0,
          'down': row.percentChange < 0
        }">
    <mat-grid-list cols="1" rowHeight="20px">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <span *ngIf="row.percentChange > 0">
          <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="row.percentChange < 0">
          <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        {{ row.dollarChange | ripCurrencyPipe }}
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        {{ row.percentChange | ripLongPercentPipe }}
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-template>

<ng-template ngx-datatable-cell-template #descriptionTemplate let-value="value" let-rowIndex="rowIndex" let-row="row">
  <span
          matTooltip="{{ value }}" matTooltipClass="mat-tooltip-custom">
    {{ value | ripTruncatePipe : 20 }}
            </span>

</ng-template>

<ng-template ngx-datatable-cell-template #priceTemplate let-row="row" let-rowIndex="rowIndex">
  <div title="{{ getAsOfDate(row) | ripCurrencyPipe }}">
    <div *ngIf="row.price_altered">
      {{ row.price_alteration_type === 'bond' ? row.price * 100 : row.price / 100 | ripCurrencyPipe }}
    </div>
    <div *ngIf="!row.price_altered">
      {{ row.price | ripCurrencyPipe }}
    </div>
  </div>
</ng-template>

<ng-template ngx-datatable-cell-template #overrideTemplate let-row="row" let-rowIndex="rowIndex">
  <fa-icon *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]" [icon]="faEdit" [fixedWidth]="true" size="sm"
           class="override-button {{ row.missing_data ? 'missing-data' : '' }}"
           (click)="openOverrideModal( row )"
           matTooltip="Click to Override Security Data{{ row.missing_data ? ' (This security is missing some key data that we need to calculate its market value or include it in the global dashboard aggregation)' : '' }}"
           matTooltipClass="mat-tooltip-custom"></fa-icon>
</ng-template>


<ng-template #mobileTemplate>
  <nb-card *ngIf="dataRetrieved; else listSkeleton">
    <nb-card-body style="padding: 0;">
      <table mat-table
             [dataSource]="rows"
             id="mobile-holdings-table"
             matSort
             matSortActive="one_day_change" matSortDirection="asc">
        <ng-container matColumnDef="ticker">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mobile-holdings-table-cell-left"
              (click)="onMatTableSort('ticker')">
            Ticker
          </th>
          <td mat-cell *matCellDef="let holding" class="mobile-holdings-table-cell-left">
            {{ holding.ticker }}
          </td>
          <td mat-footer-cell *matFooterCellDef><strong>Holdings</strong>: {{ rows.length }}</td>
        </ng-container>
        <ng-container matColumnDef="one_day_change">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mobile-holdings-table-cell-center"
              (click)="onMatTableSort('one_day_change')">
            <!-- don't have the column def here like in desktop, so we need to be specific with the disclaimer index-->
            1-Day Return <span class=" disclaimer" (click)="openDisclaimerModal( 0 )">
        <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
      </span>
          </th>
          <td mat-cell *matCellDef="let holding" class="mobile-holdings-table-cell-center">
            <ng-container *ngTemplateOutlet="oneDayChangeTemplate; context: {row: holding}"></ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mobile-holdings-table-cell-right"
              (click)="onMatTableSort('value')">
            Value/Allocation
          </th>
          <td mat-cell *matCellDef="let holding" class="mobile-holdings-table-cell-right">
            <div>{{ holding.value | ripCurrencyPipe }}</div>
            <div>{{ holding.allocation | ripPercentPipe }}</div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="mobile-holdings-table-cell-right">
            <strong>Total</strong>: {{ total | ripCurrencyPipe }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mobileColumnsList"></tr>
        <tr mat-row *matRowDef="let row; columns: mobileColumnsList" (click)="rowClicked(row)"></tr>
        <tr mat-footer-row *matFooterRowDef="mobileColumnsList"></tr>
      </table>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #listSkeleton>
  <div class="custom-skeleton p-4">
    <ul class="m-0 o-0">
      <li class="mb-3" *ngFor="let e of Array.from(Array(5))">
        <div class="flexing-row-only">
          <div>
          <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
          </div>
          <div style="flex: 5;">
            <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="75%" styleClass="mb-2"></p-skeleton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
