import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalState } from '../global.state';


@Injectable()
export class LoginGuard implements CanActivate {

  private _state: GlobalState = inject( GlobalState );
  private router: Router = inject( Router );

  canActivate(): boolean {
    const inWealthFluent: boolean = this._state.globalVars.inWealthFluent;
    if ( inWealthFluent ) {
      this.router.navigate( [ '/something-went-wrong' ] ).catch( ( err ) => {
        console.error( err );
      } );
    }
    return inWealthFluent;
  }
}