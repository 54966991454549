<div #contentTop class="content-top clearfix" [ngClass]="{'mobile-class': deviceIsMobile}">
  <div #tableContainer class="row table-container" [style.display]="showGlobalWidget ? '' : 'none'">
    <rip-allocation-widget class="rip-widget" [ngClass]="{'mobile-class': deviceIsMobile}">
    </rip-allocation-widget>
    <!--<h1 class="al-title" translate>{{ activePageTitle }}</h1>-->
  </div>

  <!-- <ul class="breadcrumb al-breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/pages/dashboard" translate>{{'general.home'}}</a>
    </li>
    <li class="breadcrumb-item active" translate>{{ activePageTitle }}</li>
  </ul>-->
</div>
