<ng-template #formField let-key="key">
    <mat-form-field [ngClass]="{'missing-data': key.fieldHasMissingData}">

        <!-- for decimal inputs -->
        <input matInput #decimalInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="decimal_{{key.key}}"
               [errorStateMatcher]="matcher"
               (change)="onInputChange(decimalInput, key.key, 'decimal')"
               placeholder="{{columns[key.key].name}} {{ key.key === 'loan_term' ? '(Years)' : '' }}"
               *ngIf="key.inputType === 'decimal'">
        <!-- for percent inputs -->
        <input matInput #percentInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="percent_{{key.key}}"
               [errorStateMatcher]="matcher"
               (change)="onInputChange(percentInput, key.key, 'percent')"
               placeholder="{{columns[key.key].name}} (%)"
               *ngIf="key.inputType === 'percent'">
        <!-- for currency inputs -->
        <input matInput #currencyInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="currency_{{key.key}}"
               [errorStateMatcher]="matcher"
               (change)="onInputChange(currencyInput, key.key, 'currency')"
               placeholder="{{columns[key.key].name}} ($)"
               *ngIf="key.inputType === 'currency'">

        <!-- for text inputs -->
        <input matInput #textInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="text_{{key.key}}"
               [errorStateMatcher]="matcher"
               placeholder="{{columns[key.key].name}}"
               *ngIf="key.inputType === 'string'">
        <!-- for select inputs -->
        <mat-select *ngIf="key.inputType === 'select'"
                    #selectBox
                    panelClass="max-select-box-height"
                    [formControl]="$any(form).controls[key.key]"
                    placeholder="{{columns[key.key].name}}"
                    (selectionChange)="onSelectionChange(selectBox, key.key)">
            <!--<mat-option *ngIf="!oHelpers.getSelectOptions(key).includes(form.controls[key].value)"
                        value="{{form.controls[key].value}}">
              {{form.controls[key].value}}
            </mat-option>-->
            <mat-option *ngFor="let option of key.selectOptions" value="{{option}}">
                {{option}}
            </mat-option>
        </mat-select>
        <!-- for date inputs -->
        <div *ngIf="key.inputType === 'date'" class="date-picker-container">
            <input matInput #dateInput
                   name="{{key.key}}"
                   [formControl]="$any(form).controls[key.key]"
                   id="date_{{key.key}}"
                   [errorStateMatcher]="matcher"
                   placeholder="{{columns[key.key].longName || columns[key.key].name}}"
                   [matDatepicker]="datepicker"
                   (dateChange)="dateChanged(dateInput, key.key)">
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
        </div>

        <mat-error *ngIf="key.isLoanField && (position && !position[key] || position[key] === '' )">
            Entering Data in this field will help us calculate a market value for this loan
        </mat-error>
    </mat-form-field>
</ng-template>

<h3 mat-dialog-title> {{ modalTitle }} </h3>
<mat-divider class="modal-header-divider"></mat-divider>
<!-- for ticker only-->
<!--<button mat-button type="button" (click)="openInvestmentSelector()">
  Pick Proxy
</button>
<button mat-button type="button" (click)="removeAllOverrides()">
  Remove All Overrides
</button>-->
<div mat-dialog-content class="modal-body manual-security-editor-modal-body">
    <form autocomplete="off" [formGroup]="form">
        <div class="override-form-container">
            <!-- Using a material vertical stepper to make groupings for the override fields so the form isn't just
            one long list -->
            <mat-vertical-stepper *ngIf="!loadingSecurityData">
                <!-- Overriding the icons so there aren't numbers which would normally be used to denote a flow -->
                <ng-template matStepperIcon="done">
                    <mat-icon>dehaze</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="edit">
                    <mat-icon>dehaze</mat-icon>
                </ng-template>
                <!-- number is the active step icon type -->
                <ng-template matStepperIcon="number">
                    <mat-icon>create</mat-icon>
                </ng-template>
                <!-- Make a step for each key set -->
                <mat-step *ngFor="let set of activeKeySets" [completed]="true">
                    <!-- label the step header with the set's label -->
                    <ng-template matStepLabel>
                        {{ set.label }}
                    </ng-template>

                    <!-- Make a form field for each key in the set -->
                    <div *ngFor="let key of set.keys" class="manual-security-form-field-container">
                        <ng-container *ngTemplateOutlet="formField; context:{'key': key}">
                        </ng-container>
                    </div>

                    <div *ngFor="let group of set.groups">
                        <p class="group-header" [ngClass]="{
                                'missing-data': group.groupHasMissingData
                            }">{{ group.label }}</p>
                        <div *ngFor="let key of group.keys" class="manual-security-form-field-container">
                            <ng-container *ngTemplateOutlet="formField; context:{'key': key}">
                            </ng-container>
                        </div>
                        <div *ngIf="group.group.footnote" class="footnote">
                            {{ group.group.footnote }}
                        </div>
                    </div>
                </mat-step>

            </mat-vertical-stepper>

        </div>
    </form>
</div>
<mat-divider class="modal-footer-divider"></mat-divider>

<div mat-dialog-actions>
    <button mat-button (click)="close()" type="button">{{ unsavedChanges ? 'Cancel' : 'Close' }}</button>

    <rip-progress-bar-button (onClick)="submit()" [options]="saveButtonOptions" [disabled]="!unsavedChanges">

    </rip-progress-bar-button>

    <button mat-button color="warn" *ngIf="forRevision && origPosition?.newSecurity"
            (click)="removeNewPosition()">
        Remove
    </button>
</div>
