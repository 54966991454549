import { Component, ViewEncapsulation } from '@angular/core';

@Component( {
  selector: 'rip-transactions-page',
  templateUrl: './transactions-page.component.html',
  styleUrls: [ './transactions-page.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class TransactionsPageComponent {


}
