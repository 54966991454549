import { AfterViewInit, Component, Input } from '@angular/core';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { UntypedFormGroup } from '@angular/forms';
import { ManualAccountErrorStateMatcher, ManualAccountManagerState, } from '../../../../../utils/manualAccountManager.state';
import { environment } from '../../../../../../environments/environment';

@Component( {
  selector: 'rip-account-short-form',
  template: `
    <form [formGroup]="form" *ngIf="form">
      <div class="form-horizontal" *ngIf="showName || showDescription">
        <mat-form-field>
          <label>
            <input matInput
                   formControlName="{{ showName ? 'name' : 'description' }}"
                   [errorStateMatcher]="matcher"
                   placeholder="Account Name / Description*">
          </label>
        </mat-form-field>
      </div>

      <div *ngIf="this.showVehicleFields">
        <div class="form-group">
          <mat-form-field>
            <label>
              <input matInput
                     formControlName="year"
                     [errorStateMatcher]="matcher"
                     placeholder="Year">
            </label>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <label>
              <input matInput
                     formControlName="make"
                     [errorStateMatcher]="matcher"
                     placeholder="Make">
            </label>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <label>
              <input matInput
                     formControlName="model"
                     [errorStateMatcher]="matcher"
                     placeholder="Model">
            </label>
          </mat-form-field>
        </div>
      </div>
    </form>
  `,
  styleUrls: [ `accountShortForm.component.scss` ],
} )

/**
 * This is for manual accounts
 */
export class AccountShortFormComponent implements AfterViewInit {

  @Input() account: Account;
  @Input() form: UntypedFormGroup;
  @Input() matcher: ManualAccountErrorStateMatcher;

  showName: boolean = false;
  showDescription: boolean = false;
  showVehicleFields: boolean = false;

  constructor( private mamState: ManualAccountManagerState ) {
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_shortForm' ] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {

      this.showName = this.form?.controls?.name?.value?.length > 0;
      this.showDescription = this.form?.controls?.description?.value?.length > 0;
      if ( !this.showName && !this.showDescription ) {
        if ( this.form?.controls?.make?.value?.length > 0 ) {
          this.showVehicleFields = true;
        }
      }

      if ( this.showName ) {
        // this.form.controls.name.setValue( this.account.name );
      }

      if ( this.showDescription ) {
        // this.form.controls.description.setValue( this.account.description );
      }
    } );
  }
}

