import { Component, inject, Input, OnDestroy } from '@angular/core';
import { CrossAppMessageType, DialogName, HouseholdMember } from '@ripsawllc/ripsaw-analyzer';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { faPlus } from '@fortawesome/sharp-solid-svg-icons/faPlus';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { DeleteHouseholdMemberDialogComponent } from './delete-household-member-dialog.component';
import { environment } from '../../../environments/environment';
import { HouseholdMembersState } from '../../utils/household-members.state';
import { WithWorkspacePermission } from '../../shared/workspace-permission';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { WealthFluentMessageService } from '../../theme/services';
import { takeUntil } from 'rxjs/operators';


@Component( {
  selector: 'rip-household-members',
  template: `
    <div class="flexing vertical-flex space-between"
         style="height: 100%;" [nbSpinner]="loading">

      <div class="household-members-container" [style.position]="'relative'">
        <nb-badge *ngIf="errorLoading"
                  text="{{ errorLoading }}. Click here to try again."
                  position="top left" (click)="householdMembersState.getHouseholdMembers()"
                  class="button-badge"
                  status="danger">
        </nb-badge>
        <div class="flexing-row-only center-vertically">
          <div class="ripsaw-card-header" style="text-align: left;">
            Additional Household Members
          </div>
        </div>
        <div>
          <div class="flexing-row-only space-between household-member-header-row">
            <div *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]"
                 class="household-member-header-cell">
              <button mat-raised-button (click)="openAddDialog()"
                      class="icon-button"
                      matTooltip="Add Household Member"
                      matTooltipClass="mat-tooltip-custom">
                <fa-icon [icon]="faPlus" fixedWidth size="1x"></fa-icon>
              </button>
            </div>
            <div class="household-member-header-cell">Name</div>
            <div class="household-member-header-cell">Relation</div>
            <div class="household-member-header-cell">Birth Date</div>
            <div class="household-member-header-cell"></div>
          </div>
          <div *ngFor="let member of householdMembersState.householdMembers; let i = index"
               class="flexing-row-only space-between household-member-row">
            <div *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]"
                 class="household-member-cell">
              <button mat-raised-button class="icon-button" (click)="openEditDialog( member )"
                      matTooltip="Edit Household Member" matTooltipClass="mat-tooltip-custom">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
              <button mat-raised-button class="icon-button" (click)="openDeleteConfirmation( member, i)"
                      matTooltip="Remove Household Member" matTooltipClass="mat-tooltip-custom">
                <fa-icon [icon]="faTrashCan"></fa-icon>
              </button>
            </div>
            <div class="household-member-cell">{{ member.name }}</div>
            <div class="household-member-cell">{{ member.relation | titlecase }}</div>
            <div class="household-member-cell">{{ member.birth_date | ripLongDatePipe }}</div>
            <div class="household-member-cell">{{ member.retired ? 'Retired' : '' }}</div>
          </div>
          <div *ngIf="householdMembersState.householdMembers?.length === 0">
            No household members added yet
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [ './household-members.component.scss' ],
} )

export class HouseholdMembersComponent extends WithWorkspacePermission implements OnDestroy {

  @Input() onboarding: boolean;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  faPlus: IconDefinition = faPlus;
  faEdit: IconDefinition = faEdit;
  faTrashCan: IconDefinition = faTrashCan;
  loading: boolean = true;
  errorLoading: string;

  constructor( public dialog: MatDialog,
               public householdMembersState: HouseholdMembersState,
  ) {
    super();

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_household-members' ] = this;
    }
    this.loading = this.householdMembersState.loading;

    this.householdMembersState.membersLoaded.pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( loaded: boolean ) => {
          this.loading = !loaded;
          this.errorLoading = undefined;
        }, error: ( err ) => {
          console.error( err );
          this.loading = false;
          this.errorLoading = 'Error loading Household Members';
        },
      } );
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  openAddDialog(): void {
    this.wealthFluentMessageService.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      message: 'Open Add Household Member Dialog',
      data: {
        dialogName: DialogName.ADD_HOUSEHOLD_MEMBER,
      },
    } );
  }

  openEditDialog( member: HouseholdMember ): void {
    this.wealthFluentMessageService.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      message: 'Open Edit Household Member Dialog',
      data: {
        dialogName: DialogName.EDIT_HOUSEHOLD_MEMBER,
        member,
      },
    } );
  }

  openDeleteConfirmation( member: HouseholdMember, index: number ) {
    const ref = this.dialog.open( DeleteHouseholdMemberDialogComponent, {
      hasBackdrop: true,
      width: '600px',
      // height: '500px',
      data: member,
    } );

    ref.afterClosed().subscribe( {
      next: ( deleted ) => {
        if ( deleted ) {
          this.householdMembersState.householdMembers.splice( index, 1 );
        }
      },
    } );
  }

}
