import { Injectable, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { GoalType, HouseholdMember, ScheduledGoalWithdrawal } from './dataInterfaces';
import { Util } from './util.service';
import * as _ from 'lodash-es';
import { GoalsUtil } from './goals.util';
import { Observable, Subject } from 'rxjs';
import { GoalsService } from '../globalData/goals.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HouseholdMembersState } from './household-members.state';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RipsawCurrencyPipe } from '../theme/pipes';
import { TitleCasePipe } from '@angular/common';
import { EVENT_NAMES, USER_GOAL_FORM_TYPES, USER_GOAL_TYPE_IDS } from './enums';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons/faEllipsis';
import { faUmbrellaBeach } from '@fortawesome/pro-light-svg-icons/faUmbrellaBeach';
import { environment } from '../../environments/environment';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { defaultColors } from './default-colors';
import { GlobalState } from '../global.state';
import { Auth } from '../auth.service';
import { AppStoreService } from '../store';
import { InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from './logger.service';

@Injectable()
export class GoalsState implements OnDestroy {

  private readonly onDestroy = new Subject<void>();
  readonly update: Subject<void> = new Subject<void>();
  goalTypes: GoalType[] = GoalsUtil.goalTypes;

  iconsMap: any = {};
  defaultIcon: IconDefinition = faEllipsis;
  retirementIcon: IconDefinition = faUmbrellaBeach;
  defaultColor: string = '#79afd7';

  goals: InvestorGoal[] = [];

  expectedReturnForFVCalc: number = 0;

  idOfGoalBeingRemoved: string;
  idsOfGoalsBeingUpdated: any = {};
  anyGoalBeingUpdated: boolean = false;

  // Fields for the form(s) that are shared by multiple instances of the form components /////////////////////
  selectedType: GoalType;
  goalForm: UntypedFormGroup;
  existingGoal: InvestorGoal;
  existingGoalIndex: number;
  fieldsToShow: number = 1;
  withdrawalSchedule: string;
  dateOfCompletion: Date;
  goalDates: Date[] | ScheduledGoalWithdrawal[];
  completeEarly: boolean;
  goalIsLessThanAYearAway: boolean;
  goalAmount: number;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  goalRetrievalError: boolean = false;

  goalsListSpinner: string = 'goals-list-spinner';

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  titleCasePipe: TitleCasePipe = new TitleCasePipe();

  goalDataInitialized: Subject<void> = new Subject<void>();

  subscriberName: string = 'GoalsState';

  constructor( private _goalsService: GoalsService,
               public snackBar: MatSnackBar,
               public householdMembersState: HouseholdMembersState,
               private _state: GlobalState,
               private _auth: Auth,
               private appStoreService: AppStoreService,
  ) {

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_goalsState' ] = this;
    }

    this.makeIconMap();
    this.getGoalsByLoadedWorkspace();

    _state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.resetAfterLogout();
    }, this.subscriberName );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [ EVENT_NAMES.LOGOUT, EVENT_NAMES.LOGGED_IN ].join( ' | ' ), this.subscriberName );
  }

  resetAfterLogout() {
    this.goals = undefined;
    this.clearFormData();
    this.goalRetrievalError = false;
    this.idOfGoalBeingRemoved = undefined;
    this.idsOfGoalsBeingUpdated = {};
    this.anyGoalBeingUpdated = false;
    this.goalTypes = GoalsUtil.goalTypes;
  }

  makeIconMap() {
    for ( const type of this.goalTypes ) {
      this.iconsMap[ type.id ] = type.icon;
    }
  }

  getGoals() {
    if ( this._auth.authenticated() ) {
      // this._spinnerService.show( this.goalsListSpinner );
      this._goalsService.getAllGoals()
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this.goals = resp.data ?? [];
            this.sortGoalsByPriority();
            this.filterOutCertainTypes();

            this.update.next();
            this.goalRetrievalError = false;
          },
          error: ( err ) => {
            console.error( err );
            this.goalRetrievalError = true;
          },
        } );
    }
  }

  filterOutCertainTypes() {
    // reset to full list and then filter out types that can only have 1 instance
    this.goalTypes = GoalsUtil.goalTypes;
    // if there is a retirement goal already, remove the retirement type from the list for now
    if ( this.goals.find( ( g: InvestorGoal ) => {
      return g.type === USER_GOAL_TYPE_IDS.retirement;
    } ) ) {
      // filter on the goal type id
      this.goalTypes = this.goalTypes.filter( ( gt: GoalType ) => {
        return gt.id !== USER_GOAL_TYPE_IDS.retirement;
      } );
    }

    // if there is an emergency fund goal already, remove the emergency fund type from the list for now
    if ( this.goals.find( ( g: InvestorGoal ) => {
      return g.type === USER_GOAL_TYPE_IDS.emergency_fund;
    } ) ) {
      // filter on the goal type id
      this.goalTypes = this.goalTypes.filter( ( gt: GoalType ) => {
        return gt.id !== USER_GOAL_TYPE_IDS.emergency_fund;
      } );
    }
  }

  sortGoalsByPriority() {
    this.goals.sort( ( a: InvestorGoal, b: InvestorGoal ) => {
      return a.priority < b.priority ? -1 : 1;
    } );
  }

  addNewGoalToList( goal: InvestorGoal ) {
    this.goals.push( goal );
  }

  updateGoalInList( event: any ) {
    if ( isNaN( event.index ) ) {
      const index = this.goals.findIndex( ( g: InvestorGoal ) => {
        return g.id === event.goal.id;
      } );
      this.goals[ index ] = event.goal;
    } else {
      this.goals[ event?.index ] = event.goal;
      this.update.next();
    }
  }

  selectGoalTypeForNewGoal( type: GoalType ) {
    this.selectedType = type;
  }

  editGoal( goal: InvestorGoal, index: number ) {
    this.selectedType = GoalsUtil.goalTypes.find( ( t: GoalType ) => {
      return t.id === goal.type;
    } );
    this.existingGoal = goal;
    this.existingGoalIndex = index;
  }

  initForm( type: GoalType, goal?: InvestorGoal, index?: number ) {
    this.clearFormData();
    this.selectedType = type;
    if ( goal ) {
      this.editGoal( goal, index );
    } else {
      this.selectGoalTypeForNewGoal( type );
    }
    switch ( type.form ) {
      case USER_GOAL_FORM_TYPES.retirement:
        this.initRetirementForm();
        break;
      case USER_GOAL_FORM_TYPES.noWithdrawal:
        this.initNoWithdrawalForm();
        break;
      default: // generic
        this.initGenericForm();
        break;
    }

    this.goalDataInitialized.next();

    if ( !goal ) {
      this.setRandomColorForNewForm( this.goalForm );
    }
  }

  initGenericForm() {

    this.fieldsToShow = this.existingGoal ? 5 : 2;
    this.goalForm = new UntypedFormGroup( {
      name: new FormControl<string>( this?.existingGoal?.name ?? GoalsUtil.transformIdToDefaultName( this?.selectedType?.id ), Validators.required ),
      total_withdrawal: new FormControl<number>( null, Validators.required ),
      goal_date: new FormControl<Date | string>( this.existingGoal?.goal_date ?? null, Validators.required ),
      owners: new UntypedFormControl( this.existingGoal?.owners ?? [] ),
      annualContributions: new FormControl<number>( null, Validators.required ),
      monthlyContributions: new FormControl<number>( null ),
      include: new FormControl<boolean>( this?.existingGoal?.include ?? true ),
      color: new FormControl<string>( this.existingGoal?.color ?? this.defaultColor ),
    } );

    this.goalForm.markAsPristine();

    if ( this.existingGoal ) {
      this.goalForm.controls.total_withdrawal.setValue( this.ripCurrencyPipe.transform( this.existingGoal.total_withdrawal ) );
      this.goalForm.controls.annualContributions.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualContributions ) );
      this.goalForm.controls.monthlyContributions.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualContributions / 12 ) );
      if ( this.existingGoal.withdrawal_dates?.length > 1 ) {
        this.withdrawalSchedule = 'schedule';
        this.goalDates = this.existingGoal.withdrawal_dates;
      } else {
        this.withdrawalSchedule = 'once';
      }
    }

  }

  initRetirementForm() {
    this.fieldsToShow = this.existingGoal ? 2 : 1;
    this.goalForm = new UntypedFormGroup( {
      name: new FormControl<string>( this.existingGoal?.name ?? GoalsUtil.transformIdToDefaultName( this.selectedType?.name ), Validators.required ),
      // total_withdrawal: new FormControl<number>( null, Validators.required ),
      goal_date: new FormControl<Date>( this.existingGoal?.goal_date ? new Date( this.existingGoal.goal_date ) : null, Validators.required ),
      owners: new FormControl<HouseholdMember>( null ),
      annualContributions: new FormControl<number>( null, Validators.required ),
      monthlyContributions: new FormControl<number>( null, Validators.required ),
      annualWithdrawals: new FormControl<number>( null, Validators.required ),
      lengthOfWithdrawals: new FormControl<number>( null, Validators.required ),
      include: new FormControl<boolean>( this?.existingGoal?.include ?? true ),
      color: new FormControl<string>( this.existingGoal?.color ?? this.defaultColor ),
    } );

    this.goalForm.markAsPristine();

    if ( this.existingGoal ) {

      this.goalForm.controls.owners.setValue( this.existingGoal.owners[ 0 ] ); // this type of goal should never have more than one owner
      this.goalForm.controls.monthlyContributions.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualContributions / 12 ) );
      this.goalForm.controls.annualContributions.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualContributions ) );
      // this.goalForm.controls.total_withdrawal.setValue( this.ripCurrencyPipe.transform( this.existingGoal.total_withdrawal ) );
      this.goalForm.controls.annualWithdrawals.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualWithdrawals ) );
      this.goalForm.controls.lengthOfWithdrawals.setValue( this.existingGoal.lengthOfWithdrawals );
    } else {

    }
  }

  initNoWithdrawalForm() {
    this.fieldsToShow = this.existingGoal ? 5 : 2;
    this.goalForm = new UntypedFormGroup( {
      name: new FormControl<string>( this?.existingGoal?.name ?? GoalsUtil.transformIdToDefaultName( this?.selectedType?.id ), Validators.required ),
      total_withdrawal: new FormControl<number>( null, Validators.required ),
      owners: new UntypedFormControl( this.existingGoal?.owners ?? [] ),
      // removed for now while we treat this completely as a minimum cash reserve. if we add more no withdrawal types,
      // we may want to bring these back without required validator
      // annualContributions: new FormControl<number>( null, Validators.required ),
      // monthlyContributions: new FormControl<number>( null ),
      include: new FormControl<boolean>( this?.existingGoal?.include ?? true ),
      color: new FormControl<string>( this.existingGoal?.color ?? this.defaultColor ),
    } );

    this.goalForm.markAsPristine();

    if ( this.existingGoal ) {
      this.goalForm.controls.total_withdrawal.setValue( this.ripCurrencyPipe.transform( this.existingGoal.total_withdrawal ) );
      // this.goalForm.controls.annualContributions.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualContributions ) );
      // this.goalForm.controls.monthlyContributions.setValue( this.ripCurrencyPipe.transform( this.existingGoal.annualContributions / 12 ) );
    }
  }

  clearFormData() {
    this.selectedType = undefined;
    this.goalForm = undefined;
    this.existingGoal = undefined;
    this.existingGoalIndex = undefined;
    this.fieldsToShow = 1;
    this.withdrawalSchedule = undefined;
    this.dateOfCompletion = undefined;
    this.goalDates = undefined;
    this.completeEarly = undefined;
    this.goalIsLessThanAYearAway = undefined;
    this.goalAmount = undefined;
  }

  removeGoal( goal: InvestorGoal ) {
    return new Observable( ( observer ) => {
      this.idOfGoalBeingRemoved = goal.id;
      this._goalsService.deleteGoal( goal.id )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( /*resp: any*/ ) => {
            this.snackBar.open( 'Goal Removed Successfully', null, Util.getSnackBarOptions() );
            this.removeGoalFromList( goal.id );
            this.filterOutCertainTypes();
            this.idOfGoalBeingRemoved = undefined;
            this.resetPriorities();
            this.update.next();
            observer.complete();
          },
          error: ( err ) => {
            console.error( err );
            this.snackBar.open( `Error Removing Goal. ${ Util.getContactSupportString() }`, null, Util.getSnackBarOptions() );
            this.idOfGoalBeingRemoved = undefined;
            observer.error( err );
          },
        } );
    } );
  }

  removeGoalFromList( goalId: string ): void {
    _.remove( this.goals, ( g: InvestorGoal ) => {
      return goalId === g.id;
    } );
  }

  updateMultipleGoals( goals: InvestorGoal[] ) {
    try {
      if ( this.goals === undefined || this.goals === null ) {
        this.goals = [];
      }
      for ( const goal of goals ) {
        this.idsOfGoalsBeingUpdated[ goal.id ] = true;
        this._goalsService.updateGoal( goal )
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: ( resp: any ) => {
              // this.snackBar.open( 'Goal Updated Successfully', null, Util.getSnackBarOptions() );
              this.idsOfGoalsBeingUpdated[ goal.id ] = false;
              // want to use resp.data instead of goal because we want the db info like id and created/updated timestamps
              this.updateGoalInList( { goal: resp.data, index: goal.priority } );
            },
            error: ( err ) => {
              console.error( err );
              this.snackBar.open( `Error updating goal. ${ Util.getContactSupportString() }`, 'dismiss', Util.getSnackBarOptions( true ) );
            },
          } );
      }
    } catch ( e ) {
      console.error( e );
    }

  }

  resetPriorities() {
    // sort the goals by priority and then reset the priorities to eliminate gaps caused by deletions
    this.sortGoalsByPriority();
    for ( let i = 0; i < this.goals.length; i++ ) {
      this.goals[ i ].priority = i;
    }
    this.updateMultipleGoals( this.goals );
  }

  /*
   returns true if a goal is being updated
   */
  checkGoalUpdateObject() {
    for ( const key of Object.keys( this.idsOfGoalsBeingUpdated ) ) {
      if ( this.idsOfGoalsBeingUpdated[ key ] ) {
        return true;
      }
    }
    return false;
  }

  updateGoal( goal: InvestorGoal, index: number ) {
    return new Observable( ( observer ) => {
      Logger.log( 'updating user goal...' );
      this.idsOfGoalsBeingUpdated[ goal.id ] = true;
      this.anyGoalBeingUpdated = true;
      this._goalsService.updateGoal( goal )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            this.updateGoalInList( { index, goal: resp.data } );
            this.idsOfGoalsBeingUpdated[ goal.id ] = false;
            this.anyGoalBeingUpdated = this.checkGoalUpdateObject();
            observer.complete();
          },
          error: ( err ) => {
            observer.error( err );
            this.idsOfGoalsBeingUpdated[ goal.id ] = false;
            console.error( err );
            this.snackBar.open( `Error updating goal. ${ Util.getContactSupportString() }`, 'dismiss', Util.getSnackBarOptions() );
          },
        } );
    } );

  }

  /**
   * Function for setting a new goal form's color to a random color
   * @param form - form that needs a random color
   */
  setRandomColorForNewForm( form: FormGroup ) {
    // `#${ Math.floor( Math.random() * 16777215 ).toString( 16 ) }`;
    const randomIndex = Math.floor( Math.random() * defaultColors.length );

    const randomColor = defaultColors[ randomIndex ];
    if ( typeof randomColor === 'string' ) {
      form.controls.color?.setValue( randomColor );
    } else {
      const randomVariantIndex = Math.floor( Math.random() * ( randomColor.variants?.length ?? 1 ) );
      form.controls.color?.setValue( randomColor.variants[ randomVariantIndex ] );
    }
  }

  getTotalWithdrawalsFromRetirementGoals() {
    try {
      let total: number = 0;
      if ( this.goals === undefined || this.goals === null ) {
        this.goals = [];
      }
      for ( const goal of this.goals ) {
        if ( goal.annualWithdrawals !== undefined && goal.include ) {
          total += goal.annualWithdrawals;
        }
      }
      return total;
    } catch ( e ) {
      console.error( e );
      return 0;
    }
  }

  getEmergencyFundGoal(): InvestorGoal {
    try {
      if ( this.goals === undefined || this.goals === null ) {
        this.goals = [];
      }
      return this.goals.find( ( g: InvestorGoal ) => {
        return g.type === USER_GOAL_TYPE_IDS.emergency_fund;
      } );
    } catch ( e ) {
      console.error( e );
      return undefined;
    }
  }

  private getGoalsByLoadedWorkspace(): void {
    this.appStoreService.loadedWorkspace$
      .pipe(
        filter( Boolean ),
      )
      .subscribe( () => this.getGoals() );
  }

}
