import { Injectable } from '@angular/core';
import { GlobalOverrideItem, LocalOverrideItem } from './dataInterfaces';
import { Position } from '@ripsawllc/ripsaw-analyzer';
import { GlobalState } from '../global.state';
import { AccountManager } from './accountManager';
import { GlobalOverridesManagerComponent } from '../pages/modals/globalOverridesManager';
import * as _ from 'lodash-es';
import { OverrideModalComponent } from '../pages/modals/overrideModal';
import { MatDialog } from '@angular/material/dialog';

@Injectable()

export class OverridesDialogUtil {


  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               public dialog: MatDialog ) {

  }

  openGlobalOverridesManager( goi?: GlobalOverrideItem ) {
    const ref = this.dialog.open( GlobalOverridesManagerComponent, {
      width: '80vw',
      data: {
        goiToOpen: goi,
      },
    } );

    ref.afterClosed().subscribe( ( result: any ) => {
      if ( result?.globalOverrideToEdit ) {
        this.openOverrideEditorModal( result.globalOverrideToEdit );
      }
      if ( result?.localOverrideToEdit ) {
        this.openOverrideEditorModal( null, result.localOverrideToEdit );
      }
    } );
  }

  openOverrideEditorModal( goi?: GlobalOverrideItem, loi?: LocalOverrideItem ) {
    const firstAccount = goi?.accounts?.length > 0 ? goi?.accounts[ 0 ] : null;
    let row;

    if ( firstAccount ) {
      row = _.find( firstAccount.positions, ( p: Position ) => {
        return p.ticker === ( goi?.override?.overrides?.ticker?.new ?? goi?.override.original_ticker );
      } );
    } else {
      // unused override, need to create a placeholder position if we want to allow edit of an unused global override
      row = loi?.position || {};
    }

    let grid;

    // if (loi) {
    //   if (this._state.globalVars.accountDataGrids[loi?.account?.account_id]) {
    grid = this._state.globalVars.accountDataGrids[ loi?.account?.account_id ];
    // }
    // }
    const ref = this.dialog.open( OverrideModalComponent, {
      data: {
        row,
        account: loi ? loi.account : null,
        grid,
        forRevision: false,
        callback: () => {
          this._accountManager.getAccounts();
        },
      },
      minWidth: '50vw',
      minHeight: '50vh',
    } );

    ref.afterClosed().subscribe( () => {
      // reopen the override manager and open the override that was edited
      setTimeout( () => {
        this.openGlobalOverridesManager( goi );
      }, 500 );
    } );
  }
}
