import { Routes, RouterModule } from '@angular/router';
import { BalanceSheetComponent } from './balanceSheet.component';

const routes: Routes = [
  {
    path: '',
    component: BalanceSheetComponent,
  },
];
export const routing = RouterModule.forChild( routes );
