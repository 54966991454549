import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HouseholdMembersUtil } from '../../utils/household-members.util';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HOUSEHOLD_MEMBER_RELATION } from '../../utils/enums';

@Component( {
  selector: 'rip-household-member-form',
  template: `
    <form [formGroup]="form" *ngIf="form">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>
            Name
          </mat-label>
          <input matInput formControlName="name" 
                 type="text" 
                 class="form-control"
                 placeholder="Name" 
                 (change)="change()">
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>
            Relation
          </mat-label>
          <mat-select formControlName="relation" (change)="change()">
            <mat-option [value]="null">Choose a Relation to you</mat-option>
            <mat-option [value]="HOUSEHOLD_MEMBER_RELATION.partner">Partner / Spouse</mat-option>
            <mat-option [value]="HOUSEHOLD_MEMBER_RELATION.child">Child</mat-option>
            <mat-option [value]="HOUSEHOLD_MEMBER_RELATION.sibling">Sibling</mat-option>
            <mat-option [value]="HOUSEHOLD_MEMBER_RELATION.parent">Parent</mat-option>
            <mat-option [value]="HOUSEHOLD_MEMBER_RELATION.other">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group toggle-input-group">
        <mat-label>
          Married:
        </mat-label>
        <nb-toggle formControlName="married" #marriedToggle (change)="change()"></nb-toggle>
      </div>
      <div class="form-group">
        <div class="household-member-form-label">When were they born?</div>
        <mat-form-field appearance="outline">
          <mat-label>
            Birth Date
          </mat-label>
          <input matInput formControlName="birth_date" class="form-control" (change)="change()"
                 [matDatepicker]="birthDatePicker" (dateChange)="publicHouseholdMembersUtil.birthDateChanged(form)">
          <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div> <!--*ngIf="form?.controls?.relation?.value === 'partner'">-->
        <div class="form-group toggle-input-group">
          <mat-label>
            Retired:
          </mat-label>
          <nb-toggle formControlName="retired" #retiredToggle (change)="change()"></nb-toggle>
        </div>
        <div class="form-group" *ngIf="!retiredToggle.checked">
          <div class="household-member-form-label">At what age would they like to retire?</div>
          <div class="flexing-row-only with-gap">
            <mat-form-field appearance="outline">
              <mat-label>
                Target Retirement Age
              </mat-label>
              <input matInput formControlName="retirementAge" class="form-control"
                     (change)="publicHouseholdMembersUtil.retirementAgeChanged(form); change()">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                Target Retirement Date
              </mat-label>
              <input matInput formControlName="shiftToWithdrawals" (change)="change()" class="form-control"
                     [matDatepicker]="retirementDatePicker">
              <mat-datepicker-toggle matSuffix [for]="retirementDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #retirementDatePicker
                              (change)="publicHouseholdMembersUtil.shiftToWithdrawalsChanged(form)"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <div class="household-member-form-label">
            How long do you want their portfolio to last{{ retiredToggle.checked ? '' : ' after retiring'}}?
          </div>
          <div class="flexing-row-only with-gap">
            <mat-form-field appearance="outline">
              <mat-label>
                Length of Retirement
              </mat-label>
              <input matInput formControlName="yearsInRetirement" class="form-control"
                     (change)="publicHouseholdMembersUtil.yearsInRetirementChanged(form); change()">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                End of Portfolio Date
              </mat-label>
              <input matInput formControlName="endOfPortfolioLife" class="form-control" (change)="change()"
                     [matDatepicker]="endOfPortfolioPicker">
              <mat-datepicker-toggle matSuffix [for]="endOfPortfolioPicker"></mat-datepicker-toggle>
              <mat-datepicker #endOfPortfolioPicker
                              (change)="publicHouseholdMembersUtil.endOfPortfolioLifeChanged( form )"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: [ `./household-member-form.component.scss` ],
} )

export class HouseholdMemberFormComponent {

  @Input() form: UntypedFormGroup;
  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();

  publicHouseholdMembersUtil = HouseholdMembersUtil;
  HOUSEHOLD_MEMBER_RELATION = HOUSEHOLD_MEMBER_RELATION;

  constructor() {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_householdMemberForm'] = this;
    }
  }

  change() {
    this.formChange.emit();
  }


}
