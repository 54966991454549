import { WorkspacePermissionNameStore } from '../../store/workspace';

const ExistingPermissions = [
  'OWNER',
  'ACCOUNTS_VIEW',
  'ACCOUNTS_EDIT',
  'ACCOUNTS_ADD',
  'ACCOUNTS_DELETE',
  'TRANSACTIONS_VIEW',
  'TRANSACTIONS_NO_ACCESS',
  'ACCOUNT_SETTINGS_VIEW',
  'ACCOUNT_SETTINGS_EDIT',
  'ACCOUNT_SETTINGS_NO_ACCESS',
  'INVESTOR_PROFILE_VIEW',
  'INVESTOR_PROFILE_EDIT',
  'SETTINGS_VIEW',
  'SETTINGS_EDIT',
  'NOTIFICATIONS_VIEW',
  'NOTIFICATIONS_NO_ACCESS',
  'DUPLICATIONS_ALLOW',
  'DUPLICATIONS_NOT_ALLOW',
] as const;

type PermissionTypeMap = { [K in WorkspacePermissionNameStore]: K };
type ExistingPermissionTypeMap = {[K in typeof ExistingPermissions[number]]: K};

const allExistingPermissionsMap: PermissionTypeMap = ExistingPermissions
  .reduce((acc, cur) => ({ ...acc, [cur]: cur }), {} as ExistingPermissionTypeMap);

export const WorkspacePermission = Object.freeze(allExistingPermissionsMap);

