import { AfterViewInit, Component, Input } from '@angular/core';
import { ColumnGroup, GlobalState } from '../../../../../global.state';
import { AllocationWidgetComponent } from '../../../../../reusableWidgets/allocationWidget';

@Component( {
  selector: 'rip-group-diffs',
  template: `
    <div id="group-diffs-container">
      <h5>{{ group?.label }}</h5>
      <table>
        <!-- risk exposure -->
        <tr id="diffs-exposure-row">
          <th>Risk Exposure</th>
          <td *ngFor="let diff of groupDiffs">
            {{ diff.label }}</td>
        </tr>
        <!-- unders row -->
        <tr id="diffs-underweights-row">
          <th>Underweight</th>
          <td *ngFor="let diff of groupDiffs" [ngClass]="{
          'group-diff-highlighted-cell': diff.label === exposureBeingAddedLabel && diff.dollar < 0
          }">
            <span *ngIf="diff.dollar < 0">
            {{ diff.dollar | ripCurrencyPipe }}
          </span>
          </td>
        </tr>
        <!-- Overs row -->
        <tr id="diffs-overweights-row">
          <th>Overweight</th>
          <td *ngFor="let diff of groupDiffs" [ngClass]="{
          'group-diff-highlighted-cell': diff.dollar > 0
          }">
            <span *ngIf="diff.dollar > 0">+{{ diff.dollar | ripCurrencyPipe }}</span>
          </td>
        </tr>
      </table>
    </div>
  `,
} )

export class GroupDiffDisplayComponent implements AfterViewInit {

  @Input() groupId: string | number;
  @Input() exposureBeingAddedLabel: string;
  group: ColumnGroup;

  groupDiffs: any = [];

  constructor( private _state: GlobalState ) {
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      const allocWidget: AllocationWidgetComponent = this._state.globalVars.allocWidget;
      const benchmarkRow = allocWidget.getBenchmarkRow();
      this.group = this._state.columnGroupings.find( ( g: ColumnGroup ) => {
        return g.id === this.groupId;
      } );

      for ( const col of this.group.columns ) {
        if ( benchmarkRow[col].dollar.diff !== 0 ) {
          const label = this._state.allColumnsObject[col].account.name;
          this.groupDiffs.push( { label, dollar: benchmarkRow[col].dollar.diff } );
        }
      }
    } );
  }
}

