import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { GlobalState } from '../../../global.state';
import {
  NewInvestmentSelectorComponent,
  SecDataStoredInterface,
} from '../newInvestmentSelectorModal/components/newInvestmentSelector/newInvestmentSelector.component';
import {
  NewInvestmentFinderUserInterface,
} from '../newInvestmentSelectorModal/components/newInvestmentFinderUserInterface';
import { AccountSelectorComponent } from '../../../reusableWidgets/accountSelector';
import { AccountManager } from '../../../utils/accountManager';
import {
  UnderweightExposure,
} from '../../accounts/components/optimizer/components/underweightExposures/underweightExposures.component';
import { Security, BenchmarkComponent } from '../../../utils/dataInterfaces';
import * as _ from 'lodash-es';
import { BenchmarkExposureChoicesComponent } from './components/benchmarkExposureChoices';
import {
  ScreenerComponent,
  SubSectorOptionData,
} from '../newInvestmentSelectorModal/components/screener/screener.component';
import { AccountDataGridComponent } from '../../accounts/components/accountDetail/components/accountDataGrid';
import { AccountsComponent } from '../../accounts/accounts.component';
import { InvestmentSelectorUtil } from '../newInvestmentSelectorModal/components/utils/investmentSelectorUtil';
import { ExposureChoicesComponent } from './components/exposureChoices';
import { environment } from '../../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbStepComponent, NbStepperComponent } from '@nebular/theme';

@Component( {
  templateUrl: './addExposureWizard.component.html',
  styleUrls: [ './addExposureWizard.scss' ],
} )

export class AddExposureWizardComponent implements AfterViewInit, NewInvestmentFinderUserInterface {

  @ViewChild( 'investmentSelector', { static: false } ) investmentSelectorComponent: NewInvestmentSelectorComponent;
  @ViewChild( 'stepper', { static: false } ) stepper: NbStepperComponent;
  @ViewChild( 'accountSelector', { static: false } ) accountSelector: AccountSelectorComponent;
  @ViewChild( 'benchmarkExposureChoices', { static: false } ) benchmarkExposureChoices: BenchmarkExposureChoicesComponent;
  @ViewChild( 'exposureChoices', { static: false } ) exposureChoices: ExposureChoicesComponent;
  @ViewChild( 'step1' ) step1: NbStepComponent;
  @ViewChild( 'step2' ) step2: NbStepComponent;
  @ViewChild( 'step3' ) step3: NbStepComponent;

  selectedSecurities: SecDataStoredInterface[] = [];
  selectedTickers: string[] = [];
  exposureBeingAdded: UnderweightExposure;
  underweightExposures: UnderweightExposure[] = [];
  showBenchmarkExposureChoices: boolean = false;
  showExposureChoices: boolean = false;
  showReOptimizeButton: boolean = false;

  addingBenchmarkInvestment: boolean = false;

  investmentFinderMessage: string;

  constructor( private _state: GlobalState,
               private _accountManager: AccountManager,
               private dialogRef: MatDialogRef<AddExposureWizardComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private _cd: ChangeDetectorRef ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_addExposureComponent'] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.init( this.data.row, this.data.underweightExposures );
    } );
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    this._cd.reattach();
  }

  init( exposure: UnderweightExposure, exposures: UnderweightExposure[] ) {
    this.exposureBeingAdded = exposure;
    this.underweightExposures = exposures;
    this.showBenchmarkExposureChoices = this._state.columnGroupings[0].columns.includes( exposure.row.dimensionProperty );
    this.showExposureChoices = InvestmentSelectorUtil.shouldAddMultipleExposures( this.exposureBeingAdded );
    if ( !this.showBenchmarkExposureChoices && !this.showExposureChoices ) { // skip step 1
      this.startSecuritySelection();
    }
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.close();
  }

  startSecuritySelection( benchmarkWeight?: BenchmarkComponent, exposureChoice?: SubSectorOptionData ) {
    this.investmentSelectorComponent.startSelection( this, true );
    switch ( this.exposureBeingAdded.row.dimensionProperty.toLowerCase() ) {
      case 'stocks':
      case 'bonds':
        if ( benchmarkWeight ) {
          this.investmentSelectorComponent.currentBenchmarkWeight = benchmarkWeight;
        }
        this.investmentSelectorComponent.selectTabBySector( this.exposureBeingAdded.riskExposure.toLowerCase() );
        this.stepper.next();
        break;
      case 'cash':
        this.investmentSelectorComponent.selectTabBySector( 'money market' );
        this.stepper.next();
        break;
      case 'value_stocks':
      case 'growth_stocks':
      case 'blend_stocks':
        let valueBlendGrowthProp = this.exposureBeingAdded.row.dimensionProperty;
        if ( this.exposureBeingAdded.row.dimensionProperty === 'blend_stocks' ) {
          if ( this._state.globalVars.underweightExposuresComponent.valueIsUnderweight ) {
            valueBlendGrowthProp = 'value_stocks';
          }
          if ( this._state.globalVars.underweightExposuresComponent.growthIsUnderweight ) {
            valueBlendGrowthProp = 'growth_stocks';
          }
        }
        if ( exposureChoice ) {
          const marketCapIndex = InvestmentSelectorUtil.getMarketCapIndex( exposureChoice.value );
          const valueBlendGrowthIndex = InvestmentSelectorUtil.getValueBlendGrowthIndex( valueBlendGrowthProp );
          const tierOptions = [];
          if ( marketCapIndex !== -1 ) {
            tierOptions.push( ScreenerComponent.StockTierOptions[marketCapIndex] );
            if ( valueBlendGrowthIndex !== -1 ) {
              tierOptions.push( ScreenerComponent.MarketCapTierOptions[valueBlendGrowthIndex] );
            }
          }
          this.setScreenerTierOptions( tierOptions, 'stocks' );
          this.stepper.next();
        }
        break;
      case 'bond_primary_sector_government':
      case 'bond_primary_sector_corporate_bond':
      case 'aaa':
        if ( this.showExposureChoices && exposureChoice ) {
          const bondsIndex = InvestmentSelectorUtil.getBondsChoiceIndex( this.exposureBeingAdded.row.dimensionProperty );
          const termIndex = InvestmentSelectorUtil.getMaturityChoiceIndex( exposureChoice.value );
          const tierOptions = [];
          if ( termIndex !== -1 ) {
            tierOptions.push( ScreenerComponent.BondsTierOptions[bondsIndex] );
            tierOptions.push( ScreenerComponent.CreditQualityTierOptions[termIndex] );
          }

          this.setScreenerTierOptions( tierOptions, 'bonds' );
          this.stepper.next();
        } else {
          this.defaultNextStep();
        }
        // tierOptions = [ ScreenerComponent.BondsTierOptions[3], ScreenerComponent.CreditQualityTierOptions[3] ];
        break;
      default:
        this.defaultNextStep();

    }
  }

  defaultNextStep() {
    this.stepper.next();
    this.investmentSelectorComponent.chooseScreenerAndTileByUnderweightExposure(
      this.exposureBeingAdded,
      this.underweightExposures,
    );
  }

  setScreenerTierOptions( tierOptions: any, sector: string ) {

    if ( tierOptions ) {
      this.investmentSelectorComponent.currentTierOptions = tierOptions;
      this.investmentSelectorComponent.currentAutoSelectedSector = sector;
    }
    this.investmentSelectorComponent.selectTabBySector( sector );
    if ( this.investmentSelectorComponent.screeners.first && this.investmentSelectorComponent.screeners.first.mainSector === sector ) {
      this.investmentSelectorComponent.screenerInitialized( this.investmentSelectorComponent.screeners.first );
    }

  }

  addProxySelection( proxyTicker: string ) {
    const security: Security = _.find( this._state.globalVars.securities, ( s: Security ) => {
      return s.ticker === proxyTicker;
    } );
    this.selectedSecurities = [ {
      securityData: security,
      ticker: proxyTicker,
      securityDetailRetrieved: true,
    } ];
    this.setSelectedTickers();
    this.determineWhatActionsToShow();
    this.startAccountSelection();
    this.addingBenchmarkInvestment = true;
  }

  startAccountSelection() {
    this.goToStep3();
    this.accountSelector.init();
  }

  goToStep1() {
    this.step2.select();
    this.step1.select();
  }

  goToStep2() {
    this.step2.select();
  }

  goToStep3() {
    this.step2.select();
    this.step3.select();
  }

  goToStepBeforeAccountSelection() {
    // this.startSecuritySelection();
    if ( this.addingBenchmarkInvestment ) {
      this.goToStep1();
      this.benchmarkExposureChoices.unSelectProxy( this.selectedSecurities[0].ticker );
    } else {
      this.goToStep2();
    }
    this.selectedTickers = [];
  }

  /*  checkSelections( selections: SecDataStoredInterface[] ) {
      // Logger.log( 'check selections to see if they are already in the target accounts' );
      return true;
    }*/

  newSecuritiesChosen( chosenSecurityData: SecDataStoredInterface[] ) {
    // store the securities chosen so we can stick them into accounts chosen in the next step
    this.selectedSecurities = _.cloneDeep( chosenSecurityData );
    this.setSelectedTickers();
    this.determineWhatActionsToShow();
    this.startAccountSelection();
  }

  determineWhatActionsToShow() {
    this.showReOptimizeButton = true; // should be true if neither exposure choices component are active

    if ( this.benchmarkExposureChoices ) {
      this.showReOptimizeButton = this.benchmarkExposureChoices.allButOneProxyHasBeenAdded();
    }

    if ( this.exposureChoices ) {
      this.showReOptimizeButton = this.exposureChoices.allButOneExposureChosen();
    }
  }

  /* accountHasInvestment( account: Account, ticker: string ): boolean {
     for ( const p of account.positions ) {
       if ( p.ticker === ticker ) {
         return true;
       }
     }
     return false;
   }*/

  insertSecuritiesIntoAccounts() {
    const accountsComponent: AccountsComponent = this._state.globalVars.accountsComponent;
    for ( const account of this.accountSelector.selectedAccounts.values() ) {
      if ( account.selected ) {
        const grid: AccountDataGridComponent = this._state.globalVars.accountDataGrids[account.account_id];
        grid.newSecuritiesChosen( this.selectedSecurities );
        accountsComponent.expandAccount( grid.account.account_id );
      }
    }
    this.finish();
  }

  finish() {
    if ( this.benchmarkExposureChoices &&
      this.benchmarkExposureChoices.benchmarkComponent &&
      this.benchmarkExposureChoices.benchmarkComponent.weights ) {
      this.benchmarkExposureChoices.selectProxy( this.benchmarkExposureChoices.lastChosenWeightProxy );

      if ( this.benchmarkExposureChoices.areAllProxiesSelected() ) {
        this.close();
      } else {
        this.goToStep1();
      }
    } else if ( this.exposureChoices ) {
      this.exposureChoices.selectExposure( this.exposureChoices.lastChosenExposure );
      if ( this.exposureChoices.allExposuresChosen() ) {
        this.close();
      } else {
        this.goToStep1();
      }
    } else {
      this.close();
    }
    this.selectedTickers = [];
  }

  insertAndClose() {
    this.insertSecuritiesIntoAccounts();
  }

  insertAndReOptimize() {
    this.insertSecuritiesIntoAccounts();
    this.close();
    this._state.notifyDataChanged( 'start.rebalance', true );
  }

  setSelectedTickers() {
    this.selectedTickers = [];
    this.selectedSecurities.forEach( ( s: any ) => {
      this.selectedTickers.push( s.ticker );
    } );
  }

}

