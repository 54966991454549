<div
  class="balance-sheet row"
  *ngIf="!deviceIsMobile">
  <div class="col col-lg-12 balance-sheet-col">
    <ng-container *ngTemplateOutlet="balanceSheetCard"></ng-container>
  </div>
</div>

<div class="mobile-balance-sheet" *ngIf="deviceIsMobile">
  <ng-container *ngTemplateOutlet="balanceSheetCard"></ng-container>
  <div
    *ngIf="
      assetAccountsLength === 0 &&
      liabilitiesAccountsLength === 0 &&
      !loadingSummary
    "
    style="margin-top: 50px; padding: 10px">
    <p>
      You do not have any accounts linked.
      <a mat-raised-button color="accent" (click)="openFastLinkForMobile()"
        >Click here</a
      >
      to link your financial institution accounts to {{ appName }}
    </p>
  </div>
</div>

<ng-template #balanceSheetCard>
  <mat-card id="print-section">
    <mat-card-header>
      <mat-card-title id="balance-sheet-title">
        <span>
          <button
            mat-raised-button
            class="icon-button pull-left"
            [disabled]="loadingSummary"
            (click)="syncAllConnections()">
            <fa-icon
              [icon]="faSyncAlt"
              [fixedWidth]="true"
              size="sm"
              [spin]="refreshing"
              matTooltip="Refresh{{ refreshing ? 'ing' : '' }} All Account Data"
              matTooltipClass="mat-tooltip-custom"></fa-icon>
          </button>
          <span
            *ngIf="refreshing && !deviceIsMobile"
            class="refreshing-indicator pull-left">
            Refreshing...
          </span>
          <!--<span *ngIf="!refreshing && !deviceIsMobile" class="pull-left" style="width: 122.812px;">

            </span>-->
        </span>

        <ng-template #workspaceNotYetLoaded>
          {{ wealthPortfolioName ?? user?.name }}
        </ng-template>

        <span class="balance-sheet-title-text">
          <span
            *ngIf="
              { data: loadedWorkspace$ | async } as workspace;
              else workspaceNotYetLoaded
            ">
            <span *ngIf="workspace?.data?.isPrimary">
              {{ wealthPortfolioName ?? user?.name ?? workspace?.data?.name }}
            </span>

            <span *ngIf="!workspace?.data?.isPrimary">
              {{ wealthPortfolioName ?? workspace?.data?.name ?? user?.name }}
            </span>
          </span>
          <span class="balance-sheet-right-side-text"
            >-
            <span *ngIf="!deviceIsMobile"> Balance Sheet </span>
            ( {{ today | date }} )
          </span>
        </span>
        <span>
          <button
            mat-raised-button
            id="print-button"
            class="icon-button pull-right"
            ripPrint
            printSectionId="print-section"
            [prePrintHookFunction]="prePrintHook"
            [postPrintHookFunction]="postPrintHook"
            [caller]="this"
            [styleSheetFile]="pdfCSSLocation"
            printTitle="Balance Sheet for {{ user.name }} - {{ today | date }}">
            <!--(click)="printToPdf()"-->
            <fa-icon
              [icon]="faPrint"
              [fixedWidth]="true"
              size="sm"
              matTooltip="Print"
              matTooltipClass="mat-tooltip-custom">
            </fa-icon>
          </button>
        </span>
        <span
          class="pull-right"
          *ngIf="!deviceIsMobile"
          id="balance-sheet-video-link">
          <a
            (click)="
              openTutorialVideo(appName + ' Balance Sheet Tutorial', '427135324')
            "
            class="legal-link balance-sheet-tutorial-link">
            Watch the Balance Sheet Tutorial
          </a>
        </span>
      </mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content #balanceSheetBody>
      <h3 matSubheader class="balance-sheet-header">Assets</h3>
      <mat-list class="account-list" *ngIf="dataRetrieved; else listSkeleton">
        <!--<mat-list-item style="height: 130px;" *ngIf="progressValue < 100 && !firstRefreshDone">
          <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
        </mat-list-item>-->
        <mat-list-item
          *ngFor="let account of assetAccounts"
          (mouseenter)="accountBeingHovered = account.account_id"
          (mouseleave)="accountBeingHovered = ''">
          <div
            class="balance-sheet-item balance-sheet-item-link"
            (click)="goToAccount(account)">
            <rip-institution-icon
              #institutionIcon
              [acct]="account"
              (click)="refreshAccount(account, institutionIcon)">
            </rip-institution-icon>
            <span class="account-desc">
              {{
                account.formattedBalanceSheetDescription ||
                  account.formattedDescription
              }}
            </span>
            <span
              *ngIf="
                account.status &&
                account.status !== 'SUCCESS' &&
                account.statusIndicator
              "
              class="status-indicator">
              <fa-icon
                [icon]="account.statusIndicator?.icon"
                size="xs"
                [fixedWidth]="true"
                (click)="openFastLinkForRepairingAccount(account)"
                matTooltip="{{ account.statusIndicator?.msg }}"
                matTooltipClass="mat-tooltip-custom"
                matTooltipPosition="right"
                [style.color]="account.statusIndicator?.color"></fa-icon>
            </span>
            <span
              *ngIf="
                accountBeingHovered === account.account_id && !deviceIsMobile
              ">
              <span class="last-sync-label" *ngIf="account.last_good_sync">
                Last Sync: {{ account.last_good_sync | date : dateFormat }}
              </span>
              <span class="last-sync-label" *ngIf="account.isManual">
                Last Updated: {{ account.updated_at | date : dateFormat }}
              </span>
              <span>
                <fa-icon
                  [icon]="faCog"
                  [fixedWidth]="true"
                  size="sm"
                  class="account-action-button"
                  (click)="
                    $event.stopPropagation();
                    openAccountSettingsDialog(account.fullAccount ?? account)
                  "
                  matTooltip="Account Settings"
                  matTooltipClass="mat-tooltip-custom">
                </fa-icon>
              </span>
            </span>
            <span class="value pull-right">
              {{
                accountManager.accountsRefreshing[account.account_id]?.nextRetry
                  ? 'Will try again in ' +
                    accountManager.accountsRefreshing[account.account_id]
                      ?.nextRetry +
                    ' seconds...'
                  : account.status !== 'SUCCESS' &&
                    account.status !== 'PARTIAL_SUCCESS' &&
                    account.newlyAdded
                  ? 'Retrieving...'
                  : (account.value | currency : 'USD' : 'symbol')
              }}
              <span
                *ngIf="
                  $any(
                    accountManager.accountsRefreshing[account.account_id]
                      ?.nextRetry
                  ) && !deviceIsMobile
                ">
                <rip-cancel-account-refresh-button
                  [account]="account"
                  [icon]="institutionIcon"
                  (clicked)="stopRefreshButtonClicked()">
                </rip-cancel-account-refresh-button>
              </span>
            </span>
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="assetAccountsLength === 0 && !loadingSummary">
          <div class="balance-sheet-item">
            <span>No Asset Accounts</span>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="dataRetrieved" class="account-list-footer">
        <!-- total for assets-->
        <mat-list-item *ngIf="assetAccountsLength > 0" class="total-line-item">
          <div class="balance-sheet-item">
            <span>{{ assetTotal.description || '' }}</span>
            <span class="value pull-right">{{
              assetTotal.value | currency : 'USD' : 'symbol'
            }}</span>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-divider></mat-divider>
      <h3 matSubheader class="balance-sheet-header">Liabilities</h3>
      <mat-list class="account-list" *ngIf="dataRetrieved; else listSkeleton">
        <!--<mat-list-item style="height: 130px;" *ngIf="progressValue < 100 && !firstRefreshDone">
          <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
        </mat-list-item>-->
        <mat-list-item
          *ngFor="let account of liabilityAccounts"
          (mouseenter)="accountBeingHovered = account.account_id"
          (mouseleave)="accountBeingHovered = ''">
          <div
            class="balance-sheet-item balance-sheet-item-link"
            (click)="goToAccount(account)">
            <rip-institution-icon
              #institutionIcon
              [acct]="account"
              (click)="refreshAccount(account, institutionIcon)">
            </rip-institution-icon>
            <span
              style="margin-left: 10px; margin-right: 5px"
              class="account-desc">
              {{
                account.formattedBalanceSheetDescription ||
                  account.formattedDescription
              }}
            </span>
            <span
              *ngIf="
                account.status &&
                account.status !== 'SUCCESS' &&
                account.statusIndicator
              "
              class="status-indicator">
              <fa-icon
                [icon]="account.statusIndicator?.icon"
                size="xs"
                [fixedWidth]="true"
                (click)="openFastLinkForRepairingAccount(account)"
                matTooltip="{{ account.statusIndicator?.msg }}"
                matTooltipClass="mat-tooltip-custom"
                matTooltipPosition="right"
                [style.color]="account.statusIndicator?.color"></fa-icon>
            </span>
            <span
              *ngIf="
                accountBeingHovered === account.account_id && !deviceIsMobile
              ">
              <span class="last-sync-label" *ngIf="account.last_good_sync">
                Last Sync: {{ account.last_good_sync | date : dateFormat }}
              </span>
              <span class="last-sync-label" *ngIf="account.isManual">
                Last Updated: {{ account.updated_at | date : dateFormat }}
              </span>
              <span>
                <fa-icon
                  [icon]="faCog"
                  [fixedWidth]="true"
                  size="sm"
                  class="account-action-button"
                  (click)="
                    $event.stopPropagation();
                    openAccountSettingsDialog(account.fullAccount)
                  "
                  matTooltip="Account Settings"
                  matTooltipClass="mat-tooltip-custom">
                </fa-icon>
              </span>
            </span>
            <span class="value pull-right">
              {{
                accountManager.accountsRefreshing[account.account_id]?.nextRetry
                  ? 'Will try again in ' +
                    accountManager.accountsRefreshing[account.account_id]
                      ?.nextRetry +
                    ' seconds...'
                  : account.status !== 'SUCCESS' &&
                    account.status !== 'PARTIAL_SUCCESS' &&
                    account.newlyAdded
                  ? 'Retrieving...'
                  : (account.value | currency : 'USD' : 'symbol')
              }}

              <span
                *ngIf="
                  accountManager.accountsRefreshing[account.account_id]
                    ?.nextRetry
                ">
                <rip-cancel-account-refresh-button
                  [account]="account"
                  [icon]="institutionIcon"
                  (clicked)="stopRefreshButtonClicked()">
                </rip-cancel-account-refresh-button>
              </span>
            </span>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="dataRetrieved" class="account-list-footer">
        <mat-list-item
          *ngIf="liabilitiesAccountsLength > 0"
          class="total-line-item">
          <div class="balance-sheet-item">
            <span>{{ liabilitiesTotal.description || '' }}</span>
            <span class="value pull-right">{{
              liabilitiesTotal.value | currency : 'USD' : 'symbol'
            }}</span>
          </div>
        </mat-list-item>

        <mat-list-item
          *ngIf="liabilitiesAccountsLength === 0 && !loadingSummary">
          <div class="balance-sheet-item">
            <span>No Liability Accounts</span>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-footer [style.margin-bottom]="deviceIsMobile ? '65px' : ''">
      <mat-list class="net-worth-list">
        <mat-list-item class="total-line-item">
          <div class="balance-sheet-item">
            <span class="net-worth-desc">{{ netWorth.description || '' }}</span>
            <!-- Show Net Worth Value because loading it done -->
            <span class="pull-right value" *ngIf="dataRetrieved">
              <span class="positive" *ngIf="netWorth.value >= 0"
                >{{ netWorth.value | currency : 'USD' : 'symbol' }}
              </span>
              <span class="negative" *ngIf="netWorth.value < 0"
                >{{ netWorth.value | currency : 'USD' : 'symbol' }}
              </span>
            </span>
            <span
              class="pull-right net-worth-loader"
              *ngIf="progressValue !== 100">
              <fa-stack>
                <fa-icon
                  [icon]="faCircleNotch"
                  [fixedWidth]="true"
                  [spin]="true"
                  stackItemSize="1x"></fa-icon>
                <fa-icon
                  [icon]="faDollarSign"
                  [fixedWidth]="true"
                  stackItemSize="1x"></fa-icon>
              </fa-stack>
            </span>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-card-footer>
    <div id="{{ spinnerSelector }}" class="spinner" style="display: none">
      <div></div>
    </div>
  </mat-card>
</ng-template>

<ng-template #listSkeleton>
  <div class="custom-skeleton p-4">
    <ul class="m-0 o-0" style="list-style: none">
      <li
        class="mb-3"
        *ngFor="let e of Array.from(Array(deviceIsMobile ? 3 : 4))">
        <div class="flexing-row-only center-vertically">
          <div>
            <p-skeleton
              shape="circle"
              styleClass="mr-2"
              size="2rem"></p-skeleton>
          </div>
          <div style="flex: 5">
            <p-skeleton width="100%" height="1rem"></p-skeleton>
            <!--            <p-skeleton width="75%" styleClass="mb-2"></p-skeleton>-->
            <!--            <p-skeleton width="75%" styleClass="mb-2"></p-skeleton>-->
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
