import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, timeout } from 'rxjs/operators';
import { Auth } from '../../auth.service';
import { ServiceErrorHandlingUtil } from '../../utils/service-error-handling.util';

@Injectable()
export class BenchmarkOptimizerService {

  private readonly lambdaOptimizerUrl: string;

  constructor( private _http: HttpClient ) {
    this.lambdaOptimizerUrl = `${ environment.ripsawAPIBaseUrl }/optimizer`;
  }

  optimizeBenchmarkPortfolio( weights: any[],
                              benchmark_covariance_object: any,
                              benchmark_expected_return_object: any,
                              desired_expected_returns: number[],
                              minCashPercent: number,
                              tolerance: number,
                              onBoarding: boolean) {
    const url = `${ this.lambdaOptimizerUrl }/optimizeBenchmark`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    return this._http.post( url, {
      weights,
      benchmark_covariance_object,
      benchmark_expected_return_object,
      desired_expected_returns,
      minCashPercent,
      tolerance,
      onBoarding,
    }, { headers } )
      .pipe(
        timeout( 30000 ),
        map( BenchmarkOptimizerService.extractData ),
        catchError( ServiceErrorHandlingUtil.optimizerHandleError ),
      );
  }

  /*
  * Function for extracting data from an HttpResponse
  * @param res {HttpResponse} - HttpResponse that contains data to be extracted
  * @returns the body of the response or an empty object
  * */
  static extractData( res: HttpResponse<any> ) {
    const body = res;

    if ( body['data']['optimal_weights'] && typeof body['data']['optimal_weights'] === 'string' ) {
      body['data']['optimal_weights'] = JSON.parse( body['data']['optimal_weights'] );
    }    // console.log(body);

    return body || {};
  }

}
