import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Util } from '../../utils/util.service';
import { DisclaimersUtil } from '../../utils/disclaimers.util';
import { GlossaryUtil } from '../../utils/glossary.util';

@Component( {
  selector: 'rip-app-footer',
  template: `
    <footer class="al-footer clearfix flexing space-between center-vertically"
            [ngClass]="{'unauthenticated': !authenticated}">

      <div class="al-footer-left">
        <span>
          <table>
            <tr style="text-align: center; color: var(--body-bg);">Version: {{ version }} </tr>
            <tr style="text-align: center">Copyright © {{ currentYear }} {{ companyName }} ({{environment.appName}}). All rights reserved. </tr>
            <tr style="text-align: left">All content and data on this site is not financial advice. A user's financial decisions are their own.</tr>
            
          </table>
        </span>
      </div>
      <div class="al-footer-main">
        <div>
          <table>
            <tr style="text-align: center">Market Data by</tr>
            <tr>
              <!-- EOD -->
              <a href="https://eodhistoricaldata.com"
                 target="_blank" rel="noopener">
                <img alt="" height="25"
                     data-src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/EODhistoricaldata-footer.png"
                     class="alignnone size-full wp-image-299 lazyloaded"
                     src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/EODhistoricaldata-footer.png">
                <noscript>
                  <img class="alignnone size-full wp-image-299"
                       src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/EODhistoricaldata-footer.png"
                       alt="" height="60"/>
                </noscript>
              </a>

              <!-- morningstar -->

              <span style="padding: 10px;" class="disclaimer" (click)="openDisclaimerModal(7)">
                <img alt="" height="22"
                     data-src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/morningstar-footer.png"
                     class="alignnone size-full wp-image-299 lazyloaded"
                     src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/morningstar-footer.png">
              </span>

              <!-- nasdaq -->

              <a href="https://data.nasdaq.com/" target="_blank" rel="noopener">

                <img alt="" height="22"
                     data-src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/NDL_logo-1.png"
                     class="alignnone size-full wp-image-299 lazyloaded"
                     src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/NDL_logo-1.png">
                <noscript>
                  <img class="alignnone size-full wp-image-299"
                       src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/NDL_logo-1.png"
                       alt="" height="50"/>
                </noscript>
              </a>

              <!--alpha vantage-->

              <a href="https://www.alphavantage.co" target="_blank" rel="noopener">
                <img alt="" height="30"
                     data-src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/alphavantageco-footer.png"
                     class="alignnone size-full wp-image-299 lazyloaded"
                     src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/alphavantageco-footer.png">
                <noscript>
                  <img class="alignnone size-full wp-image-299"
                       src="https://s3.us-west-2.amazonaws.com/assets.wealthfluent.com/logos/alphavantageco-footer.png"
                       alt="" height="60"/>
                </noscript>
              </a>

            </tr>
          </table>
        </div>

      </div>
      <!-- legal stuff-->
      <div class="al-footer-right">
        <table>
          <tr style="text-align: center">Resources</tr>
          <tr>
            <a class="legal-link" (click)="openExternal(website + '/terms-of-use')">
              Terms of Use
            </a> |
            <a class="legal-link"
               (click)="openExternal(website + '/privacy')">
              Privacy and Security
            </a> |
            <a class="legal-link" (click)="openDisclaimerModal()">
              Disclaimers
            </a> |
            <a class="legal-link" (click)="openGlossaryDialog()">
              Glossary
            </a>
          </tr>
        </table>
      </div>
    </footer>
  `,
  styleUrls: [ './app-footer.component.scss' ],
} )

export class AppFooterComponent {

  @Input() authenticated: boolean = false;

  currentYear: number = new Date().getFullYear();
  version: string;
  website: string = environment.common.website;

  companyName: string = environment.common.companyName;

  constructor( private disclaimersUtil: DisclaimersUtil,
               private glossaryUtil: GlossaryUtil ) {
    this.version = environment.version;

  }

  openDisclaimerModal( index?: number ) {
    this.disclaimersUtil.openDisclaimersDialog( index );
  }

  openGlossaryDialog() {
    this.glossaryUtil.openGlossaryDialog();
  }

  openExternal( url: string ) {
    Util.openExternalUrl( url );
  }

  protected readonly environment = environment;
}
