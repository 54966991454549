<ul class="al-sidebar-list" ngaBaSlimScroll>
  <li class="al-sidebar-list-item" id="notifications-menu" [style.margin]="0">
    <a [matMenuTriggerFor]="notificationsMenu" class="al-sidebar-list-link"
       (mouseenter)="onHoverItem($event)"
       matTooltip="Notifications"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">
      <fa-layers [fixedWidth]="true" size="2x">
        <fa-icon [icon]="faBell" size="2x" [fixedWidth]="true"></fa-icon>
        <fa-layers-counter *ngIf="getUnreadNotificationsCount() > 0"
                           content="{{getUnreadNotificationsCount()}}"
                           class="notifications-icon-badge"></fa-layers-counter>
      </fa-layers>
      <span translate *ngIf="!sidebarCollapsed">Notifications</span>
    </a>
  </li>
  <li class="al-sidebar-list-item">
    <a [matMenuTriggerFor]="profileMenu" class="al-sidebar-list-link"
       (mouseenter)="onHoverItem($event)"
       matTooltip="User Menu"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">
      <fa-icon [icon]="userIcon" size="2x" [fixedWidth]="true"></fa-icon>
      <span translate *ngIf="!sidebarCollapsed">User Menu</span>
    </a>
  </li>
</ul>

<!-- PROFILE MENU -->
<mat-menu #profileMenu="matMenu" class="profile-menu">
  <button mat-menu-item (click)="goToProfile()">
    <fa-icon [icon]="faUserCog" class="user-menu-icon"></fa-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item (click)="signout()" class="signout">
    <fa-icon [icon]="faSignOutAlt" class="user-menu-icon"></fa-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<!-- NOTIFICATIONS MENU -->

<mat-menu #notificationsMenu="matMenu" class="notifications-menu">
  <button mat-menu-item *ngFor="let notification of getUnreadNotifications()"
          (click)="openNotificationsModal(notification)"
          class="signout">
    <fa-layers *ngIf="notification.type === 'dataQuality'" class="notification-menu-icon">
      <fa-icon [icon]="getNotificationIcon(notification.type)"></fa-icon>
      <fa-layers-counter content="!" class="notification-menu-badge-icon"></fa-layers-counter>
    </fa-layers>
    <fa-icon *ngIf="notification.type !== 'dataQuality'" [icon]="getNotificationIcon(notification.type)"
             class="notification-menu-icon"></fa-icon>

    <span>{{notification.title}}</span>
  </button>
  <button mat-menu-item (click)="openNotificationsModal()" class="signout">
    <span *ngIf="notifications && notifications.length > 0">See All</span>
    <span *ngIf="!notifications || notifications.length === 0">Nothing For You Right Now</span>
  </button>
</mat-menu>
