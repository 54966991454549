import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from '../utils/util.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PricingService {

  lambdaSecuritiesUrl: string;

  constructor( private _http: HttpClient ) {
    this.lambdaSecuritiesUrl = `${environment.ripsawAPIBaseUrl}/securities`;
  }

  getPrices( tickersWithTypes: any[] ) {
    const url = `${this.lambdaSecuritiesUrl}/prices`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, tickersWithTypes, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getPrice( ticker: string, exchange?: string ) {
    let url = `${this.lambdaSecuritiesUrl}/price/${ticker}`;
    if ( exchange ) {
      url = `${url}?exchange=${exchange}`;
    }
    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getCryptoPrice( ticker: string ) {
    const url = `${this.lambdaSecuritiesUrl}/price/${ticker}?exchange=CC`;
    return this._http.get( url )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
