import { AfterViewInit, Component, inject, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { GlobalState } from '../../../global.state';
import { Account, CrossAppMessageType } from '@ripsawllc/ripsaw-analyzer';
import { RipsawCurrencyPipe, RipsawDecimalPipe } from '../../../theme/pipes';
import { environment } from '../../../../environments/environment';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { MatDialog } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MobileRowDetailComponent } from './mobileRowDetailComponent.component';
import { EVENT_NAMES } from '../../../utils/enums';
import { WealthFluentMessageService } from '../../../theme/services/wealthFluentMessage';

@Component( {
  selector: 'rip-mobile-account-details',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">{{ account?.formattedDescription ? account?.formattedDescription : 'Account' }}</div>
      <span><button mat-raised-button (click)="close()" class="mobile-close-btn">Close</button></span>
    </div>
    <div class="bottom-sheet-content">

      <div style="display: flex; flex-direction: column; margin: 1px;">
        <div style="padding: 5px;">
          <p style="display: flex;">
            <span style="flex-grow: 2"><strong>Account Value: </strong> {{ account.value | ripCurrencyPipe }}</span>
            <span>
              <fa-icon [icon]="faCog" [fixedWidth]="true" size="lg"
                       class="account-action-button"
                       (click)="openAccountSettingsDialog(account)"
                       nbTooltip="Account Actions"
                       nbTooltipClass="mat-tooltip-custom"></fa-icon>
            </span>
          </p>

          <p *ngIf="account.management_fee">
            Account Fee:
            {{ account.management_fee | ripLongPercentPipe }} |
            {{ account.value * account.management_fee | currency : 'USD' : 'symbol' }}
          </p>
        </div>
        <ngx-datatable id="accounts-table"
                       [rows]="rows"
                       [columns]="columns"
                       [rowHeight]="60"
                       [footerHeight]="50"
                       (activate)="openMobileRowDetail($event)"
                       class="material striped">

          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount">
              <div style="padding: 5px 10px;">
                <div>
                  <strong>Holdings</strong>: {{ rowCount }}
                </div>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  `,
  styleUrls: [ `./mobileLayoutComponents.scss`, `../mobileLayout.scss` ],
  encapsulation: ViewEncapsulation.None,
} )

export class MobileAccountDetailsComponent implements OnDestroy, AfterViewInit {

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  faCog = faCog;

  subscriberName: string = 'MobileAccountDetailsComponent';

  account: Account;

  screenWidth: number = 300;

  rows: any[] = [];

  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  columns: any[] = [];

  constructor( private _bottomSheetRef: MatBottomSheetRef<MobileAccountDetailsComponent>,
               private _bottomSheet: MatBottomSheet,
               @Inject( MAT_BOTTOM_SHEET_DATA ) public data: any,
               public dialog: MatDialog,
               private _state: GlobalState ) {
    this.account = data.account;
    this.screenWidth = window.innerWidth;
    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_mobileAccountDetails' ] = this;
    }
    this.rows = this.account.positions;

  }

  ngAfterViewInit(): void {
    setTimeout( () => {

      const maxWidth = this.screenWidth / 4;

      this.columns = [
        {
          name: 'Ticker',
          prop: 'ticker',
          cellClass: 'mobile-table-cell-class',
          maxWidth,
        },
        {
          name: 'Price',
          prop: 'price',
          pipe: this.ripCurrencyPipe,
          cellClass: 'mobile-table-cell-class',
          maxWidth,
        },
        {
          name: 'Quantity',
          prop: 'quantity',
          pipe: this.ripDecimalPipe,
          cellClass: 'mobile-table-cell-class',
          maxWidth,
        },
        {
          name: 'Value',
          longName: 'Market Value',
          prop: 'value',
          pipe: this.ripCurrencyPipe,
          cellClass: 'mobile-table-cell-class',
          maxWidth,
        },
        // {
        //   name: 'Cost Basis',
        //   longName: 'Cost Basis',
        //   prop: 'cost_basis',
        //   cellTemplate: this.costBasisTemplate,
        //   cellClass: 'mobile-table-cell-class',
        //   maxWidth: maxWidth * 0.8,
        // },
        // {
        //   name: 'Gain/Loss',
        //   prop: 'gain_loss',
        //   cellTemplate: this.gainLossTemplate,
        //   cellClass: 'mobile-table-cell-class',
        //   maxWidth: maxWidth * 1.2,
        // },
      ];

      this._state.subscribe( EVENT_NAMES.MANUAL_ACCOUNT_UPDATED, ( account: Account ) => {
        // there should only ever be one mobile account details open, but just in case something funny happens,
        // let's make sure it's the right account
        if ( this.account.account_id === account.account_id ) {
          this.account = account;
          this.rows = this.account.positions;
        }
      }, this.subscriberName );

    } );
  }

  ngOnDestroy(): void {
    delete window[ 'mobileAccountDetails' ];
    this._state.unsubscribe( EVENT_NAMES.MANUAL_ACCOUNT_UPDATED, this.subscriberName );
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

  openMobileRowDetail( event: any ) {
    this._bottomSheet.dismiss();
    this._bottomSheet.open( MobileRowDetailComponent, { data: { holding: event.row } } );
  }

  openAccountSettingsDialog( account: Account ) {
    this.wealthFluentMessageService.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      data: {
        account,
        type: 'account settings',
      },
      message: `Open account settings for account ${ account.account_id }`,
    } );
  }


}
