import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Util } from '../../../../../utils/util.service';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { GlobalState } from '../../../../../global.state';
import { faUniversity } from '@fortawesome/pro-light-svg-icons';

@Component( {
  selector: 'rip-bank-form',
  templateUrl: './bankForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class BankFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account;

  publicUtil: typeof Util;

  faUniversity = faUniversity;

  subscriberName: string = 'bankForm';

  constructor( private _state: GlobalState ) {
    super();
    this.publicUtil = Util;
  }

  ngAfterViewInit(): void {
    // this.form.controls.value.disable(); will need to uncomment this once the formula for bond valuation is implemented
    // Logger.log( this.account );
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    if ( this.account && this.form ) {
      ManualAccountUtil.patchBankForm( this.form, this.account );
    } else {
      // since these aren't shown, but are still required, we need to set them to 1
      this.form.controls.price.setValue( 1 );
      this.form.controls.quantity.setValue( 1 );
      this.autofocus();
    }
  }

}

