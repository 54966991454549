import { Component } from '@angular/core';
import { UsersUtil } from '../../../../../utils/users.util';
import { Util } from '../../../../../utils/util.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Auth } from '../../../../../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MobileUtil } from '../../../../../utils/mobileUtil.service';
import { environment } from '../../../../../../environments/environment';
import { GlobalState } from '../../../../../global.state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash-es';

import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { MarketInfoUtil } from '../../../../../utils/market-info.util';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Component( {
  selector: 'rip-market-info-settings',
  template: `
    <mat-card class="settings-card" [nbSpinner]="spinning">
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <h5>
              Market Information Settings
            </h5>
            <div class="row"><br></div>
            <form [formGroup]="form">
              <!-- LONG TERM SETTINGS -->
              <div>
                <div>
                  Long-Term Calculation Settings
                </div>
                <div class="form-horizontal flexing-row-only odd-setting-item center-vertically setting-item"
                     style="padding-bottom: 35px;">
                  <mat-label style="margin-right: 10px; flex: 1;">
                    Number of monthly observations to use for parameter estimation
                  </mat-label>
                  <mat-form-field style="width: 100%; flex: 2;">
                    <mat-select [(ngModel)]="data.numberOfLongTermObservations.value"
                                formControlName="numberOfLongTermObservations"
                                (valueChange)="checkNewValue('numberOfLongTermObservations')">
                      <mat-option value="60">60 Months</mat-option>
                      <mat-option value="72">72 Months</mat-option>
                      <mat-option value="84">84 Months</mat-option>
                      <mat-option value="96">96 Months</mat-option>
                      <mat-option value="108">108 Months</mat-option>
                    </mat-select>
                    <mat-hint>
                      In estimating parameters, there is a
                      tradeoff between precision (more observations the better when the parameter is constant) and the
                      true parameter changing as the market environment changes. The default value is 108 months of
                      return data
                      for beta and non-market covariance estimates.
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div class="form-horizontal flexing-row-only setting-item"
                     style="padding-bottom: 35px; margin-top: 20px; text-align: center;">

                  <!-- LONG TERM MARKET VOLATILITY -->
                  <div class="flexing vertical-flex center-vertically" style="flex: 1;">
                    <div>
                      Long-Term Market Volatility (%)
                    </div>
                    <div class="flexing-row-only" style="width: 100%;">
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput type="text" [(ngModel)]="data.longTermMarketVolatility.value"
                               formControlName="longTermMarketVolatility"
                               [value]="data.longTermMarketVolatility.value"
                               (change)="checkNewValue('longTermMarketVolatility')"/>
                        <button mat-button matSuffix
                                (click)="resetLongTermMarketVolatility()"
                                *ngIf="data.longTermMarketVolatility.value !== defaultLongTermMarketVolatility">
                          <fa-icon [icon]="faUndo"
                                   matTooltip="Reset to default"
                                   matTooltipClass="mat-tooltip-custom">

                          </fa-icon>
                        </button>

                        <mat-hint>
                          The default 17.2% realized value for the Long-Term Market Volatility is from the 49-year
                          period (1972-2020) in Exhibit 2.14 on page 38 of the
                          <a (click)="openSBBILink()" class="rip-link">SBBI® 2021 Summary Edition.</a>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- END LONG TERM MARKET VOLATILITY -->

                  <!-- LONG TERM MARKET RISK PREMIUM -->
                  <div class="flexing vertical-flex center-vertically" style="flex: 1;">
                    <div>
                      Long-Term Market Risk Premium (%)
                    </div>
                    <div class="flexing-row-only" style="width: 100%;">
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <input matInput type="text" [(ngModel)]="data.longTermMarketRiskPremium.value"
                               formControlName="longTermMarketRiskPremium"
                               [value]="data.longTermMarketRiskPremium.value"
                               (change)="checkNewValue('longTermMarketRiskPremium')"/>
                        <button mat-button matSuffix
                                (click)="resetLongTermMarketRiskPremium()"
                                *ngIf="data.longTermMarketRiskPremium.value !== defaultLongTermMarketRiskPremium">
                          <fa-icon [icon]="faUndo"
                                   matTooltip="Reset to default"
                                   matTooltipClass="mat-tooltip-custom">

                          </fa-icon>
                        </button>
                        <mat-hint>
                          The default 7.7% realized value for the Long-Term Market Risk Premium is from the 49-year
                          period
                          (1972-2020) in Exhibit 2.14 on page 38 of the
                          <a (click)="openSBBILink()" class="rip-link">SBBI® 2021 Summary Edition.</a>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- END LONG TERM MARKET RISK PREMIUM -->


                </div>
              </div>

              <!-- SHORT TERM SETTINGS -->
              <div>
                <div>
                  Short-Term Calculation Settings
                </div>

                <div class="form-horizontal flexing-row-only odd-setting-item center-vertically setting-item"
                     style="padding-bottom: 45px;">
                  <mat-label style="margin-right: 10px; flex: 1;">
                    Number of daily observations to use for parameter estimation
                  </mat-label>
                  <mat-form-field style="width: 100%; flex: 2;">
                    <mat-select [(ngModel)]="data.numberOfObservations.value" formControlName="numberOfObservations"
                                (valueChange)="checkNewValue('numberOfObservations')">
                      <mat-option value="30">30 Days</mat-option>
                      <mat-option value="60">60 Days</mat-option>
                      <mat-option value="90">90 Days</mat-option>
                      <mat-option value="120">120 Days</mat-option>
                    </mat-select>
                    <mat-hint>
                      In estimating parameters, there is a
                      tradeoff between precision (more observations the better when the parameter is constant) and the
                      true parameter changing as the market environment changes. The default value is 60 trading days
                      for beta and non-market covariance estimates.
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="form-horizontal flexing-row-only setting-item"
                     style="padding-bottom: 25px; margin-top: 20px; text-align: center;">

                  <!-- SHORT TERM MARKET VOLATILITY -->
                  <div class="flexing vertical-flex center-vertically" style="flex: 1;">
                    <div>
                      Short-Term Market Volatility (%)
                    </div>
                    <div>
                      <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="data.shortTermMarketVolatility.value"
                               formControlName="shortTermMarketVolatility"
                               [value]="data.shortTermMarketVolatility.value || ''"
                               (change)="checkNewValue('shortTermMarketVolatility')"/>
                        <button mat-button matSuffix
                                (click)="resetShortTermMarketVolatility()"
                                *ngIf="data.shortTermMarketVolatility.value !== marketInfoUtil.currentVixQuote?.price">
                          <fa-icon [icon]="faUndo"
                                   matTooltip="Reset to default"
                                   matTooltipClass="mat-tooltip-custom">

                          </fa-icon>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- END SHORT TERM MARKET VOLATILITY -->

                  <!-- SHORT TERM MARKET RISK PREMIUM -->
                  <div class="flexing vertical-flex center-vertically" style="flex: 1;">
                    <div>
                      Short-Term Market Risk Premium (%)
                    </div>
                    <div>
                      <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="data.shortTermMarketRiskPremium.value"
                               formControlName="shortTermMarketRiskPremium"
                               [value]="data.shortTermMarketRiskPremium.value || ''"
                               (change)="checkNewValue('shortTermMarketRiskPremium')"/>

                        <button mat-button matSuffix
                                (click)="resetShortTermMarketRiskPremium()"
                                *ngIf="data.shortTermMarketRiskPremium.value !== calculatedShortTermRiskPremium">
                          <fa-icon [icon]="faUndo"
                                   matTooltip="Reset to default"
                                   matTooltipClass="mat-tooltip-custom">

                          </fa-icon>
                        </button>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- END SHORT TERM MARKET RISK PREMIUM -->
                </div>
              </div>

            </form>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="updatePrefs()" [disabled]="!anyValueHasChanged">Save
        </button>
      </mat-card-actions>
    </mat-card>
  `,
  styleUrls: [],
} )

export class MarketInfoSettingsComponent {

  get defaultLongTermMarketRiskPremium(): number {
    return MarketInfoUtil.defaultLongTermRiskPremium;
  };

  get defaultLongTermMarketVolatility(): number {
    return MarketInfoUtil.defaultLongTermStandardDeviation;
  }

  spinning: boolean = false;

  deviceIsMobile: boolean = false;
  faUndo = faUndo;

  form: UntypedFormGroup = new UntypedFormGroup( {
    numberOfObservations: new UntypedFormControl(),
    numberOfLongTermObservations: new UntypedFormControl(),
    longTermMarketRiskPremium: new UntypedFormControl(),
    shortTermMarketRiskPremium: new UntypedFormControl(),
    longTermMarketVolatility: new UntypedFormControl(),
    shortTermMarketVolatility: new UntypedFormControl(),
  } );

  private readonly defaultData: any = {
    numberOfObservations: {
      original: MarketInfoUtil.defaultShortTermObservations,
      value: MarketInfoUtil.defaultShortTermObservations,
      hasChanged: false,
    },
    numberOfLongTermObservations: {
      original: MarketInfoUtil.defaultLongTermObservations,
      value: MarketInfoUtil.defaultLongTermObservations,
      hasChanged: false,
    },
    longTermMarketRiskPremium: {
      original: MarketInfoUtil.defaultLongTermRiskPremium,
      value: MarketInfoUtil.defaultLongTermRiskPremium,
      hasChanged: false,
    },
    shortTermMarketRiskPremium: {
      original: undefined,
      value: undefined,
      hasChanged: false,
    },
    longTermMarketVolatility: {
      original: MarketInfoUtil.defaultLongTermStandardDeviation,
      value: MarketInfoUtil.defaultLongTermStandardDeviation,
      hasChanged: false,
    },
    shortTermMarketVolatility: {
      original: undefined,
      value: undefined,
      hasChanged: false,
    },
  };


  data: any = _.cloneDeep( this.defaultData );

  calculatedShortTermRiskPremium: string;

  anyValueHasChanged: boolean = false;

  constructor( private _detectorService: DeviceDetectorService,
               private _auth: Auth,
               private _usersUtil: UsersUtil,
               public snackBar: MatSnackBar,
               private _state: GlobalState,
               public marketInfoUtil: MarketInfoUtil ) {
    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

    const userPrefs = UsersUtil.checkPreferences( this._auth, 'marketInfo' );

    if ( !isNaN( userPrefs.marketInfo?.numberOfObservations ) ) {
      // this is the old format, so we need to convert it
      this.data.numberOfObservations.value = userPrefs.marketInfo?.numberOfObservations;
    } else {
      if ( userPrefs.marketInfo ) {
        Object.assign( this.data, userPrefs.marketInfo );
      }
    }

    if ( environment.env !== 'prod' ) {
      window['ripsaw_marketInfoSettings'] = this;
    }

    if ( !this.data.shortTermMarketVolatility.value ) { // no value in the user settings already
      if ( this.marketInfoUtil.currentVixQuote?.price ) {
        // vix has already been retrieved, so just set it as the shortTermMarketVolatility
        this.data.shortTermMarketVolatility.value = this.data.shortTermMarketVolatility.original = this.marketInfoUtil.snapshot?.current;
        this.setShortTermRiskPremium();
      } else {
        // vix hasn't been retrieved, so we need to retrieve it
        this.getVix();
      }
    } else {
      this.setShortTermRiskPremium();
    }

  }

  getVix() {
    this.spinning = true;
    this.marketInfoUtil.getCurrentVix().subscribe( {
      next: null,
      error: ( err ) => {
        console.error( err );
        this.spinning = false;
      },
      complete: () => {
        this.data.shortTermMarketVolatility.value = this.data.shortTermMarketVolatility.original = this.marketInfoUtil.currentVixQuote?.price;
        this.setShortTermRiskPremium();
        this.spinning = false;
      },
    } );
  }

  setShortTermRiskPremium() {
    const strp = ( this.data.longTermMarketRiskPremium.value / Math.pow( this.data.longTermMarketVolatility.value, 2 ) ) * Math.pow( this.data.shortTermMarketVolatility.value, 2 );
    this.data.shortTermMarketRiskPremium.value = this.data.shortTermMarketRiskPremium.original = strp.toFixed( 2 );
    this.calculatedShortTermRiskPremium = strp.toFixed( 2 );
  }

  checkNewValue( prop: string ) {
    // observations needs to be a string because of select box stuff :(, but all the other fields are stored and compared as numbers
    this.data[prop].value = ( prop === 'numberOfObservations' || prop === 'numberOfLongTermObservations' ) ?
      this.form.controls[prop].value : FormsUtil.getSanitizedFloatValue( this.form.controls[prop].value );
    this.data[prop].hasChanged = this.data[prop].value !== this.data[prop].original;
    if ( prop !== 'shortTermMarketRiskPremium' ) {
      this.setShortTermRiskPremium();
    }
    this.anyValueHasChanged = this.checkAllValues();
  }

  checkAllValues(): boolean {
    let anyChange = false;
    Object.keys( this.data ).forEach( ( key ) => {
      if ( this.data[key].hasChanged ) {
        anyChange = true;
      }
    } );
    return anyChange;
  }


  updatePrefs() {
    this.spinning = true;
    const userPrefs = UsersUtil.checkPreferences( this._auth, 'marketInfo' );
    userPrefs.marketInfo = this.getRawPrefsFromData();
    this._usersUtil.savePreferences( userPrefs, ( err ) => {
      if ( err ) {
        console.error( err );
        this.snackBar.open( `Error saving user preferences. ${ Util.getRefCodeSupportString( err.refCode ) }`,
          'dismiss',
          Util.getSnackBarOptions( true ) );

      } else {
        this.snackBar.open( `User preferences saved `, null, Util.getSnackBarOptions() );
        this.resetDataObject();
        this._state.globalVars.numberOfObservations = this.data.numberOfObservations.value;
        this._state.notifyDataChanged( 'market.calc.settings.changed', userPrefs.marketInfo );
      }
      this.spinning = false;
    } );
  }

  resetDataObject() {
    Object.keys( this.data ).forEach( ( key ) => {
      this.data[key].original = this.data[key].value;
      this.data[key].hasChanged = false;
    } );
    this.anyValueHasChanged = false;
  }

  getRawPrefsFromData(): any { // TODO: only store value, original and dateset. Don't need hasChanged
    const prefsObj: any = {};
    // check to see if this number is different from vix (it was changed by the user)
    if ( this.data.shortTermMarketVolatility.value !== this.marketInfoUtil.currentVixQuote?.price ) {
      prefsObj.shortTermMarketVolatility = this.data.shortTermMarketVolatility;
      prefsObj.shortTermMarketVolatility.dateSet = Date.now();
    } else {
      delete prefsObj.shortTermMarketVolatility;
    }
    // check to see if short term market risk premium is different from the calculated one
    if ( this.data.shortTermMarketRiskPremium.value !== this.calculatedShortTermRiskPremium ) {
      prefsObj.shortTermMarketRiskPremium = this.data.shortTermMarketRiskPremium;
      prefsObj.shortTermMarketRiskPremium.dateSet = Date.now();
    } else {
      delete prefsObj.shortTermMarketRiskPremium;
    }
    // check to see if the long term market volatility is different from the default 17.2
    if ( this.data.longTermMarketVolatility.value !== MarketInfoUtil.defaultLongTermStandardDeviation ) {
      prefsObj.longTermMarketVolatility = this.data.longTermMarketVolatility;
      prefsObj.longTermMarketVolatility.dateSet = Date.now();
    } else {
      delete prefsObj.longTermMarketVolatility;
    }
    // check to see if the long term market risk premium is different from the default 7.7
    if ( this.data.longTermMarketRiskPremium.value !== MarketInfoUtil.defaultLongTermRiskPremium ) {
      prefsObj.longTermMarketRiskPremium = this.data.longTermMarketRiskPremium;
      prefsObj.longTermMarketRiskPremium.dateSet = Date.now();
    } else {
      delete prefsObj.longTermMarketRiskPremium;
    }

    return prefsObj;
  }

  resetShortTermMarketRiskPremium() {
    this.setShortTermRiskPremium();
    this.data.shortTermMarketRiskPremium.hasChanged = true;
    this.data.shortTermMarketRiskPremium.dateSet = undefined;
  }

  resetShortTermMarketVolatility() {
    this.data.shortTermMarketVolatility = {
      value: this.marketInfoUtil.currentVixQuote?.price,
      original: this.marketInfoUtil.currentVixQuote?.price,
      hasChanged: true,
    };
    this.anyValueHasChanged = this.checkAllValues();
  }

  resetLongTermMarketVolatility() {
    this.data.longTermMarketVolatility = {
      value: MarketInfoUtil.defaultLongTermStandardDeviation,
      original: MarketInfoUtil.defaultLongTermStandardDeviation,
      hasChanged: true,
    };
    this.anyValueHasChanged = this.checkAllValues();
  }

  resetLongTermMarketRiskPremium() {
    this.data.longTermMarketRiskPremium = {
      value: MarketInfoUtil.defaultLongTermRiskPremium,
      original: MarketInfoUtil.defaultLongTermRiskPremium,
      hasChanged: true,
    };
    this.anyValueHasChanged = this.checkAllValues();
  }

  openSBBILink() {
    Util.openExternalUrl( MarketInfoUtil.SBBI_URL );
  }

}
