<ul class="al-sidebar-list" ngaBaSlimScroll>
  <!--<li class="al-sidebar-list-item">
      <div class="al-sidebar-list-header" [ngClass]=" { minimized: sidebarCollapsed }">
          Preferences
      </div>
  </li>-->

  <li *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]" class="al-sidebar-list-item" style="margin-top: 5px;"
      id="global-overrides-manager">
    <a class="al-sidebar-list-link" (click)="openGlobalOverridesManager()"
       (mouseenter)="onHoverItem($event)"
       matTooltip="Manage Security Overrides"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right"
       [ngClass]="{ 'disabled-menu-item': editing || loading }">
      <fa-layers [fixedWidth]="true" size="2x">
        <fa-stack>
          <fa-icon [icon]="faDatabase" stackItemSize="2x" style="font-size: 0.3rem;"></fa-icon>
          <fa-icon [icon]="faCog" stackItemSize="2x" class="icon-subscript-right"
                   style="font-size: 0.2rem;"></fa-icon>
        </fa-stack>
        <fa-layers-counter *ngIf="dataQualityBadge > 0"
                           content="{{dataQualityBadge}}"
                           class="security-overrides-icon-badge"></fa-layers-counter>
      </fa-layers>

      <span translate>Security Overrides</span>
    </a>
  </li>

  <li class="al-sidebar-list-item" style="margin-top: 5px;" *ngIf="!inWealthFluent">
    <a class="al-sidebar-list-link" (click)="openSettings()"
       (mouseenter)="onHoverItem($event)"
       matTooltip="Settings"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right"
       [ngClass]="{ 'disabled-menu-item': editing, 'active-page': currentPage === 'Settings' }">
      <fa-icon [icon]="faSlidersH" size="2x" [fixedWidth]="true"></fa-icon>
      <span translate>Settings</span>
    </a>
  </li>

</ul>


