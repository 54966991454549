import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NewInvestmentSelectorComponent } from './components/newInvestmentSelector/newInvestmentSelector.component';
import { GlobalState } from '../../../global.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component( {
  selector: 'rip-new-investment-selector-modal',
  template: `
    <h3 mat-dialog-title>Investment Screener</h3>
    <div mat-dialog-content align="center">
      <rip-new-investment-selector #investmentSelector></rip-new-investment-selector>
    </div>
    <div mat-dialog-actions style="display: flex;">
      <span style="flex-grow: 2;">
        <button mat-button (click)="close()">Close</button>
      </span>
    </div>
  `,
} )

export class NewInvestmentSelectorModalComponent implements OnInit, AfterViewInit {

  @ViewChild( 'investmentSelector', { static: false } ) investmentSelectorComponent: NewInvestmentSelectorComponent;

  deviceIsMobile: boolean = false;
  appName: string = environment.appName;

  constructor( private _state: GlobalState,
               private _detectorService: DeviceDetectorService,
               public dialogRef: MatDialogRef<NewInvestmentSelectorModalComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any  ) {
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );
  }

  ngOnInit(): void {
    this._state.globalVars.investmentSelector = this;
  }

  ngAfterViewInit() {
    // this.newInvestmentSelectorModal.open();
    setTimeout( () => {
      this.investmentSelectorComponent.startSelection( this.data?.finderUser, {
        multiSelect: this.data?.multiSelect,
        forBenchmarkProxy: this.data?.forBenchmarkProxy,
        tabsToHide: this.data?.tabsToHide,
      } );
    } );
  }

  /*  open( finderUser: NewInvestmentFinderUserInterface, multiSelect?: boolean ) {
      this.newInvestmentSelectorModal.open();
      this.investmentSelectorComponent.startSelection( finderUser, multiSelect );
    }*/

  close() {
    this.investmentSelectorComponent.finishSelection();
    this.dialogRef.close();
  }

  closed() {
    this.investmentSelectorComponent.storeUserPreferences();
  }

}
