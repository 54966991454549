import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

@Component( {
  selector: 'rip-trading-view-market-overview',
  template: `
    <!-- TradingView Widget BEGIN -->
    <div style="padding: 0; margin-bottom: 40px;" [style.height]="containerHeight">
      <div class="tradingview-widget-container" #containerDiv>
        <div class="tradingview-widget-container__widget"></div>

      </div>
      <div class="tradingview-widget-copyright">

        <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
        by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
      </div>
    </div>
    <!-- TradingView Widget END -->
  `,
} )

export class TradingViewMarketOverviewComponent implements AfterViewInit {

  settings: any = {};
  @Input() containerHeight: string = '30rem';

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  tabs: any = GlobalState.marketOverviewTabs;

  ngAfterViewInit() {


    this.settings = {
      colorTheme: GlobalState.tradingViewColorTheme,
      isTransparent: false,
      largeChartUrl: '',
      displayMode: 'regular',
      width: '100%',
      height: '100%',
      autosize: true,
      locale: 'en',
      'dateRange': '12M',
      'showChart': true,
      'showSymbolLogo': true,
      'showFloatingTooltip': true,
      'plotLineColorGrowing': 'rgba(41, 98, 255, 1)',
      'plotLineColorFalling': 'rgba(41, 98, 255, 1)',
      'gridLineColor': 'rgba(255, 152, 0, 0)',
      'scaleFontColor': 'rgba(120, 123, 134, 1)',
      'belowLineFillColorGrowing': 'rgba(41, 98, 255, 0.12)',
      'belowLineFillColorFalling': 'rgba(41, 98, 255, 0.12)',
      'belowLineFillColorGrowingBottom': 'rgba(41, 98, 255, 0)',
      'belowLineFillColorFallingBottom': 'rgba(41, 98, 255, 0)',
      'symbolActiveColor': 'rgba(41, 98, 255, 0.12)',
      tabs: this.tabs,
    };
    const script = document.createElement( 'script' );
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
    script.async = true;
    script.innerHTML = JSON.stringify( this.settings );
    this.containerDiv.nativeElement.appendChild( script );

  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }
}

/*
*
* <!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/markets/" rel="noopener" target="_blank"><span class="blue-text">Financial Markets</span></a> by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async>
  {
  "colorTheme": "dark",
  "dateRange": "12M",
  "showChart": true,
  "locale": "en",
  "width": "100%",
  "height": "100%",
  "largeChartUrl": "",
  "isTransparent": false,
  "showSymbolLogo": true,
  "showFloatingTooltip": false,
  "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
  "plotLineColorFalling": "rgba(41, 98, 255, 1)",
  "gridLineColor": "rgba(240, 243, 250, 0)",
  "scaleFontColor": "rgba(120, 123, 134, 1)",
  "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
  "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
  "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
  "symbolActiveColor": "rgba(41, 98, 255, 0.12)",

}
  </script>
</div>
*

<!-- TradingView Widget END -->*/
