import { Injectable, OnDestroy } from '@angular/core';
import { Util } from './util.service';
import { takeUntil } from 'rxjs/operators';
import { Auth } from '../auth.service';
import { ProfileService } from '../pages/profile/profile.service';
import { Subject } from 'rxjs';
import { User } from './dataInterfaces';

@Injectable()

export class UsersUtil implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  static readonly UserStockFunds: string = 'userStockFunds';
  static readonly UserBondFunds: string = 'userBondFunds';

  constructor( private _auth: Auth, private _profileService: ProfileService ) {

  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  simpleUserUpdate( user: User ) {
    this._profileService.updateUser( user )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: () => {
          this._auth.renewTokens();
          console.info( 'User change Saved!' );
        }, error: ( err ) => {
          console.error( err );
          this._auth.renewTokens();
        },
      } );
  }

  savePreferences( prefs: any, cb?: Function ) {
    const user = Util.getLoggedInUser( this._auth );
    // need to stringify to save in db. UsersUtil will parse in checkPreferences
    user.preferences = JSON.stringify( prefs );
    this._profileService.updateUser( user )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: () => {
          this._auth.renewTokens();
          console.info( 'User preferences Saved!' );
          if ( cb ) {
            cb();
          }
        }, error: ( err ) => {
          console.error( err );
          if ( cb ) {
            cb( err );
          }
          this._auth.renewTokens();
        },
      } );
  }

  /**
   *
   * @param auth - Auth object
   * @param pref - string corresponding to key of the preference being requested
   * @returns - the user's preferences object with preference requested. sets both as empty objects if the pref or
   * user prefs don't exist
   */
  static checkPreferences( auth: Auth, pref: string ) {
    if ( auth.authenticated() ) {
      const user = Util.getLoggedInUser( auth );
      let preferences;
      if ( user.preferences ) {
        if ( typeof user.preferences === 'string' ) {
          preferences = JSON.parse( user.preferences );
        } else {
          preferences = user.preferences;
        }
        if ( !preferences[ pref ] ) {
          preferences[ pref ] = {};
        }
        return preferences;
      } else {
        preferences = {};
        preferences[ pref ] = {};
        return preferences;
      }
    } else {
      return {};
    }
  }

  static checkMultiplePreferences( auth: Auth, prefs: string[] ) {
    const user = Util.getLoggedInUser( auth );

    let preferences;

    if ( user.preferences ) {
      if ( typeof user.preferences === 'string' ) {
        preferences = JSON.parse( user.preferences );
      } else {
        preferences = user.preferences;
      }
    } else {
      preferences = {};
    }
    for ( const pref of prefs ) {
      if ( !preferences[ pref ] ) {
        preferences[ pref ] = {};
      }
    }

    return preferences;
  }
}
