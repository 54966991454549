import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Auth } from './auth.service';

const AUTH_INCLUDE: RegExp[] = [
  /admin/,
  /appInfo/,
  /historical/,
  /securities/,
  /stripe/,
  /task-manager/,
  /userColumnSets/,
  /userOverrides/,
  /users/,
  /workspace-account-mappings/,
  /workspace-aggregator/,
  /workspace-benchmarks/,
  /workspace-goals/,
  /workspace-household-members/,
  /workspace-manual-accounts/,
  /workspace-revisions/,
  /workspaces/,

];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept( request: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
    if ( AUTH_INCLUDE.some( pathRegExp => pathRegExp.test( request.url ) ) ) {
      return this.nextHandle( request, next, Auth.getToken() );
    }

    return next.handle( request );
  }

  private nextHandle( req: HttpRequest<any>, next: HttpHandler, accessToken: string ): Observable<HttpEvent<any>> {
    return next.handle( this.applyCredentials( req, accessToken ) );
  }

  private applyCredentials( req: HttpRequest<any>, accessToken: string ): HttpRequest<any> {
    if ( !accessToken ) {
      return req;
    }

    return req.clone( {
      setHeaders: {
        Authorization: `Bearer ${ accessToken }`,
      },
    } );
  }
}
