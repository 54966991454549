import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Util } from '../utils/util.service';

@Injectable()
export class AdminService {

  private readonly baseApiPath: string;
  private readonly lambdaAdminUrl: string;

  constructor( private _http: HttpClient ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaAdminUrl = `${ this.baseApiPath }/admin`;
  }

  getUserStatistics() {
    const url = `${ this.lambdaAdminUrl }/userStats`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  setUserStatistics( stats: any ) {
    const url = `${ this.lambdaAdminUrl }/userStats`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, { statistics: stats }, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
