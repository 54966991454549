import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { GlobalDataService, MarketDataService, PricingService } from '../../../../../globalData';
import { UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../../../../../theme/pipes';
import { Util } from '../../../../../utils/util.service';
import * as _ from 'lodash-es';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { SecurityQueryBoxComponent } from '../../../../../reusableWidgets/securityQueryBox';
import { ManualAccountManagerState } from '../../../../../utils/manualAccountManager.state';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { GlobalState } from '../../../../../global.state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountManager } from '../../../../../utils/accountManager';
import { RiskReturn } from '../../../../../utils/dataInterfaces';
import { Position } from '@ripsawllc/ripsaw-analyzer';
import { faAlarmClock, faAnalytics, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { TreasuryRatesUtil } from '../../../../../utils/treasury-rates.util';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

export interface DividendInterface {
  id: number;
  dividendDate: moment.Moment;
  dividendAmount: number;
  t: number;
  isValid: boolean;
}

@Component( {
  selector: 'rip-stock-option-form',
  templateUrl: './stockOptionForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class StockOptionFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  faAnalytics = faAnalytics;
  faInfoCircle = faInfoCircle;
  faAlarmClock = faAlarmClock;


  @ViewChild( 'queryBox', { static: false } ) queryBox: SecurityQueryBoxComponent;

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account;

  @Output() autoSave: EventEmitter<any> = new EventEmitter<any>();

  subscriberName: string = 'stockOptionForm';

  retrievingTreasuryRates: boolean = false;
  patchingForm: boolean = false;
  retrievingStockRiskReturn: boolean = false;

  constructor( private _pricingService: PricingService,
               private _gdService: GlobalDataService,
               private _state: GlobalState,
               private snackBar: MatSnackBar,
               private _mdService: MarketDataService,
               private _accountManager: AccountManager,
               public treasuryRatesUtil: TreasuryRatesUtil ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    this.form.controls.value.disable();
    this.form.controls.treasury_rate.disable();
    // Logger.log( this.account );
    if ( this.account && this.form ) {
      const position: Position = this.account.positions[0];

      this.patchingForm = true;

      ManualAccountUtil.patchStockOptionForm( this.form, this.account );
      if ( position ) {
        // try to load the list of dividends
        if ( position.dividend_list ) {
          this.dividendList = ManualAccountUtil.parseDividendList( position.dividend_list );
          // Logger.log( this.dividendList.toString() );
        }
        this.updateMaturityMatchedTreasuryRate();

        this.getPublicStockDetails( { ticker: this.form.controls.underlying_stock.value }, true );
      } else {
        // TODO: need to inform user that the position has been lost for some reason
        console.error( `ERROR LOADING POSITION FOR MANUAL STOCK OPTION: ${ this.account.name }` );
      }
    } else {
      this.autofocus();
    }

    // this.updateMarketVolatility();
  }

  updateAccountData() {
    const price = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value, false );
    const position = this.account?.positions[0];

    if ( position?.price !== price ) {
      const quantity = FormsUtil.getSanitizedFloatValue( this.form.controls.quantity.value, false );
      position.price = price;
      position.quantity = quantity;
      position.value = price * quantity;
      this.account.value = position.value;
      this._accountManager.updateManualAccountFields( this.account );
      this._state.notifyDataChanged( 'manual.account.price.recalced', this.account.account_id );
      this.autoSave.emit();
    }
    this.patchingForm = false;
  }

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();

  dividendFrequencyOptions: any = [
    { value: 'None', label: 'No Expected Dividends' },
    { value: 'Continuous', label: 'Continuous' },
    { value: 'Annual', label: 'Annual' },
    { value: 'Semi-Annual', label: 'Semi-Annual' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Custom', label: 'Custom Schedule' },
  ];
  selectedDividendFrequencyValue: string;
  dividendList: DividendInterface[] = [];
  regularDividendAmount;
  treasuryRate;
  optimalExerciseDate;

  calcValue() {
    if ( this.form.controls.price.value && this.form.controls.quantity.value ) {
      const price = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value, false );
      const quantity = FormsUtil.getSanitizedFloatValue( this.form.controls.quantity.value, false );
      Util.setFormattedValue( 'value', 'Currency', price * quantity, this.form );
    }
  }

  calculatePrice() {
    if ( this.form.controls.call_put.value && this.form.controls.underlying_price.value &&
      this.form.controls.exercise_price.value && this.form.controls.maturity_date.value &&
      this.form.controls.volatility.value && this.treasuryRate ) {

      const pricePerShare = ManualAccountUtil.calculateStockOptionPrice(
        this );

      Util.setFormattedValue( 'price_per_share', 'Currency', pricePerShare, this.form );
      this.pricePerShareChanged();
    }
  }

  updateMaturityMatchedTreasuryRate() {
    if ( this.form.controls.maturity_date.value ) {
      this.treasuryRatesUtil.figureOutTTMMatchedRate( this.form )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( rate: number ) => {
            this.treasuryRate = rate; // store treasury rate in decimal, keep full decimal points
            Util.setFormattedValue( 'treasury_rate', 'Percent', rate * 100, this.form );
          }, error: ( err ) => {
            console.error( err );
          },
        } );
    }
  }


  updateMarketVolatility( val: number ) {
    // TODO: get live market vol data.  calc standard deviation using eod historical data
    // If so, need to check if those fields are supplied and set up change events
    Util.setFormattedValue( 'volatility', 'Percent', val, this.form ); // just a place holder for now
  }


  setRegularDividendAmount( dividendAmt: number ) {
    Util.setFormattedValue( 'dividend_per_share', 'Currency', dividendAmt, this.form );
    this.regularDividendAmount = FormsUtil.getSanitizedFloatValue( this.form.controls.dividend_per_share.value, false );
  }

  addNewDividend( dividendAmount?: number ) {
    const dividend: DividendInterface = {
      id: this.dividendList.length + 1,
      dividendAmount: dividendAmount ?? 0,
      dividendDate: null,
      t: 0,
      isValid: false,
    };
    this.dividendList.push( dividend );
  }

  setCustomDividendValue( id, dividendAmount ) {
    const dividendAmountInDollars = FormsUtil.getSanitizedFloatValue( dividendAmount.value, false );
    this.setDividendValue( id, dividendAmountInDollars );
    // format the dividend amount in the form
    dividendAmount.value = ( this.ripCurrencyPipe.transform( dividendAmountInDollars ) );

    // recalculate price
    this.calculatePrice();
  }

  setDividendValue( id, dividendAmountInDollars ) {
    _.find( this.dividendList, ( d: any ) => {
      return d.id === id;
    } ).dividendAmount = dividendAmountInDollars;
  }

  setCustomDividendDate( id, dividendDate ) {
    this.setDividendDate( id, dividendDate );

    // recalculate price
    this.calculatePrice();

  }

  setDividendDate( id, dividendDate ) {
    const dividendDateMoment = moment( dividendDate );
    if ( dividendDateMoment.isAfter( moment() ) ) {
      _.find( this.dividendList, ( d: any ) => {
        return d.id === id;
      } ).dividendDate = dividendDateMoment;
    }
  }

  removeDividend( id ) {
    _.remove( this.dividendList, ( d: any ) => {
      return d.id === id;
    } );
    // recalculate price
    this.calculatePrice();
  }


  clearDividendList() {
    // clear any existing dividends
    this.dividendList = [];
  }

  updateProxyDividendList() {
    // if a regular dividend interval (other than continuous) was supplied, maturity_date must first be set in order to calculate dividend dates
    // calculate proxy regular dividend dates working backward from maturity_date
    if ( this.form.controls.maturity_date.value && this.dividendList.length === 0 && this.regularDividendAmount ) {
      const matDate = moment( this.form.controls.maturity_date.value );
      const today = moment().startOf( 'date' );
      let dateAdjustType;
      let dateAdjustQuantity = 1;
      switch ( this.selectedDividendFrequencyValue ) {
        case 'Annual':
          dateAdjustType = 'y';
          break;
        case 'Semi-Annual':
          dateAdjustType = 'M';
          dateAdjustQuantity = 6;
          break;
        case 'Quarterly':
          dateAdjustType = 'Q';
          break;
        case 'Monthly':
          dateAdjustType = 'M';
          break;
      }

      if ( dateAdjustType ) {
        const divDate = matDate;
        while ( divDate.isAfter( today ) ) {
          divDate.subtract( dateAdjustQuantity, dateAdjustType );
          this.addNewDividend();
          const id = this.dividendList[this.dividendList.length - 1].id;
          this.setDividendValue( id, this.regularDividendAmount );
          this.setDividendDate( id, divDate.toDate() );
        }
      }

    }
  }

  // securityFinder logic
  /*  newSecurityChosen( newSec: any ) {
      if ( newSec ) {
        Logger.log( `new security chosen: ${ newSec.ticker }` );
        // if ( newSec.stocks > 0 && this.isBondOrCash ) {
        //  this.securityFinderMessage = 'You have chosen a security with a non zero stock exposure. Please choose a different security';
        // } else {
        //  this.weight.proxy = newSec.ticker;
          this._state.globalVars.securityFinder.close();
        //  this.stepper.previous();
        // }
      }
    }
    securityFinderMessage: string = '';

    openSecurityFinder() {
      this._state.globalVars.securityFinder.tabShown( this );
    }*/

  underlyingPriceChanged() {
    Util.updateInputCurrencyFormat( 'underlying_price', this.form );
    this.calculatePrice();
  }

  exercisePriceChanged() {
    Util.updateInputCurrencyFormat( 'exercise_price', this.form );
    this.calculatePrice();
  }

  maturityDateChanged() {
    this.updateMaturityMatchedTreasuryRate();
    this.updateProxyDividendList();
    this.calculatePrice();
  }

  dividendFrequencyChanged() {
    this.clearDividendList();
    this.updateProxyDividendList();
    this.calculatePrice();
  }

  dividendPerShareChanged( dividendAmt ) {
    this.clearDividendList();
    this.setRegularDividendAmount( dividendAmt.value );
    this.updateProxyDividendList();
    this.calculatePrice();
  }

  priceChanged() {
    if ( this.form.controls.price.value ) {
      Util.updateInputCurrencyFormat( 'price', this.form );
      this.calcValue();
    }
  }

  pricePerShareChanged() {
    if ( this.form.controls.price_per_share.value ) {
      Util.updateInputCurrencyFormat( 'price_per_share', this.form );
      if ( this.form.controls.shares_per_option.value ) {
        const sharesPerOption = FormsUtil.getSanitizedFloatValue( this.form.controls.shares_per_option.value );
        const pricePerShare = FormsUtil.getSanitizedFloatValue( this.form.controls.price_per_share.value );
        this.form.controls.price.setValue( sharesPerOption * pricePerShare );
        this.priceChanged();
      }
    }
  }

  sharesPerOptionChanged() {
    if ( this.form.controls.shares_per_option.value ) {
      Util.updateInputDecimalFormat( 'shares_per_option', this.form );
      this.calcValue();
    }
  }

  quantityChanged() {
    if ( this.form.controls.quantity.value ) {
      Util.updateInputDecimalFormat( 'quantity', this.form );
      this.calcValue();
    }
  }

  costBasisChanged() {
    if ( this.form.controls.cost_basis.value ) {
      Util.updateInputCurrencyFormat( 'cost_basis', this.form );
    }
  }

  volatilityChanged() {
    if ( this.form.controls.volatility.value ) {
      Util.updateInputPercentFormat( 'volatility', this.form );
      this.calculatePrice();
    }
  }

  tickerChanged( val: any ) {
    this.form.controls.underlying_stock.setValue( val );
  }

  getPublicStockDetails( event: any, fromPatchFunc?: boolean ) {
    if ( event && event.ticker ) {

      const ticker = event.ticker;
      this.form.controls.underlying_stock.setValue( event.ticker );

      this.queryBox.showRetrieving();

      this._gdService.getStock( ticker ).pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( stockSearchResult: any ) => {
            const stockInfo = stockSearchResult.data;
            if ( stockInfo && stockInfo.price ) {

              // ----------------------------------------------------------------------
              // get volatility for the stock using the risk return endpoint, but since it isn't necessary for the other stuff, we can do it async
              this.retrievingStockRiskReturn = true;
              this._mdService.getRiskReturns( [ stockInfo ], 'stock' ).pipe( takeUntil( this.onDestroy ) )
                .subscribe( {
                  next: ( resp: any ) => {
                    if ( resp.data?.riskReturns?.length > 0 ) {
                      const rr: RiskReturn = resp.data.riskReturns[0];
                      if ( rr && rr.updatedStandardDeviation !== null ) {
                        this.updateMarketVolatility( rr.updatedStandardDeviation );
                        this.retrievingStockRiskReturn = false;
                      } else {
                        this.informUserOfRiskReturnError();
                      }
                    } else {
                      this.informUserOfRiskReturnError();
                    }
                  },
                  error: ( err ) => {
                    console.error( err );
                    this.informUserOfRiskReturnError();
                  },
                } );
              // ----------------------------------------------------------------------

              // set fundamentals and price and such
              ManualAccountManagerState.clearStockForm( this.form );
              // ManualAccountManagerComponent.clearStockOptionForm( this.form ); // Do we want to do this?
              ManualAccountManagerState.patchStockForm( this.form, stockInfo );

              Util.setFormattedValue( 'underlying_price', 'Currency', stockInfo.price, this.form );
              this.underlyingPriceChanged();

              if ( stockInfo.last_dividend ) {
                this.selectedDividendFrequencyValue = stockInfo.last_dividend.PaymentFrequency;
                this.form.controls.dividend_frequency.setValue( this.selectedDividendFrequencyValue );
                // this.setRegularDividendAmount( stockInfo.last_dividend.DividendAmount );
                this.dividendPerShareChanged( { value: stockInfo.last_dividend.DividendAmount } );
              }

              if ( !fromPatchFunc ) {
                this.snackBar.open( `Details for ${ ticker } retrieved successfully`, null, Util.getSnackBarOptions() );
              } else {
                this.calculatePrice();
                this.updateAccountData();
              }
            } else {
              if ( !fromPatchFunc ) {
                this.snackBar.open( 'Could not retrieve any data for the provided ticker', null, Util.getSnackBarOptions() );
              }
            }
            this.queryBox.hideRetrieving();
            this.queryBox.resetQueryControl( ticker );
          }, error: ( err ) => {
            console.error( err.err );
            if ( !fromPatchFunc ) {
              this.snackBar.open( 'Could not retrieve any data for the provided ticker', null, Util.getSnackBarOptions() );
            }
          },
        } );

    }
  }

  informUserOfRiskReturnError() {
    this.snackBar.open( 'Could not gather enough data on this stock to calculate stock option volatility', 'dismiss', Util.getSnackBarOptions( true ) );
    this.retrievingStockRiskReturn = false;
  }

  translateMarketCapInfo( stockInfo ) {
    if ( stockInfo.large_cap === 1 ) {
      return 'large';
    }
    if ( stockInfo.mid_cap === 1 ) {
      return 'mid';
    }
    if ( stockInfo.small_cap === 1 ) {
      return 'small';
    }
  }

  translateValueBlendGrowthInfo( stockInfo ) {
    if ( stockInfo.value_stocks === 1 ) {
      return 'value_stocks';
    }
    if ( stockInfo.blend_stocks === 1 ) {
      return 'blend_stocks';
    }
    if ( stockInfo.growth_stocks === 1 ) {
      return 'growth_stocks';
    }
  }

  translateSectorInfo( stockInfo ) {
    const sectors = [ 'sector_basic_materials',
      'sector_communication_services',
      'sector_consumer_cyclical',
      'sector_consumer_defensive',
      'sector_energy',
      'sector_financial_services',
      'sector_healthcare',
      'sector_industrials',
      'sector_real_estate',
      'sector_technology',
      'sector_utilities',
    ];

    for ( const sector of sectors ) {
      if ( stockInfo[sector] === 1 ) {
        return sector;
      }
    }
  }

}
