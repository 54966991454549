import { AfterViewInit, Component, HostListener, inject, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
import { RipsawAbbreviatedNumberPipe, RipsawCurrencyPipe } from '../../theme/pipes';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';
import { BenchmarkState } from '../../utils/benchmark.state';
import { EVENT_NAMES, EXPECTED_VALUE_SCALES, USER_GOAL_TYPE_IDS } from '../../utils/enums';
import { Util } from '../../utils/util.service';
import moment from 'moment';
import { GoalsState } from '../../utils/goals.state';
import { IconUtil } from '../../utils/icon.util';
import { MatDialog } from '@angular/material/dialog';
import { GoalsUtil } from '../../utils/goals.util';
import { faSquare } from '@fortawesome/sharp-solid-svg-icons/faSquare';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
import { faUmbrellaSlash } from '../../custom-icons';
//import { cloneDeep } from 'lodash';
import { Moment } from 'moment/moment';
import { FormsUtil, INVESTOR_GOAL_TYPE_IDS, InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { WealthFluentMessageService } from '../../theme/services';
import _ from 'lodash-es';


// import 'chartjs-adapter-moment';
// import { default as ZoomPlugin } from 'chartjs-plugin-zoom';

@Component( {
  selector: 'rip-expected-wealth-chart',
  template: `
    <div class="ripsaw-card-header" *ngIf="showTitle">{{ title }}</div>
    <div style="text-align: center;" [style.height]="height" [style.width]="width"
         *ngIf="chartDataHasBeenSet && !benchmarkState.hideWealthChart">
      <!-- CUSTOM LEGEND -->
      <div *ngIf="showLegend" class="flexing-row-only with-gap space-around" id="custom-wealth-chart-legend">
        <div style="flex: 1;" class="flexing-row-only with-gap space-around" id="dataset-list">
          <div *ngFor="let set of _chartDataSets; let index = index">
            <div class="flexing-row-only with-gap center-vertically custom-wealth-chart-legend-item"
                 [ngClass]="{'chart-item-inactive': set.hidden  }"
                 (click)="toggleDataSet(set, index)"
                 matTooltip="Click to {{ set.hidden ? 'show' : 'hide' }} {{ set.label }}"
                 matTooltipClass="mat-tooltip-custom">
              <fa-icon [icon]="faSquare" [style.color]="set.borderColor" style="padding-right: 5px;"></fa-icon>
              {{ set.label }} <!--{{  }}-->
            </div>
          </div>
        </div>
        <div class="flexing-row-only with-gap space-around-safe" id="goals-list" *ngIf="showGoals">
          <div *ngFor="let goal of goalsState.goals">
            <div class="flexing-row-only with-gap center-vertically custom-wealth-chart-legend-item"
                 [ngClass]="{'chart-item-inactive': !goal.include  }"
                 *ngIf="goal.type !== emergencyFundType"
                 (click)="toggleGoal(goal)"
                 matTooltip="Click to {{ goal.include ? 'hide' : 'show' }} {{ goal.name }}"
                 matTooltipClass="mat-tooltip-custom"
                 [nbSpinner]="goalsState.idsOfGoalsBeingUpdated[goal.id]">
              <fa-icon [icon]="goalsState.iconsMap[goal?.type] ?? goalsState.defaultIcon"
                       [style.color]="goal.color ?? goalsState.defaultColor"
                       class="goal-icon"></fa-icon>
              <span class="goal-name">
                            {{ goal.name }}
                          </span>
            </div>
          </div>
        </div>
      </div>
      <canvas baseChart #expectedWealthCanvas="base-chart"
              style="margin-top: 10px;"
              [datasets]="_chartDataSets"
              [options]="chartOptions"
              [type]="chartType"
              (error)="chartError($event)"
              (mouseenter)="mouseInChart = true;"
              (mouseleave)="mouseInChart = false;"
      ></canvas><!-- (wheel)="onMouseWheel($event)"-->
    </div>
    <div *ngIf="benchmarkState.hideWealthChart" [style.height]="height" [style.width]="width">
      <div class="ripsaw-card-header">
        Fix errors in the Investor Profile form to see your plan forecast.
      </div>
    </div>
    <!--    <rip-bars-spinner *ngIf="!chartDataHasBeenSet"></rip-bars-spinner>-->
    <!--    <button mat-raised-button (click)="setAnnotations()">set annotations</button>-->
  `,
  styleUrls: [ `./expected-wealth-chart.component.scss` ],
} )

export class ExpectedWealthChartComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

  emergencyFundType: INVESTOR_GOAL_TYPE_IDS = INVESTOR_GOAL_TYPE_IDS.emergency_fund;

  @ViewChild( BaseChartDirective ) expectedWealthCanvas: BaseChartDirective;

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  @HostListener( 'window:resize', [ '$event' ] )
  onResize( /*event: Event*/ ) {
    // Logger.info( 'there was a resize event' );
    this.expectedWealthCanvas?.chart?.update();

  }

  chartDataHasBeenSet: boolean = false;

  @Input() chartDataSets = [];

  _chartDataSets: any[] = [];

  readonly defaultTitle: string = 'My Wealth Plan';

  @Input() showTitle: boolean = true;
  /*  @Input() height: string = '300px';*/
  @Input() deviceIsMobile: boolean;
  @Input() height: string = '600px';
  @Input() width: string = '';
  @Input() showLegend: boolean = true;
  @Input() title: string = this.defaultTitle;
  @Input() yMax: number;
  @Input() yMin: number;

  @Input() showGoals: boolean = true;

  faSquare = faSquare;
  faPlusSquare = faPlusSquare;
  ripCurrencyPipe: RipsawCurrencyPipe = new RipsawCurrencyPipe();
  ripAbbreviatedNumberPipe: RipsawAbbreviatedNumberPipe = new RipsawAbbreviatedNumberPipe();

  hoveredAnnotation: string;

  chartOptions: ChartOptions = {

    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        // bottom: 10,
        // right: 10,
      },
    },
    scales: {
      x: {
        type: 'linear',
        title: {
          display: false,
          text: 'Portfolio Life',
        },
        grid: {
          display: true,
          color: '#C4C6D0',
          offset: false,
          drawTicks: true,
          drawOnChartArea: true,
        },
        ticks: {
          maxTicksLimit: 20,
          callback: ( label: number ) => {
            return String( label );
          },
          font: { size: 14 },
        },
      },
      y: {
        type: 'linear',
        display: true,
        // position: 'left',
        ticks: {
          callback: ( label: number ) => {
            if ( label >= 0 ) {
              return this.ripAbbreviatedNumberPipe.transform( label, '$' );
            } else {
              return '';
            }
          },
          font: { size: 14 },
        },
        min: -100000,
        beginAtZero: true,
        border: { dash: [ 4, 4 ] }, // for the grid lines
        grid: {
          display: true,
          color: '#C4C6D0', // for the grid lines
          offset: false,
          drawTicks: false, // true is default
          drawOnChartArea: true, // true is default
        },
      },
    },
    /*    onHover: ( event, chartElement ) => {
     console.log( event, chartElement );
     // event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
     },*/
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: ( tooltipItems: ( TooltipItem<ChartType> )[] ) => {
            // should only be one tooltipItem
            const tooltip = tooltipItems[ 0 ];
            const label = tooltip.label;
            const dataSet = tooltip.dataset;
            let val = `${ dataSet.label }`;

            const xVal = FormsUtil.sanitizeInput( label );
            if ( tooltip.dataIndex === 0 ) {
              val = `Starting Portfolio Size`;
            } else {
              if ( String( xVal ) === String( this.chartDataSets[ 0 ].retirement ) ) {
                val = `${ val } At Retirement (Withdrawals Begin)`;
              } else if ( dataSet?.data?.length - 1 === tooltip.dataIndex ) {
                val = `${ val } At Portfolio End`;
              }

            }

            const formattedNumber = parseFloat( xVal );
            if ( this.benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.age ) {
              val = `${ val } At Age ${ formattedNumber.toFixed( 2 ) }`; // use parseInt to truncate decimal
            } else if ( this.benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears ) {
              const formattedDate = moment()
                .year( formattedNumber )
                .dayOfYear( ( formattedNumber % 1 ) * 365 );
              val = `${ val } in ${ formattedDate.format( 'MMM, YYYY' ) }`;
            } else if ( this.benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.yearsFromToday ) {
              val = `${ val } ${ formattedNumber.toFixed( 2 ) } year(s) from today`;
            }
            return val;
          },
          label: ( tooltipItem: TooltipItem<ChartType> ) => {
            return this.ripCurrencyPipe.transform( tooltipItem.raw[ 'y' ] );
          },
        },
      },
      annotation: {
        // drawTime: 'afterDatasetDraw',
        annotations: {},
      },
      legend: {
        display: false,
      },
      /* zoom: {
       zoom: {
       wheel: {
       enabled: true,
       speed: 0.3,
       },
       pinch: {
       enabled: true,
       },
       drag: {
       enabled: false,
       },
       mode: 'x',
       },
       pan: {
       enabled: true,
       threshold: 10,
       modifierKey: null,
       mode: 'x',
       },
       },*/
    },
  };

  // plugins: PluginServiceRegistrationOptions[] = [Annotation, ZoomPlugin];

  chartType: ChartType = 'line';

  annotationTooltipElement;

  currentYear: number = new Date().getFullYear();

  today: Moment = moment();

  mouseInChart: boolean = false;

  subscriberName: string = 'expectedWealthChartComponent';

  constructor( private _state: GlobalState,
               public benchmarkState: BenchmarkState,
               public goalsState: GoalsState,
               public dialog: MatDialog,
               private ngZone: NgZone ) {

  }

  ngOnInit() {
    Chart.register( Annotation/*, ZoomPlugin*/ );
    this.checkForChartData();
  }

  ngAfterViewInit() {
    setTimeout( () => {
      if ( this.deviceIsMobile ) {
        this.showLegend = false;
      }
      if ( this.showLegend ) {
        this.chartOptions.scales.y[ 'title' ] = {
          display: true,
          text: 'Wealth',
          font: { size: this.deviceIsMobile ? 10 : 14, weight: '400' },
        };
        this.chartOptions.scales.x[ 'title' ] = {
          display: true,
          text: Util.unCamelCase( this.benchmarkState.expectedWealthScale ),

        };
      }

      const thisChartName = `expectedWealthChart_${ this.title.replace( / /ig, '_' ) }`;
      this.subscriberName = thisChartName;

      if ( environment.env !== 'prod' ) {
        window[ `ripsaw_${  thisChartName}` ] = this;
      }

      this.updateChartOptions();

      this._state.subscribe( EVENT_NAMES.SELECTED_EXPECTED_VALUE_RECALCULATED, () => {
        if ( this.title === this.defaultTitle ) {
          setTimeout( () => {
            this.updateChartOptions();
          } );
        }
      }, this.subscriberName );

      this._state.subscribe( EVENT_NAMES.EXPECTED_WEALTH_BUCKETS_RECALCULATED, () => {
        if ( this.title !== this.defaultTitle ) {
          setTimeout( () => {
            this.updateChartOptions();
          } );
        }
      }, this.subscriberName );

      this._state.subscribe( 'expected.wealth.zoom.changed', ( /*range*/ ) => {
        this.updateChartOptions();
      }, this.subscriberName );
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [
      EVENT_NAMES.EXPECTED_WEALTH_BUCKETS_RECALCULATED,
      EVENT_NAMES.SELECTED_EXPECTED_VALUE_RECALCULATED,
      'expected.wealth.zoom.changed',
    ].join( ' | ' ), this.subscriberName );
  }

  ngOnChanges( changes: SimpleChanges ) {
    if ( changes?.chartDataSets?.currentValue ) {
      // setup new datasets
      this._chartDataSets = this.chartDataSets;

    }
  }

  updateChartOptions() {

    // slice chartDataSets based on zoom range
    if ( this.benchmarkState.expectedWealthRange.currentHigh && this.benchmarkState.expectedWealthRange.currentLow ) {
      const firstSet = this.chartDataSets[ 0 ];
      if ( firstSet ) {
        let start, end;
        for ( let i = 0; i < firstSet.data.length; i++ ) {
          if ( start === undefined && firstSet.data[ i ]?.x > this.benchmarkState.expectedWealthRange.currentLow ) {
            start = i;
          }
          if ( !end && firstSet.data[ i ]?.x > this.benchmarkState.expectedWealthRange.currentHigh ) {
            end = i + 1;
            break;
          }
        }

        this._chartDataSets = _.cloneDeep( this.chartDataSets );
        this._chartDataSets.forEach( ( set: ChartDataset ) => {
          set.data = set.data.slice( start ?? 0, end );
        } );
      }
    }

    if ( this.yMin && this.title !== this.defaultTitle ) {
      this.chartOptions.scales.y[ 'suggestedMin' ] = this.yMin;
    }

    if ( this.yMax && this.title !== this.defaultTitle ) {
      this.chartOptions.scales.y[ 'suggestedMax' ] = this.yMax;
    }

    if ( this.showLegend ) {
      this.chartOptions.scales.x[ 'title' ] = {
        display: true,
        text: Util.unCamelCase( this.benchmarkState.expectedWealthScale ),
        font: { size: this.deviceIsMobile ? 10 : 14, weight: '400' },
      };

      if ( this.deviceIsMobile ) {
        this.chartOptions.scales.x.ticks.font[ 'size' ] = 10;
        this.chartOptions.scales.y.ticks.font[ 'size' ] = 10;
      }
    }

    // this.chartOptions.plugins.legend.display = this.showLegend; // going to use a custom legend instead

    const min = this.benchmarkState.expectedWealthRange.currentLow ?? this.benchmarkState.expectedWealthRange.min;
    // to leave some space for goals' icons
    this.chartOptions.scales.x[ 'suggestedMin' ] = min - 5 >= 10 ? min - 5 : 10;
    const max = Math.ceil(
      this.benchmarkState.expectedWealthRange.currentHigh ?? this.benchmarkState.expectedWealthRange.max,
    );
    this.chartOptions.scales.x[ 'max' ] = max;
    this.setAnnotations();
  }

  /*  this.chartOptions.scales.x[ 'suggestedMin' ] = this.benchmarkState.expectedWealthRange.currentLow ?? this.benchmarkState.expectedWealthRange.min;
   this.chartOptions.scales.x[ 'max' ] = Math.ceil( this.benchmarkState.expectedWealthRange.currentHigh ?? this.benchmarkState.expectedWealthRange.max );

   this.setAnnotations();
   }*/

  checkForChartData() {
    if ( this.chartDataSets?.length > 1 && this.chartDataSets[ 0 ]?.data?.length > 1 ) {
      this.chartDataHasBeenSet = true;
    } else {
      setTimeout( () => {
        this.checkForChartData();
      }, 500 );
    }
  }

  chartError( err ) {
    console.error( err );
  }

  setAnnotations() {
    if ( this.chartDataSets?.length > 1 && this.chartOptions.plugins.annotation ) {

      this.chartOptions.plugins.annotation.annotations = {};

      const portfolioEndXValue = this.chartDataSets[ 0 ]?.data[ this.chartDataSets[ 0 ]?.data.length - 1 ]?.x;

      // ANNOTATION FOR INVESTOR RETIREMENT DATE
      const content: HTMLImageElement = IconUtil.convertFAIconToSvgCanvasImage( this.goalsState.retirementIcon,
        'Retirement',
        '#6D5CE8' );

      this.chartOptions.plugins.annotation.annotations[ 'atRetirement' ] = {
        type: 'line',
        scaleID: 'x',
        // mode: 'vertical',
        value: this.chartDataSets[ 0 ]?.retirement,
        borderColor: '#6D5CE8',
        borderWidth: 2,
        //borderDash: [ 3 ],
        label: {
          position: 'start',
          rotation: 0,
          display: this.title === this.defaultTitle,
          color: '#6D5CE8',
          backgroundColor: ExpectedWealthChartComponent.backgroundColor,
          content: ( ctx: any ) => {
            return ctx.hovered ? 'My Retirement' : content;
          },
          font: {
            weight: 'bold',
          },
        },
        enter: ExpectedWealthChartComponent.enter,
        leave: ExpectedWealthChartComponent.leave,
        z: ExpectedWealthChartComponent.z,
      };


      // ANNOTATION FOR INVESTOR RETIREMENT END
      if ( this.chartDataSets[ 0 ]?.retirementEnd ) {

        const retirementEndContent: HTMLImageElement = IconUtil.convertFAIconToSvgCanvasImage( faUmbrellaSlash, 'Retirement', this.goalsState.defaultColor );

        this.chartOptions.plugins.annotation.annotations[ 'atRetirementEnd' ] = {
          type: 'line',
          scaleID: 'x',
          // mode: 'vertical',
          value: this.chartDataSets[ 0 ]?.retirementEnd,
          borderColor: 'black',
          borderWidth: 2,
          borderDash: [ 4, 4 ],
          label: {
            position: 'start',
            rotation: 0,
            display: this.title === this.defaultTitle,
            //color: this.goalsState.defaultColor,
            color: '#000',
            backgroundColor: ExpectedWealthChartComponent.backgroundColor,
            // backgroundColor: ExpectedWealthChartComponent.backgroundColor,
            content: ( ctx: any ) => {
              return ctx.hovered ? 'End of My Retirement' : retirementEndContent;
            },
            font: {
              weight: 'bold',
            },
          },
          enter: ExpectedWealthChartComponent.enter,
          leave: ExpectedWealthChartComponent.leave,
          z: ExpectedWealthChartComponent.z,
        };
      }

      // ANNOTATION FOR PORTFOLIO END
      this.chartOptions.plugins.annotation.annotations[ 'atPortfolioEnd' ] = {
        type: 'line',
        scaleID: 'x',
        // mode: 'vertical',
        value: portfolioEndXValue,
        borderColor: 'black',
        borderWidth: 2,
        borderDash: [ 3 ],
        label: {
          position: 'start',
          rotation: 0,
          display: this.title === this.defaultTitle,
          color: 'black',
          backgroundColor: 'transparent',
          content: 'Portfolio End',
          font: {
            weight: 'bold',
          },
        },
      };

      // ANNOTATION FOR STYLING OF POST RETIREMENT AREA OF THE CHART
      this.chartOptions.plugins.annotation.annotations[ 'fillAfterRetirement' ] = {
        type: 'box',
        backgroundColor: ( ctx: any ) => this.gradient( ctx, '#7B3AFF1F' ),
        xScaleID: 'x',
        yScaleID: 'y',
        xMin: this.chartDataSets[ 0 ]?.retirement,
        borderColor: 'transparent',
        // backgroundColor: 'rgba(0,0,0,0.08)',
        drawTime: 'beforeDatasetsDraw',
      };

      // ADD/REMOVE ANNOTATIONS FOR GOALS
      if ( this.benchmarkState.goalsState?.goals?.length > 0 ) {
        let counter = 0;
        const sortedGoals: InvestorGoal[] = [ ...this.benchmarkState.goalsState.goals ].sort( this.goalSortFunction );
        for ( const goal of sortedGoals ) {
          if ( goal.type !== USER_GOAL_TYPE_IDS.emergency_fund && goal.include && moment( goal.goal_date ).isAfter( this.today ) ) {
            this.setAnnotationsForGoal( goal/*, counter*/ );
            counter++;
          } else {
            this.clearAnnotationForGoal( goal );
          }
        }
      }
    }
    // this.chartDataSets = [ ...this.chartDataSets ];
    // console.log( this.chartOptions.annotation.annotations );
    // this.expectedWealthCanvas?.chart?.update();
    this.expectedWealthCanvas?.ngOnChanges( {} );
  }

  /**
   * This function takes a InvestorGoal and determines how many annotations need to be added to the chart
   * @param goal { InvestorGoal } - user goal that needs annotation(s) on the chart
   * @returns undefined because it just sets the annotation(s) on the chartOptions.plugins.annotation.annotations object
   */
  setAnnotationsForGoal( goal: InvestorGoal ) {
    const self = this;

    if ( goal.type === USER_GOAL_TYPE_IDS.retirement ) {

      const retirementDate: Moment = moment( goal.goal_date );
      const retirementXValue: number = this.benchmarkState.determineScaleNumber( retirementDate.diff( moment(), 'months' ), retirementDate );
      const retirementIcon: HTMLImageElement = IconUtil.convertFAIconToSvgCanvasImage( this.goalsState.iconsMap[ goal.type ],
        goal.name,
        goal.color ?? self.goalsState.defaultColor );

      this.setSingleAnnotationForGoal( goal, retirementXValue, retirementIcon, goal.color, goal.name );

      const retirementEndDate: Moment = moment( goal.goal_date ).add( goal.lengthOfWithdrawals, 'years' );
      const retirementEndXValue: number = this.benchmarkState.determineScaleNumber( retirementEndDate.diff( moment(), 'months' ), retirementEndDate );
      const retirementEndIcon: HTMLImageElement = IconUtil.convertFAIconToSvgCanvasImage( faUmbrellaSlash, goal.name, goal.color ?? self.goalsState.defaultColor );

      this.setSingleAnnotationForGoal(
        goal,
        retirementEndXValue,
        retirementEndIcon,
        goal.color,
        `${ goal.name } End`,
        true );

    } else {
      const date: Moment = moment( goal.withdrawal_dates?.length > 0 ? GoalsUtil.getMomentFromWithdrawalDate( goal.withdrawal_dates[ 0 ] ) : goal.goal_date );
      const value: number = this.benchmarkState.determineScaleNumber( date.diff( moment(), 'months' ), date );
      const content: HTMLImageElement = IconUtil.convertFAIconToSvgCanvasImage( this.goalsState.iconsMap[ goal.type ], goal.name, goal.color ?? self.goalsState.defaultColor );

      this.setSingleAnnotationForGoal( goal, value, content, goal.color, goal.name );
    }
  }

  /**
   * This function sets a single annotation on the chart for a InvestorGoal
   * @param { InvestorGoal } goal - user goal that needs annotation on the chart (provided here for opening the existing goal dialog on click)
   * @param { number } value  - value on the x-axis where the annotation will be placed
   * @param { HTMLImageElement } content  - image element to be placed in the label of the annotation
   * @param { string } color { string } - color for the annotation border and label
   * @param { string } name  - name for the annotation in the annotations list
   * @param { boolean } secondAnnotation - if true, the annotationId will be suffixed with '_end' so that the second annotation doesn't overwrite the
   *   first
   */
  setSingleAnnotationForGoal(
    goal: InvestorGoal,
    value: number,
    content: HTMLImageElement,
    color: string,
    name: string,
    secondAnnotation?: boolean ) {
    const self = this;
    let annotationId = goal.id ?? goal.name;
    if ( secondAnnotation ) {
      annotationId += '_end';
    }

    this.chartOptions.plugins.annotation.annotations[ annotationId ] = {
      type: 'line',
      scaleID: 'x',
      // mode: 'vertical',
      value,
      borderColor: color ?? 'black',
      borderWidth: 2,
      //borderDash: [ 3 ],
      label: {
        position: 'start',
        rotation: 0,
        display: self.title === self.defaultTitle,
        color: color ?? self.goalsState.defaultColor,
        backgroundColor: ExpectedWealthChartComponent.backgroundColor,
        content: ( ctx ) => {
          return ctx.hovered ? name : content;
        },
        font: {
          weight: 'bold',
        },

      },
      enter: ExpectedWealthChartComponent.enter,
      leave: ExpectedWealthChartComponent.leave,
      z: ExpectedWealthChartComponent.z,
      click: function ( /*{ chart, element }*/ ) {
        if ( !self.deviceIsMobile ) {
          self.ngZone.run( () => {
            self.wealthFluentMessageService.sendOpenEditGoalMessage( goal );
          } );
        }
      },
    };

  }

  clearAnnotationForGoal( goal: InvestorGoal, secondAnnotation?: boolean ) {
    let annotationId = goal.id ?? goal.name;
    // clear the annotation from the chartOptions
    this.chartOptions.plugins.annotation.annotations[ annotationId ] = undefined;

    // for retirement goals, we need to clear the annotation for the end of retirement too
    if ( goal.type === USER_GOAL_TYPE_IDS.retirement ) {
      this.chartOptions.plugins.annotation.annotations[ `${ annotationId } End` ] = undefined;
    }
  }

  goalSortFunction( a: InvestorGoal, b: InvestorGoal ) {

    const aDate: Moment = moment( a.withdrawal_dates?.length > 0 ? GoalsUtil.getMomentFromWithdrawalDate( a.withdrawal_dates[ 0 ] ) : a.goal_date );
    const bDate: Moment = moment( b.withdrawal_dates?.length > 0 ? GoalsUtil.getMomentFromWithdrawalDate( b.withdrawal_dates[ 0 ] ) : b.goal_date );
    if ( !aDate.isValid() ) {
      return 1;
    }
    if ( !bDate.isValid() ) {
      return -1;
    }

    return aDate.isBefore( bDate ) ? -1 : 1;
  }

  toggleDataSet( set: ChartDataset, index: number ) {
    set.hidden = !set.hidden;
    this._chartDataSets[ index ] = set;
    this._chartDataSets = [ ...this._chartDataSets ];
  }

  toggleGoal( goal: InvestorGoal ) {
    console.log( 'toggle goal' );
    goal.include = !goal.include;
    this.goalsState.updateGoal( goal, goal.priority ).subscribe( {
      complete: () => {
        this.benchmarkState?.setupExpectedWealthDataForSelectedFrontierPoint();
        this.benchmarkState?.setupExpectedWealthBuckets();
      },
      error: ( err ) => {
        console.error( err );
      },
    } );
  }

  onMouseWheel( event: any ) {
    if ( this.mouseInChart ) {
      // console.log( 'mouse wheel event in chart', event.wheelDelta );
      event.preventDefault();
      this._state.notifyDataChanged( EVENT_NAMES.WEALTH_CHART_SCROLLED, event?.wheelDelta ?? 0 );
    }
  }

  static enter( ctx: any, event: any ) {
    ctx.hovered = true;
    event.native.target.style.cursor = 'pointer';
    ctx.chart.update();
  }

  static leave( ctx: any, event: any ) {
    ctx.hovered = false;
    event.native.target.style.cursor = 'default';
    ctx.chart.update();
  }

  static z( ctx: any ) {
    return ctx.hovered ? 1000 : 0;
  }

  static backgroundColor( ctx: any ) {
    return ctx.hovered ? '#efefef' : 'transparent';
  }

  gradient( { chart: { ctx }, element }, color: string, rtl = false ) {
    const g = ctx.createLinearGradient( element.x, element.y, element.x2, element.y );
    g.addColorStop( rtl ? 1 : 0, color );
    g.addColorStop( rtl ? 0 : 1, 'transparent' );
    return g;
  }
}
