import { Component } from '@angular/core';

@Component( {
  selector: 'rip-bars-spinner',
  styleUrls: [ './bars.scss' ],
  template: `
    <div style="height: 5.5em;">
      <div class="loader">Loading...</div>
    </div>
  `,
} )
export class BarsSpinnerComponent {

}
