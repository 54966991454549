import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { WithWorkspacePermission } from '../../../shared/workspace-permission';
import { Subject } from 'rxjs';
import { FastLinkComponent } from '../fastLink/fastLink.component';
import { Util } from '../../../utils/util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../../environments/environment';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { faPlus } from '@fortawesome/sharp-solid-svg-icons/faPlus';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { GlobalState } from '../../../global.state';

@Component( {
  templateUrl: `./account-management-dialog.component.html`,
  styleUrls: [ './account-management-dialog.component.scss' ],
} )
export class AccountManagementDialogComponent extends WithWorkspacePermission implements OnDestroy {
  protected readonly faLink: IconDefinition = faLink;
  protected readonly faCog: IconDefinition = faCog;
  protected readonly faUniversity: IconDefinition = faUniversity;
  protected readonly faPlus: IconDefinition = faPlus;
  protected readonly faXmark: IconDefinition = faXmark;

  private readonly onDestroy: Subject<void> = new Subject<void>();

  @ViewChild( 'fastLinkComponent' ) fastLinkComponent: FastLinkComponent;

  publicUtil: typeof Util;
  deviceIsMobile: boolean = false;
  subscriberName: string = 'AccountManagementDialogComponent';
  currentTab: string = 'Connect accounts';
  index: number = 0;
  inWealthFluent: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AccountManagementDialogComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: any,
    private _deviceDetector: DeviceDetectorService,
    private _state: GlobalState,
  ) {
    super();

    this.inWealthFluent = this._state.globalVars.inWealthFluent;
    if ( data?.account_id ) {
      this.currentTab = 'Existing Manual Accounts';
      this.index = 3;
    }

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_AccountManagementDialogComponent' ] = this;
    }

    // this.saveNewButtonOptions.disabled = !this.newAccountForm.valid;
    this.publicUtil = Util;
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._deviceDetector );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  close() {
    this.dialogRef.close();
  }

  goToFastlinkTab( connectionId: number ) {
    if ( !this.inWealthFluent ) {
      this.currentTab = 'Connect accounts';
      this.index = 0;
      setTimeout( async () => {
        await this.fastLinkComponent.init( connectionId );
      } );
    }
  }
}
