import { Routes, RouterModule } from '@angular/router';
import { BenchmarkSetupComponent } from './benchmark-setup.component';

const routes: Routes = [
  {
    path: '',
    component: BenchmarkSetupComponent,
  },
];
export const routing = RouterModule.forChild( routes );
