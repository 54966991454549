<div class="mobile-dashboard-toggles col-12">
  <button mat-raised-button
          class="global-chart-flip-button"
          (click)="flip()">
    <mat-icon *ngIf="showTable">bar_chart</mat-icon>
    <mat-icon *ngIf="!showTable">table_chart</mat-icon>
  </button>

  <button mat-raised-button
          class="widget-menu"
          [color]="showRE ? '' : 'primary'"
          (click)="toggleReal()">
    <fa-layers size="lg" [fixedWidth]="true">
      <fa-icon [icon]="faHouse" size="xs" class="home-icon-stacked"></fa-icon>
      <fa-icon [icon]="faCar" size="xs" class="car-icon-stacked"></fa-icon>
    </fa-layers>
  </button>
  <button mat-raised-button
          class="widget-menu"
          [color]="!showDiffs ? '' : 'primary'"
          (click)="toggleDiffs()">
    <mat-icon>compare_arrows</mat-icon>
  </button>
<!--  <div class="mobile-group-selector">-->
    <!-- Menu for jumping to column group -->
    <rip-go-to-group-menu #columnGroupMenu></rip-go-to-group-menu>
<!--  </div>-->
</div>
<!-- need this component for managing pub/sub of infoType changes, but it doesn't need to show up -->
<rip-column-selector-menu style="display: none;"></rip-column-selector-menu>
