import { takeUntil } from 'rxjs/operators';
import { PricingService } from '../globalData';
import { Quote } from './dataInterfaces';
import { Subject } from 'rxjs';
import { RipsawDecimalPipe, RipsawPercentPipe } from '../theme/pipes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalState } from '../global.state';


export class MarketDataUtil {

  static indexTickerMap: any = {
    'DJI': { label: 'Dow Jones Industrials', type: 'price', index: true, tab: 'stocks' },
    'GSPC': { label: 'S&P 500', type: 'price', index: true, tab: 'stocks' },
    'VIX': { label: 'VIX Volatility', type: 'price', index: true, tab: 'stocks' },
    'VIX3M': { label: '3-Month Volatility', type: 'price', index: true, tab: 'stocks' },
    'IXIC': { label: 'NASDAQ Composite', type: 'price', index: true, tab: 'stocks' },
    'VXN': { label: 'Nasdaq 100 Volatility', type: 'price', index: true, tab: 'stocks' },
    'RUT': { label: 'Russell 2000', type: 'price', index: true },
    'RVX': { label: 'Russell 2000 Volatility', type: 'price', index: true },
    'BCOMCL': { label: 'WTI Crude Oil', type: 'price', index: true },
    'BCOMGC': { label: 'GOLD', type: 'price', index: true },
    'US3M': { label: '3-Month T-Bill Yield', type: 'yield', index: true },
    'US1Y': { label: '1-Year Treasury Yield', type: 'yield', index: true },
    'US2Y': { label: '2-Year Treasury Yield', type: 'yield', index: true },
    'US5Y': { label: '5-Year Treasury Yield', type: 'yield', index: true },
    'US10Y': { label: '10-Year Treasury Yield', type: 'yield', index: true },
    'US30Y': { label: '30-Year Treasury Yield', type: 'yield', index: true },
    'VGSH': { label: 'Short-Term Treasury ETF', type: 'price', index: false, tab: 'treasuries' },
    'VGIT': { label: 'Intermediate-Term Treasury ETF', type: 'price', index: false, tab: 'treasuries' },
    'VGLT': { label: 'Long-Term Treasury ETF', type: 'price', index: false, tab: 'treasuries' },
    'EDV': { label: 'Extended Duration Treasury ETF', type: 'price', index: false, tab: 'treasuries' },
    'VTC': { label: 'Corporate Bond ETF', type: 'price', index: false, tab: 'mbs' },
    'VMBS': { label: 'MBS ETF', type: 'price', index: false, tab: 'mbs' },
    /*'NTUICB': 'Investment Grade Corp Bonds',
    'NTUHCB': 'High Yield Corporate Bonds',*/
  };

  static indexBreakoutCellClassFunction( { row, column } ) {
    return {
      'up': row.dollarChange > 0,
      'down': row.dollarChange < 0,
      // 'left-align': ,
      'right-align': column.prop === 'price' || column.prop === 'dollarChange' || column.prop === 'percentChange' || column.prop === 'ticker',
      'mobile-table-cell-class': true,
    };
  }

  static indexBreakoutHeaderClassFunction( { column } ) {
    return {
      // 'left-align': column.prop === 'description',
      'right-align': column.prop === 'price' || column.prop === 'dollarChange' || column.prop === 'percentChange',
    };
  }

  static getIndexData( pricingService: PricingService, onDestroy: Subject<any>, ripDecimalPipe: RipsawDecimalPipe,
                       ripPercentPipe: RipsawPercentPipe, snackBar: MatSnackBar, events: any, indexBreakoutRows: any,
                       state: GlobalState ) {


    const indexTickersWithType = Object.keys( MarketDataUtil.indexTickerMap ).map( ( i ) => {
      return {
        identifier: `${ i }${ MarketDataUtil.indexTickerMap[i].index ? '' : '' }`,
        type: MarketDataUtil.indexTickerMap[i].index ? 'index' : 'Equity',
      };
    } );

    pricingService.getPrices( indexTickersWithType )
      .pipe( takeUntil( onDestroy ) )
      .subscribe( ( resp: any ) => {

        const indexQuotes: Quote[] = Object.values( resp.data );
        for ( const quote of indexQuotes ) {
          if ( quote?.price ) {
            // Logger.info( quote );
            const change = quote.price - quote.lastClose;
            const plainText = `${ MarketDataUtil.indexTickerMap[quote.ticker].label }: ${ ripDecimalPipe.transform( quote.price, '2-2' ) }
${ change > 0 ? '&#9650;' : change < 0 ? '&#9660;' : '' } ${ ripDecimalPipe.transform( change, '2-2' ) }  (${ ripPercentPipe.transform( ( change ) / quote.lastClose ) })`;
            const event = `
          <span title="${ plainText }">
            <span class="${ change > 0 ? 'up' : change === 0 ? '' : 'down' }">
              ${ MarketDataUtil.indexTickerMap[quote.ticker].label }:
              ${ ripDecimalPipe.transform( quote.price, '2-2' ) }
              ${ change > 0 ? `
            <span class="market-data-arrow">
                &#9650;
            </span>` : '' }
                ${ change < 0 ? `
            <span class="market-data-arrow">
                &#9660;
            </span>
          ` : '' }
            ${ ripDecimalPipe.transform( change, '2-2' ) }
            (${ ripPercentPipe.transform( ( change ) / quote.lastClose ) })
            </span>
          </span>`;

            events.push( event );

            indexBreakoutRows.push( {
              ticker: quote.ticker,
              description: MarketDataUtil.indexTickerMap[quote.ticker].label,
              type: MarketDataUtil.indexTickerMap[quote.ticker].type,
              tab: MarketDataUtil.indexTickerMap[quote.ticker].tab,
              price: quote.price,
              dollarChange: change,
              percentChange: change / quote.lastClose,
            } );
          }
        }
        state.globalVars.indexBreakoutRows = indexBreakoutRows;
        state.notifyDataChanged( 'indexes.retrieved', {} );
      }, ( err ) => {
        events = ['Error Loading Market Data. Will retry in a few minutes'];
        console.error( err );
      } );
  }


  static indexBreakoutCssClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip',
  };

}
