import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { GlobalState } from '../../../../global.state';
import { CarouselComponent } from '../../../carousel';
import { MobileUtil } from '../../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component( {
  selector: 'rip-allocation-charts',
  templateUrl: './allocationCharts.component.html',
  styleUrls: [ './allocationCharts.scss' ],
} )

export class AllocationChartsComponent implements AfterViewInit {

  @Input() slides: any[];
  @Input() nextLabel: string = 'Next';
  @Input() prevLabel: string = 'Back';

  @Output() slideChanged: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChildren( CarouselComponent ) chartCarousels: QueryList<CarouselComponent>;
  @ViewChild( 'chartCarousel', { static: false } ) chartCarousel: CarouselComponent;
  slideLoading: boolean = false;

  deviceIsMobile: boolean = false;

  constructor( private _state: GlobalState,
               private _detectorService: DeviceDetectorService,
               private _cd: ChangeDetectorRef ) {
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      // this.slideChange(0);
    } );
  }

  /*
   * Function called when the carousel changes slides. used here to set the button labels dynamically
   * */
  slideChange( index ) {
    if ( index < this.slides.length - 1 ) {
      this.nextLabel = this.slides[index + 1].group;
    }
    // console.log( `index: ${index} | this.slides.length: ${this.slides.length}` );
    if ( index > 0 ) {
      this.prevLabel = this.slides[index - 1].group;
    }
    this.slideChanged.emit();
  }

  /*
 * Function to have the carousel go to a specific slide
 * @param index { number } - index of the carousel to go to
 * */
  goToCarouselSlide( index ) {
    const carousel = this.chartCarousel; /* this.chartCarousels.find( ( child: CarouselComponent ) => {
      return !!child;
    } );*/
    if ( carousel ) {
      carousel.goToIndex( index );
    } else {
      setTimeout( () => {
        this.goToCarouselSlide( index );
      }, 1000 );
    }
  }

  getCurrentSlideIndex() {
    return this.chartCarousel.stepper.selectedIndex;
  }

}
