import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type TagColor = 'primary' | 'basic';
export type TagWeight = 'regular' | 'medium';

@Component({
  selector: 'rip-tag',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() color: TagColor = 'basic';
  @Input() weight: TagWeight = 'regular';

  @Input() class = '';
  @HostBinding('class')
  get hostClasses(): string {
    return [
      this.class,
      this.color,
      this.weight,
    ].join(' ');
  }
}
