import { Routes, RouterModule } from '@angular/router';
import { TransactionsPageComponent } from './transactions-page.component';

const routes: Routes = [
  {
    path: '',
    component: TransactionsPageComponent,
  },
];
export const routing = RouterModule.forChild( routes );
