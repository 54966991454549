import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountManager } from '../../utils/accountManager';
import { InstitutionIconComponent } from '../institutionIcon';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { GlobalState } from '../../global.state';
import { faStopCircle } from '@fortawesome/free-solid-svg-icons/faStopCircle';

@Component( {
  selector: 'rip-cancel-account-refresh-button',
  template: `
    <button mat-raised-button (click)="$event.stopPropagation(); cancelRefresh()"
            class="icon-button"
            matTooltip="Cancel"
            matTooltipClass="mat-tooltip-custom">
      <fa-icon [icon]="faStopCircle" fixedWidth="true" size="lg"></fa-icon>
    </button>
  `,
} )

export class CancelAccountRefreshButtonComponent {

  faStopCircle = faStopCircle;

  @Input() account: Account;
  @Input() icon: InstitutionIconComponent;

  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor( private _accountManager: AccountManager, private _state: GlobalState ) {

  }

  cancelRefresh() {
    this._accountManager.setAccountAsIdle( this.account.account_id, this.icon );
    this._accountManager.cancelAccountRefreshInterval( this.account.account_id );

    if ( this._state.globalVars.accountsComponent ) {
      this._state.globalVars.accountsComponent.doChanges();
    }
    this.clicked.emit( true );
  }

}
