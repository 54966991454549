import { Injectable } from '@angular/core';
import { Util } from '../utils/util.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ServiceUtil } from '../utils/service.util';
import { Account, Position } from '@ripsawllc/ripsaw-analyzer';
import { ConnectionSyncOption } from '../utils/enums';

@Injectable()
export class GlobalDataService {
  // API path
  lambdaSecuritiesUrl: string;
  lambdaHistoricalUrl: string;
  lambdaWorkspaceAggUrl: string;
  lambdaMarketDataJSUrl: string;
  lambdaColumnSetsUrl: string;
  lambdaAccountMappingsUrl: string;
  lambdaManualAccountsUrl: string;
  baseApiPath: string;

  constructor( private _http: HttpClient ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaSecuritiesUrl = `${ this.baseApiPath }/securities`;
    this.lambdaColumnSetsUrl = `${ this.baseApiPath }/userColumnSets`;
    this.lambdaAccountMappingsUrl = `${ this.baseApiPath }/workspace-account-mappings`;
    this.lambdaManualAccountsUrl = `${ this.baseApiPath }/workspace-manual-accounts`;
    this.lambdaWorkspaceAggUrl = `${ this.baseApiPath }/workspace-aggregator`;
    this.lambdaMarketDataJSUrl = `${ this.baseApiPath }/marketDataJS`;
  }

  // AGG CALLS =======================================================================================================
  addAggConnectionToWorkspace( connectionId: number ) {
    const url = `${ this.lambdaWorkspaceAggUrl }/connections/filter`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .post( url, { connectionId }, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getAllAggAccountsWithPositions() {
    const url = `${ this.lambdaWorkspaceAggUrl }/accounts/positions`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getAggAccountWithPositions( account_id: string | number ) {
    const url = `${ this.lambdaWorkspaceAggUrl }/account/${ account_id }/positions`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getAggConnections(): Observable<any> {
    const url = `${ this.lambdaWorkspaceAggUrl }/connections`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getAggUserToken(): Observable<any> {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/token`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );

    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  registerUserWithAggregator( user: any ): Observable<any> {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/user/register`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .post( url, user, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  deleteAggConnection( connection_id ) {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/connection/${ connection_id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );

    return this._http
      .delete( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  syncAllConnections() {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/connections/sync`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );

    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  syncUserConnectionsLogin( ids: number[] ) {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/connections/sync/login`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
      'aggregator': 'yodlee',
    } );

    return this._http
      .post( url, ids, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  syncConnection( connection_id: string | number ) {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/connections/${ connection_id }/sync`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );

    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  setConnectionSyncOptions(
    connection_id: string | number,
    options: { syncLogin: boolean; syncNightly: ConnectionSyncOption },
  ) {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/connections/${ connection_id }/sync/schedule`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .put( url, { ...options }, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  checkSyncProgress() {
    const url: string = `${ this.lambdaWorkspaceAggUrl }/connections/sync/progress`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );

    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  // Security CALLS ===================================================================================================
  // these don't need authentication as of yet
  getSecurities( tickers: string ) {
    if ( tickers.length === 0 ) {
      return EMPTY;
    }
    const url: string = `${ this.lambdaSecuritiesUrl }/getMultiple/${ tickers }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getSecurity( ticker: string ) {
    const url: string = `${ this.lambdaSecuritiesUrl }/get/${ ticker }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getStock( ticker: string ) {
    const url: string = `${ this.lambdaSecuritiesUrl }/getStock/${ ticker }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  searchFunds( params?: any ) {
    if ( params ) {
      if ( !params.limit ) {
        params.limit = 50;
      }
    }

    const url: string = ServiceUtil.addQueryParams(
      `${ this.lambdaSecuritiesUrl }/search`,
      params,
    );
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  /**
   *
   * @param query - String. REQUIRED. Could be any string with a ticker code or company name. Examples: ‘AAPL’, ‘Apple Inc’, ‘Apple’. You can also
   *   use ISINs for the search: US0378331005. There are no limitations to a minimum number of symbols in the query string.
   * @param options - {Object} -
   *    limit: Number. OPTIONAL. The number of results should be returned with the query. Default value: 15. If the limit is higher than 50, it will
   *   be automatically reset to 50. bonds_only: Number. OPTIONAL. The default value is 0 and search returns only tickers, ETFs, and funds. To get
   *   bonds in result use value 1: “bonds_only=1”. exchange: String. Optional. Filters output by exchange. Allowed input is the exchange code, for
   *   example: US, PA, CC, FOREX and others. type: String. OPTIONAL. The default value is ‘all’. You can specify the type of asset you search for.
   *   Possible values: all, stock, etf, fund, bonds, index, commodity, crypto. Please note: with the value ‘all’ bonds will not be displayed, you
   *   should explicitly request bonds.
   */
  searchEOD( query: string, options?: any ) {
    let url: string = `${ this.lambdaSecuritiesUrl }/search/eod/${ query }`;
    if ( options ) {
      url = ServiceUtil.addQueryParams( url, options );
    }
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getFundFamilyList() {
    const url = `${ this.lambdaSecuritiesUrl }/fundfamilies`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  queryScreener( queryParams ) {
    const url = ServiceUtil.addQueryParams(
      `${ this.lambdaSecuritiesUrl }/screener`,
      queryParams,
    );
    // console.log( url );
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  // USER COLUMNSETS =======================================================================================================
  // these will get the Authorization set in the interceptor
  getCustomColumnSets() {
    const url = `${ this.lambdaColumnSetsUrl }/get`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .get( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  createColumnSet( label: string, columns: string[] ) {
    const url = `${ this.lambdaColumnSetsUrl }/create`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .post( url, { label, columns }, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  updateColumnSet( set_id: string, label: string, columns: string[] ) {
    const url = `${ this.lambdaColumnSetsUrl }/update/${ set_id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .post( url, { label, columns }, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  deleteColumnSet( set_id: string ) {
    const url = `${ this.lambdaColumnSetsUrl }/remove/${ set_id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http
      .delete( url, { headers } )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  // ACCOUNT MAPPINGS =================================================================================================
  // these get Authorization set in interceptor
  getUserAccountMapping() {
    const url = `${ this.lambdaAccountMappingsUrl }/get`;
    return this._http
      .get( url )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  createUserAccountMapping( mapping: any ) {
    const url = `${ this.lambdaAccountMappingsUrl }/create`;
    return this._http
      .post( url, mapping )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  updateUserAccountMapping( mapping: any ) {
    const url = `${ this.lambdaAccountMappingsUrl }/update/${ mapping.id }`;
    return this._http
      .put( url, JSON.stringify( mapping.mapping ) )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  // MANUAL ACCOUNTS ==================================================================================================
  // these get Authorization set in interceptor
  createManualAccount( account: Account ) {
    const url = `${ this.lambdaManualAccountsUrl }/create`;
    return this._http
      .post( url, account )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  updateManualAccount( account: Account ) {
    const url = `${ this.lambdaManualAccountsUrl }/update/${ account.account_id }`;
    return this._http
      .put( url, account )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  updateManualPosition( position: Position ) {
    const url = `${ this.lambdaManualAccountsUrl }/update/${ position.account_id }/position`;
    return this._http
      .put( url, position )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  updateManualPositions( account_id: string, positions: Position[] ) {
    const url = `${ this.lambdaManualAccountsUrl }/update/${ account_id }/positions`;
    return this._http
      .put( url, positions )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getManualAccounts() {
    const url = `${ this.lambdaManualAccountsUrl }/get-all`;
    return this._http
      .get( url )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  deleteManualAccount( account: Account ) {
    const url = `${ this.lambdaManualAccountsUrl }/remove/${ account.account_id }`;
    return this._http
      .delete( url )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  deleteManualPosition( position: Position ) {
    const url = `${ this.lambdaManualAccountsUrl }/remove/position/${ position.position_id }`;
    return this._http
      .delete( url )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  // Zillow CALLS ===================================================================================================

  getRealEstateValue( address, cityStateZip ) {
    const url = `${ this.lambdaMarketDataJSUrl }/getHomeValue?address=${ address }&cityStateZip=${ cityStateZip }`;
    return this._http
      .get( url )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getCurrentMortgageRates(
    state: string,
    creditBucket?: string,
    loanType?: string,
  ) {
    let url = `${ this.lambdaMarketDataJSUrl }/getCurrentMortgageRates?state`;
    if ( state ) {
      url = `${ url }=${ state }`;
    } else {
      url = `${ url }=US`;
    }
    if ( creditBucket ) {
      url = `${ url }&creditBucket=${ creditBucket }`;
    }
    if ( loanType ) {
      url = `${ url }&loanType=${ loanType }`;
    }
    return this._http
      .get( url )
      .pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }
}
