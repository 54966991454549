import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getWorkspaceMenuGroups, WorkspaceMenuGroup } from './workspace-menu.meta';
import { combineLatest, Observable } from 'rxjs';
import { AppStoreService } from '../../../../../../../../store';
import { map } from 'rxjs/operators';
import { SharedWorkspaceDto, WorkspaceWithUsersDto } from '../../../../../../../../api';
import { WorkspaceLoadedStore } from '../../../../../../../../store/workspace';

export interface LoadWorkspaceEvent {
  type: WorkspaceLoadedStore['type'];
  workspace: WorkspaceWithUsersDto | SharedWorkspaceDto;
}

@Component({
  selector: 'rip-workspace-menu',
  templateUrl: './workspace-menu.component.html',
  styleUrls: ['./workspace-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceMenuComponent implements OnInit {
  menuGroups$: Observable<WorkspaceMenuGroup[]>;

  @Input() loadedWorkspace: WorkspaceLoadedStore;
  @Input() isRefreshing: boolean;
  @Output() loadWorkspace = new EventEmitter<LoadWorkspaceEvent>();

  constructor(
    private appStoreService: AppStoreService,
  ) { }

  ngOnInit(): void {
    this.menuGroups$ = this.initMenuGroupsObs();
  }

  private initMenuGroupsObs(): Observable<WorkspaceMenuGroup[]> {
    return combineLatest([
      this.appStoreService.myWorkspaces$,
      this.appStoreService.sharedWorkspaces$,
      this.appStoreService.currentUserIsFinancialAdvisor$,
      this.appStoreService.creatingIdsWorkspaces$,
    ])
      .pipe(
        map(([my, shared, isFinancialAdvisor, creatingIds]) => getWorkspaceMenuGroups(isFinancialAdvisor)
          .map(group => ({
            ...group,
            items: group.key === 'my' ?
              my.map(el => ({ ...el, isCreating: !!creatingIds.find(crId => crId === el.id) })) :
              shared.map(el => ({ ...el, isCreating: false })),
          })),
        ),
      );
  }

}
