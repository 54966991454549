import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
} from '@angular/core';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utils/util.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Logger } from '../../../utils/logger.service';

export interface Disclaimer {
  title: string;
  msg: string;
}

@Component( {
  selector: 'rip-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: [ './disclaimers.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class DisclaimersComponent implements AfterViewInit {

  deviceIsMobile: boolean = false;
  webKitHandlerAvailable: boolean = false;
  appName: string = environment.appName;
  companyName: string = environment.common.companyName;

  constructor( private _elRef: ElementRef,
               private _cd: ChangeDetectorRef,
               private _detectorService: DeviceDetectorService,
               public dialogRef: MatDialogRef<DisclaimersComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any ) {
    if ( environment.env !== 'prod' ) {
      window['ripsaw_disclaimers'] = this;
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    this.webKitHandlerAvailable = Util.isWebkitHandlerAvailable();
    if ( this.data ) {
      this.disclaimerToHighlight = this.data.disclaimerToHighlight;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.checkForHighlight();
    }, 1000 );
  }

  checkForHighlight() {
    if ( this.disclaimerToHighlight >= 0 ) {
      const item = this._elRef.nativeElement.ownerDocument.getElementById( `disclaimer_${ this.disclaimerToHighlight }` );
      if ( item ) {
        const scrollTo = this.disclaimerToHighlight === 0 ? 0 : item.offsetTop - 50;
        let ascendantElement = '';
        if ( this.deviceIsMobile ) {
          ascendantElement = Util.findAscendantElementById( item, 'mobile-disclaimers-bottom-sheet-content' );
        } else {
          ascendantElement = Util.findAscendantElementByClass( item, 'mat-dialog-content' );
        }

        if ( ascendantElement ) {
          Util.scrollTo( ascendantElement, scrollTo, 1000 );
        } else {
          Logger.error( `Couldn't find ascendant element for scrolling` );
        }
      }
    }
    this.doChanges();
  }

  doChanges() {
    this._cd.detach();
    this._cd.detectChanges();
    this._cd.reattach();
  }

  openExternal( url: string ) {
    Util.openExternalUrl( url );
  }

  disclaimerToHighlight: number;

  disclaimers = [
    {
      // index 0
      msg: 'One day changes are calculated via current price of security less yesterdays price divided by yesterday’s price. Prices used in this calculation are provided by AlphaVantage or EOD Historical Data and accounting data is provided by your manual account input or through Envestnet Yodlee for connected accounts. 1 day changes are only aggregated for investment accounts.',
      title: '1-Day Return',
    },
    // index 1
    {
      msg: `Reported yields are typically used as estimates of future realized yields on securities. Except for money market funds, the annualized 30-day SEC yield for mutual funds and ETFs is the net investment income per share for a 30-day period prior to the last day of the previous month divided by the maximum offering price per share on that last day. For money market funds, it is a trailing 7-day yield. For bond funds, yield to maturity is used when available. Individual loans and bonds have yield to maturity inputs. For annuities, social security and pensions, it is the current annual payment divided by current value. For individual stocks, the most recent regular dividend times four divided by current market price is used to calculate a dividend yield.  Also, note that these yield estimates are not risk-adjusted. Furthermore, in portfolio construction, portfolio yield estimates may be more useful in relative terms (higher versus lower) than absolute terms. Note: In cases where a bond Mutual Fund or ETF is composed of 75% or more in bonds ${ this.appName } substitutes Yield to Maturity (YTM) for this column. `,
      title: 'Yield',
    },
    // index 2
    {
      msg: `Prices are sourced primarily from Envestnet Yodlee for connected accounts. If a recent price is not available prices will be sourced from EOD Historical or Alpha Vantage for equities and ETFs, from EOD Historical for Mutual Funds.  The various pricing sources used are dependant on availability of data based on market hours.`,
      title: 'Prices',
    },
    // index 3
    {
      msg: `<span>Sometimes your browser may cache a version of ${ this.appName } even when a newer version is available. When that happens, you can clear the cached files from your browser to load the newest version by following one of these steps: </span>
<ul><li>Most Mac Browsers: Command (&#8984;) + Shift (&#8679;) + Delete (&#9003;), except in Safari where the shortcut is Command (&#8984;) + Option (&#8997;) + E </li>
<li>Most Windows Browsers: Control (&#8963;) + Shift (&#8679;) + Delete (&#9003;)</li></ul>
<span>After clearing your cache, hit your browser refresh button to load the latest version of ${ this.appName }</span>
<span>Here is a helpful article if you want more information: <a (click)="openExternal('https://www.lifewire.com/how-to-clear-cache-2617980')" href="https://www.lifewire.com/how-to-clear-cache-2617980" class="rip-link" target="_blank">How to clear your browser cache</a></span>`,
      title: 'Clearing Your Browser Cache',
    },

    // index 4
    {
      msg: `Estimate of expected future annual account fees as a percentage of the current account balance or in actual dollars. Fees are calculated by retrieving all fees for the last 90 days (~1 quarter's worth) and multiplying by 4 to get an annualized number.  Alternatively, it is the user input for annual advisor fee percentage or dollars from the Connection Manager or Account Settings dialogs`,
      title: 'Account Fees',
    },
    // index 5

    {
      msg: `${ this.companyName } is not an investment advisor or investment broker. ${ this.companyName } is a software company that provides tools to aid in portfolio management. You should not construe any such information as legal, tax, investment, financial, or other advice of any kind. ${ this.companyName } is not responsible for any investment decisions or any damages or other losses resulting from such decisions that arise in any way from the use of this product or any materials or information accessible through it. Data and analytic quality depend on third-party data and your inputs. You ultimately are in charge of your finances and may consider consulting a professional financial advisor.`,
      title: `Use of ${ this.appName }`,
    },
    // index 6

    {
      msg: `Expected Wealth gives you a look at future expected returns for your wealth portfolio. At any future date from now, T, wealth outcomes <img src="assets/img/app/plan/math-expectedwealth-1.svg"> depend on the compounding of each wealth portfolio rate of return, <img src="assets/img/app/plan/math-expectedwealth-2.svg"> , along the way. The probability distribution of wealth outcomes is described by its expected wealth measure of central tendency, <img src="assets/img/app/plan/math-expectedwealth-3.svg"> , and standard deviation of wealth from expected wealth as a measure of risk, <img src="assets/img/app/plan/math-expectedwealth-4.svg">. `,
      title: 'Expected Wealth',
    },
    // index 7
    {
      msg: `<span>The information contained within ${ this.appName } application for Fund/ETF fundamentals: (1) is
proprietary to Morningstar and/or its content providers; (2) may not be copied or distributed; and
(3) is not warranted to be accurate, complete or timely. Neither Morningstar nor its content
providers are responsible for any damages or losses arising from any use of this information. Past
performance is no guarantee of future results. <a class="rip-link" (click)="openExternal('https://www.morningstar.com')" href="https://www.morningstar.com" target="_blank">Morningstar</a></span>
`,
      title: 'Morningstar Data',
    },
  ];

}
