<div class="widget-menu-container">    <!--  <div class="col-12">-->
  <div class="widget-menu-left-side" *ngIf="global">
    <!--    <div class="col-6 col-sm-6 col-xs-6">-->
    <button mat-raised-button
            class="global-chart-flip-button primary-btn"
            matTooltip="Show {{ showTable ? 'Chart' : 'Table' }}"
            matTooltipClass="mat-tooltip-custom"
            (click)="flip()">
      <mat-icon *ngIf="showTable">bar_chart</mat-icon>
      <mat-icon *ngIf="!showTable">table_chart</mat-icon>
    </button>

    <button mat-raised-button
            class="real-asset-btn"
            [ngClass]="{'toggled-btn' : !showRE, 'primary-btn' : showRE}"
            color="{{showRE ? '' : 'primary'}}"
            matTooltip="{{ showRE ? 'Hide' : 'Show' }} Real Assets and Corresponding Liabilities"
            matTooltipClass="mat-tooltip-custom"
            (click)="toggleReal()">

      <fa-layers size="lg" [fixedWidth]="true">
        <fa-icon [icon]="faHouse" size="xs"></fa-icon>
        <fa-icon [icon]="faCar" size="xs"></fa-icon>
      </fa-layers>


      <span> {{ showRE ? '(In Net Worth Mode)' : '(In Investment Mode)' }}</span>

    </button>
    <button mat-raised-button
            class="widget-menu"
            [ngClass]="{'toggled-btn' : showDiffs, 'primary-btn' : !showDiffs}"
            color="{{!showDiffs ? '' : 'primary'}}"
            matTooltip="{{ !showDiffs ? 'Show the differences between the benchmark and your portfolio' : 'Show the benchmark values' }}"
            matTooltipClass="mat-tooltip-custom"
            (click)="toggleDiffs()">
      <mat-icon>compare_arrows</mat-icon>
    </button>

    <!-- menu for selecting column sets -->
    <rip-column-selector-menu></rip-column-selector-menu>

    <!-- Menu for jumping to column group -->
    <rip-go-to-group-menu></rip-go-to-group-menu>

    <!-- open deviation threshold settings dialog-->
    <button mat-raised-button
            class="widget-menu primary-btn"
            matTooltip="Adjust Benchmark Deviation Thresholds"
            matTooltipClass="mat-tooltip-custom"
            (click)="openSettings()">
      <fa-layers style="font-size: 1.1rem;">
        <fa-icon [icon]="faTrafficLightStop" size="1x"></fa-icon>
        <fa-icon [icon]="faSlidersH" size="xs"></fa-icon>
      </fa-layers>
    </button>
  </div>

  <!--     TODO: remove this div to get rid of market data now that news-ticker isn't compatible-->
  <div class="widget-menu-right-side">
  </div>
  <div class="col-1">
    <!-- Minimize/Restore button for the whole widget at account level-->
    <button mat-raised-button class="minimize-btn pull-right" (click)="minMax()" [ngClass]="{
    'small-maximize-btn': minimized && !global,
    'mobile-class': deviceIsMobile
  }">
      <mat-icon matTooltip="Minimize Dashboard" matTooltipClass="mat-tooltip-custom"
                *ngIf="!minimized">
        minimize
      </mat-icon>
      <mat-icon matTooltip="Restore Account Dashboard" matTooltipClass="mat-tooltip-custom"
                *ngIf="minimized" [ngClass]="{ 'small-maximize-icon': !global }">
        maximize
      </mat-icon>

    </button>
  </div>
  <!--  </div>-->

</div>
