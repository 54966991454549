import { inject, Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { User } from './dataInterfaces';
import { Auth } from '../auth.service';
import { Util } from './util.service';
import { environment } from '../../environments/environment';


export interface AnalyticsUser {
  name: string;
  email: string;
  created_at: Date;
}

export interface AnalyticsUserIdentity {
  id: string;
  userInfo: AnalyticsUser;
}

export interface UserPilotEvent {
  name: string;
  meta: any;
}

export enum USER_PILOT_EVENT_NAMES {
  SAVED_BENCHMARK = 'Wealth Plan Saved',
}

enum UserPilotFunction {
  identify = 'identify',
  track = 'track'
}

interface UserPilotActionQueueItem {
  functionName: string;
  data: UserPilotEvent | AnalyticsUserIdentity;
}

class UserPilot {
  lib: any;
  queue: any[] = [];

  constructor() {
    this.checkForUserPilotLib();
  }

  checkForUserPilotLib(): void {
    this.lib = window[ 'userpilot' ];
    // if the script hasn't loaded yet, then we need to check again with a setTimeout in a second or so
    if ( !this.lib ) {
      setTimeout( () => {
        this.checkForUserPilotLib();
      }, 1000 );
    } else {
      // if queue has any events
      this.emptyQueue();
    }
  }

  emptyQueue() {
    for ( const item of this.queue ) {
      switch ( item.functionName ) {
        case UserPilotFunction.identify:
          this.identify( item.data );
          break;
        case UserPilotFunction.track:
          this.track( item.data );
          break;
      }
    }
  }

  identify( identity: AnalyticsUserIdentity ) {
    if ( !this.lib ) {
      const item: UserPilotActionQueueItem = {
        functionName: UserPilotFunction.identify,
        data: identity,
      };
      this.queue.push( item );
    } else {
      this.lib?.identify( identity.id, identity.userInfo );
    }
  }

  track( event: UserPilotEvent ): void {
    if ( !this.lib ) {
      const item: UserPilotActionQueueItem = {
        functionName: UserPilotFunction.track,
        data: event,
      };
      this.queue.push( item );
    } else {
      this.lib?.track( event.name, event.meta );
    }
  }

  reload(): void {
    this.lib?.reload();
  }
}

class Heap {

  lib: {
    load: ( arg0: string ) => void;
    appId: any;
    identify: ( arg0: string ) => void;
    addUserProperties: ( arg0: AnalyticsUser ) => void;
  };
  loaded = false;

  constructor() {
    Logger.info( `loading heap.io in ${ environment.appName }...` );
    this.lib = window[ 'heap' ];
    if ( this.lib ) {
      this.lib.load( environment.heap_io_app_id );
      this.loaded = true;
    } else {
      Logger.warn( 'HEAP DID NOT LOAD!', true );
    }
  }

  identify( identity: AnalyticsUserIdentity ) {
    if ( this.loaded && this.lib?.appId ) {
      this.lib.identify( identity.id );
      this.lib.addUserProperties( identity.userInfo );
    }
  }
}

@Injectable( {
  providedIn: 'root',
} )
export class UserAnalyticsService {

  private _auth: Auth = inject( Auth );
  private userPilot: UserPilot = new UserPilot();
  private heap: Heap = new Heap();

  constructor() {
    if ( this._auth.authenticated() ) {
      this.identifyToAllAnalytics();
    }
  }

  identifyToAllAnalytics(): void {
    const user: User = Util.getLoggedInUser( this._auth );
    if ( user ) {
      const userInfo: AnalyticsUser = {
        name: user.name,
        email: user.email,
        created_at: user.created_at,
      };
      const identity: AnalyticsUserIdentity = {
        id: user.userId,
        userInfo,
      };
      this.userPilot.identify( identity );
      this.heap.identify( identity );
    }
  }


  trackInUserPilot( event: UserPilotEvent ): void {
    this.userPilot.track( event );
  }

  reloadUserPilot(): void {
    this.userPilot.reload();
  }

}