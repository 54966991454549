import * as moment from 'moment';

export class OneDayChangeUtil {
  static calcOneDayChange( position: any, todayIsTradingDay: boolean ) {
    if ( position.quote ) {
      // we have a quote, let's try and calc a 1 day return
      if ( position.quote.price && position.quote.lastClose && position.security_type !== 'Mutual Fund' ) {
        // the quote has a price, so we should be able to calc the return. either it is an equity (stock or etf) or
        // it is a mutual fund and it is after midnight (or later depending on the pricing source but before market open
        OneDayChangeUtil.oneDayChangeCalcHelper( position );
      } else if ( position.quote.price && position.quote.lastClose && position.security_type === 'Mutual Fund' ) {
        // the quote didn't have a price, so it is probably a mutual fund and the latest closing isn't available (or
        // the market hasn't even closed, so there shouldn't be a 1 day yet)
        // let's check if there is a difference between the price from the aggregator and the previous close in the quote
        // and whether we are past the deadline for mutual fund prices to be reported
        const diff = position.price - position.quote.lastClose;
        const timeFormat = 'h:mm A Z';
        const now = moment(), mfFundsCloseDeadline = moment( '6:00 PM -04:00', [ timeFormat ] ),
          midnightTonight = moment( '11:59 PM -04:00', [ timeFormat ] ),
          midnightThisMorning = moment( '12:00 AM -04:00', [ timeFormat ] ),
          marketOpen = moment( '9:30 AM -04:00', [ timeFormat ] );
        if ( OneDayChangeUtil.shouldCalcMutualFundOneDayChange( diff,
          now,
          mfFundsCloseDeadline,
          midnightTonight,
          midnightThisMorning,
          marketOpen,
          todayIsTradingDay,
          position.quote ) ) {
          // console.info( `calculating change for ${ row.ticker }` );
          OneDayChangeUtil.oneDayChangeCalcHelper( position, true );
          // the assumption here is that the aggregator may have the closing price now and the quote should at least
          // have the previous closing price
        } else {
          position.percentChange = undefined;
          position.dollarChange = undefined;
        }
      } else {
        position.percentChange = undefined;
        position.dollarChange = undefined;
      }
    } else {
      position.percentChange = undefined;
      position.dollarChange = undefined;
    }

  }

  static shouldCalcMutualFundOneDayChange( diff: number,
                                           now: moment.Moment,
                                           mfFundsCloseDeadline: moment.Moment,
                                           midnightTonight: moment.Moment,
                                           midnightThisMorning: moment.Moment,
                                           marketOpen: moment.Moment,
                                           todayIsTradingDay: boolean, quote: any ) {
    if ( todayIsTradingDay ) {
      if ( now.isBetween( midnightThisMorning, marketOpen ) ) {
        return true;
      }
      if ( now.isBetween( mfFundsCloseDeadline, midnightTonight ) ) {
        if ( quote.asOfDate && moment( quote.asOfDate ).date() === moment().date() ) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  private static oneDayChangeCalcHelper( row: any, mfDiff?: boolean ) {
    const price = mfDiff && !row.isProxy ? row.price : row.quote.price;
    row.percentChange = ( price - row.quote.lastClose ) / row.quote.lastClose;
    if ( row.revised_quantity && row.revised_quantity !== 0 ) {
      row.dollarChange = ( price - row.quote.lastClose ) * ( row.quantity + row.revised_quantity );
    } else {
      row.dollarChange = ( price - row.quote.lastClose ) * row.quantity;
    }
  }
}
