import { createAction } from '@ngrx/store';

export const setCurrentUserIsFinancialAdvisor = createAction(
  '[Current User] Set Is Financial Advisor',
  (value: boolean) => ({ payload: value }),
);

export const clearCurrentUser = createAction(
  '[Current User] Clear',
);
