import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Util } from '../utils/util.service';
import { catchError, map } from 'rxjs/operators';
import { Auth } from '../auth.service';
import { GlobalOverride } from '../utils/dataInterfaces';

@Injectable()

export class OverridesService {

  lambdaOverridesUrl: string;

  constructor( private _http: HttpClient ) {
    this.lambdaOverridesUrl = `${ environment.ripsawAPIBaseUrl }/userOverrides`;
  }

  createOverride( override: GlobalOverride ) {
    const url = `${ this.lambdaOverridesUrl }/create`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, override, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getOverride( id: string ) {
    const url = `${ this.lambdaOverridesUrl }/${ id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getOverridesByUser() {
    const url = `${ this.lambdaOverridesUrl }/get`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateOverride( override: GlobalOverride ) {
    const url = `${ this.lambdaOverridesUrl }/update`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, override, { headers } )
      .pipe(
        map( Util.extractData ),
      );
  }

  deleteOverride( id: string ) {
    const url = `${ this.lambdaOverridesUrl }/remove/${ id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.delete( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }
}
