import { AfterViewInit, Component, Input } from '@angular/core';
import { NotificationsComponent } from './notifications.component';
import { Notification } from '../../../../../../../../../utils/dataInterfaces';
import { Router } from '@angular/router';
import { GlobalState } from '../../../../../../../../../global.state';
import { MatDialogRef } from '@angular/material/dialog';
import { Util } from '../../../../../../../../../utils/util.service';
import { environment } from '../../../../../../../../../../environments/environment';

@Component( {
  selector: 'rip-notification',
  template: `
    <div class="row">
      <div class="col-12">
        <h3>{{ notification?.title }}</h3>
        <p [innerHTML]="notification?.details?.text | safe : 'html'"></p>
      </div>
    </div>
    <div *ngIf="notification?.subType === 'positionInconsistency'" class="issues-container">
      <mat-list>
        <mat-list-item *ngFor="let issue of issues">
          <div class="row" *ngIf="issue.type === 'pqDiff'">
            <div class="col-5">
              <p mat-line>
                Ticker: <span class="rip-link" (click)="goToAccount(issue)">{{ issue.ticker }}</span>
              </p>
              <p mat-line>
                Institution Data Anomaly in:
              </p>
              <p mat-line>
                {{ issue.account_name }}
              </p>
              <p mat-line>
                Market Value ( from institution ): {{ issue.value | ripCurrencyPipe }}
              </p>
              <p mat-line>
                However:
              </p>
              <p mat-line>
                Price of {{ issue.price | ripCurrencyPipe }} x Quantity of {{ issue.quantity }}
                = {{ issue.quantity * issue.price | ripCurrencyPipe }}
              </p>
              <p mat-line>
                Difference of {{ issue.diff | ripCurrencyPipe }}
              </p>
            </div>
            <div class="col-6">{{ appName }}'s Wealth Portfolio Management Dashboard uses your position attributes for
              calculations. Your institution is delivering a different Market Value than the calculated Market
              Value of
              price * quantity. Please use <span class="rip-link" (click)="goToAccount(issue)">
          data override
        </span> the enter the correct price or quantity.
            </div>
          </div>
          <div class="row" *ngIf="issue.type === 'cbsDiff'">
            <div class="col-5">
              <p mat-line>
                Ticker: <span class="rip-link" (click)="goToAccount(issue)">{{ issue.ticker }}</span>
              </p>
              <p mat-line>
                Position Data Anomaly in:
              </p>
              <p mat-line>
                {{ issue.account_name }}
              </p>
              <p mat-line>
                Cash: {{ issue.cash ? ( issue.cash | ripPercentPipe ) : 'Not Provided' }}
              </p>
              <p mat-line>
                Bonds: {{ issue.bonds ? ( issue.bonds | ripPercentPipe ) : 'Not Provided' }}
              </p>
              <p mat-line>
                Stocks: {{ issue.stocks ? ( issue.stocks | ripPercentPipe ) : 'Not Provided' }}
              </p>
              <p mat-line>
                Total: {{ ( ( issue.cash || 0 ) + ( issue.bonds || 0 ) + ( issue.stocks || 0 ) ) | ripPercentPipe }}
              </p>
              <p mat-line>
                Difference from 100: {{ issue.diff | ripPercentPipe }}
              </p>
            </div>
            <div class="col-6">
              {{ appName }}'s Wealth Portfolio Management Dashboard uses your position attributes for
              calculations. The reference data provider did not or could not provide the cash, bonds, stocks
              allocation
              percentages, or they were incomplete. Please use <span class="rip-link"
                                                                     (click)="goToAccount(issue)">
          data override
        </span> the enter the values.
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

  `,
  styleUrls: [ './notifications.scss' ],
} )

export class NotificationComponent implements AfterViewInit {

  @Input() notification: Notification;
  @Input() dialogRef: MatDialogRef<NotificationsComponent>;

  issues: any[] = [];

  appName: string = environment.appName;

  constructor( private _router: Router,
               private _state: GlobalState ) {

  }

  ngAfterViewInit() {
    setTimeout( () => {
      if ( this.notification?.details?.issues !== undefined ) {
        this.issues = Object.values( this.notification.details.issues );
      }
    } );
  }

  goToAccount( issue: any ) {
    Util.goToAccount( issue.account_id, issue.ticker, this._state, this._router, this.dialogRef );
  }

  /*  reRunCheck() {
      this._accountManager.applyOverridesToAccounts( this._accountManager.getAllRevisableAccounts() );
      this._accountManager.finishRefresh();
      this._state.globalVars.notificationsModal.closeNotification();
    }*/

}
