import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { GlobalState } from '../../../global.state';
import { RipsawPercentPipe } from '../../../theme/pipes';


@Component( {
  selector: 'rip-risk-exposure-donut',
  template: `
    <div class="ripsaw-card-header">
      {{ titlePrefix }} Risk Exposures
    </div>
    <div class="flexing space-between with-gap">
      <div style="height: 400px;">
        <canvas baseChart #assetAllocationChart="base-chart" *ngIf="listItems.length > 0"
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [type]="chartType">
        </canvas>
      </div>
      <div style="flex: 1;">
        <div class="flexing-row-only space-between with-gap">
          <div *ngFor="let item of listItems; let index = index" class="flexing vertical-flex composition-summary-item">
            <div [style.background-color]="chartColors[index]" class="composition-summary-item-header flexing">
              <span>Total {{ item.label }}</span>
              <span *ngIf="!deviceIsMobile">:</span>
              <span>{{ item.weight.allocation | ripPercentPipe : '2-2' }}</span>
            </div>
            <div>
              <div class="flexing space-around" *ngIf="item?.contributions?.length > 0">
                Sources of {{ item.label }}
              </div>
              <nb-list>
                <nb-list-item *ngFor="let contribution of item.contributions">
                  {{ contribution.ticker }}: {{ contribution.amount | ripPercentPipe : '2-2' }}
                </nb-list-item>
              </nb-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [ './risk-exposure-donut.component.scss' ],
} )

export class RiskExposureDonutComponent implements AfterViewInit, OnChanges {

  @Input() listItems: any[] = [];
  @Input() secondListItems: any[] = [];
  @Input() titlePrefix: string = 'Benchmark';
  @Input() deviceIsMobile: boolean;

  readonly baseTitle: string = 'Asset Allocation';

  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();

  readonly chartColors = [ '#39B54A', '#00AEEF', '#A267E5' ];
  // chart variables
  chartData: any[] = [ { data: [] } ];
  chartLabels: string[] = [];
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        'display': true,
        'color': '#fff',
        'anchor': 'center',
        'clamp': true,
        'align': 'center',
        'font': {
          'weight': 'bold',
        },
        padding: 10,
        formatter: function ( value, context ) {
          return ( context.dataset.data[ context.dataIndex ] as number ) > 0.1 ? new RipsawPercentPipe().transform( value, '0-0' ) : '';
        },
      },
      tooltip: {
        callbacks: {
          label: ( context: TooltipItem<ChartType> ) => {
            const label = context.label;
            const val = context.raw;
            const formattedVal = this.ripPercentPipe.transform( val );
            return `${ label }: ${ formattedVal }`;
          },
        },
      },
      title: {
        display: false,
        text: this.baseTitle,
        color: '#79afd7', // these three lines will need updating once we can move to chartjs 3.x
        font: {
          'weight': 'bolder',
          'size': this._state.chartTitleHeight,
        },
      },
    },


    layout: {
      padding: {
        bottom: 25,
        left: 20,
        right: 20,
        top: 5,
      },
    },

  };
  chartType: ChartType = 'doughnut';

  constructor( private _state: GlobalState ) {

  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.setupChartData();
    } );
  }

  ngOnChanges( changes: SimpleChanges ): void {
    setTimeout( () => {
      this.setupChartData();
    } );
  }

  setupChartData() {
    this.chartData[ 0 ] = {
      data: [],
      offset: 16,
      hoverOffset: 20,
    };
    if ( this.secondListItems ) {
      this.chartData[ 1 ] = {
        data: [],
        offset: 16,
        hoverOffset: 20,
      };
    }
    this.chartLabels = [];
    this.listItems.forEach( ( item ) => {
      this.chartData[ 0 ].data.push( item.weight.allocation );
      this.chartData[ 0 ].backgroundColor = this.chartColors;
      this.chartData[ 0 ].hoverBackgroundColor = this.chartColors;
      this.chartData[ 0 ].borderRadius = 6;
      this.chartData[ 0 ].borderWidth = 0;
      this.chartData[ 0 ].weight = 40;
      this.chartLabels.push( `${ item.label }` ); // : ${ this.ripPercentPipe.transform( h.allocation ) }` );
    } );

    if ( this.secondListItems?.length > 0 ) {
      this.secondListItems.forEach( ( item ) => {
        this.chartData[ 1 ].data.push( item.weight.allocation );
        this.chartData[ 1 ].backgroundColor = this.chartColors;
        this.chartData[ 1 ].hoverBackgroundColor = this.chartColors;
        this.chartData[ 1 ].borderRadius = 6;
        this.chartData[ 1 ].borderWidth = 0;
        // this.chartLabels.push( `${ item.label }` ); // : ${ this.ripPercentPipe.transform( h.allocation ) }` );
      } );
    }

    if ( this.titlePrefix ) {
      this.chartOptions.plugins.title.text = `${ this.titlePrefix } ${ this.baseTitle }`;
    }
  }
}

/*
 // this code is for adding auto-scaling text to the center of the doughnut chart

 // this block goes in the chartOptions
 elements: {
 center: {
 text: 'This text goes in the middle of the doughnut',
 color: '#FF6384', // Default is #000000
 fontStyle: 'Arial', // Default is Arial
 sidePadding: 20, // Default is 20 (as a percentage)
 minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
 lineHeight: 25, // Default is 25 (in px), used for when text wraps
 },
 },

 // this block goes in the OnInit or AfterViewInit
 Chart.register( {
 beforeDraw: ( chart: any ) => {
 if ( chart.config.options.elements.center ) {
 // Get ctx from string
 const ctx = chart.chart.ctx;

 // Get options from the center object in options
 const centerConfig = chart.config.options.elements.center;
 const fontStyle = centerConfig.fontStyle || 'Arial';
 const txt = centerConfig.text;
 const color = centerConfig.color || '#000';
 const maxFontSize = centerConfig.maxFontSize || 75;
 const sidePadding = centerConfig.sidePadding || 20;
 const sidePaddingCalculated = ( sidePadding / 100 ) * ( chart.innerRadius * 2 );
 // Start with a base font of 30px
 ctx.font = `30px ${ fontStyle }`;

 // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
 const stringWidth = ctx.measureText( txt ).width;
 const elementWidth = ( chart.innerRadius * 2 ) - sidePaddingCalculated;

 // Find out how much the font can grow in width.
 const widthRatio = elementWidth / stringWidth;
 const newFontSize = Math.floor( 30 * widthRatio );
 const elementHeight = ( chart.innerRadius * 2 );

 // Pick a new font size so it will not be larger than the height of label.
 let fontSizeToUse = Math.min( newFontSize, elementHeight, maxFontSize );
 let minFontSize = centerConfig.minFontSize;
 const lineHeight = centerConfig.lineHeight || 25;
 let wrapText = false;

 if ( minFontSize === undefined ) {
 minFontSize = 20;
 }

 if ( minFontSize && fontSizeToUse < minFontSize ) {
 fontSizeToUse = minFontSize;
 wrapText = true;
 }

 // Set font settings to draw it correctly.
 ctx.textAlign = 'center';
 ctx.textBaseline = 'middle';
 const centerX = ( ( chart.chartArea.left + chart.chartArea.right ) / 2 );
 let centerY = ( ( chart.chartArea.top + chart.chartArea.bottom ) / 2 );
 ctx.font = `${ fontSizeToUse }px ${ fontStyle }`;
 ctx.fillStyle = color;

 if ( !wrapText ) {
 ctx.fillText( txt, centerX, centerY );
 return;
 }

 const words = txt.split( ' ' );
 let line = '';
 const lines = [];

 // Break words up into multiple lines if necessary
 for ( let n = 0; n < words.length; n++ ) {
 const testLine = `${ line + words[n] } `;
 const metrics = ctx.measureText( testLine );
 const testWidth = metrics.width;
 if ( testWidth > elementWidth && n > 0 ) {
 lines.push( line );
 line = `${ words[n] } `;
 } else {
 line = testLine;
 }
 }

 // Move the center up depending on line height and number of lines
 centerY -= ( lines.length / 2 ) * lineHeight;

 for ( let n = 0; n < lines.length; n++ ) {
 ctx.fillText( lines[n], centerX, centerY );
 centerY += lineHeight;
 }
 // Draw text in center
 ctx.fillText( line, centerX, centerY );
 }
 },
 } );
 */

