<h3 mat-dialog-title>Notifications</h3>
<mat-divider class="modal-header-divider"></mat-divider>
<div mat-dialog-content class="modal-body">

  <nb-stepper #stepper id="notifications-stepper"><!-- [@fadeAnimation]>-->
    <nb-step>
      <div style="width: 100%; text-align: right;">
        <button mat-button (click)="markAllRead()" id="mark-all-read-button">Mark All Read</button>
      </div>
      <div class="notifications-list-container">
        <mat-list>
          <mat-list-item *ngFor="let notification of notifications" id="notification_{{notification.id}}"
                         [ngClass]="{
                     'highlighted': notification.id === currentNotificationId,
                     'read': notification.read,
                     'unread': !notification.read
                     }" (click)="openNotification(notification)" class="notification-list-item">
            <fa-layers *ngIf="notification.type === 'dataQuality'" mat-list-icon>
              <fa-icon [icon]="notificationIcons[notification?.type]"></fa-icon>
              <fa-layers-counter content="!" class="notification-badge-icon"></fa-layers-counter>
            </fa-layers>
            <fa-icon *ngIf="notification.type !== 'dataQuality'" [icon]="notificationIcons[notification?.type]"
                     mat-list-icon></fa-icon>
            <h4 mat-line>{{notification.title}}</h4>
            <p mat-line>{{notification.details?.text | stripHtml }}</p>
            <fa-icon [icon]="notification.read ? notificationIcons.read : notificationIcons.unread" class="pull-right"
                     matTooltip="{{ notification.read ? 'Read' : 'Unread' }} (Click to mark {{ notification.read ? 'unread' : 'read' }})"
                     (click)="toggleRead(notification)">

            </fa-icon>
          </mat-list-item>
          <mat-list-item *ngIf="notifications.length === 0">No Notifications Right Now</mat-list-item>
        </mat-list>
      </div>
    </nb-step>
    <nb-step>
      <rip-notification [notification]="currentNotification" [dialogRef]="dialogRef" *ngIf="currentNotification"></rip-notification>
    </nb-step>
  </nb-stepper>
</div>

<mat-divider class="modal-footer-divider"></mat-divider>
<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Close</button>
  <button mat-raised-button (click)="closeNotification()" *ngIf="currentNotification">
    <mat-icon>chevron_left</mat-icon>
    Back
  </button>
  <div class="pull-right footnote" *ngIf="currentNotification && currentNotification.type === 'dataQuality'">
    *If you have any questions about Data Quality alerts, please contact support at
    <a href="mailto:{{getSupportEmail()}}">{{getSupportEmail()}}</a>
  </div>
</div>
