import { AfterViewInit, Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { MarketDataUtil } from '../../utils/marketData.util';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';

@Component( {
  selector: 'rip-market-index-data',
  template: `
    <div class="index-breakout-table-container market-info-dashboard-tab-content">
      <ngx-datatable [rows]="indexBreakoutRows"
                     [columns]="indexBreakoutColumns"
                     [headerHeight]="40"
                     [rowHeight]="40"
                     [reorderable]="false"
                     [limit]="8"
                     [cssClasses]="indexBreakoutCssClasses"
                     [footerHeight]="50"
                     class="material striped market-data-breakout-table">

      </ngx-datatable>
      <ng-template #caretTemplate ngx-datatable-cell-template let-value="value" let-row="row">
        {{ value }}
        <span *ngIf="row.dollarChange > 0">
          <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="row.dollarChange < 0">
          <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
        <span *ngIf="!row.dollarChange">
          <fa-icon [icon]="faMinus" [fixedWidth]="true" size="lg"></fa-icon>
        </span>
      </ng-template>
      <ng-template #decimalTemplate ngx-datatable-cell-template let-value="value" let-row="row">
        <span *ngIf="row.type === 'price'">{{ value | ripDecimalPipe : '2-2'}}</span>
        <span *ngIf="row.type === 'yield'">{{ value | ripDecimalPipe : '3-3'}}</span>

      </ng-template>
      <ng-template #percentTemplate ngx-datatable-cell-template let-value="value">
        {{ value | ripPercentPipe : '2-2' }}
      </ng-template>
    </div>
  `,
  styleUrls: [ './market-index-data.component.scss' ],

} )

export class MarketIndexDataComponent implements AfterViewInit, OnDestroy {

  @Input() tab: string = '';
  indexBreakoutRows: any[] = [];
  indexBreakoutColumns: any[] = [];
  indexBreakoutCssClasses = MarketDataUtil.indexBreakoutCssClasses;

  @ViewChild( 'caretTemplate', { static: false } ) caretTemplate: TemplateRef<any>;
  @ViewChild( 'decimalTemplate', { static: false } ) decimalTemplate: TemplateRef<any>;
  @ViewChild( 'percentTemplate', { static: false } ) percentTemplate: TemplateRef<any>;

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faMinus = faMinus;

  subscriberName: string = 'market-data-table-component';

  constructor( private _state: GlobalState ) {
    this.indexBreakoutRows = _state.globalVars.indexBreakoutRows;

    if ( environment.env !== 'prod' ) {
      window['ripsaw_marketDataTab'] = this;
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.indexBreakoutColumns = [
        {
          prop: 'ticker',
          name: 'Ticker',
          sortable: false,
          cellTemplate: this.caretTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
        {
          prop: 'description',
          name: 'Description',
          sortable: false,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
          minWidth: 200,
        },
        {
          prop: 'price',
          name: 'Price',
          sortable: false,
          cellTemplate: this.decimalTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
        {
          prop: 'dollarChange',
          name: 'Change',
          sortable: false,
          cellTemplate: this.decimalTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
        {
          prop: 'percentChange',
          name: 'Change (%)',
          sortable: false,
          cellTemplate: this.percentTemplate,
          headerClass: MarketDataUtil.indexBreakoutHeaderClassFunction,
          cellClass: MarketDataUtil.indexBreakoutCellClassFunction,
        },
      ];

      this._state.subscribe( 'indexes.retrieved', () => {
        this.indexBreakoutRows = this._state.globalVars.indexBreakoutRows;
        this.filterMarketData();
      }, this.subscriberName );

      this.filterMarketData(); // need this for filtering the initial set
    } );
  }

  filterMarketData(): void {
    if ( this.tab && this.tab !== '' ) {
      this.indexBreakoutRows = this.indexBreakoutRows.filter( ( row ) => {
        return row.tab === this.tab;
      } );
    }
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( 'indexes.retrieved', this.subscriberName );
  }

}
