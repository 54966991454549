import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { GoToGroupMenuComponent } from '../goToColumnGroupMenu';

@Component({
  selector: 'rip-mobile-dashboard-menu',
  templateUrl: './mobileDashboardMenu.component.html',
  styleUrls: ['./mobileDashboardMenu.scss'],
})

export class MobileDashboardMenuComponent {

  @ViewChild( 'columnGroupMenu', { static: false } ) columnGroupMenu: GoToGroupMenuComponent;

  faHouse = faHouse;
  faCar = faCar;

  @Input() showTable: boolean;
  @Input() showRE: boolean;
  @Input() showDiffs: boolean;
  @Input() global: boolean;

  @Output() toggleRE: EventEmitter<any> = new EventEmitter<any>();
  @Output() flipTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleBenchmarkValues: EventEmitter<any> = new EventEmitter<any>();

  constructor() {

  }

  flip() {
    this.flipTable.emit( {} );
  }

  toggleReal() {
    this.toggleRE.emit( {} );
  }

  toggleDiffs() {
    this.toggleBenchmarkValues.emit( {} );
  }

  random() {
    return Math.random() * 1000;
  }

}
