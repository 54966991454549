import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountManager } from '../../utils/accountManager';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { Util } from '../../utils/util.service';
import { ColumnGroup, GlobalState } from '../../global.state';
import { AllocationWidgetComponent } from '../allocationWidget';
import { UnderweightExposure } from '../../pages/accounts/components/optimizer/components/underweightExposures/underweightExposures.component';
import { Logger } from '../../utils/logger.service';

export interface AccountSelectionRow {
  account_id: string | number;
  selected?: boolean;
  name: string;
  value: number;
  underweightExposureDollars?: number;
  overweights?: any;
}

@Component( {
  selector: 'rip-account-selector',
  template: `
    <div id="account-selector-container">
      <div class="form-check-inline">
        <mat-checkbox name="selectDeselectAll" id="selectDeselectAll"
                      [checked]="selectedAccounts.size === this.selectedAccountNames.length"
                      (change)="selectDeselectAll()">
          Select/Deselect All
        </mat-checkbox>
      </div>
      <div id="account-selector-table-container">
        <mat-table class="account-selector-table" [dataSource]="rows" matSort>

          <!-- Checkbox Column -->
          <ng-container matColumnDef="selected" sticky>
            <mat-header-cell *cdkHeaderCellDef class="sticky-col select-col">Select</mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="sticky-col select-col">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? toggleAccount(row) : null;"
                            [checked]="row.selected">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name" sticky>
            <mat-header-cell *cdkHeaderCellDef mat-sort-header class="sticky-col name-col">Name</mat-header-cell>
            <mat-cell *cdkCellDef="let account" class="sticky-col selector-info-col">
              {{ account.name }}
            </mat-cell>
          </ng-container>
          <!-- Value Column -->
          <ng-container matColumnDef="value" sticky>
            <mat-header-cell *cdkHeaderCellDef mat-sort-header class="sticky-col selector-info-col">Account Value
            </mat-header-cell>
            <mat-cell *cdkCellDef="let account" class="sticky-col selector-info-col">
              {{ account.value | ripCurrencyPipe }}
            </mat-cell>
          </ng-container>

          <!-- Underweight Exposure Column -->
          <ng-container matColumnDef="underweightExposureDollars" sticky>
            <mat-header-cell *cdkHeaderCellDef mat-sort-header
                             class="sticky-col selector-info-col">
              {{underweightExposure?.riskExposure}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let account" class="selector-info-col">
              {{ account.underweightExposureDollars | ripCurrencyPipe }}
            </mat-cell>
          </ng-container>

          <!-- Overweight Columns -->
          <ng-container *ngFor="let overweight of overweightColumns" matColumnDef="{{overweight?.label}}">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header
                             class="selector-info-col">
              {{overweight?.label}}
            </mat-header-cell>
            <mat-cell *cdkCellDef="let account" class="selector-info-col">
              {{ account[overweight.prop] | ripCurrencyPipe }}
            </mat-cell>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <mat-row class="screener-return-table-row" *cdkRowDef="let row; columns: columns;"
                   (click)="toggleAccount(row)"></mat-row>
        </mat-table>
      </div>
    </div>
  `,
  styleUrls: [ './accountSelector.scss' ],
} )

export class AccountSelectorComponent {

  investmentAccounts: Account[] = [];

  private readonly baseColumns: string[] = [ 'selected', 'name', 'value', 'underweightExposureDollars' ];
  columns: string[] = [];

  overweightColumns: any[] = [];
  rows: AccountSelectionRow[] = [];

  @Input() underweightExposure: UnderweightExposure;

  @Output() accountSelectionToggled: EventEmitter<any> = new EventEmitter<any>();

  selectedAccounts: Map<string | number, AccountSelectionRow> = new Map<string | number, AccountSelectionRow>();
  selectedAccountNames: string[] = [];

  constructor( private _accountManager: AccountManager, private _state: GlobalState ) {
    window['accountSelectorComponent'] = this;
  }

  init() {
    this.columns = [...this.baseColumns];
    this.selectedAccounts = new Map<string | number, AccountSelectionRow>();
    this.overweightColumns = [];

    this.investmentAccounts = this._accountManager.getAllRevisableInvestmentAccounts();

    const allocWidget: AllocationWidgetComponent = this._state.globalVars.allocWidget;
    const benchmarkRow = allocWidget.getBenchmarkRow();

    const group = this._state.columnGroupings.find( ( g: ColumnGroup ) => {
      return g.id === this.underweightExposure.row.groupId;
    } );

    for ( const col of group.columns ) {
      if ( benchmarkRow[col].dollar.diff > 0 ) {
        const label = this._state.allColumnsObject[col].account.name;
        this.columns.push( label );
        this.overweightColumns.push( { label, prop: col } );
      }
    }

    for ( const account of this.investmentAccounts ) {
      const selectedAccount: AccountSelectionRow = {
        account_id: account.account_id,
        name: Util.formatAccountDescription( account ),
        selected: false,
        value: account.value,
      };

      if ( this.underweightExposure ) {
        selectedAccount.underweightExposureDollars = this.getAccountExposure( account, this.underweightExposure.row.dimensionProperty );
        for ( const col of this.overweightColumns ) {
          selectedAccount[col.prop] = this.getAccountExposure( account, col.prop );
        }
      }

      this.selectedAccounts.set( account.account_id, selectedAccount );
    }
    this.rows = Array.from( this.selectedAccounts.values() );
    this.selectedAccountNames = [];
  }

  toggleAccount( account: AccountSelectionRow ) {
    this.selectedAccounts.get( account.account_id ).selected = !this.selectedAccounts.get( account.account_id ).selected;
    this.rows = Array.from( this.selectedAccounts.values() );
    this.setAccountNames();
  }

  setAccountNames() {
    this.selectedAccountNames = [];
    Array.from( this.selectedAccounts.values() ).forEach( ( a: AccountSelectionRow ) => {
      if ( a.selected ) {
        this.selectedAccountNames.push( a.name );
      }
    } );
    this.accountSelectionToggled.emit();
  }

  getAccountExposure( account: Account, col: string ): number {
    const accountAllocWidget: AllocationWidgetComponent = this._state.globalVars.accountAllocWidgets[account.account_id];
    if ( accountAllocWidget ) {
      return accountAllocWidget.getRevisedRow()[col].dollar;
    } else {
      Logger.log( `no alloc widget for account: ${ account.account_id }` );
    }

  }

  selectDeselectAll() {
    if ( this.selectedAccountNames.length === this.selectedAccounts.size ) {
      // all are already selected. deselect all
      this.selectedAccounts.forEach( ( account: AccountSelectionRow ) => {
        account.selected = false;
      } );
    } else {
      this.selectedAccounts.forEach( ( account: AccountSelectionRow ) => {
        account.selected = true;
      } );
    }
    this.setAccountNames();

  }

}
