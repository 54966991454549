<rip-vix-chart></rip-vix-chart>

<nb-card [nbSpinner]="loading">
    <nb-card-header style="position: relative;">
        <nb-badge *ngIf="riskReturnError" text="Error Loading Data. Click here to try again"
                  position="top left" (click)="getRiskReturns()"
                  class="button-badge"
                  status="danger">
        </nb-badge>
        <div class="market-info-card-title flexing space-between">
            <div>Forward Looking Stock Market Analysis</div>
            <div>
                <rip-term-radio (termChanged)="changeTerm($event)" [term]="term">

                </rip-term-radio>
            </div>
        </div>
    </nb-card-header>
    <nb-card-header>
        <rip-forward-looking-header [term]="term">

        </rip-forward-looking-header>
    </nb-card-header>
    <nb-card-body class="forward-looking-card-body">
        <div class="flexing">
            <div style="flex: 1; min-width: 0;" class="forward-looking-table">
                <ngx-datatable #forwardLookingMarketsTable
                               [rows]="riskReturnsValues"
                               [columns]="forwardLookingTableColumns"
                               [columnMode]="ColumnMode.force"
                               [headerHeight]="45"
                               [rowHeight]="40"
                               [cssClasses]="cssClasses"
                               [messages]="messages"
                               [reorderable]="false"
                               [style.width]="'100%'"
                               [scrollbarH]="deviceIsMobile"
                               class="material striped"></ngx-datatable>

                <div>
                    <rip-trading-view-symbol-overview [tickers]="tvSymbols"
                                                      [brandingLabel]="'Stock fund data '"
                                                      [initChartRightAway]="true"
                                                      [height]="400">

                    </rip-trading-view-symbol-overview>
                </div>
            </div>
            <div style="flex: 1; overflow:hidden; min-width: 0;">
                <rip-risk-return-scatter-plot [datasets]="scatterDatasetToShow.datasets"
                                              [labels]="scatterDatasetToShow.labels"
                                              [chartTitle]="'Expected Return vs Total Volatility (Risk)'">

                </rip-risk-return-scatter-plot>
            </div>
        </div>

        <div class="market-info-blurb">
            <a *ngIf="deviceIsMobile && !stocksMainBlurbMobileReadMore"
               (click)="stocksMainBlurbMobileReadMore = true;"
               class="rip-link">
                Learn More
            </a>
            <ul *ngIf="!deviceIsMobile || stocksMainBlurbMobileReadMore">
                <li>
                    <div>
                        <b>Long-Term Market Risk Premium</b> is a reasonable estimate of what compensation,
                        on average, the market required to sell the 1-month Treasury Bill and purchase the riskier S&P
                        500 stock portfolio.
                        <a *ngIf="!ltmrpReadMore"
                           (click)="ltmrpReadMore = true;"
                           class="rip-link">
                            Read More
                        </a>
                    </div>
                    <div *ngIf="ltmrpReadMore">
                        The default 7.7% realized value for the Long-Term Market Risk Premium is from the 49-year period
                        (1972-2020) in Exhibit 2.14 on page 38 of the
                        <a (click)="openSBBILink()" class="rip-link">SBBI® 2021 Summary Edition.</a>
                    </div>
                    <div *ngIf="ltmrpReadMore">
                        <a (click)="ltmrpReadMore = false;"
                           class="rip-link">
                            Read Less
                        </a>
                    </div>
                </li>
                <li>
                    <div>
                        <b>Long-Term Market Volatility</b> is the incremental risk of the S&P 500 return minus the
                        1-month Treasury Bill return.
                        <a *ngIf="!ltmvReadMore"
                           (click)="ltmvReadMore = true;"
                           class="rip-link">
                            Read More
                        </a>
                    </div>
                    <div *ngIf="ltmvReadMore">
                        The default 17.2% realized value for the Long-Term Market Volatility is from the 49-year
                        period (1972-2020) in Exhibit 2.14 on page 38 of the
                        <a (click)="openSBBILink()" class="rip-link">SBBI® 2021 Summary Edition.</a>
                    </div>
                    <div *ngIf="ltmvReadMore">
                        <a (click)="ltmvReadMore = false;"
                           class="rip-link">
                            Read Less
                        </a>
                    </div>
                </li>
                <li>
                    <div>
                        <b>Current Market Risk Premium</b> is the compensation required to hold S&P 500 stock portfolio
                        over the 1-month Treasury Bill, especially for short-term investment horizons.
                        <a *ngIf="!cmrpReadMore"
                           (click)="cmrpReadMore = true;"
                           class="rip-link">
                            Read More
                        </a>
                        <div *ngIf="cmrpReadMore">

                            <div>
                                <img src="assets/img/app/market-info/stocks-market-risk-premium-equation.svg"
                                     alt="Market Risk Premium Equation"
                                     class="equation">
                            </div>
                            <br>
                            <div>
                                <img src="assets/img/app/market-info/stocks-market-risk-premium-equation-2.svg"
                                     alt="Market Risk Premium Equation 2"
                                     class="equation">
                            </div>
                        </div>

                        <div>
                            The higher the current risk (VIX), the greater the market risk premium. The lower the
                            current market risk (VIX), the lower the risk premium required by the market.
                        </div>
                        <div *ngIf="cmrpReadMore">
                            <a (click)="cmrpReadMore = false;"
                               class="rip-link">
                                Read Less
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div><b>Expected Return</b> on each stock fund is the current 1-month Treasury Bill yield plus the
                        Fund’s Beta times the Current Stock Market Risk Premium. The Beta is an estimate of the sector
                        fund return sensitivity to a stock market return.
                        <a *ngIf="!expectedReturnReadMore"
                           (click)="expectedReturnReadMore = true;"
                           class="rip-link">
                            Read More
                        </a>
                    </div>
                    <div *ngIf="expectedReturnReadMore">
                        <div>
                            <img src="assets/img/app/market-info/stocks-expected-return-equation.svg"
                                 alt="Expected Return Equation"
                                 class="equation">
                        </div>
                    </div>
                    <a *ngIf="expectedReturnReadMore" (click)="expectedReturnReadMore = false;" class="rip-link">Read
                        Less</a>
                    <!-- -->
                </li>
                <li>
                    <b>Total Volatility</b> is the forward-looking stock fund Total Return Volatility. It has two
                    components: market and non-market information.
                    <a *ngIf="!volatilityReadMore" (click)="volatilityReadMore = true;" class="rip-link">Read More</a>
                    <div *ngIf="volatilityReadMore">
                        <img src="assets/img/app/market-info/stocks-total-return-vol-equation-2.svg"
                             alt="Total Return Volatility Equation 2"
                             class="equation">
                    </div>
                    <div *ngIf="volatilityReadMore">
                        New macro information (i.e., changes in monetary policy, fiscal policy, economic growth,
                        employment growth, global shocks) affect all securities, but to different degrees as represented
                        by its Beta (sensitivity) to Market Return Volatility. Non-Market Return Volatility is
                        associated with firm-specific risks (i.e., management capabilities, industry competitiveness,
                        product life cycle). {{ appName }} employs econometric techniques to estimate Beta and solve for the
                        Non-Market Volatility. The major source of changes in Total Return
                        Volatility is from the changes in Market Return Volatility for which VIX is the ideal update.
                        Therefore, forward-looking Total Volatility
                    </div>
                    <div *ngIf="volatilityReadMore">
                        <img src="assets/img/app/market-info/stocks-total-vol-equation-3.svg"
                             alt="Total Return Volatility Equation 3"
                             class="equation">
                    </div>
                    <a *ngIf="volatilityReadMore" (click)="volatilityReadMore = false;" class="rip-link">Read Less</a>

                    <!---------------------------------------------------------------------------->

                    <!-------------------------------------------------------------------------------->
                </li>
                <li>
                    <div>
                        <b>Expected Sharpe Ratio</b> provides a measure of expected risk-adjusted performance.
                        It is the expected return for the fund less that of the current 1-Month Treasury Yield per unit
                        of the fund’s Total Volatility (Risk).
                        <a *ngIf="!sharpeRatioReadMore"
                           (click)="sharpeRatioReadMore = true;"
                           class="rip-link">
                            Read More
                        </a>
                    </div>
                    <div *ngIf="sharpeRatioReadMore">
                        <img src="assets/img/app/market-info/stocks-expected-sharpe-ratio-equation.svg"
                             alt="Expected Sharpe Ratio Equation"
                             class="equation">
                    </div>
                    <a *ngIf="sharpeRatioReadMore"
                       (click)="sharpeRatioReadMore = false;"
                       class="rip-link">
                        Read Less
                    </a>
                </li>
            </ul>
            <a *ngIf="deviceIsMobile && stocksMainBlurbMobileReadMore"
               (click)="stocksMainBlurbMobileReadMore = false;"
               class="rip-link">
                Read Less
            </a>
        </div>
    </nb-card-body>
    <nb-card-footer class="hide-on-mobile">
        <nb-card-header>
            <div class="flexing space-between">
                <div class="market-info-card-title">
                    Stock Return Correlation Matrix with Decomposition
                </div>
                <div>
                    <rip-term-radio (termChanged)="changeTerm($event)" [term]="term">

                    </rip-term-radio>
                </div>
            </div>
        </nb-card-header>
        <rip-correlation-matrix [riskReturnsValues]="riskReturnsValues"
                                [loading]="loading"
                                [errorLoadingData]="riskReturnError"
                                [term]="term"
                                (retryDataLoad)="getRiskReturns()"></rip-correlation-matrix>

        <div class="market-info-blurb">
            The <b>Return Correlation Matrix with Decomposition</b> indicates all pairwise return correlations between
            the
            stock funds. The lower the correlation, the greater the benefit from diversification. As with Total
            Volatility,
            correlation has two sources of volatility from market and non-market information.
            <a *ngIf="!correlationMatrixReadMore" (click)="correlationMatrixReadMore = true;" class="rip-link">
                Read More</a>
            <div [style.display]="correlationMatrixReadMore ? 'inline' : 'none'">
                <div>For example, the pairwise return correlation between the Growth and Value funds,</div>
                <img src="assets/img/app/market-info/stocks-correlation-equation-1.svg"
                     alt="Correlation Equation 1"
                     class="equation"
                     style="height: 42px;">

                <div>Substituting the market and non-market breakdown,</div>

                <img src="assets/img/app/market-info/stocks-correlation-equation-2.svg"
                     alt="Correlation Equation 2"
                     class="equation"
                     style="height: 50px;">
                <div>
                    Substituting VIX for Market Vol (Volatility) in the equation provides a forward-looking correlation.
                    This methodology for an estimate of forward-looking correlation is also consistent with the
                    frequently observed increase in all pairwise stock correlations as market volatility rises and a
                    decrease in all pairwise stock correlations as market volatility declines. The market and non-market
                    components have a percentage contribution to total correlation. These percentage contributions are
                    in the right-hand-side triangle of the correlation matrix. Total correlation is in the
                    left-hand-side triangle of the correlation matrix.
                </div>
                <div>
                    In the future, as VIX increases, the market percentage contribution increases with no
                    diversification benefit. The non-market percentage contribution that has a diversification benefit
                    declines. That combination increases total correlations. As market volatility declines, the market
                    percentage contribution declines and the non-market percentage contribution with its diversification
                    benefit increases. That combination lowers total correlations. Substituting VIX for Market
                    Volatility updates all known information for future market volatility assessment rather than wait
                    for historical data estimates of market volatility to catch up.
                </div>
            </div>
            <a *ngIf="correlationMatrixReadMore" (click)="correlationMatrixReadMore = false;" class="rip-link">
                Read Less
            </a>

        </div>
    </nb-card-footer>
    <nb-card-footer class="hide-on-mobile">
        <span class="footnote flexing-row-only proxied-footnote">
            <span *ngFor="let key of primeIdentifiersKeysArray " class="proxy-footnote-item">
                {{ primeIdentifiers[ key ].shortName }} is proxied by {{key}}
            </span>
        </span>
    </nb-card-footer>
</nb-card>

<!-- STOCK SECTOR SECTION-->


<nb-card [nbSpinner]="loadingSectors">
    <nb-card-header style="position: relative;">
        <nb-badge *ngIf="sectorRiskReturnError" text="Error Loading Data. Click here to try again"
                  position="top left" (click)="getSectorRiskReturns()"
                  class="button-badge"
                  status="danger">
        </nb-badge>
        <div class="market-info-card-title flexing space-between">
            <div>Forward Looking Stock Market Analysis by Sector</div>
            <div>
                <rip-term-radio (termChanged)="changeTerm($event)" [term]="term">

                </rip-term-radio>
            </div>
        </div>
    </nb-card-header>
    <nb-card-header>
        <rip-forward-looking-header [term]="term">

        </rip-forward-looking-header>
    </nb-card-header>
    <nb-card-body class="forward-looking-card-body">
        <div class="flexing">
            <div style="flex: 1; min-width: 0;" class="forward-looking-table">
                <ngx-datatable #forwardLookingSectorsTable
                               [rows]="sectorRiskReturnsValues"
                               [columns]="forwardLookingTableColumnsForSectors"
                               [columnMode]="ColumnMode.force"
                               [headerHeight]="45"
                               [rowHeight]="40"
                               [cssClasses]="cssClasses"
                               [messages]="messages"
                               [reorderable]="false"
                               [style.width]="'100%'"
                               [scrollbarH]="deviceIsMobile"
                               class="material striped"></ngx-datatable>

                <div>
                    <rip-trading-view-symbol-overview [tickers]="tvSectorSymbols"
                                                      [brandingLabel]="'Stock Sector fund data '"
                                                      [initChartRightAway]="true"
                                                      [height]="400">

                    </rip-trading-view-symbol-overview>
                </div>

            </div>
            <div style="flex: 1; overflow:hidden; min-width: 0;">
                <rip-risk-return-scatter-plot [datasets]="sectorScatterDatasetToShow.datasets"
                                              [labels]="sectorScatterDatasetToShow.labels"
                                              [chartTitle]="'Expected Return vs Total Volatility (Risk)'">

                </rip-risk-return-scatter-plot>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer class="hide-on-mobile">
        <nb-card-header>
            <div class="flexing space-between">
                <div class="market-info-card-title">
                    Stock Sector Return Correlation Matrix with Decomposition
                </div>
                <div>
                    <rip-term-radio (termChanged)="changeTerm($event)" [term]="term">

                    </rip-term-radio>
                </div>
            </div>
        </nb-card-header>
        <rip-correlation-matrix [riskReturnsValues]="sectorRiskReturnsValues"
                                [loading]="loadingSectors"
                                [errorLoadingData]="sectorRiskReturnError"
                                [term]="term"
                                (retryDataLoad)="getSectorRiskReturns()"></rip-correlation-matrix>
    </nb-card-footer>
    <nb-card-footer class="hide-on-mobile">
        <span class="footnote flexing-row-only proxied-footnote">
            <span *ngFor="let key of sectorPrimeIdentifiersKeysArray " class="proxy-footnote-item">
                {{ sectorPrimeIdentifiers[ key ].shortName }} is proxied by {{key}}
            </span>
        </span>
    </nb-card-footer>
</nb-card>

<ng-template ngx-datatable-header-template #addFundHeaderTemplate>
    <!-- TODO: add this back when we are comfortable with users adding their own funds -->
    <!--<fa-icon [icon]="faPlusCircle"
                     class="add-fund-button"
                     (click)="openScreener()">
    </fa-icon>-->
</ng-template>
<ng-template ngx-datatable-cell-template #labelCellTemplate let-value="value" let-row="row">
    <fa-icon [icon]="faMinusCircle" (click)="removeFund( row.identifier )"
                     class="remove-fund-button"
                     *ngIf="row.userAdded">
    </fa-icon>
    <span title="{{ value }}">{{ value }}</span>
</ng-template>
