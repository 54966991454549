import moment from 'moment/moment';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { HouseholdMember } from '@ripsawllc/ripsaw-analyzer';
import { ManualAccountUtil } from './manualAccount.util';
import { RipsawDecimalPipe } from '../theme/pipes';

export class HouseholdMembersUtil {

  static ripDecimalPipe = new RipsawDecimalPipe();

  static birthDateChanged( form: UntypedFormGroup ) {
    if ( form.controls.retirementAge.value ) {
      HouseholdMembersUtil.retirementAgeChanged( form );
    } else if ( form.controls.shiftToWithdrawals.value ) {
      HouseholdMembersUtil.shiftToWithdrawalsChanged( form );
    }

  }

  static retirementAgeChanged( form: UntypedFormGroup ) {
    if ( form.controls.birth_date.value ) {
      form.controls.shiftToWithdrawals.setValue(
        moment( form.controls.birth_date.value ).add(
          FormsUtil.getSanitizedFloatValue( form.controls.retirementAge.value ), 'years' ),
      );
      if ( form.controls.birth_date.value && ( form.controls.retirementAge.value || form.controls.retired.value ) ) {
        const currentAge = Math.abs( moment( form.controls.birth_date.value ).diff( moment(), 'years' ) );
        let retirementAge, retirementDate, yearsInRetirement;
        if ( form.controls.retired.value ) {
          // current age if already retired
          // retirementAge = moment().diff( moment( this.form.controls.birth_date.value ), 'years', true );
          retirementDate = null;
          // deathDate - current age
          yearsInRetirement = ManualAccountUtil.getLifeExpectancy( currentAge, 'f' ) * 1.3; // going to use female because it's a higher guess
        } else {
          retirementAge = parseInt( form.controls.retirementAge.value ); // target retirement age if not already retired
          // get remaining life after retirement age
          yearsInRetirement = ManualAccountUtil.getLifeExpectancy( retirementAge, 'f' ) * 1.25; // going to use female because it's a higher guess
        }

        form.controls.yearsInRetirement.setValue( this.ripDecimalPipe.transform( yearsInRetirement, '0-2' ) );
      }
      HouseholdMembersUtil.yearsInRetirementChanged( form );
    }
  }

  static shiftToWithdrawalsChanged( form: UntypedFormGroup ) {
    if ( form.controls.birth_date.value ) {
      form.controls.retirementAge.setValue(
        moment( form.controls.shiftToWithdrawals.value )
          .diff( form.controls.birth_date.value, 'years' ),
      );
    }
  }

  static yearsInRetirementChanged( form: UntypedFormGroup ) {
    if ( form.controls.shiftToWithdrawals.value ) {
      form.controls.endOfPortfolioLife.setValue(
        moment( form.controls.shiftToWithdrawals.value )
          .add( FormsUtil.getSanitizedFloatValue( form.controls.yearsInRetirement.value ), 'years' ),
      );
    }
    if ( form.controls.retired.value === true ) {
      form.controls.endOfPortfolioLife.setValue(
        moment().add( FormsUtil.getSanitizedFloatValue( form.controls.yearsInRetirement.value ), 'years' ),
      );
    }
  }

  static endOfPortfolioLifeChanged( form: UntypedFormGroup ) {
    if ( form.controls.shiftToWithdrawals.value ) {
      form.controls.yearsInRetirement.setValue(
        moment( form.controls.endOfPortfolioLife.value )
          .diff( form.controls.shiftToWithdrawals.value, 'years', true ).toFixed(2),
      );
    }
  }

  static makeNawForm( form: UntypedFormGroup ) {
    return new UntypedFormGroup( {
      name: new FormControl<string>( '' ),
      relation: new FormControl<string>( '' ),
      birth_date: new FormControl<moment.Moment>( null ),
      retired: new FormControl<boolean>( false ),
      married: new FormControl<boolean>( false ),
      shiftToWithdrawals: new FormControl<moment.Moment>( null ),
      endOfPortfolioLife: new FormControl<moment.Moment>( null ),
      retirementAge: new FormControl<number>( 66 ),
      yearsInRetirement: new FormControl<number>( null ),
    } );
  }

  static makeExistingForm( form: UntypedFormGroup, currentMember: HouseholdMember ) {
    return new UntypedFormGroup( {
      name: new FormControl<string>( currentMember.name ),
      relation: new FormControl<string>( currentMember.relation ),
      birth_date: new FormControl<moment.Moment>( moment( currentMember.birth_date ) ),
      retired: new FormControl<boolean>( currentMember.retired ),
      married: new FormControl<boolean>( currentMember.married ),
      shiftToWithdrawals: new FormControl<moment.Moment>( moment( currentMember.shiftToWithdrawals ) ),
      endOfPortfolioLife: new FormControl<moment.Moment>( moment( currentMember.endOfPortfolioLife ) ),
      retirementAge: new FormControl<number>( null ),
      yearsInRetirement: new FormControl<number>( null ),
    } );
  }
}
