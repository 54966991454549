import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspacePermissionDirective } from './directive/workspace-permission.directive';


@NgModule({
  declarations: [
    WorkspacePermissionDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    WorkspacePermissionDirective,
  ],
})
export class LibWorkspacePermissionModule { }
