import { Injectable } from '@angular/core';
import { RipsawPercentPipe } from '../../../theme/pipes';
import { AbstractControl } from '@angular/forms';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';

@Injectable()
export class BenchmarkHelpers {

  /*
  * Update a benchmark group's total
  * @param group {Object} - the benchmark group whose total is to be updated
  * */
  static updateGroupTotal( group: any ) {
    if ( group.weights ) {
      group.total = 0;
      for ( const weight of group.weights ) {
        group.total += weight.allocation;
      }
    } else {
      group.total = group.cash.allocation + group.stocks.allocation + group.bonds.allocation;
    }
    return group.total;
  }

  /*
  * Function to check that a group's total is 100% when transformed to just 2 decimal places
  * @param total {Number} - the total of a group to be checked
  * */
  static checkGroupTotal( total ) {
    const ripPercentPipe = new RipsawPercentPipe();
    const transformed = ripPercentPipe.transform( total, '2-2' );
    return transformed === '100.00%';
  }

  /*
  * Function for formatting the label of a slider thumb
  * */
  static formatThumbLabel( value: number | null ) {
    if ( !value ) {
      return '0.00%';
    }
    return new RipsawPercentPipe().transform( value / 100, '2-2' );
  }

  /*
  * Helper for sanitizing and parsing input values
  * change to getValueFromControl
  * */
  static getValueFromControl( control: AbstractControl ) {
    let value = parseFloat( FormsUtil.sanitizeInput( control.value ) );
    if ( isNaN( value ) ) {
      value = 0;
    }
    return value;
  }

  /*
  * Function for aggregating the cash, bonds and stocks of a set of proxy funds
  * */
  static aggregateProxies( proxies: any ) {
    const portfolioValue = 1000;
    const breakdown = {
      cash: 0,
      bonds: 0,
      stocks: 0,
    };

    for ( const proxy of proxies ) {
      if ( proxy.sec ) {
        const pValue = portfolioValue * proxy.weight;
        breakdown.cash += pValue * proxy.sec.cash;
        breakdown.stocks += pValue * proxy.sec.stocks;
        breakdown.bonds += pValue * proxy.sec.bonds;
      }
    }

    breakdown.cash = breakdown.cash / portfolioValue;
    breakdown.stocks = breakdown.stocks / portfolioValue;
    breakdown.bonds = breakdown.bonds / portfolioValue;

    return breakdown;
  }

}
