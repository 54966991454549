<button mat-raised-button color="basic"
        [matMenuTriggerFor]="infoTypeMenu"
        matTooltip="Choose a global column set"
        matTooltipClass="mat-tooltip-custom"
        class="column-selector-menu primary-btn"
        *ngIf="deviceIsDesktop">
  <span>{{infoType}}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #infoTypeMenu="matMenu">
  <button mat-menu-item *ngFor="let set of columnSets" (click)="changeInfoType(set.label)">
    <span>{{set.label}}</span>
    <span *ngIf="set.label === infoType">
      <mat-icon class="current-set-indicator">stars</mat-icon>
    </span>
  </button>
  <button mat-menu-item (click)="openColumnSelectorModal()">
    <span>Customize...</span>
  </button>
</mat-menu>
