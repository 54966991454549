import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'rip-mobile-add-manual-account',
  template: `
    <div style="display: flex;" class="bottom-sheet-header">
      <div class="left">
        <rip-mobile-logo-link></rip-mobile-logo-link>
      </div>
      <div class="header-middle">Add Manual Account</div>
      <span><button mat-raised-button (click)="close()" class="mobile-close-btn">Close</button></span>
    </div>
    <div class="bottom-sheet-content">
      <rip-add-manual-account-widget [deviceIsMobile]="true"></rip-add-manual-account-widget>
    </div>
  `,
  styleUrls: [ `./mobileLayoutComponents.scss`, `../mobileLayout.scss` ],
})

export class MobileAddManualAccountComponent implements OnDestroy {

  constructor( private _bottomSheetRef: MatBottomSheetRef<MobileAddManualAccountComponent>,
               private _bottomSheet: MatBottomSheet,
               @Inject( MAT_BOTTOM_SHEET_DATA ) public data: any ) {

    if ( environment.env !== 'prod' ) {
      window['ripsaw_mobileAddManualAccount'] = this;
    }

  }

  ngOnDestroy(): void {
    delete window['mobileAccountDetails'];

  }

  close() {
    this._bottomSheetRef.dismiss();
  }
}
