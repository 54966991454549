import { Component, ElementRef, OnDestroy } from '@angular/core';
import { faGalacticRepublic } from '@fortawesome/free-brands-svg-icons/faGalacticRepublic';
import { faWandMagic } from '@fortawesome/pro-light-svg-icons/faWandMagic';
import { GlobalState } from '../../global.state';
import { AnimateUtil } from '../../utils/animate.util';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons';
import { faOptimizer } from '../../custom-icons';

@Component( {
  selector: 'rip-optimizer-icon',
  template: `
    <span class="optimizer-icon-container">
      <fa-icon [icon]="faChartNetwork" class="magic-wand-icon-component" [fixedWidth]="true"></fa-icon>
    </span>
  `,
  styleUrls: [ `./optimizerIcon.component.scss` ],
} )

export class OptimizerIconComponent implements OnDestroy {

  extraAnimationClasses: string[] = [
    'animate__slow',
  ];

  wandWaveDuration: number = 2;
  spinPie: boolean = false;

  faGalacticRepublic = faGalacticRepublic;
  faWandMagic = faWandMagic;
  faChartNetwork = faChartNetwork;
  faOptimizer = faOptimizer;

  subscriberName: string = `optimizerIcon_${ Math.random() }`;

  optimizationRunning: boolean = false;

  constructor( private _state: GlobalState, private _elRef: ElementRef ) {
    _state.subscribe( 'optimization.started', () => {
      this.spin();
      this.optimizationRunning = true;
    }, this.subscriberName );
    _state.subscribe( 'optimization.complete | optimization.canceled', () => {
      this.stop();
      this.optimizationRunning = false;
    }, this.subscriberName );
  }

  ngOnDestroy() {
    this._state.unsubscribe( 'optimization.started | optimization.complete | optimization.canceled', this.subscriberName );
  }

  spin() {
    AnimateUtil.startAnimation( '.magic-wand-icon-component svg', 'tada', {
      extraClasses: this.extraAnimationClasses,
      // duration: this.wandWaveDuration,
    } ).then( () => {
      // animation done
    } ).catch( ( err ) => {
      console.error( err );
      this.stop();
    } );
    setTimeout( () => {
      if ( this.optimizationRunning ) {
        this.spinPie = true;
      }
    }, this.wandWaveDuration );

  }


  stop() {
    this.spinPie = false;

  }
}
