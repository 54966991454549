import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './holdings.routing';
import { NgaModule } from '../../theme/nga.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { NbCardModule } from '@nebular/theme';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { LibWorkspacePermissionModule } from '../../shared/workspace-permission';
import { SkeletonModule } from 'primeng/skeleton';
import { HoldingsPageComponent } from './holdings-page.component';

const SHARED = [
  LibWorkspacePermissionModule,
];

@NgModule( {
  imports: [
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    NgaModule,
    FontAwesomeModule,
    MatListModule,
    NgxDatatableModule,
    MatProgressBarModule,
    MatIconModule,
    routing,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgChartsModule,
    NbCardModule,
    MatTableModule,
    MatSortModule,
    ...SHARED,
    SkeletonModule,
  ],
  declarations: [
    HoldingsPageComponent,
  ],
  providers: [],
  exports: [],
} )
export class HoldingsModule {}
