import { Component, ViewChild, HostListener, Input, ElementRef, AfterViewInit } from '@angular/core';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';

@Component( {
  selector: 'nga-ba-back-top',
  styleUrls: ['./baBackTop.scss'],
  template: `
    <div #baBackTop class="back-top ba-back-top">
      <fa-icon [icon]="faAngleUp" 
             [fixedWidth]="true"
             size="lg"
             matTooltip="Back to Top"
             matTooltipClass="mat-tooltip-custom"></fa-icon>
    </div>
  `,
} )
export class BaBackTopComponent implements AfterViewInit {

  faAngleUp = faAngleUp;

  @Input() position: number = 400;
  @Input() showSpeed: number = 500;
  @Input() moveSpeed: number = 1000;

  @ViewChild( 'baBackTop', { static: false } ) _selector: ElementRef;

  ngAfterViewInit() {
    this._onWindowScroll();
  }

  @HostListener( 'click' )
  _onClick(): boolean {
    jQuery( 'html, body' ).animate( { scrollTop: 0 }, { duration: this.moveSpeed } );
    return false;
  }

  @HostListener( 'window:scroll' )
  _onWindowScroll(): void {
    const el = this._selector.nativeElement;
    window.scrollY > this.position ? jQuery( el ).fadeIn( this.showSpeed ) : jQuery( el ).fadeOut( this.showSpeed );
  }
}
