import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../../utils/mobileUtil.service';
import { Auth } from '../../../../../auth.service';
import { UsersUtil } from '../../../../../utils/users.util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Util } from '../../../../../utils/util.service';
import { environment } from '../../../../../../environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { PAGE_NAMES } from '../../../../../utils/enums';

@Component( {
  selector: 'rip-app-settings',
  template: `
    <mat-card class="settings-card" [ngClass]="{ 'mobile-class': deviceIsMobile }" [nbSpinner]="spinning">
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <h5 class="app-settings-title">
              Application Settings
            </h5>
            <div class="row"><br></div>
            <form>
              <div class="form-horizontal setting-item">
                <mat-label>Collapse Sidebar On Load</mat-label>
                <mat-checkbox [formControl]="collapseSideBarOnLoadControl"
                              (change)="updatePrefs()"></mat-checkbox>
              </div>
              <div class="form-horizontal flexing-row-only odd-setting-item center-vertically setting-item">
                <mat-label style="margin-right: 10px; flex: 1;">Choose Default Landing Page</mat-label>
                <mat-form-field style="width: 100%; flex: 2;">
                  <mat-select [(value)]="landingPage"
                              (valueChange)="checkNewLandingPageValue()">
                    <mat-option value="market-information-dashboard">{{ PAGE_NAMES.MARKET_INFORMATION_DASHBOARD }}
                    </mat-option>
                    <mat-option value="balanceSheet">
                      {{ PAGE_NAMES.BALANCE_SHEET }}
                    </mat-option>
                    <mat-option value="accounts">
                      {{ PAGE_NAMES.ACCOUNTS }}
                    </mat-option>
                    <mat-option value="holdings">
                      {{ PAGE_NAMES.HOLDINGS }}
                    </mat-option>
                    <mat-option value="transactions">
                      {{ PAGE_NAMES.TRANSACTIONS }}
                    </mat-option>
                    <mat-option value="benchmark">
                      {{ PAGE_NAMES.PLAN_BENCHMARK }}
                    </mat-option>
                    <mat-option value="goals-dashboard">
                      {{ PAGE_NAMES.GOALS }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>

          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="updatePrefs()" [disabled]="!valueHasChanged">Save
        </button>
      </mat-card-actions>
    </mat-card>
  `,
  styleUrls: [ './app-settings.component.scss' ],
} )

export class AppSettingsComponent {

  PAGE_NAMES = PAGE_NAMES;

  deviceIsMobile: boolean = false;
  collapseSideBarOnLoadControl: UntypedFormControl = new UntypedFormControl( '' );
  landingPage: string = 'market-information-dashboard'; // default
  currentLandingPage: string = 'market-information-dashboard'; // default
  spinning: boolean = false;
  valueHasChanged: boolean = false;

  constructor( private _detectorService: DeviceDetectorService,
               private _auth: Auth,
               private _usersUtil: UsersUtil,
               public snackBar: MatSnackBar ) {
    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    const userPrefs = UsersUtil.checkPreferences( this._auth, 'appSettings' );
    this.collapseSideBarOnLoadControl.setValue( userPrefs?.appSettings?.collapseSideBarOnLoad );
    this.currentLandingPage = this.landingPage = userPrefs?.appSettings?.landingPage ?? this.currentLandingPage;

    if ( environment.env !== 'prod' ) {
      window['ripsaw_appSettings'] = this;
    }
  }

  updatePrefs() {
    this.spinning = true;
    const userPrefs = UsersUtil.checkPreferences( this._auth, 'appSettings' );
    this.setNewPrefsValues( userPrefs );

    this._usersUtil.savePreferences( userPrefs, ( err ) => {
      if ( err ) {
        console.error( err );
        this.snackBar.open( `Error saving user preferences. ${ Util.getRefCodeSupportString( err.refCode ) }`,
          'dismiss',
          Util.getSnackBarOptions( true ) );
      } else {
        this.currentLandingPage = this.landingPage;
        this.snackBar.open( `User preferences saved `, null, Util.getSnackBarOptions() );
      }
      this.spinning = false;
    } );
  }

  setNewPrefsValues( userPrefs: any ) {
    userPrefs.appSettings.collapseSideBarOnLoad = this.collapseSideBarOnLoadControl.value;
    userPrefs.appSettings.landingPage = this.landingPage;
  }

  checkNewLandingPageValue() {
    this.valueHasChanged = this.landingPage !== this.currentLandingPage;
  }

}
