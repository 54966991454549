import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';


@Injectable()
export class TransactionsService {

  private readonly baseApiPath: string;
  private readonly lambdaAggregatorUrl: string;

  constructor( private _http: HttpClient, private _state: GlobalState ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaAggregatorUrl = `${ this.baseApiPath }/workspace-aggregator`;
  }

  urlBuilder( url, params ) {
    if ( !params ) {
      return;
    }
    if ( !url.includes( '?' ) ) {
      url = `${ url }?`;
    }

    for ( const key of Object.keys( params ) ) {
      url = `${ url }&${ key }=${ params[key] }`;
    }

    return url;
  }

  getTransactions( params: any ) {
    let url = `${ this.lambdaAggregatorUrl }/transactions`;
    url = this.urlBuilder(url, params);

    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getTransactionCount( params: any ) {
    let url = `${ this.lambdaAggregatorUrl }/transactions/count`;
    url = this.urlBuilder(url, params);

    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
