import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnDestroy,
  Output, ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { NbStepperComponent } from '@nebular/theme';

@Component( {
  selector: 'rip-carousel',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './carousel.component.html',
  styleUrls: [ './carousel.component.scss' ],
} )
export class CarouselComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'stepper' ) stepper: NbStepperComponent;

  @Input() slides;
  @Input() nextLabel: string = 'Next';
  @Input() prevLabel: string = 'Back';
  @Input() selectedIcon: string = 'radio_button_checked';
  @Input() unselectedIcon: string = 'radio_button_unchecked';
  @Input() showHeaderControls = true;
  @Input() showFooterControls = false;
  @Input() showSlideTitle: boolean = true;
  @Output() slideChanged = new EventEmitter<any>();

  showBackButton: boolean = false; // this one starts as false cause one start, showing the first slide
  showNextButton: boolean = true;

  viewLoaded: boolean = false;
  viewDestroyed: boolean = false;

  constructor() {
    if ( environment.env !== 'prod' ) {
      window['carousel'] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.viewLoaded = true;
    } );
  }

  ngOnDestroy() {
    this.viewDestroyed = true;
  }

  slideChange() {
    this.slideChanged.emit( this.stepper.selectedIndex );
    this.setButtonFlags();
  }

  goToIndex( index ) {
    if ( this.viewLoaded ) {
      if ( index < 0 || !index ) {
        index = 0;
      }
      if ( index >= this.slides.length ) {
        index = this.slides.length - 1;
      }
      this.navigateToIndex( index );
      // this.stepper.selectedIndex = index;
      setTimeout( () => {
        this.slideChange();
      } );
    } else {
      if ( !this.viewDestroyed ) {
        setTimeout( () => {
          this.goToIndex( index );
        }, 500 );
      }
    }
  }

  navigateToIndex( index: number ) {
    if ( index > this.stepper.selectedIndex ) {
      for ( let i = this.stepper.selectedIndex; i < index; i++ ) {
        this.stepper.next();
      }
    } else {
      for ( let i = this.stepper.selectedIndex; i > index; i-- ) {
        this.stepper.previous();
      }
    }
  }

  next() {
    this.stepper.next();
    this.slideChange();
  }

  previous() {
    this.stepper.previous();
    this.slideChange();
  }

  setButtonFlags() {
    this.showBackButton = this.stepper?.selectedIndex !== 0;
    this.showNextButton = this.stepper?.selectedIndex < this.slides.length - 1;
  }

}
