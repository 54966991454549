<!-- Headers-->
<div class="form-group" *ngIf="type === 'Private Lending'">
    <div class="flexing">
        <fa-icon [icon]="faHandHoldingUsd" size="3x" class="manual-account-icon" style="margin-right: 5px;"></fa-icon>
        <h3 class="manual-account-form-H3" style="padding-bottom: 15px;">Private Lending</h3>

    </div>
    This account type can be used for fully amortizing private lending (you lent money to someone else, thus it should
    be as an asset)
</div>


<div class="form-group">
    <div>
        Fields with an * are required
    </div>
</div>
<div *ngIf="form">
    <form [formGroup]="form" #formElement>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="name"
                           [errorStateMatcher]="matcher"
                           name="name"
                           placeholder="My Loan">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required')">
                    Name is required
                </mat-error>
                <mat-label>Account Name / Description*</mat-label>
            </mat-form-field>
        </div>
        <!-- LOAN TERMS SECTION -->
        <h3 class="manual-account-form-H3">{{ type }} Terms</h3>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="loan_origination_date"
                           [errorStateMatcher]="matcher"
                           name="loan_origination_date"
                           placeholder="Loan Origination Date (MM/DD/YYYY)*"
                           [matDatepicker]="origDatepicker"
                           (change)="originationDateChanged()">
                </label>
                <mat-datepicker-toggle matSuffix [for]="origDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #origDatepicker (closed)="originationDateChanged()"></mat-datepicker>
                <mat-error *ngIf="form.controls.loan_origination_date.hasError('invalidDate')">
                    Date must be in 'MM/DD/YYYY' format
                </mat-error>
                <mat-error *ngIf="form.controls.loan_origination_date.hasError('required')">
                    Origination Date is required
                </mat-error>
                <mat-label>Loan Origination Date (MM/DD/YYYY)*</mat-label>
            </mat-form-field>
        </div>

        <div [ngClass]="{'horizontal-form-group': !deviceIsMobile, 'form-group': deviceIsMobile }">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <label>
                        <input matInput
                               formControlName="loan_term"
                               [errorStateMatcher]="matcher"
                               name="loan_term"
                               placeholder="Original Loan Term*"
                               (change)="termChanged()">
                    </label>
                    <mat-error *ngIf="form.controls.loan_term.hasError('isFloatGreaterThanZeroValidator')">
                        Original Loan Term must be greater than zero
                    </mat-error>
                    <mat-error *ngIf="form.controls.loan_term.hasError('required')">
                        Loan Term is required
                    </mat-error>
                    <mat-label>Original Loan Term*</mat-label>
                </mat-form-field>
            </div>
            <div>
                <span> In </span>
                <mat-button-toggle-group #selectedLoanTermUnits="matButtonToggleGroup"
                                         (change)="termUnitChanged()">
                    <mat-button-toggle value="months" class="term-toggle">Months</mat-button-toggle>
                    <mat-button-toggle value="years" class="term-toggle">Years</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="maturity_date"
                           [errorStateMatcher]="matcher"
                           name="maturity_date"
                           placeholder="Maturity Date (MM/DD/YYYY)*"
                           [matDatepicker]="maturityDatepicker"
                           (change)="maturityDateChanged()">
                </label>
                <mat-datepicker-toggle matSuffix [for]="maturityDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #maturityDatepicker (closed)="maturityDateChanged()"></mat-datepicker>
                <mat-error *ngIf="form.controls.maturity_date.hasError('invalidDate')">
                    Date must be in 'MM/DD/YYYY' format
                </mat-error>
                <mat-error *ngIf="form.controls.maturity_date.hasError('isFutureDateValidator')">
                    Maturity Date must be greater than today's date
                </mat-error>
                <mat-error *ngIf="form.controls.maturity_date.hasError('required')">
                    Maturity Date is required
                </mat-error>
                <mat-label>Maturity Date (MM/DD/YYYY)*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="original_loan_amount"
                           [errorStateMatcher]="matcher"
                           name="original_loan_amount"
                           placeholder="Original Principal Balance ($)*"
                           (change)="originalLoanAmountChanged()">
                </label>
                <mat-error *ngIf="form.controls.original_loan_amount.hasError('required')">
                    Original Loan Amount is required
                </mat-error>
                <mat-label>Original Principal Balance ($)*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="coupon"
                           [errorStateMatcher]="matcher"
                           name="coupon"
                           placeholder="Loan Rate (Annual %)*"
                           (change)="couponChanged()">
                </label>
                <mat-error *ngIf="form.controls.coupon.hasError('isValidPctRangeValidator')">
                    Coupon must be between 0 and 30 percent
                </mat-error>
                <mat-error *ngIf="form.controls.coupon.hasError('required')">
                    Loan Rate is required
                </mat-error>
                <mat-label>Loan Rate (Annual %)*</mat-label>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Payment Frequency*"
                            formControlName="coupon_frequency"
                            [errorStateMatcher]="matcher"
                            (selectionChange)="calculatePriceAndDuration()">
                  <mat-option *ngFor="let option of bondCouponFrequencySelectOptions"
                              value="{{option.value}}">{{option.label}}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls.coupon_frequency.hasError('required')">
                    Payment Frequency is required
                </mat-error>
            </mat-form-field>
        </div>

        <!-- LOAN VALUATION SECTION -->
        <h3 class="manual-account-form-H3">{{ type }} Valuation</h3>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="outstanding_balance"
                           [errorStateMatcher]="matcher"
                           name="outstanding_balance"
                           placeholder="Remaining Principal Balance ($)*"
                           [disabled]="true">
                    <!--                 (change)="remainingLoanBalanceChanged()">-->
                </label>
                <mat-error *ngIf="form.controls.outstanding_balance.hasError('required')">
                    Remaining Principal Balance is required
                </mat-error>
                <mat-label>Remaining Principal Balance ($)*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group" *ngIf="paydownAmount > 0">
            <!-- TODO: change this to always be there and be phrased as a question: Any extra principal payments?
                 - need a table for these with date and amount collected, then calc remaining periods (based on payment) and the current total paydown amount
                 - could do missed payments in the future

             -->
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="loan_principal_paydown"
                           [errorStateMatcher]="matcher"
                           name="loan_principal_paydown"
                           placeholder="Principal Paydown (implied from overwritten Remaining Balance) ($)"
                           (change)="paydownChanged()">
                </label>
                <mat-label>Principal Paydown</mat-label>
            </mat-form-field>
        </div>

        <!--<div *ngIf="isMortgage() && !addingInRevision">-->
        <div>
            <rip-zillow-rate-picker (rateChosen)="chooseRate($event)"></rip-zillow-rate-picker>
        </div>
        <!-- current market auto/mortgage loan rate (retrieved from data service) -->
        <!-- <div class="form-group flex-form-field" *ngIf="!addingInRevision">-->
        <div class="form-group flex-form-field">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="current_market_rate"
                           [errorStateMatcher]="matcher"
                           name="current_market_rate"
                           placeholder="Input Today's Rate (Annual %)*"
                           matTooltip="A mortgage rate from Zillow for the remaining loan life is used to estimate current value. The user can replace this rate with one that is more fully representative (i.e., use a higher rate for more default risk and amount)."
                           matTooltipClass="mat-tooltip-custom"
                           (change)="currentRateChanged()">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required')">
                    Today's Rate is required
                </mat-error>
                <mat-label>Today's Rate (Annual %)</mat-label>
            </mat-form-field>
        </div>
        <!-- treasury rate (retrieved from data service) -->
        <!--  <div class="form-group">
            <mat-form-field>
              <input matInput
                     formControlName="treasury_rate"
                     [errorStateMatcher]="matcher"
                     name="treasury_rate"
                     placeholder="Treasury Rate (Annual %)">
            </mat-form-field>
          </div>-->

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="price"
                           [errorStateMatcher]="matcher"
                           name="price"
                           placeholder="{{ type }} Current Value ($)*"
                           (change)="priceChanged()">
                </label>
                <mat-error *ngIf="form.controls.price.hasError('required')">
                    {{ type }} Current Value is required
                </mat-error>
                <mat-label>Current Value ($)*</mat-label>
            </mat-form-field>
        </div>

        <!-- modified duration -->
        <!--  <div class="form-group">
            <mat-form-field>
              <input matInput
                     formControlName="modified_duration"
                     [errorStateMatcher]="matcher"
                     name="modified_duration"
                     placeholder="Duration in Years">
            </mat-form-field>
          </div>-->

        <!-- maturity in years -->
        <!--  <div class="form-group">
            <mat-form-field>
              <input matInput
                     formControlName="maturity_in_years"
                     [errorStateMatcher]="matcher"
                     name="maturity_in_years"
                     placeholder="Weighted Average Time to Maturity in Years">
            </mat-form-field>
          </div>-->

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="quantity"
                           [errorStateMatcher]="matcher"
                           name="quantity"
                           placeholder="Quantity*"
                           (change)="quantityChanged()">
                </label>
                <mat-error *ngIf="form.controls.quantity.hasError('required')">
                    Quantity is required
                </mat-error>
                <mat-label>Quantity*</mat-label>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="value"
                           [errorStateMatcher]="matcher"
                           placeholder="Market Value ($)*">
                </label>
                <mat-error *ngIf="form.controls.value.hasError('required')">
                    Market Value is required
                </mat-error>
                <mat-label>Market Value ($)*</mat-label>
            </mat-form-field>
        </div>

        <!-- Corresponding asset -->
        <div class="form-group flexing" *ngIf="type !== 'Private Lending' && !hideCorrespondingAsset">
            <mat-form-field appearance="outline">
                <!--  need an info tool tip for this -->
                <mat-select placeholder="Corresponding Asset"
                            panelClass="max-select-box-height"
                            formControlName="corresponding_asset_id"
                            [errorStateMatcher]="matcher">
                    <mat-option *ngIf="allAssetAccounts.length > 0"
                                [value]="null">{{hasCorrespondingAsset ? 'Remove Account' : 'Choose Account'}}</mat-option>
                    <mat-option *ngIf="allAssetAccounts.length === 0">No assets to choose from yet</mat-option>
                    <mat-option *ngFor="let acct of allAssetAccounts" [value]="acct.account_id">
                        {{ acct.formattedDescription }}
                    </mat-option>
                </mat-select>
                <mat-label>Corresponding Asset</mat-label>
            </mat-form-field>
            <fa-icon [icon]="faInfoCircle"
                     class="form-info"
                     [fixedWidth]="true"
                     size="sm"
                     [nbPopover]="correspondingAssetPopContent"
                     nbPopoverClass="rip-popover">
            </fa-icon>
        </div>

        <rip-bond-select-boxes [matcher]="matcher" [form]="form">
        </rip-bond-select-boxes>
    </form>
</div>

<!--corresponding asset popover-->
<ng-template #correspondingAssetPopContent>
    <nb-card class="popover-card">
        <nb-card-header class="popover-title">
            Corresponding Asset
        </nb-card-header>
        <nb-card-body>
            <div class="popover-content">
                Selecting an associated real asset will link your loan to this asset. When switching from Net Worth to
                Investment mode both will be removed. If you choose not to include an associated asset, then this loan
                (short bond position) will remain in your bond portfolio in both modes.
            </div>
        </nb-card-body>
    </nb-card>

</ng-template>
