import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../utils/util.service';
import { environment } from '../../environments/environment';
import { Auth } from '../auth.service';

@Injectable()
export class NotificationsService {

  // API path
  readonly lambdaAppInfoUrl: string;
  readonly lambdaUsersUrl: string;
  readonly lambdaAdminUrl: string;
  readonly baseApiPath: string;

  constructor( private _http: HttpClient ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaAppInfoUrl = `${ this.baseApiPath }/appInfo`;
    this.lambdaUsersUrl = `${ this.baseApiPath }/users`;
    this.lambdaAdminUrl = `${ this.baseApiPath }/admin`;
  }

  getGlobalNotifications() {
    const url = `${ this.lambdaAppInfoUrl }/notifications`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( NotificationsService.extractData ),
        catchError( Util.handleError ),
      );
  }

  getUserNotifications() {
    const url = `${ this.lambdaUsersUrl }/notifications`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( NotificationsService.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateUserNotifications( notifications ) {
    // console.log( 'updating user:' );
    // console.log( user );
    const url = `${ this.lambdaUsersUrl }/notifications`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ Auth.getToken() }`,
    } );
    return this._http.put( url, notifications, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }


  alertAdmins( alert ) {
    const url = `${ this.lambdaAdminUrl }/alert`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, alert, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  static extractData( res: HttpResponse<any> ) {
    const body: any = res;
    // console.log(body);
    if ( body && body.data ) {
      return typeof body.data === 'string' ? JSON.parse( body.data ) : body.data;
    }
    return body || {};
  }

}
