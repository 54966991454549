import { Injectable } from '@angular/core';

@Injectable()

export class AnimateUtil {

  /**
   * this function is for starting animations with a limited duration. should not be used for infinite duration animations
   * @param elementSelector [string] - css selector of the html element to be animated
   * @param animation [string] - animation name
   * @param options [any] - object with options for the animation
   *       currently supported options are:
   *         @option extraClasses [string[]] - array of strings to be added as class to the element (can be used for animate built in functionality)
   *         @option duration - number of seconds for the animation to last/take. effectively a speed for the animation
   */
  static startAnimation( elementSelector: string, animation: string, options?: any ) {
    const prefix = 'animate__';
    const duration = options?.duration || 1;
    return new Promise( ( resolve, reject ) => {
      const animationName = `${ prefix }${ animation }`;
      const node: HTMLElement = document.querySelector( elementSelector );

      const extraClasses = [];
      if ( options?.extraClasses ) {
        extraClasses.push( ...options.extraClasses );
      }

      node.classList.add( `${ prefix }animated`, animationName, ...extraClasses );
      node.style.setProperty( '--animation-duration', `${ duration }s` );

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd( event ) {
        event.stopPropagation();
        node.classList.remove( `${ prefix }animated`, animationName, ...extraClasses );
        node.style.removeProperty('--animation-duration');
        resolve( 'Animation ended' );
      }

      node.addEventListener( 'animationend', handleAnimationEnd, { once: true } );
    } );
  }
}
