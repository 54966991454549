import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  template: `
    <h3 mat-dialog-title *ngIf="dialogRef?.id">
      Daily Performance
    </h3>
    <mat-divider class="modal-header-divider" *ngIf="dialogRef?.id"></mat-divider>
    <div mat-dialog-content>
      <rip-one-day-change-breakdown></rip-one-day-change-breakdown>
    </div>

    <mat-divider class="modal-footer-divider" *ngIf="dialogRef?.id"></mat-divider>
    <div mat-dialog-actions *ngIf="dialogRef?.id">
      <button type="button" mat-button color="primary" (click)="dialogRef.close()">
        Close
      </button>
    </div>
  `,
})

export class OneDayBreakdownDialogComponent {

  constructor( public dialogRef: MatDialogRef<OneDayBreakdownDialogComponent> ) {

  }
}
