import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IncomeStatementComponent } from './components/income-statement/income-statement.component';
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component';
import { ActivatedRoute, Params } from '@angular/router';
import { TransactionsState } from '../../utils/transactions.state';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component( {
  selector: 'rip-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: [ './transactions.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class TransactionsComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @ViewChild( 'transactionsTableComponent', { static: false } ) transactionsTableComponent: TransactionsTableComponent;
  @ViewChild( 'incomeStatementComponent', { static: false } ) incomeStatementComponent: IncomeStatementComponent;

  transactionsMarginTop: number = 5;
  subscriberName: string = 'transactionsComponent';

  deviceIsMobile: boolean;

  appName: string = environment.appName;

  constructor( private route: ActivatedRoute,
               public transactionsState: TransactionsState,
               private deviceDetector: DeviceDetectorService ) {

    if ( environment.env !== 'prod' ) {
      window['ripsaw_transactionsPage'] = this;
    }

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this.deviceDetector );

  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.checkThatDataIsLoaded();

    } );
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  checkThatDataIsLoaded() {
    // check if data is loaded and children components are available
    if ( this.transactionsState.transactionsHaveBeenLoadedAtLeastOnce && this.incomeStatementComponent ) {
      this.transactionsState.processTransactions();
      this.transactionsTableComponent.processTransactions();
      this.route.queryParams
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( ( params: Params ) => {
          if ( params.account_id ) {
            this.transactionsTableComponent.selectedAccount = params.account_id;
            this.transactionsTableComponent.filter();
            setTimeout( () => {
              this.transactionsTableComponent.scrollToTable();
            }, 1000 );
            window.location.hash = window.location.hash.split( '?' )[0]; // remove query params
          }
        } );
    } else {
      // if ( !this.errorLoadingTransactions ) { // don't keep checking if there is an error
      setTimeout( () => {
        this.checkThatDataIsLoaded();
      }, 500 );
      // }
    }
  }

  filterTableByCategory( category: any ) {
    this.transactionsTableComponent.filter( category );
    this.transactionsTableComponent.scrollToTable();
  }

}
