import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

declare const TradingViewEmbed: any;

@Component( {
  selector: 'rip-trading-view-single-ticker',
  template: `
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container" #containerDiv><!-- style="height: 300px; width: 200px">-->
      <div class="tradingview-widget-container__widget"></div>

    </div>
    <div class="tradingview-widget-copyright">
      <a (click)="goToTradingView()" class="rip-link">
        <span class="blue-text">{{ this.ticker }} Data</span></a>
      by TradingView
    </div>
    <!-- TradingView Widget END -->
  `,
} )

export class TradingViewSingleTickerComponent implements AfterViewInit {

  @Input() ticker: string = 'AAPL';
  @Input() exchange: string = 'US';
  settings: any = {};

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  scriptElement;

  constructor( private _elRef: ElementRef ) {
  }

  gotToTradingView() {
    Util.openExternalUrl( `https://www.tradingview.com/symbols/${ this.ticker }/` );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.setupWidget();
    } );

  }

  setupWidget() {
    // let symbol = `${this.exchange ? `${ this.exchange }:` : ''}${this.ticker}`;

    const symbol = `${ this.ticker.split( '-' ).join( '' ) }`;
    this.settings = {
      symbol,
      colorTheme: GlobalState.tradingViewColorTheme,
      isTransparent: false,
      largeChartUrl: '',
      displayMode: 'regular',
      // width: '100%',
      // height: '100%',
      autosize: true,
      locale: 'en',
    };

    const script = document.createElement( 'script' );
    this.scriptElement = script;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
    script.async = true;
    this.scriptElement.innerHTML = JSON.stringify( this.settings );

    this.containerDiv.nativeElement.appendChild( script );
/*    const brandingDiv = document.createElement( 'div' );
    brandingDiv.innerHTML = `

`;
    this.containerDiv.nativeElement.appendChild( brandingDiv );*/
  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }

}
