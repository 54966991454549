import { Injectable } from '@angular/core';
import { Logger } from '../../../utils/logger.service';

@Injectable()
export class RipThemeLoadingSpinnerService {

  private _selector: string = 'preloader';

  private defaultOptions = {
    overrides: {
      background: 'rgba(27, 27, 30, 0.63)',
    },
  };

  constructor() {

  }

  /**
   * @selector: optional selector to use to find the location of the spinner to be shown
   * @options: optional object for display options of the spinner
   * */
  show( selector?: string, options?: any ): void {
    if ( !options ) {
      options = this.defaultOptions;
    } else {
      options.overrides = Object.assign( {}, this.defaultOptions.overrides, options.overrides );
      options = Object.assign( {}, this.defaultOptions, options );
    }

    const element: HTMLElement = document.getElementById( selector || this._selector );
    // only try and show the spinner element is actually found and not already in block display
    if ( element ) {
      element.style['z-index'] = '10000';
      element.style['display'] = 'block';

      for ( const key of Object.keys( options.overrides ) ) {
        element.style[key] = options.overrides[key];
      }


      const firstChild: any = element.children[0];

      if ( options.size ) {
        element.style['width'] = `${ options.size }px`;
        element.style['height'] = `${ options.size }px`;
        firstChild.style['width'] = `${ options.size }px`;
        firstChild.style['height'] = `${ options.size }px`;
      }

      if ( options.class ) {
        firstChild.classList.add( options.class );
      }

      if ( options.repositionSpinner ) {
        firstChild.style['left'] = options.repositionSpinner.left;
        firstChild.style['top'] = options.repositionSpinner.top;
      }
    }
  }

  /**
   * @delay: time to delay before hiding the spinner in seconds
   * @selector: optional selector to use to find the location of the spinner to be hidden
   * */
  hide( delay: number = 0, selector?: string ): void {
    setTimeout( () => {
      const element: HTMLElement = document.getElementById( selector || this._selector );
      if ( element ) {
        element.style['display'] = 'none';
        element.style['z-index'] = '0';
      } else {
        Logger.warn( `No spinner element for selector: ${ selector }` );
      }
    }, delay * 1000 );
  }
}
