<div class="carousel-container">
  <div class="slide-container" *ngIf="slides && slides.length > 0">
    <div class="header" *ngIf="showHeaderControls">
      <ng-container *ngTemplateOutlet="controls"></ng-container>
    </div>
    <div class="row carousel-content">
      <nb-stepper #stepper>
        <nb-step *ngFor="let slide of slides">
          <div class="slide-title" *ngIf="showSlideTitle">{{ slide?.title }}</div>
          <ng-container *ngTemplateOutlet="slide?.template; context: slide?.context"></ng-container>
        </nb-step>
      </nb-stepper>

    </div>
    <div class="footer" *ngIf="showFooterControls">
      <ng-container *ngTemplateOutlet="controls"></ng-container>
    </div>
  </div>
</div>

<ng-template #controls>
  <mat-grid-list [cols]="9" rowHeight="40px">
    <mat-grid-tile [colspan]="3">
      <button mat-raised-button (click)="previous()" *ngIf="showBackButton"
              class="carousel-nav-label" title="{{prevLabel}}">
        <mat-icon>chevron_left</mat-icon>
        {{ prevLabel || '' | ripTruncatePipe : 13 }}
      </button>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="3">
      <mat-icon *ngFor="let slide of slides; let i = index" (click)="goToIndex(i)"
                class="carousel-dot" matTooltip="{{slide.title}}" matTooltipClass="mat-tooltip-custom">
        {{ stepper?.selectedIndex === i ? selectedIcon : unselectedIcon }}
      </mat-icon>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="3">
      <button mat-raised-button (click)="next()" class="pull-right carousel-nav-label"
              *ngIf="showNextButton" title="{{nextLabel}}">
        {{ nextLabel || '' | ripTruncatePipe : 13 }}
        <mat-icon>chevron_right</mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>
