import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Util } from '../../utils/util.service';
import { GlobalState } from '../../global.state';

declare const TradingViewEmbed: any;

@Component( {
  selector: 'rip-trading-view-ticker-tape',
  template: `
    <!--      <div id="fundamentals_{{ticker}}" style="height: 300px;"></div>-->
    <!-- TradingView Widget BEGIN -->
    <div class="tradingview-widget-container" style="/*height: 200px; width: 200px*/" #containerDiv>
      <div class="tradingview-widget-container__widget"></div>

    </div>
    <div class="tradingview-widget-copyright">

      <!--        <span class="blue-text">{{ brandingLabel }}</span>-->
      by <a (click)="goToTradingView()" class="rip-link"> TradingView </a>
    </div>
    <!-- TradingView Widget END -->
  `,
} )

export class TradingViewTickerTapeComponent implements AfterViewInit {

  fundamentals: any;
  @Input() symbols: any = [];
  settings: any = {};

  @ViewChild( 'containerDiv', { static: false } ) containerDiv: ElementRef;

  constructor( private _elRef: ElementRef ) {
  }

  gotToTradingView() {
    Util.openExternalUrl( `https://www.tradingview.com/` );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      const symbols = this.symbols;

      this.settings = {
        colorTheme: GlobalState.tradingViewColorTheme,
        symbols,
        isTransparent: false,
        largeChartUrl: '',
        displayMode: 'regular',
        width: '100%',
        height: '100%',
        autosize: true,
        locale: 'en',
        showSymbolLogo: true,
      };
      const script = document.createElement( 'script' );
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.innerHTML = JSON.stringify( this.settings );
      this.containerDiv.nativeElement.appendChild( script );
    } );
  }

  goToTradingView() {
    const url = `https://www.tradingview.com/`;
    Util.openExternalUrl( url );
  }

}
