import { AbstractControl } from '@angular/forms';

export class EmailValidator {

  static validate( c: AbstractControl ) {
    const EMAIL_REGEXP = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)' +
    '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

    return EMAIL_REGEXP.test( c.value ) ? null : {
      validateEmail: {
        valid: false,
      },
    };
  }
}
