/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addUserToWorkspace } from '../fn/workspaces/add-user-to-workspace';
import { AddUserToWorkspace$Params } from '../fn/workspaces/add-user-to-workspace';
import { AddUserToWorkspaceResponse } from '../models/add-user-to-workspace-response';
import { copyWorkspaceFromExisting } from '../fn/workspaces/copy-workspace-from-existing';
import { CopyWorkspaceFromExisting$Params } from '../fn/workspaces/copy-workspace-from-existing';
import { CopyWorkspaceFromExistingResponse } from '../models/copy-workspace-from-existing-response';
import { createEmptyWorkspaceFromExisting } from '../fn/workspaces/create-empty-workspace-from-existing';
import { CreateEmptyWorkspaceFromExisting$Params } from '../fn/workspaces/create-empty-workspace-from-existing';
import { createWorkspace } from '../fn/workspaces/create-workspace';
import { CreateWorkspace$Params } from '../fn/workspaces/create-workspace';
import { CreateWorkspaceResponse } from '../models/create-workspace-response';
import { deleteWorkspace } from '../fn/workspaces/delete-workspace';
import { DeleteWorkspace$Params } from '../fn/workspaces/delete-workspace';
import { DeleteWorkspaceResponse } from '../models/delete-workspace-response';
import { duplicateEmptyWorkspace } from '../fn/workspaces/duplicate-empty-workspace';
import { DuplicateEmptyWorkspace$Params } from '../fn/workspaces/duplicate-empty-workspace';
import { duplicateWorkspace } from '../fn/workspaces/duplicate-workspace';
import { DuplicateWorkspace$Params } from '../fn/workspaces/duplicate-workspace';
import { DuplicateWorkspaceResponse } from '../models/duplicate-workspace-response';
import { getSharedWorkspacePermissions } from '../fn/workspaces/get-shared-workspace-permissions';
import { GetSharedWorkspacePermissions$Params } from '../fn/workspaces/get-shared-workspace-permissions';
import { GetSharedWorkspacePermissionsResponse } from '../models/get-shared-workspace-permissions-response';
import { getSharedWorkspaces } from '../fn/workspaces/get-shared-workspaces';
import { GetSharedWorkspaces$Params } from '../fn/workspaces/get-shared-workspaces';
import { GetSharedWorkspacesResponse } from '../models/get-shared-workspaces-response';
import { getWorkspaceIsCreated } from '../fn/workspaces/get-workspace-is-created';
import { GetWorkspaceIsCreated$Params } from '../fn/workspaces/get-workspace-is-created';
import { getWorkspaces } from '../fn/workspaces/get-workspaces';
import { GetWorkspaces$Params } from '../fn/workspaces/get-workspaces';
import { GetWorkspacesIsCreatedResponse } from '../models/get-workspaces-is-created-response';
import { GetWorkspacesResponse } from '../models/get-workspaces-response';
import { getWorkspaceUserPermissions } from '../fn/workspaces/get-workspace-user-permissions';
import { GetWorkspaceUserPermissions$Params } from '../fn/workspaces/get-workspace-user-permissions';
import { GetWorkspaceUserPermissionsResponse } from '../models/get-workspace-user-permissions-response';
import { removeSharedWorkspace } from '../fn/workspaces/remove-shared-workspace';
import { RemoveSharedWorkspace$Params } from '../fn/workspaces/remove-shared-workspace';
import { RemoveSharedWorkspaceResponse } from '../models/remove-shared-workspace-response';
import { removeUserFromWorkspace } from '../fn/workspaces/remove-user-from-workspace';
import { RemoveUserFromWorkspace$Params } from '../fn/workspaces/remove-user-from-workspace';
import { RemoveUserFromWorkspaceResponse } from '../models/remove-user-from-workspace-response';
import { updateSharedWorkspace } from '../fn/workspaces/update-shared-workspace';
import { UpdateSharedWorkspace$Params } from '../fn/workspaces/update-shared-workspace';
import { UpdateSharedWorkspaceResponse } from '../models/update-shared-workspace-response';
import { updateWorkspace } from '../fn/workspaces/update-workspace';
import { UpdateWorkspace$Params } from '../fn/workspaces/update-workspace';
import { UpdateWorkspaceResponse } from '../models/update-workspace-response';
import { updateWorkspaceUserPermissions } from '../fn/workspaces/update-workspace-user-permissions';
import { UpdateWorkspaceUserPermissions$Params } from '../fn/workspaces/update-workspace-user-permissions';
import { UpdateWorkspaceUserPermissionsResponse } from '../models/update-workspace-user-permissions-response';

@Injectable({ providedIn: 'root' })
export class WorkspacesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWorkspaces()` */
  static readonly GetWorkspacesPath = '/workspaces';

  /**
   * Get current user workspaces.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkspaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkspaces$Response(params?: GetWorkspaces$Params, context?: HttpContext): Observable<StrictHttpResponse<GetWorkspacesResponse>> {
    return getWorkspaces(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current user workspaces.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkspaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkspaces(params?: GetWorkspaces$Params, context?: HttpContext): Observable<GetWorkspacesResponse> {
    return this.getWorkspaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetWorkspacesResponse>): GetWorkspacesResponse => r.body)
    );
  }

  /** Path part for operation `createWorkspace()` */
  static readonly CreateWorkspacePath = '/workspaces';

  /**
   * Create a new workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWorkspace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorkspace$Response(params: CreateWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateWorkspaceResponse>> {
    return createWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWorkspace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWorkspace(params: CreateWorkspace$Params, context?: HttpContext): Observable<CreateWorkspaceResponse> {
    return this.createWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateWorkspaceResponse>): CreateWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `getWorkspaceIsCreated()` */
  static readonly GetWorkspaceIsCreatedPath = '/workspaces/{workspaceId}/is-created';

  /**
   * Check if workspace is created.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkspaceIsCreated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkspaceIsCreated$Response(params: GetWorkspaceIsCreated$Params, context?: HttpContext): Observable<StrictHttpResponse<GetWorkspacesIsCreatedResponse>> {
    return getWorkspaceIsCreated(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if workspace is created.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkspaceIsCreated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkspaceIsCreated(params: GetWorkspaceIsCreated$Params, context?: HttpContext): Observable<GetWorkspacesIsCreatedResponse> {
    return this.getWorkspaceIsCreated$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetWorkspacesIsCreatedResponse>): GetWorkspacesIsCreatedResponse => r.body)
    );
  }

  /** Path part for operation `createEmptyWorkspaceFromExisting()` */
  static readonly CreateEmptyWorkspaceFromExistingPath = '/workspaces/{workspaceId}/from-existing/empty';

  /**
   * Create a new empty workspace from existing workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmptyWorkspaceFromExisting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEmptyWorkspaceFromExisting$Response(params: CreateEmptyWorkspaceFromExisting$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateWorkspaceResponse>> {
    return createEmptyWorkspaceFromExisting(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new empty workspace from existing workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createEmptyWorkspaceFromExisting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEmptyWorkspaceFromExisting(params: CreateEmptyWorkspaceFromExisting$Params, context?: HttpContext): Observable<CreateWorkspaceResponse> {
    return this.createEmptyWorkspaceFromExisting$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateWorkspaceResponse>): CreateWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `copyWorkspaceFromExisting()` */
  static readonly CopyWorkspaceFromExistingPath = '/workspaces/{workspaceId}/from-existing/{newWorkspaceId}';

  /**
   * Copy workspace from existing workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyWorkspaceFromExisting()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyWorkspaceFromExisting$Response(params: CopyWorkspaceFromExisting$Params, context?: HttpContext): Observable<StrictHttpResponse<CopyWorkspaceFromExistingResponse>> {
    return copyWorkspaceFromExisting(this.http, this.rootUrl, params, context);
  }

  /**
   * Copy workspace from existing workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyWorkspaceFromExisting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copyWorkspaceFromExisting(params: CopyWorkspaceFromExisting$Params, context?: HttpContext): Observable<CopyWorkspaceFromExistingResponse> {
    return this.copyWorkspaceFromExisting$Response(params, context).pipe(
      map((r: StrictHttpResponse<CopyWorkspaceFromExistingResponse>): CopyWorkspaceFromExistingResponse => r.body)
    );
  }

  /** Path part for operation `duplicateEmptyWorkspace()` */
  static readonly DuplicateEmptyWorkspacePath = '/workspaces/{workspaceId}/duplicate/empty';

  /**
   * Duplicate empty workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateEmptyWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateEmptyWorkspace$Response(params: DuplicateEmptyWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateWorkspaceResponse>> {
    return duplicateEmptyWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Duplicate empty workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateEmptyWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateEmptyWorkspace(params: DuplicateEmptyWorkspace$Params, context?: HttpContext): Observable<CreateWorkspaceResponse> {
    return this.duplicateEmptyWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateWorkspaceResponse>): CreateWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `duplicateWorkspace()` */
  static readonly DuplicateWorkspacePath = '/workspaces/{workspaceId}/duplicate/{newWorkspaceId}';

  /**
   * Duplicate workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateWorkspace$Response(params: DuplicateWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<DuplicateWorkspaceResponse>> {
    return duplicateWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Duplicate workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateWorkspace(params: DuplicateWorkspace$Params, context?: HttpContext): Observable<DuplicateWorkspaceResponse> {
    return this.duplicateWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateWorkspaceResponse>): DuplicateWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `deleteWorkspace()` */
  static readonly DeleteWorkspacePath = '/workspaces/{workspaceId}';

  /**
   * Delete workspace by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWorkspace$Response(params: DeleteWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteWorkspaceResponse>> {
    return deleteWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete workspace by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWorkspace(params: DeleteWorkspace$Params, context?: HttpContext): Observable<DeleteWorkspaceResponse> {
    return this.deleteWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteWorkspaceResponse>): DeleteWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `updateWorkspace()` */
  static readonly UpdateWorkspacePath = '/workspaces/{workspaceId}';

  /**
   * Update a workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkspace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkspace$Response(params: UpdateWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateWorkspaceResponse>> {
    return updateWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkspace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkspace(params: UpdateWorkspace$Params, context?: HttpContext): Observable<UpdateWorkspaceResponse> {
    return this.updateWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateWorkspaceResponse>): UpdateWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `addUserToWorkspace()` */
  static readonly AddUserToWorkspacePath = '/workspaces/{workspaceId}/user/{userId}';

  /**
   * Add user to workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserToWorkspace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserToWorkspace$Response(params: AddUserToWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<AddUserToWorkspaceResponse>> {
    return addUserToWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Add user to workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUserToWorkspace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addUserToWorkspace(params: AddUserToWorkspace$Params, context?: HttpContext): Observable<AddUserToWorkspaceResponse> {
    return this.addUserToWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddUserToWorkspaceResponse>): AddUserToWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `removeUserFromWorkspace()` */
  static readonly RemoveUserFromWorkspacePath = '/workspaces/{workspaceId}/user/{userId}';

  /**
   * Remove user from workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUserFromWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromWorkspace$Response(params: RemoveUserFromWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoveUserFromWorkspaceResponse>> {
    return removeUserFromWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove user from workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeUserFromWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromWorkspace(params: RemoveUserFromWorkspace$Params, context?: HttpContext): Observable<RemoveUserFromWorkspaceResponse> {
    return this.removeUserFromWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveUserFromWorkspaceResponse>): RemoveUserFromWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `getWorkspaceUserPermissions()` */
  static readonly GetWorkspaceUserPermissionsPath = '/workspaces/{workspaceId}/user/{userId}/permissions';

  /**
   * Get workspace user permissions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkspaceUserPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkspaceUserPermissions$Response(params: GetWorkspaceUserPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetWorkspaceUserPermissionsResponse>> {
    return getWorkspaceUserPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get workspace user permissions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkspaceUserPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkspaceUserPermissions(params: GetWorkspaceUserPermissions$Params, context?: HttpContext): Observable<GetWorkspaceUserPermissionsResponse> {
    return this.getWorkspaceUserPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetWorkspaceUserPermissionsResponse>): GetWorkspaceUserPermissionsResponse => r.body)
    );
  }

  /** Path part for operation `updateWorkspaceUserPermissions()` */
  static readonly UpdateWorkspaceUserPermissionsPath = '/workspaces/{workspaceId}/user/{userId}/permissions';

  /**
   * Update workspace user permissions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWorkspaceUserPermissions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkspaceUserPermissions$Response(params: UpdateWorkspaceUserPermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateWorkspaceUserPermissionsResponse>> {
    return updateWorkspaceUserPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Update workspace user permissions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWorkspaceUserPermissions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWorkspaceUserPermissions(params: UpdateWorkspaceUserPermissions$Params, context?: HttpContext): Observable<UpdateWorkspaceUserPermissionsResponse> {
    return this.updateWorkspaceUserPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateWorkspaceUserPermissionsResponse>): UpdateWorkspaceUserPermissionsResponse => r.body)
    );
  }

  /** Path part for operation `getSharedWorkspaces()` */
  static readonly GetSharedWorkspacesPath = '/workspaces/shared';

  /**
   * Get workspaces shared with current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSharedWorkspaces()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedWorkspaces$Response(params?: GetSharedWorkspaces$Params, context?: HttpContext): Observable<StrictHttpResponse<GetSharedWorkspacesResponse>> {
    return getSharedWorkspaces(this.http, this.rootUrl, params, context);
  }

  /**
   * Get workspaces shared with current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSharedWorkspaces$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedWorkspaces(params?: GetSharedWorkspaces$Params, context?: HttpContext): Observable<GetSharedWorkspacesResponse> {
    return this.getSharedWorkspaces$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetSharedWorkspacesResponse>): GetSharedWorkspacesResponse => r.body)
    );
  }

  /** Path part for operation `removeSharedWorkspace()` */
  static readonly RemoveSharedWorkspacePath = '/workspaces/shared/{workspaceId}';

  /**
   * Remove shared workspace by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSharedWorkspace()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSharedWorkspace$Response(params: RemoveSharedWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<RemoveSharedWorkspaceResponse>> {
    return removeSharedWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove shared workspace by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeSharedWorkspace$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSharedWorkspace(params: RemoveSharedWorkspace$Params, context?: HttpContext): Observable<RemoveSharedWorkspaceResponse> {
    return this.removeSharedWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveSharedWorkspaceResponse>): RemoveSharedWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `updateSharedWorkspace()` */
  static readonly UpdateSharedWorkspacePath = '/workspaces/shared/{workspaceId}';

  /**
   * Update a shared workspace.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSharedWorkspace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSharedWorkspace$Response(params: UpdateSharedWorkspace$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateSharedWorkspaceResponse>> {
    return updateSharedWorkspace(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a shared workspace.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSharedWorkspace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSharedWorkspace(params: UpdateSharedWorkspace$Params, context?: HttpContext): Observable<UpdateSharedWorkspaceResponse> {
    return this.updateSharedWorkspace$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateSharedWorkspaceResponse>): UpdateSharedWorkspaceResponse => r.body)
    );
  }

  /** Path part for operation `getSharedWorkspacePermissions()` */
  static readonly GetSharedWorkspacePermissionsPath = '/workspaces/shared/{workspaceId}/permissions';

  /**
   * Get shared workspace permissions for current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSharedWorkspacePermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedWorkspacePermissions$Response(params: GetSharedWorkspacePermissions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetSharedWorkspacePermissionsResponse>> {
    return getSharedWorkspacePermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get shared workspace permissions for current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSharedWorkspacePermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedWorkspacePermissions(params: GetSharedWorkspacePermissions$Params, context?: HttpContext): Observable<GetSharedWorkspacePermissionsResponse> {
    return this.getSharedWorkspacePermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetSharedWorkspacePermissionsResponse>): GetSharedWorkspacePermissionsResponse => r.body)
    );
  }

}
