import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../utils/util.service';
import { Observable } from 'rxjs';

@Injectable()

export class StripeService {

  lambdaStripeUrl = environment.ripsawAPIBaseUrl + '/stripe';

  constructor( private _http: HttpClient ) {
  }

  getAllSubscriptions(): Observable<any> {
    const url: string = `${ this.lambdaStripeUrl }/subscription/all?include_canceled=true`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } ).pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getProducts(): Observable<any> {
    const url: string = `${ this.lambdaStripeUrl }/products`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } ).pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

  getStripeCustomerPortalSession(): Observable<any> {
    const url: string = `${ this.lambdaStripeUrl }/customer_portal_session`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } ).pipe( map( Util.extractData ), catchError( Util.handleError ) );
  }

}