import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { faHouse } from '@fortawesome/pro-light-svg-icons';
import { faCar } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faColumns } from '@fortawesome/pro-light-svg-icons';
import { faTrafficLightStop } from '@fortawesome/pro-light-svg-icons/faTrafficLightStop';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { MobileUtil } from '../../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PricingService } from '../../../../globalData';
import { Subject } from 'rxjs';
import { RipsawDecimalPipe, RipsawPercentPipe } from '../../../../theme/pipes';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketDataUtil } from '../../../../utils/marketData.util';
import { GlobalState } from '../../../../global.state';
import { Router } from '@angular/router';
import { SettingsComponent } from '../../../../pages/modals/settings';
import { MatDialog } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';


@Component( {
  selector: 'rip-widget-menu',
  templateUrl: './widgetMenu.component.html',
  styleUrls: [ './widgetMenu.scss' ],
} )

export class WidgetMenuComponent implements OnDestroy, AfterViewInit {

  private readonly onDestroy = new Subject<void>();

  faHouse = faHouse;
  faCar = faCar;
  faInfoCircle = faInfoCircle;
  faColumns = faColumns;
  faTrafficLightStop = faTrafficLightStop;
  faSlidersH = faSlidersH;

  faAnalytics = faAnalytics;

  @Input() showTable: boolean;
  @Input() showRE: boolean;
  @Input() showDiffs: boolean;
  @Input() global: boolean;
  @Input() minimized: boolean;

  @Output() toggleRE: EventEmitter<any> = new EventEmitter<any>();
  @Output() flipTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleBenchmarkValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() minMaximize: EventEmitter<any> = new EventEmitter<any>();

  eventList = new Array<string>( 'Loading...' );

  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();

  indexDataInterval;


  indexBreakoutRows: any[] = [];

  marketDataInterval: number = 3; // interval in minutes for market data updates

  deviceIsMobile: boolean = false;

  constructor( private _detectorService: DeviceDetectorService,
               private snackBar: MatSnackBar,
               private _pricingService: PricingService,
               private _state: GlobalState,
               private _router: Router,
               public dialog: MatDialog ) {
    if ( environment.env === 'dev' ) {
      this.marketDataInterval = 10;
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.deviceIsMobile = this.isDeviceMobile();
      if ( this.global ) {
        if ( environment.env !== 'prod' ) {
          window['ripsaw_widgetMenu'] = this;
        }
        this.getIndexData();
        this.indexDataInterval = setInterval( () => {
          this.getIndexData();
        }, 60000 * this.marketDataInterval ); // once every x minutes
      }
    } );
  }

  ngOnDestroy(): void {
    clearInterval( this.indexDataInterval );
    this.onDestroy.next();
  }

  getIndexData() {
    // reset arrays
    this.eventList = new Array<string>();
    this.indexBreakoutRows = [];
    MarketDataUtil.getIndexData(
      this._pricingService,
      this.onDestroy,
      this.ripDecimalPipe,
      this.ripPercentPipe,
      this.snackBar,
      this.eventList,
      this.indexBreakoutRows,
      this._state );
  }

  flip() {
    this.flipTable.emit( {} );
  }

  toggleReal() {
    this.toggleRE.emit( {} );
  }

  toggleDiffs() {
    this.toggleBenchmarkValues.emit( {} );
  }

  minMax() {
    this.minMaximize.emit( {} );
  }

  random() {
    return Math.random() * 1000;
  }

  isDeviceDesktop() {
    return MobileUtil.deviceIsDesktop( this._detectorService );
  }

  isDeviceMobile() {
    return MobileUtil.deviceIsMobile( this._detectorService );
  }

  goToMarketInfoDashboard() {
    this._router.navigate( [ 'pages/market-information-dashboard' ] );
  }

  openSettings() {
    if ( this.dialog.openDialogs.length === 0 ) {
      this.dialog.open( SettingsComponent, {
        // width: '75vw',
        height: '50vh',
        data: {
          singleSection: 'Deviation Thresholds',
        },
        panelClass: 'settings-panel',
        hasBackdrop: false,
      } );
    }
  }
}
