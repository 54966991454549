import { WorkspacePermissionNameStore } from '../../store/workspace';

export function hasAnyPermissionFn(
  all: WorkspacePermissionNameStore[],
  checking: WorkspacePermissionNameStore | WorkspacePermissionNameStore[],
): boolean {
  if ( !all || !checking ) {
    return false;
  }

  const perm = typeof checking === 'string' ? [ checking ] : checking;
  return !!all.find( allEl => perm.includes( allEl ) );
}
