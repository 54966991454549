import { Injectable } from '@angular/core';
import { LibWorkspaceLoadDialogModule } from './workspace-load-dialog.module';
import { DialogService } from '../dialog';
import { from, Observable, of } from 'rxjs';
import {
  WorkspaceLoadDialogComponent,
  WorkspaceLoadDialogInput,
  WorkspaceLoadDialogOutput,
} from './workspace-load-dialog.component';
import { filter, map, tap } from 'rxjs/operators';
import { DONT_SHOW_LOAD_WORKSPACE_DIALOG_AGAIN } from './workspace-load-dialog.meta';
import { MessageService } from 'primeng/api';

@Injectable( {
  providedIn: LibWorkspaceLoadDialogModule,
} )
export class WorkspaceLoadDialogService {
  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
  ) {
  }

  open( data: WorkspaceLoadDialogInput ): Observable<true> {
    return from(
      this.dontShowDialog ?
        of<true>( true ) :
        this.dialogService.open<WorkspaceLoadDialogInput, WorkspaceLoadDialogOutput>( WorkspaceLoadDialogComponent, { data } ).closed
          .pipe(
            filter( Boolean ),
            tap( ( { dontShowDialogAgain } ) => {
              if ( dontShowDialogAgain ) {
                localStorage.setItem( DONT_SHOW_LOAD_WORKSPACE_DIALOG_AGAIN, JSON.stringify( true ) );
              }
            } ),
            map<WorkspaceLoadDialogOutput, true>( () => true ),
          ),
    )
      .pipe(
        tap( () => {
          this.messageService.add( {
            severity: 'success',
            summary: 'Success',
            detail: `"${ data.workspace.name }" successfully loaded.`,
          } );
        } ),
      );
  }

  private get dontShowDialog(): boolean {
    try {
      return JSON.parse( localStorage.getItem( DONT_SHOW_LOAD_WORKSPACE_DIALOG_AGAIN ) );
    } catch ( e ) {
      return false;
    }
  }
}
