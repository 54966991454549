import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BondHelpers } from './bondHelpers';
import { takeUntil } from 'rxjs/operators';
import { GlobalState } from '../global.state';
import { MarketDataService } from '../globalData';
import { FormGroup } from '@angular/forms';
import { EVENT_NAMES } from './enums';

@Injectable()
export class TreasuryRatesUtil {

  private readonly onDestroy: Subject<void> = new Subject<void>();
  treasuryRates: any;
  retrievingTreasuryRates: boolean = true;
  treasuryDataError: boolean;

  constructor( private _state: GlobalState, private _mdService: MarketDataService ) {
    this.getTreasuryRates();
  }

  /**
   *
   * @param form - form group that has a control named maturity_date to get the ttm date
   */
  figureOutTTMMatchedRate( form: FormGroup ): Observable<number> {
    return new Observable( ( observer ) => {
      const rates = this.treasuryRates;
      if ( rates ) {
        const treasury_rate = BondHelpers.geTimeToMaturityMatchedTreasuryRateInPercent( form.controls.maturity_date.value, rates );
        observer.next( treasury_rate );
      } else {
        this.retrievingTreasuryRates = true;
        this._mdService.getTreasuryRates()
          .pipe( takeUntil( this.onDestroy ) )
          .subscribe( {
            next: ( resp: any ) => {
              // console.log( resp );
              this.treasuryRates = resp.data;
              this._state.notifyDataChanged( EVENT_NAMES.TREASURIES_RETRIEVED );
              const treasury_rate = BondHelpers.geTimeToMaturityMatchedTreasuryRateInPercent( form.controls.maturity_date.value, this._state.globalVars.treasuryRates );
              this.retrievingTreasuryRates = false;
              observer.next( treasury_rate );
            }, error: ( err ) => {
              observer.error( err );
            },
          } );
      }
    } );
  }

  getTreasuryRates() {
    this.retrievingTreasuryRates = true;
    this._mdService.getTreasuryRates()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          // console.log( resp );
          this.treasuryRates = resp.data;
          this.treasuryDataError = false;
          this.retrievingTreasuryRates = false;
            this._state.notifyDataChanged( EVENT_NAMES.TREASURIES_RETRIEVED );
        }, error: ( err ) => {
          console.error( err );
          this.treasuryDataError = true;
          this.retrievingTreasuryRates = false;
          this._state.notifyDataChanged( EVENT_NAMES.ERROR_RETRIEVING_TREASURY_DATA );
        },
      } );
  }
}
