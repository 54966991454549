import { UntypedFormGroup } from '@angular/forms';

export class EqualPasswordsValidator {

  static validate( firstField, secondField ) {

    return ( c: UntypedFormGroup ) => {

      return (c.controls && c.controls[firstField].value === c.controls[secondField].value) ? null : {
        passwordsEqual: {
          valid: false,
        },
      };
    };
  }
}
