import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { ProfileService } from '../../pages/profile/profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Util } from '../../utils/util.service';
import { HouseholdMembersUtil } from '../../utils/household-members.util';
import { ManualAccountUtil } from '../../utils/manualAccount.util';

@Component( {
  template: `
    <h3 mat-dialog-title class="">
      Add Household Member
    </h3>
    <div mat-dialog-content [nbSpinner]="working">
      <div *ngIf="error" class="dialog-error-msg">
        {{ error }}
      </div>
      <rip-household-member-form [form]="form"></rip-household-member-form>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="addHouseholdMember()" color="primary">
        Add
      </button>
      <button mat-button (click)="cancel()">
        Cancel
      </button>
    </div>
  `,
  styleUrls: [ `./add-household-member-dialog.component.scss` ],
} )

export class AddHouseholdMemberDialogComponent implements OnDestroy {

  private readonly onDestroy = new Subject<void>();

  error: string;
  working: boolean = false;
  form: UntypedFormGroup;


  constructor( private dialog: MatDialogRef<AddHouseholdMemberDialogComponent>,
               private _profileService: ProfileService ) {
    this.form = HouseholdMembersUtil.makeNawForm( this.form );

  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  addHouseholdMember() {
    this.working = true;
    console.log( 'adding household member...' );
    const member = this.form.getRawValue();
    member.birth_date = ManualAccountUtil.formatDate( member.birth_date );
    member.shiftToWithdrawals = ManualAccountUtil.formatDate( member.shiftToWithdrawals );
    member.endOfPortfolioLife = ManualAccountUtil.formatDate( member.endOfPortfolioLife );
    // we don't need to store these
    delete member.retirementAge;
    delete member.yearsInRetirement;

    this._profileService.createHouseholdMember( member )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this.working = false;
          const newMember: any = resp.data;
          newMember.birth_date = ManualAccountUtil.formatDate( newMember.birth_date );
          newMember.shiftToWithdrawals = ManualAccountUtil.formatDate( newMember.shiftToWithdrawals );
          newMember.endOfPortfolioLife = ManualAccountUtil.formatDate( newMember.endOfPortfolioLife );
          this.dialog.close( newMember );
        }, error: ( err ) => {
          console.error( err );
          this.working = false;
          this.error = `Error adding household member. ${ Util.getRefCodeSupportString( err.refCode ) }`;
        },
      } );
  }

  cancel() {
    this.dialog.close();
  }


}
