import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { GlobalState } from '../../../../../global.state';
import { PricingService } from '../../../../../globalData';
import { GlobalDataService } from '../../../../../globalData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../../environments/environment';
import { Security } from '../../../../../utils/dataInterfaces';
import { SecurityQueryBoxComponent } from '../../../../../reusableWidgets/securityQueryBox/securityQueryBox.component';

@Component( {
  selector: 'rip-security-finder',
  styleUrls: [ './securityFinder.scss' ],
  templateUrl: './securityFinder.component.html',
} )
export class SecurityFinderComponent implements AfterViewInit {

  @ViewChild( SecurityQueryBoxComponent, { static: false } ) queryBox: SecurityQueryBoxComponent;

  @Output() selected = new EventEmitter<any>();

  matchingFunds: any[];

  ngAfterViewInit(): void {
    const self = this;
    self._state.globalVars.securityFinder = this;
  }

  constructor( private _state: GlobalState,
               private _pricingService: PricingService,
               private _gdService: GlobalDataService,
               public snackBar: MatSnackBar ) {

    if ( environment.env !== 'prod' ) {
      window['ripsaw_securityFinder'] = this;
    }
  }

  /*
  tabShown( finderUser: NewInvestmentFinderUserInterface ) {
    this.finderUser = finderUser;
  }
*/

  optionSelected( chosenSecurity ) {
    this.matchingFunds = [ chosenSecurity ];
  }

  securityChosen( chosen: Security[] ) {
    if ( chosen && chosen.length > 0 ) {
      this.selected.emit( chosen[0] );
      this.queryBox.resetQueryControl();
    }
  }

  /* getNewSecurityPrice( security: any ) {
     this._gdService.getSecurityPrice( security.ticker ).subscribe( ( resp: any ) => {
       if ( resp.data[security.ticker] ) {
         // Logger.log( `price retrieved: ${JSON.stringify( resp.data[security.ticker] )}` );
         this.queryControl.setValue( Object.assign( this.queryControl.value, {
           price: resp.data.price,
           quote: resp.data,
         } ) );

       }
     } );
   }*/

}

