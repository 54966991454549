<nb-card [nbSpinner]="transactionsState.loading">
  <nb-card-header>
    <div>Net Flows</div>
  </nb-card-header>

  <nb-card-body class="flexing vertical-flex space-between" id="income-statement-card-body">

    <div class="flexing">
      <div class="incomeExpenseNet-container">
        <div class="flexing" style="flex: 2;">
          <nb-card style="flex:1;" class="income-statement-card">
            <nb-card-header>
              Inflows
            </nb-card-header>
            <nb-card-body *ngIf="transactionsState.incomeDoughnutChartLabels?.length > 0">
              <div class="income-statement-items flexing vertical-flex space-between income-statement-card-content">
                <div>
                  <div *ngFor="let cat of transactionsState.incomeCategories; let i = index">

                    <div class="flexing-row-only category-line" (click)="filterByCategory(cat)"
                         (mouseenter)="onHoverLegendItem(i, 'income')"
                         (mouseleave)="onLeaveLegend( i, 'income' )">
                      <div style="flex: 0.3;">
                                            <span style="flex:1;"><fa-icon [icon]="cat.icon" *ngIf="cat.icon"
                                                                           [style.color]="cat.color"></fa-icon></span>
                      </div>
                      <div style="flex: 2.2;">
                                            <span [style.color]="cat.color"
                                                  style="flex:2;"><b>        {{ cat.label }}</b></span>
                      </div>
                      <div style="flex: 2; text-align: right;">
                        {{ cat.total | ripCurrencyPipe }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="flexing space-around center-vertically">
                  <div class="donut-container canvas-container">

                    <canvas baseChart #incomeChart="base-chart"
                            [datasets]="transactionsState.incomeDoughnutChartData.datasets"
                            [labels]="transactionsState.incomeDoughnutChartLabels"
                            [type]="transactionsState.doughnutChartType"
                            [options]="incomeDonutchartOptions"
                            (chartClick)="filterByCategory($event, 'income')">

                    </canvas>

                  </div>
                </div>
              </div>
            </nb-card-body>
            <nb-card-footer class="income-statement-items flexing-row-only space-between">
              <b> Inflows Total: </b>
              <span *ngIf="transactionsState.incomeCategories?.length === 0">There are no Inflow transactions to display</span>
              <span [innerHtml]=" transactionsState.incomeTotal | ripCreditDebitPipe : 'CREDIT' "></span>
            </nb-card-footer>

          </nb-card>

          <nb-card style="flex:1.1;" class="income-statement-card">
            <nb-card-header>
              Outflows
            </nb-card-header>
            <nb-card-body *ngIf="transactionsState.expenseDoughnutChartLabels?.length > 0">
              <div class="income-statement-items flexing vertical-flex space-between income-statement-card-content">
                <div>
                  <div *ngFor="let cat of transactionsState.expenseCategories; let i = index">

                    <div class="flexing-row-only category-line" (click)="filterByCategory(cat)"
                         (mouseenter)="onHoverLegendItem(i, 'expense')"
                         (mouseleave)="onLeaveLegend( i,'expense' )">
                      <div style="flex: 0.3;">
                                            <span style="flex:1;"><fa-icon [icon]="cat.icon" *ngIf="cat.icon"
                                                                           [style.color]="cat.color"></fa-icon></span>
                      </div>
                      <div style="flex: 2.2;">
                                            <span [style.color]="cat.color"
                                                  style="flex:2;"><b>        {{ cat.label }}</b></span>
                      </div>
                      <div style="flex: 2; text-align: right;">
                        {{ cat.total | ripCurrencyPipe }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="flexing space-around center-vertically">
                  <div class="donut-container canvas-container">

                    <canvas baseChart #expenseChart="base-chart"
                            [datasets]="transactionsState.expensesDoughnutChartData.datasets"
                            [labels]="transactionsState.expenseDoughnutChartLabels"
                            [type]="transactionsState.doughnutChartType"
                            [options]="ExpenseDonutchartOptions"
                            (chartClick)="filterByCategory($event, 'expense')">

                    </canvas>

                  </div>
                </div>
              </div>
            </nb-card-body>
            <nb-card-footer class="income-statement-items flexing-row-only space-between">
              <b>Outflows Total: </b>
              <span *ngIf="transactionsState.expenseCategories?.length === 0">There are no Outflow transactions to display</span>
              <span [innerHtml]=" transactionsState.expenseTotal | ripCreditDebitPipe : 'DEBIT' "></span>

            </nb-card-footer>
          </nb-card>
          <nb-card style="flex:1.2;" class="income-statement-card">
            <nb-card-header>Monthly Inflows vs Outflows</nb-card-header>
            <nb-card-body>
              <div class="flexing center-vertically income-statement-card-content">
                <div style=" height: 500px;" class="canvas-container"
                     id="net-income-bar-chart-container"
                     [style.width]="barChartContainerWidth"
                     *ngIf="!transactionsState.loading && transactionsState.netBarChartData.datasets.length > 0">
                  <canvas baseChart #netChart="base-chart"
                          [datasets]="transactionsState.netBarChartData.datasets"
                          [labels]="transactionsState.netBarChartLabels"
                          [options]="chartOptions"
                          [type]="barChartType">
                  </canvas>
                </div>
              </div>
            </nb-card-body>

            <nb-card-footer class="income-statement-items flexing-row-only space-between">
              <b> Net Inflows (Savings): </b>
              <span [innerHtml]=" transactionsState.incomeTotal - transactionsState.expenseTotal | ripCreditDebitPipe :
                                transactionsState.incomeTotal - transactionsState.expenseTotal >= 0 ? 'CREDIT' : 'DEBIT' "></span>

            </nb-card-footer>

          </nb-card>
        </div>
      </div>


    </div>

  </nb-card-body>
</nb-card>



