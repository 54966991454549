import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ManualAccountFormComponent } from '../../manualAccountFormInterface';
import { Util } from '../../../../../utils/util.service';
import { UntypedFormGroup } from '@angular/forms';
import { GlobalState } from '../../../../../global.state';
import { PricingService } from '../../../../../globalData';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CryptoQueryBoxComponent } from '../../../../../reusableWidgets/cryptoQueryBox';
import { ManualAccountUtil } from '../../../../../utils/manualAccount.util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCoin } from '@fortawesome/pro-light-svg-icons';
import { FormsUtil } from '@ripsawllc/ripsaw-analyzer';
import { Logger } from '../../../../../utils/logger.service';

@Component( {
  selector: 'rip-crypto-form',
  templateUrl: './cryptoForm.component.html',
  styleUrls: [ '../../manualAccountManager.scss' ],
} )

export class CryptoFormComponent extends ManualAccountFormComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @ViewChild( 'queryBox', { static: false } ) queryBox: CryptoQueryBoxComponent;

  publicUtil: typeof Util;

  @Input() form: UntypedFormGroup;
  @Input() matcher;
  @Input() type;
  @Input() account;


  faCoin = faCoin;

  subscriberName: string = 'cryptoForm';

  retrievingPrice: boolean;
  coinHasBeenSelected: boolean;

  currentSelectedCoin: any;

  constructor( private _state: GlobalState,
               private _pricingService: PricingService,
               private _elRef: ElementRef,
               public snackBar: MatSnackBar ) {
    super();
    this.publicUtil = Util;
  }

  ngAfterViewInit(): void {
    // Logger.log( this.account );
    setTimeout( () => {
      this.patchForm();
      this.setupUpdateSubscription( this._state );
      this.form.controls.value.disable();
    } );
  }

  ngOnDestroy() {
    this.unsubscribeFromUpdate( this._state );
  }

  patchForm() {
    if ( this.account && this.form ) {
      ManualAccountUtil.patchCryptoForm( this.form, this.account );
      const position = this.account.positions[0];
      this.queryBox.resetQueryControl( position.ticker );
      this.coinSelected( { Code: position.ticker, Name: position.ticker_name } );
    } else {
      // since these aren't shown, but are still required, we need to set them to 1
      if ( this.type === 'Crypto' ) {
        const element = this.formElement.nativeElement.querySelector( `.crypto-query-box-input` );
        if ( element ) {
          setTimeout( () => element.focus(), 0 );
        }
      }
    }
  }

  coinSelected( coin: any ) {
    Logger.log( coin );
    this.currentSelectedCoin = { ticker: coin.Code };
    this.coinHasBeenSelected = false;
    this.retrievingPrice = true;
    this.queryBox.showRetrieving();
    this._pricingService.getCryptoPrice( coin.Code )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( ( priceResponse: any ) => {
        const coinPrice = priceResponse.data;
        if ( coinPrice?.ticker ) {
          this.form.controls.ticker.setValue( coinPrice.ticker );
          this.form.controls.name.setValue( coin.Name );
          this.form.controls.ticker_name.setValue( coin.Name );
          this.form.controls.price.setValue( coinPrice.price );
          Util.updateInputCurrencyFormat( 'price', this.form );

          this.updateValue();

          this.queryBox.resetQueryControl( coin.Code );
          this.coinHasBeenSelected = true;
        } else {
          this.coinHasBeenSelected = false;
        }
        this.retrievingPrice = false;
        this.queryBox.hideRetrieving();
      }, ( err ) => {
        this.retrievingPrice = false;
        this.snackBar.open( 'Error Retrieving Crypto Price. Please Try Again Later', 'dismiss', Util.getSnackBarOptions( true ) );
        Logger.log( err );
      } );
  }

  queryBoxBlurred() {
    if ( this.currentSelectedCoin?.ticker !== ( this.queryBox.queryControl.value?.ticker || this.queryBox.queryControl.value ) ) {
      this.form.controls.ticker.setValue( this.queryBox.queryControl.value?.ticker || this.queryBox.queryControl.value );
      this.form.controls.ticker_name.setValue( this.form.controls.name.value );
      this.currentSelectedCoin = undefined;
      this.coinHasBeenSelected = false;
    }
  }

  quantityChanged() {
    this.updateValue();
  }

  priceChanged() {
    Util.updateInputCurrencyFormat( 'price', this.form );
    this.updateValue();
  }

  updateValue() {
    const value = FormsUtil.getSanitizedFloatValue( this.form.controls.price.value ) * FormsUtil.getSanitizedFloatValue( this.form.controls.quantity.value );
    this.form.controls.value.setValue( value );
    Util.updateInputCurrencyFormat( 'value', this.form );
  }

  costBasisChanged() {
    Util.updateInputCurrencyFormat( 'cost_basis', this.form );
  }
}
