import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { GoalsState } from '../../../utils/goals.state';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { PlanSummaryTableRow } from '../../../utils/dataInterfaces';
import { GoalsUtil } from '../../../utils/goals.util';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { GlobalState } from '../../../global.state';
import { EVENT_NAMES, EXPECTED_VALUE_SCALES, USER_GOAL_FORM_TYPES, USER_GOAL_TYPE_IDS } from '../../../utils/enums';
import { DatePipe } from '@angular/common';
import { MobileUtil } from '../../../utils/mobileUtil.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
import { Util } from '../../../utils/util.service';
import moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { Auth } from '../../../auth.service';
import { environment } from '../../../../environments/environment';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { GlossaryUtil } from '../../../utils/glossary.util';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../shared/workspace-permission';
import { AppStoreService } from '../../../store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrossAppMessageType, DialogName, InvestorGoal } from '@ripsawllc/ripsaw-analyzer';
import { WealthFluentMessageService } from '../../../theme/services';


@Component( {
  selector: 'rip-goal-contributions',
  template: `

    <div id="goal-contributions-container">
      <div class="planning-sidebar-titles flexing-row-only space-between with-gap">
        <div>
          <ng-container *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]">
            <div style="padding: 0 10px;" *ngIf="showAddGoalButton">
              <div class="flexing-row-only with-gap center-vertically add-goal-button"
                   (click)="openAddGoalDialog()"
                   matTooltip="Click to add a goal"
                   matTooltipClass="mat-tooltip-custom">
                <fa-icon [icon]="faPlusSquare" class="goal-icon"></fa-icon>
                Add Goal
              </div>
            </div>
          </ng-container>
        </div>

        <div>
          My Goals
        </div>

        <div>
          Possible Outcomes
          ({{ ( benchmarkState?.benchmark?.probability ?? benchmarkState?.riskPotentialDefaultProbability ) | percent }}
          Tail Risk)
        </div>
        <div></div>
      </div>
      <div [nbSpinner]="working" *ngIf="benchmarkState?.longTermFrontierHasBeenProcessed">

        <mat-table class="my-goals-table" [dataSource]="source" #table>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <mat-header-cell *cdkHeaderCellDef>
              Edit
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]" class="flexing center-vertically">
                <!-- only show if the user can edit the investor profile or is the workspace owner-->
                <button mat-button (click)="openEditDialog( row )"
                        class="icon-button" *ngIf="row.workspace_id ">
                  <fa-icon [icon]="faEdit" fixedWidth></fa-icon>
                </button>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>

            </mat-footer-cell>
          </ng-container>

          <!-- Goal Column -->
          <ng-container matColumnDef="goal">
            <mat-header-cell *cdkHeaderCellDef>
              Goal
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <div class="goal-name">
                <fa-icon matTooltip="{{ row.name }}"
                         matTooltipClass="mat-tooltip-custom"
                         [icon]="goalsState.iconsMap[row.type] ?? goalsState.defaultIcon" fixedWidth
                         [style.color]="row.color ?? goalsState.defaultColor"></fa-icon>
                {{ row.name }}
              </div>

            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
              <b>Totals</b>
            </mat-footer-cell>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <mat-header-cell *cdkHeaderCellDef>
              Date
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <!--{{  goal.goal_date | ripDatePipe | }}-->
              {{ row?.goal_date | date: 'MM/dd/YYYY' }}
              <!-- {{ goal.goal_date | date: 'MM/dd/YYYY'}}-->
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
            </mat-footer-cell>
          </ng-container>

          <!-- Amount Column -->
          <ng-container matColumnDef="amount">
            <mat-header-cell *cdkHeaderCellDef>
              <div *ngIf="!deviceIsMobile">
                Total
                <div>Goal Amount</div>
              </div>
              <div *ngIf="deviceIsMobile">
                Goal Amount
              </div>


            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="num-cell">

              <div *ngIf="row.total_withdrawal">
                {{ ( row.total_withdrawal | ripCurrencyPipe : true ) }}
              </div>
              <div *ngIf="!row.total_withdrawal">
                {{ row.hasPassed ? '' : ( row.pvOfWithdrawalsAtRetirementDate | ripCurrencyPipe : true ) }}
              </div>

              <!--              {{ ( row.total_withdrawal ? ( row.total_withdrawal | ripCurrencyPipe : true ) : ( row.pvOfWithdrawalsAtRetirementDate | ripCurrencyPipe : true ) ) }}-->
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
              <b *ngIf="amountSum !== undefined">{{ amountSum | ripCurrencyPipe : true }}</b>
            </mat-footer-cell>
          </ng-container>

          <!-- Contributions Column -->
          <ng-container matColumnDef="annualContributions">
            <mat-header-cell *cdkHeaderCellDef>
              <div *ngIf="!deviceIsMobile">
                Save/Invest
                <div>per Month</div>
              </div>
              <div *ngIf="deviceIsMobile">
                Monthly Savings
              </div>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="num-cell">
              {{ row.hasPassed ? '' : row.annualContributions / 12 | ripCurrencyPipe : true }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
              <b>{{ contributionsSum | ripCurrencyPipe : true }}</b>
            </mat-footer-cell>
          </ng-container>

          <!-- Time Column -->
          <ng-container matColumnDef="time">
            <mat-header-cell *cdkHeaderCellDef>
              <div>
                {{
                  benchmarkState?.expectedWealthScale === EXPECTED_VALUE_SCALES?.age ?
                    'Age' : ''
                }}
                {{
                  benchmarkState?.expectedWealthScale === EXPECTED_VALUE_SCALES?.calendarYears ?
                    'Year' : ''
                }}
                {{
                  benchmarkState?.expectedWealthScale === EXPECTED_VALUE_SCALES?.yearsFromToday ?
                    'Years From Today' : ''
                }}
              </div>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="num-cell">
              <div>
                <div *ngIf="benchmarkState.expectedWealthScale === EXPECTED_VALUE_SCALES.calendarYears">
                  {{ row?.time < 0 ? 'Retired' : row?.time | ripCalendarYearPipe }}
                </div>
                <div *ngIf="benchmarkState.expectedWealthScale !== EXPECTED_VALUE_SCALES.calendarYears">
                  {{ row?.time < 0 ? 'Retired' : row?.time | ripDecimalPipe : '0-0' }}
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>

            </mat-footer-cell>
          </ng-container>

          <!-- Downside Risk Column -->
          <ng-container matColumnDef="downsideRisk">
            <mat-header-cell *cdkHeaderCellDef>
              <div class="downside-risk">
                <div>
                  <span class="disclaimer form-disclaimer" (click)="openGlossary( 20 )">
                    <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
                  </span>
                  Downside
                </div>
                <div>
                  Risk
                </div>
              </div>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="num-cell">
              {{ row?.downsideRisk | ripCurrencyPipe : true }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
              <!--<div style="position: relative;">
                <nb-badge [status]="'warning'" text="1"
                          *ngIf="downsideRiskAtPortfolioEnd === 0"
                          class="far-right"></nb-badge>
                {{ downsideRiskAtPortfolioEnd | ripCurrencyPipe : true }}
              </div>-->
            </mat-footer-cell>
          </ng-container>
          <!-- Expected Wealth Column -->
          <ng-container matColumnDef="expectedWealth">
            <mat-header-cell *cdkHeaderCellDef>
              <div class="expected-wealth">
                <div>
                  <span class="disclaimer form-disclaimer" (click)="openGlossary( 21 )">
                    <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
                  </span>
                  Expected
                </div>
                <div>
                  Wealth
                </div>
              </div>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="num-cell">
              {{ row?.expectedWealth | ripCurrencyPipe : true }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
              <!--<div style="position: relative;">
                <nb-badge [status]="'danger'" text="1"
                          *ngIf="expectedWealthAtPortfolioEnd === 0"
                          class="far-right"></nb-badge>
                {{ expectedWealthAtPortfolioEnd | ripCurrencyPipe : true }}
              </div>-->
            </mat-footer-cell>
          </ng-container>

          <!-- Upside Potential Column -->
          <ng-container matColumnDef="upsidePotential">
            <mat-header-cell *cdkHeaderCellDef>
              <div class="upside-potential">
                <div>
                  <span class="disclaimer form-disclaimer" (click)="openGlossary( 20 )">
                    <fa-icon [icon]="faQuestionCircle" size="sm"></fa-icon>
                  </span>
                  Upside
                </div>
                <div>
                  Potential
                </div>
              </div>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row" class="num-cell">
              {{ row?.upsidePotential | ripCurrencyPipe : true }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
              <!-- <div>
                 {{ upsidePotentialAtPortfolioEnd | ripCurrencyPipe : true }}
               </div>-->
            </mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columns$ | async"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns$ | async;"></mat-row>
          <mat-footer-row *matFooterRowDef="columns$ | async"></mat-footer-row>
        </mat-table>

      </div>
    </div>
  `,
  styleUrls: [ `./goal-contributions.component.scss` ],
} )

export class GoalContributionsComponent extends WithWorkspacePermission implements OnInit, OnDestroy {

  @Input() showAddGoalButton: boolean = true;

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  columns$: Observable<string[]>;
  source: MatTableDataSource<PlanSummaryTableRow>;
  contributionsSum: number;
  amountSum: number;
  expectedWealthAtPortfolioEnd: number;
  downsideRiskAtPortfolioEnd: number;
  upsidePotentialAtPortfolioEnd: number;

  deviceIsMobile: boolean;

  faInfoCircle = faInfoCircle;
  faEdit = faEdit;

  working: boolean = false;

  protected readonly USER_GOAL_TYPE_IDS = USER_GOAL_TYPE_IDS;


  protected readonly DatePipe = DatePipe;
  protected readonly faPlusSquare = faPlusSquare;
  protected readonly EXPECTED_VALUE_SCALES = EXPECTED_VALUE_SCALES;
  protected readonly faQuestionCircle = faQuestionCircle;

  subscriberName: string = 'goalContributionsComponent';

  constructor( public goalsState: GoalsState,
               public dialog: MatDialog,
               public benchmarkState: BenchmarkState,
               private _detectorService: DeviceDetectorService,
               private _state: GlobalState,
               private _auth: Auth,
               private glossaryUtil: GlossaryUtil,
               private appStoreService: AppStoreService,
  ) {
    super();

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

    this.setup();
    goalsState.update.subscribe( {
      next: () => {
        this.setup();
      },
    } );
    _state.subscribe( EVENT_NAMES.SELECTED_EXPECTED_VALUE_RECALCULATED, () => {
      this.setup();
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_goalContributions' ] = this;
    }

  }

  ngOnInit(): void {
    this.columns$ = this.initColumnsObs();
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.SELECTED_EXPECTED_VALUE_RECALCULATED, this.subscriberName );
  }

  setup(): void {
    const today: Moment = moment();
    // start with filtered to only include goals the user set to include
    this.source = new MatTableDataSource<PlanSummaryTableRow>(
      [
        ...this.goalsState.goals.filter( ( ( g: InvestorGoal ) => {
          const goalIsRetirement = g.type === USER_GOAL_TYPE_IDS.retirement;

          if ( goalIsRetirement ) {
            g.pvOfWithdrawalsAtRetirementDate = GoalsUtil.calculateRetirementGoalPV( g.goal_date, g.annualWithdrawals, this.benchmarkState.selectedBucket?.stats?.totalLTExpectedReturn );
          }

          const hasPassed = moment( g.goal_date ).isSameOrBefore( today );

          return g.include && // we don't want goals that are supposed to be hidden
            g.type !== USER_GOAL_TYPE_IDS.emergency_fund // we don't want the emergency fund goal here
            && ( !hasPassed || goalIsRetirement ); // we don't want goals that have already passed except for retirement goals
        } ) ),
      ],
    );
    // go through and add expectedWealth, etc at goal date numbers on the filtered set
    this.source.data.forEach( ( r: PlanSummaryTableRow ) => {

      if ( r.type === USER_GOAL_FORM_TYPES.retirement ) {
        const retirementWithdrawal = this.benchmarkState.selectedBucket?.stats?.retirementWithdrawals.find( ( w ) => {
          return r.id === w.goalId;
        } );
        if ( retirementWithdrawal ) {
          r.expectedWealth = retirementWithdrawal.expectedWealthAtGoalDate;
          r.downsideRisk = retirementWithdrawal.downsideRiskAtGoalDate;
          r.upsidePotential = retirementWithdrawal.upsidePotentialAtGoalDate;
          r.time = retirementWithdrawal.xValue;
        }
      } else {
        const lastWithdrawal = this.benchmarkState.selectedBucket?.stats?.withdrawals.find( ( w ) => {
          return r.id === w.goalId && moment( w.date ).isSame( moment( r.goal_date ), 'day' );
        } );
        if ( lastWithdrawal ) {
          r.expectedWealth = lastWithdrawal.expectedWealthAtGoalDate;
          r.downsideRisk = lastWithdrawal.downsideRiskAtGoalDate;
          r.upsidePotential = lastWithdrawal.upsidePotentialAtGoalDate;
          r.time = lastWithdrawal.xValue;
        }
      }
      if ( moment( r.goal_date ).isBefore( today ) ) {
        r.hasPassed = true;
      }
    } );

    const user = Util.getLoggedInUser( this._auth ); // the benchmarkState onboardingData is a better source
    // add current portfolio row
    const currentPortfolioRow: PlanSummaryTableRow = {
      name: 'Current Portfolio',
      total_withdrawal: 0,
      goal_date: today.toDate(),
      type: USER_GOAL_TYPE_IDS.other,
      time: this.benchmarkState.selectedBucket?.stats?.startingX,
      expectedWealth: this.benchmarkState.startingPortfolioSize,
      downsideRisk: this.benchmarkState.startingPortfolioSize,
      upsidePotential: this.benchmarkState.startingPortfolioSize,
    };
    this.source.data.push( currentPortfolioRow );

    // add my retirement if the user is not retired
    const retired = ( this.benchmarkState.onboardingData?.retired ?? user?.retired ) || moment( this.benchmarkState?.benchmark?.shiftToWithdrawals ).isBefore( moment() );
    if ( !retired ) {
      const retirementRow: PlanSummaryTableRow = {
        name: 'My Retirement',
        annualContributions: this.benchmarkState.benchmark.annualContributions,
        total_withdrawal: this.benchmarkState.selectedBucket?.stats?.pvOfWithdrawalsAtRetirementDate,
        goal_date: this.benchmarkState.benchmark.shiftToWithdrawals,
        type: USER_GOAL_TYPE_IDS.retirement,
        time: this.benchmarkState.selectedBucket?.stats?.inflectionPoint?.n,
        expectedWealth: this.benchmarkState.selectedBucket?.stats?.inflectionPoint?.expectedWealth,
        downsideRisk: this.benchmarkState.selectedBucket?.stats?.inflectionPoint?.downsideRisk,
        upsidePotential: this.benchmarkState.selectedBucket?.stats?.inflectionPoint?.upsidePotential,
      };
      this.source.data.push( retirementRow );
    }
    // add portfolio end row
    const portfolioEndRow: PlanSummaryTableRow = {
      name: 'At Portfolio End',
      total_withdrawal: 0,
      goal_date: this.benchmarkState.selectedBucket?.stats?.portfolioEnd?.date,
      type: USER_GOAL_TYPE_IDS.other,
      time: this.benchmarkState.selectedBucket?.stats?.portfolioEnd?.n,
      expectedWealth: this.benchmarkState.selectedBucket?.stats?.portfolioEnd?.expectedWealth,
      downsideRisk: this.benchmarkState.selectedBucket?.stats?.portfolioEnd?.downsideRisk,
      upsidePotential: this.benchmarkState.selectedBucket?.stats?.portfolioEnd?.upsidePotential,
    };
    this.source.data.push( portfolioEndRow );

    // sort by time so the goals are in order of withdrawal
    this.source.data.sort( ( a: PlanSummaryTableRow, b: PlanSummaryTableRow ) => {
      return moment( a.goal_date ).isSameOrBefore( moment( b.goal_date ) ) ? -1 : 1;
    } );

    // get summed up data
    this.getSums();

    // get portfolio end numbers
    this.upsidePotentialAtPortfolioEnd = this.benchmarkState.selectedBucket?.stats.portfolioEnd.upsidePotential;
    this.expectedWealthAtPortfolioEnd = this.benchmarkState.selectedBucket?.stats.portfolioEnd.expectedWealth;
    this.downsideRiskAtPortfolioEnd = this.benchmarkState.selectedBucket?.stats.portfolioEnd.downsideRisk;
  }

  getSums(): void {
    this.contributionsSum = 0;
    this.amountSum = 0;
    this.source.data.forEach( g => {
      this.contributionsSum += ( g.annualContributions ?? 0 ) / 12;
      this.amountSum += g.pvOfWithdrawalsAtRetirementDate ?? g.total_withdrawal ?? g.annualWithdrawals ?? 0;
    } );
  }

  openEditDialog( goal: InvestorGoal ): void {
    this.wealthFluentMessageService.sendOpenEditGoalMessage( goal );
  }

  openAddGoalDialog(): void {
    this.wealthFluentMessageService.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      message: 'Open Add Goal Dialog',
      data: {
        dialogName: DialogName.ADD_GOAL,
      },
    } );
  }

  openGlossary( index: number ): void {
    this.glossaryUtil.openGlossaryDialog( index );
  }

  private initColumnsObs(): Observable<string[]> {
    return this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        map( allPerms => {
          return [
            'edit',
            'goal',
            'date',
            'annualContributions',
            'amount',
            'time',
            'downsideRisk',
            'expectedWealth',
            'upsidePotential',
          ].filter( col => col !== 'edit' || hasAnyPermissionFn( allPerms, [ this.perm.OWNER, this.perm.INVESTOR_PROFILE_EDIT ] ) );
        } ),
      );
  }

}
