import { Component } from '@angular/core';
import { BenchmarkState } from '../../../utils/benchmark.state';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

@Component( {
  selector: 'rip-benchmark-asset-allocation-breakdown',
  template: `
    <div class="ripsaw-card-header">
      Plan Benchmark Asset Allocation
      <fa-icon [icon]="faInfoCircle"
               class="form-info"
               [fixedWidth]="true"
               size="sm"
               [nbPopover]="benchmarkAssetAllocation"
               nbPopoverClass="rip-popover">
      </fa-icon>
    </div>
    <div class="flexing-row-only space-around benchmark-asset-allocation-breakdown-container">

      <!--<div class="benchmark-asset-allocation-breakdown-column">
        <div class="benchmark-asset-allocation-breakdown-header">
          Benchmark Proxy
        </div>
        <div class="benchmark-asset-allocation-breakdown-cell">
          Allocation
        </div>
      </div>-->
      <div *ngFor="let proxy of benchmarkState?.benchmarkAssetAllocationList"
           class="benchmark-asset-allocation-breakdown-column">
        <div class="benchmark-asset-allocation-breakdown-header">
          {{ proxy.identifier }}
        </div>
        <div class="benchmark-asset-allocation-breakdown-cell">
          {{ proxy.allocation | ripPercentPipe : '2-2' }}
        </div>
      </div>
    </div>


    <!--benchmark intro popover-->
    <ng-template #benchmarkAssetAllocation>
      <nb-card class="popover-card">
        <nb-card-header class="popover-title">
          Plan Benchmark Asset Allocation
        </nb-card-header>
        <nb-card-body>
          <div class="popover-content">
            <p>Wealth management involves constructing, monitoring, and revising investments that satisfy your objectives. A convenient way to summarize your investment objectives is your unique benchmark (strategic asset allocation).  This benchmark should be a low cost, well-diversified, readily obtainable investment strategy requiring no special information that is consistent with your investment objectives.  Given the variety of account restrictions and differential tax treatments, it is unlikely that you can or want to match your benchmark exactly in each account, but you can get close at the aggregate wealth portfolio level. Getting close is how you can avoid uncompensated risk and unintended risk exposures.</p>

          </div>
        </nb-card-body>
      </nb-card>

    </ng-template>
    
    
  `,
  styleUrls: [ './benchmark-asset-allocation-breakdown.component.scss' ],
} )

export class BenchmarkAssetAllocationBreakdownComponent {
  faInfoCircle = faInfoCircle;

  constructor( public benchmarkState: BenchmarkState ) {

  }
}
