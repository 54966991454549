import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { PricingService } from '../../../globalData';
import { Quote } from '../../../utils/dataInterfaces';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { MarketDataUtil } from '../../../utils/marketData.util';
import { Subject } from 'rxjs';
import { RipsawDecimalPipe, RipsawPercentPipe } from '../../../theme/pipes';
import { GlobalState } from '../../../global.state';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';

@Component( {
  selector: 'rip-mobile-market-data-ticker',
  template: `
    <div>
      <mat-card>
        <mat-card-content>
          <div style="display: flex;">
            <div style="display: inline; text-align: right; width: 100%">
              <span [innerHTML]="currentEvent"></span>
            </div>
            <span style="margin-left: 5px;">
              <fa-icon [icon]="faColumns"
                       [fixedWidth]="true"
                       id="market-data-mobile-icon"
                       size="sm"></fa-icon>
            </span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  `,
  styleUrls: [ `./mobileLayoutComponents.scss`, `../mobileLayout.scss` ],
} )

export class MobileMarketDataTickerComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  faColumns = faColumns;

  events: any[] = [ 'Loading Market Data...' ];
  currentEvent: string = '<span>Loading Market Data...</span>';
  currentEventIndex: number = 0;

  currentEventInterval: any;

  marketDataInterval: number = 3; // interval in minutes for market data updates

  indexQuotes: Quote[] = [];

  indexDataInterval;

  indexBreakoutRows: any[] = [];


  ripDecimalPipe: RipsawDecimalPipe = new RipsawDecimalPipe();
  ripPercentPipe: RipsawPercentPipe = new RipsawPercentPipe();

  constructor( private _detectorService: DeviceDetectorService,
               private snackBar: MatSnackBar,
               private _pricingService: PricingService,
               private _state: GlobalState ) {
    if ( environment.env === 'dev' ) {
      this.marketDataInterval = 10;
    }
    if ( environment.env !== 'prod' ) {
      window['ripsaw_marketDateTickerComponent'] = this;
    }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.getIndexData();
      this.indexDataInterval = setInterval( () => {
        this.getIndexData();
      }, 60000 * this.marketDataInterval ); // once every x minutes
      setTimeout( () => {
        this.startTickerInterval();
      }, 1000 );
    } );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  startTickerInterval() {
    this.currentEventInterval = setInterval( () => {
      this.setCurrentEvent();
    }, 5000 );
  }

  setCurrentEvent() {
    if ( this.currentEventIndex < this.events.length - 1 ) {
      this.currentEventIndex++;
    } else {
      this.currentEventIndex = 0;
    }
    this.currentEvent = this.events[this.currentEventIndex];
  }

  getIndexData() {
    this.events = new Array<string>();
    this.indexBreakoutRows = [];
    MarketDataUtil.getIndexData(
      this._pricingService,
      this.onDestroy,
      this.ripDecimalPipe,
      this.ripPercentPipe,
      this.snackBar,
      this.events,
      this.indexBreakoutRows,
      this._state );
  }
}
