<aside class="al-sidebar" sidebarResize [style.display]="showSidebar ? '' : 'none'">
  <a routerLink="/pages/balanceSheet" class="al-logo" *ngIf="!inWealthFluent">
    <img src="{{logoUrl}}" height="55px" *ngIf="!isMenuCollapsed" alt="logo">
    <img src="{{smallLogoUrl}}" width="55px" height="55px" *ngIf="isMenuCollapsed" alt="small logo">
  </a>

<!--  <a href (click)="toggleMenu()"
     class="collapse-menu-link"
     matTooltip="{{ isMenuCollapsed ? 'Expand' : 'Collapse' }} Portfolio Sidebar"
     matTooltipClass="mat-tooltip-custom"
     matTooltipPosition="right"
     *ngIf="showSidebarExpandButton">
    <mat-icon *ngIf="isMenuCollapsed">keyboard_double_arrow_right</mat-icon>
    <mat-icon *ngIf="!isMenuCollapsed">keyboard_double_arrow_left</mat-icon>
  </a>-->
  <nga-ba-menu
      [sidebarCollapsed]="isMenuCollapsed"
      (expandMenu)="menuExpand()"
      [subscriptionInactive]="subscriptionInactive">
  </nga-ba-menu>
</aside>

