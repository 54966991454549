<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle id="settings-panel-header">
  User Settings
</h3>
<mat-divider class="modal-header-divider"></mat-divider>

<div mat-dialog-content id="settings-content">
  <!-- when new sections are added, make sure to add the "!singleSection || singleSection === 'new section name'"  ngIf check -->
  <rip-deviation-thresholds #deviationThresholds *ngIf="!singleSection || singleSection === 'Deviation Thresholds'">

  </rip-deviation-thresholds>

  <ng-container *ripPerm="[perm.OWNER]; ngIf: !inWealthFluent">
    <rip-app-settings #appSettings *ngIf="!singleSection || singleSection === 'App Settings'">

    </rip-app-settings>
  </ng-container>

  <ng-container *ripPerm="[perm.OWNER]">
    <rip-market-info-settings #marketInfoSettings
                              *ngIf="!singleSection || singleSection === 'Market Information Settings'">

    </rip-market-info-settings>
  </ng-container>
</div>
<mat-divider class="modal-footer-divider"></mat-divider>
<div mat-dialog-actions id="settings-panel-actions" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <button mat-raised-button (click)="close()">Close</button>
</div>
