import { AfterViewInit, Component, HostListener, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Chart, ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import * as moment from 'moment';
import 'chartjs-adapter-moment';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { MarketDataService, PricingService } from '../../globalData';
import { GlobalState } from '../../global.state';
import { environment } from '../../../environments/environment';
import { Util } from '../../utils/util.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../utils/mobileUtil.service';
import { Auth } from '../../auth.service';
import { MarketInfoUtil } from '../../utils/market-info.util';
import { Logger } from '../../utils/logger.service';

export interface CurrentVixStats {
  diffFromHistorical?: number;
  diffFromHistoricalPercent?: number;
}

@Component( {
  selector: 'rip-vix-chart',
  template: `
    <nb-card [nbSpinner]="marketInfoUtil.loadingCurrentVix && loadingHistoricalVix">
      <nb-card-header style="position: relative;">
        <div id="vix-card-header" class="flexing space-between center-vertically">
          <div>
            <div class="market-info-card-title">
              CBOE Volatility Index<sup>®</sup> (VIX<sup>®</sup> Index)
            </div>
          </div>
          <div>
            <mat-button-toggle-group #dateRadio id="date-range-button-group"
                                     [(value)]="currentDateRangeOption"
                                     (valueChange)="changeSeriesRange()">
              <mat-button-toggle *ngFor="let option of dateRangeRadioOptions" [value]="option.value">
                {{ option.label }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div id="vix-chart-group">
          <div id="vix-chart-container" [nbSpinner]="loadingHistoricalVix">
            <nb-badge *ngIf="historicalVixError" text="Error Loading Historical Data. Click here to try again"
                      position="top left" (click)="getHistoricalVix()"
                      class="button-badge"
                      status="danger">
            </nb-badge>
            <div id="historical-high-label">
              <span>*All Time High VXO®: {{ actualHigh }} (10/19/1987)</span>
            </div>
            <div id="vix-chart-canvas-container">
              <canvas baseChart #vixChartCanvas width="100%"
                      [datasets]="vixChartDataSets"
                      [labels]="vixChartLabels"
                      [options]="vixChartOptions"
                      [legend]="false"
                      [type]="vixChartType"
                      (error)="chartError($event)"
                      *ngIf="vixChartDataSets.length > 0"></canvas>
            </div>
          </div>
          <div id="vix-snapshot-container">
            <nb-card [nbSpinner]="marketInfoUtil.loadingCurrentVix">
              <nb-card-header style="position: relative;">
                <nb-badge *ngIf="marketInfoUtil.currentVixError"
                          text="Error Loading Current Data. Click here to try again"
                          position="top left" (click)="getCurrentVix()"
                          class="button-badge"
                          status="danger">
                </nb-badge>
                <div>Today</div>
                <div class="footnote">
                  {{ marketInfoUtil.currentVixQuote?.asOfDate | date : 'medium' }}
                </div>
              </nb-card-header>
              <nb-card-body>
                <div id="vix-snapshot" *ngIf="marketInfoUtil.snapshot">

                  <div style="text-align: center;">
                    <div class="snapshot-header" *ngIf="!marketInfoUtil.customVix">
                      Current VIX<sup>®</sup>
                    </div>
                    <div class="snapshot-header" *ngIf="marketInfoUtil.customVix">
                      Your Short-Term Market Volatility
                    </div>
                    <div [style.border-color]="marketInfoUtil.snapshot?.currentColor" class="current-indicator">
                      {{ marketInfoUtil.snapshot?.current | ripDecimalPipe : '2-2' }}
                    </div>
                  </div>

                  <div>
                    <div class="snapshot-header">
                      {{ marketInfoUtil.snapshot?.diffFromLTAV | ripDecimalPipe : '2-2' }}
                      / {{ marketInfoUtil.snapshot?.percentDiffFromLTAV | ripPercentPipe : '2-2' }}
                      <b>
                        <span *ngIf="marketInfoUtil.snapshot?.diffFromLTAV > 0">ABOVE</span>
                        <span *ngIf="marketInfoUtil.snapshot?.diffFromLTAV < 0">BELOW</span>
                        <span *ngIf="marketInfoUtil.snapshot?.diffFromLTAV === 0">DIFFERENCE FROM</span>
                      </b>
                    </div>
                    <div *ngIf="marketInfoUtil.longTermStandardDeviation === defaultLongTermMarketVolatility">
                      The Long-Term Market Volatility
                    </div>
                    <div *ngIf="marketInfoUtil.longTermStandardDeviation !== defaultLongTermMarketVolatility">
                      Your Long-Term Market Volatility
                    </div>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="flexing space-between vix-chart-color-legend">
          <span><fa-icon [icon]="faSquare" style="color: #49a1e0; "></fa-icon>Extremely Low (0-10)</span>
          <span><fa-icon [icon]="faSquare" style="color: #207bbc; "></fa-icon>Low (10-13)</span>
          <span><fa-icon [icon]="faSquare" style="color: #ffa139; "></fa-icon>Moderate (13-24)</span>
          <span><fa-icon [icon]="faSquare" style="color: #ff452b; "></fa-icon>High (24-48)</span>
          <span><fa-icon [icon]="faSquare" style="color: #993333; "></fa-icon>Extremely High (48+)</span>
        </div>
        <div class="market-info-blurb">
          <div>
            <b>The CBOE Volatility Index<sup>®</sup>, or VIX<sup>®</sup></b>, is a real-time market index representing
            the market’s expectation for volatility over the coming 30 days (forward-looking). Investors use the VIX as
            a measure of the level of risk, fear, or stress in the market when making investment decisions.
          </div>
          <div>
            Learn more about <a (click)="openVixLink()" class="rip-link">VIX</a>
          </div>
        </div>
      </nb-card-footer>
      <nb-card-footer>
        <div style="display: flex; justify-content: flex-start" class="footnote">
          *VXO® All Time High sourced from
          <a (click)="openCBOELink()" class="rip-link" style="margin-left: 5px;">CBOE</a>
        </div>
      </nb-card-footer>
    </nb-card>
  `,
  styleUrls: [ './vix-chart.component.scss' ],
} )

export class VixChartComponent implements AfterViewInit, OnDestroy {

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faSquare = faSquare;

  private readonly onDestroy = new Subject<void>();

  get defaultLongTermMarketRiskPremium(): number {
    return MarketInfoUtil.defaultLongTermRiskPremium;
  };

  get defaultLongTermMarketVolatility(): number {
    return MarketInfoUtil.defaultLongTermStandardDeviation;
  }

  @ViewChild( 'tooltipTemplate' ) tooltipTemplate: TemplateRef<any>;

  @HostListener( 'window:resize', [ '$event' ] )
  onResize( /*event: Event*/ ) {
    // Logger.info( 'there was a resize event' );
    if ( this.chartObject ) {
      this.chartObject.chart.update();
    }
  }

  today: Date = new Date();


  vixSeries: any[];
  daysBack: number = 252 * 5; // 5 years of trading days
  dateRangeRadioOptions: any[] = [
    // { value: { unitsBack: '1', unit: 'D' }, label: '1D' }, // comment out until we get intraday numbers
    { value: { unitsBack: '1', unit: 'W' }, label: '1W' },
    { value: { unitsBack: '1', unit: 'M' }, label: '1M' },
    { value: { unitsBack: '1', unit: 'Y' }, label: '1Y' },
    { value: { unitsBack: '3', unit: 'Y' }, label: '3Y' },
    { value: { unitsBack: '5', unit: 'Y' }, label: '5Y' },
    { value: { unitsBack: '10', unit: 'Y' }, label: '10Y' },
    { value: { unitsBack: '15', unit: 'Y' }, label: '15Y' },
    { value: { unitsBack: '1', unit: 'MAX' }, label: 'MAX' },

  ];
  currentDateRangeOption: any;
  vixSeriesStats: any;
  currentVixStats: CurrentVixStats = {};
  // chartjs implementation  -------------------------------------------------------------------------------------------
  vixChartDataSets: any[] = [];
  vixChartLabels: any = [];
  vixChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: {
        bottom: 20,
        right: 0,
      },
    },
    scales: {
      x: {
        // id: 'time',
        type: 'time',
        time: {
          unit: 'year',
          displayFormats: {
            'hour': 'MMM DD',
            'day': 'MMM DD',
            'week': 'MMM DD',
            'month': 'MMM DD',
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          maxTicksLimit: 10,
        },
      },
      y: {
        // id: 'vix',
        type: 'linear',
        position: 'left',
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          // tooltipItems is an array. one item for each data set (but we only have one in this chart, so we can use [0])
          title: ( tooltipItems: ( TooltipItem<ChartType> )[] ) => {
            // should only be one tooltipItem
            const tooltip = tooltipItems[ 0 ];
            const labelArray = tooltip.label.split( ',' ); // this should be a fully formatted date like Aug 24, 2015, 12:00:00 am, so we want to get
                                                           // rid of the timestamp
            labelArray.pop(); // this will pop the timestamp off the end
            return labelArray.join( ', ' ); // then put the string back together and return
          },
          // tooltipItem is an object here, because it is called for each dataset at the point on the axis
          labelColor: ( tooltipItem: TooltipItem<ChartType> /*, chart*/ ) => {
            return {
              borderColor: '#a09f9f',
              backgroundColor: MarketInfoUtil.getColor( ( tooltipItem.raw as number ) ),
            };
          },
          label: ( tooltipItem ) => {
            return tooltipItem.formattedValue;
          },
        },
      },
      title: {
        display: false,
        text: 'VIX',
        color: '#79afd7',
        font: {
          weight: 'bolder',
          size: this._state.chartTitleHeight,
        },

      },
      annotation: {
        // drawTime: 'afterDatasetDraw',
        annotations: {},
      },
      /*legend: {

       }*/

    },


  };
  vixChartType: ChartType = 'line';

  chartObject: any;

  // --------------------------------------------------------------------------------------------------------------

  historicalVixError: boolean = false;
  loadingHistoricalVix: boolean = true;

  readMore: boolean = false;

  deviceIsMobile: boolean = false;

  subscriberName: string = 'vixChartComponent';

  constructor( private _mdService: MarketDataService,
               private _pricingService: PricingService,
               private _state: GlobalState,
               public snackBar: MatSnackBar,
               private _detectorService: DeviceDetectorService,
               private _auth: Auth,
               public marketInfoUtil: MarketInfoUtil ) {

    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

    this.vixChartOptions.scales.x.ticks.maxTicksLimit = this.deviceIsMobile ? 5 : 10;


    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_vixChart' ] = this;
    }

    _state.subscribe( 'market.calc.settings.changed', ( settings ) => {
      this.marketInfoUtil.marketSettingsChanged( settings );
      this.getCurrentVix();
    }, this.subscriberName );

    Chart.register( Annotation );
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.getVixData();
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( [ 'market.calc.settings.changed' ].join( ' | ' ), this.subscriberName );
  }

  getVixData() {
    this.getHistoricalVix();
    this.getCurrentVix();
  }

  getCurrentVix() {
    this.marketInfoUtil.getCurrentVix().subscribe( {
      next: () => {
        this.changeSeriesRange();
      },
      error: ( err ) => {
        console.error( err );
      },
      complete: () => {
        this.changeSeriesRange();
      },
    } );
  }

  getHistoricalVix() {
    this.loadingHistoricalVix = true;
    this.historicalVixError = false;
    this._mdService.getHistoricalVix()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this.vixSeries = resp?.data?.series; // .slice( 0, 3750 );
          this.vixSeriesStats = resp?.data?.stats;
          this.currentDateRangeOption = this.deviceIsMobile ? this.dateRangeRadioOptions[ 3 ]?.value : this.dateRangeRadioOptions[ 6 ]?.value;
          this.loadingHistoricalVix = false;
          this.changeSeriesRange();
          // this.checkForBothDataPieces();
        }, error: ( err ) => {
          console.error( err );
          this.historicalVixError = true;
          this.loadingHistoricalVix = false;
        },
      } );
  }

  chartError( event ) {
    Logger.log( event );
  }

  changeSeriesRange() {
    if ( this.currentDateRangeOption ) {
      let timeUnit;
      switch ( this.currentDateRangeOption.unit ) {
        case 'D':
          this.daysBack = this.currentDateRangeOption.unitsBack;
          timeUnit = 'day';
          break;
        case 'W':
          this.daysBack = 7 * this.currentDateRangeOption.unitsBack;
          timeUnit = 'day';
          break;
        case 'M':
          this.daysBack = 20 * this.currentDateRangeOption.unitsBack;
          timeUnit = 'week';
          break;
        case 'Y':
          this.daysBack = 252 * this.currentDateRangeOption.unitsBack;
          timeUnit = 'year';
          break;
        default:
          this.daysBack = this.vixSeries.length - 1;
          timeUnit = 'year';
          break;

      }
      this.vixChartOptions.scales.x[ 'time' ].unit = timeUnit;
      this.setVixStats();
      this.setupVixChart();
    }
  }

  /*
   checkForBothDataPieces() {
   this.bothVixDataPiecesReturned = this.vixSeries?.length > 0 && this.currentVixQuote !== undefined;

   this.setVixStats();
   this.changeSeriesRange();
   // }
   }*/

  getGradientStroke: Function = ( context ) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if ( !chartArea ) {
      // This case happens on initial chart load
      return;
    }

    const gradientStroke = ctx.createLinearGradient( 0, chartArea.bottom, 0, chartArea.top );
    gradientStroke.addColorStop( .1, MarketInfoUtil.getColor( 10 ) );
    gradientStroke.addColorStop( .13, MarketInfoUtil.getColor( 13 ) );
    gradientStroke.addColorStop( .24, MarketInfoUtil.getColor( 24 ) );
    gradientStroke.addColorStop( .48, MarketInfoUtil.getColor( 48 ) );
    gradientStroke.addColorStop( 1, MarketInfoUtil.getColor( 1000 ) );
    return gradientStroke;
  };


  setupVixChart() {
    this.vixChartDataSets = [];
    this.vixChartLabels = [];
    // chartjs implemenation
    const vixSet: ChartDataset = {
      data: [],
      borderColor: ( context ) => {
        return this.getGradientStroke( context );
      },
      backgroundColor: ( context ) => {
        return this.getGradientStroke( context );
      },
      label: 'VIX',
      pointRadius: 0,
      pointHoverRadius: 1,
      // fill: '+1', // if you want to fill to the horizontal line
      fill: false,
      borderWidth: 2,
      tension: 0,
    };

    for ( let i = this.daysBack; i >= 0; i-- ) {
      vixSet.data.push( this.vixSeries[ i ].adjusted_close );
      this.vixChartLabels.push( moment( this.vixSeries[ i ].date ) ); //.format( 'DD/MM/YYYY' ) );

    }

    if ( this.marketInfoUtil.currentVixQuote?.price ) {
      vixSet.data.push( this.marketInfoUtil.currentVixQuote?.price );

      this.vixChartLabels.push( moment( this.today ) ); //.format( 'DD/MM/YYYY' ) );
    }

    this.vixChartDataSets = [ vixSet ];
  }

  calcRiskPremium( vix ) {
    return ( 7.2 / ( Math.pow( 17.2, 2 ) ) * Math.pow( vix, 2 ) );
  }

  actualHigh = 150.19;

  setVixStats() {
    // noinspection TypeScriptValidateJSTypes
    this.vixChartOptions.plugins.annotation.annotations[ 'high' ] = {
      type: 'line',
      scaleID: 'y',
      value: this.vixSeriesStats?.historical_high?.value,
      // yMax: this.vixSeriesStats?.historical_high?.value,
      borderColor: '#993333',
      borderWidth: 2,
      label: {
        display: true,
        position: 'start',
        yAdjust: -13,
        // enabled: true,
        color: 'white',
        backgroundColor: '#993333',
        content: `VIX®: ${ this.vixSeriesStats?.historical_high?.value } (${ moment( this.vixSeriesStats?.historical_high?.date ).format( 'MM/DD/YYYY' ) })`,
        font: {
          weight: 'bold',
        },
      },
    };
    this.vixChartOptions.plugins.annotation.annotations[ 'avg' ] = {
      type: 'line',
      scaleID: 'y',
      value: this.marketInfoUtil.longTermStandardDeviation,
      borderColor: '#ffa139',
      borderWidth: 3,
      label: {
        position: 'start',
        display: true,
        xAdjust: 0,
        yAdjust: 13,
        backgroundColor: '#ffa139',
        color: 'white',
        content: `Long-Term Vol: ${ this.marketInfoUtil.longTermStandardDeviation }`,
        font: {
          weight: 'bold',
        },
      },
    };
    this.vixChartOptions.plugins.annotation.annotations[ 'low' ] = {
      type: 'line',
      scaleID: 'y',
      value: this.vixSeriesStats?.historical_low?.value,
      borderColor: '#49a1e0',
      borderWidth: 3,
      label: {
        position: 'start',
        yAdjust: 13,
        color: 'white',
        backgroundColor: '#49a1e0',
        display: true,
        content: `VIX®: ${ this.vixSeriesStats?.historical_low?.value } (${ moment( this.vixSeriesStats?.historical_low?.date ).format( 'MM/DD/YYYY' ) })`,
        font: {
          weight: 'bold',
        },
      },
    };
    this.chartObject?.update();
  }

  onSelect( data ): void {
    Logger.log( 'Item clicked', JSON.parse( JSON.stringify( data ) ) );
  }

  onActivate( data ): void {
    Logger.log( 'Activate', JSON.parse( JSON.stringify( data ) ) );
  }

  onDeactivate( data ): void {
    Logger.log( 'Deactivate', JSON.parse( JSON.stringify( data ) ) );
  }

  openVixLink() {
    Util.openExternalUrl( 'https://www.investopedia.com/terms/v/vix.asp' );
  }

  openCBOELink() {
    Util.openExternalUrl( 'https://www.cboe.com/tradable_products/vix/vix_historical_data/' );
  }
}
