// this file can be loaded into all other environment files for common environment variables

export const commonEnvironment = {
  defaultProfilePicUrl: `assets/img/app/profile/hero-profile-${ Math.floor( Math.random() * 10 ) }.png`,
  // ripsaw logos:
  // logoUrl: 'assets/img/RIPSAW_RECTANGLE_LOGO_WHITE.png',
  // smallLogoUrl: 'assets/img/RIPSAW_LARGE_SQUARE_WHITE_LOGO.png',
  // blueLogoUrl: 'assets/img/RIPSAW_RECTANGLE_LOGO_BLUE.png',
  // smallLogoUrlBlue: 'assets/img/RIPSAW_MODERN_SQUARE_BLUE.png',

  // wealth fluent logos:
  logoUrl: 'assets/img/Solid White Horiz 2@2x.png',
  smallLogoUrl: 'assets/img/Solid White Mark@2x.png',
  blueLogoUrl: 'assets/img/wealthFluent_Logo_r4_WF_Horiz_alt2.svg',
  smallLogoUrlBlue: 'assets/img/wealthFluent_Logo_r4_WF_Horiz_alt1.svg',

  yodleeLogoUrl: 'assets/img/yodlee.png',
  alphaVantageKey: '2C8UY8GZ6Q973RRB',
  zillowLogoUrl: 'assets/img/Zillowlogo.gif', // 'https://www.zillow.com/widgets/GetVersionedResource.htm?path=/static/logos/Zillowlogo_200x50.gif',
  stockdioAppkey: 'F9CB3010C63C441EBE8EAD959FBE5FF7',
  rssAppId: '_7fBIBBEQjK4XARP4',
  googleApiKey: 'AIzaSyCR04iJvRnU61pixzEl_0guvZxBHc7w47s',
  companyName: 'Ripsaw LLC',
  website: 'https://www.wealthfluent.com',
  websiteText: 'wealthfluent.com',
};
