import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Util } from '../utils/util.service';
import { UserGoal } from '../utils/dataInterfaces';
import { InvestorGoal } from '@ripsawllc/ripsaw-analyzer';

@Injectable()
export class GoalsService {

  lambdaGoalsUrl: string;

  baseApiPath: string;

  constructor( private _http: HttpClient ) {
    this.baseApiPath = environment.ripsawAPIBaseUrl;
    this.lambdaGoalsUrl = `${ this.baseApiPath }/workspace-goals`;
  }

  createGoal( goal: UserGoal ) {
    goal.include = true;
    const url = `${ this.lambdaGoalsUrl }/create`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.post( url, goal, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getGoal( id: string ) {
    const url = `${ this.lambdaGoalsUrl }/get/${ id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  getAllGoals() {
    const url = `${ this.lambdaGoalsUrl }/get`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.get( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  updateGoal( goal: InvestorGoal ) {
    const url = `${ this.lambdaGoalsUrl }/update`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.put( url, goal, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

  deleteGoal( id: string ) {
    const url = `${ this.lambdaGoalsUrl }/delete/${ id }`;
    const headers: HttpHeaders = new HttpHeaders( {
      'Content-Type': 'application/json',
    } );
    return this._http.delete( url, { headers } )
      .pipe(
        map( Util.extractData ),
        catchError( Util.handleError ),
      );
  }

}
