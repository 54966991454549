import { Component } from '@angular/core';

@Component( {
  selector: 'app-benchmark-setup',
  templateUrl: './benchmark-setup.component.html',
  styleUrls: [ './benchmark-setup.component.scss' ],
} )
export class BenchmarkSetupComponent {

  constructor() {
  }

}
