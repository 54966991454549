import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromoCodeService } from '../../../../globalData';
import { ExistingManualAccountForm } from '../../../../utils/dataInterfaces';
import { Account } from '@ripsawllc/ripsaw-analyzer';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { AdvisorFeeEditorComponent } from '../../../modals/aggConnectionManager/advisorFeeEditor';
import { NicknameEditorComponent } from '../../../modals/aggConnectionManager/nicknameEditor';
import { Util } from '../../../../utils/util.service';
import { AccountManager } from '../../../../utils/accountManager';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalState } from '../../../../global.state';
import { UntypedFormGroup } from '@angular/forms';
import { RipThemeLoadingSpinnerService } from '../../../../theme/services';
import { ManualAccountErrorStateMatcher, ManualAccountManagerState, } from '../../../../utils/manualAccountManager.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../utils/mobileUtil.service';
import { environment } from '../../../../../environments/environment';
import { FastLinkUtil } from '../../../../utils/fastLink.util';
import { ManualAccountUtil } from '../../../../utils/manualAccount.util';
import { MatPseudoCheckboxState } from '@angular/material/core';
import { EVENT_NAMES } from '../../../../utils/enums';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../../shared/workspace-permission';
import { AppStoreService } from '../../../../store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component( {
  selector: 'rip-account-settings-dialog',
  template: `
    <h3 mat-dialog-title>{{ account.isManual ? 'Manual' : 'Connected' }} Account Settings</h3>
    <mat-divider class="modal-header-divider"></mat-divider>
    <div mat-dialog-content id="account-settings-dialog-content" class="{{ deviceIsMobile ? 'mobile' : 'desktop' }}">
      <div style="display: inline; width: 100%;">
        <div style="margin: 10px;">
          <p>Account Value: {{ account.value | ripCurrencyPipe }}</p>

          <ng-container *ripPerm="[perm.OWNER, perm.ACCOUNT_SETTINGS_VIEW, perm.ACCOUNT_SETTINGS_EDIT]">
            <ng-container *ngIf="{isDisabled: accountSettingIsDisabled$ | async} as settingState">
              <span>Include Account: </span>
              <mat-pseudo-checkbox (click)="includeAccount(settingState.isDisabled)"
                                   class="account-include-box"
                                   [disabled]="settingState.isDisabled"
                                   [state]="accountIncluded">

              </mat-pseudo-checkbox>
            </ng-container>
          </ng-container>

          <span *ngIf="account.status && account.status !== 'SUCCESS' && account.statusIndicator">
              <fa-icon [icon]="account.statusIndicator.icon" size="sm" [fixedWidth]="true"
                       class="status-icon"
                       (click)="$event.stopPropagation(); openFastLinkForRepairingAccount(account)"
                       matTooltip="{{ account.statusIndicator.msg}}"
                       matTooltipClass="mat-tooltip-custom"
                       matTooltipPosition="right"
                       [style.color]="account.statusIndicator.color"></fa-icon>
          </span>

        </div>
        <!-- MANUAL ACCOUNT -->
        <div style="margin: 10px;" *ngIf="account.isManual">
          <rip-account-short-form [account]="account" [form]="form" [matcher]="matcher" *ngIf="showShortForm">
          </rip-account-short-form>
          <rip-account-fees-editor [account]="account" [accountForm]="form"></rip-account-fees-editor>

          <rip-account-full-form [account]="account"
                                 [form]="form"
                                 [matcher]="matcher"
                                 [dialog]="this.dialogRef"
                                 [existingManualForm]="existingManualAccountForm"
                                 [positionToEdit]="data.position"
                                 (autoSave)="updateManualAccount()"
                                 *ngIf="!showShortForm">

          </rip-account-full-form>
        </div>
        <!-- END MANUAL ACCOUNT SECTION -->

        <!-- CONNECTED ACCOUNT -->
        <div style="margin: 10px;" [style.display]="!account.isManual ? 'block' : 'none'">
          <div>
            <rip-nickname-editor #nicknameEditor [account]="account">

            </rip-nickname-editor>
          </div>
          <div>
            <rip-advisor-fee-editor #feeEditor [account]="account">
            </rip-advisor-fee-editor>
          </div>
        </div>
        <!--<span
          class="account-value pull-right {{nicknameEditor.editing ? 'account-value-edit' : '' }}">
             {{account.value | ripCurrencyPipe }}
           </span>-->
      </div>
    </div>
    <mat-divider class="modal-footer-divider"></mat-divider>
    <div mat-dialog-actions>
      <button mat-button (click)="cancel()">Close</button>

      <!-- Connected Account Actions -->
      <ng-container *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]">
        <button mat-button class="edit-button"
                (click)="editConnectedAccount(feeEditor, nicknameEditor)"
                matTooltip="Edit Account"
                matTooltipClass="mat-tooltip-custom"
                *ngIf="!nicknameEditor.editing && !account.isManual">
          Edit
        </button>
      </ng-container>
      <button mat-button class="nickname-cancel-edit-button"
              (click)="cancelChanges(feeEditor, nicknameEditor)"
              matTooltip="Cancel Editing"
              matTooltipClass="mat-tooltip-custom"
              *ngIf="nicknameEditor.editing">
        Cancel
      </button>
      <button mat-button matPrefix class="save-button" (click)="updateConnectedAccount(feeEditor, nicknameEditor)"
              matTooltip="Save Account Changes"
              matTooltipClass="mat-tooltip-custom"
              *ngIf="nicknameEditor.editing">
        Save
      </button>
      <!---------------------------------->
      <!-- Manual account actions -->
      <ng-container *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]">
        <button mat-button matPrefix class="save-button" (click)="updateManualAccount()"
                matTooltip="Save Account Changes"
                matTooltipClass="mat-tooltip-custom"
                *ngIf="account.isManual">
          Save
        </button>
      </ng-container>
      <!---------------------------------->

      <button mat-button matPrefix (click)="toggleShortFormLongForm()"
              matTooltip="Show {{ showShortForm ? 'full' : 'condensed' }} version of this form"
              matTooltipClass="mat-tooltip-custom"
              *ngIf="account.isManual && canShowFullForm">
        Show {{ showShortForm ? 'Full' : 'Condensed' }} Form
      </button>
    </div>
    <div id="{{spinnerSelector}}" class="spinner" style="display: none;">
      <div></div>
    </div>
  `,
  styleUrls: [ `./accountSettingsDialog.component.scss` ],
} )

export class AccountSettingsDialogComponent extends WithWorkspacePermission implements OnInit, OnDestroy {

  faEdit = faEdit;

  account: Account;

  checked: MatPseudoCheckboxState = 'checked';
  unchecked: MatPseudoCheckboxState = 'unchecked';

  accountIncluded: MatPseudoCheckboxState = 'checked';
  form: UntypedFormGroup;
  matcher: ManualAccountErrorStateMatcher = new ManualAccountErrorStateMatcher();
  existingManualAccountForm: ExistingManualAccountForm;

  showShortForm: boolean = true;
  canShowFullForm: boolean = true;

  accountTypesThatCannotShowFullFormOnMobile: string[] = [ 'Investment' ];

  spinnerSelector: string = 'includingAccountSpinner';

  subName = '';

  deviceIsMobile: boolean = false;
  accountSettingIsDisabled$: Observable<boolean>;

  constructor( public dialogRef: MatDialogRef<AccountSettingsDialogComponent>,
               private _accountManager: AccountManager,
               private _state: GlobalState,
               public snackBar: MatSnackBar,
               private _spinnerService: RipThemeLoadingSpinnerService,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               private _detectorService: DeviceDetectorService,
               private fastLinkUtil: FastLinkUtil,
               private mamState: ManualAccountManagerState,
               private _promoCodeService: PromoCodeService,
               private appStoreService: AppStoreService,
  ) {
    super();

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_accountSettingDialog' ] = this;
    }
    this.deviceIsMobile = MobileUtil.deviceIsMobile( _detectorService );

    this.account = data.account;

    if ( this.deviceIsMobile && this.accountTypesThatCannotShowFullFormOnMobile.includes( this.account.account_type ) ) {
      this.canShowFullForm = false;
    }

    this.accountIncluded = this.isAccountIncluded();

    if ( this.account.isManual ) {
      this.existingManualAccountForm = this.mamState.existingAccountForms[ this.account.account_id ];
      // need this line so the form passed into the shortForm component will be patched before render
      ManualAccountUtil.patchForm( this.existingManualAccountForm, this.account );

      this.form = this.existingManualAccountForm.form;
    }

    this.subName = `accountSettingDialog-${ this.account.account_id }`;

    if ( data.showFullForm ) {
      this.toggleShortFormLongForm();
    }
  }

  ngOnInit(): void {
    this.accountSettingIsDisabled$ = this.initAccountSettingIsDisabledObs();
    this.restrictFormByPermission();
  }

  ngOnDestroy(): void {
    if ( this.form.disabled ) {
      this.form.enable();
    }
  }

  cancel() {
    this.dialogRef.close( false );
  }

  editConnectedAccount( advisorFeeEditor: AdvisorFeeEditorComponent, nicknameEditor: NicknameEditorComponent ) {
    advisorFeeEditor.edit();
    nicknameEditor.edit();
  }

  updateConnectedAccount( advisorFeeEditor: AdvisorFeeEditorComponent, nicknameEditor: NicknameEditorComponent ) {
    advisorFeeEditor.updateFee();
    nicknameEditor.updateNickname();
    this._state.notifyDataChanged( 'account.settings.updated', {} );
  }

  cancelChanges( advisorFeeEditor: AdvisorFeeEditorComponent, nicknameEditor: NicknameEditorComponent ) {
    advisorFeeEditor.reset();
    nicknameEditor.cancelUpdateNickname();
  }

  includeAccount( isDisabled: boolean ): void {
    if ( isDisabled ) {
      return;
    }

    this._spinnerService.show( this.spinnerSelector );
    if ( this.account.isManual ) {
      const manualAccount = this._state.globalVars.allManualAccountsFromDB.find( ( a: Account ) => {
        return a.account_id === this.account.account_id;
      } );
      const form: UntypedFormGroup = this.mamState.existingAccountForms[ this.account.account_id ].form;
      form.controls.included.setValue( !form.controls.included.value );

      this.mamState.toggleIncluded( manualAccount, form, () => {
        this.accountIncluded = this.isAccountIncluded();
        this._spinnerService.hide( 0, this.spinnerSelector );
      } );
    } else {
      this._accountManager.includeAccount( this.account, ( include ) => {
        this.snackBar.open( `Account ${ include ? 'Included' : 'Hidden' } Successfully`, null, Util.getSnackBarOptions() );

        this.accountIncluded = this.isAccountIncluded();
        this._spinnerService.hide( 0, this.spinnerSelector );
      } );
    }
  }

  isAccountIncluded(): MatPseudoCheckboxState {
    if ( this.account.isManual ) {
      const manualAccount = this._state.globalVars.allManualAccountsFromDB.find( ( a: Account ) => {
        return a.account_id === this.account.account_id;
      } );
      return manualAccount.included ? this.checked : this.unchecked;
    } else {
      const mapping = this._state.globalVars.accountMapping.mapping;
      if ( mapping ) {
        const mapConnection = mapping[ this.account.connection_id ];
        if ( mapConnection ) {
          const mapAccount = mapConnection[ this.account.account_id ];
          if ( mapAccount ) {
            return mapAccount.include ? this.checked : this.unchecked;
          }
        }
      }
    }
    // in case the account or connection isn't in the map, include the account
    return this.checked;

  }

  updateManualAccount() {
    this._spinnerService.show( this.spinnerSelector );
    const manualAccount = this._state.globalVars.allManualAccountsFromDB.find( ( a: Account ) => {
      return a.account_id === this.account.account_id;
    } );

    this.mamState.updateManualAccount( manualAccount, this.form, () => {
      this._spinnerService.hide( 0, this.spinnerSelector );
      this._state.notifyDataChanged( 'account.settings.updated', {} );
    } );

  }

  toggleShortFormLongForm() {
    this.showShortForm = !this.showShortForm;
  }

  openFastLinkForRepairingAccount( account: Account ) {
    if ( this.deviceIsMobile ) {
      this._state.notifyDataChanged( EVENT_NAMES.OPEN_MOBILE_FASTLINK, account.connection_id );
    } else {
      this.fastLinkUtil.openFastlinkDialog( account.connection_id );
    }
    this.dialogRef.close( false );
  }

  private initAccountSettingIsDisabledObs(): Observable<boolean> {
    return this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        map( allPerms => hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNT_SETTINGS_VIEW ] ) ),
      );
  }

  private restrictFormByPermission(): void {
    this.appStoreService.loadedWorkspacePermissions$
      .pipe( take( 1 ) )
      .subscribe( allPerms => {
        if ( hasAnyPermissionFn( allPerms, [ this.perm.ACCOUNTS_VIEW ] ) ) {
          this.form.disable();
        }
      } );
  }
}
