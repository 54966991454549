import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { GlobalState } from '../../../../../../../global.state';
import { Auth } from '../../../../../../../auth.service';
import { Util } from '../../../../../../../utils/util.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../../environments/environment';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserCog } from '@fortawesome/pro-light-svg-icons/faUserCog';
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons/faSignOutAlt';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faUserVisor } from '@fortawesome/pro-light-svg-icons/faUserVisor';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons/faUserRobot';
import { faUserNinja } from '@fortawesome/pro-light-svg-icons/faUserNinja';
import { faUserCowboy } from '@fortawesome/pro-light-svg-icons/faUserCowboy';
import { faUserCrown } from '@fortawesome/pro-light-svg-icons/faUserCrown';
import { faUserAstronaut } from '@fortawesome/pro-light-svg-icons/faUserAstronaut';
import { faUserAlien } from '@fortawesome/pro-light-svg-icons/faUserAlien';
import { faUserHardHat } from '@fortawesome/pro-light-svg-icons/faUserHardHat';
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../../../../../../../utils/mobileUtil.service';
import { NotificationsComponent } from './components/notifications';
import { NotificationsUtil } from '../../../../../../../utils/notifications.util';
import { Notification } from '../../../../../../../utils/dataInterfaces';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component( {
  selector: 'rip-user-menu',
  templateUrl: './userMenu.component.html',
  styleUrls: [ './userMenu.scss', '../../../../baSidebar.scss' ],
} )

export class UserMenuComponent implements OnDestroy, AfterViewInit {

  @Input() sidebarCollapsed: boolean = true;
  @Output() itemHover: EventEmitter<any> = new EventEmitter<any>();

  subscriberName: string = 'UserMenuComponent';

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      'notifications.updated',
    ].join( ' | ' ), this.subscriberName );
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      this._state.subscribe( 'notifications.updated', ( notifications: Notification[] ) => {
        this.notifications = notifications;
      }, this.subscriberName );

      if ( this.notifications.length === 0 ) {
        this.notifications = this.notificationsUtil.notifications;
      }
    } );
  }

  faUserCog: IconDefinition = faUserCog;
  faSignOutAlt: IconDefinition = faSignOutAlt;
  faBell: IconDefinition = faBell;

  userIcons: IconDefinition[] = [
    faUser,
    faUserVisor,
    faUserRobot,
    faUserNinja,
    faUserCowboy,
    faUserCrown,
    faUserAstronaut,
    faUserAlien,
    faUserHardHat,
    faUserTie,
  ];
  userIcon: IconDefinition;

  profileUrl: string;

  notifications: Notification[] = [];

  constructor( private _state: GlobalState,
               private _auth: Auth,
               private router: Router,
               private dialog: MatDialog,
               private notificationsUtil: NotificationsUtil,
               private _detectorService: DeviceDetectorService ) {

    if ( environment.env !== 'prod' ) {
      window['ripsaw_userMenu'] = this;
    }

    const user = Util.getLoggedInUser( this._auth );
    this.profileUrl = user.profilePicUrl;
    if ( !this.profileUrl ) {
      this.profileUrl = environment.common.defaultProfilePicUrl;
    }

    const randomIndex = Math.floor( Math.random() * this.userIcons.length );
    this.userIcon = this.userIcons[randomIndex];
  }

  onHoverItem( $event ): void {
    this.itemHover.emit( $event );
  }

  goToProfile() {
    // console.log( 'notifying content top of page change' );
    this._state.globalVars.page = null;
    this.router.navigate( [ 'pages/profile' ] ).catch( ( err ) => {
      console.error( err );
    } );
  }

  authenticated() {
    return this._auth.authenticated();
  }

  signout() {
    this._auth.logout();
  }

  openNotificationsModal( notification?: Notification ) {
    if ( this.notifications && this.notifications.length > 0 ) {
      this.dialog.open( NotificationsComponent, {
        data: {
          notification,
        },
      } );
    }
  }

  getNotificationIcon( type: string ) {
    return NotificationsComponent.getIcon( type );
  }

  getUnreadNotificationsCount() {
    return this.getUnreadNotifications().length || 0;
  }

  getUnreadNotifications() {
    return ( this.notifications || [] ).filter( ( n: Notification ) => {
      return !n.read;
    } );
  }

  deviceIsDesktop() {
    return MobileUtil.deviceIsDesktop( this._detectorService );
  }

  deviceIsMobile() {
    return MobileUtil.deviceIsMobile( this._detectorService );
  }

}
