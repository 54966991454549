<ul class="al-sidebar-list" ngaBaSlimScroll>
  <!--  <li class="al-sidebar-list-item">-->
  <!--    <div class="al-sidebar-list-header" [ngClass]=" { minimized: sidebarCollapsed }">-->
  <!--      Account Management-->
  <!--    </div>-->
  <!--  </li>-->
  <li class="al-sidebar-list-item" id="account-management-sidebar-menu-item">
    <a class="al-sidebar-list-link" (click)="openAccountManagement()" (mouseenter)="onHoverItem($event)"
       [ngClass]="{ 'disabled-menu-item': editing }"
       matTooltip="{{ editing ? 'Cannot Add Accounts While In A Revision' : 'Add/Manage Accounts' }}"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">

      <fa-layers [fixedWidth]="true" size="2x">
        <fa-stack>
          <fa-icon [icon]="faUniversity" stackItemSize="2x" style="font-size: 0.3rem;" [fixedWidth]="true"></fa-icon>
          <fa-icon [icon]="faPlus" stackItemSize="2x" class="icon-subscript-left" ></fa-icon>
          <fa-icon [icon]="faCog" stackItemSize="2x" class="icon-subscript-right with-subscript-left"></fa-icon>
        </fa-stack>
      </fa-layers>

      <span translate>Add/Manage Accounts</span>
    </a>
  </li>

</ul>

