import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { WorkspaceLoadDialogService } from '../../../../../../../shared/workspace-load-dialog';
import { AppStoreService } from '../../../../../../../store';
import { LoadWorkspaceEvent } from './workspace-menu/workspace-menu.component';
import { GlobalState } from '../../../../../../../global.state';
import { ConfirmationComponent } from '../../../../../../../pages/accounts/confirmation-dialog.component';
import { CanDeactivateState } from '../../../../../../../pages/accounts/can-deactivate.guard';
import { MatDialog } from '@angular/material/dialog';
import { AccountManager } from '../../../../../../../utils/accountManager';
import { FEATURE_NAMES, PAGE_NAMES } from '../../../../../../../utils/enums';
import { SubscriptionDetailsUtil } from '../../../../../../../utils/subscriptionDetails.util';
import { AlertModalService } from '../../../../../../../reusableWidgets/alertModal';
import { environment } from '../../../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { WorkspaceLoadedStore } from '../../../../../../../store/workspace';
import { CrossAppMessageType, DialogName } from '@ripsawllc/ripsaw-analyzer';
import { WealthFluentMessageService } from '../../../../../../services/wealthFluentMessage';

@Component( {
  selector: 'rip-sidebar-workspace',
  templateUrl: './sidebar-workspace.component.html',
  styleUrls: [ './sidebar-workspace.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class SidebarWorkspaceComponent {
  @Input() sidebarCollapsed: boolean;

  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  loadedWorkspace$: Observable<WorkspaceLoadedStore> = this.appStoreService.loadedWorkspace$;
  isRefreshing$: Observable<boolean> = this.appStoreService.isRefreshingWorkspaces$;
  menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 2,
    },
  ];

  appName: string = environment.appName;

  constructor(
    private workspaceLoadDialogService: WorkspaceLoadDialogService,
    private appStoreService: AppStoreService,
    private _state: GlobalState,
    private _accountManager: AccountManager,
    public matDialog: MatDialog,
    private _subscriptionDetailsUtil: SubscriptionDetailsUtil,
    private _alertModalService: AlertModalService,
  ) {
  }

  loadWorkspace( { type, workspace }: LoadWorkspaceEvent ): void {
    if ( this._state.globalVars.unsavedChanges ) {
      this.matDialog.open<ConfirmationComponent, void, boolean>( ConfirmationComponent, {
        disableClose: true,
      } ).afterClosed().subscribe(
        {
          next: ( confirmed => {
            if ( !confirmed && CanDeactivateState.defendAgainstBrowserBackButton ) {
              history.pushState( null, '', '' );
            }
            if ( confirmed ) {
              this._accountManager.toggleEditingAndResetAccounts();
              if ( this._state.featureIsOn( FEATURE_NAMES.WORKSPACES ) ) {
                this.workspaceLoadDialogService.open( { workspace } )
                  .subscribe( () => this.appStoreService.loadWorkspace( workspace, type ) );
              } else {
                // getting here means they want to leave their revision but they don't have the workspaces feature in their tier
                this.upsell();
              }
            } else {
              // getting here means they don't want to leave their current unsaved revision
              return;
            }
          } ),
        },
      );
    } else if ( this._state.featureIsOn( FEATURE_NAMES.WORKSPACES ) ) {
      this.workspaceLoadDialogService.open( { workspace } )
        .subscribe( () => this.appStoreService.loadWorkspace( workspace, type ) );
    } else {
      // getting here means they weren't in a revision and they don't have workspaces
      this.upsell();
    }
  }

  refresh( evt: MouseEvent ): void {
    evt.stopPropagation();
    evt.preventDefault();
    this.appStoreService.updateIsRefreshingWorkspaces( true, [ 'my', 'shared' ] );
  }

  upsell() {
    this.wealthFluentMessageService.messageWealthFluent( {
      type: CrossAppMessageType.openDialog,
      data: {
        dialogName: DialogName.UPSELL,
        featureName: FEATURE_NAMES.WORKSPACES,
      },
      message: 'Open the upsell dialog',
    } );
  }


  protected readonly PAGE_NAMES = PAGE_NAMES;
}
