import { Component, Input } from '@angular/core';
import { GlobalOverrideItem, LocalOverrideItem } from '../../../../utils/dataInterfaces';


@Component( {
  selector: 'rip-override-display',
  template: `
    <mat-vertical-stepper>
      <!-- Overriding the icons so there aren't numbers which would normally be used to denote a flow -->
      <ng-template matStepperIcon="done">
        <mat-icon>dehaze</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="edit">
        <mat-icon>dehaze</mat-icon>
      </ng-template>
      <!-- number is the active step icon type -->
      <ng-template matStepperIcon="number">
        <mat-icon>visibility</mat-icon>
      </ng-template>

      <!-- LOCAL OVERRIDE ITEM -->
      <mat-step *ngIf="localItem" [completed]="true">
        <ng-template matStepLabel>
          {{ localItem.localKeySet.label }}
        </ng-template>
        <div class="row">
          <div *ngFor="let key of localItem.localKeySet.keys" class="col override-display-field-container">
            <div>
              <ng-container
                *ngTemplateOutlet="individualOverrideTemplate; context:{'key': key, 'override': 
                key.key === 'price' ? 
                { old: localItem?.position?.overridden_price, new: localItem?.position?.price } :
                 key.key === 'quantity' ?
                 { old: localItem?.position?.overridden_quantity, new: localItem?.position?.quantity } :
                  localItem?.overrides[key.key] }">
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let group of localItem.localKeySet.groups" class="col-12 override-group-container">
            <div class="group-header" *ngIf="group.keys.length > 0">
                   <span [ngClass]="{ 'missing-data': group.groupHasMissingData }"> 
                     {{ group.label }}
                  </span>
            </div>
            <div class="row override-keys-container" *ngIf="group.keys.length > 0">
              <div *ngFor="let key of group.keys" class="col-2 override-display-field-container">
                <ng-container
                  *ngTemplateOutlet="individualOverrideTemplate; context:{'key': key, 'override': localItem?.overrides[key.key] }">
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
      <!------------------------------------------------------------------------------------------------------------->
      <!-- GLOBAL OVERRIDE ITEM -->
      <mat-step *ngFor="let set of globalItem?.globalKeySets" [completed]="true">
        <ng-template matStepLabel>
          {{ set.label }}
        </ng-template>
        <div class="row">
          <div *ngFor="let key of set.keys" class="col override-display-field-container">
            <div *ngIf=" key?.key !== 'price' && key?.key !== 'quantity' && key?.key !== 'value'">
              <ng-container
                *ngTemplateOutlet="individualOverrideTemplate; context:{'key': key, 'override': globalItem?.override?.overrides[key.key] }">
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let group of set.groups" class="col-12 override-group-container">
            <div class="group-header" *ngIf="group.keys.length > 0">
                   <span [ngClass]="{ 'missing-data': group.groupHasMissingData }"> 
                     {{ group.label }}
                  </span>
            </div>
            <div class="row override-keys-container" *ngIf="group.keys.length > 0">
              <div *ngFor="let key of group.keys" class="col override-display-field-container">
                <ng-container
                  *ngTemplateOutlet="individualOverrideTemplate; context:{'key': key, 'override': globalItem?.override?.overrides[key.key] }">
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </mat-step>

    </mat-vertical-stepper>

    <ng-template #individualOverrideTemplate let-key="key" let-override="override">
      <div [ngClass]="{'missing-data': key.fieldHasMissingData, 'loan-field-hint': key.isLoanField }"
           class="override-key-container override-display-field-container">
        <div class="override-label" [ngClass]="{
        'override-in-place': override?.new,
        'original-value': !override?.new,
        'missing-data': key.fieldHasMissingData
        }">
          {{ key.colDef?.name }}
        </div>
        <!-- <span *ngIf="!override?.old && override?.new">
             none |
         </span>-->
        <!-- for decimal fields -->
        <span #decimalField id="decimal_{{key.key}}" *ngIf="key.inputType === 'decimal'" class="override-value">
          <div *ngIf="override?.new">
            {{ override?.new | ripDecimalPipe : '2-2' }}
          </div>
          <mat-hint *ngIf="override?.old">
            Original Value: {{ override?.old | ripDecimalPipe : '2-2' }}
          </mat-hint>
        </span>
        <!-- for percent fields -->
        <span #percentField id="percent_{{key.key}}" *ngIf="key.inputType === 'percent'" class="override-value">
          <div *ngIf="override?.new">
            {{ override?.new | ripLongPercentPipe }}
          </div>
          <mat-hint *ngIf="override?.old">
            Original Value: {{ override?.old | ripLongPercentPipe }}
          </mat-hint>
        </span>
        <!-- for currency fields -->
        <span #currencyField id="currency_{{key.key}}" *ngIf="key.inputType === 'currency'" class="override-value">
          <div *ngIf="override?.new">
            {{ override?.new | ripCurrencyPipe }}
          </div>
          <mat-hint *ngIf="override?.old">
            Original Value: {{ override?.old | ripCurrencyPipe }}
          </mat-hint>
        </span>
        <!-- for text fields -->
        <span #textField *ngIf="key.inputType === 'string'" class="override-value">
          <div *ngIf="override?.new">
            {{ override?.new }}
          </div>
          <mat-hint *ngIf="override?.old">
            Original Value: {{ override?.old }}
          </mat-hint>
        </span>
        <!-- for select fields -->
        <span *ngIf="key.inputType === 'select'" class="override-value">
          <div *ngIf="override?.new">
            {{ override?.new }}
          </div>
            <mat-hint *ngIf="override?.old">
            Original Value: {{ override?.old }}
          </mat-hint>
        </span>
        <!-- for date inputs -->
        <span *ngIf="key.inputType === 'date'" #dateField id="date_{{key.key}}" class="override-value">
          <div *ngIf="override?.new">
            {{ override?.new | ripDatePipe }}
          </div>
          <mat-hint *ngIf="override?.old">
            Original Value: {{ override?.old | ripDatePipe }}
          </mat-hint>
        </span>

        <!--        <span *ngIf="!override?.new"> none </span>-->
        <!-- Using hint for showing override -->
        <!--<mat-hint *ngIf="key.fieldHasOverride">
            Original value: {{ key.formattedOldOverrideValue }}
            <mat-icon (click)="removeSingleOverrideAndSave( key.key )" class="remove-override-button"
                      title="Remove Override">
                delete
            </mat-icon>
        </mat-hint>-->
      </div>
    </ng-template>
  `,
  styleUrls: [ './override-display.component.scss' ],
} )

export class OverrideDisplayComponent {

  @Input() globalItem: GlobalOverrideItem;
  @Input() localItem: LocalOverrideItem;

}
