import { Injectable, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ProfileService } from '../pages/profile/profile.service';
import { Subject } from 'rxjs';
import { Benchmark, HouseholdMember, TaskManagerTask, User } from './dataInterfaces';
import moment from 'moment/moment';
import { Util } from './util.service';
import { Auth } from '../auth.service';
import { environment } from '../../environments/environment';
import { GlobalState } from '../global.state';
import { EVENT_NAMES } from './enums';
import { AppStoreService } from '../store';
import { Logger } from './logger.service';

@Injectable( {
  providedIn: 'root',
} )
export class HouseholdMembersState implements OnDestroy {

  private readonly onDestroy: Subject<void> = new Subject<void>();
  membersLoaded: Subject<boolean> = new Subject<boolean>();
  tasksLoaded: Subject<boolean> = new Subject<boolean>();

  householdMembers: HouseholdMember[];
  tasksRemaining: boolean;

  loading: boolean = true;
  getAllError: boolean = false;

  user: User;

  subscriberName: string = 'HouseholdMembersState';

  investor: HouseholdMember;
  hasBeenCalledOnce: boolean = false;

  constructor(
    private _profileService: ProfileService,
    private _auth: Auth,
    private _state: GlobalState,
    private appStoreService: AppStoreService,
  ) {
    if ( this._auth.authenticated() ) {
      Logger.log( `User is logged in, get their household members` );
      this.getHouseholdMembersByLoadedWorkspace();
    } else {
      Logger.log( `User is NOT logged in, don't need to get their household members` );
    }

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_householdMemberState' ] = this;
    }
    // need to subscribe to log in even if not authenticated when the state first loads
    _state.subscribe( EVENT_NAMES.LOGOUT, () => {
      this.resetAfterAuthChange();
    }, this.subscriberName );

    // subscribe to log in event, so we can grab members after a user logs in
    _state.subscribe( [ EVENT_NAMES.LOGGED_IN, EVENT_NAMES.REGISTRATION_COMPLETED ].join( ' | ' ), () => {
      this.getHouseholdMembersByLoadedWorkspace();
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.USER_BENCHMARK_LOADED, () => {
      this.createMemberFromWorkspaceInvestor();
      if ( !this.hasBeenCalledOnce ) {
        this.getHouseholdMembersByLoadedWorkspace();
      }
    }, this.subscriberName );

  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.LOGOUT,
      EVENT_NAMES.LOGGED_IN,
      EVENT_NAMES.REGISTRATION_COMPLETED,
      EVENT_NAMES.USER_BENCHMARK_LOADED,
    ].join( ' | ' ), this.subscriberName );
  }

  setupData(): void {
    this.user = Util.getLoggedInUser( this._auth );
    this.getHouseholdMembers();
  }

  resetAfterAuthChange(): void {
    this.householdMembers = undefined;
    this.loading = true;
    this.getAllError = false;
    this.user = undefined;
  }

  createMemberFromWorkspaceInvestor(): void {
    const workspaceBenchmark: Benchmark = this._state.globalVars.benchmark;
    this.investor = {
      birth_date: workspaceBenchmark?.birth_date,
      endOfPortfolioLife: workspaceBenchmark?.endOfPortfolioLife,
      id: String( workspaceBenchmark?.workspace_id ),
      married: workspaceBenchmark?.married,
      name: workspaceBenchmark?.investor_name ?? this.user?.name ?? '',
      relation: 'Self',
      retired: workspaceBenchmark.retired,
      shiftToWithdrawals: workspaceBenchmark.shiftToWithdrawals,
    };

  }

  getHouseholdMembers(): void {

    this._profileService.getHouseholdMembers()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          this.householdMembers = resp.data;
          this.sortMembersByAge();
          this.loading = false;
          this.getAllError = false;
          this.membersLoaded.next( true );
        }, error: ( err ) => {
          this.membersLoaded.error( err );
          console.error( err );
          this.loading = false;
          this.getAllError = true;
        },
      } );

    this._profileService.getHouseholdMemberTasks()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( resp: any ) => {
          const tasks: TaskManagerTask[] = resp.data.filter( ( task: TaskManagerTask ) => task.status !== 'completed' );
          this.tasksRemaining = tasks.length > 0 && tasks?.length !== resp.data?.length;
          this.tasksLoaded.next( true );
        },
        error: ( err ) => {
          Logger.error( err );
          this.tasksLoaded.error( err );
        },
      } );
  }

  handleNewMember( newMember: HouseholdMember ): void {
    this.householdMembers.push( newMember );
    this.sortMembersByAge();
  }

  handleUpdatedMember( updatedMember: HouseholdMember ): void {
    const index: number = this.getMemberIndexById( updatedMember.id );
    if ( index >= 0 ) {
      this.householdMembers[ index ] = updatedMember;
    }
  }

  sortMembersByAge(): void {
    this.householdMembers.sort( ( a: HouseholdMember, b: HouseholdMember ) => {
      return moment( a.birth_date ).isAfter( moment( b.birth_date ) ) ? 1 : -1;
    } );

  }

  getMemberFromListById( id: string ): HouseholdMember {
    return this.householdMembers.find( ( hm: HouseholdMember ): boolean => {
      return hm.id === id;
    } );
  }

  getMemberIndexById( id: string ): number {
    return this.householdMembers.findIndex( ( hm: HouseholdMember ): boolean => {
      return hm.id === id;
    } );
  }

  private getHouseholdMembersByLoadedWorkspace(): void {
    this.hasBeenCalledOnce = true;
    this.appStoreService.loadedWorkspace$
      .pipe(
        filter( Boolean ),
      )
      .subscribe( () => {
        this.resetAfterAuthChange();
        this.setupData();
      } );
  }

}
