<!-- Headers-->
<div class="form-group">
    <div class="flexing">
        <fa-icon [icon]="faLandmark" size="3x" class="manual-account-icon"
                 style="margin-right: 5px;"></fa-icon>
        <h3 class="manual-account-form-H3" style="padding-bottom: 10px;">Create an account where you can add multiple
            securities</h3>

    </div>
    This account type can be used for Brokerage Account cases where you cannot or do not want to use a Connected
    Account.
</div>

<div class="form-group">
    <div>
        Fields with an * are required
    </div>
</div>
<div *ngIf="showForm">
    <form [formGroup]="form" #formElement>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="name"
                           [errorStateMatcher]="matcher"
                           placeholder="Account Name / Description*">
                </label>
                <mat-error *ngIf="form.controls.name.hasError('required')">
                    Name is required
                </mat-error>
                <mat-label>Account Name / Description</mat-label>
            </mat-form-field>
        </div>

        <div><h3 class="manual-account-form-H3" style="padding-bottom: 5px;">What investments do you want to add to this
            account?</h3>

        </div>
        <div class="security-table-border">
            <div class="form-group">
                <button *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]"
                        mat-raised-button
                        (click)="openInvestmentSelector()"
                        class="add-investment-button"
                        matTooltip="Add New Security To Account"
                        matTooltipClass="mat-tooltip-custom"
                        matTooltipPosition="right"
                        type="button">
                    Add Security
                </button>
            </div>
            <div class="security-simple-table">
                <mat-spinner style="margin: 0 auto;" *ngIf="loadingSecurities"></mat-spinner>

                <mat-grid-list cols="12" rowHeight="60px" *ngIf="!loadingSecurities">
                    <mat-grid-tile [rowspan]="1"></mat-grid-tile>
                    <mat-grid-tile [rowspan]="1"></mat-grid-tile>
                    <mat-grid-tile [rowspan]="1">Ticker</mat-grid-tile>
                    <mat-grid-tile [rowspan]="1" [colspan]="2">Name</mat-grid-tile>
                    <mat-grid-tile [rowspan]="1" matTooltip="Price" matTooltipClass="mat-tooltip-custom">
        <span class="title-with-disclaimer">
          Price ($)
          <span class="disclaimer" (click)="openDisclaimerModal( 2)">
            <fa-icon [icon]="faQuestionCircle" size="xs"></fa-icon>
          </span>
        </span>
                    </mat-grid-tile>
                    <mat-grid-tile [rowspan]="1" [colspan]="2">Quantity</mat-grid-tile>
                    <mat-grid-tile [rowspan]="1" [colspan]="2">Market Value ($)</mat-grid-tile>
                    <mat-grid-tile [rowspan]="1" [colspan]="2">Cost Basis ($)</mat-grid-tile>
                    <div *ngFor="let position of form.controls.positions.value; let index = index">
                        <mat-grid-tile [rowspan]="1">
                            <button *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]"
                                    mat-raised-button
                                    (click)="openManualSecurityEditor(position)"
                                    class="icon-button"
                                    [ngClass]="{'missing-data': position.missing_data }"
                                    matTooltip="Edit Security{{ position.missing_data ? ' (This security is missing some key data that we need to calculate its market value or include it in the global dashboard aggregation)' : '' }}"
                                    matTooltipClass="mat-tooltip-custom"
                                    type="button">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-grid-tile>
                        <mat-grid-tile [rowspan]="1">
                            <button *ripPerm="[perm.OWNER, perm.ACCOUNTS_EDIT]"
                                    mat-raised-button
                                    (click)="deletePosition(position, index)"
                                    class="icon-button"
                                    matTooltip="Remove Security From Account"
                                    matTooltipClass="mat-tooltip-custom"
                                    type="button">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-grid-tile>
                        <mat-grid-tile [rowspan]="1">{{ position.ticker }}</mat-grid-tile>
                        <mat-grid-tile [rowspan]="1"
                                       [colspan]="2">{{ position.ticker_name | ripTruncatePipe : 25 }}</mat-grid-tile>
                        <mat-grid-tile [rowspan]="1" [colspan]="1">
                            {{ position.price | ripCurrencyPipe }}
                        </mat-grid-tile>
                        <mat-grid-tile [rowspan]="1" [colspan]="2">
                            <mat-form-field>
                                <label>
                                    <input #quantityInput matInput
                                           [formControl]="positionQuantityInputs[position.ticker]"
                                           (change)="quantityChanged(quantityInput, position.ticker)"
                                           placeholder="Quantity*">
                                </label>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile [rowspan]="1"
                                       [colspan]="2">{{ position.quantity * position.price | ripCurrencyPipe }}
                        </mat-grid-tile>
                        <mat-grid-tile [rowspan]="1" [colspan]="2">
                            <mat-form-field>
                                <label>
                                    <input #costBasisInput matInput
                                           [formControl]="positionCostBasisInputs[position.ticker]"
                                           (change)="costBasisChanged(costBasisInput, position.ticker)"
                                           placeholder="Cost Basis*">
                                </label>
                            </mat-form-field>
                        </mat-grid-tile>
                    </div>
                    <mat-grid-tile *ngIf="!form.controls.positions.value || form.controls.positions.value.length === 0"
                                   [rowspan]="1"
                                   [colspan]="5">
                        No Positions
                    </mat-grid-tile>
                    <mat-grid-tile [rowspan]="1" [colspan]="5">
                        Total Account Market Value ($): {{ account.value || 0 | ripCurrencyPipe }}
                    </mat-grid-tile>
                </mat-grid-list>

            </div>
            <div style="padding: 5px; color: #7d7d7d;"> Prices for public securities will update automatically.
            </div>

        </div>
        <h3 class="manual-account-form-H3" style="padding-bottom: 10px; padding-top: 15px;">Account Details?</h3>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Investment Account Type"
                            id="type"
                            name="type"
                            formControlName="investment_account_type"
                            panelClass="max-select-box-height"
                            [errorStateMatcher]="matcher"
                            (selectionChange)="accountTypeChanged()">
                    <mat-option>Select Account Type</mat-option>
                    <mat-optgroup *ngFor="let category of investmentAccountTypes" [label]="category.label">
                        <mat-option *ngFor="let type of category.types" [value]="type">
                            {{ type.type }}
                        </mat-option>
                    </mat-optgroup>

                </mat-select>
            </mat-form-field>
        </div>
        <mat-checkbox formControlName="is_taxable">
            Taxable
        </mat-checkbox>
        <!--investment_account_type-->
        <!--is_taxable-->

        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Owner Type"
                            formControlName="owner_type"
                            [errorStateMatcher]="matcher">
                    <mat-option value="Individual Account">Individual Account</mat-option>
                    <mat-option value="Joint Account">Joint Account</mat-option>
                    <mat-option value="Trust Account">Trust Account</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <label>
                    <input matInput
                           formControlName="institution_name"
                           [errorStateMatcher]="matcher"
                           placeholder="Institution Name (your brokerage where this account is held)">
                </label>
                <mat-label>Institution Name (your brokerage)</mat-label>
            </mat-form-field>
        </div>
    </form>
</div>


