import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { GlobalState } from '../../global.state';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { environment } from '../../../environments/environment';
import { AccountManager } from '../../utils/accountManager';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { Router } from '@angular/router';
import { Logger } from '../../utils/logger.service';
import { EVENT_NAMES } from '../../utils/enums';

// ===================================================================================================================
// CHANGES MADE TO THIS FILE MAY REQUIRE CORRESPONDING CHANGES TO THE MOBILE VERSION
// (~/src/app/pages/mobileLayout/components/mobileOneDayChange.component.ts)
// ===================================================================================================================

export interface BreakdownData {
  portfolioOneDayBreakdownRows: any[];
  portfolioOneDayBreakdownColumns: any[];
  benchmarkOneDayBreakdownRows: any[];
  benchmarkOneDayBreakdownColumns: any[];
  tableHeaderHeight: number;
  tableRowHeight: number;
  oneDayBreakdownTableMessages: any;
  oneDayBreakdownTableCssClasses: any;
}

@Component( {
  selector: 'rip-one-day-change-breakdown',
  template: `
    <div [nbSpinner]="loading">
      <nb-card id="one-day-change-breakdown-card">
        <nb-card-header>
          <div id="daily-perf-header">


            <div></div>
            <div>Portfolio Return</div>
            <div>{{ yourPortfolioTotal | ripCurrencyPipe }}</div>
            <div class="benchmark-line" style="font-size: 2rem;">-</div>
            <div class="benchmark-line"> Benchmark Return</div>
            <div class="benchmark-line">{{ yourBenchmarkTotal | ripCurrencyPipe }}</div>
            <div class="daily-return-line">=</div>
            <div class="daily-return-line">Net of Benchmark Performance</div>
            <div class="daily-return-line" style="border: 2px solid; padding: 10px;">
              <span
                [ngClass]="{ 'up': yourPortfolioTotal - yourBenchmarkTotal > 0, 'down': yourPortfolioTotal - yourBenchmarkTotal < 0}">
                {{ yourPortfolioTotal - yourBenchmarkTotal | ripCurrencyPipe }}
              </span>
            </div>


          </div>
        </nb-card-header>
        <nb-card-body>
          <div id="one-day-breakdowns-container">
            <div class="one-day-breakdown-container">
              <h3 mat-dialog-title>
                Your Portfolio
              </h3>
              <ngx-datatable #portfolioTable
                             [rows]="breakdownData?.portfolioOneDayBreakdownRows"
                             [columns]="breakdownData?.portfolioOneDayBreakdownColumns"
                             [columnMode]="'force'"
                             [headerHeight]="breakdownData?.tableHeaderHeight"
                             [rowHeight]="breakdownData?.tableRowHeight"
                             [reorderable]="false"
                             [messages]="breakdownData?.oneDayBreakdownTableMessages"
                             [cssClasses]="breakdownData?.oneDayBreakdownTableCssClasses"
                             [footerHeight]="50"
                             (activate)="goToHolding($event)"
                             class="material striped one-day-breakdown" id="portfolio-breakdown-table">
              </ngx-datatable>
            </div>
            <div class="one-day-breakdown-container">
              <h3 mat-dialog-title>
                Your Benchmark
              </h3>
              <ngx-datatable #benchmarkTable
                             [rows]="breakdownData?.benchmarkOneDayBreakdownRows"
                             [columns]="breakdownData?.benchmarkOneDayBreakdownColumns"
                             [columnMode]="'force'"
                             [headerHeight]="breakdownData?.tableHeaderHeight"
                             [rowHeight]="breakdownData?.tableRowHeight"
                             [reorderable]="false"
                             [messages]="breakdownData?.oneDayBreakdownTableMessages"
                             [cssClasses]="breakdownData?.oneDayBreakdownTableCssClasses"
                             [footerHeight]="50"
                             class="material striped one-day-breakdown">
              </ngx-datatable>
              <ng-template ngx-datatable-cell-template #oneDayChangeTemplate let-row="row" let-rowIndex="rowIndex">
                <div
                  *ngIf="row.percentChange && row.security_type !== 'Money Market' && row.security_type !== 'Money Market Fund'"
                  class="one-day-change"
                  [ngClass]="{
          'up': row.percentChange > 0,
          'down': row.percentChange < 0
        }">
                  <mat-grid-list cols="1" rowHeight="20px">
                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      <span *ngIf="row.percentChange > 0">
                        <fa-icon [icon]="faCaretUp" [fixedWidth]="true" size="lg"></fa-icon>
                      </span>
                      <span *ngIf="row.percentChange < 0">
                        <fa-icon [icon]="faCaretDown" [fixedWidth]="true" size="lg"></fa-icon>
                      </span>
                      {{ row.dollarChange | ripCurrencyPipe }}
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="1" [rowspan]="1">
                      {{ row.percentChange | ripLongPercentPipe }}
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
              </ng-template>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          As of {{ today | date : 'short' }}
        </nb-card-footer>
      </nb-card>
    </div>
  `,
  styleUrls: [ `./one-day-breakdown.component.scss` ],
} )

export class OneDayBreakdownComponent implements AfterViewInit, OnDestroy {

  @ViewChild( 'portfolioTable' ) portfolioTable: DatatableComponent;
  @ViewChild( 'benchmarkTable' ) benchmarkTable: DatatableComponent;
  @ViewChild( 'oneDayChangeTemplate', { static: false } ) oneDayChangeTemplate: TemplateRef<any>;

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;

  breakdownData: BreakdownData = {
    benchmarkOneDayBreakdownColumns: [],
    benchmarkOneDayBreakdownRows: [],
    oneDayBreakdownTableCssClasses: {},
    oneDayBreakdownTableMessages: {},
    portfolioOneDayBreakdownColumns: [],
    portfolioOneDayBreakdownRows: [],
    tableHeaderHeight: 0,
    tableRowHeight: 0,
  };

  subscriberName: string = 'oneDayChangeBreakdownComponent';

  yourPortfolioTotal: number;
  yourBenchmarkTotal: number;
  today: Date = new Date();

  loading: boolean = true;

  constructor( private _state: GlobalState,
               private _cd: ChangeDetectorRef,
               private _accountManager: AccountManager,
               private _router: Router ) {

    if ( !this._state.globalVars.refreshingPrices && this._accountManager.getAllPositionsIncludingManualAccounts()?.length > 0 ) {
      _accountManager.refreshPrices();
    }

    if ( this._state.globalVars.oneDayBreakdownData ) {
      this.waitForTemplate();
    }

    _state.subscribe( 'one.day.breakdown.set', () => {
      this.waitForTemplate();
      this.doChanges();
    }, this.subscriberName );

    _state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
      if ( !this._state.globalVars.inWealthFluent ) {
        this.loading = true;
        _accountManager.refreshPrices();
      }
    }, this.subscriberName );

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_oneDayChangeBreakdownComponent' ] = this;
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.doChanges();
    } );
  }

  ngOnDestroy() {
    this._state.unsubscribe( 'one.day.breakdown.set', this.subscriberName );
  }

  doChanges() {
    // this._cd.detach();
    this.benchmarkTable?.recalculate();
    this.portfolioTable?.recalculate();
    // this._cd.detectChanges();
    // this._cd.reattach();
  }

  setData() {
    this.yourPortfolioTotal = 0;
    this.yourBenchmarkTotal = 0;
    for ( const x of this.breakdownData.portfolioOneDayBreakdownRows ) {
      x.dollarChange = x.dollarChange ?? x.dollar;
      x.percentChange = x.percentChange ?? x.percent;
      this.yourPortfolioTotal += x.dollarChange;
    }
    for ( const y of this.breakdownData.benchmarkOneDayBreakdownRows ) {
      y.dollarChange = y.dollarChange ?? y.dollar;
      y.percentChange = y.percentChange ?? y.percent;
      this.yourBenchmarkTotal += y.dollarChange;
    }
    console.log( 'breakdown data set' );

    this.loading = false;
  }

  waitForTemplate() {
    if ( this.oneDayChangeTemplate ) {
      this.breakdownData = this._state.globalVars.oneDayBreakdownData;
      this.breakdownData.benchmarkOneDayBreakdownColumns[ 2 ].cellTemplate = this.oneDayChangeTemplate;
      this.breakdownData.portfolioOneDayBreakdownColumns[ 2 ].cellTemplate = this.oneDayChangeTemplate;
      this.setData();
    } else {
      setTimeout( () => {
        Logger.info( 'one day change template not yet available, trying again in 500ms' );
        this.waitForTemplate();
      }, 500 );
    }
  }

  goToHolding( event ) {
    if ( event.type === 'click' ) {
      console.log( event.row );
      this._router.navigate( [ '/pages/holdings' ], { queryParams: { rowToOpen: event.row.ticker } } );
    }
  }
}
