import { Injectable, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType, Overlay } from '@angular/cdk/overlay';

@Injectable()
export class DialogService {

  constructor(
    private dialog: Dialog,
    private overlay: Overlay,
    private router: Router,
  ) { }

  open<D = unknown, R = unknown, C = unknown>(
    componentOrTemplateRef: ComponentType<C> | TemplateRef<C>,
    config?: DialogConfig<D, DialogRef<R, C>>,
    closeOnRouterEvents =  true,
  ): DialogRef<R, C> {
    const dialogRef = this.dialog.open<R, D, C>(componentOrTemplateRef, { ...this.defaultConfig, ...(config || {} as any) });

    if (closeOnRouterEvents) {
      this.closeOnRouterEvents(dialogRef);
    }

    return dialogRef;
  }

  private get defaultConfig(): DialogConfig {
    return {
      // panelClass: null,
      hasBackdrop: true,
      disableClose: true,
      minWidth: '30%',
      // positionStrategy: null,
      autoFocus: true,
      restoreFocus: true,
      closeOnNavigation: true,
    };
  }

  private closeOnRouterEvents(dialogRef: DialogRef<any, any>): void {
    this.router.events
      .pipe(
        takeUntil(dialogRef.closed),
        filter(e => e instanceof NavigationStart),
        take(1),
        tap(() => dialogRef?.close(null)),
      )
      .subscribe();
  }
}
