import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalState } from '../../../global.state';
import { GlobalOverrideItem, LocalOverrideItem, } from '../../../utils/dataInterfaces';
import { Account, Position } from '@ripsawllc/ripsaw-analyzer';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Util } from '../../../utils/util.service';
import { Router } from '@angular/router';
import { OverridesUtil } from '../../../utils/overrides.util';
import { ConfirmationComponent } from '../../accounts/confirmation-dialog.component';
import { NbTrigger } from '@nebular/theme';
import { Logger } from '../../../utils/logger.service';

@Component( {
  templateUrl: `./global-overrides-manager.component.html`,
  styleUrls: [ './global-overrides-manager.component.scss' ],
} )

export class GlobalOverridesManagerComponent implements AfterViewInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  faTrash = faTrash;
  faInfoCircle = faInfoCircle;
  faEdit = faEdit;
  faCircle = faCircle;
  faExclamationCircle = faExclamationCircle;
  faCheckCircle = faCheckCircle;
  faTrashAlt = faTrashAlt;

  loading: boolean = true;

  popoverTrigger: NbTrigger = NbTrigger.HOVER;

  constructor( public dialogRef: MatDialogRef<GlobalOverridesManagerComponent>,
               @Inject( MAT_DIALOG_DATA ) public data: any,
               public dialog: MatDialog,
               public state: GlobalState,
               public snackBar: MatSnackBar,
               private _router: Router,
               public overridesUtil: OverridesUtil ) {

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_globalOverrides' ] = this;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  ngAfterViewInit() {
    setTimeout( () => {
      this.init();
    } );
  }

  init() {
    this.loading = true;
    try {
      this.overridesUtil.checkAndCompileOverrideData();
    } catch ( e ) {
      console.error( e );
    }
    // check to see if there is an override passed to the dialog and should be expanded on load
    const goiToExpand = this.overridesUtil.globalOverrideItems.find( ( goi ) => {
      return this.data?.goiToOpen?.override?.id === goi.override.id;
    } );
    if ( goiToExpand ) {
      goiToExpand.expanded = true;
    }
    this.loading = false;
  }

  confirmRemoveOverride( event: any, goi: GlobalOverrideItem ) {
    event.stopPropagation();
    const ref = this.dialog.open( ConfirmationComponent, {
      data: {
        title: 'Delete Security Override',
        message: 'Are you sure you want to delete this security override?',
        confirmButtonLabel: 'CONFIRM DELETE',
        cancelButtonLabel: 'Cancel',
      },
    } );

    ref.afterClosed().subscribe( {
      next: ( result ) => {
        if ( result ) {
          this.removeOverride( goi );
        }
      }, error: ( err ) => {
        console.error( err );
      },
    } );
  }

  removeOverride( goi: GlobalOverrideItem ) {
    Logger.log( goi );
    goi.spinning = true;

    const cb: Function = ( err ) => {
      goi.spinning = false;
      if ( !err ) {
        _.remove( this.overridesUtil.globalOverrideItems, ( x: GlobalOverrideItem ) => {
          return x.override.id === goi.override.id;
        } );
        this.init();
      }
    };

    this.overridesUtil.deleteGlobalOverride( goi.override, cb );
  }

  editGlobalOverride( goi: GlobalOverrideItem ) {
    this.dialogRef.close( { globalOverrideToEdit: goi } );
  }

  editLocalOverride( loi: LocalOverrideItem ) {
    this.dialogRef.close( { localOverrideToEdit: loi } );
  }

  close() {
    this.dialogRef.close();
  }

  /**
   * this function helps open the account containing the position with the security in the data quality issue
   * @param {Position} position - Position that has missing data
   */
  goToAccount( position: Position ) {
    Util.goToAccount( position.account_id, position.ticker, this.state, this._router, this.dialogRef );
  }

  /**
   * this function helps open the account containing the position matching the existing global override, so the user
   * can see the local overrides too
   * @param a - account where the local override is
   * @param goi - global override item for the global override
   */
  goToLocalOverride( a: Account, goi: GlobalOverrideItem ) {
    const ticker = goi.override?.overrides?.ticker ? goi.override.overrides.ticker.new : goi.override.original_ticker;
    Util.goToAccount( a.account_id, ticker, this.state, this._router, this.dialogRef );
  }
}
